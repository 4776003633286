import React from 'react';
import { signOut } from '../../apis/Auth';
import * as Native from '../../apis/NativeApi';
import { deleteDeviceToken } from '../../apis/User';
import { Vehicle } from '../../apis/VehicleList';
import { None, Option } from '../../util/Option';
import { theme } from '../../util/Theme';
import { SlideInWindow, SlideInWindowMode } from '../components/SlideInWindow';
import { HomeDataMap, HomeDispatch } from '../home/Home';
import { Message, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { NotificationVehicles } from './NotificationVehicles';
import { NotificationWindow } from './NotificationWindow';
import { VehicleManagement } from './VehicleManagement';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mode: SlideInWindowMode;
  vehicles: Vehicle[];
}>;

function openLink(
  dispatch: HomeDispatch,
  url: string,
  title?: string,
  openType?: Native.OpenType,
): HomeDispatch {
  Native.openLink(url, title, openType);
  return dispatch;
}

function preSignOut(): Promise<void> {
  return Native.getDeviceToken().then((deviceToken) =>
    deviceToken.unwrap(
      (token) =>
        deleteDeviceToken({ platform: 'push_gcm', deviceToken: token })
          .then(() => Native.setDeviceToken(null))
          .catch((err) => {
            Native.println(err);
          }),
      () => {
        Native.println(Error(`No device token`));
      },
    ),
  );
}

interface ListItemProps {
  parent: DataMapStore<HomeDataMap>;
  icon: string;
  title: Message;
  linkName?: Message;
  onClick?: () => HomeDispatch;
}

const ListItem: React.FC<ListItemProps> = ({
  parent,
  icon,
  title,
  linkName,
  onClick,
}) => {
  const {
    views: { View, Image, Text },
    dispatch,
  } = useParentStore({ parent });

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          flexGrow: 0,
          paddingLeft: Length.px(12),
          paddingRight: Length.px(12),
        }}
      >
        <Image
          src={icon}
          style={{
            height: Length.px(40),
            width: Length.px(40),
          }}
        />
      </View>

      <View
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: Length.px(16),
          paddingBottom: Length.px(16),
          paddingRight: Length.px(12),
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
        onClick={(evt) => {
          evt.stopPropagation();
          return Option(onClick)
            .map((f) => f())
            .getOrElse(() => dispatch);
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            lineHeight: Length.px(16),
            width: Percentage(100),
          }}
        >
          {title.toReactNode()}
        </Text>

        <Text
          style={{
            fontSize: Length.px(12),
            lineHeight: Length.px(12),
            color: Colors.accent,
          }}
        >
          {Option(linkName)
            .getOrElse(() => Message([]))
            .toReactNode()}
        </Text>
      </View>
    </View>
  );
};

const HeroItem: React.VFC = () => {
  const {
    views: { View, Text, Image },
  } = useContextStore();
  const iconSize = Length.px(80);

  // TODO: アカウントの email と名前を取得する方法が不明なので、ひとまず固定値にしておく。
  const email = 'ichiro@daihatsu.com';
  const name = '恵比寿太郎';

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: Percentage(100),
        paddingTop: Length.px(8),
        paddingBottom: Length.px(19),
        borderBottom: Border('solid', Length.px(0.5), Colors.border),
      }}
    >
      <Image
        style={{
          width: iconSize,
          height: iconSize,
        }}
        src={Icons.ic_owner}
      />
      <Text
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(16),
          marginTop: Length.px(4),
        }}
      >
        {email}
      </Text>
      <Text
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(16),
          marginTop: Length.px(8),
        }}
      >
        {name}
      </Text>
    </View>
  );
};

const FooterItem: React.VFC = () => {
  const {
    views: { View, Text },
    data: { context },
  } = useContextStore();
  const intl = useIntl();

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: Length.px(8),
      }}
    >
      <Text
        style={{
          fontSize: Length.px(12),
          color: Colors.textMoreLight,
        }}
      >
        {intl.formatMessage({ id: 'Register_appVersion' }).toReactNode()}{' '}
        {context.platform.appVersion}
      </Text>
    </View>
  );
};

export function RegistrationWindow(props: Props): React.ReactElement {
  const {
    data: { context },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();

  const accountHeader = <HeroItem key='accountHeader' />;
  const accountInformation = (
    <ListItem
      key='accountInformation'
      parent={props.parent}
      icon={Icons.ic_menu_account}
      title={intl.formatMessage({ id: 'Register_dPortAccount' })}
      linkName={
        context.locale.lang === 'ja'
          ? intl.formatMessage({ id: 'Register_dPort' })
          : undefined
      }
      onClick={() =>
        openLink(
          dispatch,
          'https://stgconnect.daihatsu.co.jp/member/web/userEdit/index',
          intl.formatMessage({ id: 'Register_dPortAccount' }).toString(),
        )
      }
    />
  );
  const vehicleInformation = (
    <ListItem
      key='vehicleInformation'
      parent={props.parent}
      icon={Icons.ic_menu_mycar}
      title={intl.formatMessage({ id: 'Register_registerCar' })}
      onClick={() =>
        dispatch.context.bind(
          Context.actions.updateVehicleManagementMode,
          'Show',
        )
      }
    />
  );
  const assignedDealer = (
    <ListItem
      key='assignedDealer'
      parent={props.parent}
      icon={Icons.ic_menu_dealership}
      title={intl.formatMessage({ id: 'Register_myShop' })}
      linkName={
        context.locale.lang === 'ja'
          ? intl.formatMessage({ id: 'Register_dPort' })
          : undefined
      }
      onClick={() =>
        openLink(
          dispatch,
          'https://stgconnect.daihatsu.co.jp/member/web/mystore/',
          intl.formatMessage({ id: 'Register_myShop' }).toString(),
        )
      }
    />
  );
  const notificationSetting = (
    <ListItem
      key='notificationSetting'
      parent={props.parent}
      icon={Icons.ic_menu_notification}
      title={intl.formatMessage({ id: 'Register_settingNotify' })}
      onClick={() =>
        dispatch.context.bind(
          Context.actions.updateNotificationVehiclesMode,
          'Show',
        )
      }
    />
  );
  const termsAndConditions = (
    <ListItem
      key='termsAndConditions'
      parent={props.parent}
      icon={Icons.ic_menu_terms}
      title={intl.formatMessage({ id: 'Register_tac' })}
      onClick={() =>
        openLink(
          dispatch,
          `${window.location.href}pdfjs/web/viewer.html?file=https://www.daihatsu.co.jp/connect/terms/daihatsuconnect.pdf`,
          intl.formatMessage({ id: 'Register_tac' }).toString(),
          'InAppBrowser',
        )
      }
    />
  );
  const privacyPolicy = (
    <ListItem
      key='privacyPolicy'
      parent={props.parent}
      icon={Icons.ic_menu_privacy}
      title={intl.formatMessage({ id: 'Register_appPolicy' })}
      onClick={() =>
        openLink(
          dispatch,
          `${window.location.href}pdfjs/web/viewer.html?file=https://www.daihatsu.co.jp/connect/privacy/daihatsuconnect.pdf`,
          intl.formatMessage({ id: 'Register_appPolicy' }).toString(),
          'InAppBrowser',
        )
      }
    />
  );
  const doSignOut = (
    <ListItem
      key='doSignOut'
      parent={props.parent}
      icon={Icons.ic_menu_logout}
      title={intl.formatMessage({ id: 'Register_signOut' })}
      onClick={() =>
        dispatch.context.bind(Context.actions.updateConfirmDialog, {
          state: 'Show',
          title: intl.formatMessage({ id: 'Register_signOut' }),
          message: intl.formatMessage({ id: 'Register_dialogMessage' }),
          confirmButtonTitle: intl.formatMessage({
            id: 'Register_signOut',
          }),
          onConfirm: () => {
            preSignOut().finally(() => signOut());
            return dispatch.context
              .bind(Context.actions.updateVehicles, [])
              .context.bind(Context.actions.updateSelectedVehicleVin, None())
              .context.bind(Context.actions.updateRegisterWindowMode, 'Hide')
              .context.bind(Context.actions.updateContentState, 'SignedOut');
          },
        })
      }
    />
  );

  const contents = theme(
    [
      accountInformation,
      vehicleInformation,
      assignedDealer,
      notificationSetting,
      termsAndConditions,
      privacyPolicy,
      doSignOut,
    ],
    [accountHeader, vehicleInformation, notificationSetting, doSignOut],
  );

  return (
    <SlideInWindow
      id='RegistrationWindow'
      parent={props.parent}
      mode={props.mode}
      direction='vertical'
      title={theme(intl.formatMessage({ id: 'Register_pageTitle' }), undefined)}
      onClose={(dispatch) =>
        dispatch.context(Context.actions.updateRegisterWindowMode, 'Hide')
      }
    >
      {contents}

      <FooterItem />

      <VehicleManagement
        parent={props.parent}
        mode={context.vehicleManagementMode}
        cars={props.vehicles}
      />

      <NotificationVehicles
        parent={props.parent}
        mode={context.notificationVehiclesMode}
        cars={props.vehicles}
      />

      <NotificationWindow
        parent={props.parent}
        mode={context.notificationSettingsMode}
        cars={props.vehicles}
      />
    </SlideInWindow>
  );
}
