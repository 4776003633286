export interface Percentage {
  readonly kind: 'Percentage';
  readonly value: number;
  readonly toStyle: () => string;
  readonly copy: (value: number) => Percentage;
}

export function Percentage(value: number): Percentage {
  const text = `${value}%`;
  return {
    kind: 'Percentage',
    value,
    toStyle: () => text,
    copy: (value: number) => Percentage(value),
  };
}
