import React from 'react';
import { config } from '../../Config';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { Option, Some } from '../../util/Option';
import { systemErrorDialogAction } from '../components/AlertDialog';
import { HomeDataMap } from '../home/Home';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { getGeofenceMenuState, getTrackingMode } from './Helpers';
import { getGeoPos } from './MapUtils';
import { MenuPane } from './MenuPane';
import {
  TrackingMode,
  VehicleLocationState,
  defaultRadius,
} from './VehicleLocationState';

export function SubPanes({
  parent,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>): React.ReactElement {
  const {
    data: { context, home },
    dispatch,
  } = useParentStore({ parent });
  const actions = VehicleLocationState.actions;
  const data = home.localState.geofence;
  const localState = data.localState;
  const radius = getSelectedVehicle(context)
    .flatMap((_) => _.geofence)
    .map((geofence) => geofence.radius);
  const trackingMode: TrackingMode = context.serviceTelemetry.data.unwrap(
    (srv) =>
      getTrackingMode(
        srv.en_theft_trk,
        Option(context.ongoingRemoteStates[srv.vin]).some(
          (_) => _.trackingMode,
        ),
      ),
    () => 'Normal',
  );

  return (
    <>
      <MenuPane
        parent={parent}
        state={getGeofenceMenuState(localState.cardState)}
        radius={radius}
        trackingMode={trackingMode}
        onOpenGeofenceSetting={() =>
          dispatch.home(actions.updateSettingPaneState, 'Show').pipe((_) =>
            context.serviceTelemetry.data
              .toOption()
              .flatMap((_) => _.position)
              .map((_) => _.location)
              .orElse(() => getGeoPos(localState.userLocation))
              .orElse(() =>
                Some(config.regionPositions[context.locale.region].position),
              )
              .unwrap(
                (position) =>
                  _.home(
                    actions.onSettingPaneOpen,
                    localState.geofenceCache.unwrap(
                      (_) => _,
                      () =>
                        getSelectedVehicle(context)
                          .flatMap((_) => _.geofence)
                          .getOrElse(() => ({
                            center: position,
                            radius: defaultRadius,
                          })),
                    ),
                  ),
                () => _.context(systemErrorDialogAction('No vehicle location')),
              ),
          )
        }
      />
    </>
  );
}
