import {
  NativeCustomEventTarget,
  RawNativeRequestData,
  RawStorage,
  ReactNativeWebView,
  StorageAction,
  posToGeoPos,
  println,
  sendNativeEvent,
} from '../../apis/NativeApi';
import { StorageKey, StorageKeys } from '../../apis/StorageKey';
import { Anomaly, getErrorMessage } from '../../util/Error';
import { Lazy } from '../../util/Lazy';
import { Locale } from '../../util/Locale';
import { Option } from '../../util/Option';
import { sleep } from '../../util/Sleep';
import { theme } from '../../util/Theme';
import { Unit } from '../../util/Unit';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const platform = require('platform');

type MockWebView = ReactNativeWebView;

type MockDevice = {
  mockWebView: MockWebView;
  updateLocale: (locale: Locale) => void;
  getLocationPermission: () => Promise<boolean>;
  updateLocationPermission: (flag: boolean) => Promise<boolean>;
  getLocationEnable: () => Promise<boolean>;
  updateLocationEnable: (flag: boolean) => Promise<boolean>;
  getLocationError: () => Promise<boolean>;
  updateLocationError: (flag: boolean) => Promise<boolean>;
};

const MockWebViewEventName = 'MockWebViewEvent';

export const MockDevice: Lazy<MockDevice> = Lazy(() => {
  const mockWebView: MockWebView = {
    postMessage: (message: string) => {
      window.dispatchEvent(
        new CustomEvent(MockWebViewEventName, {
          detail: message,
        }),
      );
    },
  };

  async function setStorageItem(key: StorageKey, value: string): Promise<Unit> {
    await sleep(100);
    localStorage.setItem(key, value);
    return Promise.resolve(Unit);
  }

  async function getStorageItem(key: StorageKey): Promise<Option<string>> {
    await sleep(100);
    return Promise.resolve(Option(localStorage.getItem(key)));
  }

  async function removeStorageItem(key: StorageKey): Promise<Unit> {
    await sleep(100);
    localStorage.removeItem(key);
    return Promise.resolve(Unit);
  }

  function setLocale(locale: Locale): Promise<Unit> {
    return setStorageItem(StorageKeys.mockDeviceLocale, JSON.stringify(locale));
  }

  function getLocale(): Promise<Locale> {
    return getStorageItem(StorageKeys.mockDeviceLocale).then((s) =>
      s.unwrap(
        (_) => JSON.parse(_),
        () => theme(Locale('ja', 'JP'), Locale('en', 'MY')),
      ),
    );
  }

  function sendLocale(locale: Locale): void {
    sendNativeEvent({
      target: 'locale',
      locale: `${locale.lang}_${locale.region}`,
    });
  }

  function updateLocale(locale: Locale): void {
    setLocale(locale).then(() => sendLocale(locale));
  }

  function getLocationPermission(): Promise<boolean> {
    return getStorageItem(StorageKeys.mockDeviceLocationPermission).then((_) =>
      JSON.parse(_.getOrElse(() => 'false')),
    );
  }

  function updateLocationPermission(flag: boolean): Promise<boolean> {
    return setStorageItem(
      StorageKeys.mockDeviceLocationPermission,
      JSON.stringify(flag),
    ).then(() => flag);
  }

  function getLocationEnable(): Promise<boolean> {
    return getStorageItem(StorageKeys.mockDeviceLocationEnable).then((_) =>
      JSON.parse(_.getOrElse(() => 'false')),
    );
  }

  function updateLocationEnable(flag: boolean): Promise<boolean> {
    return setStorageItem(
      StorageKeys.mockDeviceLocationEnable,
      JSON.stringify(flag),
    ).then(() => flag);
  }

  function getLocationError(): Promise<boolean> {
    return getStorageItem(StorageKeys.mockDeviceLocationError).then((_) =>
      JSON.parse(_.getOrElse(() => 'false')),
    );
  }

  function updateLocationError(flag: boolean): Promise<boolean> {
    return setStorageItem(
      StorageKeys.mockDeviceLocationError,
      JSON.stringify(flag),
    ).then(() => flag);
  }

  function handleListenReady(): void {
    sleep(100).then(() => {
      sendNativeEvent({
        target: 'ssid',
        ssid: 'DummySSID',
        bssid: 'DummyBSSID',
      });
    });

    getLocale().then(sendLocale);

    sleep(100).then(() =>
      sendNativeEvent({
        target: 'version',
        data: {
          version: platform.os.version,
          buildNumber: platform.version,
          platform: 'android',
          osVersion: '0.1',
        },
      }),
    );
  }

  async function handleLocation(
    data: RawNativeRequestData<'location', StorageAction>,
  ) {
    const enable = await getLocationEnable();
    const permission = await getLocationPermission();
    if (enable) {
      if (permission) {
        const locationError = await getLocationError();
        if (locationError === false) {
          navigator.geolocation.getCurrentPosition(
            (pos) =>
              sendNativeEvent({
                target: data.target,
                location: posToGeoPos(pos),
                permission: 'granted',
              }),
            (err) => {
              const msg = Error(
                `Failed to get the user location: ${err.message}`,
              );
              sendNativeEvent({
                target: data.target,
                permission: 'unavailable',
                error: err,
              });
              println(msg);
            },
            {
              timeout: data.timeout,
            },
          );
        } else {
          await sleep(1000);
          const err = Anomaly(3, 'Timeout Error').toJson();
          const msg = Error(`Failed to get the user location: ${err.message}`);
          sendNativeEvent({
            target: data.target,
            permission: 'unavailable',
            error: err,
          });
          println(msg);
        }
      } else {
        sendNativeEvent({
          target: data.target,
          permission: 'unavailable',
        });
      }
    } else {
      sendNativeEvent({
        target: data.target,
        permission: 'unavailable',
        error: {
          code: 2,
          message: 'unavailable',
        },
      });
    }
  }

  function handleStorage(data: RawNativeRequestData<'storage', StorageAction>) {
    switch (data.action) {
      case 'get':
        return getStorageItem(data.key)
          .then((value) =>
            sendNativeEvent({
              ...data,
              value: value.getOrNull(),
            }),
          )
          .catch((err) =>
            sendNativeEvent({
              ...data,
              error: getErrorMessage(err),
            }),
          );

      case 'set': {
        const _data = data as RawStorage<'Request', 'set'>;
        return setStorageItem(_data.key, _data.value)
          .then(() => sendNativeEvent(_data))
          .catch((err) =>
            sendNativeEvent({
              ...data,
              error: getErrorMessage(err),
            }),
          );
      }
      case 'remove': {
        const _data = data as RawStorage<'Request', 'remove'>;
        return removeStorageItem(_data.key)
          .then(() => sendNativeEvent(_data))
          .catch((err) =>
            sendNativeEvent({
              ...data,
              error: getErrorMessage(err),
            }),
          );
      }
      default:
        return;
    }
  }

  function handleRawNativeRequest(
    data: RawNativeRequestData<NativeCustomEventTarget, StorageAction>,
  ) {
    switch (data.target) {
      case 'location':
        return handleLocation(data);

      case 'locationPermission':
        return updateLocationPermission(true).then(() =>
          sendNativeEvent({
            target: data.target,
          }),
        );

      case 'locationEnable':
        return updateLocationEnable(true).then(() =>
          sendNativeEvent({
            target: data.target,
          }),
        );

      case 'storage':
        return handleStorage(data);

      case 'google-map':
      case 'link':
        return window.open(data.url);

      case 'action':
        return window.alert('The app is closed');

      case 'log':
        return console.log(data.data);

      default:
        return;
    }
  }

  window.addEventListener(MockWebViewEventName, (_) => {
    try {
      const e = _ as CustomEvent;
      const detail = JSON.parse(e.detail);
      if (detail.event === 'listen' && detail.status === 'ready') {
        handleListenReady();
      } else {
        handleRawNativeRequest(
          detail as RawNativeRequestData<
            NativeCustomEventTarget,
            StorageAction
          >,
        );
      }
    } catch (err) {
      println(Error(getErrorMessage(err)));
    }
  });

  return {
    mockWebView,
    updateLocale,
    getLocationPermission,
    updateLocationPermission,
    getLocationEnable,
    updateLocationEnable,
    getLocationError,
    updateLocationError,
  };
});
