import { config } from '../../Config';
import {
  UserVehicleEvent,
  UserVehicleEventJson,
  VehicleEvent,
  fromUserVehicleEventJson,
  toUserVehicleEventJson,
} from '../../apis/EventTelemetry';
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from '../../apis/NativeApi';
import { NotificationStatus } from '../../apis/Notification';
import {
  ServiceTelemetry,
  ServiceTelemetryJson,
  fromServiceTelemetryJson,
  toServiceTelemetryJson,
} from '../../apis/ServiceTelemetry';
import { SharedUser } from '../../apis/SharedUser';
import { StorageKeys } from '../../apis/StorageKey';
import {
  UserType,
  Vehicle,
  VehicleJson,
  fromVehicleJson,
  toVehicleJson,
} from '../../apis/VehicleList';
import { WifiStatus } from '../../apis/Wifi';
import ic_car_cast from '../../examples/daihatsu_cast.png';
import ic_car_move from '../../examples/daihatsu_move.png';
import { ToOptional, copy } from '../../util/Copyable';
import { None, Some } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';

export type DB = {
  readonly vehicles: Vehicle[];
  readonly serviceTelemetries: ServiceTelemetry[];
  readonly vehicleEvents: UserVehicleEvent[];
  readonly userVehicleEvents: UserVehicleEvent[];
  readonly wifiStatuses: WifiStatus[];
  readonly notificationStatuses: NotificationStatus[];
  readonly owners: Map<string, SharedUser>;
  readonly sharedUsers: Map<string, SharedUser[]>;

  readonly loginError: boolean;
  readonly vehicleListError: boolean;
  readonly serviceTelemetryErrorVin1: boolean;
  readonly serviceTelemetryErrorVin2: boolean;
  readonly vehicleEventErrorVin1: boolean;
  readonly vehicleEventErrorVin2: boolean;
  readonly userLastVehicleEventError: boolean;
  readonly sendRemoteCommandError: 'Any' | 409 | 500 | 'None';
  readonly subscribeRemoteCommandResultError:
    | 'Any'
    | 408
    | 409
    | 410
    | 'SendTel'
    | 'None';
  readonly pairVehicleError: boolean;
  readonly pairActivateError: boolean;
  readonly wifiStatusErrorVin1: boolean;
  readonly notificationStatusErrorVin1: boolean;
};

type DBJson = {
  readonly [Key in Exclude<
    keyof DB,
    | 'vehicles'
    | 'serviceTelemetries'
    | 'vehicleEvents'
    | 'userVehicleEvents'
    | 'owners'
    | 'sharedUsers'
  >]: DB[Key];
} & {
  readonly vehicles: VehicleJson[];
  readonly serviceTelemetries: ServiceTelemetryJson[];
  readonly vehicleEvents: UserVehicleEventJson[];
  readonly userVehicleEvents: UserVehicleEventJson[];
  readonly owners: { [key: string]: SharedUser };
  readonly sharedUsers: { [key: string]: SharedUser[] };
};

export const defaultDB: DB = {
  vehicles: [],
  serviceTelemetries: [],
  vehicleEvents: [],
  userVehicleEvents: [],
  wifiStatuses: [],
  notificationStatuses: [],
  owners: new Map(),
  sharedUsers: new Map(),

  loginError: false,
  vehicleListError: false,
  serviceTelemetryErrorVin1: false,
  serviceTelemetryErrorVin2: false,
  vehicleEventErrorVin1: false,
  vehicleEventErrorVin2: false,
  userLastVehicleEventError: false,
  sendRemoteCommandError: 'None',
  subscribeRemoteCommandResultError: 'None',
  pairVehicleError: false,
  pairActivateError: false,
  wifiStatusErrorVin1: false,
  notificationStatusErrorVin1: false,
};

function toMapJson<V>(map: Map<string, V>): { [key: string]: V } {
  return Object.fromEntries(map);
}

function fromJsonMap<V>(map: { [key: string]: V }): Map<string, V> {
  return new Map(Object.entries(map));
}

function toDBJson(a: DB): DBJson {
  return {
    ...a,
    vehicles: a.vehicles.map(toVehicleJson),
    serviceTelemetries: a.serviceTelemetries.map(toServiceTelemetryJson),
    vehicleEvents: a.vehicleEvents.map(toUserVehicleEventJson),
    userVehicleEvents: a.userVehicleEvents.map(toUserVehicleEventJson),
    owners: toMapJson(a.owners),
    sharedUsers: toMapJson(a.sharedUsers),
  };
}

function fromDBJson(a: DBJson): DB {
  return {
    ...a,
    vehicles: a.vehicles.map(fromVehicleJson),
    serviceTelemetries: a.serviceTelemetries.map(fromServiceTelemetryJson),
    vehicleEvents: a.vehicleEvents.map(fromUserVehicleEventJson),
    userVehicleEvents: a.userVehicleEvents.map(fromUserVehicleEventJson),
    owners: fromJsonMap(a.owners),
    sharedUsers: fromJsonMap(a.sharedUsers),
  };
}

function toString(db: DB): string {
  return JSON.stringify(toDBJson(db));
}

function fromString(a: string): DB {
  return fromDBJson(JSON.parse(a));
}

async function isNewVersion(): Promise<boolean> {
  return (await getStorageItem(StorageKeys.mockDbVersion)).unwrap(
    async (version) => {
      if (config.version !== version) {
        await setStorageItem(StorageKeys.mockDbVersion, config.version);
        return true;
      } else {
        return false;
      }
    },
    async () => {
      await setStorageItem(StorageKeys.mockDbVersion, config.version);
      return true;
    },
  );
}

export async function getDB(): Promise<DB> {
  const isNew = await isNewVersion();
  if (isNew) {
    await clearDB();
    return defaultDB;
  } else {
    return (await getStorageItem(StorageKeys.mockDb))
      .map(fromString)
      .getOrElse(() => defaultDB);
  }
}

export async function updateDB(db: DB, newData: ToOptional<DB>): Promise<DB> {
  const data = copy<DB>(db, newData);
  await setStorageItem(StorageKeys.mockDb, toString(data));
  return data;
}

export function clearDB(): Promise<Unit> {
  return removeStorageItem(StorageKeys.mockDb);
}

export function createVehicle(db: DB, userType: UserType = 'owner'): Vehicle {
  const i = Scope(() => {
    if (db.vehicles.length === 0) return 1;
    else {
      return (
        parseInt(db.vehicles[db.vehicles.length - 1].vin.split('-')[1]) + 1
      );
    }
  });
  const vin = `vin-${i}`;
  return {
    vin,
    groupPath: '/cars/suv/yellow/vdcm001',
    attributes: {
      carNo: Some(`練馬 123 あ 12-${i.toString().padStart(2, '0')}`),
    },
    model: `Vehicle ${i}`,
    geofence: None(),
    dcmId: `DCM${i}`,
    image: i % 2 === 0 ? ic_car_cast : ic_car_move,
    userType,
    services: [
      `bodyVehicleConditionMonitoring`,
      'engineRestartProhibit',
      'geofence',
      'meterVehicleConditionMonitoring',
      'remoteBodyControl',
      'remoteStart',
      'serviceReminder',
      'theftTracking',
      'vehicleLocation',
      'wifi',
    ],
    timestamp: new Date(Date.now()),
  };
}

export async function updateServiceTelemetry(
  db: DB,
  vin: string,
  newData:
    | ToOptional<ServiceTelemetry>
    | ((s: ServiceTelemetry) => ToOptional<ServiceTelemetry>),
): Promise<DB> {
  return updateDB(db, {
    serviceTelemetries: db.serviceTelemetries.map((_) => {
      if (_.vin === vin)
        return Pipe(
          copy<ServiceTelemetry>(
            _,
            typeof newData === 'function' ? newData(_) : newData,
          ),
        )
          .map((_) => copy<ServiceTelemetry>(_, { timestamp: new Date() }))
          .get();
      else return _;
    }),
  });
}

export function addVehicleEvent(db: DB, event: VehicleEvent): Promise<DB> {
  const f = (a: UserVehicleEvent) => {
    if (a.vin === event.vin)
      return {
        vin: a.vin,
        events: a.events.concat(event),
      };
    else return a;
  };
  return updateDB(db, {
    vehicleEvents: db.vehicleEvents.map(f),
    userVehicleEvents: db.userVehicleEvents.map(f),
  });
}

export function updateWifiStatus(
  db: DB,
  vin: string,
  newData: ToOptional<WifiStatus> | ((s: WifiStatus) => ToOptional<WifiStatus>),
): Promise<DB> {
  return updateDB(db, {
    wifiStatuses: db.wifiStatuses.map((_) => {
      if (_.vin === vin)
        return copy<WifiStatus>(
          _,
          typeof newData === 'function' ? newData(_) : newData,
        );
      else return _;
    }),
  });
}

export function updateNotificationStatus(
  db: DB,
  vin: string,
  newData:
    | ToOptional<NotificationStatus>
    | ((s: NotificationStatus) => ToOptional<NotificationStatus>),
): Promise<DB> {
  return updateDB(db, {
    notificationStatuses: db.notificationStatuses.map((_) => {
      if (_.vin === vin)
        return copy<NotificationStatus>(
          _,
          typeof newData === 'function' ? newData(_) : newData,
        );
      else return _;
    }),
  });
}

export async function addUser(
  db: DB,
  vin: string,
  userType: UserType = 'owner',
): Promise<SharedUser> {
  if (userType === 'owner') {
    let owner = db.owners.get(vin);
    if (owner === undefined) {
      owner = {
        sharedUserId: 'ownerUserId-1',
        localUserId: 'owner',
        userName: '恵比寿一郎',
        email: 'ichiro@daihatsu.com',
        userType,
      };
      db.owners.set(vin, owner);
      db = await updateDB(db, { owners: db.owners });
    }
    return owner;
  } else {
    const map = db.sharedUsers;
    const users = map.get(vin) ?? [];
    console.log('addUser', users);
    const i =
      users.length > 0
        ? parseInt(users[users.length - 1].sharedUserId.split('-')[1]) + 1
        : 1;

    const user = {
      sharedUserId: `sharedUserId-${i}`,
      localUserId: `localUserId-${i}`,
      userName: i % 2 === 0 ? '恵比寿太郎' : '恵比寿花子',
      email: i % 2 === 0 ? 'taro@daihatsu.com' : 'hanako@daihatsu.com',
      userType,
    };
    users.push(user);
    map.set(vin, users);
    db = await updateDB(db, { sharedUsers: map });
    return user;
  }
}
