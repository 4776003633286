import React from 'react';
import { theme } from '../../util/Theme';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import {
  openedVehicleImageWidth,
  vehicleImageHeight,
} from './CarImageContainer';
import { toRemoteStateList } from './Helpers';
import {
  AirConditionerItem,
  DoorLockItem,
  EngineItem,
  HazardWarningItem,
  LightItem,
} from './RemoteStateItem';
import { ControllerState, RemoteStates } from './Types';
import { VehicleInfoCardDataMap } from './VehicleInfoCard';

type DM = VehicleInfoCardDataMap;

export const defaultRemoteStates: RemoteStates = {
  doorLock: 'Normal',
  light: 'Normal',
  hazard: 'Normal',
  ac: 'Normal',
  engine: 'Normal',
};

export function RemoteStateList({
  parent,
  isOpen,
  ...props
}: Readonly<{
  parent: DataMapStore<DM>;
  remoteStates: RemoteStates;
  isOpen: boolean;
  onClick: (
    evt: React.MouseEvent,
    controllerState: ControllerState,
  ) => DataMapDispatch<WithContext<DM>>;
}>): React.ReactElement {
  const {
    views: { View },
  } = useParentStore({ parent });
  const isLoading = toRemoteStateList(props.remoteStates).some(
    ([_, remoteState]) =>
      remoteState === 'ToNormal' || remoteState === 'ToActive',
  );
  const style: OptionalStyles = isOpen
    ? {}
    : {
        display: 'none',
      };

  return (
    <View
      style={{
        width: Percentage(100),
        display: 'flex',
        justifyContent: 'space-between',
        ...style,
      }}
    >
      <View
        style={{
          width: vehicleImageHeight,
          height: openedVehicleImageWidth,
        }}
      />
      <View
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <DoorLockItem
          parent={parent}
          remoteState={props.remoteStates.doorLock}
          isLoading={isLoading}
          {...props}
        />
        <LightItem
          parent={parent}
          remoteState={props.remoteStates.light}
          isLoading={isLoading}
          {...props}
        />
        <HazardWarningItem
          parent={parent}
          remoteState={props.remoteStates.hazard}
          isLoading={isLoading}
          {...props}
        />
        {theme(
          <AirConditionerItem
            parent={parent}
            remoteState={props.remoteStates.ac}
            isLoading={isLoading}
            {...props}
          />,
          <EngineItem
            parent={parent}
            remoteState={props.remoteStates.engine}
            isLoading={isLoading}
            {...props}
          />,
        )}
      </View>
    </View>
  );
}
