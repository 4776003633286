import React from 'react';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import {
  DebugPaneActions,
  DebugPaneData,
  DebugPaneDataMap,
  DebugPaneDispatch,
} from './DebugPane';

type Data = DebugPaneData;

export function createDispatchOnClick(
  evt: React.MouseEvent,
  data: Data,
  disable: boolean | undefined = undefined,
  onClick: (() => DebugPaneDispatch) | undefined = undefined,
  dispatch: DebugPaneDispatch,
): DebugPaneDispatch {
  return dispatch
    .effect(() => evt.stopPropagation())
    .debug(
      DebugPaneActions.updateScrollTop,
      data.openedViewBody.getRaw().unwrap(
        (_) => _.scrollTop,
        () => 0,
      ),
    )
    .pipe((_) => (disable !== true && onClick ? _.compose(onClick()) : _));
}

export function Item({
  parent,
  icon,
  text,
  disable,
  onClick,
  onClickIcon,
}: Readonly<{
  parent: DataMapStore<DebugPaneDataMap>;
  icon: React.ReactNode;
  text: React.ReactNode;
  disable?: boolean;
  onClick?: () => DebugPaneDispatch;
  onClickIcon?: () => DebugPaneDispatch;
}>): React.ReactElement {
  const {
    views: { View },
    data: { debug: data },
    dispatch,
  } = useParentStore({ parent });

  return (
    <View
      style={{
        borderBottom: Border('solid', Length.px(1), Colors.background),
        padding: Length.px(5),
        display: 'flex',
        alignItems: 'center',
        color: disable === true ? Colors.disabledButtonText : Colors.buttonText,
      }}
      onClick={(evt) =>
        createDispatchOnClick(evt, data, disable, onClick, dispatch)
      }
    >
      <View
        style={{
          paddingRight: Length.px(5),
        }}
        onClick={(evt) =>
          onClick === undefined
            ? createDispatchOnClick(evt, data, disable, onClickIcon, dispatch)
            : dispatch
        }
      >
        {icon}
      </View>
      {text}
    </View>
  );
}
