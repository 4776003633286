import React from 'react';
import { HomeData } from '../home/Home';
import { IcButton } from '../icons/IcButton';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import { Store } from '../states/Store';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { getButtonState } from './Helpers';
import { noPermissionHeight } from './PermissionWarning';
import { GpsState, VehicleLocationState } from './VehicleLocationState';

const buttonWidth = Length.px(48);
const buttonHeight = Length.px(48);

export function ToDefaultButton({
  store,
}: Readonly<{
  store: Store<HomeData>;
}>): React.ReactElement {
  const {
    views,
    data: { parent: data, context },
    dispatch,
  } = useParentStore({ parent: { parent: store } });
  const View = views.default;
  const actions = VehicleLocationState.actions;
  const buttonState = getButtonState(data.localState.geofence, context);

  if (buttonState.default === true) {
    return (
      <View
        style={{
          display: 'inline-block',
          width: buttonWidth,
          height: buttonHeight,
        }}
        onClick={(evt) =>
          dispatch
            .effect(() => evt.stopPropagation())
            .parent(actions.updateCommand, 'PanToDefault')
        }
      >
        <IcButton
          image={Icons.btn_location_default}
          imageWidth={Percentage(100)}
          imageHeight={Percentage(100)}
        />
      </View>
    );
  } else {
    return <View />;
  }
}

export function ButtonContainer({
  store,
  gpsState,
}: Readonly<{
  store: Store<HomeData>;
  gpsState: GpsState;
}>): React.ReactElement {
  const {
    views,
    data: { parent: data, context },
  } = useParentStore<Readonly<{ parent: HomeData }>>({
    parent: { parent: store },
  });
  const View = views.default;
  const mapMode = data.localState.geofence.localState.mapMode;
  const viewPort = context.viewPort;

  if (mapMode === 'Open') {
    return (
      <View
        style={{
          position: 'absolute',
          display: 'inline-block',
          left: Length.px(viewPort.width - buttonWidth.value),
          top: Length.px(gpsState === 'Normal' ? 0 : noPermissionHeight.value),
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ToDefaultButton store={store} />
        </View>
      </View>
    );
  } else {
    return <View />;
  }
}
