import React, { useEffect, useState } from 'react';
import { GeoPos } from '../../apis/Geofence';
import { GoogleMap } from '../google/GoogleMap';
import { Icons } from '../icons/Icons';
import { useContextStore } from '../states/ContextStore';
import { Length } from '../style/Length';

export interface Props {
  id: string;
  center?: GeoPos;
}

interface MapData {
  map: google.maps.Map;
  incident: google.maps.Marker;
}

const defaultCenterValue = {
  lat: 34.8064091,
  lng: 135.4351402,
};

export const MapView: React.FC<Props> = (props) => {
  const {
    views: { View },
  } = useContextStore();

  const [mapData, setMapData] = useState<MapData>();
  useEffect(() => {
    GoogleMap(props.id, {
      center: defaultCenterValue,
      zoom: 10,
      clickableIcons: false,
      disableDefaultUI: true,
    }).then((map) => {
      const incident = new google.maps.Marker({
        position: props.center ?? defaultCenterValue,
        icon: Icons.ic_location_incident,
        map,
      });
      if (props.center) {
        map.setCenter(props.center);
      }
      setMapData({ map, incident });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    if (props.center && mapData) {
      const { map, incident } = mapData;
      map.setCenter(props.center);
      incident.setPosition(props.center);
    }
  }, [mapData, props.center]);

  return (
    <View
      id={props.id}
      style={{
        height: props.center == null ? Length.px(0) : Length.px(120),
        borderRadius: Length.px(8),
      }}
    />
  );
};
