import { Matrix3x3 } from '../../math/Matrix';

export interface Transform {
  matrix: Matrix3x3;
  toStyle: () => string;
}

function matrixToStyle(matrix: Matrix3x3): string {
  const args = [
    matrix.get(0, 0),
    matrix.get(1, 0),
    matrix.get(0, 1),
    matrix.get(1, 1),
    matrix.get(0, 2),
    matrix.get(1, 2),
  ].join(', ');
  return `matrix(${args})`;
}

export function Transform(matrix: Matrix3x3): Transform {
  const style = matrixToStyle(matrix);
  return {
    matrix,
    toStyle: () => style,
  };
}
