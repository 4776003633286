/* eslint-disable @typescript-eslint/no-explicit-any */
import { Jsonable } from './Json';
import { Failure, Result, Success } from './Result';

export interface Anomaly extends Jsonable<'Anomaly', AnomalyJson> {
  readonly code: number;
  readonly error: Error;
}

export interface AnomalyJson {
  readonly code: number;
  readonly message: string;
}

type Factory = {
  (code: number, error: Error | string): Anomaly;
};

type Statics = {
  readonly unknownError: Anomaly;
  readonly jsonParseError: Anomaly;
  readonly of: {
    (err: unknown): Anomaly;
    (err: unknown, defaultMsg: string): Anomaly;
  };
  readonly fromJson: (json: any) => Result<Anomaly>;
};

function create(code: number, error: Error | string): Anomaly {
  const json: AnomalyJson = {
    code,
    message: typeof error === 'string' ? error : error.message,
  };

  return {
    kind: 'Anomaly',
    code,
    error: typeof error === 'string' ? Error(error) : error,
    toJson: () => json,
  };
}

const statics: Statics = {
  unknownError: create(0, 'Unknown error'),
  jsonParseError: create(10001, 'JSON parse error'),

  of: (err: unknown, defaultMsg?: string) => {
    if (typeof err === 'string') return Anomaly(0, Error(err));
    else if (err instanceof Error) return Anomaly(0, err);
    else if ((err as any).kind === 'Anomaly') return err as Anomaly;
    else if (defaultMsg) return Anomaly(0, Error(defaultMsg));
    else return Anomaly(0, Error(JSON.stringify(err)));
  },

  fromJson: (json: any) => {
    try {
      if (json.code && json.message) {
        const a = json as AnomalyJson;
        return Success(create(a.code, a.message));
      } else {
        throw Error('Failed to decode json');
      }
    } catch (err) {
      return Failure(Anomaly.jsonParseError);
    }
  },
};

export const Anomaly: Factory & Statics = Object.assign(create, statics);

export function getErrorMessage(err: any): string {
  if (typeof err === 'string') {
    return err;
  }
  if (err instanceof Error) {
    return err.message;
  } else if (err.kind === 'Anomaly') {
    return `${err.error.message}(${err.code})`;
  } else if (err.resultData?.errorCode) {
    return err.resultData.errorCode;
  } else {
    return JSON.stringify(err);
  }
}
