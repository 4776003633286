import { Lazy } from '../util/Lazy';
import { Range } from '../util/Range';
import { Vector } from './Vector';

export interface Matrix {
  readonly get: (i: number, j: number) => number;
  readonly getNRows: () => number;
  readonly getNCols: () => number;
  readonly rangeRow: () => number[];
  readonly rangeCol: () => number[];
  readonly add: (m: Matrix) => Matrix;
  readonly subtract: (m: Matrix) => Matrix;
  readonly multiply: (a: number) => Matrix;
  readonly product: (m: Matrix) => Matrix;
  readonly equals: (m: Matrix) => boolean;
  readonly isEmpty: () => boolean;
  readonly toArray: () => number[][];
  readonly to3x1: () => Matrix3x1;
  readonly to3x3: () => Matrix3x3;
}

export interface Matrix3x3 extends Matrix {
  readonly determinant: () => number;
  readonly inverse: () => Matrix3x3;
  readonly getX: () => number;
  readonly getY: () => number;
}

interface Matrix3x3Static {
  readonly unit: Matrix3x3;
  readonly rotation: (radian: number) => Matrix3x3;
  readonly scale: (x: number, y: number) => Matrix3x3;
  readonly translate: (x: number, y: number) => Matrix3x3;
}

interface Matrix3x3Factory {
  (
    a00: number,
    a01: number,
    a02: number,
    a10: number,
    a11: number,
    a12: number,
    a20: number,
    a21: number,
    a22: number,
  ): Matrix3x3;
}

export interface Matrix3x1 extends Matrix {
  readonly toVector: () => Vector;
}

interface Matrix3x1Static {
  readonly fromVector: (v: Vector) => Matrix3x1;
}

interface Matrix3x1Factory {
  (a00: number, a10: number, a20: number): Matrix3x1;
}

function fromArray(xs: number[][]): Matrix {
  const nrows = Lazy(() => xs.length);
  const ncols = Lazy(() => xs[0].length);
  const rangeRow = Lazy(() => Range(0, self.getNRows() - 1));
  const rangeCol = Lazy(() => Range(0, self.getNCols() - 1));
  const m3x1 = Lazy(() => fromArray3x1(xs));
  const m3x3 = Lazy(() => fromArray3x3(xs));

  const self: Matrix = {
    get: (i: number, j: number) => xs[i][j],

    getNRows: nrows.get,

    getNCols: ncols.get,

    rangeRow: rangeRow.get,

    rangeCol: rangeCol.get,

    add: (m: Matrix) =>
      fromArray(
        self.rangeRow().map((i) =>
          self.rangeCol().map((j) => {
            return self.get(i, j) + m.get(i, j);
          }),
        ),
      ),

    subtract: (m: Matrix) =>
      fromArray(
        self.rangeRow().map((i) =>
          self.rangeCol().map((j) => {
            return self.get(i, j) - m.get(i, j);
          }),
        ),
      ),

    multiply: (a: number) =>
      fromArray(
        self
          .rangeRow()
          .map((i) => self.rangeCol().map((j) => self.get(i, j) * a)),
      ),

    product: (m: Matrix) =>
      fromArray(
        self
          .rangeRow()
          .map((k) =>
            m
              .rangeCol()
              .map((i) =>
                self
                  .rangeCol()
                  .reduce((acc, j) => acc + self.get(k, j) * m.get(j, i), 0),
              ),
          ),
      ),

    equals: (m: Matrix) =>
      self
        .rangeRow()
        .every((i) =>
          self.rangeCol().every((j) => self.get(i, j) === m.get(i, j)),
        ),

    isEmpty: () =>
      Lazy(() =>
        self
          .rangeRow()
          .every((i) => self.rangeCol().every((j) => self.get(i, j) === 0)),
      ).get(),

    toArray: () => xs,

    to3x1: m3x1.get,

    to3x3: m3x3.get,
  };
  return self;
}

function fromArray3x3(a: number[][]): Matrix3x3 {
  const determinant = Lazy(
    () =>
      a[0][0] * a[1][1] * a[2][2] +
      a[0][1] * a[1][2] * a[2][0] +
      a[0][2] * a[1][0] * a[2][1] -
      a[0][2] * a[1][1] * a[2][0] -
      a[0][0] * a[1][2] * a[2][1] -
      a[0][1] * a[1][0] * a[2][2],
  );

  const inverse = Lazy(() =>
    fromArray3x3(
      create3x3(
        a[1][1] * a[2][2] - a[1][2] * a[2][1],
        a[0][2] * a[2][1] - a[0][1] * a[2][2],
        a[0][1] * a[1][2] - a[0][2] * a[1][1],

        a[1][2] * a[2][0] - a[1][0] * a[2][2],
        a[0][0] * a[2][2] - a[0][2] * a[2][0],
        a[0][2] * a[1][0] - a[0][0] * a[1][2],

        a[1][0] * a[2][1] - a[1][1] * a[2][0],
        a[0][1] * a[2][0] - a[0][0] * a[2][1],
        a[0][0] * a[1][1] - a[0][1] * a[1][0],
      )
        .multiply(1 / self.determinant())
        .toArray(),
    ),
  );

  const self: Matrix3x3 = {
    ...fromArray(a),
    determinant: determinant.get,
    inverse: inverse.get,
    getX: () => self.get(0, 2),
    getY: () => self.get(1, 2),
  };
  return self;
}

function create3x3(
  a00: number,
  a01: number,
  a02: number,
  a10: number,
  a11: number,
  a12: number,
  a20: number,
  a21: number,
  a22: number,
): Matrix3x3 {
  return fromArray3x3([
    [a00, a01, a02],
    [a10, a11, a12],
    [a20, a21, a22],
  ]);
}

const statics3x3: Matrix3x3Static = {
  unit: create3x3(1, 0, 0, 0, 1, 0, 0, 0, 1),

  rotation: (radian: number) => {
    const cos = Math.cos(radian);
    const sin = Math.sin(radian);
    return create3x3(cos, -sin, 0, sin, cos, 0, 0, 0, 1);
  },

  scale: (x: number, y: number) => {
    return create3x3(x, 0, 0, 0, y, 0, 0, 0, 1);
  },

  translate: (x: number, y: number) => {
    return create3x3(1, 0, x, 0, 1, y, 0, 0, 1);
  },
};

export const Matrix3x3: Matrix3x3Factory & Matrix3x3Static = Object.assign(
  create3x3,
  statics3x3,
);

function fromArray3x1(a: number[][]): Matrix3x1 {
  const toVector = Lazy(() => Vector(a[0][0], a[1][0]));
  return {
    ...fromArray(a),
    toVector: toVector.get,
  };
}

function create3x1(a00: number, a10: number, a20: number): Matrix3x1 {
  return fromArray3x1([[a00], [a10], [a20]]);
}

const statics3x1: Matrix3x1Static = {
  fromVector: (v: Vector) => {
    return create3x1(v.x, v.y, 1);
  },
};

export const Matrix3x1: Matrix3x1Factory & Matrix3x1Static = Object.assign(
  create3x1,
  statics3x1,
);
