import { GeoPos, Geofence } from '../../apis/Geofence';
import { UserLocation } from '../../apis/NativeApi';
import { ToOptional, copy } from '../../util/Copyable';
import { Distance } from '../../util/Distance';
import { Sender } from '../../util/Listener';
import { Option, Some } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Result } from '../../util/Result';
import { Unit } from '../../util/Unit';
import { CardState } from '../components/Card';
import { CardHeader } from '../components/CardHeader';
import { State as HelpDialogState } from '../components/HelpDialog';
import { SlideInCardState } from '../components/SlideInCard';
import { HomeData } from '../home/Home';
import { CommandActions, CommandState } from '../states/CommandState';
import { ViewPort } from '../states/Context';
import { Bound } from '../style/Bound';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { getGPSState } from './Helpers';
import { menuPaneHeight } from './MenuPane';
import { noPermissionHeight } from './PermissionWarning';
import {
  Command,
  GoogleMapState,
  RenderMap,
  VehicleLocationData,
  defaultRadius,
  vehicleLocationCardHeight,
} from './VehicleLocationState';

type Data = HomeData;

export interface Actions extends CommandActions<Data, Command> {
  readonly updateContentLoaded: (data: Data, contentLoaded: boolean) => Data;
  readonly updateUserLocation: (
    data: Data,
    userLocation: 'Loading' | Result<UserLocation>,
  ) => Data;
  readonly updateUserLocationStopper: (
    data: Data,
    userLocationStopper: Option<Sender<Unit>>,
  ) => Data;
  readonly updateHasContentLoadedListener: (
    data: Data,
    hasContentLoadedListener: boolean,
  ) => Data;
  readonly updateOpenState: (data: Data, cardState: CardState) => Data;
  readonly updateGoogleMapState: (
    data: Data,
    googleMapState: GoogleMapState,
  ) => Data;
  readonly onOpen: (data: Data, viewPort: ViewPort) => Data;
  readonly onClose: (data: Data) => Data;
  readonly onClosed: (data: Data) => Data;
  readonly onSettingPaneOpen: (data: Data, geofence: Geofence) => Data;
  readonly onSettingPaneOpened: (
    data: Data,
    args: { viewPort: ViewPort; settingPaneBound: Bound },
  ) => Data;
  readonly onSettingPaneClose: (data: Data, viewPort: ViewPort) => Data;
  readonly updateGeofenceCacheCenter: (data: Data) => Data;
  readonly updateGeofenceCacheRadius: (data: Data, radius: Distance) => Data;
  readonly updateSettingPaneState: (
    data: Data,
    settingPaneState: SlideInCardState,
  ) => Data;
  readonly updateHelpDialogState: (
    data: Data,
    helpDialogState: HelpDialogState,
  ) => Data;
  readonly updateTrackingPaneState: (
    data: Data,
    trackingPaneState: SlideInCardState,
  ) => Data;
  readonly updateIsTrackingSlideButtonSpinning: (
    data: Data,
    isTrackingSlideButtonSpinning: boolean,
  ) => Data;
}

function update(data: Data, target: ToOptional<VehicleLocationData>): Data {
  return copy(data, {
    localState: {
      geofence: target,
    },
  });
}

export function createActions(
  commandState: CommandState<Data, Command>,
): Actions {
  const actions: Actions = {
    ...commandState.actions,

    updateContentLoaded: (data: Data, contentLoaded: boolean) =>
      update(data, {
        localState: { contentLoaded },
      }),

    updateUserLocation: (
      data: Data,
      userLocation: 'Loading' | Result<UserLocation>,
    ) =>
      update(data, {
        localState: { userLocation },
      }),

    updateUserLocationStopper: (
      data: Data,
      userLocationStopper: Option<Sender<Unit>>,
    ) =>
      update(data, {
        localState: { userLocationStopper },
      }),

    updateHasContentLoadedListener: (
      data: Data,
      hasContentLoadedListener: boolean,
    ) =>
      update(data, {
        localState: { hasContentLoadedListener },
      }),

    updateOpenState: (data: Data, cardState: CardState) =>
      update(data, {
        localState: { cardState },
      }),

    updateGoogleMapState: (data: Data, googleMapState: GoogleMapState) =>
      update(data, {
        localState: { googleMapState },
      }),

    onOpen: (data: Data, viewPort: ViewPort) =>
      update(data, {
        localState: {
          cardState: 'Opening',
          mapMode: 'Open',
          command: RenderMap(),
        },
        container: data.localState.geofence.container.updateStyles({
          width: Percentage(100),
          height: Length.px(
            viewPort.height -
              CardHeader.height.value -
              menuPaneHeight.value -
              (getGPSState(data.localState.geofence.localState.userLocation) ===
              'Normal'
                ? 0
                : noPermissionHeight.value),
          ),
        }),
      }),

    onClose: (data: Data) =>
      update(data, {
        localState: {
          cardState: 'Closing',
          mapMode: 'Close',
        },
        container: data.localState.geofence.container.updateStyles({
          width: Percentage(100),
          height: vehicleLocationCardHeight,
        }),
      }),

    onClosed: (data: Data) => {
      return update(data, {
        localState: {
          cardState: 'Closed',
          command: RenderMap(),
        },
      });
    },

    onSettingPaneOpen: (data: Data, geofenceCache: Geofence) =>
      Pipe(data)
        .map((data) =>
          update(data, {
            localState: {
              mapMode: 'Geofence',
              command: RenderMap(),
              geofenceCache: Some(geofenceCache),
            },
          }),
        )
        .get(),

    onSettingPaneOpened: (data: Data, { viewPort, settingPaneBound }) =>
      update(data, {
        container: data.localState.geofence.container.updateStyles({
          height: Length.px(
            viewPort.height - CardHeader.height.value - settingPaneBound.height,
          ),
        }),
      }),

    onSettingPaneClose: (data: Data, viewPort: ViewPort) =>
      Pipe(data)
        .map((data) =>
          update(data, {
            localState: {
              mapMode: 'Open',
              command: RenderMap(),
              settingPaneState: 'Hide',
            },
            container: data.localState.geofence.container.updateStyles({
              height: Length.px(
                viewPort.height -
                  CardHeader.height.value -
                  menuPaneHeight.value -
                  (getGPSState(
                    data.localState.geofence.localState.userLocation,
                  ) === 'Normal'
                    ? 0
                    : noPermissionHeight.value),
              ),
            }),
          }),
        )
        .get(),

    updateGeofenceCacheCenter: (data: Data) => {
      if (data.localState.geofence.localState.mapMode === 'Geofence') {
        return data.localState.geofence.localState.mapData.unwrap(
          (mapData) => {
            const mapCenter = mapData.googleMap.getCenter();
            if (mapCenter === undefined) {
              return data;
            }
            const center: GeoPos = {
              lat: mapCenter.lat(),
              lng: mapCenter.lng(),
            };
            const geofenceCache: Option<Geofence> =
              data.localState.geofence.localState.geofenceCache.unwrap(
                (g) => Some({ center, radius: g.radius }),
                () => Some({ center, radius: defaultRadius }),
              );
            return update(data, {
              localState: {
                geofenceCache,
                command: 'RenderGeofence',
              },
            });
          },
          () => data,
        );
      } else {
        return data;
      }
    },

    updateGeofenceCacheRadius: (data: Data, radius: Distance) => {
      const geofenceCache: Option<Geofence> =
        data.localState.geofence.localState.geofenceCache.map((_) => ({
          center: _.center,
          radius,
        }));
      return update(data, {
        localState: {
          geofenceCache,
          command: 'AdjustGeofenceZoom',
        },
      });
    },

    updateSettingPaneState: (data: Data, settingPaneState: SlideInCardState) =>
      update(data, {
        localState: { settingPaneState },
      }),

    updateHelpDialogState: (data: Data, helpDialogState: HelpDialogState) =>
      update(data, {
        localState: { helpDialogState },
      }),

    updateTrackingPaneState: (
      data: Data,
      trackingPaneState: SlideInCardState,
    ) =>
      update(data, {
        localState: { trackingPaneState },
      }),

    updateIsTrackingSlideButtonSpinning: (
      data: Data,
      isTrackingSlideButtonSpinning: boolean,
    ) =>
      update(data, {
        localState: { isTrackingSlideButtonSpinning },
      }),
  };
  return actions;
}
