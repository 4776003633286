// userId: 'Yuusuke_Oomori@dk.daihatsu.co.jp'
// password: 'dmcdmcdmc'
// deviceId: 'MldsagH1DS5Pkd1ZmdGLFTJGTQjgci7v'
import * as Client from '@auto/monaka-client/dist/20';
import { config } from '../Config';
import { getDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { getErrorMessage } from '../util/Error';
import { Region } from '../util/Locale';
import { Option } from '../util/Option';
import { sleep } from '../util/Sleep';
import { Unit } from '../util/Unit';
import { getAuthInfo, setAuthInfo } from './NativeApi';

if (BuildOpt.env === 'mock') {
  Client.Auth.configure({
    signInUrl: `${window.location.href}login`,
    authorizationUrl: `${window.location.href}credentials`,
    durationSeconds: 36000,
  });
} else {
  Client.Auth.configure({
    signInUrl: `${config[BuildOpt.env].authEndpoint}/login`,
    authorizationUrl: `${config[BuildOpt.env].authEndpoint}/credentials`,
    durationSeconds: 36000,
  });
}

export type AuthInfo = Client.AuthInfo;

const emptyAuthInfo: AuthInfo = {
  refreshToken: '',
  driver: {
    accessToken: '',
    identityId: '',
    gSysUserId: '',
  },
  email: '',
  userId: '',
  locale: '',
};

export function isValidAuthInfo(authInfo: unknown): boolean {
  const _authInfo = authInfo as { readonly [key: string]: string };
  return Object.keys(emptyAuthInfo).every(
    (key) => _authInfo[key] !== undefined,
  );
}

export function onBackUp(
  f: (authInfo: AuthInfo | null) => Promise<void>,
): void {
  if (BuildOpt.isMock()) return;
  else return Client.Auth.onBackUp(f);
}

export function onRestore(f: () => Promise<AuthInfo | null>): Promise<void> {
  if (BuildOpt.isMock()) return Promise.resolve();
  else return Client.Auth.onRestore(f);
}

export function isSignedIn(): Promise<boolean> {
  if (BuildOpt.isMock()) {
    return getAuthInfo().then((_) =>
      _.some((_) => {
        const authInfo = _ as unknown as { isSignedIn: boolean };
        return authInfo.isSignedIn;
      }),
    );
  } else {
    return Client.Auth.currentDriver().then((_) => _ !== null);
  }
}

export async function signIn(
  userId: string,
  password: string,
  region: Region,
): Promise<Unit> {
  if (BuildOpt.isMock()) {
    await sleep(1000);
    const db = await getDB();
    if (db.loginError) return Promise.reject(Error('Login error'));
    else
      return setAuthInfo({
        ...emptyAuthInfo,
        isSignedIn: true,
      } as unknown as AuthInfo).then((_) => Unit);
  } else {
    return Client.Auth.signIn({
      user: { userId, password, deviceId: 'hoge', locale: region },
    })
      .then((_) => Unit)
      .catch((err) => {
        const msg = Error(
          `Failed to sign-in as ${userId}. ${getErrorMessage(err)}`,
        );
        return Promise.reject(msg);
      });
  }
}

export function signOut(): void {
  if (BuildOpt.isMock()) {
    setAuthInfo({
      ...emptyAuthInfo,
      isSignedIn: false,
    } as unknown as AuthInfo).then((_) => Unit);
  } else {
    Client.Auth.signOut();
  }
}
export async function authorizeAppSync(): Promise<Unit> {
  if (BuildOpt.isMock()) {
    await sleep(1000);
    return Unit;
  } else {
    return Option(await Client.Auth.currentDriver())
      .map((driver) =>
        Client.Auth.authorize({
          driver,
          scope: Client.Scope.Mobile,
        })
          .then((_) => Unit)
          .catch((err) => {
            const msg = Error(
              `Failed to authorize AppSync. ${getErrorMessage(err)}`,
            );
            return Promise.reject(msg);
          }),
      )
      .getOrElse(() => Promise.reject(Error('Could not find driver')));
  }
}

export async function authorizeIoT(dcmId: string): Promise<Unit> {
  if (BuildOpt.isMock()) {
    await sleep(1000);
    return Unit;
  } else {
    return Option(await Client.Auth.currentDriver())
      .map((driver) => {
        driver.dcmId = dcmId;
        return Client.Auth.authorize({
          driver,
          scope: Client.Scope.Iot,
        }).then((_) => Unit);
      })
      .getOrElse(() => Promise.reject(Error('Could not find driver')));
  }
}
