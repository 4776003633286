import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import React from 'react';
import {
  NativeCustomEventName,
  ReactNativeWindow,
  getDeviceToken,
  getLatestVehicleLocationTable,
  setAuthInfo,
} from '../../apis/NativeApi';
import { copy } from '../../util/Copyable';
import { None, Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import { Shape } from '../components/Shape';
import { HomeData } from '../home/Home';
import { EventId } from '../i18n/Types';
import { useContextStore, useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Store } from '../states/Store';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Time } from '../style/Time';
import { Transition } from '../style/Transition';
import { BackendDataPane } from './BackendDataPane';
import { DB, clearDB, defaultDB, getDB } from './Database';
import { DummyDataPane } from './DummyDataPane';
import { ErrorsPane } from './ErrorsPane';
import { DataError } from './Helpers';
import { InformationPane } from './InformationPane';
import { MockDevice } from './MockDevice';
import { NativeSettingsPane } from './NativeSettingsPane';
import { NotificationsPane } from './Notifications';

export type DebugPaneData = Data;
export type DebugPaneDataMap = DM;
export type DebugPaneDispatch = DataMapDispatch<WithContext<DebugPaneDataMap>>;

type Data = Readonly<{
  state: State;
  dataState: DataState;
  db: DB;
  hasLatestVehicleLocation: boolean;
  userLocationPermission: boolean;
  userLocationEnable: boolean;
  userLocationError: boolean;
  openedViewBody: Shape;
  scrollTop: number;
  deviceToken: Option<string>;
  eventType1: Readonly<{
    eventId: EventId;
    eventName: string;
    isAppActive: boolean;
    isSelectedVehicle: boolean;
  }>;
}>;

type Actions = Readonly<{
  updateState: (data: Data, state: State) => Data;
  updateDataState: (data: Data, dataState: DataState) => Data;
  updateDB: (data: Data, db: DB) => Data;
  updateHasLatestVehicleLocation: (
    data: Data,
    hasLatestVehicleLocation: boolean,
  ) => Data;
  updateUserLocationPermission: (
    data: Data,
    userLocationPermission: boolean,
  ) => Data;
  updateUserLocationEnable: (data: Data, userLocationEnable: boolean) => Data;
  updateUserLocationError: (data: Data, userLocationError: boolean) => Data;
  updateScrollTop: (data: Data, scrollTop: number) => Data;
  updateDeviceToken: (data: Data, deviceToken: Option<string>) => Data;
  render: (data: Data) => Data;
}>;

type Key = 'debug';
type State = 'Open' | 'Close';
type DataState = 'Initialize' | 'Loading' | 'Loaded' | DataError;

type PM = Readonly<{
  home: HomeData;
}>;
type DM = Readonly<{
  home: HomeData;
  debug: Data;
}>;

type Props = Readonly<{
  parent: Store<HomeData>;
}>;

export type ParentProps = Readonly<{
  parent: DataMapStore<DM>;
}>;

function ClosedView({ parent }: ParentProps): React.ReactElement {
  const {
    views: { View },
    dispatch,
  } = useParentStore({ parent });

  return (
    <>
      {ReactNativeWindow.isOnMockDevice ? (
        <View
          style={{
            width: Length.px(20),
            height: Length.px(20),
            backgroundColor: Colors.mockBackground,
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            window.dispatchEvent(
              new CustomEvent(NativeCustomEventName, {
                detail: {
                  data: {
                    target: 'action',
                    action: 'back',
                  },
                },
              }),
            );
            return dispatch;
          }}
        >
          <ArrowBackIcon style={{ fontSize: 20 }} />
        </View>
      ) : (
        <></>
      )}
      <View
        style={{
          width: Length.px(20),
          height: Length.px(20),
          backgroundColor: Colors.mockBackground,
          transition: [
            Transition('width', Time.ms(300)),
            Transition('height', Time.ms(300)),
            Transition('backgroundColor', Time.ms(300)),
          ],
        }}
        onClick={(evt) =>
          dispatch
            .effect(() => evt.stopPropagation())
            .debug(actions.updateState, 'Open')
            .debug(actions.updateDataState, 'Initialize')
        }
      >
        <SettingsIcon style={{ fontSize: 20 }} />
      </View>
    </>
  );
}

function ErrorView({
  error,
}: Readonly<{
  error: string;
}>): React.ReactElement {
  const {
    views: { View },
  } = useContextStore();

  return (
    <View
      style={{
        color: Colors.buttonText,
        backgroundColor: Colors.mockBackgroundDark,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {error}
    </View>
  );
}

function LoadingView(): React.ReactElement {
  const {
    views: { View },
  } = useContextStore();

  return (
    <View
      style={{
        color: Colors.buttonText,
        backgroundColor: Colors.mockBackgroundDark,
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <AutorenewIcon />
    </View>
  );
}

function OpenedBody({ parent }: ParentProps): React.ReactElement {
  const { views } = useParentStore({ parent });
  const OpenedViewBody = views.debug.openedViewBody;

  return (
    <OpenedViewBody
      style={{
        flexGrow: 1,
        overflow: 'scroll',
      }}
    >
      <InformationPane parent={parent} />
      <DummyDataPane parent={parent} />
      <BackendDataPane parent={parent} />
      <ErrorsPane parent={parent} />
      <NotificationsPane parent={parent} />
      <NativeSettingsPane parent={parent} />
    </OpenedViewBody>
  );
}

const actions: Actions = {
  updateState: (data: Data, state: State) => copy(data, { state }),
  updateDataState: (data: Data, dataState: DataState) =>
    copy(data, { dataState }),
  updateDB: (data: Data, db: DB) => copy(data, { db }),
  updateHasLatestVehicleLocation: (
    data: Data,
    hasLatestVehicleLocation: boolean,
  ) => copy(data, { hasLatestVehicleLocation }),
  updateUserLocationPermission: (data: Data, userLocationPermission: boolean) =>
    copy(data, { userLocationPermission }),
  updateUserLocationEnable: (data: Data, userLocationEnable: boolean) =>
    copy(data, { userLocationEnable }),
  updateUserLocationError: (data: Data, userLocationError: boolean) =>
    copy(data, { userLocationError }),
  updateScrollTop: (data: Data, scrollTop: number) => copy(data, { scrollTop }),
  updateDeviceToken: (data: Data, deviceToken: Option<string>) =>
    copy(data, { deviceToken }),
  render: (data: Data) => data,
};

export const DebugPaneActions = actions;

export function DebugPane(props: Props): React.ReactElement {
  const state = useStore<Key, Data, PM, Props>({
    key: 'debug',
    props,
    parent: { home: props.parent },

    default: () => ({
      state: 'Close',
      dataState: 'Initialize',
      db: defaultDB,
      hasLatestVehicleLocation: false,
      userLocationPermission: true,
      userLocationEnable: true,
      userLocationError: false,
      openedViewBody: Shape({}),
      scrollTop: 0,
      deviceToken: None(),
      eventType1: {
        eventId: '001',
        eventName: 'airbagAlert',
        isAppActive: true,
        isSelectedVehicle: true,
      },
    }),

    update: ({ data: { context, debug: data }, dispatch }) =>
      dispatch
        .effect(() =>
          data.openedViewBody
            .getRaw()
            .forEach((_) => (_.scrollTop = data.scrollTop)),
        )
        .pipe((_) =>
          data.dataState === 'Initialize'
            ? _.debug(actions.updateDataState, 'Loading')
                .asyncAll(
                  Scope(async () => {
                    const db = await getDB();
                    const hasVehicleLocation =
                      await context.selectedVehicleVin.unwrap(
                        async (vin) => {
                          const table = await getLatestVehicleLocationTable();
                          return !table.get(vin).isNone();
                        },
                        async () => false,
                      );
                    const deviceToken = await getDeviceToken();
                    return dispatch
                      .debug(
                        actions.updateHasLatestVehicleLocation,
                        hasVehicleLocation,
                      )
                      .debug(actions.updateDeviceToken, deviceToken)
                      .debug(actions.updateDataState, 'Loaded')
                      .debug(actions.updateDB, db);
                  }),
                )
                .pipe((_) =>
                  ReactNativeWindow.isOnMockDevice
                    ? _.asyncAll(
                        Scope(async () => {
                          const mockDevice = MockDevice.get();
                          const permission =
                            await mockDevice.getLocationPermission();
                          const enable = await mockDevice.getLocationEnable();
                          const error = await mockDevice.getLocationError();
                          return dispatch
                            .debug(
                              actions.updateUserLocationPermission,
                              permission,
                            )
                            .debug(actions.updateUserLocationEnable, enable)
                            .debug(actions.updateUserLocationError, error);
                        }),
                      )
                    : _,
                )
            : _,
        ),
  });

  const views = state.views;
  const View = views.default;
  const Header = View;
  const context = state.data.context;
  const data = state.data.debug;
  const viewPort = context.viewPort;
  const dispatch = state.dispatch;

  const OpenedView = (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: Length.px(viewPort.width),
        height: Length.px(viewPort.height),
        color: Colors.buttonText,
        backgroundColor: Colors.mockBackgroundDark,
        transition: [
          Transition('width', Time.ms(300)),
          Transition('height', Time.ms(300)),
          Transition('backgroundColor', Time.ms(300)),
        ],
        overflow: 'scroll',
      }}
    >
      <Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: Length.px(10),
          borderBottom: Border('solid', Length.px(1), Colors.background),
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              paddingLeft: Length.px(5),
              paddingRight: Length.px(5),
            }}
            onClick={(evt) => {
              evt.stopPropagation();
              clearDB();
              setAuthInfo(null);
              return dispatch.debug(actions.render, Unit);
            }}
          >
            <DeleteIcon />
          </View>
        </View>

        <View
          onClick={(evt) => {
            evt.stopPropagation();
            return dispatch.debug(actions.updateState, 'Close');
          }}
        >
          <CloseIcon />
        </View>
      </Header>

      {Scope(() => {
        switch (data.dataState) {
          case 'Initialize':
          case 'Loading':
            return <LoadingView />;
          case 'Loaded':
            return <OpenedBody parent={state.stores} />;
          default:
            return <ErrorView error={data.dataState.message} />;
        }
      })}
    </View>
  );

  return (
    <View
      style={{
        position: 'fixed',
        bottom: Length.px(
          data.state === 'Close' ? context.screenSize.marginBottom : 0,
        ),
        right: Length.px(0),
      }}
    >
      {Scope(() => {
        switch (data.state) {
          case 'Close':
            return <ClosedView parent={state.stores} />;
          case 'Open':
            return OpenedView;
        }
      })}
    </View>
  );
}
