export type GridLine = {
  readonly toStyle: () => string;
};
type GridLineItem = 'auto' | number | ['span', number];

function gridLineItemToString(item: GridLineItem): string {
  return `${item instanceof Array ? item.join(' ') : item}`;
}

export function GridLine(item1: GridLineItem, item2?: GridLineItem): GridLine {
  return {
    toStyle: () => {
      if (item2) {
        return `${gridLineItemToString(item1)} / ${gridLineItemToString(
          item2,
        )}`;
      } else {
        return gridLineItemToString(item1);
      }
    },
  };
}
