import { None, Option, Some } from '../../util/Option';
import { EventId, EventType, PrefixId, RawNotificationId } from './Types';

export type EventIdType = {
  readonly id: Option<EventId>;
  readonly prefixId: PrefixId;
  readonly type: EventType;
};

export type EventIdTypeMap = {
  readonly get: (key: RawNotificationId) => Option<EventIdType>;
};

const unsafeEventIdTypeMap = new Map<RawNotificationId, EventIdType>();
unsafeEventIdTypeMap.set('completeCarRegistrationTitle', {
  id: None<EventId>(),
  prefixId: 'Event_completeCarRegistration',
  type: 11,
});
unsafeEventIdTypeMap.set('completeCarRegistrationBody', {
  id: None<EventId>(),
  prefixId: 'Event_completeCarRegistration',
  type: 11,
});
unsafeEventIdTypeMap.set('waitingForCarRegistrationTitle', {
  id: None<EventId>(),
  prefixId: 'Event_waitingForCarRegistration',
  type: 12,
});
unsafeEventIdTypeMap.set('waitingForCarRegistrationBody', {
  id: None<EventId>(),
  prefixId: 'Event_waitingForCarRegistration',
  type: 12,
});
unsafeEventIdTypeMap.set('addSharedUserTitle', {
  id: None<EventId>(),
  prefixId: 'Event_addSharedUser',
  type: 11,
});
unsafeEventIdTypeMap.set('addSharedUserBody', {
  id: None<EventId>(),
  prefixId: 'Event_addSharedUser',
  type: 11,
});
unsafeEventIdTypeMap.set('sosCallManualTitle', {
  id: Some<EventId>('1051'),
  prefixId: 'Event_sosCallManual',
  type: 13,
});
unsafeEventIdTypeMap.set('sosCallManualBody', {
  id: Some<EventId>('1051'),
  prefixId: 'Event_sosCallManual',
  type: 13,
});
unsafeEventIdTypeMap.set('sosCallAutoTitle', {
  id: Some<EventId>('1050'),
  prefixId: 'Event_sosCallAuto',
  type: 13,
});
unsafeEventIdTypeMap.set('sosCallAutoBody', {
  id: Some<EventId>('1050'),
  prefixId: 'Event_sosCallAuto',
  type: 13,
});
unsafeEventIdTypeMap.set('lowDriveBatteryAlert3Title', {
  id: Some<EventId>('619'),
  prefixId: 'Event_lowDriveBatteryAlert3',
  type: 1,
});
unsafeEventIdTypeMap.set('lowDriveBatteryAlert3Body', {
  id: Some<EventId>('619'),
  prefixId: 'Event_lowDriveBatteryAlert3',
  type: 1,
});
unsafeEventIdTypeMap.set('lowDriveBatteryAlert2Title', {
  id: Some<EventId>('618'),
  prefixId: 'Event_lowDriveBatteryAlert2',
  type: 1,
});
unsafeEventIdTypeMap.set('lowDriveBatteryAlert2Body', {
  id: Some<EventId>('618'),
  prefixId: 'Event_lowDriveBatteryAlert2',
  type: 1,
});
unsafeEventIdTypeMap.set('lowDriveBatteryAlert1Title', {
  id: Some<EventId>('617'),
  prefixId: 'Event_lowDriveBatteryAlert1',
  type: 1,
});
unsafeEventIdTypeMap.set('lowDriveBatteryAlert1Body', {
  id: Some<EventId>('617'),
  prefixId: 'Event_lowDriveBatteryAlert1',
  type: 1,
});
unsafeEventIdTypeMap.set('backSensorAlertTitle', {
  id: Some<EventId>('515'),
  prefixId: 'Event_backSensorAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('backSensorAlertBody', {
  id: Some<EventId>('515'),
  prefixId: 'Event_backSensorAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cornerSensorFrontAlertTitle', {
  id: Some<EventId>('514'),
  prefixId: 'Event_cornerSensorFrontAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cornerSensorFrontAlertBody', {
  id: Some<EventId>('514'),
  prefixId: 'Event_cornerSensorFrontAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cornerSensorRearAlertTitle', {
  id: Some<EventId>('513'),
  prefixId: 'Event_cornerSensorRearAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cornerSensorRearAlertBody', {
  id: Some<EventId>('513'),
  prefixId: 'Event_cornerSensorRearAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cornerSensorAllAlertTitle', {
  id: Some<EventId>('512'),
  prefixId: 'Event_cornerSensorAllAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cornerSensorAllAlertBody', {
  id: Some<EventId>('512'),
  prefixId: 'Event_cornerSensorAllAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('acousticVehicleSystemAlertTitle', {
  id: Some<EventId>('511'),
  prefixId: 'Event_acousticVehicleSystemAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('acousticVehicleSystemAlertBody', {
  id: Some<EventId>('511'),
  prefixId: 'Event_acousticVehicleSystemAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('bsmAlertTitle', {
  id: Some<EventId>('510'),
  prefixId: 'Event_bsmAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('bsmAlertBody', {
  id: Some<EventId>('510'),
  prefixId: 'Event_bsmAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('lkcSystemAlertTitle', {
  id: Some<EventId>('508'),
  prefixId: 'Event_lkcSystemAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('lkcSystemAlertBody', {
  id: Some<EventId>('508'),
  prefixId: 'Event_lkcSystemAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('accBreakAlertTitle', {
  id: Some<EventId>('410'),
  prefixId: 'Event_accBreakAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('accBreakAlertBody', {
  id: Some<EventId>('410'),
  prefixId: 'Event_accBreakAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('rearDiffLockAlertTitle', {
  id: Some<EventId>('232'),
  prefixId: 'Event_rearDiffLockAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('rearDiffLockAlertBody', {
  id: Some<EventId>('232'),
  prefixId: 'Event_rearDiffLockAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('dummyAirbagAlertTitle', {
  id: Some<EventId>('9999'),
  prefixId: 'Event_dummyAirbagAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('dummyAirbagAlertBody', {
  id: Some<EventId>('9999'),
  prefixId: 'Event_dummyAirbagAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('remoteCmdFailedConditionNotSatisfiedTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdFailedConditionNotSatisfied',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdFailedConditionNotSatisfiedBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdFailedConditionNotSatisfied',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdAcceptedTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdAccepted',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdAcceptedBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdAccepted',
  type: 12,
});
unsafeEventIdTypeMap.set('commonNetworkErrorTitle', {
  id: None<EventId>(),
  prefixId: 'Event_commonNetworkError',
  type: 12,
});
unsafeEventIdTypeMap.set('commonNetworkErrorBody', {
  id: None<EventId>(),
  prefixId: 'Event_commonNetworkError',
  type: 12,
});
unsafeEventIdTypeMap.set('getGpsErrorTitle', {
  id: None<EventId>(),
  prefixId: 'Event_getGpsError',
  type: 12,
});
unsafeEventIdTypeMap.set('getGpsErrorBody', {
  id: None<EventId>(),
  prefixId: 'Event_getGpsError',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdCenterFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdCenterFail',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdCenterFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdCenterFail',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdDuplicatedTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdDuplicated',
  type: 12,
});
unsafeEventIdTypeMap.set('remoteCmdDuplicatedBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteCmdDuplicated',
  type: 12,
});
unsafeEventIdTypeMap.set('operationCampaignAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_operationCampaignAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('operationCampaignAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_operationCampaignAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('operationAppAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_operationAppAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('operationAppAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_operationAppAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('operationMaintenaceAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_operationMaintenaceAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('operationMaintenaceAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_operationMaintenaceAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipUserRemoveAllCarTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipUserRemoveAllCar',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipUserRemoveAllCarBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipUserRemoveAllCar',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipAdminRemoveCarTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipAdminRemoveCar',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipAdminRemoveCarBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipAdminRemoveCar',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipUserRemoveCarTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipUserRemoveCar',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipUserRemoveCarBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipUserRemoveCar',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiServiceExpiredTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiServiceExpired',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiServiceExpiredBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiServiceExpired',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiContractDoneTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiContractDone',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiContractDoneBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiContractDone',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiPreAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiPreAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionWifiPreAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionWifiPreAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionDCMServiceExpiredTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionDCMServiceExpired',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionDCMServiceExpiredBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionDCMServiceExpired',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionDCMContractDoneTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionDCMContractDone',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionDCMContractDoneBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionDCMContractDone',
  type: 11,
});
unsafeEventIdTypeMap.set('subsctiptionDCMAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subsctiptionDCMAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subsctiptionDCMAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_subsctiptionDCMAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionDCMPreAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionDCMPreAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('subscriptionDCMPreAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_subscriptionDCMPreAlert',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipUserActivateTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipUserActivate',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipUserActivateBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipUserActivate',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipAdminNotifyTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipAdminNotify',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipAdminNotifyBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipAdminNotify',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipInvitationTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipInvitation',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipInvitationBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipInvitation',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipChangeContentsTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipChangeContents',
  type: 11,
});
unsafeEventIdTypeMap.set('ownerShipChangeContentsBody', {
  id: None<EventId>(),
  prefixId: 'Event_ownerShipChangeContents',
  type: 11,
});
unsafeEventIdTypeMap.set('serviceReminderAlertTireTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertTire',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertTireBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertTire',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBrakeRrTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBrakeRr',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBrakeRrBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBrakeRr',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBrakeFrTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBrakeFr',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBrakeFrBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBrakeFr',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBrakeOilTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBrakeOil',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBrakeOilBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBrakeOil',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBatteryTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBattery',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBatteryBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertBattery',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertEngOilTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertEngOil',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertEngOilBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlertEngOil',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertTitle', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlert',
  type: 10,
});
unsafeEventIdTypeMap.set('serviceReminderAlertBody', {
  id: None<EventId>(),
  prefixId: 'Event_serviceReminderAlert',
  type: 10,
});
unsafeEventIdTypeMap.set('engineProhibitTitle', {
  id: None<EventId>(),
  prefixId: 'Event_engineProhibit',
  type: 6,
});
unsafeEventIdTypeMap.set('engineProhibitBody', {
  id: None<EventId>(),
  prefixId: 'Event_engineProhibit',
  type: 6,
});
unsafeEventIdTypeMap.set('trackingOnTitle', {
  id: None<EventId>(),
  prefixId: 'Event_trackingOn',
  type: 5,
});
unsafeEventIdTypeMap.set('trackingOnBody', {
  id: None<EventId>(),
  prefixId: 'Event_trackingOn',
  type: 5,
});
unsafeEventIdTypeMap.set('ukkariDoorTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariDoor',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariDoorBody', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariDoor',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariLightTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariLight',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariLightBody', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariLight',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariEngProhibitTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariEngProhibit',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariEngProhibitBody', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariEngProhibit',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariHazardTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariHazard',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariHazardBody', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariHazard',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariAirconTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariAircon',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariAirconBody', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariAircon',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariEngineTitle', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariEngine',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariEngineBody', {
  id: None<EventId>(),
  prefixId: 'Event_ukkariEngine',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariTitle', {
  id: Some<EventId>('1040'),
  prefixId: 'Event_ukkari',
  type: 4,
});
unsafeEventIdTypeMap.set('ukkariBody', {
  id: Some<EventId>('1040'),
  prefixId: 'Event_ukkari',
  type: 4,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineProhibitOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineProhibitOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteWifiResetFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiResetFail',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiResetFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiResetFail',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiResetSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiResetSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteWifiResetSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiResetSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteWifiOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOffFail',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOffFail',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOffSuccess',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOffSuccess',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOnFail',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOnFail',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOnSuccess',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteWifiOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteWifiOnSuccess',
  type: 7,
});
unsafeEventIdTypeMap.set('remoteGeofenceOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOffFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteGeofenceOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOffFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteGeofenceOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOffSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteGeofenceOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOffSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteGeofenceChangeFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceChangeFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteGeofenceChangeFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceChangeFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteGeofenceChangeSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceChangeSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteGeofenceChangeSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceChangeSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteGeofenceOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOnFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteGeofenceOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOnFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteGeofenceOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOnSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteGeofenceOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteGeofenceOnSuccess',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('remoteTrackingOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOffFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOffFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOffSuccess',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOffSuccess',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOnFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOnFail',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOnSuccess',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteTrackingOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteTrackingOnSuccess',
  type: 8,
});
unsafeEventIdTypeMap.set('remoteEngineOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteEngineOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteEngineOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteACOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteACOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHazardOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHazardOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOffFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOffFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOffFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOffSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOffSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOffSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOnFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOnFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOnFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOnSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteHeadLightOnSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteHeadLightOnSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteDoorLockedFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteDoorLockedFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteDoorLockedFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteDoorLockedFail',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteDoorLockedSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_remoteDoorLockedSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('remoteDoorLockedSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_remoteDoorLockedSuccess',
  type: 2,
});
unsafeEventIdTypeMap.set('carResgitrationFailTitle', {
  id: None<EventId>(),
  prefixId: 'Event_carResgitrationFail',
  type: 9,
});
unsafeEventIdTypeMap.set('carResgitrationFailBody', {
  id: None<EventId>(),
  prefixId: 'Event_carResgitrationFail',
  type: 9,
});
unsafeEventIdTypeMap.set('carResgitrationSuccessTitle', {
  id: None<EventId>(),
  prefixId: 'Event_carResgitrationSuccess',
  type: 12,
});
unsafeEventIdTypeMap.set('carResgitrationSuccessBody', {
  id: None<EventId>(),
  prefixId: 'Event_carResgitrationSuccess',
  type: 12,
});
unsafeEventIdTypeMap.set('keyFreeAlert4Title', {
  id: Some<EventId>('504'),
  prefixId: 'Event_keyFreeAlert4',
  type: 1,
});
unsafeEventIdTypeMap.set('keyFreeAlert4Body', {
  id: Some<EventId>('504'),
  prefixId: 'Event_keyFreeAlert4',
  type: 1,
});
unsafeEventIdTypeMap.set('towAwayAlertTitle', {
  id: Some<EventId>('1031'),
  prefixId: 'Event_towAwayAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('towAwayAlertBody', {
  id: Some<EventId>('1031'),
  prefixId: 'Event_towAwayAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('geofenceInAlertTitle', {
  id: Some<EventId>('1022'),
  prefixId: 'Event_geofenceInAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('geofenceInAlertBody', {
  id: Some<EventId>('1022'),
  prefixId: 'Event_geofenceInAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('geofenceOutAlertTitle', {
  id: Some<EventId>('1021'),
  prefixId: 'Event_geofenceOutAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('geofenceOutAlertBody', {
  id: Some<EventId>('1021'),
  prefixId: 'Event_geofenceOutAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('removeDcmAlertTitle', {
  id: Some<EventId>('1011'),
  prefixId: 'Event_removeDcmAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('removeDcmAlertBody', {
  id: Some<EventId>('1011'),
  prefixId: 'Event_removeDcmAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('alarmAlertTitle', {
  id: Some<EventId>('1010'),
  prefixId: 'Event_alarmAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('alarmAlertBody', {
  id: Some<EventId>('1010'),
  prefixId: 'Event_alarmAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('systemIGOffAlertTitle', {
  id: Some<EventId>('1002'),
  prefixId: 'Event_systemIGOffAlert',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('systemIGOffAlertBody', {
  id: Some<EventId>('1002'),
  prefixId: 'Event_systemIGOffAlert',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('systemIGOnAlertTitle', {
  id: Some<EventId>('1001'),
  prefixId: 'Event_systemIGOnAlert',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('systemIGOnAlertBody', {
  id: Some<EventId>('1001'),
  prefixId: 'Event_systemIGOnAlert',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('userIGOffAlertTitle', {
  id: Some<EventId>('102'),
  prefixId: 'Event_userIGOffAlert',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('userIGOffAlertBody', {
  id: Some<EventId>('102'),
  prefixId: 'Event_userIGOffAlert',
  type: 'N/A',
});
unsafeEventIdTypeMap.set('userIGOnAlertTitle', {
  id: Some<EventId>('101'),
  prefixId: 'Event_userIGOnAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('userIGOnAlertBody', {
  id: Some<EventId>('101'),
  prefixId: 'Event_userIGOnAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('elePowSteOreAlertTitle', {
  id: Some<EventId>('308'),
  prefixId: 'Event_elePowSteOreAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('elePowSteOreAlertBody', {
  id: Some<EventId>('308'),
  prefixId: 'Event_elePowSteOreAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('afsWarnAlretTitle', {
  id: Some<EventId>('613'),
  prefixId: 'Event_afsWarnAlret',
  type: 1,
});
unsafeEventIdTypeMap.set('afsWarnAlretBody', {
  id: Some<EventId>('613'),
  prefixId: 'Event_afsWarnAlret',
  type: 1,
});
unsafeEventIdTypeMap.set('fourWheelSysAlertTitle', {
  id: Some<EventId>('213'),
  prefixId: 'Event_fourWheelSysAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('fourWheelSysAlertBody', {
  id: Some<EventId>('213'),
  prefixId: 'Event_fourWheelSysAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('autoHighBeamAlertTitle', {
  id: Some<EventId>('615'),
  prefixId: 'Event_autoHighBeamAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('autoHighBeamAlertBody', {
  id: Some<EventId>('615'),
  prefixId: 'Event_autoHighBeamAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('keyFreeAlertTitle', {
  id: Some<EventId>('505'),
  prefixId: 'Event_keyFreeAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('keyFreeAlertBody', {
  id: Some<EventId>('505'),
  prefixId: 'Event_keyFreeAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('ecoIdelOffAlertTitle', {
  id: Some<EventId>('210'),
  prefixId: 'Event_ecoIdelOffAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('ecoIdelOffAlertBody', {
  id: Some<EventId>('210'),
  prefixId: 'Event_ecoIdelOffAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('brakeHoldAlertTitle', {
  id: Some<EventId>('310'),
  prefixId: 'Event_brakeHoldAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('brakeHoldAlertBody', {
  id: Some<EventId>('310'),
  prefixId: 'Event_brakeHoldAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('smaashiErrAlertTitle', {
  id: Some<EventId>('401'),
  prefixId: 'Event_smaashiErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('smaashiErrAlertBody', {
  id: Some<EventId>('401'),
  prefixId: 'Event_smaashiErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('lowBeamLevelErrAlertTitle', {
  id: Some<EventId>('610'),
  prefixId: 'Event_lowBeamLevelErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('lowBeamLevelErrAlertBody', {
  id: Some<EventId>('610'),
  prefixId: 'Event_lowBeamLevelErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('headLampSysAlertTitle', {
  id: Some<EventId>('616'),
  prefixId: 'Event_headLampSysAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('headLampSysAlertBody', {
  id: Some<EventId>('616'),
  prefixId: 'Event_headLampSysAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('bosErrAlertTitle', {
  id: Some<EventId>('212'),
  prefixId: 'Event_bosErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('bosErrAlertBody', {
  id: Some<EventId>('212'),
  prefixId: 'Event_bosErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('dscErrAlertTitle', {
  id: Some<EventId>('211'),
  prefixId: 'Event_dscErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('dscErrAlertBody', {
  id: Some<EventId>('211'),
  prefixId: 'Event_dscErrAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('vscTscAlertTitle', {
  id: Some<EventId>('305'),
  prefixId: 'Event_vscTscAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('vscTscAlertBody', {
  id: Some<EventId>('305'),
  prefixId: 'Event_vscTscAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('absAlertTitle', {
  id: Some<EventId>('304'),
  prefixId: 'Event_absAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('absAlertBody', {
  id: Some<EventId>('304'),
  prefixId: 'Event_absAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('parkBrakeErrorTitle', {
  id: Some<EventId>('309'),
  prefixId: 'Event_parkBrakeError',
  type: 1,
});
unsafeEventIdTypeMap.set('parkBrakeErrorBody', {
  id: Some<EventId>('309'),
  prefixId: 'Event_parkBrakeError',
  type: 1,
});
unsafeEventIdTypeMap.set('cvtAlertTitle', {
  id: Some<EventId>('206'),
  prefixId: 'Event_cvtAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('cvtAlertBody', {
  id: Some<EventId>('206'),
  prefixId: 'Event_cvtAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('Engine1AlertTitle', {
  id: Some<EventId>('202'),
  prefixId: 'Event_Engine1Alert',
  type: 1,
});
unsafeEventIdTypeMap.set('Engine1AlertBody', {
  id: Some<EventId>('202'),
  prefixId: 'Event_Engine1Alert',
  type: 1,
});
unsafeEventIdTypeMap.set('srsAirbagAlertTitle', {
  id: Some<EventId>('306'),
  prefixId: 'Event_srsAirbagAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('srsAirbagAlertBody', {
  id: Some<EventId>('306'),
  prefixId: 'Event_srsAirbagAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('elePowSteRedAlertTitle', {
  id: Some<EventId>('307'),
  prefixId: 'Event_elePowSteRedAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('elePowSteRedAlertBody', {
  id: Some<EventId>('307'),
  prefixId: 'Event_elePowSteRedAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('batteryAlertTitle', {
  id: Some<EventId>('204'),
  prefixId: 'Event_batteryAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('batteryAlertBody', {
  id: Some<EventId>('204'),
  prefixId: 'Event_batteryAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('waterTempAlertTitle', {
  id: Some<EventId>('205'),
  prefixId: 'Event_waterTempAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('waterTempAlertBody', {
  id: Some<EventId>('205'),
  prefixId: 'Event_waterTempAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('oilAlertTitle', {
  id: Some<EventId>('203'),
  prefixId: 'Event_oilAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('oilAlertBody', {
  id: Some<EventId>('203'),
  prefixId: 'Event_oilAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('brakeSysAlertTitle', {
  id: Some<EventId>('301'),
  prefixId: 'Event_brakeSysAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('brakeSysAlertBody', {
  id: Some<EventId>('301'),
  prefixId: 'Event_brakeSysAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('brakeFluAlertTitle', {
  id: Some<EventId>('302'),
  prefixId: 'Event_brakeFluAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('brakeFluAlertBody', {
  id: Some<EventId>('302'),
  prefixId: 'Event_brakeFluAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('epbAlertTitle', {
  id: Some<EventId>('002'),
  prefixId: 'Event_epbAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('epbAlertBody', {
  id: Some<EventId>('002'),
  prefixId: 'Event_epbAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('airbagAlertTitle', {
  id: Some<EventId>('001'),
  prefixId: 'Event_airbagAlert',
  type: 1,
});
unsafeEventIdTypeMap.set('airbagAlertBody', {
  id: Some<EventId>('001'),
  prefixId: 'Event_airbagAlert',
  type: 1,
});

export const EventIdTypeMap: EventIdTypeMap = {
  get: (key: RawNotificationId) => Option(unsafeEventIdTypeMap.get(key)),
};
