import React, { useCallback } from 'react';
import { VehicleEvent } from '../../apis/EventTelemetry';
import { Option } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { sleep } from '../../util/Sleep';
import { EventIdMap } from '../i18n/EventIdMap';
import { MessageKeys, useIntl } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { WarningHelp } from './Types';
import {
  VehicleInfoCardActions,
  VehicleInfoCardDataMap,
} from './VehicleInfoCard';

type DM = VehicleInfoCardDataMap;

type WarningListItemProps = Readonly<{
  parent: DataMapStore<DM>;
  vehicleEvent: Option<VehicleEvent>;
  iconNormal?: string;
  iconError?: string;
  titleId: MessageKeys;
  messageNormalId: MessageKeys;
  messageErrorId: MessageKeys;
  helpMessageId: MessageKeys;
}>;

export const defaultWarningHelp: WarningHelp = {
  state: 'Hide',
  titleId: 'Event_Engine1AlertWarningTitle',
  helpMessageId: 'Event_Engine1AlertWarningHint',
};

const styles = Scope(() => {
  const listItemRightRaw: OptionalStyles = {
    width: Percentage(100),
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  };

  const titleRaw: OptionalStyles = {
    display: 'inline-block',
    fontSize: Length.px(12),
    fontWeight: 'bold',
    lineHeight: Length.px(12),
    padding: Length.px(2),
  };

  const messageRaw: OptionalStyles = {
    display: 'inline-block',
    fontSize: Length.px(16),
    lineHeight: Length.px(16),
    padding: Length.px(2),
  };

  return {
    listItem: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexGrow: 1,
    } as OptionalStyles,

    listItemLeft: {
      display: 'flex',
      alignItems: 'center',
      padding: Length.px(10),
    } as OptionalStyles,

    listItemTopRight: listItemRightRaw,

    listItemRight: {
      borderBottom: Border('solid', Length.px(1), Colors.border),
      ...listItemRightRaw,
    } as OptionalStyles,

    titleMessage: {
      display: 'flex',
      alignItems: 'center',
    } as OptionalStyles,

    titleNormal: {
      ...titleRaw,
      color: Colors.text,
    } as OptionalStyles,

    titleError: {
      ...titleRaw,
      color: Colors.errorText,
    } as OptionalStyles,

    messageNormal: {
      ...messageRaw,
      color: Colors.text,
    } as OptionalStyles,

    messageError: {
      ...messageRaw,
      color: Colors.errorHighlightText,
      fontWeight: 'bold',
      backgroundColor: Colors.errorHighlightBackground,
      borderRadius: Length.px(2),
    } as OptionalStyles,

    arrowHelpStyle: {
      display: 'flex',
      alignItems: 'stretch',
    } as OptionalStyles,

    arrowStyle: {
      display: 'flex',
      alignItems: 'center',
      padding: Length.px(4),
      WebkitTapHighlightColor: 'transparent',
    } as OptionalStyles,

    helpStyle: {
      display: 'flex',
      alignItems: 'center',
      borderLeft: Border('solid', Length.px(1), Colors.border),
      marginTop: Length.px(12),
      marginBottom: Length.px(12),
      paddingLeft: Length.px(12),
      paddingRight: Length.px(12),
      WebkitTapHighlightColor: 'transparent',
    } as OptionalStyles,
  };
});

const WarningListItem: React.FC<WarningListItemProps> = (props) => {
  const {
    views: { View, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const Container = View;
  const TitleArea = View;

  const intl = useIntl();
  const actions = VehicleInfoCardActions;

  const isError = !props.vehicleEvent.isNone();
  const title = intl.formatMessage({ id: props.titleId });
  const icon = props.iconError;
  const message = intl.formatMessage({ id: props.messageErrorId });

  const openHelpDialog = useCallback(() => {
    return dispatch
      .vehicleInfo(actions.updateWarningHelp, {
        state: 'Hide',
        titleId: props.titleId,
        helpMessageId: props.helpMessageId,
      })
      .asyncAll(
        sleep(0).then(() =>
          dispatch.vehicleInfo(actions.updateWarningHelp, {
            state: 'Show',
            titleId: props.titleId,
            helpMessageId: props.helpMessageId,
          }),
        ),
      );
  }, [actions.updateWarningHelp, props.helpMessageId, props.titleId, dispatch]);

  const onClick = useCallback(() => {
    return dispatch.vehicleInfo(actions.updateSelectedVehicleEvent, {
      selectedVehicleEvent: props.vehicleEvent.getUnsafeValue(),
      vehicleEventWindowMode: 'Show',
    });
  }, [actions.updateSelectedVehicleEvent, props.vehicleEvent, dispatch]);

  if (!isError) {
    return null;
  }
  return (
    <Container style={styles.listItem}>
      <View style={styles.listItemLeft} onClick={onClick}>
        <Image alt={title.toString()} src={icon} />
      </View>
      <View style={styles.listItemRight}>
        <TitleArea style={styles.titleMessage} onClick={onClick}>
          <View>
            <View>
              <View style={isError ? styles.titleError : styles.titleNormal}>
                {title.toReactNode()}
              </View>
            </View>
            <View>
              <View
                style={isError ? styles.messageError : styles.messageNormal}
              >
                {message.toReactNode()}
              </View>
            </View>
          </View>
        </TitleArea>
        <View style={styles.arrowHelpStyle}>
          {isError ? (
            <View style={styles.arrowStyle} onClick={onClick}>
              <IcButton
                image={Icons.ic_forward_normal}
                imageWidth={Percentage(100)}
                imageHeight={Percentage(100)}
              />
            </View>
          ) : (
            <View />
          )}
          <View style={styles.helpStyle} onClick={openHelpDialog}>
            <IcButton
              image={Icons.ic_questionmark}
              imageWidth={Percentage(100)}
              imageHeight={Percentage(100)}
            />
          </View>
        </View>
      </View>
    </Container>
  );
};

type Props = Readonly<{
  parent: DataMapStore<DM>;
  showTitle?: boolean;
}>;

export function WarningLights(props: Props): React.ReactElement {
  const {
    views: { View },
    data: { context },
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();

  const titleStyle: OptionalStyles = {
    height: Length.px(48),
    backgroundColor: Colors.backgroundMoreDark,
    fontSize: Length.px(14),
    fontWeight: 'bold',
    color: Colors.textLight,
    lineHeight: Length.px(14),
    paddingBottom: Length.px(8),
    paddingLeft: Length.px(16),
    display: 'flex',
    alignItems: 'flex-end',
  };

  const warnings = EventIdMap.keys
    .flatMap((_) =>
      Pipe(EventIdMap.get(_))
        .map((_) => (_.warning.order === undefined ? [] : [_]))
        .get(),
    )
    .sort((a, b) =>
      Option(a.warning.order)
        .flatMap((x) => Option(b.warning.order).map((y) => x - y))
        .getOrElse(() => 0),
    )
    .map((value) => (
      <WarningListItem
        parent={props.parent}
        key={value.eventId}
        iconNormal={value.image.normal}
        iconError={value.image.active}
        titleId={value.warning.title}
        messageNormalId='VehicleInfo_cmmNormal'
        messageErrorId='VehicleInfo_cmmError'
        helpMessageId={value.warning.hint}
        vehicleEvent={context.selectedVehicleVin
          .flatMap((vin) =>
            Option(context.userLastVehicleEvents.find((a) => a.vin === vin)),
          )
          .map((a) => a.events)
          .flatMap((a) => Option(a.find((e) => e.eventId === value.eventId)))}
      />
    ));

  return (
    <>
      {props.showTitle === true ? (
        <View style={titleStyle}>
          <View>
            {intl
              .formatMessage({ id: 'VehicleInfo_warnigLight' })
              .toReactNode()}
          </View>
        </View>
      ) : null}

      {warnings}
    </>
  );
}
