import React from 'react';
import { Intl, Message, useIntl } from '../i18n/Intl';
import { ActionBinder } from '../states/ActionBinder';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Dialog, DialogButton, State } from './Dialog';

type Props<DataMap> = Readonly<{
  parent: DataMapStore<DataMap>;
  state: State;
  title: Message;
  message: Message;
  onClose?: () => DataMapDispatch<WithContext<DataMap>>;
}>;

export function AlertDialog<DataMap>(
  props: Props<DataMap>,
): React.ReactElement {
  const {
    views: { View },
  } = useParentStore({ parent: props.parent });
  const Footer = View;
  const intl = useIntl();

  return (
    <Dialog
      {...props}
      footer={
        <Footer>
          <DialogButton
            parent={props.parent}
            type='Alert'
            title={intl.formatMessage({ id: 'Common_ok' })}
            fontWeight='bold'
            onClick={props.onClose}
          />
        </Footer>
      }
    />
  );
}

export function errorDialogAction(
  dialogTitle: Message,
  dialogBody: Message,
): ActionBinder<Context> {
  return Action.bind(Context.actions.updateAlertDialog, {
    state: 'Show',
    title: dialogTitle,
    message: dialogBody,
  });
}

export function commonErrorDialogAction(intl: Intl): ActionBinder<Context> {
  return Action.bind(Context.actions.updateAlertDialog, {
    state: 'Show',
    title: intl.formatMessage({ id: 'Common_networkError' }),
    message: intl.formatMessage({ id: 'Common_networkErrorMessage' }),
  });
}

export function systemErrorDialogAction(msg: string): ActionBinder<Context> {
  return errorDialogAction(Message('System error'), Message(msg));
}
