import React from 'react';
import { config } from '../../Config';
import { Geofence, deleteGeofence, updateGeofence } from '../../apis/Geofence';
import { println } from '../../apis/NativeApi';
import { createLastRemoteCommandAction } from '../../apis/RemoteCommand';
import {
  getRemoteCommandErrorMessage,
  updateOngoingRemoteStates,
} from '../../apis/RemoteCommandExecuter';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { copy } from '../../util/Copyable';
import { Distance } from '../../util/Distance';
import { Anomaly } from '../../util/Error';
import { None, Some } from '../../util/Option';
import { Unit } from '../../util/Unit';
import {
  commonErrorDialogAction,
  errorDialogAction,
  systemErrorDialogAction,
} from '../components/AlertDialog';
import { CardHeader } from '../components/CardHeader';
import {
  SlideInCardData,
  SlideInCard as SlideInCardView,
} from '../components/SlideInCard';
import { HomeData } from '../home/Home';
import { createIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { Store } from '../states/Store';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { getGeoPos } from './MapUtils';
import { Slider } from './Slider';
import {
  VehicleLocationState,
  defaultRadius,
  minGeofenceRadius,
} from './VehicleLocationState';

type Parent = HomeData;
type SlideInData = SlideInCardData;

interface SliderProps {
  readonly radius: Distance;
  readonly parent: Store<HomeData>;
}

function SliderContainer(props: SliderProps): React.ReactElement {
  const {
    data: { home },
    views: { View },
  } = useParentStore({
    parent: {
      home: props.parent,
    },
  });
  const localState = home.localState;
  const actions = VehicleLocationState.actions;
  const minInKm = 0;
  const maxInKm = 100;

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        paddingLeft: Length.px(19),
        paddingRight: Length.px(19),
      }}
    >
      <View
        style={{
          fontSize: Length.px(12),
          lineHeight: Length.px(12),
          whiteSpace: 'nowrap',
          paddingRight: Length.px(16),
          paddingBottom: Length.px(6),
        }}
      >
        {minInKm} km
      </View>
      <Slider
        parent={{ home: props.parent }}
        minValue={minInKm}
        maxValue={maxInKm}
        value={props.radius.toKiloMeters()}
        onChange={({ value: valueInKm, dispatch }) =>
          dispatch.home(
            actions.updateGeofenceCacheRadius,
            Distance.kiloMeters(Math.round(valueInKm)),
          )
        }
        valueListener={localState.geofence.localState.radiusListener}
      />
      <View
        style={{
          fontSize: Length.px(12),
          lineHeight: Length.px(12),
          whiteSpace: 'nowrap',
          paddingLeft: Length.px(16),
          paddingBottom: Length.px(6),
        }}
      >
        {maxInKm} km
      </View>
    </View>
  );
}

type Props = Readonly<{
  parent: Store<Parent>;
  radius: Distance;
}>;

export function GeofenceSettingPane({
  radius,
  parent,
}: Props): React.ReactElement {
  const {
    data: {
      context,
      parent: { localState },
    },
    views: { View },
    dispatch,
  } = useParentStore<Readonly<{ parent: HomeData }>>({
    parent: {
      parent,
    },
  });
  const actions = VehicleLocationState.actions;
  const intl = createIntl(context);
  const viewPort = context.viewPort;

  return (
    <SlideInCardView
      parent={{ home: parent }}
      title={intl.formatMessage({ id: 'Location_geoFenceTitle' })}
      closeIcon={Icons.ic_close_normal}
      minHeight={256}
      state={localState.geofence.localState.settingPaneState}
      onOpen={(dispatch) => dispatch}
      onClose={(_, dispatch) =>
        dispatch.home(actions.onSettingPaneClose, viewPort)
      }
      onOpened={(data: SlideInData, dispatch) =>
        dispatch.home(actions.onSettingPaneOpened, {
          viewPort,
          settingPaneBound: data.target.getBound().getUnsafeValue(),
        })
      }
      onChange={(bound, dispatch) =>
        dispatch.home(
          (data) =>
            copy(data, {
              localState: {
                geofence: {
                  container: data.localState.geofence.container.updateStyles({
                    height: Length.px(bound.top - CardHeader.height.value),
                  }),
                },
              },
            }),
          Unit,
        )
      }
    >
      <View
        style={{
          marginTop: Length.px(6),
          fontSize: Length.px(16),
          lineHeight: Length.px(20),
        }}
      >
        {intl.formatMessage({ id: 'Location_getFenceSubTitle' }).toReactNode()}
      </View>

      {/* Margin */}
      <View
        style={{
          height: Length.px(20),
        }}
      />

      <SliderContainer radius={radius} parent={parent} />

      {/* Margin */}
      <View
        style={{
          height: Length.px(8),
        }}
      />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: Length.px(48),
          marginBottom: Length.px(8),
        }}
      >
        <View
          style={{
            fontSize: Length.px(16),
            color: Colors.accent,
            lineHeight: Length.px(16),
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            const newRadiusInKm =
              localState.geofence.localState.geofenceCache.unwrap(
                (g) => g.radius.toKiloMeters(),
                () => 0,
              );
            if (newRadiusInKm < minGeofenceRadius.toKiloMeters()) {
              /* delete geofence */
              const lastCommandAction = createLastRemoteCommandAction(
                'geofence',
                'delete',
              );
              return dispatch
                .parent(actions.onSettingPaneClose, viewPort)
                .context(
                  updateOngoingRemoteStates(
                    context.selectedVehicleVin,
                    'geofence',
                    true,
                  ),
                )
                .context(Context.actions.updateGeofence, None())
                .asyncAll(
                  getSelectedVehicle(context).unwrap(
                    (vehicle) =>
                      deleteGeofence(vehicle.vin, vehicle.dcmId)
                        .then(() =>
                          dispatch
                            .context(
                              updateOngoingRemoteStates(
                                Some(vehicle.vin),
                                'geofence',
                                false,
                              ),
                            )
                            .context(Context.actions.updateGeofence, None())
                            .context(
                              Context.actions.updateContentState,
                              'Reload',
                            )
                            .context(
                              Context.actions.updateFetchVehicleDataOptions,
                              config.fetchDataOptions.onGeofenceCommand,
                            )
                            .context(lastCommandAction.add)
                            .asyncAll(
                              lastCommandAction.remove.then((_) =>
                                dispatch.context(_),
                              ),
                            ),
                        )
                        .catch((err) =>
                          dispatch
                            .effect(() => println(err))
                            .pipe((_) =>
                              vehicle.geofence
                                .map((g) =>
                                  _.send(
                                    localState.geofence.localState.radiusSender,
                                    g.radius.toKiloMeters(),
                                  ),
                                )
                                .getOrElse(() => _),
                            )
                            .parent(
                              actions.updateGeofenceCacheRadius,
                              vehicle.geofence
                                .map((_) => _.radius)
                                .getOrElse(() => defaultRadius),
                            )
                            .context(
                              updateOngoingRemoteStates(
                                Some(vehicle.vin),
                                'geofence',
                                false,
                              ),
                            )
                            .context(
                              Context.actions.updateGeofence,
                              vehicle.geofence,
                            )
                            .pipe((_) => {
                              const a = Anomaly.of(err);
                              const m = getRemoteCommandErrorMessage<
                                'geofence',
                                'delete'
                              >(vehicle, 'geofence', 'delete', a.code, intl);
                              return _.context(
                                errorDialogAction(m.dialogTitle, m.dialogBody),
                              );
                            }),
                        ),
                    () =>
                      Promise.resolve(
                        dispatch
                          .effect(() =>
                            println(Error(`A selected vehicle are not set`)),
                          )
                          .context(commonErrorDialogAction(intl), Unit),
                      ),
                  ),
                );
            } else {
              /* update geofence */
              return dispatch
                .parent(actions.onSettingPaneClose, viewPort)
                .pipe((_) => {
                  return context.serviceTelemetry.data
                    .toOption()
                    .flatMap((_) => _.position)
                    .map((_) => _.location)
                    .orElse(() =>
                      getGeoPos(localState.geofence.localState.userLocation),
                    )
                    .orElse(() =>
                      Some(
                        config.regionPositions[context.locale.region].position,
                      ),
                    )
                    .unwrap(
                      (position) => {
                        const geofence: Geofence =
                          localState.geofence.localState.geofenceCache.getOrElse(
                            () => ({
                              center: position,
                              radius: defaultRadius,
                            }),
                          );
                        const lastCommandAction = createLastRemoteCommandAction(
                          'geofence',
                          'update',
                        );
                        return _.context(
                          updateOngoingRemoteStates(
                            context.selectedVehicleVin,
                            'geofence',
                            true,
                          ),
                        )
                          .context(
                            Context.actions.updateGeofence,
                            Some(geofence),
                          )
                          .asyncAll(
                            getSelectedVehicle(context).unwrap(
                              (vehicle) =>
                                updateGeofence(
                                  vehicle.vin,
                                  vehicle.dcmId,
                                  geofence,
                                )
                                  .then(() =>
                                    dispatch
                                      .context(
                                        updateOngoingRemoteStates(
                                          Some(vehicle.vin),
                                          'geofence',
                                          false,
                                        ),
                                      )
                                      .context(
                                        Context.actions.updateContentState,
                                        'Reload',
                                      )
                                      .context(
                                        Context.actions
                                          .updateFetchVehicleDataOptions,
                                        config.fetchDataOptions
                                          .onGeofenceCommand,
                                      )
                                      .context(lastCommandAction.add)
                                      .asyncAll(
                                        lastCommandAction.remove.then((_) =>
                                          dispatch.context(_),
                                        ),
                                      ),
                                  )
                                  .catch((err) =>
                                    dispatch
                                      .effect(() => println(err))
                                      .pipe((_) =>
                                        vehicle.geofence
                                          .map((g) =>
                                            _.send(
                                              localState.geofence.localState
                                                .radiusSender,
                                              g.radius.toKiloMeters(),
                                            ),
                                          )
                                          .getOrElse(() => _),
                                      )
                                      .parent(
                                        actions.updateGeofenceCacheRadius,
                                        vehicle.geofence
                                          .map((_) => _.radius)
                                          .getOrElse(() => defaultRadius),
                                      )
                                      .context(
                                        updateOngoingRemoteStates(
                                          Some(vehicle.vin),
                                          'geofence',
                                          false,
                                        ),
                                      )
                                      .context(
                                        Context.actions.updateGeofence,
                                        vehicle.geofence,
                                      )
                                      .pipe((_) => {
                                        const a = Anomaly.of(err);
                                        const m = getRemoteCommandErrorMessage<
                                          'geofence',
                                          'update'
                                        >(
                                          vehicle,
                                          'geofence',
                                          'update',
                                          a.code,
                                          intl,
                                        );
                                        return _.context(
                                          errorDialogAction(
                                            m.dialogTitle,
                                            m.dialogBody,
                                          ),
                                        );
                                      }),
                                  ),
                              () =>
                                Promise.resolve(
                                  _.effect(() =>
                                    println(
                                      Error(
                                        `The range or a selected vehicle are not set`,
                                      ),
                                    ),
                                  ).context(commonErrorDialogAction(intl)),
                                ),
                            ),
                          );
                      },
                      () =>
                        _.context(
                          systemErrorDialogAction('No vehicle location'),
                        ),
                    );
                });
            }
          }}
        >
          {intl.formatMessage({ id: 'Location_getFenceSet' }).toReactNode()}
        </View>
      </View>
    </SlideInCardView>
  );
}
