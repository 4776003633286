import { Lazy } from '../util/Lazy';

export interface Vector {
  readonly x: number;
  readonly y: number;
  readonly abs2: () => number;
  readonly abs: () => number;
  readonly normalize: () => Vector;
  readonly add: (v: Vector) => Vector;
  readonly subtract: (v: Vector) => Vector;
  readonly multiply: (a: number) => Vector;
  readonly divide: (a: number) => Vector;
  readonly dot: (v: Vector) => number;
  readonly angle: (v: Vector) => number;
  readonly interpolate: (v: Vector, a: number, b: number) => Vector;
  readonly isEmpty: () => boolean;
  readonly equals: (v: Vector) => boolean;
  readonly to: (v: Vector) => Vector;
  readonly toString: () => string;
}

interface VectorStatic {
  readonly empty: Vector;
  readonly unitX: Vector;
  readonly unitY: Vector;
}

interface VectorFactory {
  (x: number, y: number): Vector;
}

function create(x: number, y: number): Vector {
  const abs2 = Lazy(() => self.dot(self));
  const abs = Lazy(() => Math.sqrt(self.abs2()));
  const normalize = Lazy(() => Vector(x / self.abs(), y / self.abs()));
  const isEmpty = Lazy(() => x === 0 && y === 0);
  const self: Vector = {
    x,
    y,
    abs2: abs2.get,
    abs: abs.get,
    normalize: normalize.get,
    add: (v: Vector) => Vector(self.x + v.x, self.y + v.y),
    subtract: (v: Vector) => Vector(self.x - v.x, self.y - v.y),
    multiply: (a: number) => Vector(self.x * a, self.y * a),
    divide: (a: number) => Vector(self.x / a, self.y / a),
    dot: (v: Vector) => x * v.x + y * v.y,
    angle: (v: Vector) => {
      if (self.equals(v)) return 0;
      else if (v.isEmpty()) return 0;
      else if (self.isEmpty()) return 0;
      else return Math.acos(self.dot(v) / (self.abs() * v.abs()));
    },
    interpolate: (v: Vector, a: number, b: number) =>
      Vector(
        (b * self.x + a * v.x) / (a + b),
        (b * self.y + a * v.y) / (a + b),
      ),
    isEmpty: isEmpty.get,
    equals: (v: Vector) => self.x === v.x && self.y === v.y,
    to: (v: Vector) => v.subtract(self),
    toString: () => `Vector(${x}, ${y})`,
  };
  return self;
}

const statics: VectorStatic = {
  empty: create(0, 0),
  unitX: create(1, 0),
  unitY: create(0, 1),
};

export const Vector: VectorFactory & VectorStatic = Object.assign(
  create,
  statics,
);
