import React, { useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';
import {
  SharedUser,
  deleteSharedUser,
  updateSharedUserPrivilege,
  useGetUserNickName,
} from '../../apis/SharedUser';
import { UserType } from '../../apis/VehicleList';
import { LineSpace } from '../components/LineSpace';
import {
  SubWindow,
  SubWindowProps,
  WindowStackController,
} from '../components/SlideInWindowStack';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { create } from '../style/Style';
import { AsyncActionView } from './AsyncActionView';
import { Display } from './Display';
import { UserNickNameInput } from './NickNameInput';
import { getSharedUserListKey, isOwner } from './Utils';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  props: SubWindowProps;
  controller: WindowStackController;
  user: SharedUser;
  vin: string;
}>;

const styles = create({
  container: {
    width: Percentage(100),
  },
  rowHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    height: Length.px(48),
    width: Percentage(100),
    paddingLeft: Length.px(16),
    paddingBottom: Length.px(6),
    backgroundColor: Colors.backgroundMoreDark,
  },
  rowHeaderTitle: {
    fontSize: Length.px(14),
    fontWeight: 'bold',
    color: Colors.textLight,
  },
  rowPadding: {
    width: Percentage(100),
    paddingLeft: Length.px(16),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: Length.px(48),
    width: Percentage(100),
    borderTop: Border('solid', Length.px(0.5), Colors.border),
  },
  rowTitle: {
    fontSize: Length.px(16),
    fontWeight: 'bold',
  },
  rowText: {
    fontSize: Length.px(16),
    marginRight: Length.px(16),
  },
  actionText: {
    fontSize: Length.px(16),
    color: Colors.accent,
  },

  noBorder: {
    borderTop: Border.none,
  },

  heroImageContainer: {
    height: Length.px(112),
    width: Percentage(100),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heroImage: {
    height: Length.px(80),
    width: Length.px(80),
  },
  editRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: Length.px(16),
  },
  editInput: {
    fontSize: Length.px(16),
    textAlign: 'right',
    border: Border.none,
  },
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(48),
    width: Length.px(48),
  },
  sharedUserRow: {
    display: 'flex',
    height: Length.px(96),
    paddingTop: Length.px(10),
    paddingBottom: Length.px(10),
  },
  sharedUserBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectedImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(40),
    width: Length.px(40),
    minWidth: Length.px(40),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: Length.px(12),
    marginRight: Length.px(12),
  },
  sharedUserDivider: {
    width: Percentage(100),
    marginLeft: Length.px(16),
    borderTop: Border('solid', Length.px(0.5), Colors.border),
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(40),
    width: Length.px(40),
    marginLeft: Length.px(12),
    marginRight: Length.px(12),
  },
});

type UserInfoProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  user: SharedUser;
}>;

const UserInfo: React.FC<UserInfoProps> = (props) => {
  const {
    views: { View, Text, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const [nickName, isFallback] = useGetUserNickName(props.user);
  const onReset = useCallback(() => {
    return dispatch.context.bind(Context.actions.updateUserNickName, {
      globalUserId: props.user.sharedUserId,
      nickName: '',
    });
  }, [props.user.sharedUserId, dispatch]);

  const [isEditing, setEditing] = useState(false);
  const onEditStart = useCallback(() => {
    setEditing(true);
    return dispatch;
  }, [dispatch]);
  const onEditEnd = useCallback(() => {
    setEditing(false);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.heroImageContainer}>
        <Image style={styles.heroImage} src={Icons.ic_owner} />
      </View>

      <View style={styles.rowPadding}>
        <View style={styles.rowContainer}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_memberId' />
          </Text>
          <Text style={styles.rowText}>{props.user.email}</Text>
        </View>

        <View style={styles.rowContainer}>
          <Text style={{ ...styles.editRow, ...styles.rowTitle }}>
            <Display id='myCarManagement_name' />
            {isFallback ? null : (
              <View style={styles.editButton} onClick={onReset}>
                <Image src={Icons.ic_reset} />
              </View>
            )}
          </Text>
          {isEditing ? (
            <Text style={styles.editRow}>
              <View style={styles.rowText}>
                <UserNickNameInput
                  parent={props.parent}
                  style={styles.editInput}
                  user={props.user}
                  onChanged={onEditEnd}
                />
              </View>
            </Text>
          ) : (
            <Text style={styles.editRow} onClick={onEditStart}>
              {nickName}
              <View style={styles.editButton}>
                <Image src={Icons.ic_edit} />
              </View>
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

type SharedUserInfoProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  controller: WindowStackController;
  user: SharedUser;
  vin: string;
}>;

const SharedUserInfo: React.FC<SharedUserInfoProps> = (props) => {
  const {
    views: { View, Text, Image },
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();
  const { mutate } = useSWRConfig();

  const isSameType = useCallback(
    (userType: UserType) => props.user.userType === userType,
    [props.user.userType],
  );

  const changeUserPrivilegeAction = useCallback(
    (userType: UserType) => async () => {
      await updateSharedUserPrivilege(
        props.user.sharedUserId,
        props.vin,
        userType,
      );
      props.user.userType = userType;
      mutate(getSharedUserListKey(props.vin));
    },
    [mutate, props.user, props.vin],
  );
  const deleteUserAction = useCallback(async () => {
    await deleteSharedUser(props.user.sharedUserId, props.vin);
    mutate(getSharedUserListKey(props.vin));
  }, [mutate, props.user.sharedUserId, props.vin]);
  const onBack = useCallback(
    () => props.controller.hideWindow(),
    [props.controller],
  );

  const SelectedImage: React.FC<{ userType: UserType }> = ({ userType }) =>
    isSameType(userType) ? (
      <Image style={styles.selectedImage} src={Icons.ic_checkmark} />
    ) : (
      <View style={styles.selectedImage} />
    );

  return (
    <View style={styles.container}>
      <View style={styles.rowHeader}>
        <Text style={styles.rowHeaderTitle}>
          <Display id='myCarManagement_screen2Message2' />
        </Text>
      </View>

      <AsyncActionView
        parent={props.parent}
        style={styles.sharedUserRow}
        action={
          isSameType('all') ? undefined : changeUserPrivilegeAction('all')
        }
      >
        <SelectedImage userType='all' />
        <View style={styles.sharedUserBody}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_screen1Select1Title' />
          </Text>
          <Text style={styles.rowText}>
            <Display id='myCarManagement_screen1Select1Message' />
          </Text>
        </View>
      </AsyncActionView>
      <View style={styles.sharedUserDivider} />
      <AsyncActionView
        parent={props.parent}
        style={styles.sharedUserRow}
        action={
          isSameType('limited')
            ? undefined
            : changeUserPrivilegeAction('limited')
        }
      >
        <SelectedImage userType='limited' />
        <View style={styles.sharedUserBody}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_screen1Select2Title' />
          </Text>
          <Text style={styles.rowText}>
            <Display id='myCarManagement_screen1Select2Message' />
          </Text>
        </View>
      </AsyncActionView>

      <View style={styles.rowHeader}>
        <Text style={styles.rowHeaderTitle}>
          <Display id='myCarManagement_registerManagement' />
        </Text>
      </View>
      <AsyncActionView
        parent={props.parent}
        style={{ ...styles.rowContainer, ...styles.noBorder }}
        dialogOptions={{
          title: intl.formatMessage({
            id: 'myCarManagement_dialogRemoveUserTitle',
          }),
          message: intl.formatMessage({
            id: 'myCarManagement_dialogRemoveUserMessage',
          }),
          confirmButtonTitle: intl.formatMessage({
            id: 'myCarManagement_dialogRemoveUserDismiss',
          }),
        }}
        action={deleteUserAction}
        onFinished={onBack}
      >
        <View style={styles.deleteContainer}>
          <View style={styles.deleteButton}>
            <Image src={Icons.ic_owner_delete} />
          </View>
          <Text style={styles.actionText}>
            <Display id='myCarManagement_buttonRemoveUser' />
          </Text>
        </View>
      </AsyncActionView>
      <LineSpace height={Length.px(0.5)} backgroundColor={Colors.border} />
    </View>
  );
};

export const UserManagement: React.FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <SubWindow
      {...props.props}
      title={intl.formatMessage({
        id: isOwner(props.user)
          ? 'myCarManagement_userTypeOwner'
          : 'myCarManagement_pageTitleSharedUser',
      })}
    >
      <UserInfo parent={props.parent} user={props.user} />
      {isOwner(props.user) ? null : (
        <SharedUserInfo
          parent={props.parent}
          controller={props.controller}
          user={props.user}
          vin={props.vin}
        />
      )}
    </SubWindow>
  );
};
