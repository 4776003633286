import { StorageKeys } from '../apis/StorageKey';
import { Option } from './Option';
import { Scope } from './Scope';

export type Lang = 'en' | 'ja' | 'in' | 'ms';
export type Region = 'JP' | 'MY' | 'ID' | 'TH';

export type Locale = {
  readonly lang: Lang;
  readonly region: Region;
};

export function Locale(lang: Lang, region: Region): Locale {
  return {
    lang,
    region,
  };
}

export function getLocaleCache(): Option<Locale> {
  return Option(localStorage.getItem(StorageKeys.locale)).map((_) =>
    JSON.parse(_),
  );
}

export function setLocaleCache(locale: Locale): void {
  localStorage.setItem(StorageKeys.locale, JSON.stringify(locale));
}

export function parseLocale(locale: string): Locale {
  const xs = locale.split('_');
  const lang: Lang = Scope(() => {
    switch (xs[0]) {
      case 'ja':
        return 'ja';
      case 'en':
        return 'en';
      default:
        return 'ja';
    }
  });
  const region: Region = Scope(() => {
    switch (xs[1]) {
      case 'JP':
        return 'JP';
      case 'MY':
        return 'MY';
      default:
        return 'JP';
    }
  });
  return Locale(lang, region);
}
