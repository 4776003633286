import { Matrix3x3 } from '../../math/Matrix';
import { Length } from './Length';
import { OptionalStyles } from './Style';
import { Transform } from './Transform';

/**
 * Bound contains a element's padding and border width, which is same as getBoundClientRect
 */

type CopyArgs = {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
};

export type Bound = {
  readonly kind: 'Bound';
  readonly left: number;
  readonly right: number;
  readonly centerX: number;
  readonly width: number;

  readonly top: number;
  readonly bottom: number;
  readonly centerY: number;
  readonly height: number;

  readonly isEmpty: boolean;
  readonly toStyles: () => OptionalStyles;
  readonly copy: (args: CopyArgs) => Bound;
};

export interface Statics {
  readonly empty: Bound;
  readonly fromDOMRect: (domRect: DOMRect) => Bound;
  readonly fromStyles: (styles: OptionalStyles) => Bound;
}

export interface Factory {
  (left: number, top: number, width: number, height: number): Bound;
}

export function create(
  left: number,
  top: number,
  width: number,
  height: number,
): Bound {
  return {
    kind: 'Bound',

    left,
    right: left + width,
    centerX: left + width / 2,
    width,

    top,
    bottom: top + height,
    centerY: top + height / 2,
    height,

    isEmpty: top === 0 && left === 0 && width === 0 && height === 0,

    toStyles: () => ({
      transform: Transform(Matrix3x3.translate(left, top)),
      width: Length.px(width),
      height: Length.px(height),
    }),

    copy: (args: CopyArgs) =>
      create(
        args.left ?? left,
        args.top ?? top,
        args.width ?? width,
        args.height ?? height,
      ),
  };
}

const statics: Statics = {
  empty: create(0, 0, 0, 0),
  fromDOMRect: (domRect: DOMRect) =>
    create(domRect.left, domRect.top, domRect.width, domRect.height),
  fromStyles: (styles: OptionalStyles) =>
    create(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      styles.transform!.matrix.getX(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      styles.transform!.matrix.getY(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      styles.width!.value,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      styles.height!.value,
    ),
};

export const Bound: Factory & Statics = Object.assign(create, statics);
