import { copy } from '../../util/Copyable';
import { CardConstants } from '../components/Card';
import { Borders, Paddings, Shape } from '../components/Shape';
import { Reducer } from '../states/Reducer';
import { Border } from '../style/Border';
import { Bound } from '../style/Bound';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Transition } from '../style/Transition';

export interface Fade {
  readonly localState: FadeLocalState;
  readonly target: Shape;
}

interface FadeLocalState {
  readonly isOpen: boolean;
  readonly defaultBound: Bound;
  readonly defaultPaddings: Paddings;
  readonly defaultBorders: Borders;
}

interface FadeActions /*extends Actions<Fade, FadeActions>*/ {
  readonly updateDefault: (data: Fade) => Fade;
  readonly open: (data: Fade) => Fade;
  readonly close: (data: Fade) => Fade;
}

type FadeStatics = Reducer<Fade, FadeActions>;

interface FadeFactory {
  (target: Shape): Fade;
}

function getHideStyle(transition: boolean): OptionalStyles {
  return {
    transition: transition
      ? [
          Transition('height', CardConstants.transitionDuration),
          Transition('padding', CardConstants.transitionDuration),
          Transition('border', CardConstants.transitionDuration),
          Transition('opacity', CardConstants.transitionDuration),
        ]
      : [],

    paddingTop: Length.px(0),
    paddingRight: Length.px(0),
    paddingBottom: Length.px(0),
    paddingLeft: Length.px(0),
    height: Length.px(0),
    borderTop: Border.none,
    borderRight: Border.none,
    borderBottom: Border.none,
    borderLeft: Border.none,
    opacity: 0,
  };
}

function getShowStyle(state: FadeLocalState): OptionalStyles {
  return {
    transition: [
      Transition('height', CardConstants.transitionDuration),
      Transition('padding', CardConstants.transitionDuration),
      Transition('border', CardConstants.transitionDuration),
      Transition('opacity', CardConstants.transitionDuration),
    ],
    paddingTop: state.defaultPaddings.top,
    paddingRight: state.defaultPaddings.right,
    paddingBottom: state.defaultPaddings.bottom,
    paddingLeft: state.defaultPaddings.left,
    borderTop: state.defaultBorders.top,
    borderRight: state.defaultBorders.right,
    borderBottom: state.defaultBorders.bottom,
    borderLeft: state.defaultBorders.left,
    height: Length.px(state.defaultBound.height),
    opacity: 1,
  };
}

const fadeOnFactory: FadeFactory = (target: Shape) => ({
  localState: {
    isOpen: false,
    defaultBound: Bound.empty,
    defaultPaddings: target.getPaddings(),
    defaultBorders: target.getBorders(),
  },
  target: target,
});

const fadeOnStatics: FadeStatics = {
  initialize: (data: Fade) =>
    copy(data, {
      target: data.target.updateStyles(getHideStyle(false)),
    }),

  actions: {
    updateDefault: (data: Fade) =>
      copy(data, {
        localState: {
          defaultBound: data.target.getBound().getUnsafeValue(),
        },
      }),

    open: (data: Fade) =>
      copy(data, {
        localState: {
          isOpen: true,
        },
        target: data.target.updateStyles(getShowStyle(data.localState)),
      }),

    close: (data: Fade) =>
      copy(data, {
        localState: {
          isOpen: false,
        },
        target: data.target.updateStyles(getHideStyle(true)),
      }),
  },
};

export const FadeOn: FadeFactory & FadeStatics = Object.assign(
  fadeOnFactory,
  fadeOnStatics,
);

const fadeOffFactory: FadeFactory = (target) => ({
  localState: {
    isOpen: false,
    defaultBound: Bound.empty,
    defaultPaddings: target.getPaddings(),
    defaultBorders: target.getBorders(),
  },
  target: target,
});

const fadeOffStatics: FadeStatics = {
  initialize: (data: Fade) => data,

  actions: {
    updateDefault: (data: Fade) =>
      copy(data, {
        localState: {
          defaultBound: data.target.getBound().getUnsafeValue(),
        },
      }),

    open: (data: Fade) =>
      copy(data, {
        localState: {
          isOpen: true,
        },
        target: data.target.updateStyles(getHideStyle(true)),
      }),
    close: (data: Fade) =>
      copy(data, {
        localState: {
          isOpen: false,
        },
        target: data.target.updateStyles(getShowStyle(data.localState)),
      }),
  },
};

export const FadeOff: FadeFactory & FadeStatics = Object.assign(
  fadeOffFactory,
  fadeOffStatics,
);
