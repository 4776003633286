import React from 'react';
import { RGBA } from '../style/Color';
import { Length } from '../style/Length';
import { toCSSProperties } from '../style/Style';

interface Props {
  readonly height: Length;
  readonly backgroundColor?: RGBA;
}

export function LineSpace(props: Props): React.ReactElement {
  return (
    <div
      style={toCSSProperties({
        height: props.height,
        backgroundColor: props.backgroundColor,
      })}
    />
  );
}
