import { ToOptional, copy } from '../../util/Copyable';
import {
  DataMapBase,
  DispatchBase,
  DispatchItemsBase,
  DispatchMethodBase,
  createDispatchBase,
  dispatchNothing,
  emptyDispatchItemsBase,
} from './DispatchBase';

export type DefaultDataMap<Data, Parent> = DataMapBase<Data> & {
  readonly parent: Parent;
};

export type Dispatch<Data, Parent> = Omit<
  DispatchBase<'Dispatch', DefaultDataMap<Data, Parent>>,
  'current'
>;

type DispatchItems<Data, Parent> = DispatchItemsBase<
  'Dispatch',
  DefaultDataMap<Data, Parent>
>;

interface DispatchFactory {
  <Data, Parent>(): Dispatch<Data, Parent>;
}

interface DispatchStatics {
  readonly nothing: <DataMap>() => DispatchMethodBase<DataMap>;
}

function emptyDispatchItems<Data, Parent>(): DispatchItems<Data, Parent> {
  return emptyDispatchItemsBase<'Dispatch', DefaultDataMap<Data, Parent>>([
    'self',
    'parent',
    'context',
  ]);
}

function createDispatchItems<Data, Parent>(
  items: ToOptional<DispatchItems<Data, Parent>>,
): DispatchItems<Data, Parent> {
  return copy<DispatchItems<Data, Parent>>(emptyDispatchItems(), items);
}

export function createDispatch<Data, Parent>(
  items: DispatchItems<Data, Parent>,
): DispatchBase<'Dispatch', DefaultDataMap<Data, Parent>> {
  return createDispatchBase<'Dispatch', DefaultDataMap<Data, Parent>>(
    items,
    createDispatch,
  );
}

const dispatchFactory: DispatchFactory = <Data, Parent>() =>
  createDispatch(createDispatchItems<Data, Parent>({}));

const dispatchStatics: DispatchStatics = {
  nothing: <DataMap>() => dispatchNothing<DataMap>(),
};

export const Dispatch: DispatchFactory & DispatchStatics = Object.assign(
  dispatchFactory,
  dispatchStatics,
);
