import { SendOutlined } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from 'react';
import { BuildOpt } from '../../util/BuildOpt';
import { useParentStore } from '../states/ContextStore';
import { ParentProps } from './DebugPane';
import { dispatchDB } from './Helpers';
import { Item } from './Item';
import { Subtitle } from './Subtitle';

export function ErrorsPane({ parent }: ParentProps): React.ReactElement {
  const {
    views: { Text },
    data: { debug: data },
    dispatch,
  } = useParentStore({ parent });
  const db = data.db;

  return (
    <>
      <Subtitle title='Errors' />

      <Item
        parent={parent}
        text={<Text>Cause an error on login.</Text>}
        icon={db.loginError ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() => dispatchDB(db, { loginError: !db.loginError }, dispatch)}
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getVehicleList.</Text>}
        icon={
          db.vehicleListError ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(db, { vehicleListError: !db.vehicleListError }, dispatch)
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getServiceTelemetry for vin-1.</Text>}
        icon={
          db.serviceTelemetryErrorVin1 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              serviceTelemetryErrorVin1: !db.serviceTelemetryErrorVin1,
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getServiceTelemetry for vin-2.</Text>}
        icon={
          db.serviceTelemetryErrorVin2 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              serviceTelemetryErrorVin2: !db.serviceTelemetryErrorVin2,
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getVehicleEventList for vin-1.</Text>}
        icon={
          db.vehicleEventErrorVin1 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            { vehicleEventErrorVin1: !db.vehicleEventErrorVin1 },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getVehicleEventList for vin-2.</Text>}
        icon={
          db.vehicleEventErrorVin2 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            { vehicleEventErrorVin2: !db.vehicleEventErrorVin2 },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getUserLastVehiclesEventList</Text>}
        icon={
          db.userLastVehicleEventError ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              userLastVehicleEventError: !db.userLastVehicleEventError,
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on sendRemoteCommand</Text>}
        icon={
          db.sendRemoteCommandError === 'Any' ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              sendRemoteCommandError:
                db.sendRemoteCommandError !== 'Any' ? 'Any' : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an 409 error on sendRemoteCommand</Text>}
        icon={
          db.sendRemoteCommandError === 409 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              sendRemoteCommandError:
                db.sendRemoteCommandError !== 409 ? 409 : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an 500 error on sendRemoteCommand</Text>}
        icon={
          db.sendRemoteCommandError === 500 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              sendRemoteCommandError:
                db.sendRemoteCommandError !== 500 ? 500 : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on subscribeRemoteCommand</Text>}
        icon={
          db.subscribeRemoteCommandResultError === 'Any' ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              subscribeRemoteCommandResultError:
                db.subscribeRemoteCommandResultError !== 'Any' ? 'Any' : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>Cause an error on subscribeRemoteCommand(send_tel only)</Text>
        }
        icon={
          db.subscribeRemoteCommandResultError === 'SendTel' ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              subscribeRemoteCommandResultError:
                db.subscribeRemoteCommandResultError !== 'SendTel'
                  ? 'SendTel'
                  : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an 408 error on subscribeRemoteCommand</Text>}
        icon={
          db.subscribeRemoteCommandResultError === 408 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              subscribeRemoteCommandResultError:
                db.subscribeRemoteCommandResultError !== 408 ? 408 : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an 409 error on subscribeRemoteCommand</Text>}
        icon={
          db.subscribeRemoteCommandResultError === 409 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              subscribeRemoteCommandResultError:
                db.subscribeRemoteCommandResultError !== 409 ? 409 : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an 410 error on subscribeRemoteCommand</Text>}
        icon={
          db.subscribeRemoteCommandResultError === 410 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              subscribeRemoteCommandResultError:
                db.subscribeRemoteCommandResultError !== 410 ? 410 : 'None',
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on pairVehicle/acceptSharedUser</Text>}
        icon={
          db.pairVehicleError ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(db, { pairVehicleError: !db.pairVehicleError }, dispatch)
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on pairActivate</Text>}
        icon={
          db.pairActivateError ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(db, { pairActivateError: !db.pairActivateError }, dispatch)
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getWifiStatus for vin-1.</Text>}
        icon={
          db.wifiStatusErrorVin1 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            { wifiStatusErrorVin1: !db.wifiStatusErrorVin1 },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause an error on getNotificationStatus for vin-1.</Text>}
        icon={
          db.notificationStatusErrorVin1 ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              notificationStatusErrorVin1: !db.notificationStatusErrorVin1,
            },
            dispatch,
          )
        }
      />
      <Item
        parent={parent}
        text={<Text>Throw an error for Sentry</Text>}
        icon={<SendOutlined />}
        disable={false}
        onClick={() => {
          throw new Error('Test Error for Sentry. Please ignore!');
        }}
      />
    </>
  );
}
