import { None, Option, Some } from '../../util/Option';

type Unit =
  | 'ch'
  | 'em'
  | 'ex'
  | 'rem'
  | 'vh'
  | 'vw'
  | 'vmin'
  | 'vmax'
  | 'px'
  | 'km'
  | 'm'
  | 'cm'
  | 'mm'
  | 'in'
  | 'pc'
  | 'pt';

export interface Length {
  readonly kind: 'Length';
  readonly value: number;
  readonly unit: Unit;
  readonly toStyle: () => string;
  readonly copy: (value: number) => Length;
}

export interface Statics {
  readonly px: (value: number) => Length;
  readonly em: (value: number) => Length;
  readonly ch: (value: number) => Length;
  readonly m: (value: number) => Length;
  readonly km: (value: number) => Length;
  readonly mm: (value: number) => Length;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly as: (style: any) => Option<Length>;
}

export interface Factory {
  (value: number, unit: Unit): Length;
}

function create(value: number, unit: Unit): Length {
  const text = `${value}${unit}`;
  return {
    kind: 'Length',
    value,
    unit,
    toStyle: () => text,
    copy: (value: number) => create(value, unit),
  };
}

const statics: Statics = {
  px: (value: number) => create(value, 'px'),
  em: (value: number) => create(value, 'em'),
  ch: (value: number) => create(value, 'ch'),
  m: (value: number) => create(value, 'm'),
  km: (value: number) => create(value, 'km'),
  mm: (value: number) => create(value, 'mm'),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as: (style: any) => {
    if (style && style.kind === 'Length') return Some(style);
    else return None();
  },
};

export const Length: Factory & Statics = Object.assign(create, statics);
