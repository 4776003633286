import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Listener } from '../../util/Listener';
import { None, Option, Some } from '../../util/Option';
import { Shape } from '../components/Shape';
import { ShapeViewBase } from '../components/View';
import { Drag } from '../events/Drag';
import { toTouchVector } from '../events/Events';
import { useIntl } from '../i18n/Intl';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { Transform } from '../style/Transform';
import { minGeofenceRadius } from './VehicleLocationState';

type Key = 'slider';
export type SliderData = Data;

type Data = Readonly<{
  localState: LocalState;
  pop: Shape;
  bar: Shape;
  button: Shape;
}>;

type LocalState = Readonly<{
  value: Option<Percentage>;
  drag: Drag;
}>;

type Actions = Readonly<{
  updateDrag: (data: Data, drag: Drag) => Data;
  updateValue: (data: Data, value: Percentage) => Data;
}>;

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  /** max value in km */
  minValue: number;
  /** min value in km */
  maxValue: number;
  /** radius in km */
  value: number;
  onChange?: (
    _: Readonly<{
      value: number;
      dispatch: DataMapDispatch<WithContext<Parent>>;
    }>,
  ) => DataMapDispatch<WithContext<Parent>>;
  valueListener?: Listener<number>;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type DMC<Parent> = WithContext<DM<Parent>>;

const defaultOptions = {
  containerStyle: {},
  barWidth: 4,
  barColor: Colors.sliderForeground,
  barBackgroundColor: Colors.componentBackground,
  buttonRadius: 12,
  buttonColor: Colors.primary,
  popStyle: {},
};

function getEdges(bar: Shape): Option<[number, number]> {
  return bar.getBound().map((barBound) => {
    const leftEdge = barBound.left + bar.getBorders().left.width.value;
    const rightEdge = barBound.right - bar.getBorders().right.width.value;
    return [leftEdge, rightEdge];
  });
}

function getPopOffset(pop: Shape): Option<number> {
  return pop.getBound().map((popBound) => popBound.width / 2);
}

function toKm(value: Percentage, max: number, min: number): number {
  const x = ((max - min) * value.value) / 100 + min;
  return x > max ? max : x < min ? min : x;
}

function toPercentage(value: number, max: number, min: number): Percentage {
  return Percentage(((value - min) / (max - min)) * 100);
}

function getRadius(
  drag: Drag,
  button: Shape,
  bar: Shape,
  touch: Vector,
): Percentage {
  return Percentage(
    button
      .getBound()
      .flatMap((buttonBound) => {
        return getEdges(bar).map(([leftEdge, rightEdge]) => {
          if (touch.x <= leftEdge) {
            return 0;
          } else if (touch.x >= rightEdge) {
            return 100;
          } else {
            const center = buttonBound.centerX + drag.dt.x;
            return ((center - leftEdge) / (rightEdge - leftEdge)) * 100;
          }
        });
      })
      .getOrElse(() => 0),
  );
}

export const SliderActions: Actions = {
  updateDrag: (data: Data, drag: Drag) =>
    copy(data, {
      localState: {
        drag,
      },
    }),

  updateValue: (data: Data, value: Percentage) =>
    copy(data, {
      localState: {
        value: Some(value),
      },
    }),
};

export function Slider<Parent>(props: Props<Parent>): React.ReactElement {
  const state = useStore<Key, Data, Parent>({
    key: 'slider',
    parent: props.parent,
    default: () => ({
      localState: {
        value: None(),
        drag: Drag(),
      },
      pop: Shape({}),
      bar: Shape({}),
      button: Shape({}),
    }),
    update: ({ dispatch }) =>
      dispatch.pipe((_) =>
        props.valueListener
          ? _.asyncAll(
              props.valueListener.map((value) =>
                dispatch.slider(
                  SliderActions.updateValue,
                  toPercentage(value, props.maxValue, props.minValue),
                ),
              ),
            )
          : _,
      ),
  });

  const { View } = state.views;
  const Container = View;
  const Pop = state.views.slider.pop as ShapeViewBase<DMC<Parent>>;
  const BarBox = View;
  const Bar = state.views.slider.bar as ShapeViewBase<DMC<Parent>>;
  const ValueBar = View;
  const Button = state.views.slider.button as ShapeViewBase<DMC<Parent>>;
  const intl = useIntl();
  const dispatch = state.dispatch;

  const actions = SliderActions;
  const data = state.data.slider;
  const localState = data.localState;

  const value = localState.value.getOrElse(() =>
    toPercentage(props.value, props.maxValue, props.minValue),
  );
  const valueInKm = toKm(value, props.maxValue, props.minValue);

  const [popTransform, valueBarWidth, buttonTransform] = getEdges(data.bar)
    .flatMap<[Transform, Length, Transform]>(([leftEdge, rightEdge]) => {
      return data.button.getBound().flatMap((buttonBound) => {
        return getPopOffset(data.pop).map((popOffset) => {
          const buttonOffset = buttonBound.width / 2;
          const dx = (rightEdge - leftEdge) * (value.value / 100);
          const popTransform = Transform(
            Matrix3x3.translate(dx - popOffset, 0),
          );
          const valueBarWidth = Length.px(dx);
          const buttonTransform = Transform(
            Matrix3x3.translate(dx - buttonOffset, 0),
          );
          return [popTransform, valueBarWidth, buttonTransform];
        });
      });
    })
    .getOrElse(() => {
      const popTransform = Transform(Matrix3x3.translate(0, 0));
      const valueBarWidth = Length.px(0);
      const buttonTransform = Transform(Matrix3x3.translate(0, 0));
      return [popTransform, valueBarWidth, buttonTransform];
    });

  return (
    <Container
      style={{
        width: Percentage(100),
      }}
    >
      <Pop
        style={{
          paddingTop: Length.px(4),
          paddingBottom: Length.px(4),
          paddingLeft: Length.px(8),
          paddingRight: Length.px(8),
          marginBottom: Length.px(12),
          width: Length.px(80),
          textAlign: 'center',
          backgroundColor: Colors.backgroundMoreDark,
          fontSize: Length.px(16),
          fontWeight: 'bold',
          lineHeight: Length.px(16),
          display: 'inline-block',
          whiteSpace: 'nowrap',
          transform: popTransform,
          borderRadius: Length.px(10),
        }}
      >
        {valueInKm >= minGeofenceRadius.toKiloMeters()
          ? `${Math.round(valueInKm)} km`
          : intl
              .formatMessage({ id: 'Location_geoFenceNoSetting' })
              .toReactNode()}
      </Pop>
      <BarBox
        style={{
          position: 'relative',
          height: Length.px(24),
        }}
      >
        <Bar
          style={{
            position: 'absolute',
            top: Length.px(
              defaultOptions.buttonRadius - defaultOptions.barWidth / 2,
            ),
            borderRadius: Length.px(defaultOptions.barWidth),
            border: Border.none,
            height: Length.px(defaultOptions.barWidth),
            width: Percentage(100),
            backgroundColor: defaultOptions.barBackgroundColor,
          }}
        >
          <ValueBar
            style={{
              position: 'absolute',
              borderRadius: Length.px(defaultOptions.barWidth),
              border: Border.none,
              height: Length.px(defaultOptions.barWidth),
              //width: Length.px(0),
              width: valueBarWidth,
              backgroundColor: defaultOptions.barColor,
            }}
          />
        </Bar>
        <Button
          style={{
            position: 'absolute',
            display: 'inline-block',
            borderRadius: Length.px(defaultOptions.buttonRadius),
            border: Border.none,
            height: Length.px(defaultOptions.buttonRadius * 2),
            width: Length.px(defaultOptions.buttonRadius * 2),
            backgroundColor: defaultOptions.buttonColor,
            filter: DropShadow(
              Length.px(0),
              Length.px(2),
              Length.px(4),
              Colors.shadowDark,
            ),
            transform: buttonTransform,
          }}
          onTouchStart={(evt) => {
            evt.stopPropagation();
            const drag = Drag.actions.onTouchStart(
              data.localState.drag,
              toTouchVector(evt),
            );
            return dispatch.slider(actions.updateDrag, drag);
          }}
          onTouchMove={(evt) => {
            evt.stopPropagation();
            const drag = Drag.actions.onTouchMove(
              data.localState.drag,
              toTouchVector(evt),
            );
            const value = getRadius(
              drag,
              data.button,
              data.bar,
              toTouchVector(evt),
            );
            // const valueInKm = toKm(
            //   value,
            //   props.maxValue,
            //   props.minValue,
            // );
            return (
              dispatch
                //return (props.onChange ? props.onChange(valueInKm) : Dispatch.none<Data, Parent>())//Dispatch.none<Data, Parent>()
                .slider(actions.updateValue, value)
                .slider(actions.updateDrag, drag)
            );
          }}
          onTouchEnd={(evt) => {
            evt.stopPropagation();
            const drag = Drag.actions.onTouchEnd(data.localState.drag);
            //return Dispatch.none<Data, Parent>()
            return dispatch
              .unsafeCast<DataMapDispatch<WithContext<Parent>>>((_) =>
                props.onChange
                  ? props.onChange({ value: valueInKm, dispatch: _ })
                  : _,
              )
              .slider(actions.updateDrag, drag);
          }}
        />
      </BarBox>
    </Container>
  );
}
