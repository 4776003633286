import React from 'react';
import {
  RemoteCommandAction,
  RemoteCommandName,
} from '../../apis/RemoteCommand';
import { execRemoteCommand } from '../../apis/RemoteCommandExecuter';
import { copy } from '../../util/Copyable';
import { Option, Some } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { LineSpace } from '../components/LineSpace';
import { SlideButton } from '../components/SlideButton';
import { SlideInCard } from '../components/SlideInCard';
import { ViewsBase } from '../components/View';
import { MessageKeys, createIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { getToastMessageId } from './Helpers';
import { ControllerState, RemoteState, RemoteStateItemName } from './Types';

type Key = 'remoteController';

type Data = Readonly<{
  localState: LocalState;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly isSpinning: boolean;
}

interface Actions extends CommandActions<Data, Command> {
  readonly updateIsSpinning: (data: Data, isSpinning: boolean) => Data;
}

type Command = 'None' | 'CallOnOpen' | 'CallOnClose';

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  remoteState: RemoteState;
  state: ControllerState;
  onChange?: (
    _: Readonly<{
      itemName: RemoteStateItemName;
      remoteState: RemoteState;
      dispatch: DP<Parent>;
    }>,
  ) => DP<Parent>;
  onOpen: (_: DP<Parent>) => DP<Parent>;
  onClose: (_: DP<Parent>) => DP<Parent>;
}>;

type InnerProps<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  titleId: MessageKeys;
  messageId: MessageKeys;
  buttonIcon: string;
  leftIcon: string;
  rightIcon: string;
  isEnabled: boolean;
  controllerState: ControllerState;
  isSendingCommand: boolean;
  onChange: Option<(_: DP<Parent>) => DP<Parent>>;
  onOpen: (_: DP<Parent>) => DP<Parent>;
  onClose: (_: DP<Parent>) => DP<Parent>;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type DMC<Parent> = WithContext<DM<Parent>>;
type DP<Parent> = DataMapDispatch<DMC<Parent>>;

// type SlideButtonData = ExtractData<typeof SlideButton>;

const commandState = CommandState<Data, Command>();

function getNextRemoteState(remoteState: RemoteState): RemoteState {
  return remoteState === 'Active' ? 'ToNormal' : 'ToActive';
}

function toRemoteCommandName(
  itemName: RemoteStateItemName,
): Exclude<RemoteCommandName, 'geofence'> {
  switch (itemName) {
    case 'DoorLock':
      return 'allDoors';
    case 'Light':
      return 'lights';
    case 'Hazard':
      return 'hazardLamp';
    case 'Ac':
    case 'Engine': // engine also uses 'airCondition' remote command.
      return 'airCondition';
  }
}

function toRemoteCommandAction(
  name: RemoteStateItemName,
  activate: boolean,
): RemoteCommandAction<Exclude<RemoteCommandName, 'geofence'>> {
  switch (name) {
    case 'DoorLock':
      return 'locked';
    default:
      return activate ? 'on' : 'off';
  }
}

function dispatchRemoteCommand<Parent>(
  context: Context,
  actions: Actions,
  itemName: RemoteStateItemName,
  activate: boolean,
  dispatch: DP<Parent>,
): DP<Parent> {
  return execRemoteCommand({
    commandName: toRemoteCommandName(itemName),
    commandAction: toRemoteCommandAction(itemName, activate),
    closeAction: dispatch.remoteController(
      actions.updateCommand,
      'CallOnClose',
    ),
    toastMessageId: Some(getToastMessageId(itemName, activate)),
    updateIsSpinning: (enable: boolean) =>
      dispatch.remoteController(actions.updateIsSpinning, enable),
    context,
    dispatch,
  });
}

function createInnerProps<Parent>(
  parent: DataMapStore<Parent>,
  remoteState: RemoteState,
  controllerState: ControllerState,
  onChange: Option<
    (
      _: Readonly<{
        itemName: RemoteStateItemName;
        remoteState: RemoteState;
        dispatch: DP<Parent>;
      }>,
    ) => DP<Parent>
  >,
  onOpen: (_: DP<Parent>) => DP<Parent>,
  onClose: (_: DP<Parent>) => DP<Parent>,
): InnerProps<Parent> {
  const itemName = controllerState.itemName;
  const nextState = getNextRemoteState(remoteState);
  const isEnabled = Scope(() => {
    switch (remoteState) {
      case 'Active':
        return true;
      case 'Normal':
        return false;
      case 'ToActive':
        return false;
      case 'ToNormal':
        return true;
    }
  });
  const commonProps = {
    controllerState,
    isSendingCommand: remoteState === 'ToActive' || remoteState === 'ToNormal',
    isEnabled,
    onChange: onChange.map(
      (f) => (dispatch: DP<Parent>) =>
        f({ itemName, remoteState: nextState, dispatch }),
    ),
    onOpen,
    onClose,
  };

  const defaultProps: InnerProps<Parent> = {
    parent,
    titleId: 'RemoteControl_lock',
    messageId: 'RemoteControl_lockMsg',
    buttonIcon: Icons.ic_lock_open_white,
    leftIcon: Icons.ic_lock_close_black,
    rightIcon: Icons.ic_lock_open_black,
    ...commonProps,
  };

  if (itemName === 'DoorLock' && isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_lock',
      messageId: 'RemoteControl_lockMsg',
      buttonIcon: Icons.ic_lock_open_white,
      leftIcon: Icons.ic_lock_close_black,
      rightIcon: Icons.ic_lock_open_black,
      ...commonProps,
    };
  } else if (itemName === 'DoorLock' && !isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_unLock',
      messageId: 'RemoteControl_unLockMsg',
      buttonIcon: Icons.ic_lock_open_white,
      leftIcon: Icons.ic_lock_close_white,
      rightIcon: Icons.ic_lock_open_white,
      ...commonProps,
    };
  } else if (itemName === 'Light' && isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_headLightOff',
      messageId: 'RemoteControl_headLightOffMsg',
      buttonIcon: Icons.ic_light_white,
      leftIcon: Icons.ic_slide_off_black,
      rightIcon: Icons.ic_slide_on_black,
      ...commonProps,
    };
  } else if (itemName === 'Light' && !isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_headLightOn',
      messageId: 'RemoteControl_headLightOnMsg',
      buttonIcon: Icons.ic_light_white,
      leftIcon: Icons.ic_slide_off_white,
      rightIcon: Icons.ic_slide_on_white,
      ...commonProps,
    };
  } else if (itemName === 'Hazard' && isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_hazardOff',
      messageId: 'RemoteControl_hazardOffMsg',
      buttonIcon: Icons.ic_hazard_white,
      leftIcon: Icons.ic_slide_off_black,
      rightIcon: Icons.ic_slide_on_black,
      ...commonProps,
    };
  } else if (itemName === 'Hazard' && !isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_hazardOn',
      messageId: 'RemoteControl_hazardOnMsg',
      buttonIcon: Icons.ic_hazard_white,
      leftIcon: Icons.ic_slide_off_white,
      rightIcon: Icons.ic_slide_on_white,
      ...commonProps,
    };
  } else if (itemName === 'Ac' && isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_airConOff',
      messageId: 'RemoteControl_airConOffMsg',
      buttonIcon: Icons.ic_ac_white,
      leftIcon: Icons.ic_slide_off_black,
      rightIcon: Icons.ic_slide_on_black,
      ...commonProps,
    };
  } else if (itemName === 'Ac' && !isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_airConOn',
      messageId: 'RemoteControl_airConOnMsg',
      buttonIcon: Icons.ic_ac_white,
      leftIcon: Icons.ic_slide_off_white,
      rightIcon: Icons.ic_slide_on_white,
      ...commonProps,
    };
  } else if (itemName === 'Engine' && isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_engineOff',
      messageId: 'RemoteControl_engineOffMsg',
      buttonIcon: Icons.ic_engine_white,
      leftIcon: Icons.ic_slide_off_black,
      rightIcon: Icons.ic_slide_on_black,
      ...commonProps,
    };
  } else if (itemName === 'Engine' && !isEnabled) {
    return {
      parent,
      titleId: 'RemoteControl_engineOn',
      messageId: 'RemoteControl_engineOnMsg',
      buttonIcon: Icons.ic_engine_white,
      leftIcon: Icons.ic_slide_off_white,
      rightIcon: Icons.ic_slide_on_white,
      ...commonProps,
    };
  } else {
    return defaultProps;
  }
}

function createBaseElement<Parent>(
  props: InnerProps<Parent>,
  stores: DataMapStore<DM<Parent>>,
  context: Context,
  views: ViewsBase<DMC<Parent>>,
): React.ReactElement {
  const View = views.default;
  const Text = View;
  const intl = createIntl(context);
  const store = stores.remoteController;
  const localState = store.data.localState;

  return (
    <SlideInCard
      parent={stores}
      state={props.controllerState.state}
      title={intl.formatMessage({ id: props.titleId })}
      closeIcon={Icons.ic_close_normal}
      minHeight={280}
      onOpen={(dispatch) =>
        dispatch.remoteController.bind(actions.updateCommand, 'CallOnOpen')
      }
      onClose={(_, dispatch) =>
        dispatch.remoteController.bind(actions.updateCommand, 'CallOnClose')
      }
    >
      <Text
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(20),
        }}
      >
        {intl.formatMessage({ id: props.messageId }).toReactNode()}
      </Text>

      <View
        style={{
          paddingTop: Length.px(20),
          paddingBottom: Length.px(14),
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SlideButton
            parent={stores}
            isEnabled={props.isEnabled}
            isSpinning={localState.isSpinning}
            buttonIcon={props.buttonIcon}
            leftIcon={props.leftIcon}
            rightIcon={props.rightIcon}
            onChange={({
              isEnabled: _,
              dispatch,
            }: Readonly<{
              isEnabled: boolean;
              dispatch: DP<Parent>;
            }>) =>
              dispatchRemoteCommand(
                context,
                actions,
                props.controllerState.itemName,
                !props.isEnabled,
                // context.selectedVehicle,
                dispatch,
              )
            }
          />
        </View>

        {props.isSendingCommand ? (
          <>
            <LineSpace height={Length.px(12)} />

            <Text
              style={{
                fontSize: Length.px(14),
                lineHeight: Length.px(14),
                color: Colors.textLight,
                textAlign: 'center',
              }}
            >
              {intl
                .formatMessage({ id: 'RemoteControlResult_cmdSending' })
                .toReactNode()}
            </Text>
          </>
        ) : (
          <LineSpace height={Length.px(26)} />
        )}
      </View>
    </SlideInCard>
  );
}

const actions: Actions = {
  ...commandState.actions,
  updateIsSpinning: (data: Data, isSpinning: boolean) =>
    copy(data, {
      localState: { isSpinning },
    }),
};

export function RemoteControllerPane<Parent>(
  props: Props<Parent>,
): React.ReactElement {
  const state = useStore<Key, Data, Parent>({
    key: 'remoteController',
    parent: props.parent,

    default: () => ({
      localState: {
        command: commandState.default,
        //isChanged: false,
        isSpinning: false,
      },
    }),

    update: ({ data: { remoteController }, dispatch }) => {
      const _props = createInnerProps(
        props.parent,
        props.remoteState,
        props.state,
        Option(props.onChange),
        props.onOpen,
        props.onClose,
      );

      return commandState.handleDataMap(
        'remoteController',
        remoteController,
        dispatch,
        (command) => {
          if (command === 'CallOnOpen') {
            return _props.onOpen(dispatch);
          } else if (command === 'CallOnClose') {
            return _props.onClose(dispatch);
          } else {
            return dispatch;
          }
        },
      );
    },
  });

  const context = state.data.context;
  const views = state.views;
  const _props = createInnerProps(
    props.parent,
    props.remoteState,
    props.state,
    Option(props.onChange),
    props.onOpen,
    props.onClose,
  );

  return createBaseElement(_props, state.stores, context, views);
}
