import React from 'react';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { Distance } from '../../util/Distance';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { HomeDataMap, HomeDispatch } from '../home/Home';
import { createIntl } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { Icons } from '../icons/Icons';
import { Context, ViewPort } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { TrackingMode, VehicleLocationState } from './VehicleLocationState';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  state: State;
  radius: Option<Distance>;
  trackingMode: TrackingMode;
  onOpenGeofenceSetting: () => HomeDispatch;
}>;

const geofencePaneHeight = Length.px(64);
const trackingModePaneHeight = Length.px(48);
export const menuPaneHeight = Length.px(
  geofencePaneHeight.value + trackingModePaneHeight.value,
);

export type State = 'Show' | 'Hide';

function getContainerStyle(
  context: Context,
  state: State,
  viewPort: ViewPort,
): OptionalStyles {
  const style: OptionalStyles = {
    position: 'absolute',
    left: Length.px(0),
    top: Length.px(
      viewPort.height -
        (state === 'Show'
          ? menuPaneHeight.value + context.screenSize.marginBottom
          : 0),
    ),
    width: Length.px(viewPort.width),
    backgroundColor: Colors.background,
    borderRadius: Length.px(0),
    filter: DropShadow(
      Length.px(0),
      Length.px(4),
      Length.px(12),
      Colors.shadowDark,
    ),
  };

  return style;
}

export function MenuPane(props: Props): React.ReactElement {
  const { views, dispatch } = useParentStore({
    parent: props.parent,
  });
  const View = views.default;
  const Text = View;
  const Container = views.home.localState.geofence.container;
  const GeofencePane = View;
  const GeofenceLeft = View;
  const GeofenceRight = View;
  const GeofenceSpinner = View;
  const HelpButtonPane = View;
  const TrackingModePane = View;
  const TrackingModeLink = View;
  const TrackingModeSpinner = View;
  const trackingMode = props.trackingMode;
  const context = React.useContext(Context.ref);
  const intl = createIntl(context);
  const viewPort = context.viewPort;

  const radius = props.radius
    .map((_) =>
      intl.formatMessage(
        { id: 'Location_geoFenceCurStatus' },
        { radius: Math.round(_.toKiloMeters()) },
      ),
    )
    .getOrElse(() => intl.formatMessage({ id: 'Location_geoFenceNoSetting' }));

  const containerStyle = getContainerStyle(context, props.state, viewPort);
  const isSpinnerRunning = context.ongoingRemoteStates.geofence;
  const isVehicleLocationAvailable = context.serviceTelemetry.data
    .toOption()
    .flatMap((_) => _.position)
    .unwrap(
      (_) => !_.isOld,
      () => false,
    );

  return (
    <Container id='LocationMenu' style={containerStyle}>
      <GeofencePane
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          height: geofencePaneHeight,
        }}
      >
        <GeofenceLeft
          style={{
            paddingTop: Length.px(14),
            paddingLeft: Length.px(16),
            width: Percentage(100),
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            if (isServiceAllowed(context, 'geofence')) {
              return isSpinnerRunning
                ? dispatch
                : props.onOpenGeofenceSetting();
            } else {
              return dispatch.context.bind(userRestrictionAction(context));
            }
          }}
        >
          <Text
            style={{
              fontSize: Length.px(12),
              lineHeight: Length.px(12),
              fontWeight: 'bold',
            }}
          >
            {intl.formatMessage({ id: 'Location_geoFenceTitle' }).toReactNode()}
          </Text>

          <View
            style={{
              height: Length.px(4),
            }}
          />

          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(16),
              color: Colors.accent,
            }}
          >
            {radius.toReactNode()}
          </Text>
        </GeofenceLeft>

        <GeofenceRight
          style={{
            display: 'flex',
            alignContent: 'center',
          }}
        >
          {Scope(() => {
            if (isSpinnerRunning)
              return (
                <GeofenceSpinner
                  style={{
                    paddingRight: Length.px(7.5),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <IcWaitingRed
                    style={{
                      width: Length.px(32),
                      height: Length.px(32),
                    }}
                    animate
                  />
                </GeofenceSpinner>
              );
            else return null;
          })}

          <HelpButtonPane
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: Length.px(48),
              marginTop: Length.px(12),
              marginBottom: Length.px(12),
              borderLeft: Border('solid', Length.px(0.5), Colors.border),
            }}
          >
            <View
              style={{
                width: Length.px(24),
                height: Length.px(24),
              }}
              onClick={(evt) =>
                dispatch
                  .effect(() => evt.stopPropagation())
                  .home(
                    VehicleLocationState.actions.updateHelpDialogState,
                    'Show',
                  )
              }
            >
              <IcButton
                image={Icons.ic_questionmark}
                imageWidth={Percentage(100)}
                imageHeight={Percentage(100)}
              />
            </View>
          </HelpButtonPane>
        </GeofenceRight>
      </GeofencePane>

      <TrackingModePane
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: Border('solid', Length.px(0.5), Colors.border),
          height: trackingModePaneHeight,
        }}
        onClick={(evt) => {
          evt.stopPropagation();
          if (!isServiceAllowed(context, 'theftTracking')) {
            return dispatch.context.bind(userRestrictionAction(context));
          }

          if (
            (trackingMode === 'Normal' && isVehicleLocationAvailable) ||
            trackingMode === 'Active'
          ) {
            return dispatch.home(
              VehicleLocationState.actions.updateTrackingPaneState,
              'Show',
            );
          } else {
            return dispatch;
          }
        }}
      >
        <TrackingModeLink
          style={{
            display: 'inline-block',
            paddingTop: Length.px(14),
            paddingLeft: Length.px(16),
          }}
        >
          <View
            style={{
              display: 'inline-block',
              backgroundColor:
                trackingMode === 'Active' || trackingMode === 'Normalizing'
                  ? Colors.errorHighlightBackground
                  : Colors.background,
              borderRadius: Length.px(2),
              padding: Length.px(2),
            }}
          >
            <Text
              style={{
                fontSize: Length.px(16),
                fontWeight: trackingMode === 'Normal' ? 'normal' : 'bold',
                lineHeight: Length.px(16),
                color:
                  trackingMode === 'Active' || trackingMode === 'Normalizing'
                    ? Colors.errorHighlightText
                    : isVehicleLocationAvailable
                    ? Colors.accent
                    : Colors.accentLight,
              }}
            >
              {intl
                .formatMessage({
                  id:
                    trackingMode === 'Active' || trackingMode === 'Normalizing'
                      ? 'Location_trackingStatusOn'
                      : trackingMode === 'Activating'
                      ? 'Location_trackingSettingTitleOn'
                      : 'Location_trackingTitle',
                })
                .toReactNode()}
            </Text>
          </View>
        </TrackingModeLink>

        {Scope(() => {
          if (trackingMode === 'Active' || trackingMode === 'Normal') {
            return <View />;
          } else {
            return (
              <TrackingModeSpinner
                style={{
                  paddingRight: Length.px(8),
                  paddingTop: Length.px(8),
                  display: 'inline-block',
                }}
              >
                <IcWaitingRed
                  style={{
                    width: Length.px(32),
                    height: Length.px(32),
                  }}
                  animate
                />
              </TrackingModeSpinner>
            );
          }
        })}
      </TrackingModePane>
    </Container>
  );
}
