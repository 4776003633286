/* eslint-disable @typescript-eslint/ban-types */
import { Anomaly } from './Error';
import { Failure, Result, Success } from './Result';
import { Unit } from './Unit';

export interface Option<A> {
  readonly kind: 'Option';
  readonly map: <B>(f: (a: A) => B) => Option<B>;
  readonly flatMap: <B>(f: (a: A) => Option<B>) => Option<B>;
  readonly forEach: (f: (a: A) => void) => void;
  readonly every: (f: (a: A) => boolean) => boolean;
  readonly some: (f: (a: A) => boolean) => boolean;
  readonly orElse: (f: () => Option<A>) => Option<A>;
  readonly getOrElse: (f: () => A) => A;
  readonly getOrNull: () => A | null;
  readonly getOrUndefined: () => A | undefined;
  readonly onResult: (onSome: (a: A) => void, onNone: () => void) => void;
  readonly transform: <B>(
    s: (a: A) => Option<B>,
    n: () => Option<B>,
  ) => Option<B>;
  readonly unwrap: <B>(s: (a: A) => B, n: () => B) => B;
  readonly isNone: () => boolean;
  readonly equals: (a: Option<A>) => boolean;
  readonly toResult: (e?: Anomaly) => Result<A>;
  readonly getUnsafeValue: () => A;
  readonly toString: () => string;
  readonly toJson: () => OptionJson<A>;
  readonly toPromise: (err: Error) => Promise<A>;
}

export type OptionJson<A> = {
  value?: A;
};

interface Statics {
  readonly kind: 'Option';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly none: Option<any>;
  readonly fromBoolean: (a: boolean) => Option<Unit>;
  readonly fromJson: <A>(a: OptionJson<A>) => Option<A>;
  join<A, B>(a: Option<A>, b: Option<B>): Option<[A, B]>;
  join<A, B, C>(a: Option<A>, b: Option<B>, c: Option<C>): Option<[A, B, C]>;
  join<A, B, C, D>(
    a: Option<A>,
    b: Option<B>,
    c: Option<C>,
    d: Option<D>,
  ): Option<[A, B, C, D]>;
  join<A, B, C, D, E>(
    a: Option<A>,
    b: Option<B>,
    c: Option<C>,
    d: Option<D>,
    e: Option<E>,
  ): Option<[A, B, C, D, E]>;
}

interface Factory {
  <A>(a: A | null | undefined): Option<A>;
}

function create<A>(a: A | null | undefined): Option<A> {
  if (typeof a === 'boolean')
    throw Error('boolean is not available here. Use Option.fromBoolean');
  return a === null || a === undefined ? None<A>() : Some(a);
}

export function Some<A>(a: A): Option<A> {
  const self: Option<A> = {
    kind: 'Option',
    map: <B>(f: (a: A) => B) => Some(f(a)),
    flatMap: <B>(f: (a: A) => Option<B>) => f(a),
    forEach: (f: (a: A) => void) => f(a),
    every: (f: (a: A) => boolean) => f(a),
    some: (f: (a: A) => boolean) => f(a),
    orElse: (_f: () => Option<A>) => Some(a),
    getOrElse: (_f: () => A) => a,
    getOrNull: () => a,
    getOrUndefined: () => a,
    onResult: (onSome: (a: A) => void, _onNone: () => void) => onSome(a),
    transform: <B>(s: (a: A) => Option<B>, _n: () => Option<B>) => s(a),
    unwrap: <B>(s: (a: A) => B, _n: () => B) => s(a),
    isNone: () => false,
    equals: (b: Option<A>) => b.map((b) => a === b).getOrElse(() => false),
    toResult: () => Success(a),
    getUnsafeValue: () => a,
    toString: () => `Some(${a})`,
    toJson: () => ({ value: a }),
    toPromise: () => Promise.resolve(a),
  };
  return self;
}

export function None<A>(): Option<A> {
  const self: Option<A> = {
    kind: 'Option',
    map: <B>(_f: (a: A) => B) => None<B>(),
    flatMap: <B>(_f: (a: A) => Option<B>) => None<B>(),
    forEach: (_f: (a: A) => void) => {
      return;
    },
    every: (_f: (a: A) => boolean) => true,
    some: (_f: (a: A) => boolean) => false,
    orElse: (f: () => Option<A>) => f(),
    getOrElse: (f: () => A) => f(),
    getOrNull: () => null,
    getOrUndefined: () => undefined,
    onResult: (_onSome: (a: A) => void, onNone: () => void) => onNone(),
    transform: <B>(s: (a: A) => Option<B>, n: () => Option<B>) => n(),
    unwrap: <B>(s: (a: A) => B, n: () => B) => n(),
    isNone: () => true,
    equals: (b: Option<A>) => b.isNone(),
    toResult: (e: Anomaly = Anomaly.unknownError) => Failure(e),
    getUnsafeValue: () => {
      throw Error('None does not have value');
    },
    toString: () => `None`,
    toJson: () => ({}),
    toPromise: (err: Error) => Promise.reject(err),
  };
  return self;
}

function unsafeJoinNHelper(args: Option<{}>[]): Option<{}[]> {
  if (args.length === 0) return Option<{}[]>([]);
  else {
    const [fx, ...tail] = args;
    return fx.flatMap((x) =>
      unsafeJoinNHelper(tail).map((xs) => [x].concat(xs)),
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function unsafeJoinN(): any {
  // eslint-disable-next-line prefer-rest-params
  return unsafeJoinNHelper(Array.from(arguments));
}

const statics: Statics = {
  kind: 'Option',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  none: None<any>(),
  fromBoolean: (a: boolean) => {
    if (a === true) return Some(Unit);
    else return None();
  },
  fromJson: <A>(a: OptionJson<A>) => {
    if (a.value === undefined) return None<A>();
    else return Some<A>(a.value);
  },
  join: unsafeJoinN,
};

export const Option: Factory & Statics = Object.assign(create, statics);
