import { theme } from '../../util/Theme';

export interface RGBA {
  readonly red: number;
  readonly green: number;
  readonly blue: number;
  readonly alpha: number;
  readonly toStyle: () => string;
}

function create(red: number, green: number, blue: number, alpha = 1): RGBA {
  const text = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
  return {
    red,
    green,
    blue,
    alpha,
    toStyle: (): string => text,
  };
}

function alpha(original: RGBA, alpha: number): RGBA {
  return create(original.red, original.green, original.blue, alpha);
}

const black = create(0, 0, 0);
const white = create(0xff, 0xff, 0xff);
const gray = create(0x66, 0x66, 0x66);
const lightGray = create(0xdd, 0xdd, 0xdd);
const red = create(0xfa, 0x00, 0x14);
const orange = create(0xff, 0x66, 0x00);
const transparent = create(0, 0, 0, 0);

const daihatsuPrimary = red;
const daihatsuPrimaryDark = create(0xbb, 0x02, 0x11);

const peroduaPrimary = create(0x33, 0x33, 0x33);
const peroduaPrimaryDark = black;
const peroduaAccent = create(0x0, 0x71, 0xbb);

export const Colors = {
  primary: theme(daihatsuPrimary, peroduaPrimary),
  primaryPressed: theme(daihatsuPrimaryDark, peroduaPrimaryDark),
  primaryDark: create(0xcc, 0x01, 0x11),
  primaryDarkPressed: create(0x9c, 0x02, 0x0c),
  secondary: create(0x6a, 0x6b, 0x73),
  secondaryPressed: create(0x3a, 0x3b, 0x40),
  accent: theme(daihatsuPrimary, peroduaAccent),
  accentLight: alpha(theme(daihatsuPrimary, peroduaAccent), 0.25),

  text: black,
  textLight: gray,
  textMoreLight: create(0x55, 0x55, 0x55),
  errorText: red,
  errorHighlightText: theme(white, red),
  warningText: orange,
  buttonText: white,
  buttonTextLight: alpha(white, 0.4),
  buttonTextMoreLight: alpha(white, 0.25),
  disabledButtonText: create(0x88, 0x88, 0x88),

  background: white,
  backgroundDark: alpha(black, 0.05),
  backgroundMoreDark: alpha(black, 0.08),
  componentBackground: lightGray,
  errorHighlightBackground: theme(red, white),
  overlayCover: alpha(black, 0.4),
  border: alpha(black, 0.16),
  borderLight: alpha(black, 0.1),
  shadow: alpha(black, 0.2),
  shadowDark: alpha(black, 0.25),
  shadowMoreDark: alpha(black, 0.3),
  transparent,

  thickBorder: create(0xcc, 0xcc, 0xcc),
  normalActionForeground: theme(create(0x00, 0xb0, 0xf2), peroduaAccent),
  cardBadgeForeground: theme(white, gray),
  cardBadgeBackground: theme(red, transparent),
  engineProhibitForeground: theme(red, create(0xff, 0x99, 0x00)),
  engineProhibitBackground: theme(white, transparent),
  mapBackground: create(0xee, 0xee, 0xee),
  shadowWidget: alpha(black, 0.5),
  sliderForeground: theme(daihatsuPrimaryDark, peroduaAccent),
  slideActiveBackground: theme(daihatsuPrimaryDark, lightGray),
  toggleActiveBackground: create(0x00, 0xa4, 0xe2),
  vehicleAlertForeground: theme(red, white),
  vehicleAlertBackground: theme(transparent, peroduaAccent),

  mockBackground: alpha(black, 0),
  mockBackgroundDark: alpha(black, 0.8),

  disabledOpacity: 0.3,
};
