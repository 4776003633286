import React from 'react';
import { EmptyVehicleEvent, VehicleEvent } from '../../apis/EventTelemetry';
import { openLink } from '../../apis/NativeApi';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { EngineProhibitButton } from '../components/EngineProhibitButton';
import { LineSpace } from '../components/LineSpace';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowMode,
} from '../components/SlideInWindow';
import { TermsOverlay } from '../google/TermsOverlay';
import { HomeDataMap } from '../home/Home';
import { EventIdMap } from '../i18n/EventIdMap';
import { Message, useIntl } from '../i18n/Intl';
import { EventId } from '../i18n/Types';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { MapView } from './MapView';
import {
  VehicleAlertCardActions,
  VehicleAlertCardData,
} from './VehicleAlertCard';

type DM = ExtendParent<HomeDataMap, 'vehicleAlert', VehicleAlertCardData>;

type Props = Readonly<{
  event: VehicleEvent | EmptyVehicleEvent;
  mode: SlideInWindowMode;
  parent: DataMapStore<DM>;
}>;

const engineButtonEvents = ['1010', '1011', '1021', '1022', '1031'];

function isEngineEvent(eventId: EventId): boolean {
  return engineButtonEvents.some((_) => _ === eventId);
}

function ListItem<Parent>({
  parent,
  message,
  onClick,
  borderTop,
  borderBottom,
}: Readonly<{
  parent: DataMapStore<Parent>;
  message: Message;
  onClick?: () => DataMapDispatch<WithContext<Parent>>;
  borderTop?: Length;
  borderBottom?: Length;
}>): React.ReactElement {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent });

  return (
    <View
      style={{
        paddingTop: Length.px(14),
        paddingBottom: Length.px(18),
        borderTop: borderTop
          ? Border('solid', borderTop, Colors.border)
          : undefined,
        borderBottom: borderBottom
          ? Border('solid', borderBottom, Colors.border)
          : undefined,
        boxSizing: 'border-box',
        height: Length.px(48),
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        return Option(onClick).unwrap(
          (f) => f(),
          () => dispatch,
        );
      }}
    >
      <Text
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(16),
          color: Colors.accent,
        }}
      >
        {message.toReactNode()}
      </Text>
    </View>
  );
}

function HintPane<Parent>({
  parent,
  event,
}: Readonly<{
  parent: DataMapStore<Parent>;
  event: VehicleEvent | EmptyVehicleEvent;
}>): React.ReactElement {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent });
  const Hint = View;
  const List = View;
  const intl = useIntl();

  return (
    <>
      <LineSpace height={Length.px(8)} />
      <Hint
        style={{
          paddingLeft: Length.px(16),
          paddingRight: Length.px(16),
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            lineHeight: Length.px(20),
          }}
        >
          {intl
            .formatMessage({ id: EventIdMap.get(event.eventId).alert.body })
            .toReactNode()}
        </Text>
      </Hint>

      <LineSpace height={Length.px(21)} />

      <List
        style={{
          paddingLeft: Length.px(16),
        }}
      >
        <ListItem
          parent={parent}
          message={intl.formatMessage({ id: 'VehicleAlert_openContacts' })}
          onClick={() => {
            openLink('content://com.android.contacts/contacts');
            return dispatch;
          }}
          borderTop={Length.px(0.5)}
        />

        <ListItem
          parent={parent}
          message={intl.formatMessage({ id: 'VehicleAlert_phoneCallCenter' })}
          borderTop={Length.px(0.5)}
          borderBottom={Length.px(0.5)}
        />
      </List>
    </>
  );
}

function EngineButtonPane<Parent>({
  parent,
  onClickEngineProhibitButton,
}: Readonly<{
  parent: DataMapStore<Parent>;
  onClickEngineProhibitButton?: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
}>): React.ReactElement {
  const {
    views: { View },
    data: { context },
    dispatch,
  } = useParentStore({ parent });
  const List = View;
  const intl = useIntl();
  const prohibit = context.serviceTelemetry.data
    .toOption()
    .some((_) => _.dsc_eng_prohibit);

  return (
    <>
      <LineSpace height={Length.px(20)} />

      <EngineProhibitButton
        prohibit={prohibit}
        parent={parent}
        onClick={() =>
          Option(onClickEngineProhibitButton).unwrap(
            (f) => f(dispatch),
            () => dispatch,
          )
        }
      />

      <LineSpace height={Length.px(21)} />

      <List
        style={{
          paddingLeft: Length.px(16),
        }}
      >
        <ListItem
          parent={parent}
          message={intl.formatMessage({ id: 'VehicleAlert_openContacts' })}
          onClick={() => {
            openLink('content://com.android.contacts/contacts');
            return dispatch;
          }}
          borderTop={Length.px(0.5)}
        />

        <ListItem
          parent={parent}
          message={intl.formatMessage({ id: 'VehicleAlert_phoneCarEmergency' })}
          borderTop={Length.px(0.5)}
        />

        <ListItem
          parent={parent}
          message={intl.formatMessage({ id: 'VehicleAlert_contactDealer' })}
          borderTop={Length.px(0.5)}
        />

        <ListItem
          parent={parent}
          message={intl.formatMessage({ id: 'VehicleAlert_searchDealer' })}
          borderTop={Length.px(0.5)}
          borderBottom={Length.px(0.5)}
        />
      </List>
    </>
  );
}

export function FulfillBody<Parent>({
  event,
  parentId,
  parent,
  onClickEngineProhibitButton,
}: Readonly<{
  event: VehicleEvent | EmptyVehicleEvent;
  parentId: string;
  parent: DataMapStore<Parent>;
  onClickEngineProhibitButton?: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
}>): React.ReactElement {
  const {
    views: { View, Image, Text },
  } = useParentStore({
    parent,
  });
  const MapContainer = View;
  const intl = useIntl();
  const icon = EventIdMap.get(event.eventId).image.normal;

  return (
    <View
      style={{
        opacity: event.kind === 'VehicleEvent' ? 1 : 0,
      }}
    >
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flexGrow: 0,
            paddingLeft: Length.px(12),
            paddingRight: Length.px(12),
          }}
        >
          <Image
            src={icon}
            style={{
              width: Length.px(40),
              height: Length.px(40),
            }}
          />
        </View>
        <View
          style={{
            flexGrow: 1,
            paddingTop: Length.px(20),
            paddingRight: Length.px(16),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(14),
              fontWeight: 'bold',
            }}
          >
            {intl.formatDate(new Date(event.timestamp))}{' '}
            {intl.formatTime(new Date(event.timestamp))}
          </Text>
          <Text
            style={{
              paddingTop: Length.px(4),
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
            }}
          >
            {intl
              .formatMessage({ id: EventIdMap.get(event.eventId).alert.title })
              .toReactNode()}
          </Text>
        </View>
      </View>

      <LineSpace height={Length.px(20)} />

      <MapContainer
        style={{
          position: 'relative',
          marginLeft: Length.px(16),
          marginRight: Length.px(16),
        }}
      >
        <MapView
          id={`${parentId}_map`}
          center={
            event.kind === 'VehicleEvent'
              ? event.position.getOrUndefined()
              : undefined
          }
        />

        <TermsOverlay
          state={
            event.kind === 'VehicleEvent' && !event.position.isNone()
              ? 'Show'
              : 'Hide'
          }
        />
      </MapContainer>

      {Scope(() => {
        if (isEngineEvent(event.eventId)) {
          return (
            <EngineButtonPane
              parent={parent}
              onClickEngineProhibitButton={onClickEngineProhibitButton}
            />
          );
        } else {
          return <HintPane parent={parent} event={event} />;
        }
      })}
    </View>
  );
}

export function DetailedWindow({
  event,
  mode,
  parent,
}: Props): React.ReactElement {
  const intl = useIntl();
  const context = React.useContext(Context.ref);

  return (
    <SlideInWindow
      parent={parent}
      mode={mode}
      title={intl.formatMessage({ id: 'VehicleAlert_pageTitle' })}
      headerLeft={
        <HeaderLeft
          parent={parent}
          onClick={(dispatch) =>
            dispatch.context(Context.actions.updateSelectedVehicleEvent, {
              vehicleEventWindowMode: 'Hide',
            })
          }
        />
      }
      onClose={(dispatch) =>
        dispatch
          .context(Context.actions.updateCardInfo, {
            state: 'Closing',
          })
          .context(Context.actions.updateSelectedVehicleEvent, {
            vehicleEventWindowMode: 'Close',
          })
      }
    >
      <FulfillBody
        event={event}
        parentId='vehicleAlert'
        parent={parent}
        onClickEngineProhibitButton={(dispatch) =>
          isServiceAllowed(context, 'engineRestartProhibit')
            ? dispatch.vehicleAlert(
                VehicleAlertCardActions.updateControllerState,
                'Show',
              )
            : dispatch.context.bind(userRestrictionAction(context))
        }
      />
    </SlideInWindow>
  );
}
