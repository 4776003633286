import { Time } from './Time';
import { TimingFunction } from './TimingFunction';

export interface Transition {
  readonly name: string;
  readonly duration: Time;
  readonly timingFunction: TimingFunction;
  readonly delay: Time;
  readonly toStyle: () => string;
}

export function Transition(
  name: string,
  duration: Time,
  timingFunction: TimingFunction = TimingFunction.easeInOut,
  delay: Time = Time(0, 's'),
): Transition {
  const text = `${name} ${duration.toStyle()} ${timingFunction.toStyle()} ${delay.toStyle()}`;
  return {
    name,
    duration,
    timingFunction,
    delay,
    toStyle: () => text,
  };
}
