import { Pipable, toPipe } from '../../util/Pipe';
import { Unit } from '../../util/Unit';
import { Action as ActionType } from './Reducer';

type Action<Data, Args> = ActionType<Data, Args>;

interface BindMethod<Data> {
  (action: (data: Data) => Data): ActionBinder<Data>;
  <Args>(action: Action<Data, Args>, args: Args): ActionBinder<Data>;
}

interface ActionBinderMembers<Data> {
  readonly isPipable: true;
  readonly compose: (actionBinder: ActionBinder<Data>) => ActionBinder<Data>;
  readonly bind: BindMethod<Data>;
  readonly pipe: <A>(f: (a: ActionBinder<Data>) => A) => Pipable<A>;
  readonly apply: (data: Data) => Data;
}

export interface ActionBinder<Data> extends ActionBinderMembers<Data> {
  (data: Data): Data;
}

export function createActionBinder<Data, Args>(
  action: Action<Data, Args>,
  args: Args,
): ActionBinder<Data> {
  const apply: (data: Data) => Data = (data: Data) => action(data, args);

  function compose(actionBinder: ActionBinder<Data>): ActionBinder<Data> {
    return createActionBinder(
      (data: Data) => actionBinder(action(data, args)),
      Unit,
    );
  }

  function bind1<_Args2>(action2: (data: Data) => Data): ActionBinder<Data> {
    return createActionBinder(
      (data: Data) => action2(action(data, args)),
      Unit,
    );
  }

  function bind2<Args2>(
    action2: Action<Data, Args2>,
    args2: Args2,
  ): ActionBinder<Data> {
    return createActionBinder(
      (data: Data, args2: Args2) => action2(action(data, args), args2),
      args2,
    );
  }

  function bind(): ActionBinder<Data> {
    // eslint-disable-next-line prefer-rest-params
    if (arguments.length === 1) return bind1(arguments[0]);
    // eslint-disable-next-line prefer-rest-params
    else return bind2(arguments[0], arguments[1]);
  }

  function pipe<A>(f: (a: ActionBinder<Data>) => A): Pipable<A> {
    return toPipe(f(self));
  }

  const members: ActionBinderMembers<Data> = {
    isPipable: true,
    compose,
    bind,
    pipe,
    apply,
  };

  const self: ActionBinder<Data> = Object.assign(apply, members);

  return self;
}
