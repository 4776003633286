import { BuildOpt } from './BuildOpt';

export type Target = 'Daihatsu' | 'Perodua';

export function getThemeTarget(): Target {
  return theme('Daihatsu', 'Perodua');
}

export function theme<T>(daihatsu: T, perodua: T): T {
  switch (BuildOpt.env) {
    case 'mock':
    case 'dev':
    case 'qa':
    case 'devjp':
    case 'qajp':
    case 'stgjp':
    case 'prod':
      return daihatsu;
    case 'mockp':
    case 'devmy':
    case 'qamy':
    case 'stgmy':
      return perodua;
  }
}
