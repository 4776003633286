import React from 'react';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { copy } from '../../util/Copyable';
import { Lazy } from '../../util/Lazy';
import { Scope } from '../../util/Scope';
import { Card, CardState } from '../components/Card';
import { SlideInCardState } from '../components/SlideInCard';
import { SlideInWindowMode } from '../components/SlideInWindow';
import { HomeData, HomeDataMap } from '../home/Home';
import { MessageKeys, useIntl } from '../i18n/Intl';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import { ExtendParent, WithContext, useStore } from '../states/DataMapStore';
import { Store } from '../states/Store';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { ClosedView } from './ClosedView';
import { MaintenanceHistory } from './MaintenanceHistory';
import { OpenedView } from './OpenedView';
import { ResetPane } from './ResetPane';

type Key = 'serviceReminder';
export type ServiceReminderData = Data;
export type ServiceReminderDataMap = DM;

type Data = Readonly<{
  localState: LocalState;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly cardState: CardState;
  readonly maintenanceHistoryMode: SlideInWindowMode;
  readonly resetPaneTitleId: MessageKeys;
  readonly resetPaneState: SlideInCardState;
}

export type Command = 'None';

interface Actions extends CommandActions<Data, Command> {
  readonly updateCardState: (data: Data, cardState: CardState) => Data;
  readonly updateMaintenanceHistoryMode: (
    data: Data,
    maintenanceHistoryMode: SlideInWindowMode,
  ) => Data;
  readonly updateResetPaneTitleId: (
    data: Data,
    resetPaneTitleId: MessageKeys,
  ) => Data;
  readonly updateResetPaneState: (
    data: Data,
    resetPaneState: SlideInCardState,
  ) => Data;
}

type Props = Readonly<{
  store: Store<HomeData>;
}>;

type Parent = HomeDataMap;
type DM = ExtendParent<Parent, Key, Data>;
type DMC = WithContext<DM>;

const commandState = CommandState<Data, Command>();

export const ServiceReminderActions: Lazy<Actions> = Lazy(() => ({
  ...commandState.actions,
  updateCardState: (data: Data, cardState: CardState) =>
    copy(data, {
      localState: {
        cardState,
      },
    }),

  updateMaintenanceHistoryMode: (
    data: Data,
    maintenanceHistoryMode: SlideInWindowMode,
  ) =>
    copy(data, {
      localState: {
        maintenanceHistoryMode,
      },
    }),

  updateResetPaneTitleId: (data: Data, resetPaneTitleId: MessageKeys) =>
    copy(data, {
      localState: {
        resetPaneTitleId,
      },
    }),

  updateResetPaneState: (data: Data, resetPaneState: SlideInCardState) =>
    copy(data, {
      localState: {
        resetPaneState,
      },
    }),
}));

export function ServiceReminderCard(props: Props): React.ReactElement {
  const state = useStore<Key, Data, Parent, Props>({
    key: 'serviceReminder',
    props,
    parent: { home: props.store },

    default: () => ({
      localState: {
        command: commandState.default,
        cardState: 'Closed',
        maintenanceHistoryMode: 'Hide',
        resetPaneTitleId: 'Remainder_mEngineOil',
        resetPaneState: 'Hide',
      },
    }),

    initialize: (data: Data) => data,

    update: ({ data: { context, serviceReminder: data }, dispatch }) => {
      const localState = data.localState;
      const actions = ServiceReminderActions.get();
      return commandState
        .handleDataMap<DMC>(
          'serviceReminder',
          data,
          dispatch,
          (_command) => dispatch,
        )
        .pipe((_) => {
          if (
            context.nativeBackAction === 'InCard' &&
            localState.cardState === 'Opened'
          ) {
            const none = _.context.bind(
              Context.actions.updateNativeBackAction,
              'None',
            );
            if (
              context.alertDialog.state === 'Hide' &&
              context.confirmDialog.state === 'Hide'
            ) {
              if (localState.maintenanceHistoryMode === 'Show') {
                return none.serviceReminder(
                  actions.updateMaintenanceHistoryMode,
                  'Hide',
                );
              } else if (localState.resetPaneState === 'Show') {
                return none.serviceReminder(
                  actions.updateResetPaneState,
                  'Hide',
                );
              } else {
                return _.context(
                  Context.actions.updateNativeBackAction,
                  'TopLevel',
                );
              }
            } else {
              return none;
            }
          } else {
            return _;
          }
        });
    },
  });

  const { View, Text } = state.views;
  const intl = useIntl();
  const context = state.data.context;
  const localState = state.data.serviceReminder.localState;
  const actions = ServiceReminderActions.get();
  const isDataError = false;
  const isLoaded =
    (context.contentState === 'Loaded' ||
      context.contentState === 'Reload' ||
      context.contentState === 'Updating') &&
    !isDataError;

  return (
    <Card
      cardName='ServiceReminder'
      parent={state.stores}
      badgeName={intl.formatMessage({ id: 'Remainder_pageTitle' })}
      title={intl.formatMessage({ id: 'Remainder_pageTitle' })}
      reloadable={false}
      openable={true}
      contentState={context.contentState}
      overlayState='Hide'
      onOpen={(dispatch) =>
        dispatch.serviceReminder.bind(actions.updateCardState, 'Opening')
      }
      onClose={(dispatch) =>
        dispatch.serviceReminder.bind(actions.updateCardState, 'Closing')
      }
      onOpened={(dispatch) =>
        dispatch.serviceReminder.bind(actions.updateCardState, 'Opened')
      }
      onClosed={(dispatch) =>
        dispatch.serviceReminder.bind(actions.updateCardState, 'Closed')
      }
      onReload={(dispatch) => dispatch}
      onClickOverride={
        !isServiceAllowed(context, 'serviceReminder')
          ? (dispatch) => dispatch.context.bind(userRestrictionAction(context))
          : undefined
      }
      extras={
        <>
          <MaintenanceHistory
            mode={localState.maintenanceHistoryMode}
            parent={state.stores}
          />

          <ResetPane
            titleId={localState.resetPaneTitleId}
            // command === 'OpenResetPane',
            state={localState.resetPaneState}
            parent={state.stores}
          />
        </>
      }
    >
      <View
        style={{
          opacity: isLoaded ? 1 : 0,
        }}
      >
        {localState.cardState === 'Closed' ||
        localState.cardState === 'Closing' ? (
          <ClosedView />
        ) : (
          <OpenedView parent={state.stores} />
        )}
      </View>

      {Scope(() => {
        const EmptyView = <></>;
        const ErrorView = (
          <View
            style={{
              position: 'absolute',
              top: Length.px(0),
              height: Percentage(100),
              width: Percentage(100),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                fontSize: Length.px(14),
              }}
            >
              {intl.formatMessage({ id: 'Common_syncError' }).toReactNode()}
            </Text>
          </View>
        );

        switch (context.contentState) {
          case 'Loaded':
            if (isDataError) return ErrorView;
            else return EmptyView;
          default:
            return EmptyView;
        }
      })}
    </Card>
  );
}
