import React from 'react';
import { None, Option, Some } from '../../util/Option';
import { Action } from './Reducer';

type StoreState<Data> = Readonly<{
  readonly isInitialized: boolean;
  readonly data: Data;
}>;

export type Dispatchable<Data> = Readonly<{
  setState: (state: Store<Data>) => void;
  dispatch: Dispatcher<Data>;
  dispatchAsync: Dispatcher<Data>;
}>;

export type Store<Data> = StoreState<Data>;

export type DispatchableStore<Data> = Dispatchable<Data> & Store<Data>;

export type Dispatcher<Data> = <Args>(
  action: Action<Data, Args>,
  args: Args,
) => void;

type AsyncAction<Data> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly action: Action<Data, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly args: any;
};

export type ExtractData<S> = S extends Store<infer D> ? D : never;

export function useDataStore<Data>(
  create: () => Data,
  initialize?: (data: Data) => Data,
): Store<Data> {
  const [state, setState] = React.useState<StoreState<Data>>(() => ({
    isInitialized: false,
    data: create(),
  }));

  const [asyncAction, setAsyncAction] = React.useState<
    Option<AsyncAction<Data>>
  >(None());

  React.useEffect(() => {
    if (!state.isInitialized) {
      Option(initialize)
        .orElse(() => Some((data) => data))
        .map((f) => f(state.data))
        .forEach((data) =>
          setState({
            ...state,
            isInitialized: true,
            data,
          }),
        );
    }
  }, [state, initialize]);

  const store: DispatchableStore<Data> = {
    isInitialized: state.isInitialized,
    data: state.data,

    setState,

    dispatch: <Args>(action: Action<Data, Args>, args: Args) => {
      const data = action(state.data, args);
      setState({
        ...state,
        data,
      });
    },

    dispatchAsync: <Args>(action: Action<Data, Args>, args: Args) => {
      setAsyncAction(
        Some({
          action,
          args,
        }),
      );
    },
  };

  React.useEffect(() => {
    asyncAction.forEach((event) => {
      const data = event.action(store.data, event.args);
      setState({
        ...state,
        data,
      });
      // setAsyncAction(None());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asyncAction]);

  return store;
}
