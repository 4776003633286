import { Option } from '../../util/Option';

const googleMapsDomain = 'maps.googleapis.com';
const apiKey = 'AIzaSyBybg4inPFTpFKgXYC1SlTFZNUI5AhE7dQ';

export type GoogleMap = google.maps.Map;
export type Circle = google.maps.Circle;
export type Marker = google.maps.Marker;
export type LatLng = google.maps.LatLng;
export type LatLngBounds = google.maps.LatLngBounds;
type Constructor = (
  e: HTMLElement,
  options: google.maps.MapOptions,
) => google.maps.Map;

export const createGoogleMap: Promise<Constructor> = getConstructor();

const script = window.document.createElement('script');
window.document.head.appendChild(script);

export function loadGoogleMap(
  callback: (constructor: Constructor) => void,
): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).googleMaps = {
    initialize: () => {
      callback((e, opts) => new google.maps.Map(e, opts));
    },
  };
  if (!script.src) {
    script.src = `https://${googleMapsDomain}/maps/api/js?key=${apiKey}&callback=googleMaps.initialize`;
  }
}

function getConstructor(): Promise<Constructor> {
  return new Promise((resolve, reject) => {
    window.onload = () => {
      try {
        loadGoogleMap(resolve);
      } catch (err) {
        reject(err);
      }
    };
  });
}

export async function GoogleMap(
  id: string,
  options: google.maps.MapOptions,
): Promise<GoogleMap> {
  return createGoogleMap.then((f) =>
    Option(document.getElementById(id))
      .map((e) => Promise.resolve(f(e, options)))
      .getOrElse(() => Promise.reject(Error(`No such element: ${id}`))),
  );
}
