import {
  Auth,
  NotificationCategoryKey,
  NotificationSettings,
} from '@auto/monaka-client/dist/20';
import { getDB, updateDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { getErrorMessage } from '../util/Error';
import { Option } from '../util/Option';
import { sleep } from '../util/Sleep';
import { AppSyncClient } from './Client';
import { println } from './NativeApi';

export type NotificationStatus = {
  readonly vin: string;
  readonly settings: NotificationSettings[];
};

export function defaultNotificationStatus(vin: string): NotificationStatus {
  return {
    vin,
    settings: [
      {
        categoryKey: 'vehicleWarning',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'ukkari',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'remoteControl',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'geofence',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'engineStart',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'serviceReminder',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'wifi',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'general',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'accident',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'carSharing',
        displayFlg: true,
        pushEnabled: true,
      },
      {
        categoryKey: 'theftAlert',
        displayFlg: true,
        pushEnabled: true,
      },
    ],
  };
}
export async function getNotificationStatus(
  vin: string,
): Promise<NotificationStatus> {
  if (BuildOpt.isMock()) {
    await sleep(500);
    const db = await getDB();
    const error = () =>
      Promise.reject(Error(`Failed to get notification settings(vin=${vin}).`));
    if (vin === 'vin-1' && db.notificationStatusErrorVin1) return error();
    else
      return Option(db.notificationStatuses.find((s) => s.vin === vin)).unwrap(
        (s) => Promise.resolve(s),
        () => error(),
      );
  } else {
    const gSysUserId = Option(await Auth.currentDriver())
      .map((_) => _.gSysUserId)
      .getOrElse(() => 'unknown');
    return AppSyncClient.getNotificationSettings({ gSysUserId, vin })
      .then((a) => ({
        vin,
        settings: a,
      }))
      .catch((err) =>
        Promise.reject(
          Error(
            `Error occured on getNotificationStatus(vin=${vin}). ${getErrorMessage(
              err,
            )}`,
          ),
        ),
      );
  }
}

export async function setNotificationSettings(
  notificationStatus: NotificationStatus,
  notificationStatusOrig: NotificationStatus,
): Promise<void> {
  if (BuildOpt.isMock()) {
    await sleep(500);
    const db = await getDB();
    await updateDB(db, {
      notificationStatuses: db.notificationStatuses.map((n) =>
        n.vin === notificationStatus.vin ? notificationStatus : n,
      ),
    });
    return;
  } else {
    const on: NotificationCategoryKey[] = notificationStatus.settings.flatMap(
      (s, i) =>
        s.pushEnabled === true &&
        notificationStatusOrig.settings[i].pushEnabled === false
          ? [s.categoryKey as NotificationCategoryKey]
          : [],
    );
    const off: NotificationCategoryKey[] = notificationStatus.settings.flatMap(
      (s, i) =>
        s.pushEnabled === false &&
        notificationStatusOrig.settings[i].pushEnabled === true
          ? [s.categoryKey as NotificationCategoryKey]
          : [],
    );
    println(`setNotificationSettings on=${on}`);
    println(`setNotificationSettings off=${off}`);
    const gSysUserId = Option(await Auth.currentDriver())
      .map((_) => _.gSysUserId)
      .getOrElse(() => 'unknown');
    return AppSyncClient.setNotificationSettings({
      gSysUserId,
      vin: notificationStatus.vin,
      on,
      off,
    })
      .then()
      .catch((err) =>
        Promise.reject(
          Error(
            `Error occured on getNotificationStatus(vin=${
              notificationStatus.vin
            }). ${getErrorMessage(err)}`,
          ),
        ),
      );
  }
}
