import React from 'react';
import { Scope } from '../../util/Scope';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore, WithContext } from '../states/DataMapStore';
import { DispatchMethodBase, dispatchNothing } from '../states/DispatchBase';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transition } from '../style/Transition';

type Props<DataMap> = Readonly<{
  isEnabled: boolean;
  isSpinning: boolean;
  parent: DataMapStore<DataMap>;
  onChange?: (isEnabled: boolean) => DispatchMethodBase<WithContext<DataMap>>;
}>;

const width = Length.px(72);
const radius = Length.px(24);
const buttonRadius = Length.px(20);
const spinnerRadius = Length.px(16);

export function ToggleSwitch<DataMap>(
  props: Props<DataMap>,
): React.ReactElement {
  const {
    views: { View },
  } = useParentStore({ parent: props.parent });
  const Container = View;
  const Button = View;

  const left = Scope(() => {
    if (!props.isEnabled) {
      return Length.px(radius.value - buttonRadius.value);
    } else {
      return Length.px(width.value - radius.value - buttonRadius.value);
    }
  });

  return (
    <Container
      style={{
        position: 'relative',
        width,
        height: Length.px(radius.value * 2),
        backgroundColor: props.isEnabled
          ? Colors.toggleActiveBackground
          : Colors.componentBackground,
        borderRadius: radius,
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        if (props.isSpinning) {
          return dispatchNothing();
        } else {
          return props.onChange
            ? props.onChange(!props.isEnabled)
            : dispatchNothing();
        }
      }}
    >
      <Button
        style={{
          position: 'relative',
          width: Length.px(buttonRadius.value * 2),
          height: Length.px(buttonRadius.value * 2),
          left,
          top: Length.px(radius.value - buttonRadius.value),
          borderRadius: buttonRadius,
          filter: DropShadow(
            Length.px(0),
            Length.px(2),
            Length.px(4),
            Colors.shadowDark,
          ),
          backgroundColor: Colors.background,
          transition: Transition('left', Time.ms(300), TimingFunction.easeOut),
        }}
      >
        {Scope(() => {
          if (props.isSpinning)
            return (
              <IcWaitingRed
                style={{
                  position: 'relative',
                  top: Length.px(buttonRadius.value - spinnerRadius.value),
                  left: Length.px(buttonRadius.value - spinnerRadius.value),
                  width: Length.px(spinnerRadius.value * 2),
                  height: Length.px(spinnerRadius.value * 2),
                }}
                animate
              />
            );
          else return <View />;
        })}
      </Button>
    </Container>
  );
}
