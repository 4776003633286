type Unit = 's' | 'ms';

export interface Time {
  readonly value: number;
  readonly unit: Unit;
  readonly toStyle: () => string;
}

export interface Statics {
  readonly s: (value: number) => Time;
  readonly ms: (value: number) => Time;
}

export interface Factory {
  (value: number, unit: Unit): Time;
}

function create(value: number, unit: Unit): Time {
  const text = `${value}${unit}`;
  return {
    value,
    unit,
    toStyle: () => text,
  };
}

const statics: Statics = {
  s: (value: number) => create(value, 's'),
  ms: (value: number) => create(value, 'ms'),
};

export const Time: Factory & Statics = Object.assign(create, statics);
