import { GeoPos, Geofence } from '../../apis/Geofence';
import { UserLocation, println } from '../../apis/NativeApi';
import { ToOptional, copy } from '../../util/Copyable';
import { Distance } from '../../util/Distance';
import { getErrorMessage } from '../../util/Error';
import { Listener, Sender } from '../../util/Listener';
import { None, Option, Some } from '../../util/Option';
import { Result } from '../../util/Result';
import { Unit } from '../../util/Unit';
import { CardState } from '../components/Card';
import { State as HelpDialogState } from '../components/HelpDialog';
import { Shape } from '../components/Shape';
import { SlideInCardState } from '../components/SlideInCard';
import { Circle, GoogleMap, Marker } from '../google/GoogleMap';
import { HomeData, HomeDataMap, HomeDispatch } from '../home/Home';
import { createIntl } from '../i18n/Intl';
import { CommandLocalState, newCommandState } from '../states/CommandState';
import { Context } from '../states/Context';
import {
  DataMapDispatch,
  UpdateArgs,
  WithContext,
} from '../states/DataMapStore';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { startUserLocation } from './Helpers';
import {
  createMapData,
  getZoom,
  listenBoundsChanged,
  renderMap,
  setZoom,
} from './MapUtils';
import { Actions, createActions } from './VehicleLocationCardActions';

export type VehicleLocationData = NestedData;

interface NestedData {
  readonly kind: 'VehicleLocationData';
  readonly localState: LocalState;
  readonly container: Shape;
}

interface LocalState extends CommandLocalState<Command> {
  readonly contentLoaded: boolean;
  readonly cardState: CardState;
  readonly mapData: Option<MapData>;
  readonly userLocation: 'Loading' | Result<UserLocation>;
  readonly userLocationStopper: Option<Sender<Unit>>;
  readonly hasContentLoadedListener: boolean;
  readonly googleMapState: GoogleMapState;
  readonly geofenceCache: Option<Geofence>;
  readonly radiusSender: Sender<number>;
  readonly radiusListener: Listener<number>;
  readonly mapMode: MapMode;
  readonly initialRange: Option<Range>;
  readonly settingPaneState: SlideInCardState;
  readonly helpDialogState: HelpDialogState;
  readonly trackingPaneState: SlideInCardState;
  readonly isTrackingSlideButtonSpinning: boolean;
}

export type Command = MapCommand;

export type MapCommand =
  | 'RenderGeofence'
  | 'PanToDefault'
  | 'AdjustGeofenceZoom'
  | RenderMap;

export type RenderMap = {
  readonly name: 'RenderMap';
  readonly options: RenderMapOptions;
};

export type RenderMapOptions = {
  readonly disablePanToDefault: boolean;
  readonly disableAdjustGeofenceZoom: boolean;
  readonly isUserLocationPolling: boolean;
};

export interface MapData {
  readonly googleMap: GoogleMap;
  readonly vehicle: Marker;
  readonly user: Marker;
  readonly circle: Circle;
  readonly center: Marker;
}

export type GoogleMapState = 'None' | 'Loading' | 'Loaded';

export type MapMode = 'Open' | 'Close' | 'Geofence';

export type TrackingMode = 'Normal' | 'Active' | 'Normalizing' | 'Activating';

export type GpsState = 'Normal' | 'NoPermission' | 'Unavailable';

export interface UpdateGoogleMapArgs {
  readonly center: GeoPos;
  readonly zoom: number;
}

export interface ButtonState {
  readonly default: boolean;
}

export type DispatchOnClose<Parent extends { home: HomeData }> =
  DataMapDispatch<WithContext<Parent>>;

type VehicleLocationState = Readonly<{
  create: (
    args: Readonly<{
      containerStyle: OptionalStyles;
    }>,
  ) => NestedData;

  initialize: (_: NestedData) => NestedData;

  actions: Actions;

  update: <Props>(
    _: UpdateArgs<Props, WithContext<HomeDataMap>> &
      Readonly<{
        mapId: string;
      }>,
  ) => HomeDispatch;

  onOpen: (context: Context, dispatch: HomeDispatch) => HomeDispatch;
  onOpened: (dispatch: HomeDispatch) => HomeDispatch;
  onClose: (data: HomeData, dispatch: HomeDispatch) => HomeDispatch;
  onClosed: (dispatch: HomeDispatch) => HomeDispatch;
}>;

export const commandState = newCommandState<HomeData, Command>({
  get: (data) => data.localState.geofence.localState.command,
  update: (command) => ({
    localState: {
      geofence: {
        localState: {
          command,
        },
      },
    },
  }),
});

const actions = createActions(commandState);

export const defaultRadius = Distance.kiloMeters(10);
export const minGeofenceRadius = Distance.kiloMeters(1);

export const vehicleLocationCardHeight = Length.px(120);

export function RenderMap(
  options: ToOptional<RenderMapOptions> = {},
): RenderMap {
  return {
    name: 'RenderMap',
    options: {
      disablePanToDefault: options.disablePanToDefault === true,
      disableAdjustGeofenceZoom: options.disableAdjustGeofenceZoom === true,
      isUserLocationPolling: options.isUserLocationPolling === true,
    },
  };
}

export function isRenderMap(
  command: MapCommand | 'None',
): command is RenderMap {
  return (command as RenderMap).name === 'RenderMap';
}

export function loadMap(
  mapId: string,
  localState: LocalState,
  dispatch: HomeDispatch,
): HomeDispatch {
  const actions = VehicleLocationState.actions;

  return localState.mapData.unwrap(
    () => dispatch,
    () =>
      dispatch.home(actions.updateGoogleMapState, 'Loading').asyncAll(
        createMapData(mapId)
          .then((mapData) =>
            dispatch
              .home(actions.updateGoogleMapState, 'Loaded')
              .home((data) =>
                copy(data, {
                  localState: {
                    geofence: { localState: { mapData: Some(mapData) } },
                  },
                }),
              )
              .home(actions.updateCommand, RenderMap())
              .asyncAll(
                listenBoundsChanged(mapData.googleMap).map(() => {
                  return dispatch.home((data) => {
                    if (
                      data.localState.geofence.localState.mapMode === 'Open'
                    ) {
                      const zoom = mapData.googleMap.getZoom();
                      const prevZoom = getZoom();
                      if (zoom !== undefined && zoom !== prevZoom) {
                        setZoom(zoom);
                      }
                    }
                    return actions.updateGeofenceCacheCenter(data);
                  });
                }),
              ),
          )
          .catch((err) => dispatch.effect(() => println(getErrorMessage(err)))),
      ),
  );
}

export const VehicleLocationState: VehicleLocationState = {
  create: ({ containerStyle }) => {
    const [radiusListener, radiusSender] = Listener.withSender<number>();
    return {
      kind: 'VehicleLocationData',
      localState: {
        command: commandState.default,
        contentLoaded: false,
        cardState: 'Closed',
        mapData: None(),
        userLocation: 'Loading',
        userLocationStopper: None(),
        hasContentLoadedListener: false,
        googleMapState: 'None',
        geofenceCache: None(),
        radiusListener,
        radiusSender,
        mapMode: 'Close',
        initialRange: None<Range>(),
        settingPaneState: 'Hide',
        helpDialogState: 'Hide',
        trackingPaneState: 'Hide',
        isTrackingSlideButtonSpinning: false,
      },
      container: Shape(containerStyle),
    };
  },

  initialize: (data) => data,

  actions,

  update: ({ mapId, data: { context, home: parent }, dispatch }) => {
    const actions = VehicleLocationState.actions;
    const localState = parent.localState.geofence.localState;
    const intl = createIntl(context);
    const viewPort = context.viewPort;

    return (
      dispatch
        .effect(() => renderMap(parent.localState.geofence, context))
        .compose(
          commandState.handleDataMap(
            'home',
            parent,
            dispatch,
            (_command) => dispatch,
          ),
        )
        .pipe((_) =>
          localState.contentLoaded === true
            ? dispatch
                .home(actions.updateContentLoaded, false)
                .pipe((_) =>
                  localState.userLocationStopper.unwrap(
                    () => _,
                    () =>
                      Option.fromBoolean(
                        localState.cardState === 'Opened',
                      ).unwrap(
                        () => _.compose(startUserLocation(intl, dispatch)),
                        () => _,
                      ),
                  ),
                )
                .pipe((_) =>
                  localState.googleMapState === 'None'
                    ? _.compose(loadMap(mapId, localState, dispatch))
                    : _,
                )
                .home(actions.updateCommand, RenderMap())
            : _,
        )
        .pipe((_) =>
          Option.fromBoolean(localState.hasContentLoadedListener).unwrap(
            () => _,
            () =>
              _.home(actions.updateHasContentLoadedListener, true).asyncAll(
                context.contentLoadedListener.map(() =>
                  dispatch.home(actions.updateContentLoaded, true),
                ),
              ),
          ),
        )
        .pipe((_) => {
          return context.selectedVehicleEvent
            .map((e) => {
              switch (e.kind) {
                case 'StateInfo':
                  switch (e.name) {
                    case 'trackingOn':
                      return _.home(
                        actions.updateTrackingPaneState,
                        'Show',
                      ).context.bind(
                        Context.actions.updateSelectedVehicleEvent,
                        { vehicleEventWindowMode: 'Hide' },
                      );
                    case 'engineProhibit':
                      return _;
                    default:
                      return _;
                  }
                case 'VehicleEvent':
                  return _;
                case 'EmptyVehicleEvent':
                  return _;
                default:
                  return _;
              }
            })
            .getOrElse(() => _);
        })
        // A native back action behavior
        .pipe((_) => {
          if (
            context.nativeBackAction === 'InCard' &&
            localState.cardState === 'Opened'
          ) {
            const none = _.context.bind(
              Context.actions.updateNativeBackAction,
              'None',
            );
            if (
              context.alertDialog.state === 'Hide' &&
              context.confirmDialog.state === 'Hide'
            ) {
              if (localState.settingPaneState === 'Show') {
                return none.home(actions.onSettingPaneClose, viewPort);
              } else if (localState.trackingPaneState === 'Show') {
                return none.home(actions.updateTrackingPaneState, 'Hide');
              } else if (localState.helpDialogState === 'Show') {
                return none.home(actions.updateHelpDialogState, 'Hide');
              } else {
                return _.context.bind(
                  Context.actions.updateNativeBackAction,
                  'TopLevel',
                );
              }
            } else {
              return none;
            }
          } else {
            return _;
          }
        })
    );
  },

  onOpen: (context: Context, dispatch: HomeDispatch) =>
    dispatch.home
      .put(actions.onOpen, context.viewPort)
      .compose(startUserLocation(createIntl(context), dispatch)),

  onOpened: (dispatch) =>
    dispatch
      .home(actions.updateOpenState, 'Opened')
      .home.put(actions.updateCommand, 'PanToDefault'),

  onClose: (data, dispatch) =>
    dispatch
      .home(actions.onClose)
      .home.put(actions.updateSettingPaneState, 'Hide')
      .pipe((_) =>
        data.localState.geofence.localState.userLocationStopper.unwrap(
          (stopper) => _.send(stopper, Unit),
          () => _,
        ),
      ),

  onClosed: (dispatch) => dispatch.home(actions.onClosed),
};
