import { genVehicleEvent } from '../../apis/EventTelemetry';
import { RawNotification, sendNotificationEvent } from '../../apis/NativeApi';
import { ServiceTelemetry } from '../../apis/ServiceTelemetry';
import {
  Vehicle,
  getSelectedVehicle,
  getUnselectedVehicle,
} from '../../apis/VehicleList';
import { ToOptional } from '../../util/Copyable';
import { getErrorMessage } from '../../util/Error';
import { Option } from '../../util/Option';
import { EventId } from '../i18n/Types';
import { Context } from '../states/Context';
import {
  DB,
  addVehicleEvent,
  updateDB,
  updateServiceTelemetry,
} from './Database';
import { DebugPaneActions, DebugPaneDispatch } from './DebugPane';

export type DataError = {
  readonly kind: 'DataError';
  readonly message: string;
};

export function DataError(message: string): DataError {
  return {
    kind: 'DataError',
    message,
  };
}

export function dispatchDB(
  db: DB,
  f: (() => Promise<DB>) | ToOptional<DB>,
  dispatch: DebugPaneDispatch,
  options: { readonly close?: boolean } = { close: false },
): DebugPaneDispatch {
  const actions = DebugPaneActions;
  if (typeof f === 'function') {
    const g = f;
    return dispatch.debug(actions.updateDataState, 'Loading').asyncAll(
      g()
        .then((db) =>
          dispatch
            .debug(actions.updateDataState, 'Loaded')
            .debug(actions.updateDB, db)
            .pipe((_) =>
              options.close === true
                ? _.debug(actions.updateState, 'Close')
                : _,
            ),
        )
        .catch((err) =>
          dispatch.debug(
            actions.updateDataState,
            DataError(getErrorMessage(err)),
          ),
        ),
    );
  } else {
    const g = f;
    return dispatchDB(db, () => updateDB(db, g), dispatch, options);
  }
}

export function dispatchDBWithVehicle(
  db: DB,
  context: Context,
  f: (vehicle: Vehicle) => Promise<DB>,
  dispatch: DebugPaneDispatch,
  options: {
    readonly close?: boolean;
    readonly unselectedVehicle?: boolean;
  } = {
    close: false,
    unselectedVehicle: false,
  },
): DebugPaneDispatch {
  const actions = DebugPaneActions;
  return dispatch.pipe((_) =>
    (options.unselectedVehicle === true
      ? getUnselectedVehicle(context)
      : getSelectedVehicle(context)
    ).unwrap(
      (vehicle) =>
        _.compose(dispatchDB(db, () => f(vehicle), dispatch, options)),
      () =>
        _.debug(
          actions.updateDataState,
          DataError(
            `No ${
              options.unselectedVehicle === true ? 'unselected' : 'selected'
            } vehicle`,
          ),
        ),
    ),
  );
}

export function dispatchServiceTelemetry(
  db: DB,
  context: Context,
  newData:
    | ToOptional<ServiceTelemetry>
    | ((s: ServiceTelemetry) => ToOptional<ServiceTelemetry>),
  dispatch: DebugPaneDispatch,
): DebugPaneDispatch {
  return dispatchDBWithVehicle(
    db,
    context,
    (vehicle) => updateServiceTelemetry(db, vehicle.vin, newData),
    dispatch,
  );
}

export function dispatchNotification(
  db: DB,
  context: Context,
  notification: (vehicle: Vehicle) => RawNotification,
  dispatch: DebugPaneDispatch,
  options: {
    readonly unselectedVehicle?: boolean;
  } = { unselectedVehicle: false },
): DebugPaneDispatch {
  return dispatchDBWithVehicle(
    db,
    context,
    async (vehicle) => {
      sendNotificationEvent(notification(vehicle));
      return db;
    },
    dispatch,
    {
      close: true,
      ...options,
    },
  );
}

export function dispatchVehicleEvent(
  db: DB,
  context: Context,
  eventId: EventId,
  notification: (vehicle: Vehicle) => RawNotification,
  dispatch: DebugPaneDispatch,
  options: {
    readonly unselectedVehicle?: boolean;
  } = {
    unselectedVehicle: false,
  },
): DebugPaneDispatch {
  return dispatchDBWithVehicle(
    db,
    context,
    (vehicle) =>
      genVehicleEvent(vehicle.vin, eventId).then((event) =>
        addVehicleEvent(db, event).then((db) => {
          sendNotificationEvent(notification(vehicle));
          return db;
        }),
      ),
    dispatch,
    {
      close: true,
      ...options,
    },
  );
}

export function dispatchServiceTelemetryEvent(
  db: DB,
  context: Context,
  newData: ToOptional<ServiceTelemetry>,
  notification: (vehicle: Vehicle) => RawNotification,
  dispatch: DebugPaneDispatch,
  options: {
    readonly unselectedVehicle?: boolean;
    readonly vin?: string;
  } = {
    unselectedVehicle: false,
  },
): DebugPaneDispatch {
  return dispatchDBWithVehicle(
    db,
    context,
    (vehicle) =>
      updateServiceTelemetry(db, options.vin ?? vehicle.vin, newData).then(
        (db) => {
          sendNotificationEvent(notification(vehicle));
          return db;
        },
      ),
    dispatch,
    {
      close: true,
      ...options,
    },
  );
}

export function serviceTelemetryState(
  db: DB,
  context: Context,
  f: (s: ServiceTelemetry) => boolean,
): boolean {
  return context.selectedVehicleVin.some((vin) =>
    Option(db.serviceTelemetries.find((s) => s.vin === vin)).some(f),
  );
}

export function getServiceTelemetryItem(
  db: DB,
  context: Context,
  f: (s: ServiceTelemetry) => string,
): string {
  return context.selectedVehicleVin
    .flatMap((vin) =>
      Option(db.serviceTelemetries.find((s) => s.vin === vin)).map(f),
    )
    .getOrElse(() => '-');
}
