import BuildIcon from '@material-ui/icons/Build';
import LanguageIcon from '@material-ui/icons/Language';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React from 'react';
import { config } from '../../Config';
import { BuildOpt } from '../../util/BuildOpt';
import { useParentStore } from '../states/ContextStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { GridLine } from '../style/GridLine';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { ParentProps } from './DebugPane';
import { Item } from './Item';
import { Subtitle } from './Subtitle';

export function InformationPane({ parent }: ParentProps): React.ReactElement {
  const {
    views: { View, Text },
    data: { context, debug: data },
  } = useParentStore({ parent });

  const rowStyle: OptionalStyles = {
    paddingTop: Length.px(5),
    paddingRight: Length.px(5),
    paddingBottom: Length.px(5),
  };

  const rowBorderStyle: OptionalStyles = {
    ...rowStyle,
    borderTop: Border('solid', Length.px(1), Colors.background),
  };

  const rowBodyStyle: OptionalStyles = {
    paddingTop: Length.px(5),
    paddingBottom: Length.px(5),
    wordBreak: 'break-all',
  };

  const rowBorderBodyStyle: OptionalStyles = {
    ...rowBodyStyle,
    borderTop: Border('solid', Length.px(1), Colors.background),
  };

  return (
    <>
      <Subtitle title='Information' />

      <Item
        parent={parent}
        icon={<BuildIcon />}
        text={
          <View style={{ flexGrow: 1, display: 'grid' }}>
            <Text
              style={{
                gridRow: GridLine(1, 2),
                gridColumn: GridLine(1, 2),
                ...rowStyle,
              }}
            >
              OS
            </Text>
            <Text
              style={{
                gridRow: GridLine(2, 3),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              App
            </Text>
            <Text
              style={{
                gridRow: GridLine(3, 4),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              Build number
            </Text>
            <Text
              style={{
                gridRow: GridLine(4, 5),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              UI
            </Text>
            <Text
              style={{
                gridRow: GridLine(5, 6),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              Client
            </Text>

            <Text
              style={{
                gridRow: GridLine(1, 2),
                gridColumn: GridLine(2, 3),
                ...rowBodyStyle,
              }}
            >
              {context.platform.os}
            </Text>
            <Text
              style={{
                gridRow: GridLine(2, 3),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {context.platform.appVersion}
            </Text>
            <Text
              style={{
                gridRow: GridLine(3, 4),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {context.platform.buildNumber}
            </Text>
            <Text
              style={{
                gridRow: GridLine(4, 5),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {config.version}
            </Text>
            <Text
              style={{
                gridRow: GridLine(5, 6),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {config.clientVersion}
            </Text>
          </View>
        }
      />

      <Item
        parent={parent}
        icon={<LanguageIcon />}
        text={
          <View style={{ flexGrow: 1, display: 'grid' }}>
            <Text
              style={{
                gridRow: GridLine(1, 2),
                gridColumn: GridLine(1, 2),
                ...rowStyle,
              }}
            >
              Env
            </Text>
            <Text
              style={{
                gridRow: GridLine(2, 3),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              Auth
            </Text>
            <Text
              style={{
                gridRow: GridLine(3, 4),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              AppSync
            </Text>
            <Text
              style={{
                gridRow: GridLine(4, 5),
                gridColumn: GridLine(1, 2),
                ...rowBorderStyle,
              }}
            >
              IoT
            </Text>

            <Text
              style={{
                gridRow: GridLine(1, 2),
                gridColumn: GridLine(2, 3),
                ...rowBodyStyle,
              }}
            >
              {BuildOpt.env}
            </Text>
            <Text
              style={{
                gridRow: GridLine(2, 3),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {config[BuildOpt.env].authEndpoint || '-'}
            </Text>
            <Text
              style={{
                gridRow: GridLine(3, 4),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {config[BuildOpt.env].appSyncEndpoint || '-'}
            </Text>
            <Text
              style={{
                gridRow: GridLine(4, 5),
                gridColumn: GridLine(2, 3),
                ...rowBorderBodyStyle,
              }}
            >
              {config[BuildOpt.env].iotEndpoint || '-'}
            </Text>
          </View>
        }
      />

      <Item
        parent={parent}
        icon={<VpnKeyIcon />}
        text={data.deviceToken.unwrap(
          (token) => (
            <View key='deviceToken' style={{ flexGrow: 1, display: 'grid' }}>
              <Text
                style={{
                  gridRow: GridLine(1, 2),
                  gridColumn: GridLine(1, 2),
                  ...rowStyle,
                }}
              >
                Token
              </Text>

              <Text
                style={{
                  gridRow: GridLine(1, 2),
                  gridColumn: GridLine(2, 3),
                  ...rowBodyStyle,
                }}
              >
                {token}
              </Text>
            </View>
          ),
          () => (
            <View>No device token</View>
          ),
        )}
      />
    </>
  );
}
