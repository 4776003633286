import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { Option } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { isContentLoading } from '../home/Helpers';
import { Message } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';
import { Shape } from './Shape';
import { ShapeViewBase } from './View';

type Key = 'slideInWindow';

export type SlideInWindowData = Data;
export type SlideInWindowDispatch<Parent> = EDP<Parent>;

type Data = Readonly<{
  headerLeft: Shape;
  headerCenter: Shape;
  headerRight: Shape;
}>;

type Props<Parent> = Readonly<{
  id?: string;
  parent: DataMapStore<Parent>;
  children?: React.ReactNode;
  mode: SlideInWindowMode;
  title?: Message;
  subTitle?: React.ReactElement;
  headerLeft?: React.ReactElement;
  direction?: 'horizontal' | 'vertical'; // default: 'horizontal'
  zIndex?: number;
  onOpen?: (_: EDP<Parent>) => EDP<Parent>;
  onOpened?: (_: EDP<Parent>) => EDP<Parent>;
  onClose?: (_: EDP<Parent>) => EDP<Parent>;
  onClosed?: (_: EDP<Parent>) => EDP<Parent>;
  disableCloseWhileLoading?: boolean;
}>;

export type SlideInWindowMode = 'Show' | 'Hide' | 'Close';

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type EDP<Parent> = DataMapDispatch<WithContext<Parent>>;

export const headerHeight = Length.px(48);
const paddingLeft = Length.px(4);
const imageHeight = Length.px(48);
const imageWidth = Length.px(48);
const midSpace = Length.px(4 / 2);

const animationDuration = Time.ms(300);

export function HeaderLeft<Parent>({
  parent,
  onClick,
}: {
  parent: DataMapStore<Parent>;
  onClick?: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
}): React.ReactElement {
  const {
    views: { View },
    dispatch,
  } = useParentStore({ parent });
  const Left = View;

  return (
    <Left
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: paddingLeft,
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        return Option(onClick).unwrap(
          (f) => f(dispatch),
          () => dispatch,
        );
      }}
    >
      <View
        style={{
          display: 'inline-block',
          marginRight: midSpace,
        }}
      >
        <IcButton
          image={Icons.ic_back_normal}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
        />
      </View>
    </Left>
  );
}

export function SlideInWindow<Parent>(
  props: Props<Parent>,
): React.ReactElement {
  const state = useStore<Key, Data, Parent, Props<Parent>>({
    key: 'slideInWindow',
    props: props,
    parent: props.parent,

    default: () => ({
      headerLeft: Shape({}),
      headerCenter: Shape({}),
      headerRight: Shape({}),
    }),

    update: ({ data: { context }, prev, dispatch }) =>
      context.geofenceWindowMode === 'Show' &&
      prev.some(
        (_) => _.data.context.geofenceWindowMode !== context.geofenceWindowMode,
      )
        ? Option(props.onOpen)
            .map((f) => dispatch.compose(dispatch.unsafeCast<EDP<Parent>>(f)))
            .getOrElse(() => dispatch)
        : dispatch,
  });

  const { View, Text } = state.views;
  const OverLay = View;
  const Container = View;
  const Header = View;
  const Body = View;
  const Center = state.views.slideInWindow.headerCenter as ShapeViewBase<
    WithContext<DM<Parent>>
  >;
  const Left = state.views.slideInWindow.headerLeft as ShapeViewBase<
    WithContext<DM<Parent>>
  >;
  const Right = state.views.slideInWindow.headerRight as ShapeViewBase<
    WithContext<DM<Parent>>
  >;
  const viewPort = state.data.context.viewPort;
  const data = state.data.slideInWindow;
  const dispatch = state.dispatch;
  const prev = state.prev;
  const context = state.data.context;

  const headerHeightValue =
    headerHeight.value + (props.subTitle == null ? 0 : 8);

  const overlayStyle = Scope<OptionalStyles>(() => {
    const startColor = Colors.transparent;
    const endColor = Colors.shadow;

    const top = Scope(() => {
      switch (props.direction) {
        case 'vertical':
          return Length.px(0);
        case 'horizontal':
        default:
          return Length.px(headerHeightValue);
      }
    });
    const style: OptionalStyles = {
      position: 'fixed',
      top,
      left: Length.px(0),
      width: Length.px(viewPort.width),
      height: Length.px(viewPort.height),
      zIndex: props.zIndex,
      pointerEvents: 'none',
      backgroundColor: startColor,
    };
    const result = Scope(() => {
      switch (props.mode) {
        case 'Show':
          return {
            ...style,
            backgroundColor: endColor,
            transition: Transition(
              'background-color',
              animationDuration,
              TimingFunction.easeOut,
            ),
          };
        case 'Hide':
        case 'Close':
          return {
            ...style,
            backgroundColor: startColor,
            transition: [
              Transition(
                'background-color',
                animationDuration,
                TimingFunction.easeIn,
              ),
            ],
          };
      }
    });
    return result;
  });
  const containerStyle = Scope<OptionalStyles>(() => {
    const animationTransform = Transition(
      'transform',
      animationDuration,
      TimingFunction.easeOut,
    );
    const waitAnimationTransform = Transition(
      'transform',
      Time.ms(0),
      TimingFunction.easeOut,
      animationDuration,
    );
    const {
      startTop,
      startLeft,
      transformMatrix,
      showTransition,
      hideTransition,
    } =
      props.direction === 'vertical'
        ? {
            startTop: Length.px(viewPort.height),
            startLeft: Length.px(0),
            transformMatrix: Matrix3x3.translate(0, -viewPort.height),
            showTransition: [animationTransform],
            hideTransition: [animationTransform],
          }
        : {
            startTop: Length.px(0),
            startLeft: Length.px(viewPort.width),
            transformMatrix: Matrix3x3.translate(-viewPort.width, 0),
            showTransition: [],
            hideTransition: [waitAnimationTransform],
          };
    const style: OptionalStyles = {
      position: 'fixed',
      top: startTop,
      left: startLeft,
      width: Length.px(viewPort.width),
      height: Length.px(viewPort.height),
      zIndex: props.zIndex,
      opacity: 0,
    };
    const result = Scope(() => {
      switch (props.mode) {
        case 'Show':
          return {
            ...style,
            opacity: 1,
            transform: Transform(transformMatrix),
            transition: [...showTransition],
          };
        case 'Hide':
          return {
            ...style,
            opacity: 0,
            transform: Transform(Matrix3x3.translate(0, 0)),
            transition: [
              ...hideTransition,
              Transition(
                'opacity',
                Time.ms(0),
                TimingFunction.easeOut,
                animationDuration,
              ),
            ],
          };
        case 'Close':
          return {
            ...style,
            opacity: 0,
            transform: Transform(Matrix3x3.translate(0, 0)),
            transition: [
              waitAnimationTransform,
              Transition(
                'opacity',
                Time.ms(0),
                TimingFunction.easeOut,
                animationDuration,
              ),
            ],
          };
      }
    });
    if (prev.some((_) => _.props.mode === props.mode)) {
      // State unchanged. Do not initiate animation.
      return {
        ...result,
        transition: [],
      };
    }
    return result;
  });
  const headerStyle = Scope<OptionalStyles>(() => {
    if (props.direction === 'vertical') {
      return {};
    }
    const startOpacity = 0;
    const endOpacity = 1;
    const result = Scope(() => {
      switch (props.mode) {
        case 'Show':
          return {
            opacity: endOpacity,
            transition: Transition('opacity', animationDuration),
          };
        case 'Hide':
          return {
            opacity: startOpacity,
            transition: Transition('opacity', animationDuration),
          };
        case 'Close':
          return {
            opacity: startOpacity,
            transition: Transition(
              'opacity',
              Time.ms(0),
              TimingFunction.easeInOut,
              animationDuration,
            ),
          };
      }
    });
    return result;
  });
  const bodyStyle = Scope<OptionalStyles>(() => {
    if (props.direction === 'vertical') {
      return {};
    }
    const startLeft = Length.px(viewPort.width);
    const endLeft = Length.px(0);
    const result = Scope(() => {
      switch (props.mode) {
        case 'Show':
          return {
            left: endLeft,
            transition: Transition(
              'left',
              animationDuration,
              TimingFunction.easeOut,
            ),
          };
        case 'Hide':
          return {
            left: startLeft,
            transition: Transition(
              'left',
              animationDuration,
              TimingFunction.easeOut,
            ),
          };
        case 'Close':
          return {
            left: startLeft,
            transition: Transition(
              'left',
              Time.ms(0),
              TimingFunction.easeOut,
              animationDuration,
            ),
          };
      }
    });
    return result;
  });

  type HeaderStyles = [OptionalStyles, OptionalStyles];
  const [leftStyle, rightStyle] = Pipe<HeaderStyles>([
    {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  ])
    .map(([left, right]) =>
      data.headerCenter
        .getBound()
        .map<HeaderStyles>((centerBound) => {
          const width = viewPort.width;
          const centerWidth = centerBound.width;
          const sideWidth = (width - centerWidth) / 2;
          return [
            {
              ...left,
              width: Length.px(sideWidth),
            },
            {
              ...right,
              width: Length.px(sideWidth),
            },
          ];
        })
        .getOrElse(() => [left, right]),
    )
    .get();

  return (
    <>
      <OverLay style={overlayStyle} />
      <Container
        id={props.id}
        style={containerStyle}
        onTouchStart={(evt) => {
          evt.stopPropagation();
          return dispatch;
        }}
        onTouchMove={(evt) => {
          evt.stopPropagation();
          return dispatch;
        }}
        onTouchEnd={(evt) => {
          evt.stopPropagation();
          return dispatch;
        }}
        onTransitionEnd={(evt) => {
          evt.stopPropagation();
          switch (props.mode) {
            case 'Show':
              return Option(props.onOpened)
                .map((f) =>
                  dispatch.compose(dispatch.unsafeCast<EDP<Parent>>(f)),
                )
                .getOrElse(() => dispatch);
            case 'Hide':
            case 'Close':
              return Option(props.onClosed)
                .map((f) =>
                  dispatch.compose(dispatch.unsafeCast<EDP<Parent>>(f)),
                )
                .getOrElse(() => dispatch);
          }
        }}
      >
        <Header
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: Length.px(headerHeightValue),
            backgroundColor: Colors.background,
            borderBottom: Border(
              'solid',
              props.title != null ? Length.px(0.5) : Length.px(0),
              Colors.border,
            ),
            ...headerStyle,
          }}
        >
          <Left style={leftStyle}>
            {props.headerLeft ? props.headerLeft : <View />}
          </Left>

          <Center>
            {props.title != null ? (
              <Text
                style={{
                  fontSize: Length.px(16),
                  fontWeight: 'bold',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {props.title.toReactNode()}
              </Text>
            ) : null}

            {props.subTitle}
          </Center>

          <Right style={rightStyle}>
            <IcButton
              parent={props.parent}
              image={Icons.ic_close_normal}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              onClick={(dispatch) => {
                return props.onClose
                  ? dispatch.unsafeCast<EDP<Parent>>(props.onClose)
                  : dispatch;
              }}
              disabled={
                props.disableCloseWhileLoading === true &&
                isContentLoading(context)
              }
            />
          </Right>
        </Header>

        <Body
          style={{
            position: 'relative',
            height: Length.px(viewPort.height - headerHeight.value),
            backgroundColor: Colors.background,
            overflow: 'scroll',
            ...bodyStyle,
          }}
        >
          {props.children}
        </Body>
      </Container>
    </>
  );
}
