import { Scope } from './Scope';

type Unit = 'MilliMeter' | 'Meter' | 'KiloMeter';

export type Distance = {
  readonly kind: 'Distance';
  readonly toMeters: () => number;
  readonly toKiloMeters: () => number;
  readonly toString: () => string;
};

interface Statics {
  readonly milliMeters: (value: number) => Distance;
  readonly meters: (value: number) => Distance;
  readonly kiloMeters: (value: number) => Distance;
}

interface Constructor {
  (value: number, unit: Unit): Distance;
}

function constructor(value: number, unit: Unit): Distance {
  const text = `${value} ${unit}`;
  const meters = Scope(() => {
    switch (unit) {
      case 'MilliMeter':
        return value / 1000;
      case 'Meter':
        return value;
      case 'KiloMeter':
        return value * 1000;
    }
  });
  const kiloMeters = Scope(() => {
    switch (unit) {
      case 'MilliMeter':
        return value / 1000000;
      case 'Meter':
        return value / 1000;
      case 'KiloMeter':
        return value;
    }
  });
  return {
    kind: 'Distance',
    toMeters: () => meters,
    toKiloMeters: () => kiloMeters,
    toString: () => text,
  };
}

const statics: Statics = {
  milliMeters: (value: number) => constructor(value, 'MilliMeter'),
  meters: (value: number) => constructor(value, 'Meter'),
  kiloMeters: (value: number) => constructor(value, 'KiloMeter'),
};

export const Distance: Constructor & Statics = Object.assign(
  constructor,
  statics,
);
