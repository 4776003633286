import React from 'react';
import { SlideInCardState } from '../components/SlideInCard';
import { SlideInConfirmPane } from '../components/SlideInConfirmPane';
import { Message, MessageKeys, useIntl } from '../i18n/Intl';
import { DataMapStore } from '../states/DataMapStore';
import {
  ServiceReminderActions,
  ServiceReminderDataMap,
} from './ServiceReminderCard';

export function ResetPane({
  titleId,
  state,
  parent,
}: Readonly<{
  titleId: MessageKeys;
  state: SlideInCardState;
  parent: DataMapStore<ServiceReminderDataMap>;
}>): React.ReactElement {
  const intl = useIntl();
  const actions = ServiceReminderActions.get();
  return (
    <SlideInConfirmPane
      title={Message(
        `${intl.formatMessage({ id: 'Remainder_mResetBtn' }).toString()} (${intl
          .formatMessage({ id: titleId })
          .toString()})`,
      )}
      body={intl.formatMessage({ id: 'Remainder_mSubContent' })}
      buttonTitle={intl.formatMessage({ id: 'Remainder_mSubBtn' })}
      state={state}
      parent={parent}
      onClose={(_, dispatch) =>
        dispatch.serviceReminder(actions.updateResetPaneState, 'Hide')
      }
    />
  );
}
