import React from 'react';
import { Button, ButtonThemes } from '../components/Button';
import { LineSpace } from '../components/LineSpace';
import {
  CloseType,
  SlideInCard,
  SlideInCardState,
} from '../components/SlideInCard';
import { Message, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Length } from '../style/Length';

type Props<Parent> = {
  title: Message;
  body: Message;
  buttonTitle: Message;
  state: SlideInCardState;
  parent: DataMapStore<Parent>;
  onClose?: (closeType: CloseType, _: DP<Parent>) => DP<Parent>;
};

type DP<Parent> = DataMapDispatch<WithContext<Parent>>;

export function SlideInConfirmPane<Parent>({
  title,
  body,
  buttonTitle,
  state,
  parent,
  onClose,
}: Props<Parent>): React.ReactElement {
  const {
    views: { View },
  } = useParentStore({ parent });
  const Message = View;
  const intl = useIntl();

  return (
    <SlideInCard
      parent={parent}
      state={state}
      title={title}
      closeIcon={Icons.ic_close_normal}
      minHeight={280}
      onOpen={(dispatch) => dispatch}
      onClose={onClose}
    >
      <Message
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(20),
        }}
      >
        {body.toReactNode()}
      </Message>

      <View
        style={{
          paddingTop: Length.px(20),
          paddingBottom: Length.px(12),
        }}
      >
        <Button parent={parent} title={buttonTitle} />

        <LineSpace height={Length.px(8)} />

        <Button
          parent={parent}
          title={intl.formatMessage({ id: 'Remainder_mSubBtnCancel' })}
          theme={ButtonThemes.white}
        />
      </View>
    </SlideInCard>
  );
}
