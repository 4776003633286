import React from 'react';
import { Pipe } from '../../util/Pipe';
import { HomeDispatch } from '../home/Home';
import { Intl, Message } from '../i18n/Intl';
import { MessageIdMap } from '../i18n/MessageIdMap';
import { PrefixId } from '../i18n/Types';
import { ActionBinder } from '../states/ActionBinder';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { FontWeight } from '../style/Style';
import { LineSpace } from './LineSpace';

type Props<DataMap> = Readonly<{
  parent: DataMapStore<DataMap>;
  state: State;
  title: Message;
  message: Message;
  footer: React.ReactNode;
}>;

export type State = 'Show' | 'Hide';

export function DialogButton<DataMap>({
  parent,
  type,
  title,
  fontWeight,
  onClick,
}: {
  parent: DataMapStore<DataMap>;
  type: 'Alert' | 'Confirm';
  title: Message;
  fontWeight: FontWeight;
  onClick?: () => DataMapDispatch<WithContext<DataMap>>;
}): React.ReactElement {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent });

  return (
    <View
      style={{
        height: Length.px(48),
        borderTop: Border('solid', Length.px(0.5), Colors.border),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        return Pipe(onClick ? onClick() : dispatch)
          .map((_) => {
            if (type === 'Alert') {
              return _.context.bind(Context.actions.updateAlertDialog, {
                state: 'Hide',
              });
            } else {
              return _.context.bind(
                Context.actions.updateConfirmDialogState,
                'Hide',
              );
            }
          })
          .get();
      }}
    >
      <Text
        style={{
          color: Colors.accent,
          fontSize: Length.px(17),
          lineHeight: Length.px(17),
          fontWeight: fontWeight,
        }}
      >
        {title.toReactNode()}
      </Text>
    </View>
  );
}

export function Dialog<DataMap>({
  parent,
  state,
  title,
  message,
  footer,
}: Props<DataMap>): React.ReactElement {
  const {
    views: { View, Text },
    data: { context },
  } = useParentStore({ parent });
  const Container = View;
  const Window = View;
  const TitleMessage = View;
  const viewPort = context.viewPort;

  return (
    <Container
      style={{
        position: 'absolute',
        top: Length.px(state === 'Show' ? 0 : viewPort.height),
        left: Length.px(0),
        width: Length.px(viewPort.width),
        height: Length.px(viewPort.height),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.overlayCover,
      }}
    >
      <Window
        style={{
          width: Length.px(280),
          filter: DropShadow(
            Length.px(0),
            Length.px(4),
            Length.px(12),
            Colors.shadowDark,
          ),
          borderRadius: Length.px(16),
          backgroundColor: Colors.background,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
        }}
      >
        <TitleMessage
          style={{
            paddingTop: Length.px(24),
            paddingLeft: Length.px(16),
            paddingRight: Length.px(16),
            paddingBottom: Length.px(28),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(20),
              lineHeight: Length.px(20),
              fontWeight: 'bold',
            }}
          >
            {title.toReactNode()}
          </Text>

          <LineSpace height={Length.px(20)} />

          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
            }}
          >
            {message.toReactNode()}
          </Text>
        </TitleMessage>

        {footer}
      </Window>
    </Container>
  );
}

export function dialogAction(
  prefixId: PrefixId,
  intl: Intl,
  onConfirm?: () => HomeDispatch,
): ActionBinder<Context> {
  const m = MessageIdMap.get(prefixId);
  switch (m.dialog.type) {
    case 'Alert':
      return Action(Context.actions.updateAlertDialog, {
        state: 'Show',
        title: intl.formatMessage({ id: m.dialog.title }),
        message: intl.formatMessage({ id: m.dialog.body }),
      });
    case 'Confirm':
      return Action(Context.actions.updateConfirmDialog, {
        state: 'Show',
        title: intl.formatMessage({ id: m.dialog.title }),
        message: intl.formatMessage({ id: m.dialog.body }),
        confirmButtonTitle: intl.formatMessage({ id: m.dialog.action }),
        cancelButtonTitle: intl.formatMessage({ id: m.dialog.close }),
        onConfirm,
      });
    default:
      return Action();
  }
}
