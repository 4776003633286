import React from 'react';
import { println } from '../../apis/NativeApi';
import {
  sendRemoteCommand,
  subscribeRemoteCommand,
  unsubscribeRemoteCommand,
} from '../../apis/RemoteCommand';
import { getRemoteCommandErrorMessage } from '../../apis/RemoteCommandExecuter';
import { Vehicle, getSelectedVehicle } from '../../apis/VehicleList';
import { getWifiStatus } from '../../apis/Wifi';
import { Anomaly } from '../../util/Error';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import {
  commonErrorDialogAction,
  errorDialogAction,
} from '../components/AlertDialog';
import { Button, ButtonThemes } from '../components/Button';
import { LineSpace } from '../components/LineSpace';
import { SlideInCard, SlideInCardState } from '../components/SlideInCard';
import { HomeActions, HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { ActionBinder } from '../states/ActionBinder';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Length } from '../style/Length';

export function ResetPane({
  parent,
  state,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  state: SlideInCardState;
}>): React.ReactElement {
  const {
    views: { View, Text },
    data: { context },
  } = useParentStore({ parent });
  const intl = useIntl();

  return (
    <SlideInCard
      parent={parent}
      state={state}
      title={intl.formatMessage({ id: 'WiFi_wifiReset' })}
      closeIcon={Icons.ic_close_normal}
      minHeight={280}
      onClose={(_, dispatch) =>
        dispatch
          .home(HomeActions.updateResetPaneState, 'Hide')
          .context.bind(Context.actions.updateOverlayState, { state: 'Hide' })
      }
    >
      <Text
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(20),
        }}
      >
        {intl.formatMessage({ id: 'WiFi_wifiResetHint' }).toReactNode()}
      </Text>

      <View
        style={{
          paddingTop: Length.px(20),
          paddingBottom: Length.px(12),
        }}
      >
        <Button
          parent={parent}
          title={intl.formatMessage({ id: 'WiFi_wifiResetBtn' })}
          onClick={(dispatch) =>
            getSelectedVehicle(context)
              .map<
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ['resetWifi', '-', Vehicle, (err: any) => ActionBinder<Context>]
              >((vehicle) => {
                const commandName = 'resetWifi';
                const commandAction = '-';
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                function errorAction(err: any): ActionBinder<Context> {
                  const a = Anomaly.of(err);
                  const m = getRemoteCommandErrorMessage(
                    vehicle,
                    commandName,
                    commandAction,
                    a.code,
                    intl,
                  );
                  return errorDialogAction(m.dialogTitle, m.dialogBody)
                    .bind(Context.actions.updateWindowSpinnerState, 'Hide')
                    .bind(Context.actions.updateOverlayState, {
                      state: 'Hide',
                    });
                }
                return [commandName, commandAction, vehicle, errorAction];
              })
              .map(([commandName, commandAction, vehicle, errorAction]) =>
                dispatch
                  .home(HomeActions.updateResetPaneState, 'Hide')
                  .context(Context.actions.updateOverlayState, {
                    state: 'Hide',
                  })
                  .context.bind(
                    Context.actions.updateWindowSpinnerState,
                    'Show',
                  )
                  .context.bind(Context.actions.updateOverlayState, {
                    state: 'Show',
                    opacity: 0.4,
                  })
                  .asyncAll(
                    Scope(async () => {
                      const listener = await subscribeRemoteCommand(
                        commandName,
                        vehicle.dcmId,
                        vehicle.vin,
                      );

                      await sendRemoteCommand({
                        vin: vehicle.vin,
                        dcmId: vehicle.dcmId,
                        name: commandName,
                        type: 'vehicle',
                        action: commandAction,
                        params: {},
                      });

                      await listener.toPromise();

                      await unsubscribeRemoteCommand(
                        commandName,
                        vehicle.dcmId,
                      );

                      const wifiStatus = await getWifiStatus(vehicle.vin);

                      return dispatch
                        .context(Context.actions.updateWifiStatus, wifiStatus)
                        .context(
                          Context.actions.updateWindowSpinnerState,
                          'Hide',
                        )
                        .context(Context.actions.updateOverlayState, {
                          state: 'Hide',
                        });
                    }).catch((err) =>
                      dispatch
                        .effect(() => println(err))
                        .context(errorAction(err)),
                    ),
                  ),
              )
              .getOrElse(() => {
                println(Error(`A vehicle has not been selected yet`));
                return dispatch.context.bind(
                  commonErrorDialogAction(intl),
                  Unit,
                );
              })
          }
        />

        <LineSpace height={Length.px(8)} />

        <Button
          parent={parent}
          title={intl.formatMessage({ id: 'WiFi_wifiResetCancel' })}
          theme={ButtonThemes.white}
          onClick={(dispatch) =>
            dispatch
              .home(HomeActions.updateResetPaneState, 'Hide')
              .context(Context.actions.updateOverlayState, {
                state: 'Hide',
              })
          }
        />
      </View>
    </SlideInCard>
  );
}
