import React from 'react';
import { config } from '../../Config';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { copy } from '../../util/Copyable';
import { Pipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { Card, CardState } from '../components/Card';
import { HomeActions, HomeData, HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useStore } from '../states/DataMapStore';
import { Store } from '../states/Store';
import { ClosedView } from './ClosedView';
import { OpenedView } from './OpenedView';

type Key = 'wifi';

export type WifiCardData = Data;

type Data = Readonly<{
  localState: LocalState;
}>;

type LocalState = Readonly<{
  cardState: CardState;
}>;

type Actions = Readonly<{
  updateCardState: (data: Data, cardState: CardState) => Data;
}>;

type Props = Readonly<{
  store: Store<HomeData>;
}>;

const actions: Actions = {
  updateCardState: (data: Data, cardState: CardState) =>
    copy(data, {
      localState: { cardState },
    }),
};

export function WifiCard(props: Props): React.ReactElement {
  const state = useStore<Key, Data, HomeDataMap, Props>({
    key: 'wifi',
    props,
    parent: { home: props.store },

    default: () => ({
      localState: {
        cardState: 'Closed',
        resetPaneState: 'Hide',
        overlayState: 'Hide',
      },
    }),

    update: ({ data: { context, wifi: data, home }, dispatch }) => {
      const localState = data.localState;
      const parent = home.localState;
      return Pipe(dispatch)
        .map((_) => {
          if (
            context.nativeBackAction === 'InCard' &&
            localState.cardState === 'Opened'
          ) {
            const none = _.context.bind(
              Context.actions.updateNativeBackAction,
              'None',
            );
            if (
              context.alertDialog.state === 'Hide' &&
              context.confirmDialog.state === 'Hide'
            ) {
              if (parent.wifi.resetPaneState === 'Show') {
                return none
                  .home(HomeActions.updateResetPaneState, 'Hide')
                  .context(Context.actions.updateOverlayState, {
                    state: 'Hide',
                  });
              } else {
                return _.context.bind(
                  Context.actions.updateNativeBackAction,
                  'TopLevel',
                );
              }
            } else {
              return none;
            }
          } else {
            return _;
          }
        })
        .get();
    },
  });

  const { View } = state.views;
  const intl = useIntl();
  const localState = state.data.wifi.localState;
  const cardState = localState.cardState;
  const context = state.data.context;

  return (
    <Card
      id='WifiCard'
      cardName='Wifi'
      parent={state.stores}
      badgeName={intl.formatMessage({ id: 'WiFi_pageTitle' })}
      title={intl.formatMessage({ id: 'WiFi_pageTitle' })}
      reloadable={true}
      reloadOnOpen={config.fetchDataOptions.onOpenWifi}
      openable={!context.dataErrors.wifiStatus}
      contentState={context.contentState}
      overlayState='Hide'
      onOpen={(dispatch) =>
        dispatch.wifi.bind(actions.updateCardState, 'Opening')
      }
      onOpened={(dispatch) =>
        dispatch.wifi.bind(actions.updateCardState, 'Opened')
      }
      onClose={(dispatch) =>
        dispatch.wifi.bind(actions.updateCardState, 'Closing')
      }
      onClosed={(dispatch) =>
        dispatch.wifi.bind(actions.updateCardState, 'Closed')
      }
      onReload={(dispatch) =>
        dispatch
          .context(Context.actions.updateContentState, 'Reload')
          .context(
            Context.actions.updateFetchVehicleDataOptions,
            config.fetchDataOptions.onReloadWifi,
          )
      }
      onClickOverride={
        !isServiceAllowed(context, 'wifi')
          ? (dispatch) => dispatch.context.bind(userRestrictionAction(context))
          : undefined
      }
    >
      <View
        style={{
          opacity: context.contentState === 'Loading' ? 0 : 1,
        }}
      >
        {Scope(() => {
          if (cardState === 'Opened' || cardState === 'Opening') {
            return <OpenedView parent={state.stores} />;
          } else {
            return <ClosedView />;
          }
        })}
      </View>
    </Card>
  );
}
