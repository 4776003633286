import React from 'react';
import {
  hideSharedUserRegistrationScreen,
  println,
} from '../../apis/NativeApi';
import { pair, pairActivate } from '../../apis/VehiclePairing';
import { Matrix3x3 } from '../../math/Matrix';
import { copy } from '../../util/Copyable';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import { Button } from '../components/Button';
import { LineSpace } from '../components/LineSpace';
import { Shape } from '../components/Shape';
import { SlideInWindow, SlideInWindowMode } from '../components/SlideInWindow';
import { HomeData, HomeDataMap } from '../home/Home';
import { createIntl, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { ActionBinder } from '../states/ActionBinder';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapState,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Store } from '../states/Store';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';

type Key = 'vehicleRegistration';

type Data = Readonly<{
  localState: LocalState;
  contents: Shape;
}>;

type Page = 1 | 2 | 3 | 4 | 5;

type PairingState = Readonly<{
  state: 'None' | 'Pairing' | 'PairActivated' | 'Paired';
  error: boolean;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly page: Page;
  readonly pairingState: PairingState;
  readonly vinToRegister: string;
}

type Command = 'None'; // 'OnPaired' | 'Error';

interface Actions extends CommandActions<Data, Command> {
  readonly updatePage: (data: Data, page: Page) => Data;
  readonly updatePairingState: (data: Data, pairingState: PairingState) => Data;
  readonly updateVinToRegister: (data: Data, vinToRegister: string) => Data;
}

type Props = Readonly<{
  parent: Store<Parent>;
  mode: SlideInWindowMode;
}>;

type Parent = HomeData;

type DM = ExtendParent<HomeDataMap, Key, Data>;
type DMC = WithContext<DM>;
type DP = DataMapDispatch<DMC>;

const commandState = CommandState<Data, Command>();
const buttonTopMargin = Length.px(20);
const buttonBottomMargin = Length.px(16);

function pairActivateAction(
  vin: string,
): [ActionBinder<Data>, Promise<ActionBinder<Data>>] {
  return [
    Action.bind(actions.updatePairingState, {
      state: 'Pairing',
      error: false,
    }),
    new Promise((resolve, _reject) => {
      pairActivate(vin)
        .then(() =>
          resolve(
            Action.bind(actions.updatePairingState, {
              state: 'PairActivated',
              error: false,
            }),
          ),
        )
        .catch((err) => {
          println(err);
          resolve(
            Action.bind(actions.updatePairingState, {
              state: 'PairActivated',
              error: true,
            }),
          );
        });
    }),
  ];
}

function pairVehicleAction(
  vin: string,
  bssid: string,
): [ActionBinder<Data>, Promise<ActionBinder<Data>>] {
  return [
    Action.bind(actions.updatePairingState, {
      state: 'Pairing',
      error: false,
    }),
    new Promise((resolve, _reject) => {
      pair(vin, bssid)
        .then(() =>
          resolve(
            Action.bind(actions.updatePairingState, {
              state: 'Paired',
              error: false,
            }).bind(actions.updateVinToRegister, ''),
          ),
        )
        .catch((err) => {
          println(err);
          resolve(
            Action.bind(actions.updatePairingState, {
              state: 'Paired',
              error: true,
            }),
          );
        });
    }),
  ];
}

function Navigator(
  props: Readonly<{
    parent: DataMapStore<DM>;
    page: Page;
  }>,
): React.ReactElement {
  const {
    views: { View, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const naviIcon = (page: Page) => {
    const [onIcon, offIcon] =
      page === 1
        ? [Icons.btn_step1_on, Icons.btn_step1_off]
        : page === 2
        ? [Icons.btn_step2_on, Icons.btn_step2_off]
        : page === 3
        ? [Icons.btn_step3_on, Icons.btn_step3_off]
        : page === 4
        ? [Icons.btn_step4_on, Icons.btn_step4_off]
        : [Icons.btn_step5_on, Icons.btn_step5_off];

    return (
      <>
        <View
          style={{
            position: 'relative',
            width: Length.px(56),
            height: Length.px(56),
            WebkitTapHighlightColor: 'transparent',
            marginLeft: Length.px(2),
            marginRight: Length.px(2),
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            if (props.page > page) {
              return dispatch.vehicleRegistration(actions.updatePage, page);
            } else {
              return dispatch;
            }
          }}
        >
          <Image
            src={offIcon}
            style={{
              position: 'absolute',
              top: Length.px(0),
              width: Length.px(56),
              height: Length.px(56),
            }}
          />
          <Image
            src={onIcon}
            style={{
              position: 'absolute',
              top: Length.px(0),
              width: Length.px(56),
              height: Length.px(56),
              opacity: props.page === page ? 1.0 : 0.0,
              transition: [
                Transition('opacity', Time.ms(300), TimingFunction.easeIn),
              ],
            }}
          />
        </View>
      </>
    );
  };

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {naviIcon(1)}

      {naviIcon(2)}

      {naviIcon(3)}

      {naviIcon(4)}

      {naviIcon(5)}
    </View>
  );
}

const pageBodyStyle: OptionalStyles = {
  flexShrink: 0,
  width: Percentage(100),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: Length.px(16),
  paddingRight: Length.px(16),
};

function PageBody1(): React.ReactElement {
  const {
    views: { View, Text, Image },
  } = useContextStore();
  const intl = useIntl();

  return (
    <View style={pageBodyStyle}>
      <Text
        style={{
          fontSize: Length.px(20),
          fontWeight: 'bold',
        }}
      >
        {intl
          .formatMessage({
            id: 'VehicleActivate_screen1title',
          })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(12)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen1Message' })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(20)} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          src={Icons.img_vehicle_vin}
          style={{
            width: Length.px(280),
            height: Length.px(180),
          }}
        />
      </View>
    </View>
  );
}

function PageBody2(
  props: Readonly<{ parent: DataMapStore<DM> }>,
): React.ReactElement {
  const {
    views: { View, Text, Input },
    data: { vehicleRegistration: data },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();

  const inputStyle: OptionalStyles = {
    height: Length.px(48),
    border: Border('solid', Length.px(0.5), Colors.border),
    borderRadius: Length.px(24),
    backgroundColor: Colors.backgroundDark,
    paddingTop: Length.px(15),
    paddingBottom: Length.px(15),
    paddingLeft: Length.px(8),
    paddingRight: Length.px(8),
    fontSize: Length.px(18),
    lineHeight: Length.px(18),
    textAlign: 'center',
  };

  return (
    <View style={pageBodyStyle}>
      <Text
        style={{
          fontSize: Length.px(20),
          fontWeight: 'bold',
        }}
      >
        {intl
          .formatMessage({
            id: 'VehicleActivate_screen2title',
          })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(12)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen2Message' })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(20)} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: Percentage(100),
        }}
      >
        <Input
          type='email'
          inputMode='email'
          style={{
            ...inputStyle,
            width: Percentage(100),
          }}
          value={data.localState.vinToRegister}
          placeholder={intl
            .formatMessage({ id: 'VehicleActivate_screen2InputLabel1' })
            .toString()}
          // autoFocus
          onChange={(evt) => {
            evt.stopPropagation();
            return dispatch.vehicleRegistration(
              actions.updateVinToRegister,
              (evt as React.FormEvent<HTMLInputElement>).currentTarget.value,
            );
          }}
          onKeyPress={(evt) => {
            evt.stopPropagation();
            if (
              evt.key === 'Enter' &&
              data.localState.vinToRegister.length > 0
            ) {
              return dispatch.vehicleRegistration(actions.updatePage, 3);
            } else return dispatch;
          }}
        />
      </View>
    </View>
  );
}

function PageBody3(): React.ReactElement {
  const {
    views: { View, Text, Image },
  } = useContextStore();
  const intl = useIntl();

  return (
    <View style={pageBodyStyle}>
      <Text
        style={{
          fontSize: Length.px(20),
          fontWeight: 'bold',
        }}
      >
        {intl
          .formatMessage({
            id: 'VehicleActivate_screen3title',
          })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(12)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen3Message' })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(20)} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image
          src={Icons.img_vehicle_switch}
          style={{
            width: Length.px(240),
            height: Length.px(120),
          }}
        />
      </View>
    </View>
  );
}

function PageBody4(): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();
  const intl = useIntl();

  return (
    <View style={pageBodyStyle}>
      <Text
        style={{
          fontSize: Length.px(20),
          fontWeight: 'bold',
        }}
      >
        {intl
          .formatMessage({
            id: 'VehicleActivate_screen4title',
          })
          .toReactNode()}
      </Text>
      <LineSpace height={Length.px(20)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen4Message1' })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(20)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen4Message2' })
          .toReactNode()}
      </Text>
    </View>
  );
}

function PageBody5(): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();
  const intl = useIntl();

  return (
    <View style={pageBodyStyle}>
      <Text
        style={{
          fontSize: Length.px(20),
          fontWeight: 'bold',
        }}
      >
        {intl
          .formatMessage({
            id: 'VehicleActivate_screen6Title',
          })
          .toReactNode()}
      </Text>
      <LineSpace height={Length.px(20)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen6Message1' })
          .toReactNode()}
      </Text>

      <LineSpace height={Length.px(20)} />

      <Text
        style={{
          textAlign: 'center',
        }}
      >
        {intl
          .formatMessage({ id: 'VehicleActivate_screen6Message2' })
          .toReactNode()}
      </Text>
    </View>
  );
}

const actions: Actions = {
  ...commandState.actions,

  updatePage: (data: Data, page: Page) =>
    copy(data, {
      localState: { page },
    }),

  updatePairingState: (data: Data, pairingState: PairingState) =>
    copy(data, {
      localState: { pairingState },
    }),

  updateVinToRegister: (data: Data, vinToRegister: string) =>
    copy(data, {
      localState: { vinToRegister },
    }),
};

export function VehicleRegistration(props: Props): React.ReactElement {
  const state: DataMapState<Props, DM> = useStore<
    Key,
    Data,
    HomeDataMap,
    Props
  >({
    key: 'vehicleRegistration',
    props,
    parent: { home: props.parent },

    default: () => ({
      localState: {
        command: commandState.default,
        page: 1,
        pairingState: { state: 'None', error: false },
        vinToRegister: '',
      },
      contents: Shape({}),
    }),

    update: ({ data: { context }, prev, dispatch }) => {
      const intl = createIntl(context);

      return dispatch
        .compose(
          commandState.handleDataMap<DMC>(
            'vehicleRegistration',
            data,
            dispatch,
            (_command) => dispatch,
          ),
        )
        .pipe((_) => {
          if (
            props.mode === 'Hide' &&
            prev.unwrap(
              ({ props }) => props.mode === 'Show',
              () => false,
            )
          ) {
            return _.vehicleRegistration(actions.updatePage, 1)
              .vehicleRegistration(actions.updateVinToRegister, '')
              .vehicleRegistration(actions.updatePairingState, {
                state: 'None',
                error: false,
              });
          } else {
            return _;
          }
        })
        .pipe((_) => {
          if (
            data.localState.pairingState.state === 'Pairing' &&
            context.overlayState.state === 'Hide'
          ) {
            return _.context
              .bind(Context.actions.updateOverlayState, {
                state: 'Show',
                opacity: 0.4,
              })
              .context.bind(Context.actions.updateWindowSpinnerState, 'Show');
          } else if (
            data.localState.pairingState.state === 'PairActivated' ||
            data.localState.pairingState.state === 'Paired'
          ) {
            return _.pipe((_) =>
              _.vehicleRegistration(actions.updatePairingState, {
                state: 'None',
                error: false,
              })
                .context.bind(Context.actions.updateOverlayState, {
                  state: 'Hide',
                })
                .context.bind(Context.actions.updateWindowSpinnerState, 'Hide'),
            ).pipe((_) => {
              if (!data.localState.pairingState.error) {
                if (data.localState.pairingState.state === 'PairActivated') {
                  return _.vehicleRegistration(actions.updatePage, 3);
                } else {
                  return _.context
                    .bind(Context.actions.updateContentState, 'Load')
                    .context.bind(
                      Context.actions.updateCarRegScreenMode,
                      'Hide',
                    )
                    .context.bind(Context.actions.updateAlertDialog, {
                      state: 'Show',
                      title: intl.formatMessage({
                        id: 'VehicleActivate_screen4Registered',
                      }),
                      message: intl.formatMessage({
                        id: 'VehicleActivate_screen4Completed',
                      }),
                    });
                }
              } else {
                return _.context.bind(Context.actions.updateAlertDialog, {
                  state: 'Show',
                  title: intl.formatMessage({
                    id: 'VehicleActivate_screen4Error',
                  }),
                  message: intl.formatMessage({
                    id: 'Common_networkErrorMessage',
                  }),
                });
              }
            });
          } else {
            return _;
          }
        })
        .pipe((_) => {
          if (
            context.sharedUserRegScreenMode.state === 'Registered' &&
            context.confirmDialog.state === 'Hide'
          ) {
            hideSharedUserRegistrationScreen();
            if (context.sharedUserRegScreenMode.error === false) {
              return _.context
                .bind(Context.actions.updateContentState, 'Load')
                .context.bind(Context.actions.updateAlertDialog, {
                  state: 'Show',
                  title: intl.formatMessage({
                    id: 'VehicleActivate_screen4Registered',
                  }),
                  message: intl.formatMessage({
                    id: 'VehicleActivate_screen5Completed',
                  }),
                })
                .context.bind(Context.actions.updateSharedUserRegScreenMode, {
                  state: 'None',
                  error: false,
                });
            } else {
              return _.context
                .bind(Context.actions.updateAlertDialog, {
                  state: 'Show',
                  title: intl.formatMessage({
                    id: 'VehicleActivate_screen4Error',
                  }),
                  message: intl.formatMessage({
                    id: 'Common_networkErrorMessage',
                  }),
                })
                .context.bind(Context.actions.updateSharedUserRegScreenMode, {
                  state: 'None',
                  error: false,
                });
            }
          }
          return _;
        });
    },
  });

  const { View } = state.views;
  const Contents = state.views.vehicleRegistration.contents;
  const BodyContainer = View;
  const data = state.data.vehicleRegistration;
  const context = state.data.context;
  const intl = useIntl();
  const viewPort = context.viewPort;

  const isNotConnectedToCarWifi = () =>
    context.ssidInfo.ssid !== context.wifiStatus.currentSSID;

  const button = Scope(() => {
    const titleId = Scope(() => {
      switch (data.localState.page) {
        case 1:
          return 'VehicleActivate_screen1Button';
        case 2:
          return 'VehicleActivate_screen2Button';
        case 3:
          return 'VehicleActivate_screen3Button';
        case 4:
          return 'VehicleActivate_screen4Button';
        case 5:
          return 'VehicleActivate_screen6Button';
      }
    });
    const disabled = Scope(() => {
      switch (data.localState.page) {
        case 1:
          return false;
        case 2:
          return data.localState.vinToRegister.length === 0;
        case 3:
          return false;
        case 4:
          return false;
        case 5:
          return !isNotConnectedToCarWifi();
      }
    });

    const onClick = Scope(() => {
      switch (data.localState.page) {
        case 1:
          return (dispatch: DP) =>
            dispatch.vehicleRegistration(actions.updatePage, 2);
        case 2:
          return (dispatch: DP) => {
            const [action1, action2] = pairActivateAction(
              data.localState.vinToRegister,
            );
            return dispatch
              .vehicleRegistration(action1, Unit)
              .vehicleRegistration.async((dispatch) =>
                action2.then((action2) => dispatch(action2, Unit)),
              );
          };
        case 3:
          return (dispatch: DP) =>
            dispatch.vehicleRegistration.bind(actions.updatePage, 4);
        case 4:
          return (dispatch: DP) => {
            if (context.ssidInfo.bssid !== '') {
              return dispatch.vehicleRegistration.bind(actions.updatePage, 5);
            } else {
              return dispatch.context.bind(Context.actions.updateAlertDialog, {
                state: 'Show',
                title: intl.formatMessage({
                  id: 'VehicleActivate_screen4WiFiErrorTitle',
                }),
                message: intl.formatMessage({
                  id: 'VehicleActivate_screen4WiFiErrorMessage',
                }),
              });
            }
          };

        case 5:
          return (dispatch: DP) => {
            const [action1, action2] = pairVehicleAction(
              data.localState.vinToRegister,
              context.ssidInfo.bssid,
            );
            return dispatch
              .vehicleRegistration(action1, Unit)
              .vehicleRegistration.async((dispatch) =>
                action2.then((action2) => dispatch(action2, Unit)),
              );
          };
      }
    });

    const button = titleId ? (
      <Button
        parent={state.stores}
        title={intl.formatMessage({ id: titleId })}
        disabled={disabled}
        onClick={onClick}
      />
    ) : (
      <></>
    );

    return (
      <View
        style={{
          width: Length.px(viewPort.width),
          paddingTop: buttonTopMargin,
          paddingBottom: buttonBottomMargin,
          paddingLeft: Length.px(16),
          paddingRight: Length.px(16),
        }}
      >
        {button}
      </View>
    );
  });

  return (
    <SlideInWindow
      parent={state.stores}
      mode={props.mode}
      title={intl.formatMessage({ id: 'VehicleActivate_registerCar' })}
      direction='vertical'
      onClose={(dispatch) =>
        dispatch.context(Context.actions.updateCarRegScreenMode, 'Hide')
      }
    >
      <View
        style={{
          height: Percentage(100),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Contents
          style={{
            flexGrow: 1,
            paddingLeft: Length.px(16),
            paddingRight: Length.px(16),
            overflow: 'hidden',
          }}
        >
          <LineSpace height={Length.px(20)} />

          <Navigator parent={state.stores} page={data.localState.page} />

          <LineSpace height={Length.px(20)} />
          <BodyContainer
            style={{
              position: 'fixed',
              left: Length.px(0),
              width: Percentage(100),
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'visible',
              flexWrap: 'nowrap',
              transform: Transform(
                Matrix3x3.translate(
                  -viewPort.width * (data.localState.page - 1),
                  0,
                ),
              ),
              transition: Transition(
                'transform',
                Time.ms(300),
                TimingFunction.easeOut,
              ),
            }}
          >
            <PageBody1 />
            <PageBody2 parent={state.stores} />
            <PageBody3 />
            <PageBody4 />
            <PageBody5 />
          </BodyContainer>
        </Contents>

        {button}
      </View>
    </SlideInWindow>
  );
}
