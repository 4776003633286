import React from 'react';
import { Scope } from '../../util/Scope';
import { useIntl } from '../i18n/Intl';
import { IcWaitingWhite } from '../icons/IcWaitingWhite';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Button } from './Button';

export function EngineProhibitButton<Parent>({
  prohibit,
  onClick,
  parent,
}: Readonly<{
  prohibit: boolean;
  onClick: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
  parent: DataMapStore<Parent>;
}>): React.ReactElement {
  const {
    views: { View, Image, Text },
    data: { context },
  } = useParentStore({ parent });
  const ButtonPane = View;
  const intl = useIntl();

  return (
    <ButtonPane
      style={{
        paddingLeft: Length.px(16),
        paddingRight: Length.px(16),
      }}
    >
      <Button
        parent={parent}
        center={
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: Length.px(20),
              borderRadius: Length.px(2),
              backgroundColor: prohibit
                ? Colors.engineProhibitBackground
                : undefined,
            }}
          >
            <Text
              style={{
                fontSize: Length.px(16),
                lineHeight: Length.px(16),
                fontWeight: 'bold',
                color: prohibit
                  ? Colors.engineProhibitForeground
                  : Colors.buttonText,
              }}
            >
              {intl
                .formatMessage({
                  id: prohibit
                    ? 'VehicleAlert_engineProhibitOff'
                    : 'VehicleAlert_engineProhibitOn',
                })
                .toReactNode()}
            </Text>
          </View>
        }
        right={
          <View
            style={{
              paddingRight: Length.px(10),
            }}
          >
            {Scope(() => {
              if (context.ongoingRemoteStates.engineProhibit) {
                return (
                  <IcWaitingWhite
                    style={{
                      width: Length.px(32),
                      height: Length.px(32),
                    }}
                    animate
                  />
                );
              } else {
                return (
                  <Image
                    src={Icons.ic_action_white}
                    style={{
                      width: Length.px(18),
                      height: Length.px(32),
                    }}
                  />
                );
              }
            })}
          </View>
        }
        onClick={onClick}
      />
    </ButtonPane>
  );
}
