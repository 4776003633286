import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { EmptyProps } from '../components/Component';
import { Drag } from '../events/Drag';
import { toTouchVector } from '../events/Events';
import { IcSliderWaiting } from '../icons/IcSliderWaiting';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import {
  DataMapDispatch,
  DataMapState,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';
import { Shape } from './Shape';
import { ShapeViewBase } from './View';

type Key = 'slideButton';

export type SlideButtonData = Data;

type Data = Readonly<{
  localState: LocalState;
  container: Shape;
  button: Shape;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly isEnabled: boolean;
  readonly isSpinning: boolean;
  readonly drag: Drag;
}

type Command = 'isChanged';

interface Actions extends CommandActions<Data, Command> {
  readonly onTouchStart: (data: Data, touch: Vector) => Data;
  readonly onTouchMove: (data: Data, touch: Vector) => Data;
  readonly onTouchEnd: (data: Data, offset?: number) => Data;
}

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  isEnabled: boolean;
  isSpinning: boolean;
  buttonIcon: string;
  leftIcon: string;
  rightIcon: string;
  onChange: (
    _: Readonly<{
      isEnabled: boolean;
      dispatch: DataMapDispatch<WithContext<Parent>>;
    }>,
  ) => DataMapDispatch<WithContext<Parent>>;
  //reset?: boolean;
}>;

type Edges = Readonly<{
  leftEdge: number;
  rightEdge: number;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type DMC<Parent> = WithContext<DM<Parent>>;

function getEdges(container: Shape): Option<Edges> {
  return container.getBound().map((containerBound) => {
    const leftEdge =
      containerBound.left + container.getBorders().left.width.value;
    const rightEdge =
      containerBound.right - container.getBorders().right.width.value;
    return { leftEdge, rightEdge };
  });
}

function getButtonStyle<Parent>(
  props: Props<Parent>,
  container: Shape,
  button: Shape,
): OptionalStyles {
  const style: OptionalStyles = {
    position: 'absolute',
    top: Length.px(0),
    display: 'inline-block',
    borderRadius: Length.px(44),
    height: Length.px(88),
    width: Length.px(88),
    backgroundColor: Colors.primary,
    filter: DropShadow(
      Length.px(0),
      Length.px(3),
      Length.px(6),
      Colors.shadowDark,
    ),
  };

  return button
    .getBound()
    .flatMap<OptionalStyles>((buttonBound) => {
      return getEdges(container).map<OptionalStyles>(
        ({ leftEdge, rightEdge }) => {
          const left = Scope(() => {
            const rightEnd = rightEdge - leftEdge - buttonBound.width;
            if (props.isEnabled && !props.isSpinning) {
              return rightEnd;
            } else if (props.isEnabled && props.isSpinning) {
              return 0;
            } else if (!props.isEnabled && props.isSpinning) {
              return rightEnd;
            } else {
              return 0;
            }
          });
          return {
            ...style,
            left: Length.px(left),
          };
        },
      );
    })
    .getOrElse(() => ({
      ...style,
      left: Length.px(0),
    }));
}

const duration = Time.ms(200);
const timingFunction = TimingFunction.easeOut;

const commandState = CommandState<Data, Command>();

const actions: Actions = {
  ...commandState.actions,
  onTouchStart: (data: Data, touch: Vector) => {
    const drag = Drag.actions.onTouchStart(data.localState.drag, touch);
    return copy(data, {
      localState: {
        drag,
      },
      button: data.button.updateStyles({
        transform: Transform(Matrix3x3.translate(0, 0)),
        transition: [],
      }),
    });
  },

  onTouchMove: (data: Data, touch: Vector) => {
    const drag = Drag.actions.onTouchMove(data.localState.drag, touch);
    const buttonBound = data.button.getBound().getUnsafeValue();
    const { leftEdge, rightEdge } = getEdges(data.container).getUnsafeValue();
    const left = buttonBound.left + drag.dt.x;
    const right = buttonBound.right + drag.dt.x;
    const isEnabled = data.localState.isEnabled;
    //const isSpinning = data.localState.isSpinning;
    const rightEnd = isEnabled ? 0 : rightEdge - leftEdge - buttonBound.width;
    const leftEnd = isEnabled ? leftEdge - rightEdge + buttonBound.width : 0;
    if (touch.x >= rightEdge) {
      return copy(data, {
        localState: {
          drag,
        },
        button: data.button.updateStyles({
          transform: Transform(Matrix3x3.translate(rightEnd, 0)),
        }),
      });
    } else if (touch.x <= leftEdge) {
      return copy(data, {
        localState: {
          drag,
        },
        button: data.button.updateStyles({
          transform: Transform(Matrix3x3.translate(leftEnd, 0)),
        }),
      });
    } else if (left > leftEdge && right < rightEdge) {
      return copy(data, {
        localState: {
          drag,
        },
        button: data.button.updateStyles({
          transform: data.button.translate(drag.dt.x, 0),
        }),
      });
    } else {
      return data;
    }
  },

  onTouchEnd: (data: Data, _offset = 5) => {
    const drag = Drag.actions.onTouchEnd(data.localState.drag);
    const isEnabled = data.localState.isEnabled;
    return data.button
      .getBound()
      .flatMap((buttonBound) => {
        return getEdges(data.container).map(({ leftEdge, rightEdge }) => {
          const offset = 5;
          const isChanged = Scope(() => {
            if (isEnabled && buttonBound.left <= leftEdge + offset) {
              return true;
            } else if (isEnabled) {
              return false;
            } else if (buttonBound.right >= rightEdge - offset) {
              return true;
            } else {
              return false;
            }
          });

          return copy(
            data,
            isChanged
              ? {
                  localState: {
                    command: 'isChanged',
                    drag,
                  },
                  button: data.button.updateStyles({
                    //transform: Transform(Matrix3x3.translate(0, 0)),
                    //transition: Transition('transform', duration, timingFunction),
                  }),
                }
              : {
                  button: data.button.updateStyles({
                    transform: Transform(Matrix3x3.translate(0, 0)),
                    transition: Transition(
                      'transform',
                      duration,
                      timingFunction,
                    ),
                  }),
                },
          );
        });
      })
      .getOrElse(() =>
        copy(data, {
          localState: {
            drag,
          },
        }),
      );
  },
};

export function SlideButton<Parent>(props: Props<Parent>): React.ReactElement {
  const state: DataMapState<EmptyProps, DM<Parent>> = useStore<
    Key,
    Data,
    Parent
  >({
    key: 'slideButton',

    parent: props.parent,

    default: () => ({
      localState: {
        command: commandState.default,
        isEnabled: false,
        isSpinning: false,
        drag: Drag(),
      },
      container: Shape({}),
      button: Shape({}),
    }),

    initialize: (data: Data) =>
      copy(data, {
        localState: {
          drag: Drag.initialize(data.localState.drag),
        },
      }),

    update: ({ data: { slideButton }, dispatch }) => {
      const localState = slideButton.localState;
      return dispatch
        .compose(
          commandState.handleDataMap(
            'slideButton',
            slideButton,
            dispatch,
            (command) => {
              switch (command) {
                case 'isChanged':
                  return dispatch.unsafeCast<
                    DataMapDispatch<WithContext<Parent>>
                  >((a) =>
                    props.onChange({
                      isEnabled: props.isEnabled,
                      dispatch: a,
                    }),
                  );
                default:
                  return dispatch;
              }
            },
          ),
        )
        .pipe((_) => {
          if (
            props.isEnabled !== localState.isEnabled ||
            props.isSpinning !== localState.isSpinning
          ) {
            return _.slideButton((data) =>
              copy<Data>(data, {
                localState: {
                  isEnabled: props.isEnabled,
                  isSpinning: props.isSpinning,
                },
                button: data.button.updateStyles({
                  transform: Transform(Matrix3x3.translate(0, 0)),
                }),
              }),
            );
          } else return _;
        });
    },
  });

  const { View, Image } = state.views;
  const Container = state.views.slideButton.container as ShapeViewBase<
    DMC<Parent>
  >;
  const Button = state.views.slideButton.button as ShapeViewBase<DMC<Parent>>;
  const ButtonIcon = View;
  const Spinner = View;

  const data = state.data.slideButton;
  const dispatch = state.dispatch;

  const buttonStyle = getButtonStyle(props, data.container, data.button);

  return (
    <Container>
      <View
        style={{
          position: 'relative',
          borderRadius: Length.px(44),
          height: Length.px(88),
          width: Length.px(270),
          backgroundColor: props.isEnabled
            ? Colors.slideActiveBackground
            : Colors.componentBackground,
        }}
      >
        {/* Background icons */}
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: Percentage(100),
            paddingLeft: Length.px(14),
            paddingRight: Length.px(14),
          }}
        >
          <View
            style={{
              display: 'inline-block',
              opacity: props.isEnabled ? 0.3 : 1,
              width: Length.px(60),
              height: Length.px(60),
            }}
          >
            <img alt='normal' src={props.leftIcon} width='100%' height='100%' />
          </View>
          <View
            style={{
              display: 'inline-block',
              opacity: props.isEnabled ? 0.3 : 1,
              width: Length.px(60),
              height: Length.px(60),
            }}
          >
            <img
              alt='active'
              src={props.rightIcon}
              width='100%'
              height='100%'
            />
          </View>
        </View>

        {/* Button */}
        <Button
          style={buttonStyle}
          onTouchStart={(evt) => {
            evt.stopPropagation();
            if (props.isSpinning) return dispatch;
            else
              return dispatch.slideButton(
                actions.onTouchStart,
                toTouchVector(evt),
              );
          }}
          onTouchMove={(evt) => {
            evt.stopPropagation();
            if (props.isSpinning) return dispatch;
            else
              return dispatch.slideButton(
                actions.onTouchMove,
                toTouchVector(evt),
              );
          }}
          onTouchEnd={(evt) => {
            evt.stopPropagation();
            if (props.isSpinning) return dispatch;
            else return dispatch.slideButton(actions.onTouchEnd, undefined);
          }}
        >
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: Percentage(100),
            }}
          >
            <ButtonIcon
              style={{
                display: 'inline-block',
                width: Length.px(60),
                height: Length.px(60),
                opacity: props.isSpinning ? 0 : 1,
              }}
            >
              <Image
                alt='button'
                src={props.buttonIcon}
                width='100%'
                height='100%'
              />
            </ButtonIcon>

            {Scope(() => {
              if (props.isSpinning) {
                return (
                  <Spinner
                    style={{
                      position: 'absolute',
                      display: 'inline-block',
                      width: Length.px(60),
                      height: Length.px(60),
                      opacity: props.isSpinning ? 1 : 0,
                    }}
                  >
                    <IcSliderWaiting
                      style={{
                        width: Percentage(100),
                        height: Percentage(100),
                      }}
                      animate
                    />
                  </Spinner>
                );
              } else {
                return <View />;
              }
            })}
          </View>
        </Button>
        {/* Button*/}
      </View>
    </Container>
  );
}
