import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { None, Option } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { Unit } from '../../util/Unit';
import { Drag } from '../events/Drag';
import { toTouchVector } from '../events/Events';
import { FetchVehicleDataOptions } from '../home/FetchVehicleData';
import { isContentLoading } from '../home/Helpers';
import { Message, createIntl } from '../i18n/Intl';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import {
  ContentState,
  Context,
  OngoingRemoteStates,
  ViewPort,
} from '../states/Context';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Bound } from '../style/Bound';
import { BoxShadow } from '../style/BoxShadow';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';
import { CardBadgeStyle, cardBadgeHeight } from './CardBadge';
import { CardHeader } from './CardHeader';
import { Overlay, OverlayState } from './Overlay';
import { Shape } from './Shape';
import { Spinner, State as SpinnerState } from './Spinner';
import { ShapeViewBase } from './View';

type Key = 'card';
export type CardData = Data;

type Data = Readonly<{
  localState: LocalState;
  container: Shape;
  badge: Shape;
  content: Shape;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type ED<Parent> = DataMapDispatch<WithContext<Parent>>;
type DP<Parent> = DataMapDispatch<WithContext<DM<Parent>>>;

interface LocalState extends CommandLocalState<Command> {
  readonly openState: CardState;
  readonly defaultContainerTransform: Transform;
  readonly closedContainerBound: Bound;
  readonly initialBound: Bound;
  readonly hasAdjustedSize: boolean;
  readonly drag: Drag;
  readonly badgeBoundCache: Bound;
}

interface Actions extends CommandActions<Data, Command> {
  readonly updateDefault: (data: Data) => Data;
  readonly prepareCard: (data: Data) => Data;
  readonly updateHasAdjustedSize: (
    data: Data,
    hasAdjustedSize: boolean,
  ) => Data;
  readonly open: (
    data: Data,
    args: { viewPort: ViewPort; force: boolean },
  ) => Data;
  readonly close: (data: Data) => Data;
  readonly onTransitionEnd: (data: Data) => Data;
  readonly onTouchStart: (data: Data, touch: Vector) => Data;
  readonly onTouchEnd: (data: Data, viewPort: ViewPort) => Data;
  readonly updateBadgeBoundCache: (data: Data, badgeBoundCache: Bound) => Data;
}

export type CardName =
  | 'VehicleInfo'
  | 'VehicleLocation'
  | 'ServiceReminder'
  | 'SDLApp'
  | 'VehicleAlert'
  | 'Wifi';

export type CardState = 'Opening' | 'Opened' | 'Closing' | 'Closed';

type Command =
  | 'CallOnOpen'
  | 'CallOnClose'
  | 'CallOnOpened'
  | 'CallOnClosed'
  | 'Reload'
  | 'PrepareCard';

type Statics = Readonly<{
  transitionDuration: Time;
  topMargin: Length;
  bottomMargin: Length;
  sideMargin: Length;
  borderWidth: Length;
  borderRadius: Length;
}>;

type Props<Parent> = Readonly<{
  id?: string;
  cardName: CardName;
  parent: DataMapStore<Parent>;
  children?: React.ReactNode;
  extras?: React.ReactNode;
  badgeName: Message;
  title: Message;
  lastUpdate?: Date | Message;
  openable: boolean;
  reloadable: boolean;
  reloadOnOpen?: FetchVehicleDataOptions;
  contentState: ContentState;
  overlayState: OverlayState;
  pullToCloseOnHeader?: boolean;
  disableSpinnerOnOpen?: boolean;
  onOpen?: (_: ED<Parent>) => ED<Parent>;
  onClose?: (_: ED<Parent>) => ED<Parent>;
  onOpened?: (_: ED<Parent>) => ED<Parent>;
  onClosed?: (_: ED<Parent>) => ED<Parent>;
  onCardStateChanged?: (cardState: CardState, _: ED<Parent>) => ED<Parent>;
  onReload?: (_: ED<Parent>) => ED<Parent>;
  onBadgeBound?: (bound: Bound, _: ED<Parent>) => ED<Parent>;
  onClickOverride?: (_: ED<Parent>) => ED<Parent>;
}>;

const cardTopMargin = Length.px(4);
const cardBottomMargin = Length.px(20);
export const cardSideMargin = Length.px(16);
const cardBorderWidth = Length.px(1);
export const cardTransitionDuration = Time.ms(600);
export const cardBorderRadius = Length.px(12);

export const CardConstants: Statics = {
  transitionDuration: cardTransitionDuration,
  topMargin: cardTopMargin,
  bottomMargin: cardBottomMargin,
  sideMargin: cardSideMargin,
  borderWidth: cardBorderWidth,
  borderRadius: cardBorderRadius,
};

const commandState = CommandState<Data, Command>();

function getSpinnerState(
  contentState: ContentState,
  openState: CardState,
  disableSpinnerOnOpen: boolean,
): SpinnerState {
  switch (contentState) {
    case 'SignedOut':
      return 'Show';
    case 'SigningIn':
      return 'Show';
    case 'SignedIn':
      return 'Show';
    case 'Loading':
      return 'Show';
    case 'Updating':
      if (
        (openState === 'Opening' || openState === 'Opened') &&
        disableSpinnerOnOpen
      )
        return 'Hide';
      else return 'Show';
    default:
      return 'Hide';
  }
}

function getClosedStyle(
  defaultContainerTransform: Transform,
  defaultContainerBound: Bound,
): OptionalStyles {
  return {
    transform: defaultContainerTransform,
    width: Length.px(defaultContainerBound.width),
    height: Length.px(defaultContainerBound.height),
  };
}

function onTouchMoveAction<_Parent>(
  data: Data,
  _actions: Actions,
  touch: Vector,
): [Data, boolean] {
  const drag = Drag.actions.onTouchMove(data.localState.drag, touch);
  const scrollTop = data.content.getRaw().getUnsafeValue().scrollTop;
  if (scrollTop === 0 && drag.dt.y > 0) {
    const [dx, width] = Scope(() => {
      const dx = drag.dt.y / 2;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const width = data.container.styles.width!.value - drag.dt.y;
      const maxW = data.localState.initialBound.width;
      const minW = maxW * 0.9;
      if (width > minW && width < maxW) return [dx, width];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      else return [0, data.container.styles.width!.value];
    });

    const [dy, height, shouldClose] = Scope(() => {
      const dy = drag.dt.y;
      const movedY = drag.initial.to(drag.current).y;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const height = data.container.styles.height!.value - drag.dt.y * 2;
      const maxH = data.localState.initialBound.height;
      const minH = maxH * 0.9;
      const closeH = movedY > maxH * 0.3;
      if (height > minH && height < maxH) return [dy, height, closeH];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      else return [0, data.container.styles.height!.value, closeH];
    });

    if (shouldClose && data.localState.openState === 'Opened') {
      return [
        copy(data, {
          localState: {
            drag,
          },
        }),
        true,
      ];
    } else if (data.localState.openState === 'Opened') {
      return [
        copy(data, {
          localState: {
            drag,
          },
          container: data.container.updateStyles({
            transform: data.container.translate(dx, dy),
            width: Length.px(width),
            height: Length.px(height),
            transition: [],
          }),
        }),
        false,
      ];
    } else {
      return [
        copy(data, {
          localState: {
            drag,
          },
        }),
        false,
      ];
    }
  } else {
    return [
      copy(data, {
        localState: {
          drag: Drag.actions.onTouchStart(data.localState.drag, touch),
        },
      }),
      false,
    ];
  }
}

function dispatchOnTouchStart<Parent>(
  data: Data,
  actions: Actions,
  evt: React.TouchEvent<HTMLDivElement>,
  dispatch: DP<Parent>,
): DP<Parent> {
  const localState = data.localState;
  const openState = localState.openState;
  evt.stopPropagation();
  if (openState === 'Opened' && evt.touches.length === 1) {
    return dispatch.card(actions.onTouchStart, toTouchVector(evt));
  } else {
    return dispatch;
  }
}

function dispatchOnTouchMove<Parent>(
  data: Data,
  actions: Actions,
  evt: React.TouchEvent<HTMLDivElement>,
  dispatch: DP<Parent>,
): DP<Parent> {
  const localState = data.localState;
  const openState = localState.openState;
  evt.stopPropagation();
  if (
    openState === 'Opened' &&
    localState.drag.isDragging &&
    evt.touches.length === 1
  ) {
    const [next, shouldClose] = onTouchMoveAction(
      data,
      actions,
      toTouchVector(evt),
    );
    return dispatch
      .pipe((_) => _.card((_data) => next, Unit))
      .pipe((_) => {
        if (shouldClose)
          return _.context.bind(Context.actions.updateCardInfo, {
            state: 'Closing',
          });
        else return _;
      });
  } else return dispatch;
}

function dispatchOnTouchEnd<Parent>(
  data: Data,
  actions: Actions,
  viewPort: ViewPort,
  evt: React.TouchEvent<HTMLDivElement>,
  dispatch: DP<Parent>,
): DP<Parent> {
  const localState = data.localState;
  const openState = localState.openState;
  evt.stopPropagation();
  if (openState === 'Opened' && localState.drag.isDragging) {
    return dispatch.card(actions.onTouchEnd, viewPort);
  } else {
    return dispatch;
  }
}

function adjustSize<Parent>(data: Data, dispatch: DP<Parent>): DP<Parent> {
  const localState = data.localState;
  const containerBound = localState.closedContainerBound;
  const contentBound = data.content.getBound().getUnsafeValue();
  const closedContainerBound = Bound(
    containerBound.left,
    containerBound.top,
    containerBound.width,
    contentBound.height,
  );
  return dispatch.card(
    (data: Data) =>
      copy(data, {
        localState: {
          closedContainerBound,
          hasAdjustedSize: true,
        },
        container: data.container.updateStyles(
          getClosedStyle(
            localState.defaultContainerTransform,
            closedContainerBound,
          ),
        ),
      }),
    Unit,
  );
}

const actions: Actions = {
  ...commandState.actions,

  updateDefault: (data: Data) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const defaultContainerTransform = data.container.styles.transform!;
    const closedContainerBound = data.container.getBound().getUnsafeValue();

    return copy(data as Data, {
      localState: {
        defaultContainerTransform: defaultContainerTransform,
        closedContainerBound,
      },
    });
  },

  prepareCard: (data: Data) => {
    const containerBound = data.container.getBound().getUnsafeValue();
    const closedContainerBound = Bound(
      containerBound.left,
      containerBound.top,
      containerBound.width,
      containerBound.height,
    );
    return Pipe(data)
      .map((data) =>
        copy(data as Data, {
          localState: {
            openState: 'Closed',
            initialBound: data.container.getBound().getUnsafeValue(),
            closedContainerBound,
            drag: Drag.initialize(data.localState.drag),
          },
          container: Shape.initialize(data.container).updateStyles(
            getClosedStyle(
              data.localState.defaultContainerTransform,
              closedContainerBound,
            ),
          ),
        }),
      )
      .get();
  },

  updateHasAdjustedSize: (data: Data, hasAdjustedSize: boolean) =>
    copy(data, {
      localState: { hasAdjustedSize },
    }),

  open: (data: Data, { viewPort, force }) => {
    if (force || data.localState.openState === 'Closed') {
      const bound = data.container.getBound().getUnsafeValue();
      const nextWidth = viewPort.width;
      const nextHeight = viewPort.height;

      const localState = Scope(() => {
        if (data.localState.openState === 'Closed') {
          return {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            defaultContainerTransform: data.container.styles.transform!,
            closedContainerBound: bound,
          };
        } else {
          return {};
        }
      });

      return Pipe(data)
        .map((data) =>
          copy(data, {
            localState: {
              openState:
                data.localState.openState === 'Opened' ? 'Opened' : 'Opening',
              command: 'CallOnOpen',
              ...localState,
            },
            container: data.container.updateStyles({
              transform: data.container.translate(
                0 - bound.left,
                0 - bound.top,
              ),
              width: Length.px(nextWidth),
              height: Length.px(nextHeight),
              transition: [
                Transition('height', statics.transitionDuration),
                Transition('width', statics.transitionDuration),
                Transition('transform', statics.transitionDuration),
              ],
              overflow: 'hidden',
            }),
            content: data.content.updateStyles({
              height: Length.px(
                nextHeight -
                  Pipe(data.container.getBorders())
                    .map(
                      ({ top, bottom }) => top.width.value + bottom.width.value,
                    )
                    .get() -
                  Pipe(data.container.getPaddings())
                    .map(({ top, bottom }) => top.value + bottom.value)
                    .get() -
                  CardHeader.height.value,
              ),
              overflow: 'auto',
            }),
          }),
        )
        .get();
    } else {
      return data;
    }
  },

  close: (data: Data) => {
    if (data.localState.openState === 'Opened') {
      data.content.getRaw().getUnsafeValue().scrollTop = 0;
      return Pipe(data)
        .map((data) =>
          copy(data as Data, {
            localState: {
              openState: 'Closing',
              command: 'CallOnClose',
            },
            /* reset to 'relative' positioning from 'fixed' */
            container: data.container.updateStyles({
              position: 'relative',
              transform: data.container.translate(
                0 - data.localState.closedContainerBound.left,
                0 - data.localState.closedContainerBound.top,
              ),
            }),
          }),
        )
        .get();
    } else {
      return data;
    }
  },

  onTransitionEnd: (data: Data) => {
    if (
      data.localState.openState === 'Closing' ||
      data.localState.openState === 'Closed'
    ) {
      return copy(data as Data, {
        localState: {
          openState: 'Closed',
          command: 'CallOnClosed',
        },
        container: data.container.updateStyles({
          transition: [],
        }),
      });
    } else if (
      data.localState.openState === 'Opening' ||
      data.localState.openState === 'Opened'
    ) {
      return copy(data as Data, {
        localState: {
          openState: 'Opened',
          command: 'CallOnOpened',
        },
        container: data.container.updateStyles({
          transition: [],
          transform: Transform(Matrix3x3.unit),
        }),
      });
    } else {
      return data;
    }
  },

  onTouchStart: (data: Data, touch: Vector) =>
    copy(data as Data, {
      localState: {
        initialBound: data.container.getBound().getUnsafeValue(),
        drag: Drag.actions.onTouchStart(data.localState.drag, touch),
      },
    }),

  onTouchEnd: (data: Data, viewPort: ViewPort) =>
    Pipe(data)
      .map((data) => {
        if (data.localState.openState === 'Opened') {
          if (
            data.container.getBound().getUnsafeValue().top >
            data.localState.initialBound.top
          ) {
            return actions.open(data, { viewPort, force: true });
          } else {
            return data;
          }
        } else {
          return data;
        }
      })
      .map((data) =>
        copy(data as Data, {
          localState: {
            drag: Drag.actions.onTouchEnd(data.localState.drag),
          },
        }),
      )
      .get(),

  updateBadgeBoundCache: (data: Data, badgeBoundCache: Bound) =>
    copy(data, {
      localState: { badgeBoundCache },
    }),
};

const statics: Statics = CardConstants;

function CardComponent<Parent>(props: Props<Parent>): React.ReactElement {
  const state = useStore<Key, Data, Parent, Props<Parent>>({
    key: 'card',
    props: props,
    parent: props.parent,

    default: () => {
      const badge = Shape(CardBadgeStyle);
      return {
        localState: {
          command: commandState.default,
          openState: 'Closed',
          defaultContainerTransform: Transform(Matrix3x3.unit),
          closedContainerBound: Bound.empty,
          initialBound: Bound(0, 0, 0, 0),
          hasAdjustedSize: false,
          drag: Drag(),
          badgeBoundCache: Bound(0, 0, 0, 0),
        },
        container: Shape({}),
        badge,
        content: Shape({}),
      };
    },

    initialize: (data: Data) =>
      Pipe(data)
        .map((data) => actions.updateDefault(data))
        .map((data) =>
          copy(data, {
            localState: {
              command: 'PrepareCard',
            },
          }),
        )
        .get(),

    update: ({ props, data: { context, card: data }, prev, dispatch }) => {
      const localState = data.localState;
      const viewPort = context.viewPort;
      return dispatch
        .pipe((_) =>
          Option.join(Option(props.onBadgeBound), data.badge.getBound()).unwrap(
            ([f, b]) =>
              b.right !== localState.badgeBoundCache.right
                ? _.card(actions.updateBadgeBoundCache, b).compose(
                    dispatch.unsafeCast<ED<Parent>>((_) => f(b, _)),
                  )
                : _,
            () => _,
          ),
        )
        .compose(
          commandState.handleDataMap<WithContext<DM<Parent>>>(
            'card',
            data,
            dispatch,
            (command) => {
              if (command === 'PrepareCard') {
                return dispatch.card(actions.prepareCard, {});
              } else if (command === 'CallOnOpen') {
                return Option(props.onOpen)
                  .unwrap(
                    (f) => dispatch.unsafeCast<ED<Parent>>(f),
                    () => dispatch,
                  )
                  .pipe((_) =>
                    Option(props.onCardStateChanged).unwrap(
                      (f) =>
                        _.compose(
                          _.unsafeCast<ED<Parent>>((_) => f('Opening', _)),
                        ),
                      () => _,
                    ),
                  );
              } else if (command === 'CallOnOpened') {
                return Option(props.onOpened)
                  .unwrap(
                    (f) => dispatch.compose(dispatch.unsafeCast<ED<Parent>>(f)),
                    () => dispatch,
                  )
                  .pipe((_) =>
                    Option(props.onCardStateChanged).unwrap(
                      (f) =>
                        _.compose(
                          _.unsafeCast<ED<Parent>>((_) => f('Opened', _)),
                        ),
                      () => _,
                    ),
                  )
                  .pipe((_) =>
                    _.context.bind(Context.actions.updateCardInfo, {
                      state: 'Opened',
                      name: props.cardName,
                    }),
                  );
              } else if (command === 'CallOnClose') {
                return Option(props.onClose)
                  .unwrap(
                    (f) => dispatch.compose(dispatch.unsafeCast<ED<Parent>>(f)),
                    () => dispatch,
                  )
                  .pipe((_) =>
                    Option(props.onCardStateChanged).unwrap(
                      (f) =>
                        _.compose(
                          dispatch.unsafeCast<ED<Parent>>((_) =>
                            f('Closing', _),
                          ),
                        ),
                      () => _,
                    ),
                  )
                  .pipe((_) =>
                    _.card((data: Data) =>
                      copy(data as Data, {
                        /* initiate closing animation */
                        container: data.container.updateStyles({
                          transition: [
                            Transition('height', statics.transitionDuration),
                            Transition('width', statics.transitionDuration),
                            Transition('transform', statics.transitionDuration),
                          ],
                          ...getClosedStyle(
                            data.localState.defaultContainerTransform,
                            data.localState.closedContainerBound,
                          ),
                        }),
                        content: data.content.updateStyles({
                          height: undefined,
                          overflow: 'hidden',
                        }),
                      }),
                    ),
                  );
              } else if (command === 'CallOnClosed') {
                return dispatch
                  .pipe((_) =>
                    Option(props.onClosed)
                      .map((f) => _.compose(_.unsafeCast<ED<Parent>>(f)))
                      .getOrElse(() => _),
                  )
                  .pipe((_) =>
                    Option(props.onCardStateChanged).unwrap(
                      (f) =>
                        _.compose(
                          _.unsafeCast<ED<Parent>>((_) => f('Closed', _)),
                        ),
                      () => _,
                    ),
                  )
                  .pipe((_) => {
                    if (
                      context.cardInfo.name.some((_) => _ === props.cardName)
                    ) {
                      return _.context
                        .bind(Context.actions.updateCardInfo, {
                          state: 'Closed',
                        })
                        .pipe((_) =>
                          context.onCardClosed
                            .map((f) => _.context.bind(f(), Unit))
                            .getOrElse(() => _),
                        )
                        .pipe((_) =>
                          _.context.bind(
                            Context.actions.updateOnCardClosed,
                            None(),
                          ),
                        );
                    }
                    return _;
                  })
                  .pipe((_) =>
                    _.card(
                      (data: Data) =>
                        copy(data, { localState: { hasAdjustedSize: false } }),
                      Unit,
                    ),
                  );
              } else if (command === 'Reload') {
                return Option(props.onReload).unwrap(
                  (f) => dispatch.unsafeCast<ED<Parent>>(f),
                  () => dispatch,
                );
              } else {
                return dispatch;
              }
            },
          ),
        )
        .pipe((_) => {
          if (
            !localState.hasAdjustedSize &&
            context.command !== 'HandleOnLoaded'
          ) {
            return _.compose(adjustSize(data, _));
          } else if (
            localState.hasAdjustedSize === true &&
            context.command === 'HandleOnLoaded' &&
            (context.cardInfo.state === 'Closing' ||
              context.cardInfo.state === 'Closed') &&
            props.cardName === 'VehicleAlert'
          ) {
            return _.card(actions.updateHasAdjustedSize, false);
          } else {
            return _;
          }
        })
        .pipe((_) => {
          if (
            prev.some(
              (_) =>
                _.data.context.nativeForegroundAction !==
                context.nativeForegroundAction,
            ) &&
            localState.openState === 'Opened'
          ) {
            return _.card(actions.open, { viewPort, force: true });
          } else {
            return _;
          }
        })
        .pipe((_) =>
          context.cardInfo.name.unwrap(
            (name) => {
              if (
                props.cardName === name &&
                (context.cardInfo.state === 'Opening' ||
                  context.cardInfo.state === 'Opened') &&
                localState.openState === 'Closed'
              ) {
                return _.card(actions.open, { viewPort, force: false });
              } else if (
                props.cardName !== name &&
                (context.cardInfo.state === 'Opening' ||
                  context.cardInfo.state === 'Opened') &&
                localState.openState === 'Opened'
              ) {
                return _.card(actions.close, false);
              } else if (
                (context.cardInfo.state === 'Closing' ||
                  context.cardInfo.state === 'Closed') &&
                localState.openState === 'Opened'
              ) {
                return _.card(actions.close, false);
              } else {
                return _;
              }
            },
            () => {
              if (
                (context.cardInfo.state === 'Closing' ||
                  context.cardInfo.state === 'Closed') &&
                localState.openState === 'Opened'
              ) {
                return _.card(actions.close, false);
              } else {
                return _;
              }
            },
          ),
        );
    },
  });

  const dispatch = state.dispatch;
  const views = state.views;
  const Container = views.card.container as ShapeViewBase<
    WithContext<DM<Parent>>
  >;
  const Header = views.default;
  const Content = views.card.content as ShapeViewBase<WithContext<DM<Parent>>>;
  const Badge = views.card.badge as ShapeViewBase<WithContext<DM<Parent>>>;
  const CardBodyOverlay = views.default;
  const context = state.data.context;
  const viewPort = context.viewPort;
  const data = state.data.card;
  const localState = data.localState;
  const openState = localState.openState;
  const intl = createIntl(context);
  const contentState = props.contentState;
  const ongoingState = context.selectedVehicleVin.some((vin) =>
    Option(context.ongoingRemoteStates[vin]).some((s) =>
      Object.keys(s).some((key) => {
        type A = OngoingRemoteStates & { readonly [key: string]: boolean };
        return (context.ongoingRemoteStates as A)[key];
      }),
    ),
  );

  const canOpen =
    props.openable && contentState === 'Loaded' && openState === 'Closed';

  const widgetDisabled = openState === 'Closed' && isContentLoading(context);

  const cardBodyDisabled = openState !== 'Closed' && isContentLoading(context);

  const containerStyle =
    openState === 'Opened'
      ? Scope(() => {
          const openStyle: OptionalStyles = {
            position: 'fixed',
            backgroundColor: Colors.background,
            top: Length.px(0),
            left: Length.px(0),
          };

          return openStyle;
        })
      : Scope(() => {
          const common: OptionalStyles = {
            position: 'relative',
            backgroundColor: Colors.background,
            overflow: 'hidden',
            opacity: widgetDisabled ? Colors.disabledOpacity : 1.0,
            zIndex:
              context.cardInfo.name.some((n) => n !== props.cardName) &&
              context.cardInfo.state === 'Opened'
                ? -1
                : undefined,
          };
          return theme(
            {
              ...common,
              marginTop: cardTopMargin,
              marginBottom: cardBottomMargin,
              marginLeft: cardSideMargin,
              marginRight: cardSideMargin,
              borderRadius: cardBorderRadius,
              // MON-751: use box-shadow instead of drop-shadow to avoid rendering problem in MON-751.
              boxShadow: BoxShadow(
                Length.px(0),
                Length.px(4),
                Length.px(24),
                Colors.shadowDark,
              ),
            },
            {
              ...common,
              margin: Length.px(0),
              borderBottom: Border('solid', Length.px(1), Colors.border),
            },
          );
        });

  return (
    <Container
      id={props.id}
      style={containerStyle}
      onClick={(evt) => {
        evt.stopPropagation();
        if (!widgetDisabled && props.onClickOverride) {
          return Option(props.onClickOverride).unwrap(
            (f) => dispatch.unsafeCast<ED<Parent>>(f),
            () => dispatch,
          );
        }

        if (canOpen)
          return dispatch.context
            .bind(Context.actions.updateCardInfo, {
              state: 'Opening',
              name: props.cardName,
            })
            .pipe((_) =>
              props.reloadOnOpen
                ? _.context
                    .bind(Context.actions.updateContentState, 'Reload')
                    .context.bind(
                      Context.actions.updateFetchVehicleDataOptions,
                      props.reloadOnOpen,
                    )
                : _,
            );
        // .pipe(_ => props.cardName !== 'ServiceReminder' && props.cardName !== 'SDLApp'
        //   ? _
        //     .context.bind(Context.actions.updateContentState, 'Reload')
        //     .context.bind(Context.actions.updateFetchVehicleDataOptions, {
        //       disableVehicleList: true,
        //       disableOtherServiceTelemetries: true,
        //       disableSendTel: true,
        //       disableOtherSendTels: true,
        //     })
        //   : _);
        else return dispatch;
      }}
      onTouchStart={(evt) =>
        dispatch.pipe((_) =>
          !props.pullToCloseOnHeader
            ? _.compose(dispatchOnTouchStart(data, actions, evt, _))
            : _,
        )
      }
      onTouchMove={(evt) =>
        dispatch.pipe((_) =>
          !props.pullToCloseOnHeader
            ? _.compose(dispatchOnTouchMove(data, actions, evt, _))
            : _,
        )
      }
      onTouchEnd={(evt) =>
        dispatch.pipe((_) =>
          !props.pullToCloseOnHeader
            ? _.compose(dispatchOnTouchEnd(data, actions, viewPort, evt, _))
            : _,
        )
      }
      onTransitionEnd={() => {
        return dispatch.card(actions.onTransitionEnd, {});
      }}
    >
      <Header
        style={{
          transition: [
            Transition('opacity', cardTransitionDuration),
            Transition('height', cardTransitionDuration),
          ],
          height:
            openState === 'Opening' || openState === 'Opened'
              ? CardHeader.height
              : Length.px(0),
          opacity: openState === 'Opening' || openState === 'Opened' ? 1 : 0,
        }}
        onTouchStart={(evt) =>
          dispatchOnTouchStart(data, actions, evt, dispatch)
        }
        onTouchMove={(evt) => dispatchOnTouchMove(data, actions, evt, dispatch)}
        onTouchEnd={(evt) =>
          dispatchOnTouchEnd(data, actions, viewPort, evt, dispatch)
        }
      >
        <CardHeader
          parent={state.stores.card}
          title={props.title}
          lastUpdate={props.lastUpdate}
          reloadable={props.reloadable}
          isOpen={openState === 'Opening' || openState === 'Opened'}
          contentState={contentState}
          ongoingState={ongoingState}
          intl={intl}
          onClose={(dispatch) => {
            if (openState === 'Opened') {
              return dispatch.context.bind(Context.actions.updateCardInfo, {
                state: 'Closing',
              });
            } else {
              return dispatch;
            }
          }}
          onReload={(dispatch) =>
            dispatch.card(actions.updateCommand, 'Reload')
          }
        />
      </Header>

      <Content
        style={{
          paddingBottom: Length.px(
            openState !== 'Closed' ? context.screenSize.marginBottom : 0,
          ),
        }}
      >
        {props.children}
      </Content>

      <Badge
        style={{
          ...CardBadgeStyle,
          transition: [
            Transition('opacity', cardTransitionDuration),
            Transition('height', cardTransitionDuration),
          ],
          opacity: openState === 'Opening' || openState === 'Opened' ? 0 : 1,
          height:
            openState === 'Opening' || openState === 'Opened'
              ? Length.px(0)
              : cardBadgeHeight,
        }}
      >
        {props.badgeName.toReactNode()}
      </Badge>

      <Overlay state={props.overlayState} />

      {props.extras}

      {cardBodyDisabled && (
        <CardBodyOverlay
          style={{
            position: 'absolute',
            top: Length.px(CardHeader.height.value),
            left: Length.px(0),
            width: Length.px(viewPort.width),
            height: Length.px(viewPort.height),
            backgroundColor: Colors.overlayCover,
          }}
        />
      )}

      <Spinner
        state={getSpinnerState(
          contentState,
          localState.openState,
          props.disableSpinnerOnOpen ?? false,
        )}
        owner={data.container}
        position={
          localState.openState === 'Opening' ? 'WindowCenter' : 'OwnerCenter'
        }
        useSecondColor={cardBodyDisabled}
      />
    </Container>
  );
}

export const Card = Object.assign(CardComponent, statics);
