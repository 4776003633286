import { config } from '../../Config';
import { isSignedIn } from '../../apis/Auth';
import { EmptyVehicleEvent } from '../../apis/EventTelemetry';
import { Geofence } from '../../apis/Geofence';
import {
  NativeCustomEventData,
  println,
  setDeviceToken,
  subscribeNativeCustomEvent,
} from '../../apis/NativeApi';
import { hasLastRemoteCommand } from '../../apis/RemoteCommand';
import {
  ServiceTelemetry,
  ServiceTelemetryResult,
} from '../../apis/ServiceTelemetry';
import { acceptSharedUser, registerDeviceToken } from '../../apis/User';
import { Vehicle } from '../../apis/VehicleList';
import { WifiStatus } from '../../apis/Wifi';
import { Listener } from '../../util/Listener';
import { None, Option, Some } from '../../util/Option';
import { Result } from '../../util/Result';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import { commonErrorDialogAction } from '../components/AlertDialog';
import { CardName } from '../components/Card';
import { ToastData } from '../components/Toast';
import { EventIdType, EventIdTypeMap } from '../i18n/EventTypeMap';
import { Intl, createIntl } from '../i18n/Intl';
import { MessageIdMap } from '../i18n/MessageIdMap';
import {
  DialogActionId,
  DialogBodyId,
  DialogCloseId,
  DialogTitleId,
  NotificationTitleId,
} from '../i18n/Types';
import { ActionBinder } from '../states/ActionBinder';
import {
  CardInfo,
  Context,
  LastRemoteCommand,
  OngoingRemoteState,
} from '../states/Context';
import { Dispatch } from '../states/Dispatch';
import { Action } from '../states/Reducer';
import {
  FetchVehicleDataOptions,
  VehicleDataResult,
  getVehicleDataForVin,
  handleVehicleDataResponse,
} from './FetchVehicleData';
import { closeRegistrationWindows, getViewPort, openCard } from './Helpers';
import { HomeData, HomeDispatch, homeDispatch } from './Home';

type NotificationHandlerParams = Readonly<{
  vehicle: Vehicle;
  eventIdType: EventIdType;
  data: NativeCustomEventData<'notification'>;
  targetVin: Option<string>;
  notificationTitleId: NotificationTitleId;
  dialogTitleId: DialogTitleId;
  dialogBodyId: DialogBodyId;
  dialogActionId: DialogActionId;
  dialogCloseId: DialogCloseId;
  showDialog: (params: ShowDialogParams) => ActionBinder<Context>;
  showToast: (params: ShowToastParams) => ActionBinder<Context>;
  context: Context;
}>;

type ShowDialogParams = Readonly<{
  cardName: CardName;
  onConfirm?: () => HomeDispatch;
  onCancel?: () => HomeDispatch;
}>;

type ShowToastParams = {
  readonly cardName: CardName;
  readonly onClick?: () => Dispatch<ToastData, HomeData>;
};

type ChangedState = 'Toast' | 'Dialog' | 'None';

function showConfirmDialog(
  dialogTitleId: DialogTitleId,
  dialogBodyId: DialogBodyId,
  dialogActionId: DialogActionId,
  dialogCloseId: DialogCloseId,
  model: string,
  targetCardName: CardName,
  currentCardInfo: CardInfo,
  targetVin: Option<string>,
  intl: Intl,
  onConfirm: (() => HomeDispatch) | undefined = undefined,
  onCancel: (() => HomeDispatch) | undefined = undefined,
): ActionBinder<Context> {
  return Action.bind(Context.actions.updateConfirmDialog, {
    state: 'Show',
    title: intl.formatMessage({ id: dialogTitleId }),
    message: intl.formatMessage({ id: dialogBodyId }, { model: model }),
    confirmButtonTitle: intl.formatMessage({ id: dialogActionId }),
    cancelButtonTitle: intl.formatMessage({ id: dialogCloseId }),
    onConfirm: () =>
      homeDispatch
        .context(openCard(targetCardName, currentCardInfo, targetVin))
        .pipe((_) => (onConfirm ? _.compose(onConfirm()) : _)),
    onCancel,
  });
}

function getRemoteControlChangedStatus(
  dialogTitleId: DialogTitleId,
  oldGeofence: Option<Geofence>,
  newGeofence: Option<Geofence>,
  oldServiceTelemetry: ServiceTelemetryResult,
  newServiceTelemetry: ServiceTelemetryResult,
  oldWifiStatus: WifiStatus,
  newWifiStatus: WifiStatus,
  lastRemoteCommands: LastRemoteCommand[],
  ongoingStates: Option<OngoingRemoteState>,
): ChangedState {
  if (ongoingStates.some((_) => _.door === false)) {
    switch (dialogTitleId) {
      case 'Event_remoteDoorLockedSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.door_status !== b.door_status),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteDoorLockedFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'allDoors', 'locked')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteHeadLightOnSuccessDialogTitle':
      case 'Event_remoteHeadLightOffSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.light !== b.light),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteHeadLightOnFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'lights', 'on')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteHeadLightOffFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'lights', 'off')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteHazardOnSuccessDialogTitle':
      case 'Event_remoteHazardOffSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.hazard_lamp !== b.hazard_lamp),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteHazardOnFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'hazardLamp', 'on')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteHazardOffFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'hazardLamp', 'off')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteACOnSuccessDialogTitle':
      case 'Event_remoteACOffSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.air_con_status !== b.air_con_status),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteACOnFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'airCondition', 'on')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteACOffFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'airCondition', 'off')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteEngineOnSuccessDialogTitle':
      case 'Event_remoteEngineOffSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.eng_status !== b.eng_status),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteEngineOnFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'airCondition', 'on') // engine uses 'airCondition' remote command.
          ? 'Dialog'
          : 'None';
      case 'Event_remoteEngineOffFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'airCondition', 'off') // engine uses 'airCondition' remote command.
          ? 'Dialog'
          : 'None';
      case 'Event_remoteTrackingOnSuccessDialogTitle':
      case 'Event_remoteTrackingOffSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.en_theft_trk !== b.en_theft_trk),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteTrackingOnFailDialogTitle':
        return !hasLastRemoteCommand(
          lastRemoteCommands,
          'theftTracking',
          'true',
        )
          ? 'Dialog'
          : 'None';
      case 'Event_remoteTrackingOffFailDialogTitle':
        return !hasLastRemoteCommand(
          lastRemoteCommands,
          'theftTracking',
          'false',
        )
          ? 'Dialog'
          : 'None';
      case 'Event_remoteGeofenceOnSuccessDialogTitle':
      case 'Event_remoteGeofenceOffSuccessDialogTitle':
      case 'Event_remoteGeofenceChangeSuccessDialogTitle':
        return oldGeofence.some((a) => newGeofence.some((b) => a !== b))
          ? 'None'
          : 'None';
      case 'Event_remoteGeofenceOnFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'geofence', 'create')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteGeofenceOffFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'geofence', 'delete')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteGeofenceChangeFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'geofence', 'update')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteWifiOnSuccessDialogTitle':
      case 'Event_remoteWifiOffSuccessDialogTitle':
        return oldWifiStatus.wifiRequest !== newWifiStatus.wifiRequest
          ? 'None'
          : 'None';
      case 'Event_remoteWifiOnFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'wifi', 'true')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteWifiOffFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'wifi', 'false')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteWifiResetSuccessDialogTitle':
        return oldWifiStatus.currentSSID !== newWifiStatus.currentSSID
          ? 'None'
          : 'None';
      case 'Event_remoteWifiResetFailDialogTitle':
        return !hasLastRemoteCommand(lastRemoteCommands, 'resetWifi', '-')
          ? 'Dialog'
          : 'None';
      case 'Event_remoteEngineProhibitOnSuccessDialogTitle':
      case 'Event_remoteEngineProhibitOffSuccessDialogTitle':
        return oldServiceTelemetry.data
          .toOption()
          .some((a) =>
            newServiceTelemetry.data
              .toOption()
              .some((b) => a.dsc_eng_prohibit !== b.dsc_eng_prohibit),
          )
          ? 'Toast'
          : 'None';
      case 'Event_remoteEngineProhibitOnFailDialogTitle':
        return !hasLastRemoteCommand(
          lastRemoteCommands,
          'engineCommandProhibited',
          'true',
        )
          ? 'Dialog'
          : 'None';
      case 'Event_remoteEngineProhibitOffFailDialogTitle':
        return !hasLastRemoteCommand(
          lastRemoteCommands,
          'engineCommandProhibited',
          'false',
        )
          ? 'Dialog'
          : 'None';
      default:
        return 'None';
    }
  } else {
    return 'None';
  }
}

async function handleRemoteCommand<_A>(
  {
    data,
    vehicle,
    targetVin,
    context,
    showDialog,
    showToast,
  }: NotificationHandlerParams,
  cardName: CardName,
  getChangedState: (a: VehicleDataResult) => Result<ChangedState>,
  options: FetchVehicleDataOptions,
): Promise<ActionBinder<Context>> {
  if (data.foreground) {
    const [results, getAction] = await fetchBackendData(
      vehicle,
      context,
      options,
    );
    const state = await getChangedState(results).toPromise();
    return Action<Context>()
      .bind(getAction(targetVin.isNone()))
      .compose(
        Scope<ActionBinder<Context>>(() => {
          switch (state) {
            case 'Toast':
              return showToast({
                cardName,
                onClick: () =>
                  Dispatch<ToastData, HomeData>().context(getAction(true)),
              });
            case 'Dialog':
              return showDialog({
                cardName,
                onConfirm: () => homeDispatch.context(getAction(true)),
              });
            case 'None':
              return Action();
            //default: return context;
          }
        }),
      );
  } else {
    return Promise.resolve(
      Action
        //.bind(Context.actions.updateContentState, 'Reload')
        .compose(openCard(cardName, context.cardInfo, targetVin)),
    );
  }
}

async function fetchBackendData(
  vehicle: Vehicle,
  context: Context,
  options: FetchVehicleDataOptions,
): Promise<
  [VehicleDataResult, (isSelectedVehicle: boolean) => ActionBinder<Context>]
> {
  const r = await getVehicleDataForVin({
    vehicle,
    context,
    cars: context.vehicles,
    options,
  });

  return [
    r,
    (isSelectedVehicle: boolean) => {
      const [action, errors] = handleVehicleDataResponse(
        vehicle.vin,
        isSelectedVehicle,
        context.dataErrors,
        context.vehicles,
        r.userLastVehicleEvents,
        r.vehicleEvents,
        r.serviceTelemetry,
        r.wifiStatus,
        r.notificationStatus,
        options?.enableGeofence === true ? Some(r.geofence) : None(),
        options?.enableLastVehiclesEventList === true
          ? Some(r.lastVehicleEvents)
          : None(),
      );

      return errors.unwrap(
        (e) => action.bind(Context.actions.updateDataErrors, e),
        () => action,
      );
    },
  ];
}

function handleEventType1({
  vehicle,
  eventIdType,
  data,
  targetVin,
  notificationTitleId,
  showDialog,
  context,
}: NotificationHandlerParams): Promise<ActionBinder<Context>> {
  return eventIdType.id.unwrap(
    async (eventId) => {
      const options = config.fetchDataOptions.onEventType1.onForeground;
      if (data.foreground) {
        const [results, getAction] = await fetchBackendData(
          vehicle,
          context,
          options,
        );

        return Action<Context>()
          .bind(getAction(targetVin.isNone()))
          .bind(
            showDialog({
              cardName: 'VehicleAlert',
              onConfirm: () =>
                homeDispatch
                  .context(
                    Context.actions.updateOtherServiceTelemetries,
                    results.otherServiceTelemetries,
                  )
                  .context(getAction(true))
                  .context(Context.actions.updateSelectedVehicleEvent, {
                    selectedVehicleEvent: EmptyVehicleEvent(
                      data.vin,
                      eventId,
                      data.timestamp,
                    ),
                    vehicleEventWindowMode: 'Show',
                  }),
            }),
          );
      } else {
        return Promise.resolve(
          Action.bind(Context.actions.updateSelectedVehicleEvent, {
            selectedVehicleEvent: EmptyVehicleEvent(
              data.vin,
              eventId,
              data.timestamp,
            ),
            vehicleEventWindowMode: 'Show',
          }).bind(openCard('VehicleAlert', context.cardInfo, targetVin), Unit),
        );
      }
    },
    () => {
      const intl = createIntl(context);
      println(Error(`Could not find an event id for ${notificationTitleId}`));
      return Promise.resolve(Action.bind(commonErrorDialogAction(intl), Unit));
    },
  );
}

function handleEventType3(
  params: NotificationHandlerParams,
): Promise<ActionBinder<Context>> {
  return handleRemoteCommand<ServiceTelemetry>(
    params,
    'VehicleInfo',
    (a: VehicleDataResult) =>
      a.serviceTelemetry.data.map((_) =>
        getRemoteControlChangedStatus(
          params.dialogTitleId,
          params.vehicle.geofence,
          params.vehicle.geofence,
          params.context.serviceTelemetry,
          a.serviceTelemetry,
          params.context.wifiStatus,
          params.context.wifiStatus,
          params.context.lastRemoteCommands,
          Option(params.context.ongoingRemoteStates[params.vehicle.vin]),
        ),
      ),
    config.fetchDataOptions.onEventType3.onForeground,
  );
}

async function handleEventType4({
  data,
  vehicle,
  context,
  targetVin,
  showDialog,
}: NotificationHandlerParams): Promise<ActionBinder<Context>> {
  if (data.foreground) {
    const options = config.fetchDataOptions.onEventType4.onForeground;
    const [_results, getAction] = await fetchBackendData(
      vehicle,
      context,
      options,
    );
    return Action<Context>()
      .bind(getAction(targetVin.isNone()))
      .compose(
        showDialog({
          cardName: 'VehicleInfo',
          onConfirm: () => homeDispatch.context(getAction(true)),
        }),
      );
  } else {
    return Promise.resolve(
      Action
        //.bind(Context.actions.updateContentState, 'Reload')
        .compose(openCard('VehicleInfo', context.cardInfo, targetVin)),
    );
  }
}

async function handleEventType6(
  { data, vehicle, targetVin, context }: NotificationHandlerParams,
  cardName: CardName,
): Promise<ActionBinder<Context>> {
  if (data.foreground) {
    const options = config.fetchDataOptions.onEventType6.onForeground;
    const [_results, getAction] = await fetchBackendData(
      vehicle,
      context,
      options,
    );
    return Action<Context>()
      .bind(getAction(targetVin.isNone()))
      .compose(openCard(cardName, context.cardInfo, targetVin));
  } else {
    //return Promise.resolve(Action.bind(Context.actions.updateContentState, 'Reload'));
    return Promise.resolve(Action());
  }
}

function handleEventType7(
  params: NotificationHandlerParams,
): Promise<ActionBinder<Context>> {
  return handleRemoteCommand<WifiStatus>(
    params,
    'Wifi',
    (a: VehicleDataResult) =>
      a.wifiStatus.map((a) =>
        getRemoteControlChangedStatus(
          params.dialogTitleId,
          params.vehicle.geofence,
          params.vehicle.geofence,
          params.context.serviceTelemetry,
          params.context.serviceTelemetry,
          params.context.wifiStatus,
          a,
          params.context.lastRemoteCommands,
          Option(params.context.ongoingRemoteStates[params.vehicle.vin]),
        ),
      ),
    config.fetchDataOptions.onEventType7.onForeground,
  );
}

function handleEventType8(
  params: NotificationHandlerParams,
): Promise<ActionBinder<Context>> {
  return handleRemoteCommand<[Option<Geofence>, Option<ServiceTelemetry>]>(
    params,
    'VehicleLocation',
    (a: VehicleDataResult) =>
      a.geofence.map((newGeofence) =>
        getRemoteControlChangedStatus(
          params.dialogTitleId,
          params.vehicle.geofence,
          newGeofence,
          params.context.serviceTelemetry,
          a.serviceTelemetry,
          params.context.wifiStatus,
          params.context.wifiStatus,
          params.context.lastRemoteCommands,
          Option(params.context.ongoingRemoteStates[params.vehicle.vin]),
        ),
      ),
    config.fetchDataOptions.onEventType8.onForeground,
  );
}

function handleEventType9({
  data,
  context,
  dialogTitleId,
  dialogBodyId,
  dialogActionId,
  dialogCloseId,
  vehicle,
}: NotificationHandlerParams): Promise<ActionBinder<Context>> {
  const openAction = Action.bind(Context.actions.updateCardInfo, {
    state: 'Closing',
  })
    .compose(closeRegistrationWindows())
    .bind(Context.actions.updateRegisterWindowMode, 'Show')
    .bind(Context.actions.updateVehicleManagementMode, 'Show');

  if (data.foreground) {
    const intl = createIntl(context);
    return Promise.resolve(
      Action.bind(Context.actions.updateContentState, 'Reload').bind(
        Context.actions.updateConfirmDialog,
        {
          state: 'Show',
          title: intl.formatMessage({ id: dialogTitleId }),
          message: intl.formatMessage(
            { id: dialogBodyId },
            { model: vehicle.model },
          ),
          confirmButtonTitle: intl.formatMessage({ id: dialogActionId }),
          cancelButtonTitle: intl.formatMessage({ id: dialogCloseId }),
          onConfirm: () => homeDispatch.context.bind(openAction),
        },
      ),
    );
  } else {
    return Promise.resolve(
      Action
        //.bind(Context.actions.updateContentState, 'Reload')
        .compose(openAction),
    );
  }
}

async function handleEventType10({
  data,
  vehicle,
  context,
  targetVin,
  showDialog,
}: NotificationHandlerParams): Promise<ActionBinder<Context>> {
  if (data.foreground) {
    const options = config.fetchDataOptions.onEventType10.onForeground;
    const [_results, getAction] = await fetchBackendData(
      vehicle,
      context,
      options,
    );
    return Action<Context>()
      .bind(getAction(targetVin.isNone()))
      .compose(
        showDialog({
          cardName: 'ServiceReminder',
          onConfirm: () => homeDispatch.context(getAction(true)),
        }),
      );
  } else {
    return Promise.resolve(
      Action
        //.bind(Context.actions.updateContentState, 'Reload')
        .compose(openCard('ServiceReminder', context.cardInfo, targetVin)),
    );
  }
}

export async function handleNotification(
  context: Context,
): Promise<ActionBinder<Context>> {
  if (await isSignedIn()) {
    const intl = createIntl(context);
    return context.notificationData.unwrap(
      (data) => {
        if (
          (data.foreground === true && context.contentState === 'Loaded') ||
          data.foreground === false
        ) {
          return EventIdTypeMap.get(data.titleId).unwrap(
            (eventIdType) => {
              const prefixId = eventIdType.prefixId;
              const messageId = MessageIdMap.get(prefixId);
              const notificationTitleId = messageId.notification.title;
              const dialogTitleId = messageId.dialog.title;
              const dialogBodyId = messageId.dialog.body;
              const dialogActionId = messageId.dialog.action;
              const dialogCloseId = messageId.dialog.close;
              const toastId = messageId.remoteControl;
              const targetVin = context.selectedVehicleVin.flatMap((vin) => {
                if (vin === data.vin) return None<string>();
                else return Some(data.vin);
              });

              return Option(
                context.vehicles.find((_) => _.vin === data.vin),
              ).unwrap(
                (vehicle) => {
                  function showDialog({
                    cardName,
                    onConfirm,
                    onCancel,
                  }: ShowDialogParams): ActionBinder<Context> {
                    return showConfirmDialog(
                      dialogTitleId,
                      dialogBodyId,
                      dialogActionId,
                      dialogCloseId,
                      vehicle.model,
                      cardName,
                      context.cardInfo,
                      targetVin,
                      intl,
                      onConfirm,
                      onCancel,
                    );
                  }

                  function showToast({
                    cardName,
                    onClick,
                  }: ShowToastParams): ActionBinder<Context> {
                    return Action.bind(Context.actions.showToast, {
                      state: 'Show',
                      message: intl.formatMessage({ id: toastId }),
                      onClick: () =>
                        Dispatch<ToastData, HomeData>()
                          .context(
                            openCard(cardName, context.cardInfo, targetVin),
                          )
                          .pipe((_) => (onClick ? _.compose(onClick()) : _)),
                    });
                  }

                  const params: NotificationHandlerParams = {
                    vehicle,
                    eventIdType,
                    data,
                    targetVin,
                    notificationTitleId,
                    dialogTitleId,
                    dialogBodyId,
                    dialogActionId,
                    dialogCloseId,
                    showDialog,
                    showToast,
                    context,
                  };

                  switch (eventIdType.type) {
                    case 1:
                      return handleEventType1(params);
                    case 2:
                    case 3:
                      return handleEventType3(params);
                    case 4:
                      return handleEventType4(params);
                    case 5:
                      return handleEventType6(params, 'VehicleLocation');
                    case 6:
                      return handleEventType6(params, 'VehicleInfo');
                    case 7:
                      return handleEventType7(params);
                    case 8:
                      return handleEventType8(params);
                    case 9:
                      return handleEventType9(params);
                    case 10:
                      return handleEventType10(params);
                    default:
                      return Promise.resolve(Action<Context>());
                  }
                },
                () => {
                  println(
                    Error(
                      `Invalid VIN: ${data.vin}. You have ${context.vehicles
                        .map((_) => _.vin)
                        .join(',')}.`,
                    ),
                  );
                  //return Promise.resolve(Action.compose(commonErrorDialogAction(intl)));
                  return Promise.resolve(Action<Context>());
                },
              );
            },
            () => {
              println(Error(`Invalid messageId: ${data.titleId}`));
              return Promise.resolve(Action<Context>());
            },
          );
        } else {
          println(
            Error(
              `A notification has been received but contentState(${context.contentState}) is not valid`,
            ),
          );
          return Promise.resolve(Action<Context>());
        }
      },
      () => {
        println(Error(`No notification data found`));
        return Promise.resolve(commonErrorDialogAction(intl));
      },
    );
  } else {
    println(
      Error(`A notification has been received but you are not signed in.`),
    );
    return Promise.resolve(Action<Context>());
  }
}

export function listenNativeEvent(dispatch: HomeDispatch): HomeDispatch {
  const _ = dispatch;
  return _.asyncAll(
    subscribeNativeCustomEvent()
      .map<HomeDispatch>((data) => {
        if (data.target === 'ssid') {
          return _.context(Context.actions.updateSSID, {
            ssid: data.ssid,
            bssid: data.bssid,
          });
        } else if (data.target === 'locale') {
          return _.context(Context.actions.updateLocale, data.locale);
        } else if (data.target === 'deviceToken') {
          isSignedIn().then((signedIn) => {
            if (signedIn) {
              registerDeviceToken({
                platform: 'push_gcm',
                deviceToken: data.token,
              })
                .then(() => setDeviceToken(data.token))
                .catch(() => setDeviceToken(null));
            }
          });
          return _;
        } else if (data.target === 'notification') {
          if (
            (data.foreground === false && data.label === 'openedapp') ||
            data.foreground === true
          ) {
            return _.context(
              Context.actions.updateCommand,
              'HandleNotification',
            ).context(Context.actions.updateNotificationData, Some(data));
          } else {
            return _;
          }
        } else if (data.target === 'version') {
          return _.context(Context.actions.updatePlatform, {
            appVersion: data.data.version,
            buildNumber: data.data.buildNumber,
            os: data.data.platform,
            osVersion: data.data.osVersion,
          });
        } else if (data.target === 'action' && data.action === 'back') {
          return _.context((context) => {
            if (
              context.cardInfo.state === 'Opening' ||
              context.cardInfo.state === 'Opened'
            ) {
              return Context.actions.updateNativeBackAction(context, 'InCard');
            } else {
              return Context.actions.updateNativeBackAction(
                context,
                'TopLevel',
              );
            }
          });
        } else if (data.target === 'action' && data.action === 'foreground') {
          return _.context((ctx) =>
            Context.actions.updateNativeForegroundAction(
              ctx,
              !ctx.nativeForegroundAction,
            ),
          ).context((context) =>
            context.contentState === 'Loaded'
              ? Action.bind(Context.actions.updateContentState, 'Reload')
                  .bind(
                    Context.actions.updateFetchVehicleDataOptions,
                    config.fetchDataOptions.onBackToForeground,
                  )
                  .apply(context)
              : context,
          );
        } else if (data.target === 'keyboard') {
          return _.context((context) =>
            Context.actions.updateViewPort(context, getViewPort(context)),
          );
        } else if (data.target === 'screenSize') {
          return _.context(Context.actions.updateScreenSize, {
            width: data.screenWidth,
            height: data.screenHeight,
            marginTop: data.marginTop,
            marginBottom: data.marginBottom,
          }).context((context) =>
            Context.actions.updateViewPort(context, getViewPort(context)),
          );
        } else if (data.target === 'qrCodeData') {
          return _.asyncAll(
            acceptSharedUser({
              invitationCode: data.invitationCode,
              invitationExpireTime: data.invitationExpireTime,
            })
              .then(() => {
                return _.context(
                  Context.actions.updateSharedUserRegScreenMode,
                  {
                    state: 'Registered',
                    error: false,
                  },
                );
              })
              .catch((e) => {
                println(e);
                return _.context(
                  Context.actions.updateSharedUserRegScreenMode,
                  {
                    state: 'Registered',
                    error: true,
                  },
                );
              }),
          ).context(Context.actions.updateSharedUserRegScreenMode, {
            state: 'InvitationCodeSent',
            error: false,
          });
        } else {
          return _;
        }
      })
      .recover((err) => Listener.success(_.effect(() => println(err)))),
  );
}
