import { AuthInfo, isSignedIn, onBackUp, onRestore } from '../../apis/Auth';
import {
  getAuthInfo,
  notifyListenReadyOnInitialize,
  println,
  setAuthInfo,
} from '../../apis/NativeApi';
import {
  subscribeServiceTelemetry,
  unsubscribeServiceTelemetry,
} from '../../apis/ServiceTelemetry';
import { Vehicle, getSelectedVehicle } from '../../apis/VehicleList';
import { Listener } from '../../util/Listener';
import { Option, Some } from '../../util/Option';
import { Success } from '../../util/Result';
import { Unit } from '../../util/Unit';
import { commonErrorDialogAction } from '../components/AlertDialog';
import { CardName } from '../components/Card';
import { createIntl } from '../i18n/Intl';
import { ActionBinder } from '../states/ActionBinder';
import { CardInfo, Context, ViewPort } from '../states/Context';
import { Action } from '../states/Reducer';
import { HomeDispatch } from './Home';
import { listenNativeEvent } from './NativeEventListener';

export function closeRegistrationWindows(): ActionBinder<Context> {
  return Action.bind(Context.actions.updateRegisterWindowMode, 'Hide')
    .bind(Context.actions.updateVehicleManagementMode, 'Close')
    .bind(Context.actions.updateNotificationVehiclesMode, 'Close')
    .bind(Context.actions.updateNotificationSettingsMode, 'Close')
    .bind(Context.actions.updateCarRegScreenMode, 'Close');
}

export function openCard(
  name: CardName,
  cardInfo: CardInfo,
  targetVin: Option<string>,
): ActionBinder<Context> {
  return Action<Context>()
    .bind(closeRegistrationWindows(), Unit)
    .pipe((_) =>
      targetVin.unwrap(
        (vin) =>
          _.bind(Context.actions.updateContentState, 'Load').bind(
            Context.actions.updateSelectedVehicleVin,
            Some(vin),
          ),
        () => _,
      ),
    )
    .pipe((_) => {
      if (
        (cardInfo.state === 'Opening' || cardInfo.state === 'Opened') &&
        cardInfo.name.some((_) => _ === name)
      ) {
        return _;
      } else if (cardInfo.state === 'Opening' || cardInfo.state === 'Opened') {
        return _.bind(Context.actions.updateCardInfo, {
          state: 'Closing',
        }).bind(
          Context.actions.updateOnCardClosed,
          Some(() =>
            Action.bind(Context.actions.updateCardInfo, {
              state: 'Opening',
              name,
            }),
          ),
        );
      } else {
        return _.bind(Context.actions.updateCardInfo, {
          state: 'Opening',
          name,
        });
      }
    });
}

export function getLastUpdate(context: Context): Date {
  return context.vehicleEvents.reduce(
    (latest, event) =>
      event.timestamp.getTime() > latest.getTime() ? event.timestamp : latest,
    getSelectedVehicle(context).unwrap(
      (_) => _.timestamp,
      () => new Date() /* Should not happen */,
    ),
  );
}

export function getViewPort(context: Context): ViewPort {
  return {
    width: window.innerWidth,
    height:
      context.platform.os === 'ios' ? window.outerHeight : window.innerHeight,
  };
}

export function initialize(
  context: Context,
  dispatch: HomeDispatch,
): HomeDispatch {
  const _ = dispatch;
  if (context.contentState === 'Initialize') {
    return _.context(Context.actions.updateContentState, 'Initializing')
      .asyncAll(
        Listener((dispatch) =>
          window.addEventListener('resize', () =>
            dispatch(
              Success(
                _.context((context) =>
                  Context.actions.updateViewPort(context, getViewPort(context)),
                ),
              ),
            ),
          ),
        ),
      )
      .compose(listenNativeEvent(_))
      .asyncAll(
        notifyListenReadyOnInitialize([
          'locale',
          'ssid',
          'deviceToken',
          'version',
          'action',
          // 'notification',
          'locationPermission',
          'locationEnable',
          'location',
          'storage',
          'keyboard',
          'qrCodeData',
          'screenSize',
        ]).then((platform) =>
          _.effect(() => onBackUp((authInfo) => setAuthInfo(authInfo)))
            .context(Context.actions.updatePlatform, platform)
            .asyncAll(
              onRestore(() =>
                getAuthInfo().then((_) =>
                  _.map((_) => _ as AuthInfo | null).getOrElse(() => null),
                ),
              )
                .then(() => isSignedIn())
                .then((isSignedIn) =>
                  _.pipe((_) =>
                    isSignedIn
                      ? _.context(
                          Context.actions.updateContentState,
                          'SignedIn',
                        )
                      : _.context(
                          Context.actions.updateContentState,
                          'SignedOut',
                        ),
                  ),
                ),
            ),
        ),
      );
  } else {
    return _;
  }
}

export function startSubscribeServiceTelemetry(
  context: Context,
  vehicle: Vehicle,
  dispatch: HomeDispatch,
): HomeDispatch {
  const intl = createIntl(context);
  const _ = dispatch;
  if (
    context.serviceTelemetry.data.toOption().some((_) => _.en_theft_trk) &&
    !context.isServiceTelemetrySubscribed
  ) {
    return _.context(
      Context.actions.updateServiceTelemetrySubscribed,
      true,
    ).asyncAll(
      Listener((dispatch) =>
        subscribeServiceTelemetry(vehicle.dcmId)
          .then((listener) =>
            listener.onResult(
              (srv) =>
                dispatch(
                  Success(
                    _.context(Context.actions.updateServiceTelemetry, srv),
                  ),
                ),
              (err) =>
                dispatch(
                  Success(
                    _.effect(() => println(err)).context(
                      commonErrorDialogAction(intl),
                    ),
                  ),
                ),
            ),
          )
          .catch((err) =>
            dispatch(
              Success(
                _.effect(() => println(err)).context(
                  commonErrorDialogAction(intl),
                ),
              ),
            ),
          ),
      ),
    );
  } else {
    return _;
  }
}

export function stopSubscribeServiceTelemetry(
  context: Context,
  vehicle: Vehicle,
  dispatch: HomeDispatch,
): HomeDispatch {
  const intl = createIntl(context);
  const _ = dispatch;
  if (
    !context.serviceTelemetry.data.toOption().some((_) => _.en_theft_trk) &&
    context.isServiceTelemetrySubscribed
  ) {
    return _.context
      .bind(Context.actions.updateServiceTelemetrySubscribed, false)
      .asyncAll(
        unsubscribeServiceTelemetry(vehicle.dcmId)
          .then(() => _)
          .catch((err) =>
            _.effect(() => println(err)).context(commonErrorDialogAction(intl)),
          ),
      );
  } else {
    return _;
  }
}

// MON-697: Some operations are restricted while loading data.
export function isContentLoading(context: Context): boolean {
  return (
    context.contentState === 'Loading' || context.contentState === 'Updating'
  );
}

export const shouldShowNetworkErrorOnHome = (context: Context): boolean =>
  context.dataErrors.vehicleList; // currently only show network error screen when vehicleList is error.
