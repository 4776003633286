import { ToOptional, copy } from '../../util/Copyable';
import {
  DataMapBase,
  DispatchBase,
  DispatchItemsBase,
  createDispatchBase,
  emptyDispatchItemsBase,
} from './DispatchBase';

export type RootDataMap<Data> = DataMapBase<Data>;

export type DispatchRoot<Data> = Omit<
  DispatchBase<'DispatchRoot', RootDataMap<Data>>,
  'current'
>;

export type DispatchRootItems<Data> = DispatchItemsBase<
  'DispatchRoot',
  RootDataMap<Data>
>;

interface DispatchRootFactory {
  <Data>(): DispatchRoot<Data>;
}

function emptyDispatchRootItems<Data>(): DispatchRootItems<Data> {
  return emptyDispatchItemsBase<'DispatchRoot', RootDataMap<Data>>([
    'self',
    'context',
  ]);
}

export function createDispatchRootItems<Data>(
  items: ToOptional<DispatchRootItems<Data>>,
): DispatchRootItems<Data> {
  return copy<DispatchRootItems<Data>>(emptyDispatchRootItems(), items);
}

export function createDispatchRoot<Data>(
  items: DispatchRootItems<Data>,
): DispatchBase<'DispatchRoot', RootDataMap<Data>> {
  return createDispatchBase<'DispatchRoot', RootDataMap<Data>>(
    items,
    createDispatchRoot,
  );
}

const dispatchRootFactory: DispatchRootFactory = <_Data>() =>
  createDispatchRoot(createDispatchRootItems({}));

export const DispatchRoot: DispatchRootFactory = Object.assign(
  dispatchRootFactory,
  {},
);
