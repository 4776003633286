import React from 'react';
import { Message, useIntl } from '../i18n/Intl';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Dialog, DialogButton, State } from './Dialog';

type Props<DataMap> = Readonly<{
  parent: DataMapStore<DataMap>;
  state: State;
  title: Message;
  message: Message;
  confirmButtonTitle?: Message;
  confirmButton2Title?: Message;
  cancelButtonTitle?: Message;
  onConfirm?: () => DataMapDispatch<WithContext<DataMap>>;
  onConfirm2?: () => DataMapDispatch<WithContext<DataMap>>;
  onCancel?: () => DataMapDispatch<WithContext<DataMap>>;
}>;

export function ConfirmDialog<DataMap>(
  props: Props<DataMap>,
): React.ReactElement {
  const {
    views: { View },
  } = useParentStore({ parent: props.parent });
  const Footer = View;
  const intl = useIntl();

  return (
    <Dialog
      {...props}
      footer={
        <Footer>
          <DialogButton
            parent={props.parent}
            type='Confirm'
            title={
              props.confirmButtonTitle
                ? props.confirmButtonTitle
                : intl.formatMessage({ id: 'Common_ok' })
            }
            fontWeight='bold'
            onClick={props.onConfirm}
          />
          {props.confirmButton2Title && props.onConfirm2 && (
            <DialogButton
              parent={props.parent}
              type='Confirm'
              title={props.confirmButton2Title}
              fontWeight='bold'
              onClick={props.onConfirm2}
            />
          )}
          <DialogButton
            parent={props.parent}
            type='Confirm'
            title={
              props.cancelButtonTitle
                ? props.cancelButtonTitle
                : intl.formatMessage({ id: 'Common_cancel' })
            }
            fontWeight='normal'
            onClick={props.onCancel}
          />
        </Footer>
      }
    />
  );
}
