import React from 'react';
import { println } from '../../apis/NativeApi';
import { getNotificationStatus } from '../../apis/Notification';
import { Vehicle } from '../../apis/VehicleList';
import { Unit } from '../../util/Unit';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowMode,
} from '../components/SlideInWindow';
import { HomeDataMap } from '../home/Home';
import { Intl, useIntl } from '../i18n/Intl';
import { ActionBinder } from '../states/ActionBinder';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mode: SlideInWindowMode;
  cars: Vehicle[];
}>;

function ListItem({
  parent,
  model,
  vin,
  image,
}: {
  parent: DataMapStore<HomeDataMap>;
  model: string;
  vin: string;
  image: string;
}): React.ReactElement {
  const {
    views: { View, Image, Text },
    dispatch,
  } = useParentStore({ parent });
  const intl = useIntl();
  const errorDialog = errorAction(intl);

  return (
    <View>
      <View
        style={{
          display: 'flex',
          alignItems: 'stretch',
          marginLeft: Length.px(16),
        }}
        onClick={() => {
          return dispatch.context
            .bind(Context.actions.updateOverlayState, {
              state: 'Show',
              opacity: 0.4,
            })
            .context.bind(Context.actions.updateWindowSpinnerState, 'Show')
            .asyncAll(
              getNotificationStatus(vin)
                .then((notificationStatus) => {
                  return dispatch.context
                    .bind(
                      Context.actions.updateNotificationStatus,
                      notificationStatus,
                    )
                    .context.bind(
                      Context.actions.updateNotificationStatusOrig,
                      notificationStatus,
                    )
                    .context.bind(
                      Context.actions.updateNotificationSettingsMode,
                      'Show',
                    )
                    .context.bind(
                      Context.actions.updateNotificationSelectedModel,
                      model,
                    )
                    .context.bind(
                      Context.actions.updateIsNotificationPushButtonDisabled,
                      true,
                    )
                    .context.bind(Context.actions.updateOverlayState, {
                      state: 'Hide',
                    })
                    .context.bind(
                      Context.actions.updateWindowSpinnerState,
                      'Hide',
                    );
                })
                .catch((err) =>
                  dispatch
                    .effect(() => println(err))
                    .context(errorDialog, Unit),
                ),
            );
        }}
      >
        <View
          style={{
            flexGrow: 0,
            marginRight: Length.px(16),
            paddingTop: Length.px(0),
            paddingBottom: Length.px(0),
          }}
        >
          <Image
            src={image}
            style={{
              height: Length.px(64),
            }}
          />
        </View>
        <View
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: Length.px(4),
            borderBottom: Border('solid', Length.px(0.5), Colors.border),
          }}
        >
          <View>
            <Text
              style={{
                fontSize: Length.px(16),
                lineHeight: Length.px(16),
                fontWeight: 'bold',
              }}
            >
              {model}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

function errorAction(intl: Intl): ActionBinder<Context> {
  return Action.bind(Context.actions.updateAlertDialog, {
    state: 'Show',
    title: intl.formatMessage({ id: 'Common_networkError' }),
    message: intl.formatMessage({ id: 'Common_networkErrorMessage' }),
  })
    .bind(Context.actions.updateOverlayState, { state: 'Hide' })
    .bind(Context.actions.updateWindowSpinnerState, 'Hide');
}
export function NotificationVehicles(props: Props): React.ReactElement {
  const intl = useIntl();

  return (
    <>
      <SlideInWindow
        parent={props.parent}
        mode={props.mode}
        title={intl.formatMessage({ id: 'Register_settingNotify' })}
        headerLeft={
          <HeaderLeft
            onClick={(dispatch) =>
              dispatch.context.bind(
                Context.actions.updateNotificationVehiclesMode,
                'Hide',
              )
            }
            parent={props.parent}
          />
        }
        onClose={(dispatch) =>
          dispatch
            .context(Context.actions.updateRegisterWindowMode, 'Hide')
            .context(Context.actions.updateNotificationVehiclesMode, 'Hide')
        }
      >
        {props.cars.map((car, idx) => (
          <ListItem
            parent={props.parent}
            model={car.model}
            vin={car.vin}
            image={car.image}
            key={idx}
          />
        ))}
      </SlideInWindow>
    </>
  );
}
