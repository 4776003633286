import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import * as intl from 'react-intl';
import { Lang } from '../../util/Locale';
import { Context } from '../states/Context';
import { MessageIds, MessagesType as Messages } from './Types';
import { Messages as en } from './en';
import { Messages as ja } from './ja';

export type Message = Readonly<{
  toString: () => string;
  toReactNode: () => React.ReactNode;
}>;

export type MessageKeys = MessageIds;

export type Intl = Readonly<{
  formatMessage: (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType>,
  ) => Message;
  formatDate: (date: Date, options?: intl.FormatDateOptions) => string;
  formatTime: (date: Date, options?: intl.FormatDateOptions) => string;
  formatDateTime: (date: Date, options?: intl.FormatDateOptions) => string;
}>;

type MessageDescriptor = Readonly<{
  id: MessageKeys;
}>;

export function Message(lines: string | string[]): Message {
  const xs = typeof lines === 'string' ? [lines] : lines;
  return {
    toString: () => xs.join(' '),
    toReactNode: () => {
      return React.createElement(
        React.Fragment,
        null,
        ...xs.map((line, index) => {
          if (index === 0)
            return React.createElement(React.Fragment, null, line);
          else
            return React.createElement(
              React.Fragment,
              null,
              React.createElement('br'),
              line,
            );
        }),
      );
    },
  };
}

export function getMessages(lang: Lang): Messages {
  switch (lang) {
    case 'ja':
      return ja;
    case 'en':
      return en;
    default:
      return en;
  }
}

export function createIntl(context: Context): Intl {
  return createIntlInstance(context.locale.lang);
}

export function useIntl(): Intl {
  const context = React.useContext(Context.ref);
  const lang = context.locale.lang;
  return React.useMemo(() => createIntlInstance(lang), [lang]);
}

function createIntlInstance(lang: Lang): Intl {
  const self = intl.createIntl(
    {
      locale: lang,
      messages: getMessages(lang),
    },
    intl.createIntlCache(),
  );
  return {
    formatMessage: (
      descriptor: MessageDescriptor,
      values?: Record<string, PrimitiveType>,
    ) => {
      return Message(
        self.formatMessage({ id: descriptor.id }, values).split('\n'),
      );
    },
    formatDate: (date: Date, options?: intl.FormatDateOptions) =>
      self.formatDate(date, options),
    formatTime: (date: Date, options?: intl.FormatDateOptions) =>
      self.formatTime(date, options),
    formatDateTime: (date: Date, options?: intl.FormatDateOptions) =>
      `${self.formatDate(date, options)} ${self.formatTime(date, options)}`,
  };
}
