import React from 'react';
import { config } from '../../Config';
import {
  dismissSplash,
  nativeBack,
  notifyListenReady,
  println,
} from '../../apis/NativeApi';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { copy } from '../../util/Copyable';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { Unit } from '../../util/Unit';
import { AlertDialog } from '../components/AlertDialog';
import { cardBorderRadius } from '../components/Card';
import { EmptyProps } from '../components/Component';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { Overlay, OverlayState } from '../components/Overlay';
import { SlideInCardState } from '../components/SlideInCard';
import { Toast } from '../components/Toast';
import { WindowSpinner } from '../components/WindowSpinner';
import { useIntl } from '../i18n/Intl';
import { LoginWindow, getLoginWindowMode } from '../login/LoginWindow';
import { DebugPane } from '../mock/DebugPane';
import { RegistrationWindow } from '../registration/RegistrationWindow';
import { VehicleRegistration } from '../registration/VehicleRegistration';
import { Context } from '../states/Context';
import {
  DataMapDispatch,
  ExtendParent,
  WithContext,
  createDispatchDataMap,
  useStore,
} from '../states/DataMapStore';
import { emptyDispatchItemsBase } from '../states/DispatchBase';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { FluidPanes as GeofenceFluidPanes } from '../vehicle-location/FluidPanes';
import { GeofenceWindow } from '../vehicle-location/GeofenceWindow';
import { getOverlayState as getGeofenceOverlayState } from '../vehicle-location/Helpers';
import {
  VehicleLocationData,
  VehicleLocationState,
  vehicleLocationCardHeight,
} from '../vehicle-location/VehicleLocationState';
import { ResetPane as WifiResetPane } from '../wifi/ResetPane';
import { WifiWindow } from '../wifi/WifiWindow';
import { fetchVehicleData } from './FetchVehicleData';
import {
  initialize,
  startSubscribeServiceTelemetry,
  stopSubscribeServiceTelemetry,
} from './Helpers';
import { HomeContainer } from './HomeContainer';
import { handleNotification } from './NativeEventListener';

type Key = 'home';

export type HomeDataMap = ExtendParent<EmptyProps, Key, Data>;

export type HomeData = Data;

type Data = Readonly<{
  localState: LocalState;
}>;

type LocalState /*CommandLocalState<HomeCommand> &*/ = Readonly<{
  geofence: VehicleLocationData;

  wifi: Readonly<{
    resetPaneState: SlideInCardState;
  }>;
}>;

export type Actions = Readonly<{
  updateResetPaneState: (data: Data, resetPaneState: SlideInCardState) => Data;
}>;

export type HomeDispatch = DataMapDispatch<WithContext<HomeDataMap>>;

//export type HomeCommand = MapCommand;

//const commandState = CommandState<Context, ContextCommand>();

export const homeDispatch = Scope<HomeDispatch>(() => {
  return createDispatchDataMap(
    emptyDispatchItemsBase<'DataMapDispatch', WithContext<HomeDataMap>>([
      'context',
      'home',
    ]),
  );
});

function getOverlayState(context: Context, homeData: HomeData): OverlayState {
  return context.overlayState.state === 'Show' ||
    getGeofenceOverlayState(
      homeData.localState.geofence.localState.helpDialogState,
      homeData.localState.geofence.localState.trackingPaneState,
    ) === 'Show'
    ? 'Show'
    : 'Hide';
}

function handleCommand(context: Context, dispatch: HomeDispatch): HomeDispatch {
  return Context.handleCommand<HomeDataMap>(context, dispatch, (command) => {
    switch (command) {
      case 'HandleNotification':
        return dispatch.asyncAll(
          handleNotification(context).then((binder) =>
            dispatch.context(
              binder.bind(Context.actions.updateCommand, 'HandleOnLoaded'),
              Unit,
            ),
          ),
        );
      default:
        return dispatch;
    }
  });
}

export const HomeActions: Actions = {
  ...VehicleLocationState.actions,
  updateResetPaneState: (data: Data, resetPaneState: SlideInCardState) =>
    copy(data, {
      localState: {
        wifi: {
          resetPaneState,
        },
      },
    }),
};

export function Home(): React.ReactElement {
  const state = useStore<Key, Data>({
    key: 'home',

    default: () => ({
      localState: {
        //...commandState.localState,
        geofence: VehicleLocationState.create(
          theme(
            {
              containerStyle: {
                position: 'relative',
                height: vehicleLocationCardHeight,
                borderRadius: cardBorderRadius,
              },
            },
            {
              containerStyle: {
                position: 'relative',
              },
            },
          ),
        ),

        wifi: {
          resetPaneState: 'Hide',
        },
      },
    }),

    initialize: (data: Data) => ({
      ...data,
      localState: {
        ...data.localState,
        geofence: VehicleLocationState.initialize(data.localState.geofence),
      },
    }),

    update: ({ data: { context }, dispatch }) => {
      return handleCommand(context, dispatch)
        .effect(() => {
          if (
            context.contentState === 'Loaded' ||
            context.contentState === 'NoVehicle'
          )
            dismissSplash();
        })
        .pipe((_) =>
          getSelectedVehicle(context)
            .map((vehicle) =>
              _.compose(
                startSubscribeServiceTelemetry(context, vehicle, dispatch),
              ).compose(
                stopSubscribeServiceTelemetry(context, vehicle, dispatch),
              ),
            )
            .getOrElse(() => _),
        )
        .compose(initialize(context, dispatch))
        .pipe((_) => {
          if (
            context.contentState === 'Loaded' &&
            context.isNotificationReady === false
          ) {
            notifyListenReady(['notification']);
            return _.context(Context.actions.updateNotificationReady, true);
          } else {
            return _;
          }
        })
        .pipe((_) => {
          if (
            context.contentState === 'SignedIn' ||
            context.contentState === 'Load'
          ) {
            return _.context
              .bind(Context.actions.updateContentState, 'Loading')
              .context.bind(Context.actions.updateFetchVehicleDataOptions, {})
              .compose(
                fetchVehicleData({
                  context,
                  options: config.fetchDataOptions.afterSignedIn,
                }),
              );
          } else return _;
        })
        .pipe((_) => {
          if (context.contentState === 'Reload')
            return _.context
              .bind(Context.actions.updateContentState, 'Updating')
              .context.bind(Context.actions.updateFetchVehicleDataOptions, {})
              .compose(
                fetchVehicleData({
                  context,
                  options: context.fetchVehicleDataOptions,
                }),
              );
          else return _;
        })
        .pipe((_) => {
          if (context.nativeBackAction === 'TopLevel') {
            const none = _.context.bind(
              Context.actions.updateNativeBackAction,
              'None',
            );

            if (context.backHandler.handle()) {
              println('[DEBUG] Back is handled by backHandler');
              return none;
            }

            if (
              context.alertDialog.state === 'Hide' &&
              context.confirmDialog.state === 'Hide' &&
              context.overlayState.state === 'Hide'
            ) {
              if (context.carRegScreenMode === 'Show') {
                return none.context.bind(
                  Context.actions.updateCarRegScreenMode,
                  'Hide',
                );
              } else if (context.notificationSettingsMode === 'Show') {
                return none.context.bind(
                  Context.actions.updateNotificationSettingsMode,
                  'Hide',
                );
              } else if (context.notificationVehiclesMode === 'Show') {
                return none.context.bind(
                  Context.actions.updateNotificationVehiclesMode,
                  'Hide',
                );
              } else if (context.vehicleManagementMode === 'Show') {
                return none.context.bind(
                  Context.actions.updateVehicleManagementMode,
                  'Hide',
                );
              } else if (context.registerWindowMode === 'Show') {
                return none.context.bind(
                  Context.actions.updateRegisterWindowMode,
                  'Hide',
                );
              } else if (
                context.cardInfo.state === 'Opening' ||
                context.cardInfo.state === 'Opened'
              ) {
                return none.context.bind(Context.actions.updateCardInfo, {
                  state: 'Closing',
                });
              } else if (
                context.cardInfo.state === 'Closing' ||
                context.cardInfo.state === 'Closed'
              ) {
                nativeBack();
                return none;
              } else if (getLoginWindowMode(context) === 'Show') {
                nativeBack();
                return none;
              } else {
                return none;
              }
            } else {
              return none;
            }
          } else {
            return _;
          }
        })
        .effect(() => {
          if (context.fetchVehicleDataCount < 0)
            println(
              Error(
                `fetchVehicleDataCount is negative: ${context.fetchVehicleDataCount}`,
              ),
            );
        });
    },
  });

  const Container = state.views.View;
  const context = state.data.context;
  const intl = useIntl();
  const viewPort = context.viewPort;
  const data = state.data.home;
  const localState = data.localState;
  const store = state.stores.home;

  return (
    <>
      <Container
        id='Home'
        style={{
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: Colors.background,
          width: Length.px(viewPort.width),
          height: Length.px(viewPort.height),
          userSelect: 'none',
          WebkitUserSelect: 'none',
          touchAction: 'none',
        }}
      >
        <HomeContainer parent={state.stores} />

        <RegistrationWindow
          parent={state.stores}
          mode={context.registerWindowMode}
          vehicles={context.vehicles}
        />

        <LoginWindow parent={store} mode={getLoginWindowMode(context)} />

        <VehicleRegistration parent={store} mode={context.carRegScreenMode} />

        {theme(
          null,
          <>
            <GeofenceWindow
              parent={state.stores}
              mode={context.geofenceWindowMode}
            />
            <WifiWindow parent={state.stores} mode={context.wifiWindowMode} />
          </>,
        )}

        <Overlay state={getOverlayState(context, data)} />

        <WindowSpinner state={context.windowSpinnerState} />

        <GeofenceFluidPanes parent={store} />

        <WifiResetPane
          parent={state.stores}
          state={localState.wifi.resetPaneState}
        />

        <Toast
          state={context.toast.state}
          message={context.toast.message}
          //open={context.command === 'ShowToast'}
          onClick={context.toast.onClick}
        />

        <AlertDialog
          parent={state.stores}
          state={context.alertDialog.state}
          title={context.alertDialog.title}
          message={context.alertDialog.message}
        />

        <ConfirmDialog
          parent={state.stores}
          state={context.confirmDialog.state}
          title={context.confirmDialog.title}
          message={context.confirmDialog.message}
          confirmButtonTitle={context.confirmDialog.confirmButtonTitle.unwrap(
            (_) => _,
            () => intl.formatMessage({ id: 'Common_ok' }),
          )}
          confirmButton2Title={context.confirmDialog.confirmButton2Title.unwrap(
            (_) => _,
            () => undefined,
          )}
          cancelButtonTitle={context.confirmDialog.cancelButtonTitle.unwrap(
            (_) => _,
            () => intl.formatMessage({ id: 'Common_cancel' }),
          )}
          onConfirm={context.confirmDialog.onConfirm}
          onConfirm2={context.confirmDialog.onConfirm2.unwrap(
            (_) => _,
            () => undefined,
          )}
          onCancel={context.confirmDialog.onCancel}
        />
      </Container>

      <DebugPane parent={store} />
    </>
  );
}
