import React, { useCallback, useEffect, useState } from 'react';
import { HomeDataMap } from '../home/Home';
import { Message } from '../i18n/Intl';
import { Context } from '../states/Context';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { usePrevious } from '../states/Previous';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowDispatch,
  SlideInWindowMode,
} from './SlideInWindow';

export type WindowDispatch = SlideInWindowDispatch<HomeDataMap>;
type HeaderLeftDispatch = DataMapDispatch<WithContext<HomeDataMap>>;

export interface WindowStackController {
  readonly parent: DataMapStore<HomeDataMap>;
  /**
   * showWindow: The action for showing the Window component.
   */
  readonly showWindow: () => void;
  /**
   * hideWindow: The action for hiding the Window component. The window header is displayed automatically, so this action is not normally required.
   */
  readonly hideWindow: () => void;
  /**
   * closeWindows: The action for closing all windows in the stack. This action, like `hideWindow`, does not need to be used normally.
   */
  readonly closeWindowStack: () => void;
}

export interface WindowProps {
  readonly title?: Message;
  readonly subTitle?: React.ReactElement;
  readonly onOpened?: (dispatch: WindowDispatch) => WindowDispatch;
  readonly onClosed?: (dispatch: WindowDispatch) => WindowDispatch;
}

export interface RootWindowProps {
  readonly parent: DataMapStore<HomeDataMap>;
  readonly title?: Message;
  readonly subTitle?: React.ReactElement;
  readonly mode: SlideInWindowMode;
  readonly onClose: (_: WindowDispatch) => WindowDispatch;
  readonly onOpened?: (dispatch: WindowDispatch) => WindowDispatch;
  readonly onClosed?: (dispatch: WindowDispatch) => WindowDispatch;
}

export interface SubWindowProps {
  readonly parent: DataMapStore<HomeDataMap>;
  readonly title?: Message;
  readonly subTitle?: React.ReactElement;
  readonly mode: SlideInWindowMode;
  readonly onBack: (dispatch: HeaderLeftDispatch) => HeaderLeftDispatch;
  readonly onClose: (
    _: SlideInWindowDispatch<HomeDataMap>,
  ) => SlideInWindowDispatch<HomeDataMap>;
  readonly onOpened?: (dispatch: WindowDispatch) => WindowDispatch;
  readonly onClosed?: (dispatch: WindowDispatch) => WindowDispatch;
}

/**
 * Create a controller and a props for a slide in window.
 */
export function useRootWindow(
  parent: DataMapStore<HomeDataMap>,
  props?: WindowProps,
): [RootWindowProps, WindowStackController] {
  const [mode, setMode] = useState<SlideInWindowMode>('Hide');
  const prevMode = usePrevious(mode);

  const showWindow = useCallback(() => {
    setMode('Show');
  }, []);
  const hideWindow = useCallback(() => {
    setMode('Hide');
  }, []);
  const onClose = useCallback(
    (dispatch: SlideInWindowDispatch<HomeDataMap>) => {
      hideWindow();
      return dispatch;
    },
    [hideWindow],
  );

  const backAction = useCallback(() => {
    hideWindow();
    return true;
  }, [hideWindow]);
  const context = React.useContext(Context.ref);

  useEffect(() => {
    if (prevMode.some((_) => _ !== mode)) {
      if (mode === 'Hide' || mode === 'Close') {
        context.backHandler.removeListener(backAction);
      } else if (mode === 'Show') {
        context.backHandler.addListener(backAction);
      }
    }
  }, [mode, context, backAction, prevMode]);

  return [
    { ...props, parent, mode, onClose },
    { parent, showWindow, hideWindow, closeWindowStack: hideWindow },
  ];
}

export const RootWindow: React.FC<RootWindowProps> = (props) => {
  return (
    <SlideInWindow
      {...props}
      parent={props.parent}
      direction='vertical'
      data-stack-window-type='root'
    >
      {props.children}
    </SlideInWindow>
  );
};

/**
 * Create a new controller and a props for a sub window for the existing window stack.
 */
export function useSubWindow(
  controller: WindowStackController,
  props?: WindowProps,
): [SubWindowProps, WindowStackController] {
  const [mode, setMode] = useState<SlideInWindowMode>('Hide');
  const prevMode = usePrevious(mode);

  const showWindow = useCallback(() => {
    setMode('Show');
  }, []);
  const hideWindow = useCallback(() => {
    setMode('Hide');
  }, []);
  const closeWindowStack = useCallback(() => {
    setMode('Close');
    controller.closeWindowStack();
  }, [controller]);
  const onBack = useCallback(
    (dispatch: HeaderLeftDispatch) => {
      hideWindow();
      return dispatch;
    },
    [hideWindow],
  );
  const onClose = useCallback(
    (dispatch: WindowDispatch) => {
      closeWindowStack();
      return dispatch;
    },
    [closeWindowStack],
  );

  const backAction = useCallback(() => {
    hideWindow();
    return true;
  }, [hideWindow]);

  const context = React.useContext(Context.ref);

  useEffect(() => {
    if (prevMode.some((_) => _ !== mode)) {
      if (mode === 'Hide' || mode === 'Close') {
        context.backHandler.removeListener(backAction);
      } else if (mode === 'Show') {
        context.backHandler.addListener(backAction);
      }
    }
  }, [mode, context, backAction, prevMode]);

  return [
    { ...props, parent: controller.parent, mode, onBack, onClose },
    { parent: controller.parent, showWindow, hideWindow, closeWindowStack },
  ];
}

export const SubWindow: React.FC<SubWindowProps> = (props) => {
  return (
    <SlideInWindow
      {...props}
      parent={props.parent}
      direction='horizontal'
      headerLeft={<HeaderLeft onClick={props.onBack} parent={props.parent} />}
      data-stack-window-type='sub'
    >
      {props.children}
    </SlideInWindow>
  );
};
