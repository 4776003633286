import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { None, Option, Some } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { Intl, Message, useIntl } from '../i18n/Intl';
import { FlashImage } from '../icons/FlashImage';
import { Icons } from '../icons/Icons';
import { useContextStore, useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Bound } from '../style/Bound';
import { Colors, RGBA } from '../style/Color';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Transform } from '../style/Transform';
import { toFuelMileage, toRemoteStateList, toRemoteStates } from './Helpers';
import { OpenedContainer } from './OpenedContainer';
import { FuelMileage, RemoteStates } from './Types';
import { VehicleInfoCardDataMap } from './VehicleInfoCard';

type Icon = string;

function getRemoteStateIcons(
  remoteStates: RemoteStates,
  intl: Intl,
): [Message, Message, Option<Message>, RGBA, Icon] {
  const actives = toRemoteStateList(remoteStates).filter(
    theme(
      ([name, state]) =>
        name !== 'Engine' && (state === 'Active' || state === 'ToNormal'),
      ([name, state]) =>
        name !== 'Ac' && (state === 'Active' || state === 'ToNormal'),
    ),
  );

  if (actives.length === 0) {
    return [
      intl.formatMessage({ id: 'VehicleInfo_doorLock' }),
      intl.formatMessage({ id: 'VehicleInfo_locked' }),
      None(),
      Colors.text,
      Icons.ic_lock_close_normal,
    ];
  } else {
    const [name] = actives[0];
    const nAlerts = actives.length - 1;
    const others: Option<Message> =
      nAlerts === 0
        ? None()
        : Some(
            intl.formatMessage({ id: 'Home_alertCount' }, { n: `${nAlerts}` }),
          );
    switch (name) {
      case 'DoorLock':
        return [
          intl.formatMessage({ id: 'VehicleInfo_doorLock' }),
          intl.formatMessage({ id: 'VehicleInfo_unLocked' }),
          others,
          Colors.errorText,
          Icons.ic_lock_open_active,
        ];
      case 'Light':
        return [
          intl.formatMessage({ id: 'VehicleInfo_light' }),
          intl.formatMessage({ id: 'VehicleInfo_lightOn' }),
          others,
          Colors.errorText,
          Icons.ic_light_active,
        ];
      case 'Hazard':
        return [
          intl.formatMessage({ id: 'VehicleInfo_hazardLight' }),
          intl.formatMessage({ id: 'VehicleInfo_hazardOn' }),
          others,
          Colors.errorText,
          Icons.ic_hazard_active,
        ];
      case 'Ac':
        return [
          intl.formatMessage({ id: 'VehicleInfo_airCon' }),
          intl.formatMessage({ id: 'VehicleInfo_airConOn' }),
          others,
          Colors.errorText,
          Icons.ic_ac_active,
        ];
      case 'Engine':
        return [
          intl.formatMessage({ id: 'VehicleInfo_engine' }),
          intl.formatMessage({ id: 'VehicleInfo_engineOn' }),
          others,
          Colors.errorText,
          Icons.ic_engine_active,
        ];
    }
  }
}

function RemoteStateAlertPane({
  parent,
  isDataError,
}: Readonly<{
  parent: DataMapStore<VehicleInfoCardDataMap>;
  isDataError: boolean;
}>): React.ReactElement {
  const {
    views: {
      View,
      Image,
      Text,
      vehicleInfo: { doorLockContainer: DoorLockContainer },
    },
    data: { context },
  } = useParentStore({ parent });
  const intl = useIntl();
  const remoteStates = toRemoteStates(
    context.serviceTelemetry,
    context.ongoingRemoteStates,
  );
  const [title, body, count, color, image] = getRemoteStateIcons(
    remoteStates,
    intl,
  );

  const LoadedView = (
    <DoorLockContainer>
      <Image
        alt='ic_lock'
        src={image}
        style={{
          width: Length.px(40),
          height: Length.px(40),
        }}
      />
      <View
        style={{
          fontSize: Length.px(16),
          fontWeight: 'bold',
          color,
        }}
      >
        {title.toReactNode()}:{body.toReactNode()}
        {count.unwrap(
          (n) => ` (${n.toString()})`,
          () => '',
        )}
      </View>
    </DoorLockContainer>
  );

  const LoadingView = (
    <DoorLockContainer
      style={{
        height: Length.px(40),
      }}
    />
  );

  const ErrorView = (
    <DoorLockContainer
      style={{
        height: Length.px(40),
      }}
    >
      <Text
        style={{
          fontSize: Length.px(14),
        }}
      >
        {intl.formatMessage({ id: 'Common_syncError' }).toReactNode()}
      </Text>
    </DoorLockContainer>
  );

  switch (context.contentState) {
    case 'Reload':
    case 'Updating':
    case 'Loaded':
      if (isDataError) return ErrorView;
      else return LoadedView;
    case 'Loading':
      return LoadingView;
    default:
      return LoadingView;
  }
}

function getGasGaugeIcon(fuelMileage: FuelMileage): Icon {
  const f = fuelMileage.fuelWarn;
  switch (fuelMileage.fuel) {
    case 0:
      return f ? Icons.ic_gas_gauge_1_alert : Icons.ic_gas_gauge_1;
    case 1:
      return f ? Icons.ic_gas_gauge_1_alert : Icons.ic_gas_gauge_1;
    case 2:
      return f ? Icons.ic_gas_gauge_2_alert : Icons.ic_gas_gauge_2;
    case 3:
      return f ? Icons.ic_gas_gauge_3_alert : Icons.ic_gas_gauge_3;
    case 4:
      return f ? Icons.ic_gas_gauge_4_alert : Icons.ic_gas_gauge_4;
    case 5:
      return f ? Icons.ic_gas_gauge_5_alert : Icons.ic_gas_gauge_5;
    case 6:
      return f ? Icons.ic_gas_gauge_6_alert : Icons.ic_gas_gauge_6;
    case 7:
      return f ? Icons.ic_gas_gauge_7_alert : Icons.ic_gas_gauge_7;
    default:
      return f ? Icons.ic_gas_gauge_8_alert : Icons.ic_gas_gauge_8;
  }
}

export function GasGaugePane(): React.ReactElement {
  const {
    views: { View, Image },
    data: { context },
  } = useContextStore();
  const fuelMileage = toFuelMileage(context.serviceTelemetry);
  const gasGaugeImage = getGasGaugeIcon(fuelMileage);

  return (
    <View
      style={{
        position: 'relative',
        width: Length.px(71),
        height: Length.px(12),
        overflow: 'hidden',
      }}
    >
      <Image
        alt='ic_gas_gauge_base'
        src={Icons.ic_gas_gauge_base}
        style={{
          position: 'absolute',
          left: Length.px(0),
          top: Length.px(0),
          width: Length.px(71),
          height: Length.px(12),
        }}
      />

      {Scope(() => {
        switch (context.contentState) {
          case 'Reload':
          case 'Updating':
          case 'Loaded':
            if (!context.dataErrors.serviceTelemetry && fuelMileage.fuel > 0) {
              const style: OptionalStyles = {
                position: 'absolute',
                left: Length.px(0),
                top: Length.px(0),
                width: Length.px(71),
                height: Length.px(12),
              };
              return (
                <FlashImage
                  alt='ic_gas_gauge'
                  src={gasGaugeImage}
                  style={style}
                  animate={fuelMileage.fuelWarn}
                />
              );
            } else return <></>;
          default:
            return <></>;
        }
      })}
    </View>
  );
}

export function MainView({
  parent,
  badgeBound,
  isDataError,
}: Readonly<{
  parent: DataMapStore<VehicleInfoCardDataMap>;
  badgeBound: Bound;
  isDataError: boolean;
}>): React.ReactElement {
  const {
    views: {
      Image,
      vehicleInfo: { gasContainer: GasContainer },
    },
    data,
  } = useParentStore({ parent });
  const gasGauge = <GasGaugePane />;

  const gasContainerTransform = data.vehicleInfo.gasContainer
    .getBound()
    .unwrap<OptionalStyles>(
      (b) =>
        badgeBound.right > b.left
          ? {
              transform: Transform(
                Matrix3x3.translate(badgeBound.right - b.left, 0),
              ),
            }
          : {},
      () => ({}),
    );

  return (
    <>
      <GasContainer style={gasContainerTransform}>
        <Image
          alt='ic_gas_black'
          src={Icons.ic_gas_black}
          style={{
            width: Length.px(32),
            height: Length.px(32),
          }}
        />
        {gasGauge}
      </GasContainer>

      <RemoteStateAlertPane parent={parent} isDataError={isDataError} />

      <OpenedContainer parent={parent} />
    </>
  );
}
