import React from 'react';
import { LineSpace } from '../components/LineSpace';
import { useContextStore } from '../states/ContextStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

export function Table2x2({
  leftTitle,
  leftBody,
  rightTitle,
  rightBody,
  centerMargin,
}: Readonly<{
  leftTitle: string;
  leftBody: string;
  rightTitle: string;
  rightBody: string;
  centerMargin: number;
}>): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <View
        style={{
          flexGrow: 1,
          marginRight: Length.px(centerMargin / 2),
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Colors.backgroundDark,
            borderTopLeftRadius: Length.px(8),
            borderTopRightRadius: Length.px(8),
            paddingTop: Length.px(6),
            paddingBottom: Length.px(5),
            paddingLeft: Length.px(8),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(12),
              fontWeight: 'bold',
            }}
          >
            {leftTitle}
          </Text>
        </View>

        <LineSpace height={Length.px(1)} />

        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Colors.backgroundDark,
            borderBottomLeftRadius: Length.px(8),
            borderBottomRightRadius: Length.px(8),
            paddingTop: Length.px(8),
            paddingBottom: Length.px(8),
            paddingRight: Length.px(8),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              fontWeight: 'bold',
              lineHeight: Length.px(16),
            }}
          >
            {leftBody}
          </Text>
        </View>
      </View>

      <View
        style={{
          flexGrow: 1,
          marginLeft: Length.px(centerMargin / 2),
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Colors.backgroundDark,
            borderTopLeftRadius: Length.px(8),
            borderTopRightRadius: Length.px(8),
            paddingTop: Length.px(6),
            paddingBottom: Length.px(5),
            paddingLeft: Length.px(8),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(12),
              fontWeight: 'bold',
            }}
          >
            {rightTitle}
          </Text>
        </View>

        <LineSpace height={Length.px(1)} />

        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: Colors.backgroundDark,
            borderBottomLeftRadius: Length.px(8),
            borderBottomRightRadius: Length.px(8),
            paddingTop: Length.px(8),
            paddingBottom: Length.px(8),
            paddingRight: Length.px(8),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              fontWeight: 'bold',
              lineHeight: Length.px(16),
            }}
          >
            {rightBody}
          </Text>
        </View>
      </View>
    </View>
  );
}
