export const StorageKeys = {
  authInfo: 'monaka.authInfo',
  locale: 'monaka.locale',
  mapZoom: 'monaka.map.zoom',
  carNickName: 'monaka.car.nickName',
  userNickName: 'monaka.user.nickName',
  latestVehicleLocation: 'monaka.map.latestVehicleLocation',
  deviceToken: 'monaka.deviceToken',

  mockDb: 'monaka.mock.db',
  mockDbVersion: 'monaka.mock.db.version',
  mockDeviceLocale: 'monaka.mock.device.locale',
  mockDeviceLocationPermission: 'monaka.mock.device.locationPermission',
  mockDeviceLocationEnable: 'monaka.mock.device.locationEnable',
  mockDeviceLocationError: 'monaka.mock.device.locationError',
};

type StorageKeys = typeof StorageKeys;

export type StorageKey = StorageKeys[keyof StorageKeys];
