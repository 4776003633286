import React from 'react';
import {
  EmptyVehicleEvent,
  UserVehicleEvent,
  VehicleEvent,
} from '../../apis/EventTelemetry';
import { Geofence } from '../../apis/Geofence';
import { NativeCustomEventData } from '../../apis/NativeApi';
import {
  NotificationStatus,
  defaultNotificationStatus,
} from '../../apis/Notification';
import {
  RemoteCommandAction,
  RemoteCommandName,
} from '../../apis/RemoteCommand';
import {
  ServiceTelemetryResult,
  defaultServiceTelemetry,
} from '../../apis/ServiceTelemetry';
import { Vehicle, getSelectedVehicleIndex } from '../../apis/VehicleList';
import { WifiStatus, defaultWifiStatus } from '../../apis/Wifi';
import { UIBackHandler, createBackHandler } from '../../util/BackHandler';
import { ToOptional, ToRequired, copy } from '../../util/Copyable';
import { Anomaly } from '../../util/Error';
import { Listener, Sender } from '../../util/Listener';
import { Locale, getLocaleCache, setLocaleCache } from '../../util/Locale';
import {
  CarNickName,
  UserNickName,
  getCarNickName,
  getUserNickName,
  setCarNickName,
  setUserNickName,
} from '../../util/NickName';
import { None, Option, Some } from '../../util/Option';
import { Failure } from '../../util/Result';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { Unit } from '../../util/Unit';
import { CardName } from '../components/Card';
import { State as DialogState } from '../components/Dialog';
import { OverlayState } from '../components/Overlay';
import { Shape } from '../components/Shape';
import { SlideInWindowMode } from '../components/SlideInWindow';
import { ToastData, ToastDataMap, ToastState } from '../components/Toast';
import { SpinnerState } from '../components/WindowSpinner';
import { FetchVehicleDataOptions } from '../home/FetchVehicleData';
import { HomeData, HomeDispatch, homeDispatch } from '../home/Home';
import * as Intl from '../i18n/Intl';
import { VehicleState } from '../notification/Notification';
import { ActionBinder } from './ActionBinder';
import { DataMapDispatch, WithContext } from './DataMapStore';
import { Dispatch } from './Dispatch';
import { Reducer } from './Reducer';
import { Store } from './Store';

export type Context = Readonly<{
  command: Command;
  contentState: ContentState;
  fetchVehicleDataOptions: ToRequired<FetchVehicleDataOptions>;
  fetchVehicleDataCount: number;
  contentLoadedListener: Listener<Unit>;
  contentLoadedSender: Sender<Unit>;
  dataErrors: DataErrors;
  cardInfo: CardInfo;

  locale: Locale;
  carNickName: CarNickName;
  userNickName: UserNickName;
  ssidInfo: Readonly<{
    ssid: string;
    bssid: string;
  }>;
  platform: Platform;
  nativeBackAction: NativeBackAction;
  nativeForegroundAction: boolean;
  notificationData: Option<NativeCustomEventData<'notification'>>;

  vehicles: Vehicle[];
  serviceTelemetry: ServiceTelemetryResult;
  otherServiceTelemetries: ServiceTelemetryResult[];
  userLastVehicleEvents: UserVehicleEvent[];
  vehicleEvents: VehicleEvent[];
  wifiStatus: WifiStatus;
  notificationStatus: NotificationStatus;
  notificationStatusOrig: NotificationStatus;
  notificationSelectedModel: string;
  isNotificationPushButtonDisabled: boolean;

  selectedVehicleVin: Option<string>;
  selectedVehicleEvent: Option<VehicleEvent | VehicleState | EmptyVehicleEvent>;
  vehicleEventWindowMode: SlideInWindowMode;
  ongoingRemoteStates: OngoingRemoteStates;
  lastRemoteCommands: LastRemoteCommand[];

  registerWindowMode: SlideInWindowMode;
  vehicleManagementMode: SlideInWindowMode;
  notificationVehiclesMode: SlideInWindowMode;
  notificationSettingsMode: SlideInWindowMode;
  carRegScreenMode: SlideInWindowMode;
  sharedUserRegScreenMode: SharedUserRegScreenMode;
  geofenceWindowMode: SlideInWindowMode;
  wifiWindowMode: SlideInWindowMode;

  onCardClosed: Option<() => ActionBinder<Context>>;

  overlayState: Readonly<{
    state: OverlayState;
  }>;
  windowSpinnerState: SpinnerState;

  toast: Readonly<{
    state: ToastState;
    message: Intl.Message;
    onClick: (
      _: DataMapDispatch<ToastDataMap>,
    ) => DataMapDispatch<ToastDataMap>;
  }>;

  alertDialog: Readonly<{
    state: DialogState;
    title: Intl.Message;
    message: Intl.Message;
  }>;
  confirmDialog: Readonly<{
    state: DialogState;
    title: Intl.Message;
    message: Intl.Message;
    confirmButtonTitle: Option<Intl.Message>;
    confirmButton2Title: Option<Intl.Message>;
    cancelButtonTitle: Option<Intl.Message>;
    onConfirm: () => HomeDispatch;
    onConfirm2: Option<() => HomeDispatch>;
    onCancel: () => HomeDispatch;
  }>;

  // readonly hasResizeListener: boolean;
  // readonly hasNativeEventListener: boolean;
  isNotificationReady: boolean;
  isServiceTelemetrySubscribed: boolean;

  homeContainer: Shape;

  viewPort: ViewPort;

  screenSize: ScreenSize;

  backHandler: UIBackHandler;
}>;

export type Command =
  | 'None'
  //  | 'ShowToast'
  | 'HandleOnLoaded'
  | 'HandleNotification';

export type ContentState =
  | 'Initialize'
  | 'Initializing'
  | 'SignedOut'
  | 'SigningIn'
  | 'SignedIn'
  | 'Load'
  | 'Loading'
  | 'Reload'
  | 'Updating'
  | 'NoVehicle'
  | 'Loaded';

export type ContextCardState = 'Opening' | 'Opened' | 'Closing' | 'Closed';

export type CardInfo = {
  readonly state: ContextCardState;
  readonly name: Option<CardName>;
};

export type DataErrors = {
  readonly vehicleList: boolean;
  readonly serviceTelemetry: boolean;
  readonly userVehicleEvents: boolean;
  readonly vehicleEvents: boolean;
  readonly wifiStatus: boolean;
  readonly notificationStatus: boolean;
};

export type OS = 'ios' | 'android' | '-';

export type Platform = {
  readonly appVersion: string;
  readonly buildNumber: string;
  readonly os: OS;
  readonly osVersion: number;
};

export type ViewPort = {
  readonly width: number;
  readonly height: number;
};

export type ScreenSize = {
  readonly width: number;
  readonly height: number;
  readonly marginTop: number;
  readonly marginBottom: number;
};

type NativeBackAction = 'None' | 'InCard' | 'TopLevel';

export type OngoingRemoteStates = {
  readonly [vin: string]: OngoingRemoteState | undefined;
};

export type OngoingRemoteState = {
  readonly door: boolean;
  readonly light: boolean;
  readonly hazard: boolean;
  readonly air_con: boolean;
  readonly geofence: boolean;
  readonly trackingMode: boolean;
  readonly wifi: boolean;
  readonly wifiReset: boolean;
  readonly engineProhibit: boolean;
};

export type LastRemoteCommand = {
  readonly name: RemoteCommandName;
  readonly action: RemoteCommandAction<RemoteCommandName>;
  readonly timestamp: number; // epoch time in milles
};

type SharedUserRegScreenMode = {
  readonly state: 'None' | 'Scanning' | 'InvitationCodeSent' | 'Registered';
  readonly error: boolean;
};

type ShowToastArgs =
  | {
      readonly state: 'Show';
      readonly message: Intl.Message;
      readonly onClick?: () => Dispatch<ToastData, HomeData>;
    }
  | {
      readonly state: 'Hide';
    };

type Actions = {
  readonly updateCommand: (data: Context, command: Command) => Context;
  readonly updateContentState: (
    data: Context,
    contentState: ContentState,
  ) => Context;
  readonly updateFetchVehicleDataOptions: (
    data: Context,
    options: FetchVehicleDataOptions,
  ) => Context;
  readonly updateContentStateCount: (
    data: Context,
    updater: (state: ContentState, count: number) => [ContentState, number],
  ) => Context;
  readonly updateDataErrors: (data: Context, dataErrors: DataErrors) => Context;
  readonly updateCardInfo: (
    data: Context,
    cardInfo: {
      state: ContextCardState;
      name?: CardName;
    },
  ) => Context;

  readonly updateLocale: (data: Context, locale: Locale) => Context;
  readonly updateCarNickName: (
    data: Context,
    args: {
      readonly vin: string;
      readonly nickName: string;
    },
  ) => Context;
  readonly updateUserNickName: (
    data: Context,
    args: {
      readonly globalUserId: string;
      readonly nickName: string;
    },
  ) => Context;
  readonly updateSSID: (
    data: Context,
    args: {
      readonly ssid: string;
      readonly bssid: string;
    },
  ) => Context;
  readonly updatePlatform: (data: Context, platform: Platform) => Context;
  readonly updateNativeBackAction: (
    data: Context,
    nativeBackAction: NativeBackAction,
  ) => Context;
  readonly updateNativeForegroundAction: (
    data: Context,
    nativeForegroundAction: boolean,
  ) => Context;
  readonly updateNotificationData: (
    data: Context,
    notificationData: Option<NativeCustomEventData<'notification'>>,
  ) => Context;

  readonly updateVehicles: (data: Context, payload: Vehicle[]) => Context;
  readonly updateServiceTelemetry: (
    data: Context,
    serviceTelemetry: ServiceTelemetryResult,
  ) => Context;
  readonly updateOtherServiceTelemetries: (
    data: Context,
    otherServiceTelemetries: ServiceTelemetryResult[],
  ) => Context;
  readonly updateOtherServiceTelemetry: (
    data: Context,
    args: {
      readonly vin: string;
      readonly serviceTelemetry: ServiceTelemetryResult;
    },
  ) => Context;
  readonly updateVehicleEvents: (
    data: Context,
    vehicleEvents: VehicleEvent[],
  ) => Context;
  readonly updateLastVehicleEvents: (
    data: Context,
    lastVehicleEvents: VehicleEvent[],
  ) => Context;
  readonly updateUserLastVehicleEvents: (
    data: Context,
    userLastVehicleEvents: UserVehicleEvent[],
  ) => Context;
  readonly updateWifiStatus: (
    data: Context,
    wifiStatus: ToOptional<WifiStatus>,
  ) => Context;
  readonly updateNotificationStatus: (
    data: Context,
    notificationStatus: ToOptional<NotificationStatus>,
  ) => Context;
  readonly updateNotificationStatusOrig: (
    data: Context,
    notificationStatus: ToOptional<NotificationStatus>,
  ) => Context;
  readonly updateNotificationSelectedModel: (
    data: Context,
    notificationSelectedModel: string,
  ) => Context;
  readonly updateIsNotificationPushButtonDisabled: (
    data: Context,
    isNotificationPushButtonDisabled: boolean,
  ) => Context;
  readonly updateGeofence: (
    data: Context,
    geofence: Option<Geofence>,
  ) => Context;

  readonly updateSelectedVehicleVin: (
    data: Context,
    selectedVehicleVin: Option<string>,
  ) => Context;
  readonly updateSelectedVehicleEvent: (
    data: Context,
    args: {
      selectedVehicleEvent?: VehicleEvent | VehicleState | EmptyVehicleEvent;
      vehicleEventWindowMode: SlideInWindowMode;
    },
  ) => Context;
  // readonly updateOngoingRemoteStates: (
  //   data: Context,
  //   ongoingRemoteStates: ToOptional<OngoingRemoteStates>,
  // ) => Context;
  readonly updateLastRemoteCommands: (
    data: Context,
    lastRemoteCommands: LastRemoteCommand[],
  ) => Context;

  readonly updateRegisterWindowMode: (
    data: Context,
    registerWindowMode: SlideInWindowMode,
  ) => Context;
  readonly updateVehicleManagementMode: (
    data: Context,
    vehicleManagementMode: SlideInWindowMode,
  ) => Context;
  readonly updateNotificationVehiclesMode: (
    data: Context,
    notificationVehiclesMode: SlideInWindowMode,
  ) => Context;
  readonly updateNotificationSettingsMode: (
    data: Context,
    notificationSettingsMode: SlideInWindowMode,
  ) => Context;
  readonly updateCarRegScreenMode: (
    data: Context,
    mode: SlideInWindowMode,
  ) => Context;
  readonly updateSharedUserRegScreenMode: (
    data: Context,
    mode: SharedUserRegScreenMode,
  ) => Context;
  readonly updateGeofenceWindowMode: (
    data: Context,
    geofenceWindowMode: SlideInWindowMode,
  ) => Context;
  readonly updateWifiWindowMode: (
    data: Context,
    wifiWindowMode: SlideInWindowMode,
  ) => Context;

  readonly updateOnCardClosed: (
    data: Context,
    onCardClosed: Option<() => ActionBinder<Context>>,
  ) => Context;

  readonly updateOverlayState: (
    data: Context,
    overlayState: {
      readonly state: OverlayState;
      readonly opacity?: number;
    },
  ) => Context;
  readonly updateWindowSpinnerState: (
    data: Context,
    windowSpinnerState: SpinnerState,
  ) => Context;

  readonly showToast: (data: Context, args: ShowToastArgs) => Context;

  readonly updateAlertDialog: (
    data: Context,
    args: {
      readonly state: DialogState;
      readonly title?: Intl.Message;
      readonly message?: Intl.Message;
    },
  ) => Context;

  readonly updateConfirmDialogState: (
    data: Context,
    state: DialogState,
  ) => Context;
  readonly updateConfirmDialog: (
    data: Context,
    args: {
      readonly state: DialogState;
      readonly title: Intl.Message;
      readonly message: Intl.Message;
      readonly confirmButtonTitle?: Intl.Message;
      readonly confirmButton2Title?: Intl.Message;
      readonly cancelButtonTitle?: Intl.Message;
      readonly onConfirm?: () => HomeDispatch;
      readonly onConfirm2?: () => HomeDispatch;
      readonly onCancel?: () => HomeDispatch;
    },
  ) => Context;

  // readonly updateResizeListener: (data: Context, hasResizeListener: boolean) => Context;
  // readonly updateNativeEventListener: (data: Context, hasNativeEventListener: boolean) => Context;
  readonly updateNotificationReady: (
    data: Context,
    isNotificationReady: boolean,
  ) => Context;
  readonly updateServiceTelemetrySubscribed: (
    data: Context,
    isServiceTelemetrySubscribed: boolean,
  ) => Context;

  readonly updateViewPort: (data: Context, viewPort: ViewPort) => Context;

  readonly updateScreenSize: (data: Context, screenSize: ScreenSize) => Context;
};

interface Statics extends Reducer<Context, Actions> {
  readonly ref: React.Context<Context>;
  readonly store: Option<Store<Context>>;
  readonly setStore: (store: Store<Context>) => void;
  readonly handleCommand: <DataMap>(
    data: Context,
    dispatch: DataMapDispatch<WithContext<DataMap>>,
    f: (command: Command) => DataMapDispatch<WithContext<DataMap>>,
  ) => DataMapDispatch<WithContext<DataMap>>;
}

interface Factory {
  (): Context;
}

export const defaultFetchVehicleDataOptions: ToRequired<FetchVehicleDataOptions> =
  {
    disableUserLastVehiclesEventList: false,
    disableVehicleEventList: false,
    disableServiceTelemetry: false,
    disableOtherServiceTelemetries: false,
    disableSendTel: false,
    disableOtherSendTels: false,
    disableVehicleList: false,
    disableWifiStatus: false,
    disableNotificationStatus: false,
    enableGeofence: true,
    enableLastVehiclesEventList: true,
  };

export const defaultOngoingState: OngoingRemoteState = {
  door: false,
  light: false,
  hazard: false,
  air_con: false,
  geofence: false,
  trackingMode: false,
  wifi: false,
  wifiReset: false,
  engineProhibit: false,
};

const factory: Factory = () => {
  const [contentLoadedListener, contentLoadedSender] =
    Listener.withSender<Unit>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (contentLoadedListener as any).__name__ = 'contentLoadedListener';
  return {
    command: 'None',
    contentState: 'Initialize',
    fetchVehicleDataOptions: defaultFetchVehicleDataOptions,
    fetchVehicleDataCount: 0,
    contentLoadedListener,
    contentLoadedSender,
    dataErrors: {
      vehicleList: false,
      serviceTelemetry: false,
      userVehicleEvents: false,
      vehicleEvents: false,
      wifiStatus: false,
      notificationStatus: false,
    },
    cardInfo: { state: 'Closed', name: None() },

    locale: getLocaleCache().getOrElse(() =>
      theme(
        {
          lang: 'ja',
          region: 'JP',
        },
        {
          lang: 'en',
          region: 'MY',
        },
      ),
    ),
    carNickName: getCarNickName(),
    userNickName: getUserNickName(),
    ssidInfo: {
      ssid: '',
      bssid: '',
    },
    platform: {
      appVersion: '-',
      buildNumber: '-',
      os: '-',
      osVersion: 0,
    },
    nativeBackAction: 'None',
    nativeForegroundAction: false,
    notificationData: None(),

    vehicles: [],
    vehicleEvents: [],
    userLastVehicleEvents: [],
    serviceTelemetry: defaultServiceTelemetry,
    otherServiceTelemetries: [],
    wifiStatus: defaultWifiStatus(''),
    notificationStatus: defaultNotificationStatus(''),
    notificationStatusOrig: defaultNotificationStatus(''),
    notificationSelectedModel: 'foobar',
    isNotificationPushButtonDisabled: true,

    selectedVehicleVin: None(),
    selectedVehicleEvent: None(),
    vehicleEventWindowMode: 'Hide',
    ongoingRemoteStates: {},
    lastRemoteCommands: [],

    registerWindowMode: 'Hide',
    vehicleManagementMode: 'Hide',
    notificationVehiclesMode: 'Hide',
    notificationSettingsMode: 'Hide',
    carRegScreenMode: 'Hide',
    sharedUserRegScreenMode: { state: 'None', error: false },
    geofenceWindowMode: 'Hide',
    wifiWindowMode: 'Hide',

    onCardClosed: None(),
    overlayState: { state: 'Hide', opacity: 0.08 },
    windowSpinnerState: 'Hide',

    toast: {
      state: 'Hide',
      message: Intl.Message([]),
      onClick: (_: DataMapDispatch<ToastDataMap>) => _,
    },

    alertDialog: {
      state: 'Hide',
      title: Intl.Message('Dialog Title'),
      message: Intl.Message('Dialog Message'),
    },

    confirmDialog: {
      state: 'Hide',
      title: Intl.Message('Dialog Title'),
      message: Intl.Message('Dialog Message'),
      confirmButtonTitle: None(),
      confirmButton2Title: None(),
      cancelButtonTitle: None(),
      onConfirm: () => homeDispatch,
      onConfirm2: Option(() => homeDispatch),
      onCancel: () => homeDispatch,
    },

    isNotificationReady: false,
    isServiceTelemetrySubscribed: false,

    homeContainer: Shape({}),

    viewPort: {
      width: window.innerWidth,
      height: window.innerHeight,
    },

    screenSize: {
      width: window.innerWidth,
      height: window.innerHeight,
      marginTop: 0,
      marginBottom: 0,
    },

    backHandler: createBackHandler(),
  };
};

const statics: Statics = {
  ref: React.createContext(factory()),

  store: None(),

  initialize: (data: Context) => data,

  actions: {
    updateCommand: (data: Context, command: Command) =>
      copy(data, {
        command,
      }),

    updateContentState: (data: Context, contentState: ContentState) => {
      return copy(data, { contentState });
    },

    updateFetchVehicleDataOptions: (
      data: Context,
      options: FetchVehicleDataOptions,
    ) =>
      copy(data, {
        fetchVehicleDataOptions: copy(defaultFetchVehicleDataOptions, options),
      }),

    updateContentStateCount: (
      data: Context,
      updater: (state: ContentState, count: number) => [ContentState, number],
    ) => {
      const [contentState, fetchVehicleDataCount] = updater(
        data.contentState,
        data.fetchVehicleDataCount,
      );
      return copy(data, { contentState, fetchVehicleDataCount });
    },

    updateDataErrors: (data: Context, dataErrors: DataErrors) =>
      copy(data, {
        dataErrors,
      }),

    updateCardInfo: (
      data: Context,
      cardInfo: {
        state: ContextCardState;
        name?: CardName;
      },
    ) =>
      copy(data, {
        cardInfo: {
          state: cardInfo.state,
          name: cardInfo.name === undefined ? undefined : Option(cardInfo.name),
        },
      }),

    updateLocale: (data: Context, locale: Locale) => {
      setLocaleCache(locale);
      return {
        ...data,
        locale,
      };
    },

    updateCarNickName: (data: Context, { vin, nickName }) => {
      const carNickName = { ...data.carNickName };
      if (nickName) {
        carNickName[vin] = nickName;
      } else {
        delete carNickName[vin];
      }
      setCarNickName(carNickName);
      return {
        ...data,
        carNickName,
      };
    },

    updateUserNickName: (data: Context, { globalUserId, nickName }) => {
      const userNickName = { ...data.userNickName };
      if (nickName) {
        userNickName[globalUserId] = nickName;
      } else {
        delete userNickName[globalUserId];
      }
      setUserNickName(userNickName);
      return {
        ...data,
        userNickName,
      };
    },

    updateSSID: (data: Context, { ssid, bssid }) =>
      copy(data, {
        ssidInfo: {
          ssid,
          bssid,
        },
      }),

    updatePlatform: (data: Context, platform: Platform) =>
      copy(data, {
        platform,
      }),

    updateNativeBackAction: (
      data: Context,
      nativeBackAction: NativeBackAction,
    ) =>
      copy(data, {
        nativeBackAction,
      }),

    updateNativeForegroundAction: (
      data: Context,
      nativeForegroundAction: boolean, // flipped when 'foreground' message is received.
    ) =>
      copy(data, {
        nativeForegroundAction,
      }),

    updateNotificationData: (
      data: Context,
      notificationData: Option<NativeCustomEventData<'notification'>>,
    ) =>
      copy(data, {
        notificationData,
      }),

    updateVehicles: (data: Context, payload: Vehicle[]) =>
      copy(data, {
        vehicles: payload,
      }),

    updateServiceTelemetry: (
      data: Context,
      serviceTelemetry: ServiceTelemetryResult,
    ) =>
      copy(data, {
        serviceTelemetry: copy<ServiceTelemetryResult>(
          data.serviceTelemetry,
          serviceTelemetry,
        ),
      }),

    updateOtherServiceTelemetries: (
      data: Context,
      otherServiceTelemetries: ServiceTelemetryResult[],
    ) =>
      copy(data, {
        otherServiceTelemetries,
      }),

    updateOtherServiceTelemetry: (data: Context, { vin, serviceTelemetry }) =>
      copy(data, {
        otherServiceTelemetries: data.otherServiceTelemetries.reduce<
          ServiceTelemetryResult[]
        >(
          (xs, x) =>
            vin === x.vin ? xs.concat([serviceTelemetry]) : xs.concat([x]),
          [],
        ),
      }),
    // updateOtherServiceTelemetry: (data: Context, otherServiceTelemetries: ServiceTelemetryResult[]) => copy(data, {
    //   otherServiceTelemetries,
    // }),

    updateVehicleEvents: (data: Context, vehicleEvents: VehicleEvent[]) =>
      copy(data, {
        vehicleEvents,
      }),

    updateLastVehicleEvents: (
      data: Context,
      lastVehicleEvents: VehicleEvent[],
    ) =>
      copy(data, {
        userLastVehicleEvents: data.userLastVehicleEvents.map((a) => {
          if (
            lastVehicleEvents.length > 0 &&
            lastVehicleEvents[0].vin === a.vin
          ) {
            return {
              vin: a.vin,
              events: lastVehicleEvents,
            };
          } else {
            return a;
          }
        }),
      }),

    updateUserLastVehicleEvents: (
      data: Context,
      userLastVehicleEvents: UserVehicleEvent[],
    ) =>
      copy(data, {
        userLastVehicleEvents,
      }),

    updateWifiStatus: (data: Context, wifiStatus: ToOptional<WifiStatus>) =>
      copy(data, {
        wifiStatus: copy(data.wifiStatus, wifiStatus),
      }),

    updateNotificationStatus: (
      data: Context,
      notificationStatus: ToOptional<NotificationStatus>,
    ) =>
      copy(data, {
        notificationStatus: copy(data.notificationStatus, notificationStatus),
      }),
    updateNotificationStatusOrig: (
      data: Context,
      notificationStatus: ToOptional<NotificationStatus>,
    ) =>
      copy(data, {
        notificationStatusOrig: copy(
          data.notificationStatusOrig,
          notificationStatus,
        ),
      }),

    updateNotificationSelectedModel: (
      data: Context,
      notificationSelectedModel: string,
    ) =>
      copy(data, {
        notificationSelectedModel,
      }),

    updateIsNotificationPushButtonDisabled: (
      data: Context,
      isNotificationPushButtonDisabled: boolean,
    ) =>
      copy(data, {
        isNotificationPushButtonDisabled,
      }),

    updateGeofence: (data: Context, geofence: Option<Geofence>) =>
      copy(data, {
        vehicles: getSelectedVehicleIndex(data)
          .map((idx) => {
            data.vehicles[idx] = copy(data.vehicles[idx], {
              geofence,
              timestamp: new Date(Date.now()),
            });
            return data.vehicles;
          })
          .getOrElse(() => {
            return data.vehicles;
          }),
      }),

    updateSelectedVehicleVin: (
      data: Context,
      selectedVehicleVin: Option<string>,
    ) =>
      copy(data, {
        selectedVehicleVin,
        serviceTelemetry: data.selectedVehicleVin.some((a) =>
          selectedVehicleVin.some((b) => a === b),
        )
          ? undefined // vin unchanged.
          : selectedVehicleVin
              .flatMap((vin) =>
                Option(data.otherServiceTelemetries.find((_) => _.vin === vin)),
              )
              .getOrElse(() => {
                return {
                  vin: selectedVehicleVin.getOrElse(() => ''),
                  data: Failure(Anomaly.of('No ServiceTelemetry')),
                };
              }),
      }),

    updateSelectedVehicleEvent: (
      data: Context,
      args: {
        selectedVehicleEvent?: VehicleEvent | VehicleState | EmptyVehicleEvent;
        vehicleEventWindowMode: SlideInWindowMode;
      },
    ) =>
      copy(data, {
        selectedVehicleEvent:
          args.selectedVehicleEvent === undefined
            ? None()
            : Some(args.selectedVehicleEvent),
        vehicleEventWindowMode:
          args.vehicleEventWindowMode === 'Show'
            ? Scope(() => {
                switch (args.selectedVehicleEvent?.kind) {
                  case 'VehicleEvent':
                  case 'EmptyVehicleEvent':
                    return 'Show';
                  case 'StateInfo':
                  case undefined:
                    return undefined;
                }
              })
            : args.vehicleEventWindowMode,
      }),

    // updateOngoingRemoteStates: (
    //   data: Context,
    //   ongoingRemoteStates: ToOptional<OngoingRemoteStates>,
    // ) =>
    //   copy(data, {
    //     ongoingRemoteStates: copy(
    //       data.ongoingRemoteStates,
    //       ongoingRemoteStates,
    //     ),
    //   }),

    updateLastRemoteCommands: (
      data: Context,
      lastRemoteCommands: LastRemoteCommand[],
    ) =>
      copy(data, {
        lastRemoteCommands,
      }),

    updateRegisterWindowMode: (
      data: Context,
      registerWindowMode: SlideInWindowMode,
    ) =>
      copy(data, {
        registerWindowMode,
      }),

    updateVehicleManagementMode: (
      data: Context,
      vehicleManagementMode: SlideInWindowMode,
    ) =>
      copy(data, {
        vehicleManagementMode,
      }),

    updateNotificationVehiclesMode: (
      data: Context,
      notificationVehiclesMode: SlideInWindowMode,
    ) =>
      copy(data, {
        notificationVehiclesMode,
      }),

    updateNotificationSettingsMode: (
      data: Context,
      notificationSettingsMode: SlideInWindowMode,
    ) =>
      copy(data, {
        notificationSettingsMode,
      }),

    updateCarRegScreenMode: (data: Context, mode: SlideInWindowMode) =>
      copy(data, {
        carRegScreenMode: mode,
      }),

    updateSharedUserRegScreenMode: (
      data: Context,
      mode: SharedUserRegScreenMode,
    ) =>
      copy(data, {
        sharedUserRegScreenMode: mode,
      }),

    updateGeofenceWindowMode: (
      data: Context,
      geofenceWindowMode: SlideInWindowMode,
    ) =>
      copy(data, {
        geofenceWindowMode,
      }),

    updateWifiWindowMode: (data: Context, wifiWindowMode: SlideInWindowMode) =>
      copy(data, {
        wifiWindowMode,
      }),

    updateOnCardClosed: (
      data: Context,
      onCardClosed: Option<() => ActionBinder<Context>>,
    ) =>
      copy(data, {
        onCardClosed,
      }),

    updateOverlayState: (
      data: Context,
      args: {
        readonly state: OverlayState;
        readonly opacity?: number;
      },
    ) =>
      copy(data, {
        overlayState: {
          state: args.state,
        },
      }),

    updateWindowSpinnerState: (
      data: Context,
      windowSpinnerState: SpinnerState,
    ) =>
      copy(data, {
        windowSpinnerState,
      }),

    showToast: (data: Context, args: ShowToastArgs) =>
      copy(data, {
        toast:
          args.state === 'Show'
            ? {
                state: 'Show',
                message: args.message,
                onClick: args.onClick ?? (() => Dispatch()),
              }
            : {
                state: 'Hide',
              },
      }),

    updateAlertDialog: (data: Context, args) =>
      copy(data, {
        alertDialog: {
          state: args.state,
          title: args.title ?? data.alertDialog.title,
          message: args.message ?? data.alertDialog.message,
        },
      }),

    updateConfirmDialogState: (data: Context, state: DialogState) =>
      copy(data, {
        confirmDialog: { state },
      }),

    updateConfirmDialog: (data: Context, args) =>
      copy(data, {
        confirmDialog: {
          state: args.state,
          title: args.title,
          message: args.message,
          confirmButtonTitle: Option(args.confirmButtonTitle),
          confirmButton2Title: Option(args.confirmButton2Title),
          cancelButtonTitle: Option(args.cancelButtonTitle),
          onConfirm: args.onConfirm ?? data.confirmDialog.onConfirm,
          onConfirm2: Option(args.onConfirm2),
          onCancel: args.onCancel ?? data.confirmDialog.onCancel,
        },
      }),

    updateNotificationReady: (data: Context, isNotificationReady: boolean) =>
      copy(data, {
        isNotificationReady,
      }),

    updateServiceTelemetrySubscribed: (
      data: Context,
      isServiceTelemetrySubscribed: boolean,
    ) =>
      copy(data, {
        isServiceTelemetrySubscribed,
      }),

    updateViewPort: (data: Context, viewPort: ViewPort) =>
      copy(data, {
        viewPort,
      }),

    updateScreenSize: (data: Context, screenSize: ScreenSize) =>
      copy(data, { screenSize }),
  },

  setStore: (store: Store<Context>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (Context.store as any) = Some(store);
  },

  handleCommand: <DataMap>(
    data: Context,
    dispatch: DataMapDispatch<WithContext<DataMap>>,
    f: (command: Command) => DataMapDispatch<WithContext<DataMap>>,
  ) => {
    if (data.command !== 'None') {
      return f(data.command).context.bind((newData) => {
        return Context.actions.updateCommand(
          newData,
          newData.command !== data.command ? newData.command : 'None',
        );
      }, Unit);
    } else {
      return dispatch;
    }
  },
};

export const Context: Factory & Statics = Object.assign(factory, statics);
