import React from 'react';
import { config } from '../../Config';
import { Scope } from '../../util/Scope';
import { getLastUpdate, shouldShowNetworkErrorOnHome } from '../home/Helpers';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import { IcReload } from '../icons/IcReload';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

export function Updater(props: Props): React.ReactElement {
  const {
    views: { View },
    data: { context },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();
  const contentState = context.contentState;
  const isSpinning = contentState === 'Loading' || contentState === 'Updating';
  const date: Date = getLastUpdate(context);

  const LoadedView = (
    <View>
      {intl.formatMessage({ id: 'Home_lastUpdated' }).toReactNode()}{' '}
      {intl.formatDate(date, {
        month: 'numeric',
        day: 'numeric',
      })}{' '}
      {intl.formatTime(date)}
    </View>
  );
  const SpinnerView = (
    <View>{intl.formatMessage({ id: 'Common_onUpdating' }).toReactNode()}</View>
  );
  const ErrorView = (
    <View
      style={{
        fontSize: Length.px(14),
        color: Colors.textMoreLight,
      }}
    >
      {intl.formatMessage({ id: 'Common_networkError' }).toReactNode()}
    </View>
  );

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.background,
      }}
    >
      <View
        style={{
          width: Length.px(48),
        }}
      />

      <View
        style={{
          color: Colors.textMoreLight,
          fontSize: Length.px(14),
        }}
      >
        {Scope(() => {
          switch (context.contentState) {
            case 'Loaded':
              if (shouldShowNetworkErrorOnHome(context)) return ErrorView;
              else return LoadedView;
            default:
              return SpinnerView;
          }
        })}
      </View>

      <View
        style={{
          height: Length.px(48),
          WebkitTapHighlightColor: 'transparent',
        }}
        onClick={(evt) =>
          dispatch
            .effect(() => evt.stopPropagation())
            .pipe((_) =>
              context.contentState === 'Loaded'
                ? _.context
                    .bind(Context.actions.updateContentState, 'Reload')
                    .context.bind(
                      Context.actions.updateFetchVehicleDataOptions,
                      config.fetchDataOptions.onReloadHome,
                    )
                : _,
            )
        }
      >
        {isSpinning ? (
          <IcReload
            style={{
              width: Length.px(48),
              height: Length.px(48),
            }}
            animate
          />
        ) : (
          <IcButton
            image={Icons.ic_reload}
            imageWidth={Length.px(48)}
            imageHeight={Length.px(48)}
          />
        )}
      </View>
    </View>
  );
}
