import { NotificationSettings } from '@auto/monaka-client/dist/20';
import React from 'react';
import { println } from '../../apis/NativeApi';
import { setNotificationSettings } from '../../apis/Notification';
import { Vehicle } from '../../apis/VehicleList';
import { Unit } from '../../util/Unit';
import { Button } from '../components/Button';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowMode,
} from '../components/SlideInWindow';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { HomeDataMap } from '../home/Home';
import { Intl, Message, useIntl } from '../i18n/Intl';
import { MessageIds } from '../i18n/Types';
import { ActionBinder } from '../states/ActionBinder';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mode: SlideInWindowMode;
  cars: Vehicle[];
}>;

const CategoryLabelDic: { [key: string]: MessageIds } = {
  vehicleWarning: 'Register_notifyLabel1',
  ukkari: 'Register_notifyLabel2',
  remoteControl: 'Register_notifyLabel3',
  geofence: 'Register_notifyLabel4',
  engineStart: 'Register_notifyLabel5',
  serviceReminder: 'Register_notifyLabel6',
  wifi: 'Register_notifyLabel7',
  general: 'Register_notifyLabel8',
  accident: 'Register_notifyLabel9',
  theftAlert: 'Register_notifyLabel10',
  carSharing: 'Register_notifyLabel11',
};

function ListItem({
  parent,
  setting,
}: {
  parent: DataMapStore<HomeDataMap>;
  setting: NotificationSettings;
}): React.ReactElement {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent });
  const intl = useIntl();

  return (
    <View>
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: Length.px(16),
          height: Length.px(64),
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
        onClick={() =>
          dispatch.context.bind(
            Context.actions.updateNotificationSettingsMode,
            'Hide',
          )
        }
      >
        <View
          style={{
            flexGrow: 1,
            marginRight: Length.px(16),
            paddingTop: Length.px(0),
            paddingBottom: Length.px(0),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(16),
            }}
          >
            {intl
              .formatMessage({ id: CategoryLabelDic[setting.categoryKey] })
              .toReactNode()}
          </Text>
        </View>
        <View
          style={{
            flexGrow: 0,
            display: 'flex',
            alignItems: 'center',
            paddingRight: Length.px(8),
          }}
        >
          <NotificationSettingToggleSwitch setting={setting} parent={parent} />
        </View>
      </View>
    </View>
  );
}

export function NotificationWindow(props: Props): React.ReactElement {
  const {
    views: { View },
    data: { context },
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();
  const errorDialog = errorAction(intl);

  return (
    <>
      <SlideInWindow
        parent={props.parent}
        mode={props.mode}
        title={Message(context.notificationSelectedModel)}
        headerLeft={
          <HeaderLeft
            onClick={(dispatch) =>
              dispatch.context.bind(
                Context.actions.updateNotificationSettingsMode,
                'Hide',
              )
            }
            parent={props.parent}
          />
        }
        onClose={(dispatch) =>
          dispatch
            .context(Context.actions.updateRegisterWindowMode, 'Hide')
            .context(Context.actions.updateNotificationVehiclesMode, 'Hide')
            .context(Context.actions.updateNotificationSettingsMode, 'Hide')
        }
      >
        {context.notificationStatus.settings.map((notificationSetting, idx) =>
          notificationSetting.displayFlg ? (
            <ListItem
              parent={props.parent}
              setting={notificationSetting}
              key={idx}
            />
          ) : null,
        )}
        <View
          style={{
            paddingBottom: Length.px(16),
            paddingLeft: Length.px(16),
            paddingRight: Length.px(16),
            paddingTop: Length.px(23),
          }}
        >
          <Button
            parent={props.parent}
            title={intl.formatMessage({ id: 'Register_save' })}
            disabled={context.isNotificationPushButtonDisabled}
            onClick={(dispatch) =>
              dispatch.context
                .bind(Context.actions.updateOverlayState, {
                  state: 'Show',
                  opacity: 0.4,
                })
                .context.bind(Context.actions.updateWindowSpinnerState, 'Show')
                .asyncAll(
                  setNotificationSettings(
                    context.notificationStatus,
                    context.notificationStatusOrig,
                  )
                    .then(() => {
                      return dispatch.context
                        .bind(Context.actions.updateOverlayState, {
                          state: 'Hide',
                        })
                        .context.bind(
                          Context.actions.updateWindowSpinnerState,
                          'Hide',
                        )
                        .context.bind(
                          Context.actions.updateNotificationSettingsMode,
                          'Hide',
                        );
                    })
                    .catch((err) =>
                      dispatch.context
                        .bind(
                          Context.actions.updateNotificationSettingsMode,
                          'Hide',
                        )
                        .effect(() => println(err))
                        .context(errorDialog, Unit),
                    ),
                )
            }
          />
        </View>
      </SlideInWindow>
    </>
  );
}

function NotificationSettingToggleSwitch({
  parent,
  setting,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  setting: NotificationSettings;
}>): React.ReactElement {
  const { dispatch } = useParentStore({ parent });
  const context = React.useContext(Context.ref);

  return (
    <ToggleSwitch
      isEnabled={setting.pushEnabled}
      isSpinning={false}
      parent={parent}
      onChange={(isEnabled) => {
        const newStatus = {
          vin: context.notificationStatus.vin,
          settings: context.notificationStatus.settings.map((s) => ({
            ...s,
            pushEnabled:
              s.categoryKey === setting.categoryKey ? isEnabled : s.pushEnabled,
          })),
        };
        return dispatch.context
          .bind(Context.actions.updateIsNotificationPushButtonDisabled, false)
          .context.bind(Context.actions.updateNotificationStatus, newStatus);
      }}
    />
  );
}

function errorAction(intl: Intl): ActionBinder<Context> {
  return Action.bind(Context.actions.updateAlertDialog, {
    state: 'Show',
    title: intl.formatMessage({ id: 'Common_networkError' }),
    message: intl.formatMessage({ id: 'Common_networkErrorMessage' }),
  })
    .bind(Context.actions.updateOverlayState, { state: 'Hide' })
    .bind(Context.actions.updateWindowSpinnerState, 'Hide');
}
