import {
  UserVehicleEvent,
  VehicleEvent,
  getLastVehicleEventList,
  getUserLastVehiclesEventList,
  getVehicleEventList,
} from '../../apis/EventTelemetry';
import { Geofence, getGeofence } from '../../apis/Geofence';
import { println } from '../../apis/NativeApi';
import {
  NotificationStatus,
  defaultNotificationStatus,
  getNotificationStatus,
} from '../../apis/Notification';
import {
  sendRemoteCommand,
  subscribeRemoteCommand,
  unsubscribeRemoteCommand,
} from '../../apis/RemoteCommand';
import {
  ServiceTelemetryResult,
  getServiceTelemetry,
} from '../../apis/ServiceTelemetry';
import { Vehicle, getVehicleList } from '../../apis/VehicleList';
import { WifiStatus, errorWifiStatus, getWifiStatus } from '../../apis/Wifi';
import { ToOptional, copy } from '../../util/Copyable';
import { None, Option, Some } from '../../util/Option';
import { Failure, Result, Success } from '../../util/Result';
import { Unit } from '../../util/Unit';
import { EventId } from '../i18n/Types';
import { ActionBinder } from '../states/ActionBinder';
import { Context, DataErrors } from '../states/Context';
import { Action } from '../states/Reducer';
import { closeRegistrationWindows } from './Helpers';
import { HomeDispatch, homeDispatch } from './Home';

type FetchVehicleDataForVinOptions = {
  readonly disableUserLastVehiclesEventList?: boolean;
  readonly disableVehicleEventList?: boolean;
  readonly disableServiceTelemetry?: boolean;
  readonly disableOtherServiceTelemetries?: boolean;
  readonly disableWifiStatus?: boolean;
  readonly disableNotificationStatus?: boolean;
  readonly disableSendTel?: boolean;
  readonly disableOtherSendTels?: boolean;
  readonly enableGeofence?: boolean;
  readonly enableLastVehiclesEventList?: boolean;
};

type FetchVehicleListOptions = {
  readonly disableVehicleList?: boolean;
};

export type FetchVehicleDataOptions = FetchVehicleDataForVinOptions &
  FetchVehicleListOptions;

type VehicleDataValues = [
  Promise<Result<UserVehicleEvent[]>>,
  Promise<Result<VehicleEvent[]>>,
  Promise<ServiceTelemetryResult>,
  Promise<Result<WifiStatus>>,
  Promise<Result<NotificationStatus>>,
  Promise<Result<Option<Geofence>>>,
  Promise<Result<VehicleEvent[]>>,
  Promise<ServiceTelemetryResult>,
];

export type VehicleDataResult = {
  readonly userLastVehicleEvents: Result<UserVehicleEvent[]>;
  readonly vehicleEvents: Result<VehicleEvent[]>;
  readonly serviceTelemetry: ServiceTelemetryResult;
  readonly wifiStatus: Result<WifiStatus>;
  readonly notificationStatus: Result<NotificationStatus>;
  readonly geofence: Result<Option<Geofence>>;
  readonly lastVehicleEvents: Result<VehicleEvent[]>;
  readonly otherServiceTelemetries: ServiceTelemetryResult[];
};

function LoadedAction(context: Context): HomeDispatch {
  return homeDispatch
    .context(Context.actions.updateContentStateCount, (state, count) => {
      const newCount = count - 1;
      if (newCount === 0) return ['Loaded', newCount];
      else return [state, newCount];
    })
    .send(context.contentLoadedSender, Unit)
    .context(Context.actions.updateCommand, 'HandleOnLoaded')
    .context(Context.actions.updateOverlayState, { state: 'Hide' })
    .context(Context.actions.updateWindowSpinnerState, 'Hide');
}

function fetchVehicleList({
  context,
  options,
}: Readonly<{
  context: Context;
  options?: FetchVehicleListOptions;
}>): Promise<[Option<[Vehicle[], Vehicle]>, HomeDispatch]> {
  const dispatch = homeDispatch;
  return (
    options?.disableVehicleList === true
      ? Promise.resolve(context.vehicles)
      : getVehicleList()
  ).then((cars) => {
    if (cars.length === 0) {
      return [
        None<[Vehicle[], Vehicle]>(),
        dispatch
          .context(Context.actions.updateContentStateCount, (state, count) => {
            const newCount = count - 1;
            if (newCount === 0) return ['NoVehicle', newCount];
            else return [state, newCount];
          })
          .context(Context.actions.updateVehicles, [])
          .context(Context.actions.updateSelectedVehicleVin, None())
          .context(Context.actions.updateOverlayState, { state: 'Hide' })
          .context(Context.actions.updateWindowSpinnerState, 'Hide')
          .context(closeRegistrationWindows()),
      ];
    } else {
      const vehicle = context.selectedVehicleVin
        .flatMap((vin) => Option(cars.find((c) => c.vin === vin)))
        .getOrElse(() => cars[0]);
      return [
        Some<[Vehicle[], Vehicle]>([cars, vehicle]),
        dispatch
          .context(Context.actions.updateVehicles, cars)
          .context(Context.actions.updateSelectedVehicleVin, Some(vehicle.vin)),
      ];
    }
  });
}

export function getServiceTelemetryWithSendTel(
  vehicle: Vehicle,
): Promise<ServiceTelemetryResult> {
  return subscribeRemoteCommand('sendTel', vehicle.dcmId, vehicle.vin)
    .then((listener) =>
      sendRemoteCommand({
        vin: vehicle.vin,
        dcmId: vehicle.dcmId,
        name: 'sendTel',
        type: 'vehicle',
        action: '-',
        params: {},
      }).then(() =>
        listener
          .toPromise()
          .then(() => unsubscribeRemoteCommand('sendTel', vehicle.dcmId)),
      ),
    )
    .catch((err) => println(err))
    .then(() => getServiceTelemetry(vehicle.vin));
}

function wrapResult<A>(p: Promise<A>): Promise<Result<A>> {
  return p.then((_) => Success(_)).catch((err) => Failure(err));
}

function getOtherServiceTelemetries({
  cars,
  vin,
  disableOtherSendTels,
}: Readonly<{
  cars: Vehicle[];
  vin: string;
  disableOtherSendTels?: boolean;
}>): Promise<ServiceTelemetryResult>[] {
  return cars.flatMap((v) =>
    v.vin === vin
      ? []
      : [
          disableOtherSendTels === true
            ? getServiceTelemetry(v.vin)
            : getServiceTelemetryWithSendTel(v),
        ],
  );
}

export function getVehicleDataForVin({
  vehicle,
  context,
  cars,
  options,
}: Readonly<{
  vehicle: Vehicle;
  context: Context;
  cars: Vehicle[];
  options?: FetchVehicleDataForVinOptions;
}>): Promise<VehicleDataResult> {
  return Promise.all([
    wrapResult(
      options?.disableUserLastVehiclesEventList === true
        ? Promise.resolve(context.userLastVehicleEvents)
        : getUserLastVehiclesEventList(),
    ),

    wrapResult(
      options?.disableVehicleEventList === true
        ? Promise.resolve(context.vehicleEvents)
        : getVehicleEventList({ vin: vehicle.vin }),
    ),

    options?.disableServiceTelemetry === true
      ? Promise.resolve(context.serviceTelemetry)
      : options?.disableSendTel === true
      ? getServiceTelemetry(vehicle.vin)
      : getServiceTelemetryWithSendTel(vehicle),

    wrapResult(
      options?.disableWifiStatus === true
        ? Promise.resolve(context.wifiStatus)
        : getWifiStatus(vehicle.vin),
    ),

    wrapResult(
      options?.disableNotificationStatus === true
        ? Promise.resolve(context.notificationStatus)
        : getNotificationStatus(vehicle.vin),
    ),

    wrapResult(
      options?.enableGeofence === true
        ? getGeofence(vehicle.vin)
        : Promise.resolve(vehicle.geofence),
    ),

    wrapResult(
      options?.enableLastVehiclesEventList === true
        ? getLastVehicleEventList(vehicle.vin)
        : Option(
            context.userLastVehicleEvents.find((_) => _.vin === vehicle.vin),
          ).unwrap(
            (_) => Promise.resolve(_.events),
            () =>
              Promise.reject(Error(`No LastVehicleEvents for ${vehicle.vin}`)),
          ),
    ),

    ...(options?.disableOtherServiceTelemetries === true
      ? context.otherServiceTelemetries.map((_) => Promise.resolve(_))
      : cars === undefined
      ? context.otherServiceTelemetries.map((_) =>
          Promise.reject(Error('cars are undefined')),
        )
      : getOtherServiceTelemetries({
          cars,
          vin: vehicle.vin,
          disableOtherSendTels: options?.disableOtherSendTels,
        })),
  ] as VehicleDataValues).then(
    ([
      userLastVehicleEvents,
      vehicleEvents,
      serviceTelemetry,
      wifiStatus,
      notificationStatus,
      geofence,
      lastVehicleEvents,
      ...otherServiceTelemetries
    ]) => ({
      userLastVehicleEvents,
      vehicleEvents,
      serviceTelemetry,
      wifiStatus,
      notificationStatus,
      geofence,
      lastVehicleEvents,
      otherServiceTelemetries,
    }),
  );
}

export function handleVehicleDataResponse(
  vin: string,
  isSelectedVehicle: boolean,
  currentDataErrors: DataErrors,
  vehicleList: Vehicle[],
  userLastEvents: Result<UserVehicleEvent[]>,
  events: Result<VehicleEvent[]>,
  service: ServiceTelemetryResult,
  wifi: Result<WifiStatus>,
  notification: Result<NotificationStatus>,
  geofence: Option<Result<Option<Geofence>>>,
  lastEvents: Option<Result<VehicleEvent[]>>,
): [ActionBinder<Context>, Option<DataErrors>] {
  type B = [ActionBinder<Context>, Option<DataErrors>];
  function updateErrors(
    e: Option<DataErrors>,
    error: ToOptional<DataErrors>,
  ): Option<DataErrors> {
    return e
      .map((e) => copy<DataErrors>(e, error))
      .orElse(() => Some(copy<DataErrors>(currentDataErrors, error)));
  }

  const dropEventIdList: EventId[] = ['1050', '1051']; // MON-791: Some events should not be handled in CAPP.

  return Action<Context>()
    .pipe<B>((a) => [a, None()])
    .pipe<B>(([a, e]) =>
      geofence
        .map((r) =>
          r.map((geofence) =>
            vehicleList.map((v) => (v.vin === vin ? { ...v, geofence } : v)),
          ),
        )
        .getOrElse(() => Success(vehicleList))
        .map<B>((xs) => [a.bind(Context.actions.updateVehicles, xs), e])
        .unwrap<B>(
          ([a, e]) => {
            return [a, updateErrors(e, { vehicleList: false })];
          },
          (err) => {
            println(err);
            return [a, updateErrors(e, { vehicleList: true })];
          },
        ),
    )
    .pipe(([a, e]) =>
      userLastEvents
        .flatMap<B>((_) =>
          lastEvents
            .map((r) =>
              r.map((events) =>
                _.map((_) => {
                  if (_.vin === vin)
                    return {
                      vin: vin,
                      events: events.filter(
                        (e) => !dropEventIdList.includes(e.eventId),
                      ),
                    };
                  else
                    return {
                      vin: _.vin,
                      events: _.events.filter(
                        (e) => !dropEventIdList.includes(e.eventId),
                      ),
                    };
                }),
              ),
            )
            .getOrElse(() =>
              Success(
                _.map((userVehicleEvent) => {
                  return {
                    vin: userVehicleEvent.vin,
                    events: userVehicleEvent.events.filter(
                      (e) => !dropEventIdList.includes(e.eventId),
                    ),
                  };
                }),
              ),
            )
            .map((xs) => [
              a.bind(Context.actions.updateUserLastVehicleEvents, xs),
              e,
            ]),
        )
        .unwrap<B>(
          ([a, e]) => {
            return [a, updateErrors(e, { userVehicleEvents: false })];
          },
          (err) => {
            println(err);
            return [a, updateErrors(e, { userVehicleEvents: true })];
          },
        ),
    )
    .pipe(([a, e]) =>
      events
        .map<B>((events) =>
          isSelectedVehicle
            ? [
                a.bind(
                  Context.actions.updateVehicleEvents,
                  events.filter((e) => !dropEventIdList.includes(e.eventId)),
                ),
                e,
              ]
            : [a, e],
        )
        .unwrap<B>(
          ([a, e]) => {
            return [a, updateErrors(e, { vehicleEvents: false })];
          },
          (err) => {
            println(err);
            return [a, updateErrors(e, { vehicleEvents: true })];
          },
        ),
    )
    .pipe(([a, e]) =>
      service.data
        .map<B>((_) =>
          isSelectedVehicle
            ? [a.bind(Context.actions.updateServiceTelemetry, service), e]
            : [
                a.bind(Context.actions.updateOtherServiceTelemetry, {
                  vin,
                  serviceTelemetry: service,
                }),
                e,
              ],
        )
        .unwrap<B>(
          ([a, e]) => {
            return [a, updateErrors(e, { serviceTelemetry: false })];
          },
          (err) => {
            println(err);
            return [a, updateErrors(e, { serviceTelemetry: true })];
          },
        ),
    )
    .pipe(([a, e]) =>
      wifi
        .map<B>((_) =>
          isSelectedVehicle
            ? [a.bind(Context.actions.updateWifiStatus, _), e]
            : [a, e],
        )
        .getOrElse((err) => {
          println(err);
          return isSelectedVehicle
            ? [
                a.bind(Context.actions.updateWifiStatus, errorWifiStatus(vin)),
                updateErrors(e, { wifiStatus: true }),
              ]
            : [a, e];
        }),
    )
    .pipe(([a, e]) =>
      notification
        .map<B>((_) =>
          isSelectedVehicle
            ? [a.bind(Context.actions.updateNotificationStatus, _), e]
            : [a, e],
        )
        .getOrElse((err) => {
          println(err);
          return isSelectedVehicle
            ? [
                a.bind(
                  Context.actions.updateNotificationStatus,
                  defaultNotificationStatus(vin),
                ),
                updateErrors(e, { notificationStatus: true }),
              ]
            : [a, e];
        }),
    );
  //.pipe(([a, e]) => a.bind(Context.actions.updateDataErrors, e));
}

export function fetchVehicleDataForVin({
  vehicle,
  context,
  cars,
  options,
}: Readonly<{
  vehicle: Vehicle;
  context: Context;
  cars: Vehicle[];
  options?: FetchVehicleDataForVinOptions;
}>): Promise<HomeDispatch> {
  return getVehicleDataForVin({
    vehicle,
    cars,
    context,
    options,
  })
    .then(
      ({
        userLastVehicleEvents,
        vehicleEvents,
        serviceTelemetry,
        wifiStatus,
        notificationStatus,
        geofence,
        lastVehicleEvents,
        otherServiceTelemetries,
      }) => {
        return homeDispatch
          .context(
            Context.actions.updateOtherServiceTelemetries,
            otherServiceTelemetries,
          )
          .compose(LoadedAction(context))
          .pipe((_) => {
            const [action, errors] = handleVehicleDataResponse(
              vehicle.vin,
              true,
              context.dataErrors,
              cars,
              userLastVehicleEvents,
              vehicleEvents,
              serviceTelemetry,
              wifiStatus,
              notificationStatus,
              options?.enableGeofence === true ? Some(geofence) : None(),
              options?.enableLastVehiclesEventList === true
                ? Some(lastVehicleEvents)
                : None(),
            );
            return _.context(action).context(
              Context.actions.updateDataErrors,
              errors.getOrElse(() => context.dataErrors),
            );
          });
        // .context(handleVehicleDataResponse(
        //   vehicle.vin,
        //   true,
        //   cars,
        //   userLastEvents,
        //   events,
        //   service,
        //   wifi,
        //   options?.enableGeofence ? Some(geofence) : None(),
        //   options?.enableLastVehiclesEventList ? Some(lastEvents) : None(),
        // ));
      },
    )
    .catch((err) => {
      println(err);
      return homeDispatch
        .context(Context.actions.updateDataErrors, {
          vehicleList: false,
          serviceTelemetry: true,
          userVehicleEvents: true,
          vehicleEvents: true,
          wifiStatus: true,
          notificationStatus: true,
        })
        .compose(LoadedAction(context));
    });
}

export function fetchVehicleData({
  context,
  options,
}: Readonly<{
  context: Context;
  options?: FetchVehicleDataOptions;
}>): HomeDispatch {
  return homeDispatch.context
    .bind(Context.actions.updateContentStateCount, (_, count) => [_, count + 1])
    .asyncAll(
      fetchVehicleList({ context, options })
        .then(([_, dispatch]) =>
          _.unwrap(
            ([cars, vehicle]) =>
              dispatch.asyncAll(
                fetchVehicleDataForVin({
                  vehicle,
                  cars,
                  context,
                  options,
                }),
              ),
            () => dispatch,
          ),
        )
        .catch((err) => {
          println(err);
          return homeDispatch
            .compose(LoadedAction(context))
            .context(Context.actions.updateDataErrors, {
              vehicleList: true,
              serviceTelemetry: true,
              userVehicleEvents: true,
              vehicleEvents: true,
              wifiStatus: true,
              notificationStatus: true,
            });
        }),
    );
}
