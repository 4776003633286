import { Scope } from './Scope';

type Unit = 'MilliSecond' | 'Second' | 'Minute' | 'Hour';

export type Duration = {
  readonly kind: 'Duration';
  readonly toMilliSeconds: () => number;
  readonly toString: () => string;
};

interface Statics {
  readonly milliSeconds: (value: number) => Duration;
  readonly seconds: (value: number) => Duration;
  readonly minutes: (value: number) => Duration;
  readonly hours: (value: number) => Duration;
}

interface Constructor {
  (value: number, unit: Unit): Duration;
}

function constructor(value: number, unit: Unit): Duration {
  const text = `${value} ${unit}`;
  const milles = Scope(() => {
    switch (unit) {
      case 'MilliSecond':
        return value;
      case 'Second':
        return value * 1000;
      case 'Minute':
        return value * 60 * 1000;
      case 'Hour':
        return value * 60 * 60 * 1000;
    }
  });

  return {
    kind: 'Duration',
    toMilliSeconds: () => milles,
    toString: () => text,
  };
}

const statics: Statics = {
  milliSeconds: (value: number) => constructor(value, 'MilliSecond'),
  seconds: (value: number) => constructor(value, 'Second'),
  minutes: (value: number) => constructor(value, 'Minute'),
  hours: (value: number) => constructor(value, 'Hour'),
};

export const Duration: Constructor & Statics = Object.assign(
  constructor,
  statics,
);
