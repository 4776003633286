import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import { ActionBinder, createActionBinder } from './ActionBinder';

export type Action<Data, Args> = {
  (data: Data, args: Args): Data;
};
export type ActionNoArgs<Data> = {
  (data: Data): Data;
};

export type Actions<Data, Self> = {
  readonly [Key in keyof Self]: Self[Key] extends ActionNoArgs<Data>
    ? ActionNoArgs<Data>
    : Self[Key] extends Action<Data, infer Args>
    ? Action<Data, Args>
    : never;
};

export interface Initializer<Data> {
  readonly initialize: (data: Data) => Data;
}

export interface Reducer<Data, A extends Actions<Data, A>>
  extends Initializer<Data> {
  readonly actions: A;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractData<R> = R extends Reducer<infer Data, any> ? Data : never;

export type ExtractLocalState<D> = 'localState' extends keyof D
  ? D extends { localState: infer S }
    ? S
    : never
  : never;

export type ExtractActions<R> = R extends Reducer<infer D, infer A>
  ? A extends Actions<D, A>
    ? A
    : never
  : never;

interface ActionFactory {
  <Data>(): ActionBinder<Data>;
  <Data>(action: (data: Data) => Data): ActionBinder<Data>;
  <Data, Args>(action: Action<Data, Args>, args: Args): ActionBinder<Data>;
}

interface ActionStatics extends ActionFactory {
  readonly bind: <Data, Args>(
    action: Action<Data, Args>,
    args: Args,
  ) => ActionBinder<Data>;
  readonly compose: <Data>(
    actionBinder: ActionBinder<Data>,
  ) => ActionBinder<Data>;
}

export const Action: ActionStatics = Scope(() => {
  const factory0: <Data>() => ActionBinder<Data> = () =>
    createActionBinder((data) => data, Unit);
  const factory1: <Data>(action: (data: Data) => Data) => ActionBinder<Data> = (
    action,
  ) => createActionBinder(action, Unit);
  const factory2: <Data, Args>(
    action: Action<Data, Args>,
    args: Args,
  ) => ActionBinder<Data> = (action, args) => createActionBinder(action, args);

  function factory<Data>(): ActionBinder<Data> {
    if (arguments.length === 0) return factory0();
    // eslint-disable-next-line prefer-rest-params
    else if (arguments.length === 1) return factory1(arguments[0]);
    // eslint-disable-next-line prefer-rest-params
    else return factory2(arguments[0], arguments[1]);
  }

  function bind<Data, Args>(action: Action<Data, Args>, args: Args) {
    return createActionBinder(action, args);
  }

  function compose<Data>(actionBinder: ActionBinder<Data>): ActionBinder<Data> {
    return createActionBinder(actionBinder, Unit);
  }

  return Object.assign(factory, { bind, compose });
});
