import * as Client from '@auto/monaka-client/dist/20';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from 'react';
import { println } from '../../apis/NativeApi';
import {
  VehicleLocation,
  defaultVehicleLocation,
} from '../../apis/ServiceTelemetry';
import { getSelectedVehicle, isServiceAllowed } from '../../apis/VehicleList';
import { BuildOpt } from '../../util/BuildOpt';
import { copy } from '../../util/Copyable';
import { None, Some } from '../../util/Option';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { ParentProps } from './DebugPane';
import {
  dispatchDB,
  dispatchServiceTelemetry,
  getServiceTelemetryItem,
  serviceTelemetryState,
} from './Helpers';
import { Item } from './Item';
import { Subtitle } from './Subtitle';

export function BackendDataPane({ parent }: ParentProps): React.ReactElement {
  const {
    views: { View, Text },
    data: { context, debug: data },
    dispatch,
  } = useParentStore({ parent });
  const db = data.db;

  return (
    <>
      <Subtitle title='Backend Data' />

      <Item
        parent={parent}
        text={<Text>Activate the door lock status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.door_status) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              door_status: !s.door_status,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the light status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.light) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({ light: !s.light }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the hazard lamp status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.hazard_lamp) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              hazard_lamp: !s.hazard_lamp,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the engine status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.eng_status) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              eng_status: !s.eng_status,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the air conditioner status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.air_con_status) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              air_con_status: !s.air_con_status,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the engine prohibited status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.dsc_eng_prohibit) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              dsc_eng_prohibit: !s.dsc_eng_prohibit,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the tracking mode status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.en_theft_trk) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              en_theft_trk: !s.en_theft_trk,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Increment the gas gauge.</Text>}
        icon={
          <View
            style={{
              width: Length.px(22),
              height: Length.px(22),
              borderRadius: Length.px(12),
              backgroundColor: Colors.background,
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Text
              style={{
                color: Colors.text,
                fontWeight: 'bold',
              }}
            >
              {getServiceTelemetryItem(db, context, (s) => s.fl_seg.toString())}
            </Text>
          </View>
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              fl_seg: s.fl_seg > 7 ? 0 : s.fl_seg + 1,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the gas gauge warning.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.fl_wrn) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({ fl_wrn: !s.fl_wrn }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Activate the IG status.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.ig_status) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              ig_status: !s.ig_status,
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>No vehicle location for the selected vehicle.</Text>}
        icon={
          serviceTelemetryState(db, context, (s) => s.position.isNone()) ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetry(
            db,
            context,
            (s) => ({
              position: s.position.unwrap(
                () => None<VehicleLocation>(),
                () =>
                  Some<VehicleLocation>({
                    isOld: false,
                    location: defaultVehicleLocation,
                  }),
              ),
            }),
            dispatch,
          )
        }
      />

      {!getSelectedVehicle(context).isNone() &&
        Array<Client.ServiceKey>(
          `bodyVehicleConditionMonitoring`,
          'engineRestartProhibit',
          'geofence',
          'meterVehicleConditionMonitoring',
          'remoteBodyControl',
          'remoteStart',
          'serviceReminder',
          'theftTracking',
          'vehicleLocation',
          'wifi',
        ).map((serviceKey) => (
          <Item
            key={serviceKey}
            parent={parent}
            text={
              isServiceAllowed(context, serviceKey) ? (
                <Text>
                  Drop &quot;{serviceKey}&quot; service from current vehicle
                </Text>
              ) : (
                <Text>
                  Add &quot;{serviceKey}&quot; service to current vehicle
                </Text>
              )
            }
            icon={
              isServiceAllowed(context, serviceKey) ? (
                <CheckCircleIcon />
              ) : (
                <CheckCircleOutlineIcon />
              )
            }
            disable={!BuildOpt.isMock()}
            onClick={() => {
              const selectedVehicle = getSelectedVehicle(context).getOrNull();
              if (selectedVehicle === null) {
                return dispatch;
              }
              const services = isServiceAllowed(context, serviceKey)
                ? selectedVehicle.services.filter((v) => v !== serviceKey)
                : selectedVehicle.services.concat([serviceKey]);
              println(`new services ${services}`);
              return dispatchDB(
                db,
                {
                  vehicles: db.vehicles.map((v) => {
                    if (v.vin === selectedVehicle.vin)
                      return copy(v, { services: services });
                    else return v;
                  }),
                },
                dispatch,
              ).context.bind(Context.actions.updateContentState, 'Load');
            }}
          />
        ))}
    </>
  );
}
