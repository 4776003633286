import { UpdateArgs, WithSetCallback } from './DataMapStore';
import { DispatchBase } from './DispatchBase';

export type CurrentCallback<DataMap> = <Props>(
  args: UpdateArgs<Props, DataMap>,
) => DispatchBase<'DataMapDispatch', DataMap>;

export interface DispatchCurrentItem<DataMap> {
  readonly compose: (
    that: DispatchCurrentItem<DataMap>,
  ) => DispatchCurrentItem<DataMap>;

  readonly dispatch: (state: WithSetCallback<DataMap>) => void;

  readonly dispatchAsync: (state: WithSetCallback<DataMap>) => void;

  readonly flatMap: (
    f: (a: CurrentCallback<DataMap>) => DispatchCurrentItem<DataMap>,
  ) => DispatchCurrentItem<DataMap>;

  readonly current: (
    f: CurrentCallback<DataMap>,
  ) => DispatchCurrentItem<DataMap>;
}

function bind<DataMap>(
  f1: CurrentCallback<DataMap>,
  f2: CurrentCallback<DataMap>,
): DispatchCurrentItem<DataMap> {
  return createDispatchCurrentItem<DataMap>((args) =>
    f1(args).compose(f2(args)),
  );
}

export function createDispatchCurrentItem<DataMap>(
  callback: CurrentCallback<DataMap>,
): DispatchCurrentItem<DataMap> {
  type Unsafe = DispatchCurrentItem<DataMap> & {
    readonly __callback: CurrentCallback<DataMap>;
  };

  const unsafe: Unsafe = {
    __callback: callback,

    dispatch: (state: WithSetCallback<DataMap>) => state.setCallback(callback),

    dispatchAsync: (state: WithSetCallback<DataMap>) =>
      state.setCallback(callback),

    flatMap: (f) => f(callback),

    current: (f: CurrentCallback<DataMap>) => bind(callback, f),

    compose: (a: DispatchCurrentItem<DataMap>) =>
      a.flatMap((g) => bind(callback, g)),
  };

  return unsafe;
}
