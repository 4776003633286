import React from 'react';
import {
  ReactNativeWindow,
  requestLocationEnable,
  requestLocationPermission,
} from '../../apis/NativeApi';
import { HomeDataMap, homeDispatch } from '../home/Home';
import { Message, useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { startUserLocation } from './Helpers';
import { GpsState, MapMode } from './VehicleLocationState';

export const noPermissionHeight = Length.px(48);

export function PermissionWarning({
  parent,
  mapMode,
  gpsState,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mapMode: MapMode;
  gpsState: GpsState;
}>): React.ReactElement {
  const {
    views,
    data: { context },
    dispatch,
  } = useParentStore({ parent });
  const View = views.default;
  const intl = useIntl();
  const request =
    gpsState === 'NoPermission'
      ? requestLocationPermission
      : requestLocationEnable;

  const restart = dispatch
    .context(Context.actions.updateContentState, 'Reload')
    .context(Context.actions.updateFetchVehicleDataOptions, {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: true,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
    });

  if (gpsState !== 'Normal' && mapMode === 'Open')
    return (
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: noPermissionHeight,
          color: Colors.errorText,
        }}
        onClick={(evt) =>
          dispatch
            .effect(() => evt.stopPropagation())
            .pipe((_) => {
              if (ReactNativeWindow.isOnMockDevice) {
                return _.context(Context.actions.updateConfirmDialog, {
                  state: 'Show',
                  title: intl.formatMessage({ id: 'Location_locationService' }),
                  message: intl.formatMessage({
                    id: 'Location_locationServiceContents',
                  }),
                  confirmButtonTitle: Message('Tern On'),
                  onConfirm: () =>
                    homeDispatch.asyncAll(request().then((_) => restart)),
                });
              } else if (
                context.platform.os === 'ios' ||
                (context.platform.os === 'android' &&
                  context.platform.osVersion >= 11)
              ) {
                return _.context(Context.actions.updateConfirmDialog, {
                  state: 'Show',
                  title: intl.formatMessage({ id: 'Location_locationService' }),
                  message: intl.formatMessage({
                    id: 'Location_locationServiceContents',
                  }),
                  confirmButtonTitle: intl.formatMessage({
                    id: 'Location_moveToSettings',
                  }),
                  cancelButtonTitle: intl.formatMessage({
                    id: 'Common_cancel',
                  }),
                  onConfirm: () =>
                    dispatch.asyncAll(request().then((_) => restart)),
                });
              } else {
                return _.asyncAll(
                  request().then((_) => startUserLocation(intl, dispatch)),
                );
              }
            })
        }
      >
        {intl
          .formatMessage({ id: 'Location_locationServiceOff' })
          .toReactNode()}
      </View>
    );
  else return <></>;
}
