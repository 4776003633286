import { None, Option, Some } from './Option';

export interface Either<A, B> {
  map<C>(f: (b: B) => C): Either<A, C>;
  flatMap<C>(f: (b: B) => Either<A, C>): Either<A, C>;
  getRight(): Option<B>;
  getLeft(): Option<A>;
  unwrap<C>(r: (a: B) => C, l: (a: A) => C): C;
  isRight(): boolean;
  isLeft(): boolean;
  equals(a: Either<A, B>): boolean;
}

interface Statics {
  readonly fromOption: <A, B>(o: Option<B>, a: () => A) => Either<A, B>;
}

export const Either: Statics = {
  fromOption: <A, B>(o: Option<B>, a: () => A) =>
    o.map((b) => Right<A, B>(b)).getOrElse(() => Left<A, B>(a())),
};

export function Right<A, B>(b: B): Either<A, B> {
  const self: Either<A, B> = {
    map: <C>(f: (b: B) => C) => {
      return Right<A, C>(f(b));
    },

    flatMap: <C>(f: (b: B) => Either<A, C>) => f(b),

    getRight: () => Some(b),

    getLeft: () => None<A>(),

    unwrap: <C>(r: (a: B) => C, _l: (a: A) => C) => r(b),

    isRight: () => true,

    isLeft: () => false,

    equals: (c: Either<A, B>) =>
      c.getRight().equals(self.getRight()) ||
      c.getLeft().equals(self.getLeft()),
  };
  return self;
}

export function Left<A, B>(a: A): Either<A, B> {
  const self: Either<A, B> = {
    map: <C>(_f: (b: B) => C) => Left<A, C>(a),

    flatMap: <C>(_f: (b: B) => Either<A, C>) => Left<A, C>(a),

    getRight: () => None<B>(),

    getLeft: () => Some(a),

    unwrap: <C>(r: (a: B) => C, l: (a: A) => C) => l(a),

    isRight: () => false,

    isLeft: () => true,

    equals: (c: Either<A, B>) => c.getLeft().equals(self.getLeft()),
  };
  return self;
}
