import React, { useCallback, useState } from 'react';
import { SharedUser, useGetUserNickName } from '../../apis/SharedUser';
import { Vehicle, useGetCarNickName } from '../../apis/VehicleList';
import { HomeDataMap, HomeDispatch } from '../home/Home';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { OptionalStyles } from '../style/Style';

export type CarNickNameInputProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  style?: OptionalStyles;
  car: Vehicle;
  onChanged: () => void;
}>;

export const CarNickNameInput: React.FC<CarNickNameInputProps> = (props) => {
  const { dispatch } = useParentStore({ parent: props.parent });
  const [defaultValue] = useGetCarNickName(props.car, false);
  const onValueChanged = useCallback(
    (value: string) => {
      const nickName = value;
      props.onChanged();
      return dispatch.context.bind(Context.actions.updateCarNickName, {
        vin: props.car.vin,
        nickName,
      });
    },
    [props, dispatch],
  );

  return (
    <NickNameInput
      parent={props.parent}
      style={props.style}
      defaultValue={defaultValue}
      onValueChanged={onValueChanged}
    />
  );
};

export type UserNickNameInputProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  style?: OptionalStyles;
  user: SharedUser;
  onChanged: () => void;
}>;

export const UserNickNameInput: React.FC<UserNickNameInputProps> = (props) => {
  const { dispatch } = useParentStore({ parent: props.parent });
  const [defaultValue] = useGetUserNickName(props.user, false);
  const onValueChanged = useCallback(
    (value: string) => {
      const nickName = value;
      props.onChanged();
      return dispatch.context.bind(Context.actions.updateUserNickName, {
        globalUserId: props.user.sharedUserId,
        nickName,
      });
    },
    [props, dispatch],
  );

  return (
    <NickNameInput
      parent={props.parent}
      style={props.style}
      defaultValue={defaultValue}
      onValueChanged={onValueChanged}
    />
  );
};

type NickNameInputProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  style?: OptionalStyles;
  defaultValue: string;
  onValueChanged: (value: string) => HomeDispatch;
}>;

const NickNameInput: React.FC<NickNameInputProps> = (props) => {
  const {
    views: { Input },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const [value, setValue] = useState(props.defaultValue);
  const onValueChanged = useCallback(
    (evt) => {
      const value = (evt as React.FormEvent<HTMLInputElement>).currentTarget
        .value;
      setValue(value);
      return dispatch;
    },
    [dispatch],
  );
  const onBlur = useCallback(() => {
    return props.onValueChanged(value);
  }, [props, value]);

  return (
    <Input
      style={props.style}
      autoFocus
      value={value}
      onChange={onValueChanged}
      onBlur={onBlur}
    />
  );
};
