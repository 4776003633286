import React from 'react';
import { Option } from '../../util/Option';
import { useContextStore } from '../states/ContextStore';
import { Dispatch } from '../states/Dispatch';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';

interface Props {
  readonly state: OverlayState;
}

export type OverlayState = 'Show' | 'Hide';

export function Overlay(props: Props): React.ReactElement {
  const {
    views,
    data: { context },
  } = useContextStore();
  const Container = views.default;
  const viewPort = context.viewPort;

  const containerStyle = Option.fromBoolean(props.state === 'Show')
    .map<OptionalStyles>(() => ({
      top: Length.px(0),
    }))
    .getOrElse(() => ({
      top: Length.px(viewPort.height),
    }));

  return (
    <Container
      style={{
        position: 'absolute',
        left: Length.px(0),
        width: Length.px(viewPort.width),
        height: Length.px(viewPort.height),
        backgroundColor: Colors.overlayCover,
        ...containerStyle,
      }}
      onClick={(evt: React.MouseEvent) => {
        evt.stopPropagation();
        //return Dispatch();
        return Dispatch.nothing();
      }}
      onTouchStart={(evt: React.TouchEvent) => {
        evt.stopPropagation();
        return Dispatch.nothing();
      }}
      onTouchMove={(evt: React.TouchEvent) => {
        evt.stopPropagation();
        return Dispatch.nothing();
      }}
      onTouchEnd={(evt: React.TouchEvent) => {
        evt.stopPropagation();
        return Dispatch.nothing();
      }}
    />
  );
}
