import { Colors, RGBA } from './Color';
import { Length } from './Length';

type LineWidth = Length;
type LineStyle =
  | 'none'
  | 'hidden'
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset';

export type Border = {
  readonly style: LineStyle;
  readonly width: LineWidth;
  readonly color: RGBA;
  readonly toStyle: () => string;
};

type Statics = {
  readonly none: Border;
};

type Factory = {
  (style: LineStyle, width: Length, color: RGBA): Border;
};

function create(style: LineStyle, width: Length, color: RGBA): Border {
  const text = `${style} ${width.toStyle()} ${color.toStyle()}`;
  return {
    style,
    width,
    color,
    toStyle: () => text,
  };
}

const statics: Statics = {
  none: create('solid', Length.px(0), Colors.transparent),
};

export const Border: Factory & Statics = Object.assign(create, statics);
