import * as Sentry from '@sentry/react';
import React from 'react';
import './App.css';
import { ContextProvider } from './ui/components/ContextProvider';
import { FallbackError } from './ui/components/FallbackError';
import { Home } from './ui/home/Home';

export default function App(): React.ReactElement {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <FallbackError error={error} componentStack={componentStack} />
      )}
    >
      <ContextProvider>
        <Home />
      </ContextProvider>
    </Sentry.ErrorBoundary>
  );
}

// export default function App() {

//   console.log('=============>');
//   Listener<number>(dispatch => {
//     setTimeout(() => {
//       dispatch(Success(1));
//     }, 1000);
//   })
//     .flatMap(a => {
//       console.log('<<<<< CALLBACK 1', a);
//       return Listener.success(a);
//     })
//     .flatMap(a => {
//       console.log('<<<<< CALLBACK 2', a);
//       return Listener.success(a);
//     })
//   return <></>;
// }
