export type Pipable<A> = A & {
  readonly isPipable: boolean;
  readonly pipe: <B>(f: (a: A) => B) => Pipable<B>;
  readonly unwrap: () => A;
};

export interface Pipe<A> {
  readonly kind: 'Pipe';
  readonly forEach: (f: (a: A) => void) => void;
  readonly map: <B>(f: (a: A) => B) => Pipe<B>;
  readonly flatMap: <B>(f: (a: A) => Pipe<B>) => Pipe<B>;
  readonly with: (f: (a: A) => void) => Pipe<A>;
  readonly get: () => A;
}

type Factory = {
  <A>(a: A): Pipe<A>;
};

type Statics = {
  readonly lazy: <A>(f: () => A) => Pipe<A>;
};

function create<A>(a: A): Pipe<A> {
  return {
    kind: 'Pipe',
    forEach: (f: (a: A) => void) => f(a),
    map: <B>(f: (a: A) => B) => create(f(a)),
    flatMap: <B>(f: (a: A) => Pipe<B>) => f(a),
    with: (f: (a: A) => void) => {
      f(a);
      return create(a);
    },
    get: () => a,
  };
}

const statics: Statics = {
  lazy: <A>(f: () => A) => create(f()),
};

export const Pipe: Factory & Statics = Object.assign(create, statics);

export function toPipe<A>(a: A): Pipable<A> {
  type Mutable = {
    isPipable: boolean;
    pipe: <B>(f: (a: A) => B) => Pipable<B>;
    unwrap: () => A;
  };
  const _a = a as unknown as Mutable;
  if (_a.isPipable) return a as Pipable<A>;
  else {
    _a.isPipable = true;
    _a.pipe = <B>(f: (a: A) => B) => toPipe(f(a));
    _a.unwrap = () => a;
    return a as Pipable<A>;
  }
}
