import * as Color from './Color';
import { Length } from './Length';

export interface BoxShadow {
  readonly offsetX: Length;
  readonly offsetY: Length;
  readonly blurRadius: Length;
  readonly color: Color.RGBA;
  readonly toStyle: () => string;
}

export function BoxShadow(
  offsetX: Length,
  offsetY: Length,
  blurRadius: Length,
  color: Color.RGBA,
): BoxShadow {
  const text = `${offsetX.toStyle()} ${offsetY.toStyle()} ${blurRadius.toStyle()} ${color.toStyle()}`;
  return {
    offsetX,
    offsetY,
    blurRadius,
    color,
    toStyle: () => text,
  };
}
