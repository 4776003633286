import { v4 as uuid } from 'uuid';
import { Anomaly } from './Error';
import { Failure, Result, Success } from './Result';

export interface Listener<A> {
  readonly kind: 'Listener';
  readonly map: <B>(g: (a: A) => B) => Listener<B>;
  readonly flatMap: <B>(g: (a: A) => Listener<B>) => Listener<B>;
  readonly onResult: (
    onSuccess: (a: A) => void,
    onFailure: (err: Anomaly) => void,
  ) => void;
  readonly onSuccess: (f: (a: A) => void) => void;
  readonly onFailure: (f: (err: Anomaly) => void) => void;
  readonly recover: (g: (err: Anomaly) => Listener<A>) => Listener<A>;
  readonly stop: () => void;
  readonly toPromise: () => Promise<A>;
}

export interface Sender<_A> {
  readonly kind: 'Sender';
}

export interface UnsafeSender<A> extends Sender<A> {
  readonly send: (a: A) => void;
}

interface Statics {
  readonly success: <A>(a: A) => Listener<A>;
  readonly failure: <A>(err: Anomaly) => Listener<A>;
  readonly fromPromise: <A>(p: Promise<A>) => Listener<A>;
  readonly withSender: <A>() => [Listener<A>, Sender<A>];
}

type Dispatch<A> = (result: Result<A>) => void;

interface Factory {
  <A>(
    f: (dispatch: Dispatch<A>) => void,
    options?: { readonly __debugId?: string },
  ): Listener<A>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const factory: Factory = <A>(
  f: (dispatch: Dispatch<A>) => void,
  options: {
    readonly __debugId?: string;
  } = {},
) => {
  type Unsafe = Listener<A> & {
    readonly __debugId: string;
  };
  const results: Result<A>[] = [];
  const callbacks: Dispatch<A>[] = [];
  let isStopped = false;
  const __debugId = options.__debugId ?? '0';

  function addCallback(cb: Dispatch<A>): void {
    callbacks.push(cb);
    results.forEach((r) => cb(r));
  }

  f((a) => {
    if (isStopped === false) {
      callbacks.forEach((cb) => cb(a));
      results.push(a);
    }
  });

  const self: Unsafe = {
    __debugId,

    kind: 'Listener',

    map: <B>(g: (a: A) => B) => self.flatMap((a) => Listener.success(g(a))),

    flatMap: <B>(g: (a: A) => Listener<B>) =>
      Listener<B>(
        (dispatch) =>
          addCallback((result) =>
            result.onResult(
              (a) =>
                g(a).onResult(
                  (b) => dispatch(Success(b)),
                  (err) => dispatch(Failure(err)),
                ),
              (err) => dispatch(Failure(err)),
            ),
          ),
        { __debugId: `${__debugId}-${callbacks.length + 1}` },
      ),

    onResult: (
      onSuccess: (a: A) => void,
      onFailure: (err: Anomaly) => void,
    ) => {
      addCallback((result) =>
        result.onResult(
          (a) => onSuccess(a),
          (err) => onFailure(err),
        ),
      );
    },

    onSuccess: (f: (a: A) => void) => self.onResult((a) => f(a), noop),

    onFailure: (f: (err: Anomaly) => void) =>
      self.onResult(noop, (err) => f(err)),

    recover: (g: (err: Anomaly) => Listener<A>) =>
      Listener<A>((dispatch) =>
        addCallback((result) =>
          result.onResult(
            (a) => dispatch(Success(a)),
            (err) =>
              g(err).onResult(
                (a) => dispatch(Success(a)),
                (err) => dispatch(Failure(err)),
              ),
          ),
        ),
      ),

    stop: () => {
      isStopped = true;
    },

    toPromise: () =>
      new Promise((resolve, reject) => self.onResult(resolve, reject)),
  };
  return self;
};

const statics: Statics = {
  success: <A>(a: A) =>
    Listener<A>((dispatch) => setTimeout(() => dispatch(Success(a)))),

  failure: <A>(err: Anomaly) =>
    Listener<A>((dispatch) => setTimeout(() => dispatch(Failure(err)))),

  fromPromise: <A>(p: Promise<A>) =>
    factory<A>((dispatch) => p.then((a) => dispatch(Success(a)))),

  withSender: <A>() => {
    const id = uuid();
    const listener = factory<A>((dispatch) =>
      window.addEventListener(id, (e: Event) =>
        dispatch(Success((e as CustomEvent).detail)),
      ),
    );
    const sender: UnsafeSender<A> = {
      kind: 'Sender',
      send: (a: A) => window.dispatchEvent(new CustomEvent(id, { detail: a })),
    };
    return [listener, sender];
  },
};

export const Listener: Factory & Statics = Object.assign(factory, statics);
