import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { BuildOpt } from '../../util/BuildOpt';
import { Lazy } from '../../util/Lazy';
import { None, Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { EventIdMap } from '../i18n/EventIdMap';
import { EventIdType, EventIdTypeMap } from '../i18n/EventTypeMap';
import {
  EventId,
  RawNotificationBodyId,
  RawNotificationId,
  RawNotificationTitleId,
} from '../i18n/Types';
import { useParentStore } from '../states/ContextStore';
import { Colors } from '../style/Color';
import * as Style from '../style/Style';
import { ParentProps } from './DebugPane';
import {
  dispatchDB,
  dispatchNotification,
  dispatchServiceTelemetryEvent,
  dispatchVehicleEvent,
} from './Helpers';
import { Item } from './Item';
import { Subtitle } from './Subtitle';

const listOfEventType1: Lazy<Array<[string, string]>> = Lazy(() =>
  EventIdMap.keys
    .flatMap((key) =>
      Option(RegExp(`^Event_(.+)Home`).exec(EventIdMap.get(key).home))
        .flatMap<string>((_) => Option(_[1]))
        .flatMap<[string, EventIdType]>((a) =>
          EventIdTypeMap.get(`${a}Body` as RawNotificationId).map((b) => [
            a,
            b,
          ]),
        )
        .flatMap<[string, string]>(([a, b]) =>
          b.type === 1
            ? b.id.map<[string, string]>((c) => [c, a])
            : None<[string, string]>(),
        )
        .unwrap<Array<[string, string]>>(
          (_) => [_],
          () => [],
        ),
    )
    .sort((a, b) => parseInt(a[0]) - parseInt(b[0])),
);

const styles = Style.create({
  select: {
    backgroundColor: Colors.mockBackground,
    color: Colors.background,
  },
});

export function NotificationsPane({ parent }: ParentProps): React.ReactElement {
  const {
    views: { View, Text, Select: Sel, Option: Opt },
    data: { context, debug: data },
    dispatch,
  } = useParentStore({ parent });

  const db = data.db;

  return (
    <>
      <Subtitle title='Events & Notifications' />

      <Item
        parent={parent}
        text={<Text>Clear all vehicle event lists.</Text>}
        icon={Scope(() => {
          if (
            db.vehicleEvents.some((_) => _.events.length > 0) &&
            db.userVehicleEvents.some((_) => _.events.length > 0)
          ) {
            return <DeleteIcon />;
          } else {
            return <DeleteOutlineOutlinedIcon />;
          }
        })}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            {
              vehicleEvents: db.vehicleEvents.map((a) => ({
                vin: a.vin,
                events: [],
              })),
              userVehicleEvents: db.userVehicleEvents.map((a) => ({
                vin: a.vin,
                events: [],
              })),
            },
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text>[EventType: 1]</Text>
            <Sel
              style={styles.select}
              onChange={(evt) =>
                dispatch.debug((data) => {
                  const [eventId, eventName] = JSON.parse(
                    evt.currentTarget.value,
                  ) as [EventId, string];
                  return {
                    ...data,
                    eventType1: {
                      ...data.eventType1,
                      eventId,
                      eventName,
                    },
                  };
                })
              }
            >
              {listOfEventType1.get().map(([id, name], idx) => (
                <Opt key={idx} value={JSON.stringify([id, name])}>
                  {name}({id})
                </Opt>
              ))}
            </Sel>
            <Sel
              style={styles.select}
              onChange={(evt) =>
                dispatch.debug((data) => ({
                  ...data,
                  eventType1: {
                    ...data.eventType1,
                    isAppActive: evt.currentTarget.value === 'foreground',
                  },
                }))
              }
            >
              <Opt value='foreground'>On the foreground</Opt>
              <Opt value='background'>On the background</Opt>
            </Sel>
            <Sel
              style={styles.select}
              onChange={(evt) =>
                dispatch.debug((data) => ({
                  ...data,
                  eventType1: {
                    ...data.eventType1,
                    isSelectedVehicle: evt.currentTarget.value === 'selected',
                  },
                }))
              }
            >
              <Opt value='selected'>To a selected vehicle</Opt>
              <Opt value='unselected'>To a unselected vehicle </Opt>
            </Sel>
          </View>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClickIcon={() =>
          dispatchVehicleEvent(
            db,
            context,
            data.eventType1.eventId,
            (vehicle) => ({
              target: 'notification',
              foreground: data.eventType1.isAppActive,
              vin: vehicle.vin,
              titleId:
                `${data.eventType1.eventName}Title` as RawNotificationTitleId,
              bodyId:
                `${data.eventType1.eventName}Body` as RawNotificationBodyId,
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 2] Send a remoteHeadLightOnSuccess notification to a
            selected vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { light: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteHeadLightOnSuccessTitle',
              bodyId: 'remoteHeadLightOnSuccessBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 2] Send a remoteHeadLightOnSuccess notification to a
            unselected vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { light: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteHeadLightOnSuccessTitle',
              bodyId: 'remoteHeadLightOnSuccessBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 2] Send a remoteHeadLightOnFail notification to a
            unselected vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteHeadLightOnFailTitle',
              bodyId: 'remoteHeadLightOnFailBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 2] Send a remoteHeadLightOffFail notification from
            background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'remoteHeadLightOffFailTitle',
              bodyId: 'remoteHeadLightOffFailBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 4] Send a Ukkari notification from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { door_status: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'ukkariTitle',
              bodyId: 'ukkariBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 4] Send a UkkariDoor notification from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { door_status: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'ukkariDoorTitle',
              bodyId: 'ukkariDoorBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 4] Send a UkkariDoor notification from background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { door_status: true },
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'ukkariDoorTitle',
              bodyId: 'ukkariDoorBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 4] Send a UkkariDoor notification to vin-2 from
            foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { door_status: true },
            (_vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: 'vin-2',
              titleId: 'ukkariDoorTitle',
              bodyId: 'ukkariDoorBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              vin: 'vin-2',
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 4] Send a UkkariDoor notification to vin-2 from
            background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { door_status: true },
            (_vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: 'vin-2',
              titleId: 'ukkariDoorTitle',
              bodyId: 'ukkariDoorBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              vin: 'vin-2',
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 5] Send a trackingOn notification to a selected vehicle
            from background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { en_theft_trk: true },
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'trackingOnTitle',
              bodyId: 'trackingOnBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 5] Send a trackingOn notification to an unselected
            vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { en_theft_trk: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'trackingOnTitle',
              bodyId: 'trackingOnBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 6] Send a engineProhibit notification to a selected
            vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { dsc_eng_prohibit: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'engineProhibitTitle',
              bodyId: 'engineProhibitBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 7] Send a remoteWifiOnFail notification to a selected
            vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteWifiOnFailTitle',
              bodyId: 'remoteWifiOnFailBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 7] Send a remoteWifiOnFail notification to a unselected
            vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteWifiOnFailTitle',
              bodyId: 'remoteWifiOnFailBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 7] Send a remoteWifiOnFail notification to a selected
            vehicle from background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'remoteWifiOnFailTitle',
              bodyId: 'remoteWifiOnFailBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 7] Send a remoteWifiOnFail notification to a unselected
            vehicle from background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'remoteWifiOnFailTitle',
              bodyId: 'remoteWifiOnFailBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 8] Send a remoteGeofenceOffFail notification to a
            selected vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteGeofenceOffFailTitle',
              bodyId: 'remoteGeofenceOffFailBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 8] Send a remoteGeofenceOffFail notification to a
            selected vehicle from background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'remoteGeofenceOffFailTitle',
              bodyId: 'remoteGeofenceOffFailBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 8] Send a remoteTrackingOnSuccess notification to a
            unselected vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchServiceTelemetryEvent(
            db,
            context,
            { en_theft_trk: true },
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'remoteTrackingOnSuccessTitle',
              bodyId: 'remoteTrackingOnSuccessBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 9] Send a carResgitrationFail notification to a selected
            vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'carResgitrationFailTitle',
              bodyId: 'carResgitrationFailBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 9] Send a carResgitrationFail notification to a
            unselected vehicle from foreground.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'carResgitrationFailTitle',
              bodyId: 'carResgitrationFailBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 9] Send a carResgitrationFail notification to a
            unselected vehicle from background.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchNotification(
            db,
            context,
            (vehicle) => ({
              target: 'notification',
              foreground: false,
              vin: vehicle.vin,
              titleId: 'carResgitrationFailTitle',
              bodyId: 'carResgitrationFailBody',
              label: 'openedapp',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
            {
              unselectedVehicle: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 13] Send a SOSCall(auto) event from foreground to
            selected vehicle.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchVehicleEvent(
            db,
            context,
            '1050',
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'sosCallAutoTitle',
              bodyId: 'sosCallAutoBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>
            [EventType: 13] Send a SOSCall(manual) event from foreground to
            selected vehicle.
          </Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchVehicleEvent(
            db,
            context,
            '1051',
            (vehicle) => ({
              target: 'notification',
              foreground: true,
              vin: vehicle.vin,
              titleId: 'sosCallManualTitle',
              bodyId: 'sosCallManualBody',
              label: 'onmessage',
              messageId: 'dummy message',
              timestamp: Date.now(),
            }),
            dispatch,
          )
        }
      />
    </>
  );
}
