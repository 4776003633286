import { v4 as uuid } from 'uuid';
import { config } from '../Config';
import {
  RawNotificationBodyId,
  RawNotificationTitleId,
} from '../ui/i18n/Types';
import { MockDevice } from '../ui/mock/MockDevice';
import { OS, Platform } from '../ui/states/Context';
import { BuildOpt } from '../util/BuildOpt';
import { debugAsync2 } from '../util/Debug';
import { Duration } from '../util/Duration';
import { Anomaly, AnomalyJson, getErrorMessage } from '../util/Error';
import { Listener } from '../util/Listener';
import { Locale, parseLocale } from '../util/Locale';
import { None, Option, Some } from '../util/Option';
import { Success } from '../util/Result';
import { Scope } from '../util/Scope';
import { Unit } from '../util/Unit';
import { AuthInfo, isValidAuthInfo } from './Auth';
import { GeoPos } from './Geofence';
import { StorageKey, StorageKeys } from './StorageKey';

export type ReactNativeWebView = {
  readonly postMessage: (message: string) => void;
};

type ReactNativeWindow = Window & {
  //readonly reactNativeWebView: Option<ReactNativeWebView>;
  readonly isOnMockDevice: boolean;
  readonly webView: ReactNativeWebView;
};

type MessageType = 'Request' | 'Response';

export type UserLocation = 'NoPermission' | 'Unavailable' | GeoPos;

export type NativeCustomEventTarget =
  | 'ssid'
  | 'locale'
  | 'notification'
  | 'deviceToken'
  | 'version'
  | 'action'
  | 'location'
  | 'locationPermission'
  | 'locationEnable'
  | 'storage'
  | 'keyboard'
  | 'google-map'
  | 'log'
  | 'link'
  | 'dismissSplash'
  | 'showSharedUserRegistrationScreen'
  | 'qrCodeData'
  | 'screenSize';

interface RawNativeCustomEvent<
  Target extends NativeCustomEventTarget,
  SA extends StorageAction,
> extends Event {
  readonly detail?: {
    readonly data?: RawNativeCustomEventData<Target, SA>;
  };
}

type RawSSIDBase = {
  readonly target: 'ssid';
};

type RawSSIDRequest = RawSSIDBase;

interface RawSSIDResponse extends RawSSIDBase {
  readonly ssid: string;
  readonly bssid: string;
}

type RawLocaleInfo = {
  readonly target: 'locale';
  readonly locale: string;
};

type NotificationBase = {
  readonly target: 'notification';
  readonly foreground: boolean;
  readonly vin: string;
  readonly titleId: RawNotificationTitleId;
  readonly bodyId: RawNotificationBodyId;
  readonly messageId: string;
  readonly label: 'openedapp' | 'initial' | 'onmessage' | 'background';
};

export type RawNotification = NotificationBase & {
  readonly timestamp: number;
};

type RawDeviceToken = {
  readonly target: 'deviceToken';
  readonly token: string;
};

type RawVersion = {
  readonly target: 'version';
  readonly data: {
    readonly version: string;
    readonly buildNumber: string;
    readonly platform: OS;
    readonly osVersion: string;
  };
};

export type NativeAction = 'None' | 'back' | 'foreground';

interface RawAction {
  readonly target: 'action';
  readonly action: NativeAction;
}

interface RawLocationBase {
  readonly target: 'location';
}

interface RawLocationRequest extends RawLocationBase {
  readonly timeout: number; // milliseconds
}

interface RawLocationResponse extends RawLocationBase {
  readonly location?: GeoPos;
  readonly permission: 'granted' | 'unavailable' | 'blocked' | 'denied';
  readonly error?: AnomalyJson;
}

interface RawLocationPermissionBase {
  readonly target: 'locationPermission';
}

type RawLocationPermissionRequest = RawLocationPermissionBase;

interface RawLocationPermissionResponse extends RawLocationPermissionBase {
  readonly error?: AnomalyJson;
}

interface RawLocationEnableBase {
  readonly target: 'locationEnable';
}

type RawLocationEnableRequest = RawLocationEnableBase;

interface RawLocationEnableResponse extends RawLocationEnableBase {
  readonly error?: AnomalyJson;
}

export type StorageAction = 'get' | 'set' | 'remove';

export type RawStorageBase<Action extends StorageAction> = {
  readonly target: 'storage';
  readonly action: Action;
  readonly id: string;
  readonly key: StorageKey;
  readonly error?: string;
};

export type RawStorage<
  Type extends MessageType,
  Action extends StorageAction,
  Base extends RawStorageBase<Action> = RawStorageBase<Action>,
> = Type extends 'Request'
  ? Action extends 'set'
    ? Base & { readonly value: string }
    : Base
  : Type extends 'Response'
  ? Action extends 'get'
    ? Base & { readonly value: string | null }
    : Base
  : never;

type RawKeyboard = {
  readonly target: 'keyboard';
  readonly action: 'show' | 'hide';
  readonly height: number;
};

export type OpenType = 'InAppBrowser' | 'ExternalBrowser';

type RawLinkRequest = {
  readonly target: 'link';
  readonly url: string;
  readonly title?: string;
  readonly openType?: OpenType;
};

type RawGoogleMapRequest = {
  readonly target: 'google-map';
  readonly url: string;
};

type RawLogRequest = {
  readonly target: 'log';
  readonly data: string;
  readonly isDebug: boolean;
};

type RawDismissSplash = {
  readonly target: 'dismissSplash';
};

type RawShowSharedUserRegistrationScreen = {
  readonly target: 'sharedUserRegistrationScreen';
  readonly action: 'show' | 'hide';
};

type RawQRCodeData = {
  readonly target: 'qrCodeData';
  readonly invitationCode: string;
  readonly invitationExpireTime: number; // epoch
};

type RawScreenSize = {
  readonly target: 'screenSize';
  readonly screenWidth: number;
  readonly screenHeight: number;
  readonly marginTop: number;
  readonly marginBottom: number;
};

export type RawNativeCustomEventData<
  Target extends NativeCustomEventTarget,
  SA extends StorageAction,
> = Target extends 'ssid'
  ? RawSSIDResponse
  : Target extends 'locale'
  ? RawLocaleInfo
  : Target extends 'notification'
  ? RawNotification
  : Target extends 'deviceToken'
  ? RawDeviceToken
  : Target extends 'version'
  ? RawVersion
  : Target extends 'action'
  ? RawAction
  : Target extends 'location'
  ? RawLocationResponse
  : Target extends 'locationPermission'
  ? RawLocationPermissionResponse
  : Target extends 'locationEnable'
  ? RawLocationEnableResponse
  : Target extends 'storage'
  ? RawStorage<'Response', SA>
  : Target extends 'keyboard'
  ? RawKeyboard
  : Target extends 'qrCodeData'
  ? RawQRCodeData
  : Target extends 'screenSize'
  ? RawScreenSize
  : never;

export type RawNativeRequestData<
  Target extends NativeCustomEventTarget,
  SA extends StorageAction,
> = Target extends 'location'
  ? RawLocationRequest
  : Target extends 'locationPermission'
  ? RawLocationPermissionRequest
  : Target extends 'locationEnable'
  ? RawLocationEnableRequest
  : Target extends 'storage'
  ? RawStorage<'Request', SA>
  : Target extends 'google-map'
  ? RawGoogleMapRequest
  : Target extends 'link'
  ? RawLinkRequest
  : Target extends 'ssid'
  ? RawSSIDRequest
  : Target extends 'action'
  ? RawAction
  : Target extends 'log'
  ? RawLogRequest
  : Target extends 'dismissSplash'
  ? RawDismissSplash
  : Target extends 'sharedUserRegistrationScreen'
  ? RawShowSharedUserRegistrationScreen
  : never;

type LocaleInfo = {
  readonly target: 'locale';
  readonly locale: Locale;
};

export type Notification = NotificationBase & {
  readonly timestamp: Date;
};

type Version = {
  readonly target: 'version';
  readonly data: {
    readonly version: string;
    readonly buildNumber: string;
    readonly platform: OS;
    readonly osVersion: number;
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LocationRequest = Omit<RawLocationRequest, 'timeout'> & {
  readonly timeout: Duration;
};

type LocationResponse = Omit<RawLocationResponse, 'location' | 'error'> & {
  readonly location: Option<GeoPos>;
  readonly error: Option<Anomaly>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LocationPermissionRequest = RawLocationPermissionRequest;
type LocationPermissionResponse = Omit<
  RawLocationPermissionResponse,
  'error'
> & {
  readonly error: Option<Anomaly>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type LocationEnableRequest = RawLocationEnableRequest;
type LocationEnableResponse = Omit<RawLocationEnableResponse, 'error'> & {
  readonly error: Option<Anomaly>;
};

type Storage<
  Type extends MessageType,
  Action extends StorageAction,
> = Type extends 'Response'
  ? Action extends 'get'
    ? Omit<RawStorage<Type, Action>, 'error' | 'value'> & {
        readonly error: Option<string>;
        readonly value: Option<string>;
      }
    : Omit<RawStorage<Type, Action>, 'error'> & {
        readonly error: Option<string>;
      }
  : Omit<RawStorage<Type, Action>, 'error'> & {
      readonly error: Option<string>;
    };

export type NativeCustomEventData<
  Target extends NativeCustomEventTarget,
  SA extends StorageAction = StorageAction,
> = Target extends 'ssid'
  ? RawSSIDResponse
  : Target extends 'locale'
  ? LocaleInfo
  : Target extends 'notification'
  ? Notification
  : Target extends 'deviceToken'
  ? RawDeviceToken
  : Target extends 'version'
  ? Version
  : Target extends 'action'
  ? RawAction
  : Target extends 'location'
  ? LocationResponse
  : Target extends 'locationPermission'
  ? LocationPermissionResponse
  : Target extends 'locationEnable'
  ? LocationEnableResponse
  : Target extends 'storage'
  ? Storage<'Response', SA>
  : Target extends 'keyboard'
  ? RawKeyboard
  : Target extends 'qrCodeData'
  ? RawQRCodeData
  : Target extends 'screenSize'
  ? RawScreenSize
  : never;

type RawLatestVehicleLocationTable = {
  readonly [vin: string]: GeoPos;
};

type LatestVehicleLocationTable = {
  readonly get: (vin: string) => Option<GeoPos>;
  readonly set: (vin: string, location: GeoPos) => void;
  readonly remove: (vin: string) => void;
  readonly toRaw: () => RawLatestVehicleLocationTable;
};

export const ReactNativeWindow: ReactNativeWindow = Scope(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w = window as any;
  const rn = Option(w.ReactNativeWebView);
  w.reactNativeWebView = rn;
  w.isOnMockDevice = rn.isNone();
  w.webView = rn.unwrap(
    (_: ReactNativeWebView) => _,
    () => MockDevice.get().mockWebView,
  );
  return w;
});

export const NativeCustomEventName = 'NativeCustomEvent';

type Position = {
  readonly coords: {
    readonly latitude: number;
    readonly longitude: number;
  };
};
export function posToGeoPos(pos: Position): GeoPos {
  return {
    lat: pos.coords.latitude,
    lng: pos.coords.longitude,
  };
}

export async function getUserLocation(): Promise<UserLocation> {
  // return Promise.resolve<GeoPos>({
  //   lat: 39.9185658,
  //   lng: -122.8001796,
  //   //lat: -80.9185658,
  //   //lng: 134.8001796,
  // });

  return debugAsync2(
    'Getting the user location',
    () =>
      new Promise<UserLocation>((resolve, reject) => {
        try {
          subscribeNativeCustomEvent({
            removeListener: (data) => data.target === 'location',
          }).onResult(
            async (data) => {
              if (data.target === 'location') {
                if (await MockDevice.get().getLocationError()) {
                  reject(Error(`Failed to get the user location`));
                } else {
                  data.error.unwrap(
                    (e) => {
                      switch (e.code) {
                        case 1:
                          return resolve('NoPermission');
                        case 2:
                          return resolve('Unavailable');
                        default:
                          return reject(e);
                      }
                    },
                    () =>
                      data.location.unwrap(
                        (p) =>
                          data.permission === 'granted'
                            ? resolve(p)
                            : resolve('NoPermission'),
                        () =>
                          data.permission === 'granted'
                            ? reject(
                                Error(
                                  `You are granted but failed to get the user location`,
                                ),
                              )
                            : resolve('NoPermission'),
                      ),
                  );
                }
              }
            },
            (err) => reject(err),
          );

          const m: RawLocationRequest = {
            target: 'location',
            timeout: config.geolocationTimeout.toMilliSeconds(),
          };
          ReactNativeWindow.webView.postMessage(JSON.stringify(m));
        } catch (err) {
          println(`Error on getUserLocation: ${err}`);
          reject(err);
        }
      }),
    (_) =>
      _ === 'NoPermission'
        ? `No permission to get the user location`
        : _ === 'Unavailable'
        ? `GPS is unavailable`
        : `The user location has been got: lat: ${_.lat}, lng: ${_.lng}`,
  );
}

export function requestLocationPermission(): Promise<Unit> {
  return debugAsync2(
    `Sending a location permission request`,
    () =>
      new Promise((resolve, reject) => {
        subscribeNativeCustomEvent({
          removeListener: (data) => data.target === 'locationPermission',
        }).onResult(
          (data) => {
            if (data.target === 'locationPermission')
              data.error.unwrap(
                (e) => reject(e),
                () => resolve(Unit),
              );
          },
          (err) => reject(err),
        );

        const m: RawLocationPermissionRequest = {
          target: 'locationPermission',
        };
        ReactNativeWindow.webView.postMessage(JSON.stringify(m));
      }),
    () => `The location permission has been received`,
  );
}

export function requestLocationEnable(): Promise<Unit> {
  return new Promise((resolve, reject) => {
    subscribeNativeCustomEvent({
      removeListener: (data) => data.target === 'locationEnable',
    }).onResult(
      (data) => {
        if (data.target === 'locationEnable')
          data.error.unwrap(
            (e) => reject(e),
            () => resolve(Unit),
          );
      },
      (err) => reject(err),
    );

    const m: RawLocationEnableRequest = {
      target: 'locationEnable',
    };
    ReactNativeWindow.webView.postMessage(JSON.stringify(m));
  });
}

export function setAuthInfo(authInfo: AuthInfo | null): Promise<void> {
  if (authInfo === null)
    return removeStorageItem(StorageKeys.authInfo).then((_) => {
      return;
    });
  else
    return setStorageItem(StorageKeys.authInfo, JSON.stringify(authInfo)).then(
      (_) => {
        return;
      },
    );
}

export function getAuthInfo(): Promise<Option<AuthInfo>> {
  return getStorageItem(StorageKeys.authInfo).then((_) =>
    _.flatMap((_) => {
      const a = JSON.parse(_);
      if (BuildOpt.isMock() && a.isSignedIn === undefined) return None();
      else if (BuildOpt.isMock() && !isValidAuthInfo(a)) return None();
      else return Some(a as AuthInfo);
    }),
  );
}

export async function getLatestVehicleLocationTable(): Promise<LatestVehicleLocationTable> {
  function fromMap(map: Map<string, GeoPos>): LatestVehicleLocationTable {
    return {
      get: (vin: string) => Option(map.get(vin)),
      set: (vin: string, location: GeoPos) => map.set(vin, location),
      remove: (vin: string) => map.delete(vin),
      toRaw: () =>
        Array.from(map.keys()).reduce((acc, key) => {
          const _acc = acc as { [vin: string]: GeoPos | undefined };
          _acc[key] = map.get(key);
          return acc;
        }, {} as RawLatestVehicleLocationTable),
    };
  }
  return (await getStorageItem(StorageKeys.latestVehicleLocation)).unwrap<
    Promise<LatestVehicleLocationTable>
  >(
    async (v) => {
      const map = new Map<string, GeoPos>();
      const raw = JSON.parse(v) as RawLatestVehicleLocationTable;
      Object.keys(raw).forEach((vin: string) => {
        map.set(vin, raw[vin]);
      });
      return fromMap(map);
    },
    async () => {
      const map = new Map<string, GeoPos>();
      return fromMap(map);
    },
  );
}

export async function setLatestVehicleLocation(
  vin: string,
  location: GeoPos,
): Promise<Unit> {
  const table = await getLatestVehicleLocationTable();
  table.set(vin, location);
  const value = JSON.stringify(table.toRaw());
  return setStorageItem(StorageKeys.latestVehicleLocation, value);
}

export async function removeLatestVehicleLocation(vin: string): Promise<Unit> {
  const table = await getLatestVehicleLocationTable();
  table.remove(vin);
  const value = JSON.stringify(table.toRaw());
  return setStorageItem(StorageKeys.latestVehicleLocation, value);
}

export function setDeviceToken(deviceToken: string | null): Promise<void> {
  if (deviceToken === null)
    return removeStorageItem(StorageKeys.deviceToken).then((_) => {
      return;
    });
  else
    return setStorageItem(StorageKeys.deviceToken, deviceToken).then((_) => {
      return;
    });
}

export const getDeviceToken = (): Promise<Option<string>> =>
  getStorageItem(StorageKeys.deviceToken).catch(() => None());

export function setStorageItem(key: StorageKey, value: string): Promise<Unit> {
  return new Promise<Unit>((resolve, reject) => {
    const id = uuid();
    subscribeNativeCustomEvent({
      removeListener: (data) =>
        data.target === 'storage' && data.action === 'set' && data.id === id,
    }).onResult(
      (data) =>
        data.target === 'storage' && data.action === 'set' && data.id === id
          ? data.error.unwrap(
              (e) => reject(Error(e)),
              () => resolve(Unit),
            )
          : Unit,
      (err) => reject(err),
    );

    const m: RawStorage<'Request', 'set'> = {
      target: 'storage',
      action: 'set',
      id,
      key,
      value,
    };
    ReactNativeWindow.webView.postMessage(JSON.stringify(m));
  });
}

export function getStorageItem(key: StorageKey): Promise<Option<string>> {
  return new Promise<Option<string>>((resolve, reject) => {
    const id = uuid();
    subscribeNativeCustomEvent({
      removeListener: (data) =>
        data.target === 'storage' && data.action === 'get' && data.id === id,
    }).onResult(
      (data) =>
        data.target === 'storage' && data.action === 'get' && data.id === id
          ? data.error.unwrap(
              (e) => reject(Error(e)),
              () => resolve(data.value),
            )
          : Unit,
      (err) => reject(err),
    );

    const m: RawStorage<'Request', 'get'> = {
      target: 'storage',
      action: 'get',
      id,
      key,
    };
    ReactNativeWindow.webView.postMessage(JSON.stringify(m));
  });
}

export function removeStorageItem(key: StorageKey): Promise<Unit> {
  return new Promise<Unit>((resolve, reject) => {
    const id = uuid();
    subscribeNativeCustomEvent({
      removeListener: (data) =>
        data.target === 'storage' && data.action === 'remove' && data.id === id,
    }).onResult(
      (data) =>
        data.target === 'storage' && data.action === 'remove' && data.id === id
          ? data.error.unwrap(
              (e) => reject(Error(e)),
              () => resolve(Unit),
            )
          : Unit,
      (err) => reject(err),
    );

    const m: RawStorage<'Request', 'remove'> = {
      target: 'storage',
      action: 'remove',
      id,
      key,
    };
    ReactNativeWindow.webView.postMessage(JSON.stringify(m));
  });
}

export function openGoogleMap(pos: GeoPos): void {
  const url = `https://www.google.com/maps/@?api=1&map_action=map&center=${pos.lat},${pos.lng}`;
  ReactNativeWindow.webView.postMessage(
    JSON.stringify({
      target: 'google-map',
      url,
    }),
  );
}

export function openLink(
  url: string,
  title?: string,
  openType?: OpenType,
): void {
  ReactNativeWindow.webView.postMessage(
    JSON.stringify({
      target: 'link',
      url,
      title,
      openType,
    }),
  );
}

export function nativeBack(): void {
  ReactNativeWindow.webView.postMessage(
    JSON.stringify({
      target: 'action',
      action: 'exit',
    }),
  );
}

export function dismissSplash(): void {
  const req: RawDismissSplash = {
    target: 'dismissSplash',
  };
  ReactNativeWindow.webView.postMessage(JSON.stringify(req));
}

export function showSharedUserRegistrationScreen(): void {
  const req: RawShowSharedUserRegistrationScreen = {
    target: 'sharedUserRegistrationScreen',
    action: 'show',
  };
  ReactNativeWindow.webView.postMessage(JSON.stringify(req));
}

export function hideSharedUserRegistrationScreen(): void {
  const req: RawShowSharedUserRegistrationScreen = {
    target: 'sharedUserRegistrationScreen',
    action: 'hide',
  };
  ReactNativeWindow.webView.postMessage(JSON.stringify(req));
}

export function println(
  message: string | Error | Anomaly,
  isDebug = false,
): void {
  if (ReactNativeWindow.isOnMockDevice) {
    if (isDebug) {
      console.debug(message);
    } else {
      console.log(message);
    }
  } else {
    ReactNativeWindow.webView.postMessage(
      JSON.stringify({
        target: 'log',
        data: getErrorMessage(message),
        isDebug,
      }),
    );
  }
}

export function sendNativeEvent<
  Target extends NativeCustomEventTarget,
  SA extends StorageAction = StorageAction,
>(data: RawNativeCustomEventData<Target, SA>): void {
  window.dispatchEvent(
    new CustomEvent(NativeCustomEventName, { detail: { data } }),
  );
}

export function sendNotificationEvent(data: RawNotification): void {
  sendNativeEvent(data);
  if (data.foreground === false)
    sendNativeEvent<'action'>({
      target: 'action',
      action: 'foreground',
    });
}

export function notifyListenReady(targets: NativeCustomEventTarget[]): void {
  ReactNativeWindow.webView.postMessage(
    JSON.stringify({
      event: 'listen',
      status: 'ready',
      targets,
    }),
  );
}

export function notifyListenReadyOnInitialize(
  targets: NativeCustomEventTarget[],
): Promise<Platform> {
  return new Promise<Platform>((resolve, reject) => {
    subscribeNativeCustomEvent({
      removeListener: (data) => data.target === 'version',
    }).onResult(
      (data) =>
        data.target === 'version'
          ? resolve({
              appVersion: data.data.version,
              buildNumber: data.data.buildNumber,
              os: data.data.platform,
              osVersion: data.data.osVersion,
            })
          : Unit,
      (err) => reject(err),
    );

    notifyListenReady(targets);
  });
}

export function subscribeNativeCustomEvent(
  options: {
    readonly removeListener?: (
      data: NativeCustomEventData<NativeCustomEventTarget, StorageAction>,
    ) => boolean;
  } = {},
): Listener<NativeCustomEventData<NativeCustomEventTarget, StorageAction>> {
  return Listener((dispatch) => {
    function callback(_: Event): void {
      const event = _ as unknown as RawNativeCustomEvent<
        NativeCustomEventTarget,
        StorageAction
      >;
      const data: Option<
        NativeCustomEventData<NativeCustomEventTarget, StorageAction>
      > = Scope(() => {
        if (event.detail?.data) {
          type R = NativeCustomEventData<
            NativeCustomEventTarget,
            StorageAction
          >;
          const raw = event.detail.data;
          println(
            `An NativeEvent has been received: ${JSON.stringify(raw)}`,
            true,
          );
          switch (raw.target) {
            case 'notification':
              return Some<R>({
                ...raw,
                timestamp: new Date(raw.timestamp),
              });

            case 'version':
              return Some<R>({
                ...raw,
                data: {
                  ...raw.data,
                  osVersion: parseFloat(raw.data.osVersion || '0'),
                },
              });

            case 'locale':
              return Some<R>({
                ...raw,
                locale: parseLocale(raw.locale),
              });

            case 'location':
              return Some<R>({
                ...raw,
                location: Option(raw.location),
                error: Option(raw.error).map((_) =>
                  Anomaly.fromJson(_).unwrap(
                    (_) => _,
                    (err) => Anomaly(0, getErrorMessage(err)),
                  ),
                ),
              });

            case 'locationPermission':
              return Some<R>({
                ...raw,
                error: Option(raw.error).map((_) =>
                  Anomaly.fromJson(_).unwrap(
                    (_) => _,
                    (err) => Anomaly(0, getErrorMessage(err)),
                  ),
                ),
              });

            case 'locationEnable':
              return Some<R>({
                ...raw,
                error: Option(raw.error).map((_) =>
                  Anomaly.fromJson(_).unwrap(
                    (_) => _,
                    (err) => Anomaly(0, getErrorMessage(err)),
                  ),
                ),
              });

            case 'storage':
              switch (raw.action) {
                case 'get': {
                  const _raw = raw as RawStorage<'Response', 'get'>;
                  return Some<R>({
                    ..._raw,
                    value: Option(_raw.value),
                    error: Option(_raw.error),
                  });
                }
                default:
                  return Some({
                    ...raw,
                    error: Option(raw.error),
                  } as R);
              }

            default:
              return Some<R>(event.detail.data as R);
          }
        } else {
          return None();
        }
      });
      data.forEach((data) => {
        dispatch(Success(data));
        if (options.removeListener?.(data)) {
          window.removeEventListener(NativeCustomEventName, callback);
        }
      });
    }

    window.addEventListener(NativeCustomEventName, callback);
  });
}
