import React from 'react';
import { config } from '../../Config';
import { VehicleEvent } from '../../apis/EventTelemetry';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { copy } from '../../util/Copyable';
import { Some } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Card, CardState } from '../components/Card';
import { EngineController } from '../components/EngineController';
import { HelpDialog } from '../components/HelpDialog';
import { Shape } from '../components/Shape';
import { SlideInCardState } from '../components/SlideInCard';
import { SlideInWindowMode } from '../components/SlideInWindow';
import { Fade, FadeOff } from '../effects/Fade';
import { getLastUpdate } from '../home/Helpers';
import { HomeData, HomeDataMap } from '../home/Home';
import { MessageKeys, useIntl } from '../i18n/Intl';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import { ExtendParent, WithContext, useStore } from '../states/DataMapStore';
import { Action, ExtractLocalState } from '../states/Reducer';
import { Store } from '../states/Store';
import { Bound } from '../style/Bound';
import { Length } from '../style/Length';
import { defaultVehicleEvent } from '../vehicle-alert/Helpers';
import { getOverlayState, getRemoteState, toRemoteStates } from './Helpers';
import { MainView } from './MainView';
import { RemoteControllerPane } from './RemoteControllerPane';
import { ControllerState, WarningHelp } from './Types';
import { VehicleEventDetailWindow } from './VehicleEventDetailWindow';
import { defaultWarningHelp } from './WarningLights';

type Key = 'vehicleInfo';
export type VehicleInfoCardData = Data;
export type VehicleInfoCardDataMap = DM;

type Data = Readonly<{
  kind: 'CarStateCardData';
  localState: LocalState;
  container: Shape;
  gasContainer: Shape;
  doorLockContainer: Shape;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly cardState: CardState;
  readonly gasFade: ExtractLocalState<Fade>;
  readonly doorLockFade: ExtractLocalState<Fade>;
  readonly controllerState: ControllerState;
  readonly selectedVehicleEvent: VehicleEvent;
  readonly vehicleEventWindowMode: SlideInWindowMode;
  readonly engineControllerState: SlideInCardState;
  readonly isEngineControllerSpinning: boolean;
  readonly warningHelp: WarningHelp;
  readonly toastMessageId: MessageKeys;
  readonly isOpen: boolean;
  readonly badgeBound: Bound;
}

interface Actions extends CommandActions<Data, Command> {
  readonly open: (data: Data) => Data;
  readonly close: (data: Data) => Data;
  readonly updateCardState: (data: Data, cardState: CardState) => Data;
  readonly updateControllerState: (
    data: Data,
    controllerState: ControllerState,
  ) => Data;
  readonly updateSelectedVehicleEvent: (
    data: Data,
    args: {
      selectedVehicleEvent?: VehicleEvent;
      vehicleEventWindowMode: SlideInWindowMode;
    },
  ) => Data;
  readonly updateEngineControllerState: (
    data: Data,
    engineControllerState: SlideInCardState,
  ) => Data;
  readonly updateIsEngineControllerSpinning: (
    data: Data,
    isEngineControllerSpinning: boolean,
  ) => Data;
  readonly updateWarningHelp: (data: Data, warningCommand: WarningHelp) => Data;
  readonly updateBadgeBound: (data: Data, badgeBound: Bound) => Data;
  readonly updateToastMessageId: (
    data: Data,
    toastMessageId: MessageKeys,
  ) => Data;
}

type Command = 'None';

type Props = Readonly<{
  store: Store<HomeData>;
}>;

type DM = ExtendParent<HomeDataMap, Key, Data>;

const commandState = CommandState<Data, Command>();

function toGasFade(data: Data): Fade {
  return {
    localState: data.localState.gasFade,
    target: data.gasContainer,
  };
}

function toDoorLockFade(data: Data): Fade {
  return {
    localState: data.localState.doorLockFade,
    target: data.doorLockContainer,
  };
}

function fromGasFade(data: Data, gasFade: Fade): Data {
  return copy(data, {
    localState: {
      gasFade: gasFade.localState,
    },
    gasContainer: gasFade.target,
  });
}

function fromDoorLockFade(data: Data, doorLockFade: Fade): Data {
  return copy(data, {
    localState: {
      doorLockFade: doorLockFade.localState,
    },
    doorLockContainer: doorLockFade.target,
  });
}

export const VehicleInfoCardActions: Actions = {
  ...commandState.actions,

  open: (data: Data) =>
    Pipe(fromGasFade(data, FadeOff.actions.open(toGasFade(data))))
      .map((data) =>
        fromDoorLockFade(data, FadeOff.actions.open(toDoorLockFade(data))),
      )
      .map((data) => {
        return copy(data, {
          localState: {
            isOpen: true,
          },
          container: data.container.updateStyles({
            padding: Length.px(0),
          }),
        });
      })
      .get(),

  close: (data: Data) =>
    Pipe(fromGasFade(data, FadeOff.actions.close(toGasFade(data))))
      .map((data) =>
        fromDoorLockFade(data, FadeOff.actions.close(toDoorLockFade(data))),
      )
      .map((data) => {
        return copy(data, {
          localState: {
            isOpen: false,
          },
          container: data.container.updateStyles({
            paddingTop: Length.px(9),
            paddingBottom: Length.px(8),
          }),
        });
      })
      .get(),

  updateCardState: (data: Data, cardState: CardState) =>
    copy(data, {
      localState: { cardState },
    }),

  updateControllerState: (data: Data, controllerState: ControllerState) =>
    copy(data, {
      localState: { controllerState },
    }),

  updateSelectedVehicleEvent: (
    data: Data,
    args: {
      selectedVehicleEvent?: VehicleEvent;
      vehicleEventWindowMode: SlideInWindowMode;
    },
  ) => copy(data, { localState: { ...args } }),

  updateEngineControllerState: (
    data: Data,
    engineControllerState: SlideInCardState,
  ) =>
    copy(data, {
      localState: { engineControllerState },
    }),

  updateIsEngineControllerSpinning: (
    data: Data,
    isEngineControllerSpinning: boolean,
  ) =>
    copy(data, {
      localState: { isEngineControllerSpinning },
    }),

  updateWarningHelp: (data: Data, warningHelp: WarningHelp) =>
    copy(data, {
      localState: { warningHelp },
    }),

  updateToastMessageId: (data: Data, toastMessageId: MessageKeys) =>
    copy(data, {
      localState: { toastMessageId },
    }),

  updateBadgeBound: (data: Data, badgeBound: Bound) =>
    copy(data, {
      localState: { badgeBound },
    }),
};

const actions = VehicleInfoCardActions;

export function VehicleInfoCard(props: Props): React.ReactElement {
  const state = useStore<Key, Data, HomeDataMap, Props>({
    key: 'vehicleInfo',
    props,
    parent: { home: props.store },

    default: () => {
      const gasFade = FadeOff(
        Shape({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      );

      const doorLockFade = FadeOff(
        Shape({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      );

      return {
        kind: 'CarStateCardData',
        localState: {
          command: commandState.default,
          cardState: 'Closed',
          gasFade: gasFade.localState,
          doorLockFade: doorLockFade.localState,
          controllerState: {
            state: 'Hide',
            itemName: 'DoorLock',
          },
          selectedVehicleEvent: defaultVehicleEvent,
          vehicleEventWindowMode: 'Hide',
          engineControllerState: 'Hide',
          isEngineControllerSpinning: false,
          warningHelp: defaultWarningHelp,
          toastMessageId: 'RemoteControlResult_doorLockedMsg',
          isOpen: false,
          badgeBound: Bound(0, 0, 0, 0),
        },
        container: Shape({
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: Length.px(200),
          paddingTop: Length.px(9),
          paddingBottom: Length.px(8),
        }),
        gasContainer: gasFade.target,
        doorLockContainer: doorLockFade.target,
      };
    },

    initialize: (data: Data) =>
      Pipe(
        fromGasFade(
          data,
          Pipe(toGasFade(data))
            .map((data) => FadeOff.actions.updateDefault(data))
            .map((data) => FadeOff.initialize(data))
            .get(),
        ),
      )
        .map((data) =>
          fromDoorLockFade(
            data,
            Pipe(toDoorLockFade(data))
              .map((data) => FadeOff.actions.updateDefault(data))
              .map((data) => FadeOff.initialize(data))
              .get(),
          ),
        )
        .get(),

    update: ({ data: { context, vehicleInfo: data }, dispatch }) => {
      const localState = data.localState;
      return commandState
        .handleDataMap<WithContext<DM>>(
          'vehicleInfo',
          data,
          dispatch,
          (_command) => dispatch,
        )
        .pipe((_) => {
          if (
            context.nativeBackAction === 'InCard' &&
            localState.cardState === 'Opened'
          ) {
            const none = _.context.bind(
              Context.actions.updateNativeBackAction,
              'None',
            );
            if (
              context.alertDialog.state === 'Hide' &&
              context.confirmDialog.state === 'Hide'
            ) {
              if (localState.controllerState.state === 'Show') {
                return none.vehicleInfo(actions.updateControllerState, {
                  state: 'Hide',
                  itemName: localState.controllerState.itemName,
                });
              } else if (localState.vehicleEventWindowMode === 'Show') {
                return none.vehicleInfo(actions.updateSelectedVehicleEvent, {
                  vehicleEventWindowMode: 'Hide',
                });
              } else if (localState.engineControllerState === 'Show') {
                return none.vehicleInfo(
                  actions.updateEngineControllerState,
                  'Hide',
                );
              } else if (localState.warningHelp.state === 'Show') {
                return none.vehicleInfo(
                  actions.updateWarningHelp,
                  copy(localState.warningHelp, {
                    state: 'Hide',
                  }),
                );
              } else {
                return _.context.bind(
                  Context.actions.updateNativeBackAction,
                  'TopLevel',
                );
              }
            } else {
              return none;
            }
          } else {
            return _;
          }
        });
    },
  });

  const Container = state.views.vehicleInfo.container;

  const intl = useIntl();
  const data = state.data.vehicleInfo;
  const context = state.data.context;
  const localState = data.localState;
  const warningHelp = localState.warningHelp;
  const remoteStates = toRemoteStates(
    context.serviceTelemetry,
    context.ongoingRemoteStates,
  );
  const overlayState = getOverlayState(
    localState.controllerState,
    localState.warningHelp.state,
    localState.engineControllerState,
  );
  const isDataError =
    context.dataErrors.serviceTelemetry || context.dataErrors.vehicleEvents;

  return (
    <Card
      id='VehicleInfoCard'
      cardName='VehicleInfo'
      parent={state.stores}
      badgeName={intl.formatMessage({ id: 'VehicleInfo_pageTitle' })}
      title={intl.formatMessage({ id: 'VehicleInfo_pageTitle' })}
      lastUpdate={getLastUpdate(context)}
      reloadable={true}
      reloadOnOpen={config.fetchDataOptions.onOpenVehicleInfo}
      openable={!isDataError}
      contentState={context.contentState}
      overlayState={overlayState}
      disableSpinnerOnOpen
      onCardStateChanged={(cardState, dispatch) =>
        dispatch.vehicleInfo(actions.updateCardState, cardState)
      }
      onOpen={(dispatch) => dispatch.vehicleInfo(actions.open)}
      onClose={(dispatch) => dispatch.vehicleInfo(actions.close)}
      onReload={(dispatch) =>
        dispatch
          .context(Context.actions.updateContentState, 'Reload')
          .context(
            Context.actions.updateFetchVehicleDataOptions,
            config.fetchDataOptions.onReloadVehicleInfo,
          )
      }
      onClickOverride={
        !isServiceAllowed(context, 'meterVehicleConditionMonitoring') &&
        !isServiceAllowed(context, 'bodyVehicleConditionMonitoring')
          ? (dispatch) => dispatch.context.bind(userRestrictionAction(context))
          : undefined
      }
      onBadgeBound={(bound, dispatch) =>
        dispatch.vehicleInfo(actions.updateBadgeBound, bound)
      }
      extras={
        <>
          <VehicleEventDetailWindow
            parent={state.stores}
            mode={localState.vehicleEventWindowMode}
            event={localState.selectedVehicleEvent}
            onBack={(dispatch) =>
              dispatch.vehicleInfo(actions.updateSelectedVehicleEvent, {
                vehicleEventWindowMode: 'Hide',
              })
            }
            onClose={(dispatch) =>
              dispatch.context
                .bind(Context.actions.updateCardInfo, {
                  state: 'Closing',
                })
                .vehicleInfo(actions.updateSelectedVehicleEvent, {
                  vehicleEventWindowMode: 'Close',
                })
            }
          />

          <RemoteControllerPane
            parent={state.stores}
            remoteState={getRemoteState(
              remoteStates,
              localState.controllerState.itemName,
            )}
            state={localState.controllerState}
            onOpen={(dispatch) => dispatch}
            onClose={(dispatch) =>
              dispatch.vehicleInfo(actions.updateControllerState, {
                state: 'Hide',
                itemName: localState.controllerState.itemName,
              })
            }
          />

          <HelpDialog
            parent={state.stores}
            title={intl.formatMessage({ id: warningHelp.titleId })}
            message={intl.formatMessage({
              id: warningHelp.helpMessageId,
            })}
            state={warningHelp.state}
            onClose={(dispatch) =>
              dispatch.vehicleInfo(
                actions.updateWarningHelp,
                copy(warningHelp, {
                  state: 'Hide',
                }),
              )
            }
          />

          <EngineController
            store={state.stores.vehicleInfo}
            state={localState.engineControllerState}
            isSpinning={localState.isEngineControllerSpinning}
            closeAction={Action(actions.updateEngineControllerState, 'Hide')}
            toastMessageId={(commandAction) => {
              switch (commandAction) {
                case 'true':
                  return Some<MessageKeys>(
                    'Event_remoteEngineProhibitOnSuccessRemoteControl',
                  );
                case 'false':
                  return Some<MessageKeys>(
                    'Event_remoteEngineProhibitOffSuccessRemoteControl',
                  );
              }
            }}
            updateIsSpinningAction={actions.updateIsEngineControllerSpinning}
            updateControllerStateAction={actions.updateEngineControllerState}
          />
        </>
      }
    >
      <Container>
        <MainView
          parent={state.stores}
          badgeBound={localState.badgeBound}
          isDataError={isDataError}
        />
      </Container>
    </Card>
  );
}
