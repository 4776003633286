import { Env } from '../Config';

type BuildOptMode =
  | 'development' // npm run start (connect to localhost:3000
  | 'production' // npm run build (connect to a external server)
  | 'test';

export const BuildOpt = {
  env: (process.env.REACT_APP_BUILD_OPT as Env | undefined) ?? 'mock',
  mode: process.env.NODE_ENV as BuildOptMode,

  isMock(): boolean {
    return this.env === 'mock' || this.env === 'mockp';
  },
};
