import { Animation } from '../style/Animation';
import { OptionalStyles } from '../style/Style';

export type IconProps = {
  readonly style?: OptionalStyles;
  readonly animate?: boolean;
};

export function animation(
  animation: Animation,
  animate?: boolean,
): OptionalStyles {
  if (animate === true) return { animation };
  else return {};
}
