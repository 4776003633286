import React from 'react';
import {
  getSelectedVehicle,
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { useIntl } from '../i18n/Intl';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { CarImageContainer } from './CarImageContainer';
import { FuelMileageList } from './FuelMileageList';
import { toFuelMileage, toRemoteStates } from './Helpers';
import { GasGaugePane } from './MainView';
import { RemoteStateList } from './RemoteStateList';
import {
  VehicleInfoCardActions,
  VehicleInfoCardDataMap,
} from './VehicleInfoCard';
import { WarningLights } from './WarningLights';

function EngineProhibitContainer({
  parent,
  isOpen,
}: Readonly<{
  parent: DataMapStore<VehicleInfoCardDataMap>;
  isOpen: boolean;
}>): React.ReactElement {
  const {
    views: { View, Image, Text },
    data: { context },
    dispatch,
  } = useParentStore({ parent });
  const Container = View;
  const actions = VehicleInfoCardActions;
  const engineProhibit = context.serviceTelemetry.data
    .toOption()
    .some((_) => _.dsc_eng_prohibit);
  const intl = useIntl();
  const isSpinning = getSelectedVehicle(context)
    .flatMap((v) => Option(context.ongoingRemoteStates[v.vin]))
    .some((s) => s.engineProhibit === true);

  if (isOpen && engineProhibit) {
    return (
      <Container
        style={{
          display: 'flex',
        }}
        onClick={() =>
          dispatch.vehicleInfo(actions.updateEngineControllerState, 'Show')
        }
      >
        <View
          style={{
            paddingTop: Length.px(12),
            paddingRight: Length.px(10),
            paddingBottom: Length.px(12),
            paddingLeft: Length.px(10),
          }}
        >
          <Image
            style={{
              width: Length.px(40),
              height: Length.px(40),
            }}
            src={Icons.ic_enginelock_active}
          />
        </View>

        <View
          style={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: Border('solid', Length.px(0.5), Colors.border),
          }}
        >
          <View
            style={{
              paddingTop: Length.px(22),
              paddingBottom: Length.px(22),
            }}
          >
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: Length.px(16),
                lineHeight: Length.px(16),
                color: Colors.buttonText,
                backgroundColor: Colors.primary,
                borderRadius: Length.px(2),
                padding: Length.px(2),
              }}
            >
              {intl
                .formatMessage({
                  id: engineProhibit
                    ? 'VehicleAlert_engineProhibitOff'
                    : 'VehicleAlert_engineProhibitOn',
                })
                .toReactNode()}
            </Text>
          </View>

          <View
            style={{
              paddingRight: Length.px(8),
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {isSpinning ? (
              <IcWaitingRed
                animate
                style={{
                  width: Length.px(32),
                  height: Length.px(32),
                }}
              />
            ) : (
              <Image src={Icons.ic_action} />
            )}
          </View>
        </View>
      </Container>
    );
  } else {
    return <></>;
  }
}

export function OpenedContainer({
  parent,
}: Readonly<{
  parent: DataMapStore<VehicleInfoCardDataMap>;
}>): React.ReactElement {
  const {
    views: { View },
    data: { context, vehicleInfo },
    dispatch,
  } = useParentStore({ parent });
  const Container = View;
  const RemoteContainer = View;
  const actions = VehicleInfoCardActions;
  const localState = vehicleInfo.localState;
  const cardState = localState.cardState;
  const isOpen = cardState === 'Opened' || cardState === 'Opening';
  const gasGauge = <GasGaugePane />;
  const fuelMileage = toFuelMileage(context.serviceTelemetry);
  const remoteStates = toRemoteStates(
    context.serviceTelemetry,
    context.ongoingRemoteStates,
  );

  return (
    <Container
      style={{
        position: 'absolute',
        top: Length.px(isOpen ? 0 : 20),
        width: Percentage(100),
      }}
    >
      <View style={{ display: isOpen ? 'block' : 'none' }}>
        <WarningLights parent={parent} />
      </View>

      <EngineProhibitContainer parent={parent} isOpen={isOpen} />

      <RemoteContainer
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <RemoteStateList
          parent={parent}
          isOpen={isOpen}
          remoteStates={remoteStates}
          onClick={(_evt, cmd) => {
            const restricted = Scope(() => {
              switch (cmd.itemName) {
                case 'DoorLock':
                case 'Hazard':
                case 'Light':
                  return !isServiceAllowed(context, 'remoteBodyControl');
                case 'Ac':
                case 'Engine':
                  return !isServiceAllowed(context, 'remoteStart');
              }
            });
            if (restricted) {
              return dispatch.context.bind(userRestrictionAction(context));
            } else {
              return dispatch.vehicleInfo(actions.updateControllerState, cmd);
            }
          }}
        />

        <CarImageContainer isOpen={isOpen} />
      </RemoteContainer>

      <View style={{ display: isOpen ? 'block' : 'none' }}>
        <FuelMileageList
          gasGauge={gasGauge}
          range={fuelMileage.range}
          mileage={fuelMileage.mileage}
          fuelEconomy={fuelMileage.fuelEconomy}
        />
      </View>
    </Container>
  );
}
