import React from 'react';
import { useContextStore } from '../states/ContextStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

export function Subtitle({
  title,
}: Readonly<{ title: string }>): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();

  return (
    <View
      style={{
        padding: Length.px(10),
        borderBottom: Border('solid', Length.px(1), Colors.background),
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Text
        style={{
          fontSize: Length.px(20),
          fontWeight: 'bold',
        }}
      >
        {title}
      </Text>
    </View>
  );
}
