import { None, Option, Some } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Unit } from '../../util/Unit';
import { ActionBinder } from './ActionBinder';
import { Action } from './Reducer';
import { Dispatchable, Dispatcher } from './Store';

export type ItemType = 'State' | 'Event';

export interface ComposableItem<R> {
  readonly compose: (that: R) => R;
}

export interface DispatchItem<
  IT extends ItemType,
  Data,
  R extends DispatchItem<IT, Data, R>,
> extends ComposableItem<R> {
  readonly itemType: IT;
  readonly dispatch: (store: Dispatchable<Data>) => void;
  readonly forEach: (
    f: <Args>(action: Action<Data, Args>, args: Args) => void,
  ) => void;
  readonly bind: <Args>(action: Action<Data, Args>, args: Args) => R;
  readonly flatMap: (
    f: <Args>(action: Action<Data, Args>, args: Args) => R,
  ) => R;
  readonly isNone: boolean;
}

export interface DispatchItemState<Data>
  extends DispatchItem<'State', Data, DispatchItemState<Data>> {
  readonly dispatchAsync: (store: Dispatchable<Data>) => void;
}

export interface DispatchItemAsync<Data>
  extends DispatchItem<'Event', Data, DispatchItemAsync<Data>> {
  readonly async: (
    f: (dispatch: Dispatcher<Data>) => void,
  ) => DispatchItemAsync<Data>;
  readonly put: <Args>(
    action: Action<Data, Args>,
    args: Args,
  ) => DispatchItemAsync<Data>;
}

export function createDispatchItemState<Data, Args>(
  action: Action<Data, Args>,
  args: Args,
): DispatchItemState<Data> {
  const self: DispatchItemState<Data> = {
    itemType: 'State',

    dispatch: (store: Dispatchable<Data>) => store.dispatch(action, args),

    dispatchAsync: (store: Dispatchable<Data>) =>
      store.dispatchAsync(action, args),

    forEach: (f: <Args>(action: Action<Data, Args>, args: Args) => void) =>
      f(action, args),

    bind: <Args2>(action2: Action<Data, Args2>, args2: Args2) =>
      self.flatMap((action, args) =>
        createDispatchItemState(
          (data, args2) =>
            Pipe(action(data, args))
              .map((data) => action2(data, args2))
              .get(),
          args2,
        ),
      ),

    flatMap: (
      f: (action: Action<Data, Args>, args: Args) => DispatchItemState<Data>,
    ) => f(action, args),

    compose: (that: DispatchItemState<Data>) =>
      that.flatMap((action, args) => {
        return self.bind(action, args);
      }),

    isNone: false,
  };
  return self;
}

export function createDispatchItemEvent<Data>(
  listener: (dispatch: Dispatcher<Data>) => void,
): DispatchItemAsync<Data> {
  let actionBinder: Option<ActionBinder<Data>> = None();
  let dispatcher: Option<Dispatcher<Data>> = None();

  function setDispatcher(cb: Dispatcher<Data>): void {
    dispatcher = Some(cb);
    actionBinder.forEach((a) => cb(a, Unit));
    actionBinder = None();
  }

  listener((action, args) => {
    dispatcher.onResult(
      (cb) => cb(action, args),
      () => (actionBinder = Some(Action.bind(action, args))),
    );
  });

  const self: DispatchItemAsync<Data> = {
    itemType: 'Event',

    dispatch: (store) => self.forEach(store.dispatchAsync),

    forEach: (f: <Args>(action: Action<Data, Args>, args: Args) => void) =>
      setDispatcher(f),

    bind: <Args2>(action2: Action<Data, Args2>, args2: Args2) =>
      self.flatMap((action, args) =>
        createDispatchItemEvent((dispatch) => {
          dispatch(
            (data, args2) =>
              Pipe(action(data, args))
                .map((data) => action2(data, args2))
                .get(),
            args2,
          );
        }),
      ),

    flatMap: (
      g: <Args>(
        action: Action<Data, Args>,
        args: Args,
      ) => DispatchItemAsync<Data>,
    ) =>
      createDispatchItemEvent((dispatch) => {
        setDispatcher(<Args>(action: Action<Data, Args>, args: Args) => {
          g(action, args).forEach(dispatch);
        });
      }),

    async: (f: (dispatch: Dispatcher<Data>) => void) =>
      createDispatchItemEvent((dispatch) => {
        setDispatcher(dispatch);
        f(dispatch);
      }),

    put: <Args>(action: Action<Data, Args>, args: Args) =>
      self.async((dispatch) => {
        dispatch(action, args);
      }),

    compose: (that: DispatchItemAsync<Data>) =>
      self.async((dispatch) => {
        that.forEach(dispatch);
      }),

    isNone: false,
  };
  return self;
}
