import React from 'react';
import { LineSpace } from '../components/LineSpace';
import { Table2x2 } from '../components/Table2x2';
import { useIntl } from '../i18n/Intl';
import { useContextStore } from '../states/ContextStore';
import { Length } from '../style/Length';

export function ClosedView(): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();
  const intl = useIntl();

  return (
    <>
      <LineSpace height={Length.px(40)} />
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: Length.px(8),
          paddingRight: Length.px(8),
        }}
      >
        <Text
          style={{
            fontSize: Length.px(12),
          }}
        >
          {intl.formatMessage({ id: 'Remainder_pageSubTitle1' }).toReactNode()}
        </Text>
      </View>

      <LineSpace height={Length.px(8)} />

      <View
        style={{
          paddingLeft: Length.px(8),
          paddingRight: Length.px(8),
        }}
      >
        <Table2x2
          leftTitle={intl.formatMessage({ id: 'Remainder_day' }).toString()}
          leftBody={intl.formatDate(new Date())}
          rightTitle={intl
            .formatMessage({ id: 'Remainder_odoMeter' })
            .toString()}
          rightBody={`${(10050 as number).toLocaleString('en-US')} km`}
          centerMargin={8}
        />
      </View>

      <LineSpace height={Length.px(8)} />
    </>
  );
}
