import { StorageKeys } from '../apis/StorageKey';

type VIN = string;
export type CarNickName = Record<VIN, string>;

type GlobalUserId = string;
export type UserNickName = Record<GlobalUserId, string>;

export function getCarNickName(): CarNickName {
  return JSON.parse(localStorage.getItem(StorageKeys.carNickName) ?? '{}');
}

export function setCarNickName(nickName: CarNickName): void {
  localStorage.setItem(StorageKeys.carNickName, JSON.stringify(nickName));
}

export function getUserNickName(): UserNickName {
  return JSON.parse(localStorage.getItem(StorageKeys.userNickName) ?? '{}');
}

export function setUserNickName(nickName: UserNickName): void {
  localStorage.setItem(StorageKeys.userNickName, JSON.stringify(nickName));
}
