import React from 'react';
import { execRemoteCommand } from '../../apis/RemoteCommandExecuter';
import { Some } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { SlideButton } from '../components/SlideButton';
import { SlideInCard } from '../components/SlideInCard';
import { HomeData } from '../home/Home';
import { MessageKeys, createIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import { Store } from '../states/Store';
import { Length } from '../style/Length';
import { toRemoteCommandExecuterActions } from './Helpers';
import { TrackingMode, VehicleLocationState } from './VehicleLocationState';

type Parent = HomeData;

type Props = {
  parent: Store<Parent>;
  trackingMode: TrackingMode;
};

export function VehicleTrackerPane({
  parent,
  trackingMode,
}: Props): React.ReactElement {
  const {
    data: { context },
    views: { View },
  } = useParentStore({
    parent: {
      parent,
    },
  });

  const actions = VehicleLocationState.actions;
  const localState = parent.data.localState.geofence.localState;
  const mode = trackingMode;
  const intl = createIntl(context);
  const isEnabled = mode === 'Active' || mode === 'Normalizing';
  const commandName = 'theftTracking';
  const commandAction = isEnabled ? 'false' : 'true';

  return (
    <SlideInCard
      parent={{ home: parent }}
      title={intl.formatMessage({
        id:
          mode === 'Active'
            ? 'Location_trackingSettingTitleOff'
            : 'Location_trackingSettingTitleOn',
      })}
      closeIcon={Icons.ic_close_normal}
      minHeight={280}
      // open={command === 'OpenTrackingPane'}
      // close={command === 'CloseTrackingPane'}
      state={localState.trackingPaneState}
      onOpen={(dispatch) => dispatch}
      // onClose={() => Dispatch.parent(actions.updateCommand, 'HideOverlay')}
      onClose={(_, dispatch) =>
        dispatch.home(actions.updateTrackingPaneState, 'Hide')
      }
    >
      <View
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(20),
        }}
      >
        {intl
          .formatMessage({
            id:
              mode === 'Active'
                ? 'Location_trackingSettingSubTitleOff'
                : 'Location_trackingSettingSubTitleOn',
          })
          .toReactNode()}
      </View>

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: Length.px(20),
          paddingBottom: Length.px(40),
        }}
      >
        <SlideButton
          parent={{ home: parent }}
          isEnabled={isEnabled}
          isSpinning={localState.isTrackingSlideButtonSpinning}
          buttonIcon={Icons.ic_pursuit_white}
          leftIcon={
            mode === 'Active'
              ? Icons.ic_slide_off_black
              : Icons.ic_slide_off_white
          }
          rightIcon={
            mode === 'Active'
              ? Icons.ic_slide_on_black
              : Icons.ic_slide_on_white
          }
          onChange={({ dispatch }) =>
            execRemoteCommand({
              commandName,
              commandAction,
              closeAction: dispatch.home(
                actions.updateTrackingPaneState,
                'Hide',
              ),
              toastMessageId: Some(
                Scope<MessageKeys>(() => {
                  switch (commandAction) {
                    case 'true':
                      return 'Event_remoteTrackingOnSuccessRemoteControl';
                    case 'false':
                      return 'Event_remoteTrackingOffSuccessRemoteControl';
                  }
                }),
              ),
              updateIsSpinning: (enable: boolean) =>
                dispatch.home(
                  toRemoteCommandExecuterActions(actions).updateIsSpinning,
                  enable,
                ),
              context,
              dispatch,
            })
          }
        />
      </View>
    </SlideInCard>
  );
}
