import { UserType } from '../../apis/VehicleList';
import { MessageIds } from '../i18n/Types';

export function isOwner(value: { userType: UserType }): boolean {
  return value.userType === 'owner';
}

export function getUserTypeTextId(userType: UserType): MessageIds {
  switch (userType) {
    case 'all':
      return 'myCarManagement_userTypeUser1';
    case 'limited':
      return 'myCarManagement_userTypeUser2';
    case 'owner':
    default:
      return 'myCarManagement_userTypeOwner';
  }
}

export function getSharedUserListKey(vin: string): string {
  return `getSharedUserList:${vin}`;
}

export function getSharedUserInvitationCodeKey(
  vin: string,
  userType: UserType,
): string {
  return `getSharedUserInvitationCode:${vin}-${userType}`;
}
