import React from 'react';
import { HomeData, HomeDataMap } from '../home/Home';
import { Message } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import { DataMapDispatch, WithContext } from '../states/DataMapStore';
import { Store } from '../states/Store';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';

interface Props {
  readonly parent: Store<HomeData>;
  readonly title: Message;
  readonly style?: OptionalStyles;
  readonly onClick?: () => DataMapDispatch<WithContext<HomeDataMap>>;
}

export function PopupButton(props: Props): React.ReactElement {
  const {
    views: { View, Image },
    dispatch,
  } = useParentStore({
    parent: {
      home: props.parent,
    },
  });
  const Container = View;

  return (
    <Container
      style={{
        position: 'absolute',
        width: Percentage(100),
        ...props.style,
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        if (props.onClick) return props.onClick();
        else return dispatch;
      }}
    >
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginLeft: Length.px(16),
          marginRight: Length.px(16),
          paddingLeft: Length.px(24 + 6),
          paddingRight: Length.px(6),
          minHeight: Length.px(48),
          backgroundColor: Colors.primary,
          borderRadius: Length.px(24),
        }}
      >
        <View
          style={{
            flexGrow: 1,
            color: Colors.background,
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: Length.px(14),
            lineHeight: Length.px(14),
          }}
        >
          {props.title.toReactNode()}
        </View>

        <View>
          <Image
            src={Icons.ic_forward_white}
            style={{
              width: Length.px(24),
              height: Length.px(24),
            }}
          />
        </View>
      </View>
    </Container>
  );
}
