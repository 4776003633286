import React from 'react';
import { IcWaitingWhite } from '../icons/IcWaitingWhite';
import { useContextStore } from '../states/ContextStore';
import { Length } from '../style/Length';

export type SpinnerState = 'Hide' | 'Show';

export function WindowSpinner({
  state,
}: {
  state: SpinnerState;
}): React.ReactElement {
  const {
    views: { View },
    data: { context },
  } = useContextStore();
  const viewPort = context.viewPort;

  if (state === 'Show') {
    return (
      <View
        style={{
          position: 'absolute',
          top: Length.px(0),
          left: Length.px(0),
          width: Length.px(viewPort.width),
          height: Length.px(viewPort.height),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IcWaitingWhite animate />
      </View>
    );
  } else {
    return <></>;
  }
}
