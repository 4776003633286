import React from 'react';
import { config } from '../../Config';
import * as Auth from '../../apis/Auth';
import {
  dismissSplash,
  getDeviceToken,
  openLink,
  println,
  setDeviceToken,
} from '../../apis/NativeApi';
import { registerDeviceToken } from '../../apis/User';
import { Matrix3x3 } from '../../math/Matrix';
import { BuildOpt } from '../../util/BuildOpt';
import { copy } from '../../util/Copyable';
import { Region } from '../../util/Locale';
import { Option } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { Unit } from '../../util/Unit';
import { Button, ButtonThemes } from '../components/Button';
import { LineSpace } from '../components/LineSpace';
import { Shape } from '../components/Shape';
import { SlideInWindowMode } from '../components/SlideInWindow';
import { HomeData } from '../home/Home';
import { getLogo } from '../home/HomeHeader';
import { Intl, useIntl } from '../i18n/Intl';
import { ActionBinder } from '../states/ActionBinder';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import {
  DataMapDispatch,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Store } from '../states/Store';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';

type Key = 'login';

type Data = Readonly<{
  localState: LocalState;
  emailInput: Shape;
  passwordInput: Shape;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly signInButtonState: SignInButtonState;
  readonly showed: boolean;
}

type Command = 'SignIn';
type SignInButtonState = 'Disabled' | 'Normal';

interface Actions extends CommandActions<Data, Command> {
  readonly updateSignInButtonState: (data: Data) => Data;
  readonly updateShowed: (data: Data, showed: boolean) => Data;
}

type Parent = HomeData;
// type ButtonData = ExtractData<typeof Button>;

type Props = Readonly<{
  parent: Store<Parent>;
  mode: SlideInWindowMode;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type DP<Parent> = DataMapDispatch<WithContext<DM<Parent>>>;

const commandState = CommandState<Data, Command>();

const InputStyle: OptionalStyles = {
  border: Border('solid', Length.px(0.5), Colors.border),
  borderRadius: Length.px(24),
  backgroundColor: Colors.backgroundDark,
  paddingTop: Length.px(13),
  paddingBottom: Length.px(18),
  paddingLeft: Length.px(20),
  paddingRight: Length.px(20),
  width: Percentage(100),
  fontSize: Length.px(17),
  lineHeight: Length.px(17),
};

function loginErrorAction(intl: Intl): ActionBinder<Context> {
  return Action.bind(Context.actions.updateAlertDialog, {
    state: 'Show',
    title: intl.formatMessage({ id: 'Common_networkError' }),
    message: intl.formatMessage({ id: 'Common_networkErrorMessage' }),
  })
    .bind(Context.actions.updateOverlayState, { state: 'Hide' })
    .bind(Context.actions.updateWindowSpinnerState, 'Hide');
}

function postSignIn(): void {
  getDeviceToken().then((deviceToken) =>
    deviceToken.unwrap(
      (token) =>
        registerDeviceToken({ platform: 'push_gcm', deviceToken: token })
          .then(() => setDeviceToken(token))
          .catch((err) => {
            setDeviceToken(null);
            println(err);
          }),
      () => println(Error(`No device token`)),
    ),
  );
}

function dispatchSignIn<Parent>(
  data: Data,
  intl: Intl,
  region: Region,
  dispatch: DataMapDispatch<WithContext<Parent>>,
): DataMapDispatch<WithContext<Parent>> {
  const errorDialog = loginErrorAction(intl);

  return Option.join(
    data.emailInput
      .getRaw()
      .flatMap((e) => Option((e as HTMLInputElement).value)),
    data.passwordInput
      .getRaw()
      .flatMap((e) => Option((e as HTMLInputElement).value)),
  ).unwrap(
    ([email, password]) =>
      dispatch
        .context(Context.actions.updateContentState, 'SigningIn')
        .context(Context.actions.updateOverlayState, {
          state: 'Show',
          opacity: 0.4,
        })
        .context(Context.actions.updateWindowSpinnerState, 'Show')
        .asyncAll(
          Auth.signIn(email, password, region)
            .then(() =>
              Auth.authorizeAppSync()
                .then(() =>
                  dispatch.context(
                    (context) =>
                      Pipe(Unit)
                        .map(() => postSignIn())
                        .map(() =>
                          Action.bind(
                            Context.actions.updateContentState,
                            'SignedIn',
                          )
                            .bind(Context.actions.updateOverlayState, {
                              state: 'Hide',
                            })
                            .bind(
                              Context.actions.updateWindowSpinnerState,
                              'Hide',
                            )
                            .apply(context),
                        )
                        .get(),
                    Unit,
                  ),
                )
                .catch((err) =>
                  dispatch
                    .effect(() => println(err))
                    .context(errorDialog, Unit),
                ),
            )
            .catch((err) =>
              dispatch.effect(() => println(err)).context(errorDialog, Unit),
            ),
        ),
    () =>
      dispatch
        .effect(() => println(Error('Could not get email and password')))
        .context(errorDialog, Unit),
  );
}

export function getLoginWindowMode(context: Context): SlideInWindowMode {
  return context.contentState === 'SignedOut' ||
    context.contentState === 'SigningIn'
    ? 'Show'
    : 'Hide';
}

const actions: Actions = {
  ...commandState.actions,

  updateSignInButtonState: (data: Data) =>
    copy(data, {
      localState: {
        signInButtonState: data.emailInput
          .getRaw()
          .flatMap((a) => {
            return data.passwordInput.getRaw().map((b) => {
              if (
                (a as HTMLInputElement).value.length > 0 &&
                (b as HTMLInputElement).value.length > 0
              ) {
                return 'Normal';
              } else {
                return 'Disabled';
              }
            });
          })
          .getOrElse(() => 'Disabled'),
      },
    }),

  updateShowed: (data: Data, showed: boolean) =>
    copy(data, {
      localState: { showed },
    }),
};

export function LoginWindow(props: Props): React.ReactElement {
  const state = useStore<Key, Data, Parent>({
    key: 'login',
    default: () => ({
      localState: {
        command: commandState.default,
        state: 'Open',
        showed: false,
        signInButtonState: 'Disabled',
      },
      emailInput: Shape({}),
      passwordInput: Shape({}),
    }),
    update: ({ data: { login }, dispatch }) => {
      const localState = login.localState;
      return dispatch
        .compose(
          commandState.handleDataMap(
            'login',
            login,
            dispatch,
            (_command) => dispatch,
          ),
        )
        .pipe((_) => {
          if (props.mode === 'Show' && !localState.showed) {
            data.emailInput.getRaw().forEach((a) => a.focus());
            return _.login(actions.updateShowed, true);
          } else if (props.mode === 'Hide' && localState.showed) {
            return _.login(actions.updateShowed, false);
          } else {
            return _;
          }
        });
    },
  });

  const { View, Image, Input, Text } = state.views;
  const intl = useIntl();
  const data = state.data.login;
  const context = state.data.context;
  const localState = data.localState;
  const viewPort = context.viewPort;
  const dispatch = state.dispatch;

  const containerStyle = Scope<OptionalStyles>(() => {
    /*if (Auth.isSignedIn() && context.contentState === 'SignedOut') {
        return {
          transform: Transform(Matrix3x3.translate(-viewPort.width, 0)),
        };
      } else */ if (props.mode === 'Show') {
      return {
        transform: Transform(Matrix3x3.translate(0, 0)),
        transition: Transition('transform', Time.ms(300)),
      };
    } else {
      return {
        transform: Transform(Matrix3x3.translate(-viewPort.width, 0)),
        transition: Transition('transform', Time.ms(300)),
      };
    }
  });

  return (
    <View
      id='LoginWindow'
      style={{
        ...containerStyle,
        position: 'absolute',
        top: Length.px(0),
        width: Length.px(viewPort.width),
        height: Length.px(viewPort.height),
        backgroundColor: Colors.background,
        overflow: 'scroll',
        // zIndex: ZIndices.loginWindow,
      }}
      onTransitionEnd={(evt) =>
        dispatch.effect(() => {
          evt.stopPropagation();
          if (props.mode === 'Show') {
            dismissSplash();
          } else {
            data.emailInput
              .getRaw()
              .map((e) => ((e as HTMLInputElement).value = ''));
            data.passwordInput
              .getRaw()
              .map((e) => ((e as HTMLInputElement).value = ''));
          }
        })
      }
    >
      <LineSpace height={Length.px(60)} backgroundColor={Colors.background} />

      {/* Title Image */}
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: Colors.background,
        }}
      >
        <Image
          src={getLogo(context.locale.lang)}
          style={{
            width: Length.px(280),
            height: Length.px(50),
          }}
        />
      </View>

      <LineSpace height={Length.px(72)} backgroundColor={Colors.background} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: Colors.background,
          paddingLeft: Length.px(16),
          paddingRight: Length.px(16),
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            lineHeight: Length.px(20),
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({ id: 'Login_signUpSubtitle' }).toReactNode()}
        </Text>
      </View>

      <LineSpace height={Length.px(16)} backgroundColor={Colors.background} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: Colors.background,
        }}
      >
        <View
          style={{
            width: Length.px(160),
          }}
        >
          <Button
            parent={state.stores}
            title={intl.formatMessage({ id: 'Login_signUp' })}
            theme={ButtonThemes.gray}
            onClick={(dispatch) => {
              openLink(
                config[BuildOpt.env].dynamicLinkEndpoint.length > 0
                  ? config[BuildOpt.env].dynamicLinkEndpoint + '/app/register'
                  : 'mailto:web-newentry@cp-daihatsu.jp?subject=daihatsu-port&body=send',
              );
              return dispatch;
            }}
          />
        </View>
      </View>

      <LineSpace height={Length.px(32)} backgroundColor={Colors.background} />

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: Colors.background,
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            lineHeight: Length.px(16),
          }}
        >
          {intl.formatMessage({ id: 'Login_signInSubtitle' }).toReactNode()}
        </Text>
      </View>

      <LineSpace height={Length.px(12)} backgroundColor={Colors.background} />

      <View
        style={{
          paddingLeft: Length.px(16),
          paddingRight: Length.px(16),
          backgroundColor: Colors.background,
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Input
            ref={data.emailInput.getRef()}
            type='email'
            inputMode='email'
            placeholder={intl
              .formatMessage({ id: 'Login_signInUser' })
              .toString()}
            style={InputStyle}
            autoFocus
            onChange={(evt) => {
              evt.stopPropagation();
              return dispatch.login(actions.updateSignInButtonState, Unit);
            }}
            onFocus={() =>
              dispatch.login(actions.updateSignInButtonState, Unit)
            }
            onBlur={() => dispatch.login(actions.updateSignInButtonState, Unit)}
            onKeyPress={(evt) => {
              evt.stopPropagation();
              if (evt.key === 'Enter') {
                data.passwordInput.getRaw().forEach((_) => _.focus());
              }
              return dispatch;
            }}
          />
        </View>

        <LineSpace height={Length.px(12)} />

        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Input
            ref={data.passwordInput.getRef()}
            type='password'
            placeholder={intl
              .formatMessage({ id: 'Login_signInPassword' })
              .toString()}
            style={InputStyle}
            onChange={(evt) => {
              evt.stopPropagation();
              return dispatch.login(actions.updateSignInButtonState, Unit);
            }}
            onFocus={() =>
              dispatch.login(actions.updateSignInButtonState, Unit)
            }
            onBlur={() => dispatch.login(actions.updateSignInButtonState, Unit)}
            onKeyPress={(evt) => {
              evt.stopPropagation();
              if (evt.key === 'Enter') {
                data.passwordInput.getRaw().forEach((_) => _.blur());
                return dispatchSignIn<DM<Parent>>(
                  data,
                  intl,
                  theme('JP', 'MY'),
                  dispatch,
                );
              } else return dispatch;
            }}
          />
        </View>

        <LineSpace height={Length.px(12)} />

        <View>
          <Button
            parent={state.stores}
            title={intl.formatMessage({ id: 'Login_signIn' })}
            disabled={localState.signInButtonState === 'Disabled'}
            click={localState.command === 'SignIn'}
            onClick={(dispatch: DP<Parent>) => {
              return dispatchSignIn(data, intl, theme('JP', 'MY'), dispatch);
            }}
          />
        </View>

        <LineSpace height={Length.px(12)} />

        <View>
          <Button
            parent={state.stores}
            title={intl.formatMessage({ id: 'Login_forgetPassword' })}
            theme={ButtonThemes.white}
            onClick={(dispatch) => {
              openLink(
                config[BuildOpt.env].dynamicLinkEndpoint.length > 0
                  ? config[BuildOpt.env].dynamicLinkEndpoint + '/app/forgot'
                  : 'https://stgconnect.daihatsu.co.jp/member/web/password/',
                intl.formatMessage({ id: 'Login_forgetPassword' }).toString(),
              );
              return dispatch;
            }}
          />
        </View>
      </View>

      <LineSpace height={Length.px(context.screenSize.marginBottom)} />
    </View>
  );
}
