import React from 'react';
import { Scope } from '../../util/Scope';
import { isContentLoading } from '../home/Helpers';
import { Intl, Message } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import { Icons } from '../icons/Icons';
import { ContentState } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapDispatch, WithContext } from '../states/DataMapStore';
import { Store } from '../states/Store';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { CardData } from './Card';
import { LastUpdateDate } from './LastUpdateDate';
import { ReloadButton } from './ReloadButton';

interface Factory {
  (props: Props): React.ReactElement;
}

interface Statics {
  readonly height: Length;
}

type PM = Readonly<{ card: CardData }>;
type ED = DataMapDispatch<WithContext<PM>>;

type Props = Readonly<{
  parent: Store<CardData>;
  title: Message;
  lastUpdate?: Date | Message;
  isOpen: boolean;
  reloadable: boolean;
  contentState: ContentState;
  ongoingState: boolean;
  intl: Intl;
  onClose: (_: ED) => ED;
  onReload: (_: ED) => ED;
}>;

function CardHeaderComponent(props: Props): React.ReactElement {
  const {
    views: { View },
    data: { context },
  } = useParentStore({ parent: { card: props.parent } });

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: CardHeader.height,
        borderBottom: Border('solid', Length.px(1), Colors.border),
      }}
    >
      {/* Left */}
      {Scope(() => {
        if (props.reloadable) {
          return (
            <ReloadButton
              {...props}
              parent={{
                card: props.parent,
              }}
            />
          );
        } else {
          return (
            <View
              style={{
                width: Length.px(48),
                height: Length.px(48),
              }}
            />
          );
        }
      })}

      {/* Center */}
      <View>
        <View
          style={{
            fontSize: Length.px(16),
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {props.title.toReactNode()}
        </View>

        <LastUpdateDate {...props} />
      </View>

      {/* Right */}
      <IcButton
        parent={{ card: props.parent }}
        image={Icons.ic_close_normal}
        imageWidth={Length.px(48)}
        imageHeight={Length.px(48)}
        disabled={isContentLoading(context)}
        onClick={(dispatch) => {
          return props.onClose ? props.onClose(dispatch) : dispatch;
        }}
      />
    </View>
  );
}

const statics: Statics = {
  height: Length.px(56),
};

const component: Factory = CardHeaderComponent;

export const CardHeader: Factory & Statics = Object.assign(component, statics);
