import React, { useCallback } from 'react';
import { VehicleEvent } from '../../apis/EventTelemetry';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowDispatch,
  SlideInWindowMode,
} from '../components/SlideInWindow';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
} from '../states/DataMapStore';
import { FulfillBody } from '../vehicle-alert/DetailedWindow';
import { VehicleInfoCardActions, VehicleInfoCardData } from './VehicleInfoCard';

type Data = VehicleInfoCardData;
type DM = ExtendParent<HomeDataMap, 'vehicleInfo', Data>;
type DP = DataMapDispatch<WithContext<DM>>;
type OnCloseDispatch = SlideInWindowDispatch<DM>;

export type Props = Readonly<{
  parent: DataMapStore<DM>;
  mode: SlideInWindowMode;
  event: VehicleEvent;
  onBack: (dispatch: DP) => DP;
  onClose: (dispatch: OnCloseDispatch) => OnCloseDispatch;
}>;

export const VehicleEventDetailWindow: React.FC<Props> = (props) => {
  const intl = useIntl();
  const actions = VehicleInfoCardActions;
  const openEngineProhibit = useCallback(
    (dispatch: DP) =>
      dispatch.vehicleInfo(actions.updateEngineControllerState, 'Show'),
    [actions],
  );

  return (
    <SlideInWindow
      parent={props.parent}
      mode={props.mode}
      title={intl.formatMessage({ id: 'VehicleAlert_pageTitle' })}
      headerLeft={<HeaderLeft onClick={props.onBack} parent={props.parent} />}
      onClose={(_) => props.onClose(_)}
    >
      <FulfillBody
        event={props.event}
        parentId='vehicleInfoCard'
        parent={props.parent}
        onClickEngineProhibitButton={openEngineProhibit}
      />
    </SlideInWindow>
  );
};
