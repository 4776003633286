import React, { useCallback } from 'react';
import { config } from '../../Config';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { IconsPerodua } from '../../images/EncodedIconsPerodua';
import { isContentLoading } from '../home/Helpers';
import { HomeDataMap, HomeDispatch } from '../home/Home';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';

type Action = () => HomeDispatch;

const iconSize = Length.px(96);

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

type WidgetButtonProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  image: string;
  action: Action;
}>;

const WidgetButton: React.VFC<WidgetButtonProps> = ({
  parent,
  image,
  action,
}: WidgetButtonProps) => {
  const {
    views: { View, Image },
    data: { context },
    dispatch,
  } = useParentStore({ parent });
  const widgetDisabled = isContentLoading(context);

  const onClick = useCallback(
    (evt) => {
      if (widgetDisabled) {
        return dispatch;
      }
      evt.stopPropagation();
      return action();
    },
    [action, widgetDisabled, dispatch],
  );

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.background,
        width: Length.px(164),
        height: Length.px(108),
        borderRadius: Length.px(12),
        filter: DropShadow(
          Length.px(0),
          Length.px(2),
          Length.px(8),
          Colors.shadowWidget,
        ),
        WebkitTapHighlightColor: 'transparent',
        opacity: widgetDisabled ? Colors.disabledOpacity : 1.0,
        zIndex: context.cardInfo.state === 'Opened' ? -1 : undefined,
      }}
      onClick={onClick}
    >
      <Image
        style={{
          width: iconSize,
          height: iconSize,
        }}
        src={image}
      />
    </View>
  );
};

export const WidgetList: React.VFC<Props> = ({ parent }: Props) => {
  const {
    views: { View },
    data: { context },
    dispatch,
  } = useParentStore({ parent });

  const Widgets = [
    {
      image: IconsPerodua.ic_widget_geofencing,
      action: () =>
        !isServiceAllowed(context, 'geofence')
          ? dispatch.context.bind(userRestrictionAction(context))
          : dispatch
              .context(Context.actions.updateGeofenceWindowMode, 'Show')
              .context(Context.actions.updateContentState, 'Reload')
              .context(
                Context.actions.updateFetchVehicleDataOptions,
                config.fetchDataOptions.onOpenVehicleLocation,
              ),
    },
    {
      image: IconsPerodua.ic_widget_wifimonitor,
      action: () =>
        !isServiceAllowed(context, 'wifi')
          ? dispatch.context.bind(userRestrictionAction(context))
          : dispatch
              .context(Context.actions.updateWifiWindowMode, 'Show')
              .context(Context.actions.updateContentState, 'Reload')
              .context(
                Context.actions.updateFetchVehicleDataOptions,
                config.fetchDataOptions.onOpenWifi,
              ),
    },
  ];

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        paddingTop: Length.px(24),
        paddingBottom: Length.px(24),
      }}
    >
      {Widgets.map((link, i) => (
        <WidgetButton key={`widget-button-${i}`} {...link} parent={parent} />
      ))}
    </View>
  );
};
