import React from 'react';
import { showSharedUserRegistrationScreen } from '../../apis/NativeApi';
import { Vehicle, useGetCarNickName } from '../../apis/VehicleList';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowMode,
} from '../components/SlideInWindow';
import { useRootWindow } from '../components/SlideInWindowStack';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { getUserTypeTextId } from './Utils';
import { VehicleInformation } from './VehicleInformation';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mode: SlideInWindowMode;
  cars: Vehicle[];
}>;

function ListItem({
  car,
  onClick,
}: Readonly<{
  car: Vehicle;
  onClick: () => void;
}>): React.ReactElement {
  const {
    views: { View, Image, Text },
  } = useContextStore();
  const intl = useIntl();

  const [name] = useGetCarNickName(car);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: 96,
      }}
      onClick={onClick}
    >
      <View
        style={{
          paddingLeft: Length.px(12),
          paddingRight: Length.px(12),
        }}
      >
        <Image
          src={car.image}
          style={{
            width: Length.px(90),
          }}
        />
      </View>

      <View
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          height: Percentage(100),
        }}
      >
        <View>
          <Text
            style={{
              marginTop: Length.px(24),
              fontSize: Length.px(16),
              lineHeight: Length.px(16),
              fontWeight: 'bold',
            }}
          >
            {name}
          </Text>
          <Text
            style={{
              marginTop: Length.px(8),
              fontSize: Length.px(14),
              lineHeight: Length.px(14),
            }}
          >
            {intl
              .formatMessage({ id: getUserTypeTextId(car.userType) })
              .toReactNode()}
          </Text>
        </View>
      </View>
    </div>
  );
}

export const VehicleManagement: React.FC<Props> = (props) => {
  const {
    views: { View, Text },
    stores,
    dispatch,
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();

  const [rootProps, controller] = useRootWindow(stores);
  const [selectedCar, setSelectedCar] = React.useState<Vehicle | undefined>(
    props.cars[0],
  );
  const openInfoWindow = React.useCallback(
    (car: Vehicle) => () => {
      setSelectedCar(car);
      controller.showWindow();
    },
    [controller],
  );

  return (
    <SlideInWindow
      parent={stores}
      mode={props.mode}
      title={intl.formatMessage({ id: 'VehicleActivate_pageTitle' })}
      headerLeft={
        <HeaderLeft
          onClick={(dispatch) =>
            dispatch.context(
              Context.actions.updateVehicleManagementMode,
              'Hide',
            )
          }
          parent={stores}
        />
      }
      onClose={(dispatch) =>
        dispatch
          .context(Context.actions.updateRegisterWindowMode, 'Hide')
          .context(Context.actions.updateVehicleManagementMode, 'Hide')
      }
    >
      {props.cars.map((car, i) => (
        <View key={car.vin}>
          {i > 0 ? (
            <View
              style={{
                width: Percentage(100),
                marginLeft: Length.px(12 + 90 + 12),
                borderBottom: Border('solid', Length.px(0.5), Colors.border),
              }}
            />
          ) : null}
          <ListItem car={car} onClick={openInfoWindow(car)} />
        </View>
      ))}

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: Length.px(24),
          paddingBottom: Length.px(24),
          borderTop: Border('solid', Length.px(0.5), Colors.border),
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            color: Colors.accent,
          }}
          onClick={(evt) => {
            evt.stopPropagation();
            return dispatch.context.bind(Context.actions.updateConfirmDialog, {
              state: 'Show',
              title: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterTitle',
              }),
              message: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterBody',
              }),
              confirmButtonTitle: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterButton1',
              }),
              confirmButton2Title: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterButton2',
              }),
              onConfirm: () => {
                return dispatch.context.bind(
                  Context.actions.updateCarRegScreenMode,
                  'Show',
                );
              },
              onConfirm2: () => {
                showSharedUserRegistrationScreen();
                return dispatch.context.bind(
                  Context.actions.updateSharedUserRegScreenMode,
                  { state: 'Scanning', error: false },
                );
              },
            });
          }}
        >
          {intl
            .formatMessage({ id: 'VehicleActivate_registerCar' })
            .toReactNode()}
        </Text>
      </View>

      <VehicleInformation
        parent={props.parent}
        props={rootProps}
        controller={controller}
        car={selectedCar}
      />
    </SlideInWindow>
  );
};
