import * as Client from '@auto/monaka-client/dist/20';
import { getDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { Anomaly, getErrorMessage } from '../util/Error';
import { Listener } from '../util/Listener';
import { None, Option, OptionJson, Some } from '../util/Option';
import { Failure, Result, Success } from '../util/Result';
import { Scope } from '../util/Scope';
import { sleep } from '../util/Sleep';
import { Unit } from '../util/Unit';
import { AppSyncClient } from './Client';
import { GeoPos } from './Geofence';
import {
  getLatestVehicleLocationTable,
  println,
  setLatestVehicleLocation,
} from './NativeApi';

export interface ServiceTelemetry {
  readonly acceleration: number;
  readonly accuracy: number;
  readonly altitude: number;
  readonly altitude_accuracy: number;
  readonly fl_seg: number;
  readonly fl_wrn: boolean;
  readonly ig_status: boolean;
  readonly position: Option<VehicleLocation>;
  readonly obu_id: string;
  readonly odo: number;
  readonly omega: number;
  /** distance to empty */
  readonly range: number;
  readonly ssa: number;
  readonly temperature: number;
  readonly timestamp: Date;
  /** fuel efficiency */
  readonly to_fc: number;
  readonly total_data_usage: Option<number>;
  readonly wifi_data_usage: Option<number>;
  readonly lte_reception_level: Option<number>;
  readonly trip_id: string;
  readonly velocity: number;
  readonly vin: string;
  readonly wiper: string;
  readonly door_status: boolean;
  readonly light: boolean;
  readonly hazard_lamp: boolean;
  readonly air_con_status: boolean;
  readonly dsc_eng_prohibit: boolean;
  readonly en_theft_trk: boolean;
  readonly eng_status: boolean;
}

export type VehicleLocation = {
  readonly isOld: boolean;
  readonly location: GeoPos;
};

export type ServiceTelemetryJson = Omit<
  ServiceTelemetry,
  'timestamp' | 'position'
> & {
  readonly position: OptionJson<VehicleLocation>;
  readonly timestamp: number; // epoch in milliseconds
};

export type ServiceTelemetryResult = {
  readonly vin: string;
  readonly data: Result<ServiceTelemetry>;
};

const ServiceTelemetryEventName = 'ServiceTelemetryEvent';

export function toServiceTelemetryJson(
  a: ServiceTelemetry,
): ServiceTelemetryJson {
  return {
    ...a,
    position: a.position.toJson(),
    timestamp: a.timestamp.getTime(),
  };
}

export function fromServiceTelemetryJson(
  a: ServiceTelemetryJson,
): ServiceTelemetry {
  return {
    ...a,
    position: Option.fromJson(a.position),
    timestamp: new Date(a.timestamp),
  };
}

export const defaultVehicleLocation: GeoPos = {
  lat: 35.6505341,
  lng: 139.7077698,
};

export function genServiceTelemetry({
  vin,
  position,
}: {
  readonly vin?: string;
  readonly position?: GeoPos;
} = {}): ServiceTelemetry {
  return {
    acceleration: 0,
    accuracy: 0,
    altitude: 0,
    altitude_accuracy: 0,
    fl_seg: 5,
    fl_wrn: true,
    ig_status: false,
    position: Option(position).map<VehicleLocation>((p) => ({
      isOld: false,
      location: p,
    })),
    obu_id: '',
    odo: 10135,
    omega: 0,
    range: 1649,
    ssa: 0,
    temperature: 0,
    timestamp: new Date(Date.now()),
    to_fc: 1018.5,
    total_data_usage: None(),
    wifi_data_usage: None(),
    lte_reception_level: None(),
    trip_id: '',
    velocity: 0,
    vin: vin ?? '',
    wiper: '',
    door_status: false,
    light: false,
    hazard_lamp: false,
    air_con_status: false,
    dsc_eng_prohibit: false,
    en_theft_trk: false,
    eng_status: false,
  };
}

export const defaultServiceTelemetry: ServiceTelemetryResult = {
  vin: '',
  data: Failure(Anomaly.of('')),
};

function fromClientServiceTelemetry(
  _: Client.ServiceTelemetry,
): ServiceTelemetry {
  return {
    acceleration: _.acceleration,
    accuracy: _.accuracy,
    altitude: _.altitude,
    altitude_accuracy: _.altitude_accuracy,
    fl_seg: _.fl_seg,
    fl_wrn: _.fl_wrn === 1,
    ig_status: _.ig_status === 1,
    position:
      _.latitude === null || _.longitude === null
        ? None()
        : Some<VehicleLocation>({
            isOld: false,
            location: {
              lat: _.latitude,
              lng: _.longitude,
            },
          }),
    obu_id: _.obu_id,
    odo: _.odo,
    omega: _.omega,
    range: _.range,
    ssa: _.ssa,
    temperature: _.temperature,
    timestamp: new Date(_.timestamp),
    to_fc: _.to_fc,
    total_data_usage: Option(_.total_data_usage),
    wifi_data_usage: Option(_.wifi_data_usage),
    lte_reception_level: Option(_.lte_reception_level),
    trip_id: _.trip_id,
    velocity: _.velocity,
    vin: _.vin,
    wiper: _.wiper,
    door_status: _.door_status === 0,
    light: _.light === 1,
    hazard_lamp: _.hazard_lamp === 1,
    air_con_status: _.air_con_status === 1,
    dsc_eng_prohibit: _.dsc_engine_prohibit === 1,
    en_theft_trk: _.en_theft_trk === 1,
    eng_status: _.eng_status === 1,
  };
}

function correctVehicleLocation(
  a: ServiceTelemetryResult,
): Promise<ServiceTelemetryResult> {
  return a.data
    .toOption()
    .flatMap((_) => _.position)
    .unwrap(
      async (p) => {
        setLatestVehicleLocation(a.vin, p.location).catch((err) =>
          println(
            `Failed to save the vehicle location: ${JSON.stringify(err)}`,
          ),
        );
        return a;
      },
      async () => {
        const p = (await getLatestVehicleLocationTable())
          .get(a.vin)
          .map((p) => ({
            isOld: true,
            location: p,
          }));
        return {
          ...a,
          position: p,
        };
      },
    );
}

export async function getServiceTelemetry(
  vin: string,
): Promise<ServiceTelemetryResult> {
  println(`Getting the service telemetry for vin=${vin}`);
  const a = await Scope<Promise<ServiceTelemetryResult>>(async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      const db = await getDB();
      if (db.serviceTelemetryErrorVin1 && vin === 'vin-1')
        return Promise.reject(Error(`Error: ${vin}`));
      else if (db.serviceTelemetryErrorVin2 && vin === 'vin-2')
        return Promise.reject(Error(`Error: ${vin}`));
      else
        return Option(db.serviceTelemetries.find((_) => _.vin === vin)).unwrap(
          (s) => {
            println(`Got the service telemetry for vin=${vin}`);
            return Promise.resolve({ vin: vin, data: Success(s) });
          },
          () => {
            const msg = `Error on getServiceTelemetry for vin=${vin}`;
            return Promise.reject({ vin: vin, data: Failure(Anomaly.of(msg)) });
          },
        );
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.getServiceTelemetry(gSysUserId, vin)
        .then((_) => {
          println(`Got the service telemetry for vin=${vin}`);
          return { vin: vin, data: Success(fromClientServiceTelemetry(_)) };
        })
        .catch(async (err) => {
          const prefix = `Error on getServiceTelemetry for vin=${vin}, gSysUserId=${gSysUserId}`;
          const msg = getErrorMessage(err);
          return {
            vin: vin,
            data: Failure(Anomaly.of(`${prefix}: ${msg}`)),
          };
        });
    }
  });

  return await correctVehicleLocation(a);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let serviceTelemetryEventListener: ((event: any) => void) | null = null;

export async function subscribeServiceTelemetry(
  dcmId: string,
): Promise<Listener<ServiceTelemetryResult>> {
  println(`Start subscribing service telemetries for ${dcmId}`);
  const a = await Scope<Promise<Listener<ServiceTelemetry>>>(() => {
    if (BuildOpt.isMock()) {
      return Promise.resolve(
        Listener((dispatch) => {
          serviceTelemetryEventListener = (event) => {
            println(`Received subscribed service telemetry for ${dcmId}`);
            if (event.detail?.data) {
              dispatch(Success(event.detail.data));
            }
          };
          window.addEventListener(
            ServiceTelemetryEventName,
            serviceTelemetryEventListener,
          );
        }),
      );
    } else {
      return new Promise((resolve, reject) => {
        const listener = Listener<ServiceTelemetry>((dispatch) => {
          return AppSyncClient.subscribeServiceTelemetry(
            dcmId,
            async (_): Promise<void> => {
              println(`Received subscribed service telemetry for ${dcmId}`);
              dispatch(Success(fromClientServiceTelemetry(_)));
            },
          )
            .then(() => {
              resolve(listener);
            })
            .catch((err) => {
              const msg = `Error on subscribeServiceTelemetry(${dcmId}): ${getErrorMessage(
                err,
              )}`;
              return reject(Error(msg));
            });
          /*
          Auth.authorizeIoT(dcmId)
            .then(() => {
              return IotClient.subscribeServiceTelemetry(dcmId, (_) => {
                println(`Received subscribed service telemetry for ${dcmId}`);
                dispatch(Success(fromClientServiceTelemetry(_)));
              })
                .then(() => {
                  resolve(listener);
                })
                .catch((err) => {
                  const msg = `Error on subscribeServiceTelemetry(${dcmId}): ${getErrorMessage(
                    err,
                  )}`;
                  return reject(Error(msg));
                });
            })
            .catch((err) => {
              const msg = `Error on authorizeIoT(${dcmId}): ${getErrorMessage(
                err,
              )}`;
              return reject(Error(msg));
            });
            */
        });
      });
    }
  });

  return a.flatMap((s) =>
    Listener.fromPromise(
      correctVehicleLocation({ vin: s.vin, data: Success(s) }),
    ),
  );
}

export async function unsubscribeServiceTelemetry(
  dcmId: string,
): Promise<Unit> {
  println(`Start unsubscribing service telemetries for ${dcmId}`);
  if (BuildOpt.isMock()) {
    if (serviceTelemetryEventListener)
      window.removeEventListener(
        ServiceTelemetryEventName,
        serviceTelemetryEventListener,
      );
    return Promise.resolve(Unit);
  } else {
    return AppSyncClient.unsubscribeServiceTel(dcmId)
      .then((_) => Unit)
      .catch((err) => {
        const msg = `Error on unsubscribeServiceTelemetry(${dcmId}): ${getErrorMessage(
          err,
        )}`;
        return Promise.reject(Error(msg));
      });
    /*
    return IotClient.unsubscribeServiceTelemetry(dcmId)
      .then((_) => Unit)
      .catch((err) => {
        const msg = `Error on unsubscribeServiceTelemetry(${dcmId}): ${getErrorMessage(
          err,
        )}`;
        return Promise.reject(Error(msg));
      });
    */
  }
}
