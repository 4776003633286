import React from 'react';
import { copy } from '../../util/Copyable';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { Message } from '../i18n/Intl';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Colors, RGBA } from '../style/Color';
import { Length } from '../style/Length';
import { FontWeight } from '../style/Style';
import { Shape } from './Shape';

type Key = 'button';
export type ButtonData = Data;

type Data = Readonly<{
  localState: LocalState;
  container: Shape;
}>;

type LocalState = Readonly<{
  buttonPressed: boolean;
}>;

type Actions = Readonly<{
  updateButtonPressed: (data: Data, buttonPressed: boolean) => Data;
}>;

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  title?: Message;
  center?: React.ReactNode;
  right?: React.ReactNode;
  theme?: Theme;
  disabled?: boolean;
  click?: boolean;
  onClick?: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
}>;

type ThemeItem = Readonly<{
  fontSize: Length;
  fontWeight: FontWeight;
  fontColor: RGBA;
  backgroundColor: RGBA;
}>;

type Theme = Readonly<{
  normal: ThemeItem;
  pressed: ThemeItem;
  disabled: ThemeItem;
}>;

type ButtonThemes = Readonly<{
  default: Theme;
  gray: Theme;
  white: Theme;
}>;

export const buttonHeight = Length.px(48);

export const ButtonThemes: ButtonThemes = {
  default: {
    normal: {
      fontSize: Length.px(16),
      fontWeight: 'bold',
      fontColor: Colors.buttonText,
      backgroundColor: Colors.primary,
    },
    pressed: {
      fontSize: Length.px(16),
      fontWeight: 'bold',
      fontColor: Colors.buttonText,
      backgroundColor: Colors.primaryPressed,
    },
    disabled: {
      fontSize: Length.px(16),
      fontWeight: 'bold',
      fontColor: Colors.buttonTextLight,
      backgroundColor: Colors.primary,
    },
  },
  gray: {
    normal: {
      fontSize: Length.px(17),
      fontWeight: 'bold',
      fontColor: Colors.buttonText,
      backgroundColor: Colors.secondary,
    },
    pressed: {
      fontSize: Length.px(17),
      fontWeight: 'bold',
      fontColor: Colors.buttonText,
      backgroundColor: Colors.secondaryPressed,
    },
    disabled: {
      fontSize: Length.px(17),
      fontWeight: 'bold',
      fontColor: Colors.buttonTextMoreLight,
      backgroundColor: Colors.secondary,
    },
  },
  white: {
    normal: {
      fontSize: Length.px(16),
      fontWeight: 'normal',
      fontColor: Colors.accent,
      backgroundColor: Colors.background,
    },
    pressed: {
      fontSize: Length.px(16),
      fontWeight: 'normal',
      fontColor: Colors.accent,
      backgroundColor: Colors.background,
    },
    disabled: {
      fontSize: Length.px(16),
      fontWeight: 'normal',
      fontColor: Colors.accent,
      backgroundColor: Colors.background,
    },
  },
};

const actions: Actions = {
  updateButtonPressed: (data: Data, buttonPressed: boolean) =>
    copy(data, {
      localState: { buttonPressed },
    }),
};

export function Button<Parent>(props: Props<Parent>): React.ReactElement {
  const state = useStore<Key, Data, Parent>({
    key: 'button',
    parent: props.parent,
    default: () => ({
      localState: {
        buttonPressed: false,
      },
      container: Shape({}),
    }),
    update: ({ dispatch }) => {
      return dispatch.unsafeCast<DataMapDispatch<WithContext<Parent>>>((_) => {
        if (props.click && props.onClick) {
          return _.compose(props.onClick(_));
        } else {
          return _;
        }
      });
    },
  });

  const { View, Text } = state.views;
  const data = state.data.button;
  const localState = data.localState;
  const dispatch = state.dispatch;

  const theme = props.theme ?? ButtonThemes.default;
  const themeItem = Scope(() => {
    if (localState.buttonPressed) {
      return theme.pressed;
    }
    if (props.disabled === undefined || props.disabled === false) {
      return theme.normal;
    } else {
      return theme.disabled;
    }
  });

  return (
    <View
      ref={data.container.getRef()}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: buttonHeight,
        //paddingTop: Length.px(14),
        //paddingBottom: Length.px(18),
        borderRadius: Length.px(24),
        backgroundColor: themeItem.backgroundColor,
      }}
      onTouchStart={(evt) => {
        evt.stopPropagation();
        if (props.disabled === true) {
          return dispatch;
        } else {
          return dispatch.button(actions.updateButtonPressed, true);
        }
      }}
      onTouchEnd={(evt) => {
        evt.stopPropagation();
        if (props.disabled === true) {
          return dispatch;
        } else {
          return dispatch.button(actions.updateButtonPressed, false);
        }
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        if (props.disabled === true) {
          return dispatch;
        } else {
          return Option(props.onClick)
            .map((f) =>
              dispatch.unsafeCast<DataMapDispatch<WithContext<Parent>>>((_) =>
                f(_),
              ),
            )
            .getOrElse(() => dispatch);
        }
      }}
    >
      <View
        style={{
          flexGrow: 1,
          opacity: 0,
        }}
      >
        {Scope(() => {
          if (props.right) {
            return props.right;
          } else {
            return <View />;
          }
        })}
      </View>

      <View
        style={{
          flexGrow: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {Scope(() => {
          if (props.center) {
            return props.center;
          } else {
            return (
              <Text
                style={{
                  fontSize: themeItem.fontSize,
                  fontWeight: themeItem.fontWeight,
                  lineHeight: themeItem.fontSize,
                  color: themeItem.fontColor,
                }}
              >
                {props.title?.toReactNode()}
              </Text>
            );
          }
        })}
      </View>

      <View
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {Scope(() => {
          if (props.right) {
            return props.right;
          } else {
            return <View />;
          }
        })}
      </View>
    </View>
  );
}
