import React from 'react';
import { Icons } from '../icons/Icons';
import { useContextStore } from '../states/ContextStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

export const badgeWidth = 40;
export const badgeHeight = 20;

export function Badge({
  nEvents,
  arrowPos,
}: Readonly<{
  nEvents: number;
  arrowPos: 'Right' | 'Left';
}>): React.ReactElement {
  const {
    views: { View, Text, Image },
  } = useContextStore();

  return (
    <View
      style={{
        display: 'flex',
        width: Length.px(badgeWidth),
        height: Length.px(badgeHeight),
        paddingLeft: Length.px(4),
        paddingRight: Length.px(4),
        backgroundColor: Colors.primary,
        borderRadius: Length.px(10),
      }}
    >
      {arrowPos === 'Left' ? (
        <View
          style={{
            flexGrow: 0,
          }}
        >
          <Image
            alt='ic_badge_back'
            src={Icons.ic_badge_back}
            style={{
              width: Length.px(6),
              height: Length.px(12),
            }}
          />
        </View>
      ) : null}

      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            fontWeight: 'bold',
            color: Colors.buttonText,
          }}
        >
          {nEvents}
        </Text>
      </View>

      {arrowPos === 'Right' ? (
        <View
          style={{
            flexGrow: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Image
            alt='ic_badge_forward'
            src={Icons.ic_badge_forward}
            style={{
              width: Length.px(6),
              height: Length.px(12),
            }}
          />
        </View>
      ) : null}
    </View>
  );
}
