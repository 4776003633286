import React from 'react';
import { Animation } from '../style/Animation';
import { OptionalStyles, toCSSProperties } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { animation } from './Helpers';

export type SpinDegree = '15' | '20';
export type SpinImageProps = {
  readonly alt: string;
  readonly src: string;
  readonly duration: Time;
  readonly style?: OptionalStyles;
  readonly animate?: boolean;
  readonly degree?: SpinDegree;
};

// eslint-disable-next-line react/display-name
export const SpinImage = React.forwardRef(
  (
    { style: _style, alt, src, animate, duration, degree }: SpinImageProps,
    ref: React.Ref<HTMLImageElement>,
  ) => {
    const name = degree ? `spin-${degree}deg` : 'spin-smooth';
    const style = {
      ...animation(
        Animation(
          name,
          duration,
          TimingFunction.linear,
          Time.ms(0),
          'infinite',
        ),
        animate,
      ),
      ..._style,
    };

    return <img alt={alt} style={toCSSProperties(style)} ref={ref} src={src} />;
  },
);
