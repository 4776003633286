import React, { Suspense, useCallback, useState } from 'react';
import useSWR from 'swr';
import { config } from '../../Config';
import { openLink } from '../../apis/NativeApi';
import {
  SharedUser,
  getSharedUserList,
  useGetUserNickName,
} from '../../apis/SharedUser';
import { Vehicle, useGetCarNickName } from '../../apis/VehicleList';
import { deleteVehicle } from '../../apis/VehiclePairing';
import { BuildOpt } from '../../util/BuildOpt';
import { theme } from '../../util/Theme';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { LineSpace } from '../components/LineSpace';
import {
  RootWindow,
  RootWindowProps,
  WindowStackController,
  useRootWindow,
  useSubWindow,
} from '../components/SlideInWindowStack';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { create } from '../style/Style';
import { AsyncActionView } from './AsyncActionView';
import { Display } from './Display';
import { InviteSharedUser } from './InviteSharedUser';
import { Loading } from './Loading';
import { CarNickNameInput } from './NickNameInput';
import { UserManagement } from './UserManagement';
import { getSharedUserListKey, getUserTypeTextId, isOwner } from './Utils';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  props: RootWindowProps;
  controller: WindowStackController;
  car: Vehicle | undefined;
}>;

const styles = create({
  container: {
    width: Percentage(100),
  },
  rowHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    height: Length.px(48),
    width: Percentage(100),
    paddingLeft: Length.px(16),
    paddingBottom: Length.px(6),
    backgroundColor: Colors.backgroundMoreDark,
  },
  rowHeaderTitle: {
    fontSize: Length.px(14),
    fontWeight: 'bold',
    color: Colors.textLight,
  },
  rowPadding: {
    width: Percentage(100),
    paddingLeft: Length.px(16),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: Length.px(48),
    width: Percentage(100),
    borderTop: Border('solid', Length.px(0.5), Colors.border),
  },
  rowTitle: {
    fontSize: Length.px(16),
    fontWeight: 'bold',
  },
  rowText: {
    fontSize: Length.px(16),
    marginRight: Length.px(16),
  },
  subText: {
    fontSize: Length.px(14),
    marginRight: Length.px(16),
  },
  actionText: {
    fontSize: Length.px(16),
    color: Colors.accent,
  },

  noBorder: {
    borderTop: Border.none,
  },
  wideRow: {
    alignItems: 'flex-start',
    height: Length.px(64),
    paddingTop: Length.px(10),
    paddingBottom: Length.px(10),
  },

  carImageContainer: {
    height: Length.px(112),
    width: Percentage(100),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carImage: {
    width: Length.px(120),
  },
  userRow: {
    height: Length.px(64),
    width: Percentage(100),
    display: 'flex',
    alignItems: 'center',
  },
  userImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(40),
    width: Length.px(40),
    marginLeft: Length.px(12),
    marginRight: Length.px(12),
  },
  userRowBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  serviceRowBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  editRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: Length.px(16),
  },
  editInput: {
    fontSize: Length.px(16),
    textAlign: 'right',
    border: Border.none,
  },
  editButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(48),
    width: Length.px(48),
  },
  forwardButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(24),
    width: Length.px(24),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: Length.px(4),
    marginRight: Length.px(4),
  },
  deleteContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(40),
    width: Length.px(40),
    marginLeft: Length.px(12),
    marginRight: Length.px(12),
  },
});

type CarInfoProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  car: Vehicle;
}>;

const CarInfo: React.FC<CarInfoProps> = (props) => {
  const {
    views: { View, Text, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const [nickName, isFallback] = useGetCarNickName(props.car);
  const onReset = useCallback(() => {
    return dispatch.context.bind(Context.actions.updateCarNickName, {
      vin: props.car.vin,
      nickName: '',
    });
  }, [props.car.vin, dispatch]);

  const [isEditing, setEditing] = useState(false);
  const onEditStart = useCallback(() => {
    setEditing(true);
    return dispatch;
  }, [dispatch]);
  const onEditEnd = useCallback(() => {
    setEditing(false);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.carImageContainer}>
        <Image style={styles.carImage} src={props.car.image} />
      </View>

      <View style={styles.rowPadding}>
        <View style={styles.rowContainer}>
          <Text style={{ ...styles.editRow, ...styles.rowTitle }}>
            <Display id='myCarManagement_carName' />
            {isFallback ? null : (
              <View style={styles.editButton} onClick={onReset}>
                <Image src={Icons.ic_reset} />
              </View>
            )}
          </Text>
          {isEditing ? (
            <Text style={styles.editRow}>
              <View style={styles.rowText}>
                <CarNickNameInput
                  parent={props.parent}
                  style={styles.editInput}
                  car={props.car}
                  onChanged={onEditEnd}
                />
              </View>
            </Text>
          ) : (
            <Text style={styles.editRow} onClick={onEditStart}>
              {nickName}
              <View style={styles.editButton}>
                <Image src={Icons.ic_edit} />
              </View>
            </Text>
          )}
        </View>

        <View style={styles.rowContainer}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_carNumber' />
          </Text>
          <Text style={styles.rowText}>
            {props.car.attributes.carNo.getOrElse(() => '')}
          </Text>
        </View>

        <View style={styles.rowContainer}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_vin' />
          </Text>
          <Text style={styles.rowText}>{props.car.vin}</Text>
        </View>

        {isOwner(props.car) ? null : (
          <View style={styles.rowContainer}>
            <Text style={styles.rowTitle}>
              <Display id='myCarManagement_userType' />
            </Text>
            <Text style={styles.rowText}>
              <Display id={getUserTypeTextId(props.car.userType)} />
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

type UsersProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  controller: WindowStackController;
  car: Vehicle;
}>;

const Users: React.FC<UsersProps> = (props) => {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const [rootProps, controller] = useRootWindow(props.controller.parent);
  const openAddUserWindow = React.useCallback(() => {
    controller.showWindow();
    return dispatch;
  }, [controller, dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.rowHeader}>
        <Text style={styles.rowHeaderTitle}>
          <Display id='myCarManagement_userHeader' />
        </Text>
      </View>

      <ErrorBoundary
        cacheKey={getSharedUserListKey(props.car.vin)}
        fallback={<Loading />}
      >
        <Suspense fallback={<Loading />}>
          <UserList {...props} />
        </Suspense>
      </ErrorBoundary>

      {isOwner(props.car) ? (
        <>
          <View style={styles.rowPadding} onClick={openAddUserWindow}>
            <View style={styles.rowContainer}>
              <Text style={styles.actionText}>
                <Display id='myCarManagement_addUser' />
              </Text>
            </View>
          </View>
          <InviteSharedUser
            parent={props.parent}
            props={rootProps}
            controller={controller}
            vin={props.car.vin}
          />
        </>
      ) : null}
    </View>
  );
};

const UserList: React.FC<UsersProps> = (props) => {
  const { data } = useSWR(
    getSharedUserListKey(props.car.vin),
    () => getSharedUserList(props.car.vin),
    { suspense: true },
  );

  return data == null || data.length === 0 ? null : (
    <UserListBody {...props} users={data} />
  );
};

const UserListBody: React.FC<UsersProps & { users: SharedUser[] }> = (
  props,
) => {
  const {
    views: { View, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const [subProps, controller] = useSubWindow(props.controller);
  const [selectedUser, setSelectedUser] = React.useState<SharedUser>(
    props.users[0],
  );

  const openUserWindow = React.useCallback(
    (user: SharedUser) => () => {
      setSelectedUser(user);
      controller.showWindow();
      return dispatch;
    },
    [controller, dispatch],
  );

  return (
    <>
      {props.users.map((user, i) => (
        <View
          key={user.sharedUserId}
          style={styles.userRow}
          onClick={openUserWindow(user)}
        >
          <Image style={styles.userImage} src={Icons.ic_owner} />
          <View
            style={{
              ...styles.rowContainer,
              ...styles.wideRow,
              ...(i === 0 ? styles.noBorder : {}),
            }}
          >
            <UserItem parent={props.parent} user={user} />
            <Image style={styles.forwardButton} src={Icons.ic_forward_normal} />
          </View>
        </View>
      ))}

      <UserManagement
        parent={props.parent}
        props={subProps}
        controller={controller}
        user={selectedUser}
        vin={props.car.vin}
      />
    </>
  );
};

type UserItemProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  user: SharedUser;
}>;

const UserItem: React.FC<UserItemProps> = (props) => {
  const {
    views: { View, Text },
  } = useParentStore({ parent: props.parent });

  const [nickName] = useGetUserNickName(props.user);

  return (
    <View style={styles.userRowBody}>
      <Text style={styles.rowTitle}>{nickName}</Text>
      <Text style={styles.subText}>
        <Display id={getUserTypeTextId(props.user.userType)} />
      </Text>
    </View>
  );
};

type ServiceUsageInfoProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

const ServiceUsageInfo: React.FC<ServiceUsageInfoProps> = (props) => {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent: props.parent });

  // TODO: API がまだ無いのでダミーデータを表示する。

  const openSubscriptionLink = React.useCallback(() => {
    const url = config[BuildOpt.env].subscriptionEndpoint;
    if (url.length > 0) {
      openLink(url);
    }
    return dispatch;
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <View style={styles.rowHeader}>
        <Text style={styles.rowHeaderTitle}>
          <Display id='myCarManagement_contractHeader' />
        </Text>
      </View>
      <View style={styles.rowPadding}>
        <View style={{ ...styles.rowContainer, ...styles.noBorder }}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_remoteAccessStartDate' />
          </Text>
          <Text style={styles.rowText}>2021/9/1〜</Text>
        </View>
        <View style={{ ...styles.rowContainer, ...styles.wideRow }}>
          <View style={styles.serviceRowBody}>
            <Text style={styles.rowTitle}>
              <Display id='myCarManagement_remoteAccessUsageFee' />
            </Text>
            <Text style={styles.subText}>
              <Display id='myCarManagement_remoteAccessText' />
            </Text>
          </View>
          <Text style={styles.rowText}>300円/月</Text>
        </View>

        <View style={{ ...styles.rowContainer, ...styles.wideRow }}>
          <View style={styles.serviceRowBody}>
            <Text style={styles.rowTitle}>
              <Display id='myCarManagement_vehicleWifiExpireDate' />
            </Text>
            <Text style={styles.subText}>
              <Display id='myCarManagement_vehicleWifiText' />
            </Text>
          </View>
          <Text style={styles.rowText}>2021/11/30</Text>
        </View>
        <View style={styles.rowContainer}>
          <Text style={styles.rowTitle}>
            <Display id='myCarManagement_vehicleWifiUsageStatus' />
          </Text>
          <Text style={styles.rowText}>3GB</Text>
        </View>

        <View style={styles.rowContainer} onClick={openSubscriptionLink}>
          <Text style={styles.actionText}>
            <Display id='myCarManagement_applyConnectService' />
          </Text>
        </View>
      </View>
    </View>
  );
};

type CarManagementProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  controller: WindowStackController;
  car: Vehicle;
}>;

const CarManagement: React.FC<CarManagementProps> = (props) => {
  const {
    views: { View, Text, Image },
  } = useParentStore({ parent: props.parent });

  const intl = useIntl();
  const [nickName] = useGetCarNickName(props.car);
  const asyncAction = useCallback(
    () => deleteVehicle(props.car.vin),
    [props.car.vin],
  );
  const onClose = useCallback(
    () => props.controller.closeWindowStack(),
    [props.controller],
  );

  return (
    <View style={styles.container}>
      <View style={styles.rowHeader}>
        <Text style={styles.rowHeaderTitle}>
          <Display id='myCarManagement_registerManagement' />
        </Text>
      </View>
      <AsyncActionView
        parent={props.parent}
        style={{ ...styles.rowContainer, ...styles.noBorder }}
        dialogOptions={{
          title: intl.formatMessage({
            id: 'myCarManagement_dialogRemoveCarTitle',
          }),
          message: intl.formatMessage(
            { id: 'myCarManagement_dialogRemoveCarMessage' },
            { yourcar: nickName },
          ),
          confirmButtonTitle: intl.formatMessage({
            id: 'myCarManagement_dialogRemoveUserDismiss',
          }),
        }}
        action={asyncAction}
        onFinished={onClose}
      >
        <View style={styles.deleteContainer}>
          <View style={styles.deleteButton}>
            <Image src={Icons.ic_delete} />
          </View>
          <Text style={styles.actionText}>
            <Display id='myCarManagement_dialogRemoveCarTitle' />
          </Text>
        </View>
      </AsyncActionView>
      <LineSpace height={Length.px(0.5)} backgroundColor={Colors.border} />
    </View>
  );
};

export const VehicleInformation: React.FC<Props> = (props) => {
  const intl = useIntl();

  return (
    <RootWindow
      {...props.props}
      title={intl.formatMessage({ id: 'myCarManagement_pageTitleMycarInfo' })}
    >
      {props.car == null ? null : (
        <>
          <CarInfo parent={props.parent} car={props.car} />
          <Users
            parent={props.parent}
            controller={props.controller}
            car={props.car}
          />
          {isOwner(props.car)
            ? theme(<ServiceUsageInfo parent={props.parent} />, null)
            : null}
          <CarManagement
            parent={props.parent}
            controller={props.controller}
            car={props.car}
          />
        </>
      )}
    </RootWindow>
  );
};
