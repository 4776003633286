import React from 'react';
import { copy } from '../../util/Copyable';
import { Option } from '../../util/Option';
import { Shape } from '../components/Shape';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';

export type IcButtonData = Data;
export type IcButtonDataMap<Parent> = DM<Parent>;
export type IcButtonDispatch<Parent> = DP<Parent>;

type Key = 'icButton';

type Data = Readonly<{
  localState: LocalState;
  container: Shape;
}>;

type LocalState = Readonly<{
  buttonPressed: boolean;
}>;

type Actions = Readonly<{
  updateButtonPressed: (data: Data, buttonPressed: boolean) => Data;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type DMC<Parent> = WithContext<DM<Parent>>;
type DP<Parent> = DataMapDispatch<DMC<Parent>>;

type Props<Parent> = Readonly<{
  parent?: DataMapStore<Parent>;
  image: string;
  imageWidth: Length | Percentage;
  imageHeight: Length | Percentage;
  disabled?: boolean;
  click?: boolean;
  onClick?: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
}>;

const actions: Actions = {
  updateButtonPressed: (data: Data, buttonPressed: boolean) =>
    copy(data, {
      localState: { buttonPressed },
    }),
};

export function IcButton<Parent>(props: Props<Parent>): React.ReactElement {
  const {
    views: { View, Image },
    data: { icButton: data },
    dispatch,
  } = useStore<Key, Data, Parent>({
    key: 'icButton',
    parent: props.parent,
    default: () => ({
      localState: {
        buttonPressed: false,
      },
      container: Shape({}),
    }),
    update: ({ dispatch }) => {
      if (props.click && props.onClick) {
        return dispatch.unsafeCast<DataMapDispatch<WithContext<Parent>>>((_) =>
          props.onClick ? props.onClick(_) : _,
        );
      } else {
        return dispatch;
      }
    },
  });

  const localState = data.localState;
  const pressedOpacity = 0.5;
  const disableOpacity = 0.5;

  return (
    <View
      ref={data.container.getRef()}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: localState.buttonPressed ? pressedOpacity : 1.0,
        WebkitTapHighlightColor: 'transparent',
      }}
      onTouchStart={(evt) => {
        evt.stopPropagation();
        if (props.disabled === true) {
          return dispatch;
        } else {
          return dispatch.icButton(actions.updateButtonPressed, true);
        }
      }}
      onTouchEnd={(evt) => {
        evt.stopPropagation();
        if (props.disabled === true) {
          return dispatch;
        } else {
          return dispatch.icButton(actions.updateButtonPressed, false);
        }
      }}
      onClick={(evt) => {
        if (props.disabled === true) {
          return dispatch;
        } else {
          return Option(props.onClick)
            .map((f) => {
              evt.stopPropagation();
              return dispatch.unsafeCast<DataMapDispatch<WithContext<Parent>>>(
                (_) => f(_),
              );
            })
            .getOrElse(() => dispatch);
        }
      }}
    >
      <Image
        src={props.image}
        style={{
          height: props.imageHeight,
          width: props.imageWidth,
          opacity: props.disabled ? disableOpacity : 1.0,
        }}
      />
    </View>
  );
}
