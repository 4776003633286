import React from 'react';
import { copy } from '../../util/Copyable';
import { Duration } from '../../util/Duration';
import { sleep } from '../../util/Sleep';
import { Message } from '../i18n/Intl';
import { Context, ViewPort } from '../states/Context';
import { DataMapDispatch, WithContext, useStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transition } from '../style/Transition';
import { Shape } from './Shape';

export type ToastData = Readonly<{
  localState: LocalState;
  container: Shape;
}>;

type LocalState = Readonly<{
  isOpen: boolean;
}>;

export type ToastDataMap = WithContext<Readonly<{ toast: ToastData }>>;

export type ToastState = 'Show' | 'Hide';

type ToastActions = Readonly<{
  updateIsOpen: (data: ToastData, isOpen: boolean) => ToastData;
}>;

type Props = Readonly<{
  state: ToastState;
  message: Message;
  onClick?: (_: DataMapDispatch<ToastDataMap>) => DataMapDispatch<ToastDataMap>;
}>;

const duration = Time.ms(300);
const lifetime = Duration.milliSeconds(3000);
const sideMargin = 16;
const bottomMargin = 17;

function getContainerStyle(
  isOpen: boolean,
  container: Shape,
  viewPort: ViewPort,
): OptionalStyles {
  const style: OptionalStyles = {
    position: 'absolute',
    left: Length.px(0),
    top: Length.px(viewPort.height),
    width: Length.px(viewPort.width),
    paddingLeft: Length.px(sideMargin),
    paddingRight: Length.px(sideMargin),
  };

  return container
    .getBound()
    .map<OptionalStyles>((bound) => {
      if (isOpen) {
        return {
          ...style,
          top: Length.px(viewPort.height - bound.height - bottomMargin),
          transition: Transition('top', duration, TimingFunction.easeOut),
          opacity: 1,
        };
      } else {
        return {
          ...style,
          top: Length.px(viewPort.height),
          transition: Transition('top', duration, TimingFunction.easeOut),
        };
      }
    })
    .getOrElse(() => style);
}

export const ToastActions: ToastActions = {
  updateIsOpen: (data: ToastData, isOpen: boolean) =>
    copy(data, {
      localState: {
        isOpen,
      },
    }),
};

export function Toast(props: Props): React.ReactElement {
  const state = useStore<'toast', ToastData>({
    key: 'toast',
    default: () => ({
      localState: {
        isOpen: false,
      },
      container: Shape({}),
    }),
    update: ({ data: { toast }, dispatch }) => {
      const localState = toast.localState;
      if (props.state === 'Show' && localState.isOpen === false) {
        return dispatch
          .toast(ToastActions.updateIsOpen, true)
          .context(Context.actions.showToast, { state: 'Hide' })
          .asyncAll(
            sleep(lifetime.toMilliSeconds()).then((_) =>
              dispatch.toast(ToastActions.updateIsOpen, false),
            ),
          );
      } else {
        return dispatch;
      }
    },
  });

  const { View, Text } = state.views;
  const Container = state.views.toast.container;
  const data = state.data.toast;
  const localState = data.localState;
  const viewPort = state.data.context.viewPort;
  const dispatch = state.dispatch;

  return (
    <Container
      style={getContainerStyle(localState.isOpen, data.container, viewPort)}
      onTransitionEnd={() =>
        dispatch.toast((data) =>
          copy(data, {
            container: data.container.updateStyles({
              opacity: data.localState.isOpen ? 1 : 0,
            }),
          }),
        )
      }
    >
      <View
        style={{
          backgroundColor: Colors.primary,
          filter: data.localState.isOpen
            ? DropShadow(
                Length.px(0),
                Length.px(0),
                Length.px(4),
                Colors.shadowMoreDark,
              )
            : undefined,
          borderRadius: Length.px(24),
          padding: Length.px(15),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={(evt) => {
          evt.stopPropagation();
          return props.onClick ? props.onClick(dispatch) : dispatch;
        }}
      >
        <Text
          style={{
            display: 'inline-block',
            fontSize: Length.px(14),
            fontWeight: 'bold',
            color: Colors.buttonText,
            lineHeight: Length.px(14),
          }}
        >
          {props.message.toReactNode()}
        </Text>
      </View>
    </Container>
  );
}
