import React from 'react';
import { LineSpace } from '../components/LineSpace';
import {
  HeaderLeft,
  SlideInWindow,
  SlideInWindowMode,
} from '../components/SlideInWindow';
import { Message, useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import {
  ServiceReminderActions,
  ServiceReminderDataMap,
} from './ServiceReminderCard';

function Item({
  date,
  odo,
  content,
}: Readonly<{
  date: Date;
  odo: number;
  content: Message;
}>): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();
  const intl = useIntl();

  return (
    <View
      style={{
        paddingTop: Length.px(8),
        paddingLeft: Length.px(16),
      }}
    >
      <View
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(24),
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
      >
        <Text>
          {intl.formatMessage({ id: 'Remainder_day' }).toReactNode()}:
          {intl.formatDate(date)}
        </Text>

        <Text>
          {intl.formatMessage({ id: 'Remainder_odoMeter' }).toReactNode()}:
          {odo.toLocaleString('en-US')}
        </Text>

        <Text>
          {intl.formatMessage({ id: 'Remainder_exp' }).toReactNode()}:
          {content.toReactNode()}
        </Text>

        <LineSpace height={Length.px(16)} />
      </View>
    </View>
  );
}

export function MaintenanceHistory({
  mode,
  parent,
}: Readonly<{
  mode: SlideInWindowMode;
  parent: DataMapStore<ServiceReminderDataMap>;
}>): React.ReactElement {
  const {
    views: { View },
  } = useParentStore({ parent });
  const Body = View;
  const intl = useIntl();
  const actions = ServiceReminderActions.get();

  return (
    <SlideInWindow
      parent={parent}
      mode={mode}
      title={intl.formatMessage({ id: 'ShopHistory_pageTitle' })}
      onClose={(dispatch) =>
        dispatch
          .context(Context.actions.updateCardInfo, {
            state: 'Closing',
          })
          .serviceReminder(actions.updateMaintenanceHistoryMode, 'Hide')
      }
      headerLeft={
        <HeaderLeft
          parent={parent}
          onClick={(dispatch) =>
            dispatch.serviceReminder(
              actions.updateMaintenanceHistoryMode,
              'Hide',
            )
          }
        />
      }
    >
      <Body>
        <Item
          date={new Date('2020-01-01T00:00:00+09:00')}
          odo={5050}
          content={intl.formatMessage({ id: 'ShopHistory_exp_1' })}
        />

        <Item
          date={new Date('2019-08-01T00:00:00+09:00')}
          odo={2300}
          content={intl.formatMessage({ id: 'ShopHistory_exp_2' })}
        />
      </Body>
    </SlideInWindow>
  );
}
