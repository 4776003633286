import * as Client from '@auto/monaka-client/dist/20';
import { config } from '../Config';
import { BuildOpt } from '../util/BuildOpt';

export const AuthClient = Client.Auth;

export const AppSyncClient = new Client.GraphQLClient({
  url: config[BuildOpt.env].appSyncEndpoint,
  region: config[BuildOpt.env].appSyncRegion,
  credentials: () => Client.Auth.currentCredentials(Client.Scope.Mobile),
});

// export const IotClient = new Client.IotClient({
//   endpoint: config[BuildOpt.env].iotEndpoint,
//   credentials: () => Client.Auth.currentCredentials(Client.Scope.Iot),
// });
export const IotClient = AppSyncClient;
