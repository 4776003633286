import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import {
  ReactNativeWindow,
  removeLatestVehicleLocation,
  sendNativeEvent,
} from '../../apis/NativeApi';
import { BuildOpt } from '../../util/BuildOpt';
import { Locale } from '../../util/Locale';
import { useParentStore } from '../states/ContextStore';
import { DebugPaneActions, ParentProps } from './DebugPane';
import { dispatchDB } from './Helpers';
import { Item } from './Item';
import { MockDevice } from './MockDevice';
import { Subtitle } from './Subtitle';

export function NativeSettingsPane({
  parent,
}: ParentProps): React.ReactElement {
  const {
    views: { Text },
    data: { context, debug: data },
    dispatch,
  } = useParentStore({ parent });
  const db = data.db;

  return (
    <>
      <Subtitle title='Native Settings' />

      <Item
        parent={parent}
        text={<Text>Give GPS permission.</Text>}
        icon={
          data.userLocationPermission ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!ReactNativeWindow.isOnMockDevice}
        onClick={() =>
          dispatch.asyncAll(
            MockDevice.get()
              .updateLocationPermission(!data.userLocationPermission)
              .then((permission) =>
                dispatch.debug(
                  DebugPaneActions.updateUserLocationPermission,
                  permission,
                ),
              ),
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Enable GPS.</Text>}
        icon={
          data.userLocationEnable ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={!ReactNativeWindow.isOnMockDevice}
        onClick={() =>
          dispatch.asyncAll(
            MockDevice.get()
              .updateLocationEnable(!data.userLocationEnable)
              .then((flag) =>
                dispatch.debug(DebugPaneActions.updateUserLocationEnable, flag),
              ),
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Cause a user location error.</Text>}
        icon={
          data.userLocationError ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={BuildOpt.env !== 'mock' && BuildOpt.env !== 'dev'}
        onClick={() =>
          dispatch.asyncAll(
            MockDevice.get()
              .updateLocationError(!data.userLocationError)
              .then((error) =>
                dispatch.debug(DebugPaneActions.updateUserLocationError, error),
              ),
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Delete a latest location of the selected vehicle.</Text>}
        icon={
          data.hasLatestVehicleLocation ? (
            <CheckCircleIcon />
          ) : (
            <CheckCircleOutlineIcon />
          )
        }
        disable={false}
        onClick={() =>
          dispatch.asyncAll(
            context.selectedVehicleVin.unwrap(
              async (vin) => {
                await removeLatestVehicleLocation(vin);
                return dispatch.debug(
                  DebugPaneActions.updateHasLatestVehicleLocation,
                  false,
                );
              },
              async () => dispatch,
            ),
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Set SSID to car WIFI SSID.</Text>}
        icon={<SendIcon />}
        disable={false}
        onClick={() =>
          dispatchDB(
            db,
            async () => {
              sendNativeEvent({
                target: 'ssid',
                ssid: context.wifiStatus.currentSSID,
                bssid: context.ssidInfo.bssid,
              });
              return db;
            },
            dispatch,
            {
              close: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Clear WIFI SSID and BSSID.</Text>}
        icon={<SendIcon />}
        disable={false}
        onClick={() =>
          dispatchDB(
            db,
            async () => {
              sendNativeEvent({
                target: 'ssid',
                ssid: '',
                bssid: '',
              });
              return db;
            },
            dispatch,
            {
              close: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Send a locale event from the OS.</Text>}
        icon={<SendIcon />}
        disable={!ReactNativeWindow.isOnMockDevice}
        onClick={() =>
          dispatch
            .effect(() =>
              MockDevice.get().updateLocale(
                context.locale.lang === 'ja'
                  ? Locale('en', 'JP')
                  : Locale('ja', 'JP'),
              ),
            )
            .debug(DebugPaneActions.updateState, 'Close')
        }
      />

      <Item
        parent={parent}
        text={<Text>Send a locale event with en_MY from the OS.</Text>}
        icon={<SendIcon />}
        disable={!ReactNativeWindow.isOnMockDevice}
        onClick={() =>
          dispatch
            .effect(() => MockDevice.get().updateLocale(Locale('en', 'MY')))
            .debug(DebugPaneActions.updateState, 'Close')
        }
      />

      <Item
        parent={parent}
        text={<Text>Send a back key event from the OS.</Text>}
        icon={<SendIcon />}
        disable={!ReactNativeWindow.isOnMockDevice}
        onClick={() =>
          dispatchDB(
            db,
            async () => {
              sendNativeEvent({
                target: 'action',
                action: 'back',
              });
              return db;
            },
            dispatch,
            {
              close: true,
            },
          )
        }
      />

      <Item
        parent={parent}
        text={<Text>Send a QR code data.</Text>}
        icon={<SendIcon />}
        disable={!ReactNativeWindow.isOnMockDevice}
        onClick={() =>
          dispatchDB(
            db,
            async () => {
              sendNativeEvent({
                target: 'qrCodeData',
                invitationCode: 'ldmy8004-020a-4c99-821b-1643cb7cb3e2',
                invitationExpireTime: 1635211119,
              });
              return db;
            },
            dispatch,
            {
              close: true,
            },
          )
        }
      />
    </>
  );
}
