import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Actions, Reducer } from '../states/Reducer';

export interface Swipe {
  readonly localState: LocalState;
}

interface LocalState {
  readonly touches: Array<{ touch: Vector; time: number }>;
  readonly dxy: Vector;
}

type Acts = Actions<
  Swipe,
  {
    readonly onTouchStart: (data: Swipe, touch: Vector) => Swipe;
    readonly onTouchMove: (data: Swipe, touch: Vector) => Swipe;
    readonly onTouchEnd: (data: Swipe) => Swipe;
  }
>;

type Statics = Reducer<Swipe, Acts>;

interface Factory {
  (): Swipe;
}

const dt = 100;

const factory: Factory = () => ({
  localState: {
    touches: [],
    dxy: Vector(0, 0),
  },
});

const statics: Statics = {
  initialize: (data: Swipe) => data,

  actions: {
    onTouchStart: (data: Swipe, touch: Vector) =>
      copy(data, {
        localState: {
          touches: [{ touch, time: Date.now() }],
        },
      }),

    onTouchMove: (data: Swipe, touch: Vector) => {
      const touches = data.localState.touches;
      const now = Date.now();
      while (touches.length > 0 && now - touches[0].time > dt) {
        touches.shift();
      }
      touches.push({ touch, time: now });

      return copy(data, {
        localState: {
          touches,
        },
      });
    },

    onTouchEnd: (data: Swipe) => {
      const touches = data.localState.touches;
      const now = Date.now();
      while (touches.length > 0 && now - touches[0].time > dt) {
        touches.shift();
      }
      const dxy =
        touches.length > 0
          ? touches[touches.length - 1].touch.subtract(touches[0].touch)
          : Vector(0, 0);
      return copy(data, {
        localState: {
          dxy,
        },
      });
    },
  },
};

export const Swipe: Factory & Statics = Object.assign(factory, statics);
