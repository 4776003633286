import { Either, Left, Right } from './Either';
import { Anomaly } from './Error';
import { None, Option, Some } from './Option';

export interface Result<A> extends Either<Anomaly, A> {
  readonly kind: 'Result';
  readonly map: <B>(f: (a: A) => B) => Result<B>;
  readonly flatMap: <B>(f: (a: A) => Result<B>) => Result<B>;
  readonly isSuccess: () => boolean;
  readonly getSuccess: () => Option<A>;
  readonly getFailure: () => Option<Anomaly>;
  readonly onResult: (
    onSuccess: (a: A) => void,
    onFailure: (err: Anomaly) => void,
  ) => void;
  readonly transform: <B>(
    s: (a: A) => Result<B>,
    f: (e: Anomaly) => Result<B>,
  ) => Result<B>;
  readonly unwrap: <B>(s: (a: A) => B, f: (e: Anomaly) => B) => B;
  readonly recover: (f: (err: Anomaly) => Result<A>) => Result<A>;
  readonly getOrElse: (f: (err: Anomaly) => A) => A;
  readonly toOption: () => Option<A>;
  readonly toPromise: () => Promise<A>;
  readonly equals: (a: Result<A>) => boolean;
}

function extendsEither<A>(either: Either<Anomaly, A>): Result<A> {
  const self: Result<A> = {
    ...either,

    kind: 'Result',

    map: <B>(f: (a: A) => B) => extendsEither(either.map(f)),

    flatMap: <B>(f: (a: A) => Result<B>) => extendsEither(either.flatMap(f)),

    isSuccess: either.isRight,

    getSuccess: either.getRight,

    getFailure: either.getLeft,

    onResult: (onSuccess: (a: A) => void, onFailure: (err: Anomaly) => void) =>
      either.unwrap(onSuccess, onFailure),

    transform: <B>(s: (a: A) => Result<B>, f: (e: Anomaly) => Result<B>) =>
      extendsEither(either.unwrap(s, f)),

    unwrap: <B>(s: (a: A) => B, f: (e: Anomaly) => B) => either.unwrap(s, f),

    recover: (f: (err: Anomaly) => Result<A>) =>
      self
        .getFailure()
        .map((err) => f(err))
        .getOrElse(() => self),

    getOrElse: (f: (err: Anomaly) => A) =>
      self.getSuccess().getOrElse(() =>
        self
          .getFailure()
          .map((err) => f(err))
          .getOrElse(() => f(Anomaly(0, Error('Unknown error')))),
      ),

    toOption: () =>
      self.unwrap(
        (a) => Some(a),
        () => None(),
      ),

    toPromise: () =>
      self
        .map((a) => Promise.resolve(a))
        .getOrElse((err) => Promise.reject(err)),

    equals: (a: Result<A>) =>
      self.getSuccess().equals(a.getSuccess()) ||
      self.getFailure().equals(a.getFailure()),
  };
  return self;
}

export function Success<A>(a: A): Result<A> {
  return extendsEither(Right(a));
}

export function Failure<A>(err: Anomaly): Result<A> {
  return extendsEither(Left(err));
}
