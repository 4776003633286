/**
 * The structure of `DispatchBase`:
 * + DispatchBaseCommon
 *   + DispatchComposeBase
 *     - compose: (dispatch) -> DispatchBase
 *   + DispatchMethodBase
 *     - dispatch: (stores) => void
 *   + DispatchGroupBase
 *     - asyncAll: (promise|listener) => DispatchBase
 *     - current: (callback) => DispatchBase
 *     - keys
 *       + BindMethodBase
 *         - (action, args) -> DispatchBase
 *         - (action) -> DispatchBase
 *       + DispatchGroupBaseWoConst
 *         + DispatchEventMethodsBase
 *           + DispatchBindMethodBase
 *             - bind: (action, args) -> DispatchBase
 *             - bind: (action) -> DispatchBase
 *           - listen: (dispatch) -> void
 *           - async: (dispatch) -> void
 *           - put: (action, args) -> DispatchBase
 *         - state
 *           + DispatchStateMethodsBase
 *             + DispatchBindMethodBase
 *               - bind: (action, args) -> DispatchBase
 *               - bind: (action) -> DispatchBase
 *         - event
 *           + DispatchEventMethodsBase
 *             + DispatchBindMethodBase
 *               - bind: (action, args) -> DispatchBase
 *               - bind: (action) -> DispatchBase
 *             - listen: (dispatch) -> void
 *             - async: (dispatch) -> void
 *             - put: (action, args) -> DispatchBase
 *   - isPipable:
 *   - isNone:
 *   - effect:
 *   - send:
 *   - orElse:
 *   - pipe:
 * --- if self is specified ---
 * + DispatchEventMethodsBase
 *   + DispatchBindMethodBase
 *     - bind: (action, args) -> DispatchBase
 *     - bind: (action) -> DispatchBase
 *   - listen: (dispatch) -> void
 *   - async: (dispatch) -> void
 *   - put: (action, args) -> DispatchBase
 * - kind:
 *
 * The JSON structure of Dispatch
 * {
 *   kind;
 *   compose;
 *   dispatch;
 *   asyncAll;
 *   isPipable;
 *   isNone;
 *   effect;
 *   send;
 *   orElse;
 *   pipe;
 *   bind;
 *   listen;
 *   async;
 *   put;
 *   self: {
 *     bind;
 *     listen;
 *     async;
 *     put;
 *     state: {
 *       bind;
 *     };
 *     event: {
 *       bind;
 *       listen;
 *       async;
 *       put;
 *     };
 *   };
 *   parent: {
 *     bind;
 *     listen;
 *     async;
 *     put;
 *     state: {
 *       bind;
 *     };
 *     event: {
 *       bind;
 *       listen;
 *       async;
 *       put;
 *     };
 *   };
 * }
 *
 * The structure of a DispatchItem
 * {
 *  state: DispatchItemState;
 *  event: DispatchItemAsync;
 * }
 *
 * The structure of a DispatchItems
 * {
 *   asyncAll: DispatchAsyncAllItem;
 *   current: DispatchCurrentItem;
 *   key1: DispatchItem;
 *   key2: DispatchItem;
 *   ...
 * }
 *
 * Creating a DiapatchBase
 * ```
 *   createDispatchBase(DispatchItems)
 * ```
 *
 * @module
 */
import { ToOptional, copy } from '../../util/Copyable';
import { Sender, UnsafeSender } from '../../util/Listener';
import { None, Option, Some } from '../../util/Option';
import { toPipe } from '../../util/Pipe';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import { Context } from './Context';
import {
  DispatchableDataMapState,
  IndexedDispatchables,
  WithSetCallback,
  asWithSetCallback,
} from './DataMapStore';
import {
  AsyncAllCallback,
  DispatchAsyncAllItem,
  createDispatchAsyncAllItem,
} from './DispatchAsyncItem';
import {
  CurrentCallback,
  DispatchCurrentItem,
  createDispatchCurrentItem,
} from './DispatchCurrentItem';
import {
  ComposableItem,
  DispatchItemAsync,
  DispatchItemState,
  ItemType,
  createDispatchItemEvent,
  createDispatchItemState,
} from './DispatchItem';
import { Action } from './Reducer';
import { Dispatchable, Dispatcher } from './Store';

export type DataMapBase<Data> = {
  readonly self: Data;
  readonly context: Context;
};

/**
 * Type definition of the bind method with args for a specific key:
 * dispatch.key.bind(action, args)
 */
interface BindMethodBase1<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> {
  <Args>(action: Action<DataMap[Key], Args>, args: Args): DispatchBase<
    Kind,
    DataMap
  >;
}

/**
 * Type definition of the bind method without args for a specific key:
 * dispatch.key.bind(action)
 */
interface BindMethodBase2<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> {
  (action: (data: DataMap[Key]) => DataMap[Key]): DispatchBase<Kind, DataMap>;
}

/**
 * Type definition of the integrated overload bind methods
 */
export interface BindMethodBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> extends BindMethodBase1<Kind, DataMap, Key>,
    BindMethodBase2<Kind, DataMap, Key> {}

/**
 * Type definition of the listen method for specific key
 */
export type ListenMethodBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> = (
  f: (dispatch: Dispatcher<DataMap[Key]>) => void,
) => DispatchBase<Kind, DataMap>;

/**
 * Type definition of the async method for specific key
 */
export type AsyncMethodBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> = (
  f: (dispatch: Dispatcher<DataMap[Key]>) => void,
) => DispatchBase<Kind, DataMap>;

export type PutMethodBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> = <Args>(
  action: Action<DataMap[Key], Args>,
  args: Args,
) => DispatchBase<Kind, DataMap>;

/**
 * Type definition of the asyncAll method for the DataMap
 */
export type AsyncAllMethodBase<Kind extends KindKey, DataMap> = (
  f: AsyncAllCallback<DispatchBase<Kind, DataMap>>,
) => DispatchBase<Kind, DataMap>;

type CurrentMethodBase<Kind extends KindKey, DataMap> = (
  f: CurrentCallback<DataMap>,
) => DispatchBase<Kind, DataMap>;

// export type LatestMethodBase<DataMap> = unknown;

export interface DispatchBindMethodBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> {
  readonly bind: BindMethodBase<Kind, DataMap, Key>;
}

export type DispatchStateMethodsBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> = DispatchBindMethodBase<Kind, DataMap, Key>;

export interface DispatchEventMethodsBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> extends DispatchBindMethodBase<Kind, DataMap, Key> {
  readonly listen: ListenMethodBase<Kind, DataMap, Key>;
  readonly async: AsyncMethodBase<Kind, DataMap, Key>;
  readonly put: PutMethodBase<Kind, DataMap, Key>;
}

export type DispatchEventMethodsWithoutAsyncBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> = Exclude<DispatchEventMethodsBase<Kind, DataMap, Key>, 'async'>;

export interface DispatchGroupBaseWoConst<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> extends DispatchEventMethodsBase<Kind, DataMap, Key> {
  readonly state: DispatchStateMethodsBase<Kind, DataMap, Key>;
  readonly event: DispatchEventMethodsBase<Kind, DataMap, Key>;
}

export interface DispatchGroupBase<
  Kind extends KindKey,
  DataMap,
  Key extends keyof DataMap,
> extends BindMethodBase<Kind, DataMap, Key>,
    DispatchGroupBaseWoConst<Kind, DataMap, Key> {}

export type ItemGroupKeys<DataMap> = keyof DataMap;
//export type ItemKeys<DataMap> = ItemGroupKeys<DataMap> | 'asyncAll' | 'current';
export type ItemGroupType<
  Key extends ItemGroupKeys<DataMap>,
  DataMap,
> = DataMap[Key];

type WithCurrentMethodBase<Kind extends KindKey, DataMap> = Readonly<{
  current: CurrentMethodBase<Kind, DataMap>;
}>;

type DispatchGroupsBaseWithoutCurrent<Kind extends KindKey, DataMap> = Readonly<
  {
    asyncAll: AsyncAllMethodBase<Kind, DataMap>;
  } & {
    [Key in ItemGroupKeys<DataMap>]: DispatchGroupBase<Kind, DataMap, Key>;
  }
>;

export type DispatchGroupsBase<
  Kind extends KindKey,
  DataMap,
> = DispatchGroupsBaseWithoutCurrent<Kind, DataMap> &
  WithCurrentMethodBase<Kind, DataMap>;

export interface DispatchComposeBase<Kind extends KindKey, DataMap> {
  readonly compose: (
    dispatch: DispatchBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>;
}

export interface DispatchMethodBase<DataMap> {
  readonly dispatch: (stores: DispatchableDataMapState<DataMap>) => void;
}

//! export type Stores<DataMap> = {
//!   readonly [Key in keyof DataMap]: Store<DataMap[Key]>;
//! };

export type DispatchBaseCommon<
  Kind extends KindKey,
  DataMap,
> = DispatchComposeBase<Kind, DataMap> &
  DispatchMethodBase<DataMap> &
  DispatchGroupsBase<Kind, DataMap> &
  Readonly<{
    isPipable: true;

    isNone: boolean;

    effect: (f: () => void) => DispatchBase<Kind, DataMap>;

    send: <A>(sender: Sender<A>, a: A) => DispatchBase<Kind, DataMap>;

    orElse: (
      f: () => DispatchBase<Kind, DataMap>,
    ) => DispatchBase<Kind, DataMap>;

    pipe: (
      f: (a: DispatchBase<Kind, DataMap>) => DispatchBase<Kind, DataMap>,
    ) => DispatchBase<Kind, DataMap>;

    unsafeCast: <B>(f: (b: B) => B) => DispatchBase<Kind, DataMap>;
  }>;

export type KindKey = 'Dispatch' | 'DispatchRoot' | 'DataMapDispatch';

/**
 * @param Kind
 * @param DataMap
 * @param Key default key that can omit the key name to call
 */
export type DispatchBaseWithHelpers<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
> = Omit<DispatchBaseCommon<Kind, DataMap>, 'current'> &
  DispatchEventMethodsBase<Kind, DataMap, Key> & {
    readonly kind: Kind;
  };

type DataMapKind<Kind extends KindKey, D> = Kind extends
  | 'Dispatch'
  | 'DispatchRoot'
  ? D & { self: unknown }
  : D;

export type DispatchBase<
  Kind extends KindKey,
  DataMap,
> = Kind extends 'Dispatch'
  ? DispatchBaseWithHelpers<Kind, DataMapKind<Kind, DataMap>, 'self'>
  : Kind extends 'DispatchRoot'
  ? DispatchBaseWithHelpers<Kind, DataMapKind<Kind, DataMap>, 'self'>
  : DispatchBaseCommon<Kind, DataMap>;

export interface PrivateDispatchBase<Kind extends KindKey, DataMap> {
  readonly items: DispatchItemsBase<Kind, DataMap>;
}

export interface DispatchItemsMember<DataMap, Key extends keyof DataMap> {
  readonly state: Option<DispatchItemState<DataMap[Key]>>;
  readonly event: Option<DispatchItemAsync<DataMap[Key]>>;
}

export type WithDispatchAsyncAllItem<Kind extends KindKey, DataMap> = Readonly<{
  asyncAll: Option<DispatchAsyncAllItem<DataMap, DispatchBase<Kind, DataMap>>>;
}>;

export type WithDispatchCurrentItem<DataMap> = Readonly<{
  current: Option<DispatchCurrentItem<DataMap>>;
}>;

export type DispatchGroupItems<DataMap> = Readonly<{
  [Key in ItemGroupKeys<DataMap>]: DispatchItemsMember<DataMap, Key>;
}>;

type DispatchItemsBaseWithoutCurrent<
  Kind extends KindKey,
  DataMap,
> = DispatchGroupItems<DataMap> & WithDispatchAsyncAllItem<Kind, DataMap>;

export type DispatchItemsBase<
  Kind extends KindKey,
  DataMap,
> = DispatchItemsBaseWithoutCurrent<Kind, DataMap> &
  WithDispatchCurrentItem<DataMap>;

export function emptyDispatchItem<
  DataMap,
  Key extends keyof DataMap,
>(): DispatchItemsMember<DataMap, Key> {
  return {
    state: None<DispatchItemState<DataMap[Key]>>(),
    event: None<DispatchItemAsync<DataMap[Key]>>(),
  };
}

export function emptyDispatchItemsBase<Kind extends KindKey, DataMap>(
  keys: Array<keyof DataMap>,
): DispatchItemsBase<Kind, DataMap> {
  return keys.reduce(
    (obj, key) => {
      type Indexed = {
        [key: string]: DispatchItemsMember<DataMap, keyof DataMap>;
      };
      const _obj = obj as unknown as Indexed;
      _obj[key as string] = emptyDispatchItem<DataMap, keyof DataMap>();
      return obj;
    },
    {
      asyncAll: None(),
      current: None(),
    } as DispatchItemsBase<Kind, DataMap>,
  );
}

export function copyItemsStateBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  items: DispatchItemsBase<Kind, DataMap>,
  key: Key,
  state: Option<DispatchItemState<DataMap[Key]>>,
): DispatchItemsBase<Kind, DataMap> {
  const rep: ToOptional<DispatchItemsBase<Kind, DataMap>> = Scope(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj = {} as any;
    obj[key] = { state };
    return obj;
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return copy(items as any, rep);
}

export function createRepItemEventBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  event: Option<DispatchItemAsync<DataMap[Key]>>,
): ToOptional<DispatchItemsBase<Kind, DataMap>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const obj = {} as any;
  obj[key] = { event };
  return obj;
}

export function copyItemsEventBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  items: DispatchItemsBase<Kind, DataMap>,
  key: Key,
  event: Option<DispatchItemAsync<DataMap[Key]>>,
): DispatchItemsBase<Kind, DataMap> {
  return copy(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items as any,
    createRepItemEventBase<Kind, DataMap, Key>(key, event),
  );
}

function getItemState<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
): Option<DispatchItemState<DataMap[Key]>> {
  type IndexedItems = {
    readonly [key: string]: DispatchItemsMember<DataMap, Key>;
  };
  const _items = items as unknown as IndexedItems;
  return _items[key as string].state;
}

function getItemEvent<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
): Option<DispatchItemAsync<DataMap[Key]>> {
  type IndexedItems = {
    readonly [key: string]: DispatchItemsMember<DataMap, Key>;
  };
  const _items = items as unknown as IndexedItems;
  return _items[key as string].event;
}

export function createBindBase<
  Kind extends KindKey,
  IT extends ItemType,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  itemType: IT,
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): BindMethodBase<Kind, DataMap, Key> {
  if (itemType === 'State') {
    const itemState = getItemState<Kind, DataMap, Key>(key, items);
    const bind1: BindMethodBase1<Kind, DataMap, Key> = <Args>(
      action: Action<DataMap[Key], Args>,
      args: Args,
    ) => {
      const state = itemState
        .map((_) => _.bind(action, args))
        .orElse(() => Some(createDispatchItemState(action, args)));
      return create(copyItemsStateBase(items, key, state));
    };
    const bind2: BindMethodBase2<Kind, DataMap, Key> = (
      action: (data: DataMap[Key]) => DataMap[Key],
    ) => {
      const state = itemState
        .map((_) => _.bind(action, Unit))
        .orElse(() => Some(createDispatchItemState(action, Unit)));
      return create(copyItemsStateBase(items, key, state));
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bind = (arg1: any, arg2: any) => {
      if (arg2 === undefined) return bind2(arg1);
      else return bind1(arg1, arg2);
    };
    return bind as unknown as BindMethodBase<Kind, DataMap, Key>;
  } else {
    const itemEvent = getItemEvent<Kind, DataMap, Key>(key, items);
    const bind1: BindMethodBase1<Kind, DataMap, Key> = <Args>(
      action: Action<DataMap[Key], Args>,
      args: Args,
    ) => {
      const event = itemEvent
        .map((_) => _.bind(action, args))
        .orElse(() =>
          Some(createDispatchItemEvent((dispatch) => dispatch(action, args))),
        );
      return create(copyItemsEventBase(items, key, event));
    };
    const bind2: BindMethodBase2<Kind, DataMap, Key> = <_Args>(
      action: (data: DataMap[Key]) => DataMap[Key],
    ) => {
      const event = itemEvent
        .map((_) => _.bind(action, Unit))
        .orElse(() =>
          Some(createDispatchItemEvent((dispatch) => dispatch(action, Unit))),
        );
      return create(copyItemsEventBase(items, key, event));
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bind = (arg1: any, arg2: any) => {
      if (arg2 === undefined) return bind2(arg1);
      else return bind1(arg1, arg2);
    };
    return bind as unknown as BindMethodBase<Kind, DataMap, Key>;
  }
}

export function createListenItemBase<
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  item: Option<DispatchItemAsync<DataMap[Key]>>,
  f: (dispatch: Dispatcher<DataMap[Key]>) => void,
): Option<DispatchItemAsync<DataMap[Key]>> {
  return item
    .map((_) =>
      _.flatMap((action, args) =>
        createDispatchItemEvent((dispatch) =>
          f((action2, args2) =>
            dispatch((data, args) => action2(action(data, args), args2), args),
          ),
        ),
      ),
    )
    .orElse(() => Some(createDispatchItemEvent(f)));
}

export function createListenBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): ListenMethodBase<Kind, DataMap, Key> {
  const item = getItemEvent<Kind, DataMap, Key>(key, items);
  return (f: (dispatch: Dispatcher<DataMap[Key]>) => void) => {
    const event = createListenItemBase<DataMap, Key>(item, f);
    return create(copyItemsEventBase(items, key, event));
  };
}

export function createAsyncBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): AsyncMethodBase<Kind, DataMap, Key> {
  const item = getItemEvent<Kind, DataMap, Key>(key, items);
  return (f: (dispatch: Dispatcher<DataMap[Key]>) => void) => {
    const event = item
      .map((_) => _.async(f))
      .orElse(() => Some(createDispatchItemEvent(f)));
    return create(copyItemsEventBase(items, key, event));
  };
}

export function createPutBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): PutMethodBase<Kind, DataMap, Key> {
  const item = getItemEvent<Kind, DataMap, Key>(key, items);
  return <Args>(action: Action<DataMap[Key], Args>, args: Args) => {
    const event = item
      .map((_) => _.put(action, args))
      .orElse(() =>
        Some(createDispatchItemEvent((dispatch) => dispatch(action, args))),
      );
    return create(copyItemsEventBase(items, key, event));
  };
}

export function createAsyncAllBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): AsyncAllMethodBase<Kind, DataMap> {
  type IndexedItems = {
    readonly [key: string]: Option<
      DispatchAsyncAllItem<DataMap, DispatchBase<Kind, DataMap>>
    >;
  };
  const _items = items as unknown as IndexedItems;
  const item = _items['asyncAll'];
  return (f: AsyncAllCallback<DispatchBase<Kind, DataMap>>) => {
    const newItem = item
      .map((_) => _.async(f))
      .orElse(() =>
        Some(
          createDispatchAsyncAllItem<
            Kind,
            DataMap,
            DispatchBase<Kind, DataMap>
          >(f),
        ),
      );
    return create({
      ...items,
      asyncAll: newItem,
    });
  };
}

export function createCurrentBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): CurrentMethodBase<Kind, DataMap> {
  return (f: CurrentCallback<DataMap>) => {
    return create({
      ...items,
      current: items.current.unwrap(
        (_) => Some(_.current(f)),
        () => Some(createDispatchCurrentItem<DataMap>(f)),
      ),
    });
  };
}

export function createDispatchItemMethodsBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): DispatchGroupBase<Kind, DataMap, Key> {
  type _A = DataMap[Key];

  const bindState = createBindBase<Kind, 'State', DataMap, Key>(
    'State',
    key,
    items,
    create,
  );
  const bindEvent = createBindBase<Kind, 'Event', DataMap, Key>(
    'Event',
    key,
    items,
    create,
  );
  const listen = createListenBase<Kind, DataMap, Key>(key, items, create);
  const async = createAsyncBase<Kind, DataMap, Key>(key, items, create);
  const put = createPutBase<Kind, DataMap, Key>(key, items, create);

  const ms: DispatchGroupBaseWoConst<Kind, DataMap, Key> = {
    bind: bindState,
    listen,
    async,
    put,
    state: {
      bind: bindState,
    },
    event: {
      bind: bindEvent,
      listen,
      async,
      put,
    },
  };

  // function make<Args>(action: Action<A, Args>, args: Args): DispatchBase<DataMap> {
  //   return ms.state.bind(action, args);
  // };

  return Object.assign(bindState, ms);
}

export function dispatchMethodBaseHelper<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  state: DispatchableDataMapState<DataMap>,
  callItemDispatch: <Key extends keyof DataMap>(
    key: Key,
    item: DispatchItemsMember<DataMap, Key>,
    store: Dispatchable<DataMap[Key]>,
  ) => void,
  callCurrentDispatch: (
    item: DispatchCurrentItem<DataMap>,
    state: WithSetCallback<DataMap>,
  ) => void,
): void {
  type IndexedItems = {
    readonly [key: string]: DispatchItemsMember<DataMap, keyof DataMap>;
  };
  //! type IndexedStores = {
  //!   readonly [key: string]: DispatchableStore<DataMap[keyof DataMap]>;
  //! };
  const _items = items as unknown as IndexedItems;
  const _stores = state.stores as unknown as IndexedDispatchables<DataMap>;
  Object.keys(items).forEach((key) => {
    if (key !== 'asyncAll' && key !== 'current') {
      callItemDispatch(key as keyof DataMap, _items[key], _stores[key]);
    }
  });

  items.asyncAll.forEach((a) => a.dispatch(state));
  asWithSetCallback(state).forEach((s) =>
    items.current.forEach((a) => callCurrentDispatch(a, s)),
  );
}

export function dispatchMethodBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  state: DispatchableDataMapState<DataMap>,
): void {
  dispatchMethodBaseHelper(
    items,
    state,
    (key, item, store) => {
      item.state.forEach((a) => a.dispatch(store));
      item.event.forEach((a) => a.dispatch(store));
    },
    (item, state) => item.dispatch(state),
  );
}

export function isNoneMethodBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
): boolean {
  return Object.keys(items).every((key) => {
    if (key === 'asyncAll') {
      type IndexedAsyncItems = {
        [key: string]: Option<unknown>;
      };
      const _items = items as IndexedAsyncItems;
      return _items[key].isNone();
    } else if (key === 'current') {
      const _items = items as { [key: string]: Option<unknown> };
      return _items[key].isNone();
    } else {
      type IndexedGroupItems = {
        readonly [key: string]: DispatchItemsMember<DataMap, keyof DataMap>;
      };
      const _items = items as unknown as IndexedGroupItems;
      return _items[key].state.isNone() && _items[key].event.isNone();
    }
  });
}

function createDispatchGroupsBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): DispatchGroupsBase<Kind, DataMap> {
  return Object.keys(items).reduce((obj, key) => {
    if (key === 'asyncAll') {
      type IndexedGroups = {
        [key: string]: AsyncAllMethodBase<Kind, DataMap>;
      };
      const _obj = obj as unknown as IndexedGroups;
      _obj[key] = createAsyncAllBase<Kind, DataMap>(items, create);
      return obj;
    } else if (key === 'current') {
      const _obj = obj as unknown as {
        [key: string]: CurrentMethodBase<Kind, DataMap>;
      };
      _obj[key] = createCurrentBase<Kind, DataMap>(items, create);
      return obj;
    } else {
      type Key = ItemGroupKeys<DataMap>;
      type IndexedGroups = {
        [key: string]: DispatchGroupBase<Kind, DataMap, Key>;
      };
      const _obj = obj as unknown as IndexedGroups;
      _obj[key] = createDispatchItemMethodsBase<Kind, DataMap, Key>(
        key as Key,
        items,
        create,
      );
      return obj;
    }
  }, {} as DispatchGroupsBase<Kind, DataMap>);
}

function createHelpersBase<
  Kind extends KindKey,
  DataMap,
  Key extends ItemGroupKeys<DataMap>,
>(
  key: Key,
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): DispatchEventMethodsBase<Kind, DataMap, Key> {
  return {
    bind: createBindBase<Kind, 'State', DataMap, Key>(
      'State',
      key,
      items,
      create,
    ),
    listen: createListenBase<Kind, DataMap, Key>(key, items, create),
    async: createAsyncBase<Kind, DataMap, Key>(key, items, create),
    put: createPutBase<Kind, DataMap, Key>(key, items, create),
  };
}

function composeItem<Item extends ComposableItem<Item>>(
  item1: Option<Item>,
  item2: Option<Item>,
): Option<Item> {
  return item1
    .flatMap((a) => item2.map((b) => a.compose(b)).orElse(() => Some(a)))
    .orElse(() => item2);
}

const composeItemState = <Data>(
  item1: Option<DispatchItemState<Data>>,
  item2: Option<DispatchItemState<Data>>,
) => composeItem<DispatchItemState<Data>>(item1, item2);

const composeItemEvent = <Data>(
  item1: Option<DispatchItemAsync<Data>>,
  item2: Option<DispatchItemAsync<Data>>,
) => composeItem<DispatchItemAsync<Data>>(item1, item2);

function composeMethodBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  dispatch: PrivateDispatchBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap>,
): DispatchBase<Kind, DataMap> {
  const that = dispatch;

  const newItems = Object.keys(items).reduce((obj, key) => {
    if (key === 'asyncAll') {
      type IndexedAsyncItems = {
        [key: string]: Option<
          DispatchAsyncAllItem<DataMap, DispatchBase<Kind, DataMap>>
        >;
      };
      const _items1 = items as unknown as IndexedAsyncItems;
      const _items2 = that.items as unknown as IndexedAsyncItems;
      const _obj = obj as unknown as IndexedAsyncItems;
      _obj[key] = composeItem(_items1[key], _items2[key]);
      return obj;
    } else if (key === 'current') {
      type Indexed = { [key: string]: Option<DispatchCurrentItem<DataMap>> };
      const _items1 = items as unknown as Indexed;
      const _items2 = that.items as unknown as Indexed;
      const _obj = obj as unknown as Indexed;
      _obj[key] = composeItem(_items1[key], _items2[key]);
      return obj;
    } else {
      type Key = ItemGroupKeys<DataMap>;
      type IndexedGroupItems = {
        [key: string]: DispatchItemsMember<DataMap, Key>;
      };
      const _items1 = items as unknown as IndexedGroupItems;
      const _items2 = that.items as unknown as IndexedGroupItems;
      const _obj = obj as unknown as IndexedGroupItems;
      _obj[key] = {
        state: composeItemState(_items1[key].state, _items2[key].state),
        event: composeItemEvent(_items1[key].event, _items2[key].event),
      };
      return obj;
    }
  }, {} as DispatchItemsBase<Kind, DataMap>);
  return create(newItems);
}

export function createDispatchBase<Kind extends KindKey, DataMap>(
  items: DispatchItemsBase<Kind, DataMap>,
  create: (
    items: DispatchItemsBase<Kind, DataMap>,
  ) => DispatchBase<Kind, DataMap> = createDispatchBase,
): DispatchBase<Kind, DataMap> {
  type IndexedItems = {
    readonly [key: string]: DispatchItemsMember<DataMap, keyof DataMap>;
  };

  const groups = createDispatchGroupsBase<Kind, DataMap>(items, create);

  const common: DispatchBaseCommon<Kind, DataMap> &
    PrivateDispatchBase<Kind, DataMap> = {
    items,

    ...groups,

    dispatch: (stores: DispatchableDataMapState<DataMap>) => {
      dispatchMethodBase<Kind, DataMap>(items, stores);
    },

    isPipable: true,

    isNone: isNoneMethodBase(items),

    compose: (dispatch: DispatchBase<Kind, DataMap>) => {
      return composeMethodBase<Kind, DataMap>(
        items,
        dispatch as unknown as PrivateDispatchBase<Kind, DataMap>,
        create,
      );
    },

    effect: (f: () => void) => {
      f();
      return create(items);
    },

    send: <A>(sender: Sender<A>, a: A) => {
      (sender as UnsafeSender<A>).send(a);
      return create(items);
    },

    orElse: (f: () => DispatchBase<Kind, DataMap>) =>
      common.isNone ? f() : create(items),

    pipe: (
      f: (a: DispatchBase<Kind, DataMap>) => DispatchBase<Kind, DataMap>,
    ) => toPipe(f(create(items))),

    unsafeCast: <B>(f: (b: B) => B) =>
      f(create(items) as unknown as B) as unknown as DispatchBase<
        Kind,
        DataMap
      >,
  };

  const _items = items as unknown as IndexedItems;
  if (_items['self']) {
    type Key = keyof DataMap;
    const helpers = createHelpersBase<Kind, DataMap, Key>(
      'self' as Key,
      items,
      create,
    );
    const kind = _items['parent'] ? 'Dispatch' : 'DispatchRoot';
    return {
      kind,
      ...helpers,
      ...common,
    } as unknown as DispatchBase<Kind, DataMap>;
  } else {
    return common as unknown as DispatchBase<Kind, DataMap>;
  }
}

export function dispatchNothing<DataMap>(): DispatchMethodBase<DataMap> {
  return {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => {},
  };
}
