import React from 'react';
import { config } from '../../Config';
import ic_add from '../../assets/ic_add.svg';
import ic_alert from '../../assets/ic_alert.svg';
import { copy } from '../../util/Copyable';
import { Card, CardState } from '../components/Card';
import { CardHeader } from '../components/CardHeader';
import { LineSpace } from '../components/LineSpace';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import { useContextStore } from '../states/ContextStore';
import {
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import ic_LineCarnavi_normal from './ic_LineCarnavi_normal.png';
import ic_Navitime_normal from './ic_Navitime_normal.png';
import ic_YahooCarNavi_normal from './ic_YahooCarNavi_normal.png';

type Key = 'sdl';

type Data = Readonly<{
  localState: LocalState;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly cardState: CardState;
}

export type Command = 'CloseCard';

interface Actions extends CommandActions<Data, Command> {
  readonly updateCardState: (data: Data, cardState: CardState) => Data;
}

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

type DM = ExtendParent<HomeDataMap, Key, Data>;

const commandState = CommandState<Data, Command>();

function WarningContainer(props: { isClosed: boolean }): React.ReactElement {
  const {
    views: { View, Image, Text },
  } = useContextStore();
  const intl = useIntl();
  const isClosed = props.isClosed;

  if (isClosed) {
    return (
      <>
        <View />
        <LineSpace height={Length.px(40)} />
      </>
    );
  } else {
    return (
      <>
        <View
          style={{
            padding: Length.px(8),
            borderBottom: Border('solid', Length.px(4), Colors.borderLight),
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: Length.px(9),
              paddingBottom: Length.px(8),
              border: Border('solid', Length.px(1), Colors.thickBorder),
              borderRadius: Length.px(6),
            }}
          >
            <View>
              <Image
                src={ic_alert}
                style={{
                  width: Length.px(23),
                  height: Length.px(20),
                }}
              />
              <Text
                style={{
                  display: 'inline-block',
                  fontSize: Length.px(17),
                  fontWeight: 'bold',
                  paddingLeft: Length.px(4),
                }}
              >
                {intl.formatMessage({ id: 'Sdl_connectMessage' }).toReactNode()}
              </Text>
            </View>

            <LineSpace height={Length.px(9)} />

            <Text
              style={{
                fontSize: Length.px(17),
                color: Colors.accent,
              }}
            >
              {intl.formatMessage({ id: 'Sdl_connectHint' }).toReactNode()}
            </Text>
          </View>
        </View>
        <LineSpace height={Length.px(24)} />
      </>
    );
  }
}

function SDLImages(
  props: Readonly<{
    iconSize: number;
    iconMargin: number;
    iconMarginLeft: number;
  }>,
): React.ReactElement {
  const {
    views: { View, Image },
  } = useContextStore();
  const iconSize = props.iconSize;
  const iconMargin = props.iconMargin;
  const iconMarginLeft = props.iconMarginLeft;

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: Length.px(iconMarginLeft),
        paddingBottom: Length.px(20),
      }}
    >
      <Image
        src={ic_YahooCarNavi_normal}
        style={{
          height: Length.px(iconSize),
          width: Length.px(iconSize),
        }}
      />

      <View style={{ width: Length.px(iconMargin) }} />

      <Image
        src={ic_Navitime_normal}
        style={{
          height: Length.px(iconSize),
          width: Length.px(iconSize),
        }}
      />

      <View style={{ width: Length.px(iconMargin) }} />

      <Image
        src={ic_LineCarnavi_normal}
        style={{
          height: Length.px(iconSize),
          width: Length.px(iconSize),
        }}
      />
    </View>
  );
}

function AddAppButtonContainer(props: {
  isClosed: boolean;
}): React.ReactElement {
  const {
    views: { View, Image, Text },
    data: { context },
  } = useContextStore();
  const intl = useIntl();
  const isClosed = props.isClosed;
  const viewPort = context.viewPort;

  if (isClosed) {
    return <View />;
  } else {
    const buttonHeight = 64;
    const paddingBottom = 8;
    const top =
      viewPort.height - CardHeader.height.value - buttonHeight - paddingBottom;
    return (
      <View
        style={{
          position: 'absolute',
          top: Length.px(top),
          width: Length.px(viewPort.width),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            height: Length.px(buttonHeight),
            paddingTop: Length.px(22),
            paddingBottom: Length.px(22),
            paddingLeft: Length.px(20),
            paddingRight: Length.px(20),
            backgroundColor: Colors.primary,
            borderRadius: Length.px(32),
            filter: DropShadow(
              Length.px(0),
              Length.px(4),
              Length.px(12),
              Colors.shadow,
            ),
          }}
        >
          <Image
            src={ic_add}
            style={{
              width: Length.px(24),
              height: Length.px(24),
            }}
          />
          <Text
            style={{
              display: 'inline-block',
              fontSize: Length.px(20),
              fontWeight: 'bold',
              color: Colors.buttonText,
              paddingLeft: Length.px(4),
            }}
          >
            {intl.formatMessage({ id: 'Sdl_addAppsBtn' }).toReactNode()}
          </Text>
        </View>
      </View>
    );
  }
}

const actions: Actions = {
  ...commandState.actions,
  updateCardState: (data: Data, cardState: CardState) =>
    copy(data, {
      localState: {
        cardState,
      },
    }),
};

export function SDLCard(props: Props): React.ReactElement {
  const state = useStore<Key, Data, HomeDataMap, Props>({
    key: 'sdl',
    props,
    parent: props.parent,

    default: () => ({
      localState: {
        command: commandState.default,
        cardState: 'Closed',
      },
    }),

    update: ({ data: { context, sdl: data }, dispatch }) => {
      return commandState
        .handleDataMap<WithContext<DM>>(
          'sdl',
          data,
          dispatch,
          (_command) => dispatch,
        )
        .pipe((_) => {
          if (
            context.nativeBackAction === 'InCard' &&
            data.localState.cardState === 'Opened'
          ) {
            return _.context.bind(
              Context.actions.updateNativeBackAction,
              'TopLevel',
            );
          } else {
            return _;
          }
        });
    },
  });

  const { View } = state.views;
  const intl = useIntl();
  const context = state.data.context;
  const localState = state.data.sdl.localState;

  const isClosed =
    localState.cardState === 'Closed' || localState.cardState === 'Closing';
  const iconSize = isClosed ? 48 : 60;
  const iconMarginLeft = isClosed ? 51 : 27;
  const iconMargin = isClosed ? 16 : 27;

  return (
    <Card
      cardName='SDLApp'
      parent={state.stores}
      badgeName={intl.formatMessage({ id: 'Sdl_pageTitle' })}
      title={intl.formatMessage({ id: 'Sdl_pageTitle' })}
      reloadable={false}
      reloadOnOpen={config.fetchDataOptions.onOpenWifi}
      openable={true}
      contentState={context.contentState}
      overlayState='Hide'
      // close={command === 'CloseCard'}
      onOpen={(dispatch) =>
        dispatch.sdl.bind(actions.updateCardState, 'Opening')
      }
      onClose={(dispatch) =>
        dispatch.sdl.bind(actions.updateCardState, 'Closing')
      }
      onOpened={(dispatch) =>
        dispatch.sdl.bind(actions.updateCardState, 'Opened')
      }
      onClosed={(dispatch) =>
        dispatch.sdl.bind(actions.updateCardState, 'Closed')
      }
      onReload={(dispatch) => dispatch}
    >
      <View
        style={{
          opacity: context.contentState === 'Loading' ? 0 : 1,
        }}
      >
        <WarningContainer isClosed={isClosed} />

        <SDLImages
          iconSize={iconSize}
          iconMargin={iconMargin}
          iconMarginLeft={iconMarginLeft}
        />

        <AddAppButtonContainer isClosed={isClosed} />
      </View>
    </Card>
  );
}
