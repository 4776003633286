import { Icons } from '../icons/Icons';
import { MessageIdMap as IdMap, MessageIdMapValue, PrefixId } from './Types';

export type MessageIdMap = IdMap;

const unsafeMessageIdMap = new Map<PrefixId, MessageIdMapValue>();

unsafeMessageIdMap.set('Event_completeCarRegistration', {
  prefixId: 'Event_completeCarRegistration',

  eventName: 'Event_completeCarRegistrationEventName',

  warning: {
    title: 'Event_completeCarRegistrationWarningTitle',
    hint: 'Event_completeCarRegistrationWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_completeCarRegistrationNotificationTitle',
    body: 'Event_completeCarRegistrationNotificationBody',
  },
  home: 'Event_completeCarRegistrationHome',
  dialog: {
    type: 'Alert',
    title: 'Event_completeCarRegistrationDialogTitle',
    body: 'Event_completeCarRegistrationDialogBody',
    action: 'Event_completeCarRegistrationDialogAction',
    action2: 'Event_completeCarRegistrationDialogAction2',
    close: 'Event_completeCarRegistrationDialogClose',
  },
  alert: {
    title: 'Event_completeCarRegistrationAlertTitle',
    body: 'Event_completeCarRegistrationAlertBody',
  },
  location: 'Event_completeCarRegistrationLocation',
  remoteControl: 'Event_completeCarRegistrationRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_waitingForCarRegistration', {
  prefixId: 'Event_waitingForCarRegistration',

  eventName: 'Event_waitingForCarRegistrationEventName',

  warning: {
    title: 'Event_waitingForCarRegistrationWarningTitle',
    hint: 'Event_waitingForCarRegistrationWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_waitingForCarRegistrationNotificationTitle',
    body: 'Event_waitingForCarRegistrationNotificationBody',
  },
  home: 'Event_waitingForCarRegistrationHome',
  dialog: {
    type: 'Alert',
    title: 'Event_waitingForCarRegistrationDialogTitle',
    body: 'Event_waitingForCarRegistrationDialogBody',
    action: 'Event_waitingForCarRegistrationDialogAction',
    action2: 'Event_waitingForCarRegistrationDialogAction2',
    close: 'Event_waitingForCarRegistrationDialogClose',
  },
  alert: {
    title: 'Event_waitingForCarRegistrationAlertTitle',
    body: 'Event_waitingForCarRegistrationAlertBody',
  },
  location: 'Event_waitingForCarRegistrationLocation',
  remoteControl: 'Event_waitingForCarRegistrationRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_addSharedUser', {
  prefixId: 'Event_addSharedUser',

  eventName: 'Event_addSharedUserEventName',

  warning: {
    title: 'Event_addSharedUserWarningTitle',
    hint: 'Event_addSharedUserWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_addSharedUserNotificationTitle',
    body: 'Event_addSharedUserNotificationBody',
  },
  home: 'Event_addSharedUserHome',
  dialog: {
    type: 'None',
    title: 'Event_addSharedUserDialogTitle',
    body: 'Event_addSharedUserDialogBody',
    action: 'Event_addSharedUserDialogAction',
    action2: 'Event_addSharedUserDialogAction2',
    close: 'Event_addSharedUserDialogClose',
  },
  alert: {
    title: 'Event_addSharedUserAlertTitle',
    body: 'Event_addSharedUserAlertBody',
  },
  location: 'Event_addSharedUserLocation',
  remoteControl: 'Event_addSharedUserRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_sosCallManual', {
  prefixId: 'Event_sosCallManual',

  eventName: 'Event_sosCallManualEventName',

  warning: {
    title: 'Event_sosCallManualWarningTitle',
    hint: 'Event_sosCallManualWarningHint',
    order: 41,
  },
  notification: {
    title: 'Event_sosCallManualNotificationTitle',
    body: 'Event_sosCallManualNotificationBody',
  },
  home: 'Event_sosCallManualHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_sosCallManualDialogTitle',
    body: 'Event_sosCallManualDialogBody',
    action: 'Event_sosCallManualDialogAction',
    action2: 'Event_sosCallManualDialogAction2',
    close: 'Event_sosCallManualDialogClose',
  },
  alert: {
    title: 'Event_sosCallManualAlertTitle',
    body: 'Event_sosCallManualAlertBody',
  },
  location: 'Event_sosCallManualLocation',
  remoteControl: 'Event_sosCallManualRemoteControl',

  image: {
    normal: Icons.ic_sos_normal,
    active: Icons.ic_sos_active,
    white: Icons.ic_sos_white,
  },
});

unsafeMessageIdMap.set('Event_sosCallAuto', {
  prefixId: 'Event_sosCallAuto',

  eventName: 'Event_sosCallAutoEventName',

  warning: {
    title: 'Event_sosCallAutoWarningTitle',
    hint: 'Event_sosCallAutoWarningHint',
    order: 40,
  },
  notification: {
    title: 'Event_sosCallAutoNotificationTitle',
    body: 'Event_sosCallAutoNotificationBody',
  },
  home: 'Event_sosCallAutoHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_sosCallAutoDialogTitle',
    body: 'Event_sosCallAutoDialogBody',
    action: 'Event_sosCallAutoDialogAction',
    action2: 'Event_sosCallAutoDialogAction2',
    close: 'Event_sosCallAutoDialogClose',
  },
  alert: {
    title: 'Event_sosCallAutoAlertTitle',
    body: 'Event_sosCallAutoAlertBody',
  },
  location: 'Event_sosCallAutoLocation',
  remoteControl: 'Event_sosCallAutoRemoteControl',

  image: {
    normal: Icons.ic_sos_normal,
    active: Icons.ic_sos_active,
    white: Icons.ic_sos_white,
  },
});

unsafeMessageIdMap.set('Event_lowDriveBatteryAlert3', {
  prefixId: 'Event_lowDriveBatteryAlert3',

  eventName: 'Event_lowDriveBatteryAlert3EventName',

  warning: {
    title: 'Event_lowDriveBatteryAlert3WarningTitle',
    hint: 'Event_lowDriveBatteryAlert3WarningHint',
    order: 39,
  },
  notification: {
    title: 'Event_lowDriveBatteryAlert3NotificationTitle',
    body: 'Event_lowDriveBatteryAlert3NotificationBody',
  },
  home: 'Event_lowDriveBatteryAlert3Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowDriveBatteryAlert3DialogTitle',
    body: 'Event_lowDriveBatteryAlert3DialogBody',
    action: 'Event_lowDriveBatteryAlert3DialogAction',
    action2: 'Event_lowDriveBatteryAlert3DialogAction2',
    close: 'Event_lowDriveBatteryAlert3DialogClose',
  },
  alert: {
    title: 'Event_lowDriveBatteryAlert3AlertTitle',
    body: 'Event_lowDriveBatteryAlert3AlertBody',
  },
  location: 'Event_lowDriveBatteryAlert3Location',
  remoteControl: 'Event_lowDriveBatteryAlert3RemoteControl',

  image: {
    normal: Icons.ic_lowdrivebattery_normal,
    active: Icons.ic_lowdrivebattery_active,
    white: Icons.ic_lowdrivebattery_white,
  },
});

unsafeMessageIdMap.set('Event_lowDriveBatteryAlert2', {
  prefixId: 'Event_lowDriveBatteryAlert2',

  eventName: 'Event_lowDriveBatteryAlert2EventName',

  warning: {
    title: 'Event_lowDriveBatteryAlert2WarningTitle',
    hint: 'Event_lowDriveBatteryAlert2WarningHint',
    order: 38,
  },
  notification: {
    title: 'Event_lowDriveBatteryAlert2NotificationTitle',
    body: 'Event_lowDriveBatteryAlert2NotificationBody',
  },
  home: 'Event_lowDriveBatteryAlert2Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowDriveBatteryAlert2DialogTitle',
    body: 'Event_lowDriveBatteryAlert2DialogBody',
    action: 'Event_lowDriveBatteryAlert2DialogAction',
    action2: 'Event_lowDriveBatteryAlert2DialogAction2',
    close: 'Event_lowDriveBatteryAlert2DialogClose',
  },
  alert: {
    title: 'Event_lowDriveBatteryAlert2AlertTitle',
    body: 'Event_lowDriveBatteryAlert2AlertBody',
  },
  location: 'Event_lowDriveBatteryAlert2Location',
  remoteControl: 'Event_lowDriveBatteryAlert2RemoteControl',

  image: {
    normal: Icons.ic_lowdrivebattery_normal,
    active: Icons.ic_lowdrivebattery_active,
    white: Icons.ic_lowdrivebattery_white,
  },
});

unsafeMessageIdMap.set('Event_lowDriveBatteryAlert1', {
  prefixId: 'Event_lowDriveBatteryAlert1',

  eventName: 'Event_lowDriveBatteryAlert1EventName',

  warning: {
    title: 'Event_lowDriveBatteryAlert1WarningTitle',
    hint: 'Event_lowDriveBatteryAlert1WarningHint',
    order: 37,
  },
  notification: {
    title: 'Event_lowDriveBatteryAlert1NotificationTitle',
    body: 'Event_lowDriveBatteryAlert1NotificationBody',
  },
  home: 'Event_lowDriveBatteryAlert1Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowDriveBatteryAlert1DialogTitle',
    body: 'Event_lowDriveBatteryAlert1DialogBody',
    action: 'Event_lowDriveBatteryAlert1DialogAction',
    action2: 'Event_lowDriveBatteryAlert1DialogAction2',
    close: 'Event_lowDriveBatteryAlert1DialogClose',
  },
  alert: {
    title: 'Event_lowDriveBatteryAlert1AlertTitle',
    body: 'Event_lowDriveBatteryAlert1AlertBody',
  },
  location: 'Event_lowDriveBatteryAlert1Location',
  remoteControl: 'Event_lowDriveBatteryAlert1RemoteControl',

  image: {
    normal: Icons.ic_lowdrivebattery_normal,
    active: Icons.ic_lowdrivebattery_active,
    white: Icons.ic_lowdrivebattery_white,
  },
});

unsafeMessageIdMap.set('Event_backSensorAlert', {
  prefixId: 'Event_backSensorAlert',

  eventName: 'Event_backSensorAlertEventName',

  warning: {
    title: 'Event_backSensorAlertWarningTitle',
    hint: 'Event_backSensorAlertWarningHint',
    order: 36,
  },
  notification: {
    title: 'Event_backSensorAlertNotificationTitle',
    body: 'Event_backSensorAlertNotificationBody',
  },
  home: 'Event_backSensorAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_backSensorAlertDialogTitle',
    body: 'Event_backSensorAlertDialogBody',
    action: 'Event_backSensorAlertDialogAction',
    action2: 'Event_backSensorAlertDialogAction2',
    close: 'Event_backSensorAlertDialogClose',
  },
  alert: {
    title: 'Event_backSensorAlertAlertTitle',
    body: 'Event_backSensorAlertAlertBody',
  },
  location: 'Event_backSensorAlertLocation',
  remoteControl: 'Event_backSensorAlertRemoteControl',

  image: {
    normal: Icons.ic_backsonar_normal,
    active: Icons.ic_backsonar_active,
    white: Icons.ic_backsonar_white,
  },
});

unsafeMessageIdMap.set('Event_cornerSensorFrontAlert', {
  prefixId: 'Event_cornerSensorFrontAlert',

  eventName: 'Event_cornerSensorFrontAlertEventName',

  warning: {
    title: 'Event_cornerSensorFrontAlertWarningTitle',
    hint: 'Event_cornerSensorFrontAlertWarningHint',
    order: 35,
  },
  notification: {
    title: 'Event_cornerSensorFrontAlertNotificationTitle',
    body: 'Event_cornerSensorFrontAlertNotificationBody',
  },
  home: 'Event_cornerSensorFrontAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cornerSensorFrontAlertDialogTitle',
    body: 'Event_cornerSensorFrontAlertDialogBody',
    action: 'Event_cornerSensorFrontAlertDialogAction',
    action2: 'Event_cornerSensorFrontAlertDialogAction2',
    close: 'Event_cornerSensorFrontAlertDialogClose',
  },
  alert: {
    title: 'Event_cornerSensorFrontAlertAlertTitle',
    body: 'Event_cornerSensorFrontAlertAlertBody',
  },
  location: 'Event_cornerSensorFrontAlertLocation',
  remoteControl: 'Event_cornerSensorFrontAlertRemoteControl',

  image: {
    normal: Icons.ic_cornersensor_front_normal,
    active: Icons.ic_cornersensor_front_active,
    white: Icons.ic_cornersensor_front_white,
  },
});

unsafeMessageIdMap.set('Event_cornerSensorRearAlert', {
  prefixId: 'Event_cornerSensorRearAlert',

  eventName: 'Event_cornerSensorRearAlertEventName',

  warning: {
    title: 'Event_cornerSensorRearAlertWarningTitle',
    hint: 'Event_cornerSensorRearAlertWarningHint',
    order: 34,
  },
  notification: {
    title: 'Event_cornerSensorRearAlertNotificationTitle',
    body: 'Event_cornerSensorRearAlertNotificationBody',
  },
  home: 'Event_cornerSensorRearAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cornerSensorRearAlertDialogTitle',
    body: 'Event_cornerSensorRearAlertDialogBody',
    action: 'Event_cornerSensorRearAlertDialogAction',
    action2: 'Event_cornerSensorRearAlertDialogAction2',
    close: 'Event_cornerSensorRearAlertDialogClose',
  },
  alert: {
    title: 'Event_cornerSensorRearAlertAlertTitle',
    body: 'Event_cornerSensorRearAlertAlertBody',
  },
  location: 'Event_cornerSensorRearAlertLocation',
  remoteControl: 'Event_cornerSensorRearAlertRemoteControl',

  image: {
    normal: Icons.ic_cornersensor_rear_normal,
    active: Icons.ic_cornersensor_rear_active,
    white: Icons.ic_cornersensor_rear_white,
  },
});

unsafeMessageIdMap.set('Event_cornerSensorAllAlert', {
  prefixId: 'Event_cornerSensorAllAlert',

  eventName: 'Event_cornerSensorAllAlertEventName',

  warning: {
    title: 'Event_cornerSensorAllAlertWarningTitle',
    hint: 'Event_cornerSensorAllAlertWarningHint',
    order: 33,
  },
  notification: {
    title: 'Event_cornerSensorAllAlertNotificationTitle',
    body: 'Event_cornerSensorAllAlertNotificationBody',
  },
  home: 'Event_cornerSensorAllAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cornerSensorAllAlertDialogTitle',
    body: 'Event_cornerSensorAllAlertDialogBody',
    action: 'Event_cornerSensorAllAlertDialogAction',
    action2: 'Event_cornerSensorAllAlertDialogAction2',
    close: 'Event_cornerSensorAllAlertDialogClose',
  },
  alert: {
    title: 'Event_cornerSensorAllAlertAlertTitle',
    body: 'Event_cornerSensorAllAlertAlertBody',
  },
  location: 'Event_cornerSensorAllAlertLocation',
  remoteControl: 'Event_cornerSensorAllAlertRemoteControl',

  image: {
    normal: Icons.ic_cornersensor_all_normal,
    active: Icons.ic_cornersensor_all_active,
    white: Icons.ic_cornersensor_all_white,
  },
});

unsafeMessageIdMap.set('Event_acousticVehicleSystemAlert', {
  prefixId: 'Event_acousticVehicleSystemAlert',

  eventName: 'Event_acousticVehicleSystemAlertEventName',

  warning: {
    title: 'Event_acousticVehicleSystemAlertWarningTitle',
    hint: 'Event_acousticVehicleSystemAlertWarningHint',
    order: 32,
  },
  notification: {
    title: 'Event_acousticVehicleSystemAlertNotificationTitle',
    body: 'Event_acousticVehicleSystemAlertNotificationBody',
  },
  home: 'Event_acousticVehicleSystemAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_acousticVehicleSystemAlertDialogTitle',
    body: 'Event_acousticVehicleSystemAlertDialogBody',
    action: 'Event_acousticVehicleSystemAlertDialogAction',
    action2: 'Event_acousticVehicleSystemAlertDialogAction2',
    close: 'Event_acousticVehicleSystemAlertDialogClose',
  },
  alert: {
    title: 'Event_acousticVehicleSystemAlertAlertTitle',
    body: 'Event_acousticVehicleSystemAlertAlertBody',
  },
  location: 'Event_acousticVehicleSystemAlertLocation',
  remoteControl: 'Event_acousticVehicleSystemAlertRemoteControl',

  image: {
    normal: Icons.ic_acoustic_normal,
    active: Icons.ic_acoustic_active,
    white: Icons.ic_acoustic_white,
  },
});

unsafeMessageIdMap.set('Event_bsmAlert', {
  prefixId: 'Event_bsmAlert',

  eventName: 'Event_bsmAlertEventName',

  warning: {
    title: 'Event_bsmAlertWarningTitle',
    hint: 'Event_bsmAlertWarningHint',
    order: 31,
  },
  notification: {
    title: 'Event_bsmAlertNotificationTitle',
    body: 'Event_bsmAlertNotificationBody',
  },
  home: 'Event_bsmAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_bsmAlertDialogTitle',
    body: 'Event_bsmAlertDialogBody',
    action: 'Event_bsmAlertDialogAction',
    action2: 'Event_bsmAlertDialogAction2',
    close: 'Event_bsmAlertDialogClose',
  },
  alert: {
    title: 'Event_bsmAlertAlertTitle',
    body: 'Event_bsmAlertAlertBody',
  },
  location: 'Event_bsmAlertLocation',
  remoteControl: 'Event_bsmAlertRemoteControl',

  image: {
    normal: Icons.ic_bsm_normal,
    active: Icons.ic_bsm_active,
    white: Icons.ic_bsm_white,
  },
});

unsafeMessageIdMap.set('Event_lkcSystemAlert', {
  prefixId: 'Event_lkcSystemAlert',

  eventName: 'Event_lkcSystemAlertEventName',

  warning: {
    title: 'Event_lkcSystemAlertWarningTitle',
    hint: 'Event_lkcSystemAlertWarningHint',
    order: 30,
  },
  notification: {
    title: 'Event_lkcSystemAlertNotificationTitle',
    body: 'Event_lkcSystemAlertNotificationBody',
  },
  home: 'Event_lkcSystemAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_lkcSystemAlertDialogTitle',
    body: 'Event_lkcSystemAlertDialogBody',
    action: 'Event_lkcSystemAlertDialogAction',
    action2: 'Event_lkcSystemAlertDialogAction2',
    close: 'Event_lkcSystemAlertDialogClose',
  },
  alert: {
    title: 'Event_lkcSystemAlertAlertTitle',
    body: 'Event_lkcSystemAlertAlertBody',
  },
  location: 'Event_lkcSystemAlertLocation',
  remoteControl: 'Event_lkcSystemAlertRemoteControl',

  image: {
    normal: Icons.ic_lkcsystem_normal,
    active: Icons.ic_lkcsystem_active,
    white: Icons.ic_lkcsystem_white,
  },
});

unsafeMessageIdMap.set('Event_accBreakAlert', {
  prefixId: 'Event_accBreakAlert',

  eventName: 'Event_accBreakAlertEventName',

  warning: {
    title: 'Event_accBreakAlertWarningTitle',
    hint: 'Event_accBreakAlertWarningHint',
    order: 29,
  },
  notification: {
    title: 'Event_accBreakAlertNotificationTitle',
    body: 'Event_accBreakAlertNotificationBody',
  },
  home: 'Event_accBreakAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_accBreakAlertDialogTitle',
    body: 'Event_accBreakAlertDialogBody',
    action: 'Event_accBreakAlertDialogAction',
    action2: 'Event_accBreakAlertDialogAction2',
    close: 'Event_accBreakAlertDialogClose',
  },
  alert: {
    title: 'Event_accBreakAlertAlertTitle',
    body: 'Event_accBreakAlertAlertBody',
  },
  location: 'Event_accBreakAlertLocation',
  remoteControl: 'Event_accBreakAlertRemoteControl',

  image: {
    normal: Icons.ic_accbrake_normal,
    active: Icons.ic_accbrake_active,
    white: Icons.ic_accbrake_white,
  },
});

unsafeMessageIdMap.set('Event_rearDiffLockAlert', {
  prefixId: 'Event_rearDiffLockAlert',

  eventName: 'Event_rearDiffLockAlertEventName',

  warning: {
    title: 'Event_rearDiffLockAlertWarningTitle',
    hint: 'Event_rearDiffLockAlertWarningHint',
    order: 28,
  },
  notification: {
    title: 'Event_rearDiffLockAlertNotificationTitle',
    body: 'Event_rearDiffLockAlertNotificationBody',
  },
  home: 'Event_rearDiffLockAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_rearDiffLockAlertDialogTitle',
    body: 'Event_rearDiffLockAlertDialogBody',
    action: 'Event_rearDiffLockAlertDialogAction',
    action2: 'Event_rearDiffLockAlertDialogAction2',
    close: 'Event_rearDiffLockAlertDialogClose',
  },
  alert: {
    title: 'Event_rearDiffLockAlertAlertTitle',
    body: 'Event_rearDiffLockAlertAlertBody',
  },
  location: 'Event_rearDiffLockAlertLocation',
  remoteControl: 'Event_rearDiffLockAlertRemoteControl',

  image: {
    normal: Icons.ic_reardifflock_normal,
    active: Icons.ic_reardifflock_active,
    white: Icons.ic_reardifflock_white,
  },
});

unsafeMessageIdMap.set('Event_dummyAirbagAlert', {
  prefixId: 'Event_dummyAirbagAlert',

  eventName: 'Event_dummyAirbagAlertEventName',

  warning: {
    title: 'Event_dummyAirbagAlertWarningTitle',
    hint: 'Event_dummyAirbagAlertWarningHint',
    order: 27,
  },
  notification: {
    title: 'Event_dummyAirbagAlertNotificationTitle',
    body: 'Event_dummyAirbagAlertNotificationBody',
  },
  home: 'Event_dummyAirbagAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_dummyAirbagAlertDialogTitle',
    body: 'Event_dummyAirbagAlertDialogBody',
    action: 'Event_dummyAirbagAlertDialogAction',
    action2: 'Event_dummyAirbagAlertDialogAction2',
    close: 'Event_dummyAirbagAlertDialogClose',
  },
  alert: {
    title: 'Event_dummyAirbagAlertAlertTitle',
    body: 'Event_dummyAirbagAlertAlertBody',
  },
  location: 'Event_dummyAirbagAlertLocation',
  remoteControl: 'Event_dummyAirbagAlertRemoteControl',

  image: {
    normal: Icons.ic_srs_normal,
    active: Icons.ic_srs_active,
    white: Icons.ic_srs_white,
  },
});

unsafeMessageIdMap.set('Event_remoteCmdFailedConditionNotSatisfied', {
  prefixId: 'Event_remoteCmdFailedConditionNotSatisfied',

  eventName: 'Event_remoteCmdFailedConditionNotSatisfiedEventName',

  warning: {
    title: 'Event_remoteCmdFailedConditionNotSatisfiedWarningTitle',
    hint: 'Event_remoteCmdFailedConditionNotSatisfiedWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteCmdFailedConditionNotSatisfiedNotificationTitle',
    body: 'Event_remoteCmdFailedConditionNotSatisfiedNotificationBody',
  },
  home: 'Event_remoteCmdFailedConditionNotSatisfiedHome',
  dialog: {
    type: 'Alert',
    title: 'Event_remoteCmdFailedConditionNotSatisfiedDialogTitle',
    body: 'Event_remoteCmdFailedConditionNotSatisfiedDialogBody',
    action: 'Event_remoteCmdFailedConditionNotSatisfiedDialogAction',
    action2: 'Event_remoteCmdFailedConditionNotSatisfiedDialogAction2',
    close: 'Event_remoteCmdFailedConditionNotSatisfiedDialogClose',
  },
  alert: {
    title: 'Event_remoteCmdFailedConditionNotSatisfiedAlertTitle',
    body: 'Event_remoteCmdFailedConditionNotSatisfiedAlertBody',
  },
  location: 'Event_remoteCmdFailedConditionNotSatisfiedLocation',
  remoteControl: 'Event_remoteCmdFailedConditionNotSatisfiedRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteCmdAccepted', {
  prefixId: 'Event_remoteCmdAccepted',

  eventName: 'Event_remoteCmdAcceptedEventName',

  warning: {
    title: 'Event_remoteCmdAcceptedWarningTitle',
    hint: 'Event_remoteCmdAcceptedWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteCmdAcceptedNotificationTitle',
    body: 'Event_remoteCmdAcceptedNotificationBody',
  },
  home: 'Event_remoteCmdAcceptedHome',
  dialog: {
    type: 'Alert',
    title: 'Event_remoteCmdAcceptedDialogTitle',
    body: 'Event_remoteCmdAcceptedDialogBody',
    action: 'Event_remoteCmdAcceptedDialogAction',
    action2: 'Event_remoteCmdAcceptedDialogAction2',
    close: 'Event_remoteCmdAcceptedDialogClose',
  },
  alert: {
    title: 'Event_remoteCmdAcceptedAlertTitle',
    body: 'Event_remoteCmdAcceptedAlertBody',
  },
  location: 'Event_remoteCmdAcceptedLocation',
  remoteControl: 'Event_remoteCmdAcceptedRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_commonNetworkError', {
  prefixId: 'Event_commonNetworkError',

  eventName: 'Event_commonNetworkErrorEventName',

  warning: {
    title: 'Event_commonNetworkErrorWarningTitle',
    hint: 'Event_commonNetworkErrorWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_commonNetworkErrorNotificationTitle',
    body: 'Event_commonNetworkErrorNotificationBody',
  },
  home: 'Event_commonNetworkErrorHome',
  dialog: {
    type: 'Alert',
    title: 'Event_commonNetworkErrorDialogTitle',
    body: 'Event_commonNetworkErrorDialogBody',
    action: 'Event_commonNetworkErrorDialogAction',
    action2: 'Event_commonNetworkErrorDialogAction2',
    close: 'Event_commonNetworkErrorDialogClose',
  },
  alert: {
    title: 'Event_commonNetworkErrorAlertTitle',
    body: 'Event_commonNetworkErrorAlertBody',
  },
  location: 'Event_commonNetworkErrorLocation',
  remoteControl: 'Event_commonNetworkErrorRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_getGpsError', {
  prefixId: 'Event_getGpsError',

  eventName: 'Event_getGpsErrorEventName',

  warning: {
    title: 'Event_getGpsErrorWarningTitle',
    hint: 'Event_getGpsErrorWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_getGpsErrorNotificationTitle',
    body: 'Event_getGpsErrorNotificationBody',
  },
  home: 'Event_getGpsErrorHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_getGpsErrorDialogTitle',
    body: 'Event_getGpsErrorDialogBody',
    action: 'Event_getGpsErrorDialogAction',
    action2: 'Event_getGpsErrorDialogAction2',
    close: 'Event_getGpsErrorDialogClose',
  },
  alert: {
    title: 'Event_getGpsErrorAlertTitle',
    body: 'Event_getGpsErrorAlertBody',
  },
  location: 'Event_getGpsErrorLocation',
  remoteControl: 'Event_getGpsErrorRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteCmdCenterFail', {
  prefixId: 'Event_remoteCmdCenterFail',

  eventName: 'Event_remoteCmdCenterFailEventName',

  warning: {
    title: 'Event_remoteCmdCenterFailWarningTitle',
    hint: 'Event_remoteCmdCenterFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteCmdCenterFailNotificationTitle',
    body: 'Event_remoteCmdCenterFailNotificationBody',
  },
  home: 'Event_remoteCmdCenterFailHome',
  dialog: {
    type: 'Alert',
    title: 'Event_remoteCmdCenterFailDialogTitle',
    body: 'Event_remoteCmdCenterFailDialogBody',
    action: 'Event_remoteCmdCenterFailDialogAction',
    action2: 'Event_remoteCmdCenterFailDialogAction2',
    close: 'Event_remoteCmdCenterFailDialogClose',
  },
  alert: {
    title: 'Event_remoteCmdCenterFailAlertTitle',
    body: 'Event_remoteCmdCenterFailAlertBody',
  },
  location: 'Event_remoteCmdCenterFailLocation',
  remoteControl: 'Event_remoteCmdCenterFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteCmdDuplicated', {
  prefixId: 'Event_remoteCmdDuplicated',

  eventName: 'Event_remoteCmdDuplicatedEventName',

  warning: {
    title: 'Event_remoteCmdDuplicatedWarningTitle',
    hint: 'Event_remoteCmdDuplicatedWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteCmdDuplicatedNotificationTitle',
    body: 'Event_remoteCmdDuplicatedNotificationBody',
  },
  home: 'Event_remoteCmdDuplicatedHome',
  dialog: {
    type: 'Alert',
    title: 'Event_remoteCmdDuplicatedDialogTitle',
    body: 'Event_remoteCmdDuplicatedDialogBody',
    action: 'Event_remoteCmdDuplicatedDialogAction',
    action2: 'Event_remoteCmdDuplicatedDialogAction2',
    close: 'Event_remoteCmdDuplicatedDialogClose',
  },
  alert: {
    title: 'Event_remoteCmdDuplicatedAlertTitle',
    body: 'Event_remoteCmdDuplicatedAlertBody',
  },
  location: 'Event_remoteCmdDuplicatedLocation',
  remoteControl: 'Event_remoteCmdDuplicatedRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_operationCampaignAlert', {
  prefixId: 'Event_operationCampaignAlert',

  eventName: 'Event_operationCampaignAlertEventName',

  warning: {
    title: 'Event_operationCampaignAlertWarningTitle',
    hint: 'Event_operationCampaignAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_operationCampaignAlertNotificationTitle',
    body: 'Event_operationCampaignAlertNotificationBody',
  },
  home: 'Event_operationCampaignAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_operationCampaignAlertDialogTitle',
    body: 'Event_operationCampaignAlertDialogBody',
    action: 'Event_operationCampaignAlertDialogAction',
    action2: 'Event_operationCampaignAlertDialogAction2',
    close: 'Event_operationCampaignAlertDialogClose',
  },
  alert: {
    title: 'Event_operationCampaignAlertAlertTitle',
    body: 'Event_operationCampaignAlertAlertBody',
  },
  location: 'Event_operationCampaignAlertLocation',
  remoteControl: 'Event_operationCampaignAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_operationAppAlert', {
  prefixId: 'Event_operationAppAlert',

  eventName: 'Event_operationAppAlertEventName',

  warning: {
    title: 'Event_operationAppAlertWarningTitle',
    hint: 'Event_operationAppAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_operationAppAlertNotificationTitle',
    body: 'Event_operationAppAlertNotificationBody',
  },
  home: 'Event_operationAppAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_operationAppAlertDialogTitle',
    body: 'Event_operationAppAlertDialogBody',
    action: 'Event_operationAppAlertDialogAction',
    action2: 'Event_operationAppAlertDialogAction2',
    close: 'Event_operationAppAlertDialogClose',
  },
  alert: {
    title: 'Event_operationAppAlertAlertTitle',
    body: 'Event_operationAppAlertAlertBody',
  },
  location: 'Event_operationAppAlertLocation',
  remoteControl: 'Event_operationAppAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_operationMaintenaceAlert', {
  prefixId: 'Event_operationMaintenaceAlert',

  eventName: 'Event_operationMaintenaceAlertEventName',

  warning: {
    title: 'Event_operationMaintenaceAlertWarningTitle',
    hint: 'Event_operationMaintenaceAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_operationMaintenaceAlertNotificationTitle',
    body: 'Event_operationMaintenaceAlertNotificationBody',
  },
  home: 'Event_operationMaintenaceAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_operationMaintenaceAlertDialogTitle',
    body: 'Event_operationMaintenaceAlertDialogBody',
    action: 'Event_operationMaintenaceAlertDialogAction',
    action2: 'Event_operationMaintenaceAlertDialogAction2',
    close: 'Event_operationMaintenaceAlertDialogClose',
  },
  alert: {
    title: 'Event_operationMaintenaceAlertAlertTitle',
    body: 'Event_operationMaintenaceAlertAlertBody',
  },
  location: 'Event_operationMaintenaceAlertLocation',
  remoteControl: 'Event_operationMaintenaceAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipUserRemoveAllCar', {
  prefixId: 'Event_ownerShipUserRemoveAllCar',

  eventName: 'Event_ownerShipUserRemoveAllCarEventName',

  warning: {
    title: 'Event_ownerShipUserRemoveAllCarWarningTitle',
    hint: 'Event_ownerShipUserRemoveAllCarWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipUserRemoveAllCarNotificationTitle',
    body: 'Event_ownerShipUserRemoveAllCarNotificationBody',
  },
  home: 'Event_ownerShipUserRemoveAllCarHome',
  dialog: {
    type: 'Alert',
    title: 'Event_ownerShipUserRemoveAllCarDialogTitle',
    body: 'Event_ownerShipUserRemoveAllCarDialogBody',
    action: 'Event_ownerShipUserRemoveAllCarDialogAction',
    action2: 'Event_ownerShipUserRemoveAllCarDialogAction2',
    close: 'Event_ownerShipUserRemoveAllCarDialogClose',
  },
  alert: {
    title: 'Event_ownerShipUserRemoveAllCarAlertTitle',
    body: 'Event_ownerShipUserRemoveAllCarAlertBody',
  },
  location: 'Event_ownerShipUserRemoveAllCarLocation',
  remoteControl: 'Event_ownerShipUserRemoveAllCarRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipAdminRemoveCar', {
  prefixId: 'Event_ownerShipAdminRemoveCar',

  eventName: 'Event_ownerShipAdminRemoveCarEventName',

  warning: {
    title: 'Event_ownerShipAdminRemoveCarWarningTitle',
    hint: 'Event_ownerShipAdminRemoveCarWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipAdminRemoveCarNotificationTitle',
    body: 'Event_ownerShipAdminRemoveCarNotificationBody',
  },
  home: 'Event_ownerShipAdminRemoveCarHome',
  dialog: {
    type: 'Alert',
    title: 'Event_ownerShipAdminRemoveCarDialogTitle',
    body: 'Event_ownerShipAdminRemoveCarDialogBody',
    action: 'Event_ownerShipAdminRemoveCarDialogAction',
    action2: 'Event_ownerShipAdminRemoveCarDialogAction2',
    close: 'Event_ownerShipAdminRemoveCarDialogClose',
  },
  alert: {
    title: 'Event_ownerShipAdminRemoveCarAlertTitle',
    body: 'Event_ownerShipAdminRemoveCarAlertBody',
  },
  location: 'Event_ownerShipAdminRemoveCarLocation',
  remoteControl: 'Event_ownerShipAdminRemoveCarRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipUserRemoveCar', {
  prefixId: 'Event_ownerShipUserRemoveCar',

  eventName: 'Event_ownerShipUserRemoveCarEventName',

  warning: {
    title: 'Event_ownerShipUserRemoveCarWarningTitle',
    hint: 'Event_ownerShipUserRemoveCarWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipUserRemoveCarNotificationTitle',
    body: 'Event_ownerShipUserRemoveCarNotificationBody',
  },
  home: 'Event_ownerShipUserRemoveCarHome',
  dialog: {
    type: 'Alert',
    title: 'Event_ownerShipUserRemoveCarDialogTitle',
    body: 'Event_ownerShipUserRemoveCarDialogBody',
    action: 'Event_ownerShipUserRemoveCarDialogAction',
    action2: 'Event_ownerShipUserRemoveCarDialogAction2',
    close: 'Event_ownerShipUserRemoveCarDialogClose',
  },
  alert: {
    title: 'Event_ownerShipUserRemoveCarAlertTitle',
    body: 'Event_ownerShipUserRemoveCarAlertBody',
  },
  location: 'Event_ownerShipUserRemoveCarLocation',
  remoteControl: 'Event_ownerShipUserRemoveCarRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionWifiServiceExpired', {
  prefixId: 'Event_subscriptionWifiServiceExpired',

  eventName: 'Event_subscriptionWifiServiceExpiredEventName',

  warning: {
    title: 'Event_subscriptionWifiServiceExpiredWarningTitle',
    hint: 'Event_subscriptionWifiServiceExpiredWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionWifiServiceExpiredNotificationTitle',
    body: 'Event_subscriptionWifiServiceExpiredNotificationBody',
  },
  home: 'Event_subscriptionWifiServiceExpiredHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionWifiServiceExpiredDialogTitle',
    body: 'Event_subscriptionWifiServiceExpiredDialogBody',
    action: 'Event_subscriptionWifiServiceExpiredDialogAction',
    action2: 'Event_subscriptionWifiServiceExpiredDialogAction2',
    close: 'Event_subscriptionWifiServiceExpiredDialogClose',
  },
  alert: {
    title: 'Event_subscriptionWifiServiceExpiredAlertTitle',
    body: 'Event_subscriptionWifiServiceExpiredAlertBody',
  },
  location: 'Event_subscriptionWifiServiceExpiredLocation',
  remoteControl: 'Event_subscriptionWifiServiceExpiredRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionWifiContractDone', {
  prefixId: 'Event_subscriptionWifiContractDone',

  eventName: 'Event_subscriptionWifiContractDoneEventName',

  warning: {
    title: 'Event_subscriptionWifiContractDoneWarningTitle',
    hint: 'Event_subscriptionWifiContractDoneWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionWifiContractDoneNotificationTitle',
    body: 'Event_subscriptionWifiContractDoneNotificationBody',
  },
  home: 'Event_subscriptionWifiContractDoneHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionWifiContractDoneDialogTitle',
    body: 'Event_subscriptionWifiContractDoneDialogBody',
    action: 'Event_subscriptionWifiContractDoneDialogAction',
    action2: 'Event_subscriptionWifiContractDoneDialogAction2',
    close: 'Event_subscriptionWifiContractDoneDialogClose',
  },
  alert: {
    title: 'Event_subscriptionWifiContractDoneAlertTitle',
    body: 'Event_subscriptionWifiContractDoneAlertBody',
  },
  location: 'Event_subscriptionWifiContractDoneLocation',
  remoteControl: 'Event_subscriptionWifiContractDoneRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionWifiAlert', {
  prefixId: 'Event_subscriptionWifiAlert',

  eventName: 'Event_subscriptionWifiAlertEventName',

  warning: {
    title: 'Event_subscriptionWifiAlertWarningTitle',
    hint: 'Event_subscriptionWifiAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionWifiAlertNotificationTitle',
    body: 'Event_subscriptionWifiAlertNotificationBody',
  },
  home: 'Event_subscriptionWifiAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionWifiAlertDialogTitle',
    body: 'Event_subscriptionWifiAlertDialogBody',
    action: 'Event_subscriptionWifiAlertDialogAction',
    action2: 'Event_subscriptionWifiAlertDialogAction2',
    close: 'Event_subscriptionWifiAlertDialogClose',
  },
  alert: {
    title: 'Event_subscriptionWifiAlertAlertTitle',
    body: 'Event_subscriptionWifiAlertAlertBody',
  },
  location: 'Event_subscriptionWifiAlertLocation',
  remoteControl: 'Event_subscriptionWifiAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionWifiPreAlert', {
  prefixId: 'Event_subscriptionWifiPreAlert',

  eventName: 'Event_subscriptionWifiPreAlertEventName',

  warning: {
    title: 'Event_subscriptionWifiPreAlertWarningTitle',
    hint: 'Event_subscriptionWifiPreAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionWifiPreAlertNotificationTitle',
    body: 'Event_subscriptionWifiPreAlertNotificationBody',
  },
  home: 'Event_subscriptionWifiPreAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionWifiPreAlertDialogTitle',
    body: 'Event_subscriptionWifiPreAlertDialogBody',
    action: 'Event_subscriptionWifiPreAlertDialogAction',
    action2: 'Event_subscriptionWifiPreAlertDialogAction2',
    close: 'Event_subscriptionWifiPreAlertDialogClose',
  },
  alert: {
    title: 'Event_subscriptionWifiPreAlertAlertTitle',
    body: 'Event_subscriptionWifiPreAlertAlertBody',
  },
  location: 'Event_subscriptionWifiPreAlertLocation',
  remoteControl: 'Event_subscriptionWifiPreAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionDCMServiceExpired', {
  prefixId: 'Event_subscriptionDCMServiceExpired',

  eventName: 'Event_subscriptionDCMServiceExpiredEventName',

  warning: {
    title: 'Event_subscriptionDCMServiceExpiredWarningTitle',
    hint: 'Event_subscriptionDCMServiceExpiredWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionDCMServiceExpiredNotificationTitle',
    body: 'Event_subscriptionDCMServiceExpiredNotificationBody',
  },
  home: 'Event_subscriptionDCMServiceExpiredHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionDCMServiceExpiredDialogTitle',
    body: 'Event_subscriptionDCMServiceExpiredDialogBody',
    action: 'Event_subscriptionDCMServiceExpiredDialogAction',
    action2: 'Event_subscriptionDCMServiceExpiredDialogAction2',
    close: 'Event_subscriptionDCMServiceExpiredDialogClose',
  },
  alert: {
    title: 'Event_subscriptionDCMServiceExpiredAlertTitle',
    body: 'Event_subscriptionDCMServiceExpiredAlertBody',
  },
  location: 'Event_subscriptionDCMServiceExpiredLocation',
  remoteControl: 'Event_subscriptionDCMServiceExpiredRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionDCMContractDone', {
  prefixId: 'Event_subscriptionDCMContractDone',

  eventName: 'Event_subscriptionDCMContractDoneEventName',

  warning: {
    title: 'Event_subscriptionDCMContractDoneWarningTitle',
    hint: 'Event_subscriptionDCMContractDoneWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionDCMContractDoneNotificationTitle',
    body: 'Event_subscriptionDCMContractDoneNotificationBody',
  },
  home: 'Event_subscriptionDCMContractDoneHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionDCMContractDoneDialogTitle',
    body: 'Event_subscriptionDCMContractDoneDialogBody',
    action: 'Event_subscriptionDCMContractDoneDialogAction',
    action2: 'Event_subscriptionDCMContractDoneDialogAction2',
    close: 'Event_subscriptionDCMContractDoneDialogClose',
  },
  alert: {
    title: 'Event_subscriptionDCMContractDoneAlertTitle',
    body: 'Event_subscriptionDCMContractDoneAlertBody',
  },
  location: 'Event_subscriptionDCMContractDoneLocation',
  remoteControl: 'Event_subscriptionDCMContractDoneRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subsctiptionDCMAlert', {
  prefixId: 'Event_subsctiptionDCMAlert',

  eventName: 'Event_subsctiptionDCMAlertEventName',

  warning: {
    title: 'Event_subsctiptionDCMAlertWarningTitle',
    hint: 'Event_subsctiptionDCMAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subsctiptionDCMAlertNotificationTitle',
    body: 'Event_subsctiptionDCMAlertNotificationBody',
  },
  home: 'Event_subsctiptionDCMAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subsctiptionDCMAlertDialogTitle',
    body: 'Event_subsctiptionDCMAlertDialogBody',
    action: 'Event_subsctiptionDCMAlertDialogAction',
    action2: 'Event_subsctiptionDCMAlertDialogAction2',
    close: 'Event_subsctiptionDCMAlertDialogClose',
  },
  alert: {
    title: 'Event_subsctiptionDCMAlertAlertTitle',
    body: 'Event_subsctiptionDCMAlertAlertBody',
  },
  location: 'Event_subsctiptionDCMAlertLocation',
  remoteControl: 'Event_subsctiptionDCMAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_subscriptionDCMPreAlert', {
  prefixId: 'Event_subscriptionDCMPreAlert',

  eventName: 'Event_subscriptionDCMPreAlertEventName',

  warning: {
    title: 'Event_subscriptionDCMPreAlertWarningTitle',
    hint: 'Event_subscriptionDCMPreAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_subscriptionDCMPreAlertNotificationTitle',
    body: 'Event_subscriptionDCMPreAlertNotificationBody',
  },
  home: 'Event_subscriptionDCMPreAlertHome',
  dialog: {
    type: 'Alert',
    title: 'Event_subscriptionDCMPreAlertDialogTitle',
    body: 'Event_subscriptionDCMPreAlertDialogBody',
    action: 'Event_subscriptionDCMPreAlertDialogAction',
    action2: 'Event_subscriptionDCMPreAlertDialogAction2',
    close: 'Event_subscriptionDCMPreAlertDialogClose',
  },
  alert: {
    title: 'Event_subscriptionDCMPreAlertAlertTitle',
    body: 'Event_subscriptionDCMPreAlertAlertBody',
  },
  location: 'Event_subscriptionDCMPreAlertLocation',
  remoteControl: 'Event_subscriptionDCMPreAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipUserActivate', {
  prefixId: 'Event_ownerShipUserActivate',

  eventName: 'Event_ownerShipUserActivateEventName',

  warning: {
    title: 'Event_ownerShipUserActivateWarningTitle',
    hint: 'Event_ownerShipUserActivateWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipUserActivateNotificationTitle',
    body: 'Event_ownerShipUserActivateNotificationBody',
  },
  home: 'Event_ownerShipUserActivateHome',
  dialog: {
    type: 'Alert',
    title: 'Event_ownerShipUserActivateDialogTitle',
    body: 'Event_ownerShipUserActivateDialogBody',
    action: 'Event_ownerShipUserActivateDialogAction',
    action2: 'Event_ownerShipUserActivateDialogAction2',
    close: 'Event_ownerShipUserActivateDialogClose',
  },
  alert: {
    title: 'Event_ownerShipUserActivateAlertTitle',
    body: 'Event_ownerShipUserActivateAlertBody',
  },
  location: 'Event_ownerShipUserActivateLocation',
  remoteControl: 'Event_ownerShipUserActivateRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipAdminNotify', {
  prefixId: 'Event_ownerShipAdminNotify',

  eventName: 'Event_ownerShipAdminNotifyEventName',

  warning: {
    title: 'Event_ownerShipAdminNotifyWarningTitle',
    hint: 'Event_ownerShipAdminNotifyWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipAdminNotifyNotificationTitle',
    body: 'Event_ownerShipAdminNotifyNotificationBody',
  },
  home: 'Event_ownerShipAdminNotifyHome',
  dialog: {
    type: 'Alert',
    title: 'Event_ownerShipAdminNotifyDialogTitle',
    body: 'Event_ownerShipAdminNotifyDialogBody',
    action: 'Event_ownerShipAdminNotifyDialogAction',
    action2: 'Event_ownerShipAdminNotifyDialogAction2',
    close: 'Event_ownerShipAdminNotifyDialogClose',
  },
  alert: {
    title: 'Event_ownerShipAdminNotifyAlertTitle',
    body: 'Event_ownerShipAdminNotifyAlertBody',
  },
  location: 'Event_ownerShipAdminNotifyLocation',
  remoteControl: 'Event_ownerShipAdminNotifyRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipInvitation', {
  prefixId: 'Event_ownerShipInvitation',

  eventName: 'Event_ownerShipInvitationEventName',

  warning: {
    title: 'Event_ownerShipInvitationWarningTitle',
    hint: 'Event_ownerShipInvitationWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipInvitationNotificationTitle',
    body: 'Event_ownerShipInvitationNotificationBody',
  },
  home: 'Event_ownerShipInvitationHome',
  dialog: {
    type: 'None',
    title: 'Event_ownerShipInvitationDialogTitle',
    body: 'Event_ownerShipInvitationDialogBody',
    action: 'Event_ownerShipInvitationDialogAction',
    action2: 'Event_ownerShipInvitationDialogAction2',
    close: 'Event_ownerShipInvitationDialogClose',
  },
  alert: {
    title: 'Event_ownerShipInvitationAlertTitle',
    body: 'Event_ownerShipInvitationAlertBody',
  },
  location: 'Event_ownerShipInvitationLocation',
  remoteControl: 'Event_ownerShipInvitationRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ownerShipChangeContents', {
  prefixId: 'Event_ownerShipChangeContents',

  eventName: 'Event_ownerShipChangeContentsEventName',

  warning: {
    title: 'Event_ownerShipChangeContentsWarningTitle',
    hint: 'Event_ownerShipChangeContentsWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ownerShipChangeContentsNotificationTitle',
    body: 'Event_ownerShipChangeContentsNotificationBody',
  },
  home: 'Event_ownerShipChangeContentsHome',
  dialog: {
    type: 'Alert',
    title: 'Event_ownerShipChangeContentsDialogTitle',
    body: 'Event_ownerShipChangeContentsDialogBody',
    action: 'Event_ownerShipChangeContentsDialogAction',
    action2: 'Event_ownerShipChangeContentsDialogAction2',
    close: 'Event_ownerShipChangeContentsDialogClose',
  },
  alert: {
    title: 'Event_ownerShipChangeContentsAlertTitle',
    body: 'Event_ownerShipChangeContentsAlertBody',
  },
  location: 'Event_ownerShipChangeContentsLocation',
  remoteControl: 'Event_ownerShipChangeContentsRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlertTire', {
  prefixId: 'Event_serviceReminderAlertTire',

  eventName: 'Event_serviceReminderAlertTireEventName',

  warning: {
    title: 'Event_serviceReminderAlertTireWarningTitle',
    hint: 'Event_serviceReminderAlertTireWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertTireNotificationTitle',
    body: 'Event_serviceReminderAlertTireNotificationBody',
  },
  home: 'Event_serviceReminderAlertTireHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertTireDialogTitle',
    body: 'Event_serviceReminderAlertTireDialogBody',
    action: 'Event_serviceReminderAlertTireDialogAction',
    action2: 'Event_serviceReminderAlertTireDialogAction2',
    close: 'Event_serviceReminderAlertTireDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertTireAlertTitle',
    body: 'Event_serviceReminderAlertTireAlertBody',
  },
  location: 'Event_serviceReminderAlertTireLocation',
  remoteControl: 'Event_serviceReminderAlertTireRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlertBrakeRr', {
  prefixId: 'Event_serviceReminderAlertBrakeRr',

  eventName: 'Event_serviceReminderAlertBrakeRrEventName',

  warning: {
    title: 'Event_serviceReminderAlertBrakeRrWarningTitle',
    hint: 'Event_serviceReminderAlertBrakeRrWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertBrakeRrNotificationTitle',
    body: 'Event_serviceReminderAlertBrakeRrNotificationBody',
  },
  home: 'Event_serviceReminderAlertBrakeRrHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertBrakeRrDialogTitle',
    body: 'Event_serviceReminderAlertBrakeRrDialogBody',
    action: 'Event_serviceReminderAlertBrakeRrDialogAction',
    action2: 'Event_serviceReminderAlertBrakeRrDialogAction2',
    close: 'Event_serviceReminderAlertBrakeRrDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertBrakeRrAlertTitle',
    body: 'Event_serviceReminderAlertBrakeRrAlertBody',
  },
  location: 'Event_serviceReminderAlertBrakeRrLocation',
  remoteControl: 'Event_serviceReminderAlertBrakeRrRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlertBrakeFr', {
  prefixId: 'Event_serviceReminderAlertBrakeFr',

  eventName: 'Event_serviceReminderAlertBrakeFrEventName',

  warning: {
    title: 'Event_serviceReminderAlertBrakeFrWarningTitle',
    hint: 'Event_serviceReminderAlertBrakeFrWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertBrakeFrNotificationTitle',
    body: 'Event_serviceReminderAlertBrakeFrNotificationBody',
  },
  home: 'Event_serviceReminderAlertBrakeFrHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertBrakeFrDialogTitle',
    body: 'Event_serviceReminderAlertBrakeFrDialogBody',
    action: 'Event_serviceReminderAlertBrakeFrDialogAction',
    action2: 'Event_serviceReminderAlertBrakeFrDialogAction2',
    close: 'Event_serviceReminderAlertBrakeFrDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertBrakeFrAlertTitle',
    body: 'Event_serviceReminderAlertBrakeFrAlertBody',
  },
  location: 'Event_serviceReminderAlertBrakeFrLocation',
  remoteControl: 'Event_serviceReminderAlertBrakeFrRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlertBrakeOil', {
  prefixId: 'Event_serviceReminderAlertBrakeOil',

  eventName: 'Event_serviceReminderAlertBrakeOilEventName',

  warning: {
    title: 'Event_serviceReminderAlertBrakeOilWarningTitle',
    hint: 'Event_serviceReminderAlertBrakeOilWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertBrakeOilNotificationTitle',
    body: 'Event_serviceReminderAlertBrakeOilNotificationBody',
  },
  home: 'Event_serviceReminderAlertBrakeOilHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertBrakeOilDialogTitle',
    body: 'Event_serviceReminderAlertBrakeOilDialogBody',
    action: 'Event_serviceReminderAlertBrakeOilDialogAction',
    action2: 'Event_serviceReminderAlertBrakeOilDialogAction2',
    close: 'Event_serviceReminderAlertBrakeOilDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertBrakeOilAlertTitle',
    body: 'Event_serviceReminderAlertBrakeOilAlertBody',
  },
  location: 'Event_serviceReminderAlertBrakeOilLocation',
  remoteControl: 'Event_serviceReminderAlertBrakeOilRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlertBattery', {
  prefixId: 'Event_serviceReminderAlertBattery',

  eventName: 'Event_serviceReminderAlertBatteryEventName',

  warning: {
    title: 'Event_serviceReminderAlertBatteryWarningTitle',
    hint: 'Event_serviceReminderAlertBatteryWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertBatteryNotificationTitle',
    body: 'Event_serviceReminderAlertBatteryNotificationBody',
  },
  home: 'Event_serviceReminderAlertBatteryHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertBatteryDialogTitle',
    body: 'Event_serviceReminderAlertBatteryDialogBody',
    action: 'Event_serviceReminderAlertBatteryDialogAction',
    action2: 'Event_serviceReminderAlertBatteryDialogAction2',
    close: 'Event_serviceReminderAlertBatteryDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertBatteryAlertTitle',
    body: 'Event_serviceReminderAlertBatteryAlertBody',
  },
  location: 'Event_serviceReminderAlertBatteryLocation',
  remoteControl: 'Event_serviceReminderAlertBatteryRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlertEngOil', {
  prefixId: 'Event_serviceReminderAlertEngOil',

  eventName: 'Event_serviceReminderAlertEngOilEventName',

  warning: {
    title: 'Event_serviceReminderAlertEngOilWarningTitle',
    hint: 'Event_serviceReminderAlertEngOilWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertEngOilNotificationTitle',
    body: 'Event_serviceReminderAlertEngOilNotificationBody',
  },
  home: 'Event_serviceReminderAlertEngOilHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertEngOilDialogTitle',
    body: 'Event_serviceReminderAlertEngOilDialogBody',
    action: 'Event_serviceReminderAlertEngOilDialogAction',
    action2: 'Event_serviceReminderAlertEngOilDialogAction2',
    close: 'Event_serviceReminderAlertEngOilDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertEngOilAlertTitle',
    body: 'Event_serviceReminderAlertEngOilAlertBody',
  },
  location: 'Event_serviceReminderAlertEngOilLocation',
  remoteControl: 'Event_serviceReminderAlertEngOilRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_serviceReminderAlert', {
  prefixId: 'Event_serviceReminderAlert',

  eventName: 'Event_serviceReminderAlertEventName',

  warning: {
    title: 'Event_serviceReminderAlertWarningTitle',
    hint: 'Event_serviceReminderAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_serviceReminderAlertNotificationTitle',
    body: 'Event_serviceReminderAlertNotificationBody',
  },
  home: 'Event_serviceReminderAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_serviceReminderAlertDialogTitle',
    body: 'Event_serviceReminderAlertDialogBody',
    action: 'Event_serviceReminderAlertDialogAction',
    action2: 'Event_serviceReminderAlertDialogAction2',
    close: 'Event_serviceReminderAlertDialogClose',
  },
  alert: {
    title: 'Event_serviceReminderAlertAlertTitle',
    body: 'Event_serviceReminderAlertAlertBody',
  },
  location: 'Event_serviceReminderAlertLocation',
  remoteControl: 'Event_serviceReminderAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_engineProhibit', {
  prefixId: 'Event_engineProhibit',

  eventName: 'Event_engineProhibitEventName',

  warning: {
    title: 'Event_engineProhibitWarningTitle',
    hint: 'Event_engineProhibitWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_engineProhibitNotificationTitle',
    body: 'Event_engineProhibitNotificationBody',
  },
  home: 'Event_engineProhibitHome',
  dialog: {
    type: 'None',
    title: 'Event_engineProhibitDialogTitle',
    body: 'Event_engineProhibitDialogBody',
    action: 'Event_engineProhibitDialogAction',
    action2: 'Event_engineProhibitDialogAction2',
    close: 'Event_engineProhibitDialogClose',
  },
  alert: {
    title: 'Event_engineProhibitAlertTitle',
    body: 'Event_engineProhibitAlertBody',
  },
  location: 'Event_engineProhibitLocation',
  remoteControl: 'Event_engineProhibitRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_trackingOn', {
  prefixId: 'Event_trackingOn',

  eventName: 'Event_trackingOnEventName',

  warning: {
    title: 'Event_trackingOnWarningTitle',
    hint: 'Event_trackingOnWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_trackingOnNotificationTitle',
    body: 'Event_trackingOnNotificationBody',
  },
  home: 'Event_trackingOnHome',
  dialog: {
    type: 'None',
    title: 'Event_trackingOnDialogTitle',
    body: 'Event_trackingOnDialogBody',
    action: 'Event_trackingOnDialogAction',
    action2: 'Event_trackingOnDialogAction2',
    close: 'Event_trackingOnDialogClose',
  },
  alert: {
    title: 'Event_trackingOnAlertTitle',
    body: 'Event_trackingOnAlertBody',
  },
  location: 'Event_trackingOnLocation',
  remoteControl: 'Event_trackingOnRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkariDoor', {
  prefixId: 'Event_ukkariDoor',

  eventName: 'Event_ukkariDoorEventName',

  warning: {
    title: 'Event_ukkariDoorWarningTitle',
    hint: 'Event_ukkariDoorWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariDoorNotificationTitle',
    body: 'Event_ukkariDoorNotificationBody',
  },
  home: 'Event_ukkariDoorHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariDoorDialogTitle',
    body: 'Event_ukkariDoorDialogBody',
    action: 'Event_ukkariDoorDialogAction',
    action2: 'Event_ukkariDoorDialogAction2',
    close: 'Event_ukkariDoorDialogClose',
  },
  alert: {
    title: 'Event_ukkariDoorAlertTitle',
    body: 'Event_ukkariDoorAlertBody',
  },
  location: 'Event_ukkariDoorLocation',
  remoteControl: 'Event_ukkariDoorRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkariLight', {
  prefixId: 'Event_ukkariLight',

  eventName: 'Event_ukkariLightEventName',

  warning: {
    title: 'Event_ukkariLightWarningTitle',
    hint: 'Event_ukkariLightWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariLightNotificationTitle',
    body: 'Event_ukkariLightNotificationBody',
  },
  home: 'Event_ukkariLightHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariLightDialogTitle',
    body: 'Event_ukkariLightDialogBody',
    action: 'Event_ukkariLightDialogAction',
    action2: 'Event_ukkariLightDialogAction2',
    close: 'Event_ukkariLightDialogClose',
  },
  alert: {
    title: 'Event_ukkariLightAlertTitle',
    body: 'Event_ukkariLightAlertBody',
  },
  location: 'Event_ukkariLightLocation',
  remoteControl: 'Event_ukkariLightRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkariEngProhibit', {
  prefixId: 'Event_ukkariEngProhibit',

  eventName: 'Event_ukkariEngProhibitEventName',

  warning: {
    title: 'Event_ukkariEngProhibitWarningTitle',
    hint: 'Event_ukkariEngProhibitWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariEngProhibitNotificationTitle',
    body: 'Event_ukkariEngProhibitNotificationBody',
  },
  home: 'Event_ukkariEngProhibitHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariEngProhibitDialogTitle',
    body: 'Event_ukkariEngProhibitDialogBody',
    action: 'Event_ukkariEngProhibitDialogAction',
    action2: 'Event_ukkariEngProhibitDialogAction2',
    close: 'Event_ukkariEngProhibitDialogClose',
  },
  alert: {
    title: 'Event_ukkariEngProhibitAlertTitle',
    body: 'Event_ukkariEngProhibitAlertBody',
  },
  location: 'Event_ukkariEngProhibitLocation',
  remoteControl: 'Event_ukkariEngProhibitRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkariHazard', {
  prefixId: 'Event_ukkariHazard',

  eventName: 'Event_ukkariHazardEventName',

  warning: {
    title: 'Event_ukkariHazardWarningTitle',
    hint: 'Event_ukkariHazardWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariHazardNotificationTitle',
    body: 'Event_ukkariHazardNotificationBody',
  },
  home: 'Event_ukkariHazardHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariHazardDialogTitle',
    body: 'Event_ukkariHazardDialogBody',
    action: 'Event_ukkariHazardDialogAction',
    action2: 'Event_ukkariHazardDialogAction2',
    close: 'Event_ukkariHazardDialogClose',
  },
  alert: {
    title: 'Event_ukkariHazardAlertTitle',
    body: 'Event_ukkariHazardAlertBody',
  },
  location: 'Event_ukkariHazardLocation',
  remoteControl: 'Event_ukkariHazardRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkariAircon', {
  prefixId: 'Event_ukkariAircon',

  eventName: 'Event_ukkariAirconEventName',

  warning: {
    title: 'Event_ukkariAirconWarningTitle',
    hint: 'Event_ukkariAirconWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariAirconNotificationTitle',
    body: 'Event_ukkariAirconNotificationBody',
  },
  home: 'Event_ukkariAirconHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariAirconDialogTitle',
    body: 'Event_ukkariAirconDialogBody',
    action: 'Event_ukkariAirconDialogAction',
    action2: 'Event_ukkariAirconDialogAction2',
    close: 'Event_ukkariAirconDialogClose',
  },
  alert: {
    title: 'Event_ukkariAirconAlertTitle',
    body: 'Event_ukkariAirconAlertBody',
  },
  location: 'Event_ukkariAirconLocation',
  remoteControl: 'Event_ukkariAirconRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkariEngine', {
  prefixId: 'Event_ukkariEngine',

  eventName: 'Event_ukkariEngineEventName',

  warning: {
    title: 'Event_ukkariEngineWarningTitle',
    hint: 'Event_ukkariEngineWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariEngineNotificationTitle',
    body: 'Event_ukkariEngineNotificationBody',
  },
  home: 'Event_ukkariEngineHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariEngineDialogTitle',
    body: 'Event_ukkariEngineDialogBody',
    action: 'Event_ukkariEngineDialogAction',
    action2: 'Event_ukkariEngineDialogAction2',
    close: 'Event_ukkariEngineDialogClose',
  },
  alert: {
    title: 'Event_ukkariEngineAlertTitle',
    body: 'Event_ukkariEngineAlertBody',
  },
  location: 'Event_ukkariEngineLocation',
  remoteControl: 'Event_ukkariEngineRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_ukkari', {
  prefixId: 'Event_ukkari',

  eventName: 'Event_ukkariEventName',

  warning: {
    title: 'Event_ukkariWarningTitle',
    hint: 'Event_ukkariWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_ukkariNotificationTitle',
    body: 'Event_ukkariNotificationBody',
  },
  home: 'Event_ukkariHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ukkariDialogTitle',
    body: 'Event_ukkariDialogBody',
    action: 'Event_ukkariDialogAction',
    action2: 'Event_ukkariDialogAction2',
    close: 'Event_ukkariDialogClose',
  },
  alert: {
    title: 'Event_ukkariAlertTitle',
    body: 'Event_ukkariAlertBody',
  },
  location: 'Event_ukkariLocation',
  remoteControl: 'Event_ukkariRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineProhibitOffFail', {
  prefixId: 'Event_remoteEngineProhibitOffFail',

  eventName: 'Event_remoteEngineProhibitOffFailEventName',

  warning: {
    title: 'Event_remoteEngineProhibitOffFailWarningTitle',
    hint: 'Event_remoteEngineProhibitOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineProhibitOffFailNotificationTitle',
    body: 'Event_remoteEngineProhibitOffFailNotificationBody',
  },
  home: 'Event_remoteEngineProhibitOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteEngineProhibitOffFailDialogTitle',
    body: 'Event_remoteEngineProhibitOffFailDialogBody',
    action: 'Event_remoteEngineProhibitOffFailDialogAction',
    action2: 'Event_remoteEngineProhibitOffFailDialogAction2',
    close: 'Event_remoteEngineProhibitOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineProhibitOffFailAlertTitle',
    body: 'Event_remoteEngineProhibitOffFailAlertBody',
  },
  location: 'Event_remoteEngineProhibitOffFailLocation',
  remoteControl: 'Event_remoteEngineProhibitOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineProhibitOffSuccess', {
  prefixId: 'Event_remoteEngineProhibitOffSuccess',

  eventName: 'Event_remoteEngineProhibitOffSuccessEventName',

  warning: {
    title: 'Event_remoteEngineProhibitOffSuccessWarningTitle',
    hint: 'Event_remoteEngineProhibitOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineProhibitOffSuccessNotificationTitle',
    body: 'Event_remoteEngineProhibitOffSuccessNotificationBody',
  },
  home: 'Event_remoteEngineProhibitOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteEngineProhibitOffSuccessDialogTitle',
    body: 'Event_remoteEngineProhibitOffSuccessDialogBody',
    action: 'Event_remoteEngineProhibitOffSuccessDialogAction',
    action2: 'Event_remoteEngineProhibitOffSuccessDialogAction2',
    close: 'Event_remoteEngineProhibitOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineProhibitOffSuccessAlertTitle',
    body: 'Event_remoteEngineProhibitOffSuccessAlertBody',
  },
  location: 'Event_remoteEngineProhibitOffSuccessLocation',
  remoteControl: 'Event_remoteEngineProhibitOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineProhibitOnFail', {
  prefixId: 'Event_remoteEngineProhibitOnFail',

  eventName: 'Event_remoteEngineProhibitOnFailEventName',

  warning: {
    title: 'Event_remoteEngineProhibitOnFailWarningTitle',
    hint: 'Event_remoteEngineProhibitOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineProhibitOnFailNotificationTitle',
    body: 'Event_remoteEngineProhibitOnFailNotificationBody',
  },
  home: 'Event_remoteEngineProhibitOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteEngineProhibitOnFailDialogTitle',
    body: 'Event_remoteEngineProhibitOnFailDialogBody',
    action: 'Event_remoteEngineProhibitOnFailDialogAction',
    action2: 'Event_remoteEngineProhibitOnFailDialogAction2',
    close: 'Event_remoteEngineProhibitOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineProhibitOnFailAlertTitle',
    body: 'Event_remoteEngineProhibitOnFailAlertBody',
  },
  location: 'Event_remoteEngineProhibitOnFailLocation',
  remoteControl: 'Event_remoteEngineProhibitOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineProhibitOnSuccess', {
  prefixId: 'Event_remoteEngineProhibitOnSuccess',

  eventName: 'Event_remoteEngineProhibitOnSuccessEventName',

  warning: {
    title: 'Event_remoteEngineProhibitOnSuccessWarningTitle',
    hint: 'Event_remoteEngineProhibitOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineProhibitOnSuccessNotificationTitle',
    body: 'Event_remoteEngineProhibitOnSuccessNotificationBody',
  },
  home: 'Event_remoteEngineProhibitOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteEngineProhibitOnSuccessDialogTitle',
    body: 'Event_remoteEngineProhibitOnSuccessDialogBody',
    action: 'Event_remoteEngineProhibitOnSuccessDialogAction',
    action2: 'Event_remoteEngineProhibitOnSuccessDialogAction2',
    close: 'Event_remoteEngineProhibitOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineProhibitOnSuccessAlertTitle',
    body: 'Event_remoteEngineProhibitOnSuccessAlertBody',
  },
  location: 'Event_remoteEngineProhibitOnSuccessLocation',
  remoteControl: 'Event_remoteEngineProhibitOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteWifiResetFail', {
  prefixId: 'Event_remoteWifiResetFail',

  eventName: 'Event_remoteWifiResetFailEventName',

  warning: {
    title: 'Event_remoteWifiResetFailWarningTitle',
    hint: 'Event_remoteWifiResetFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteWifiResetFailNotificationTitle',
    body: 'Event_remoteWifiResetFailNotificationBody',
  },
  home: 'Event_remoteWifiResetFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteWifiResetFailDialogTitle',
    body: 'Event_remoteWifiResetFailDialogBody',
    action: 'Event_remoteWifiResetFailDialogAction',
    action2: 'Event_remoteWifiResetFailDialogAction2',
    close: 'Event_remoteWifiResetFailDialogClose',
  },
  alert: {
    title: 'Event_remoteWifiResetFailAlertTitle',
    body: 'Event_remoteWifiResetFailAlertBody',
  },
  location: 'Event_remoteWifiResetFailLocation',
  remoteControl: 'Event_remoteWifiResetFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteWifiResetSuccess', {
  prefixId: 'Event_remoteWifiResetSuccess',

  eventName: 'Event_remoteWifiResetSuccessEventName',

  warning: {
    title: 'Event_remoteWifiResetSuccessWarningTitle',
    hint: 'Event_remoteWifiResetSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteWifiResetSuccessNotificationTitle',
    body: 'Event_remoteWifiResetSuccessNotificationBody',
  },
  home: 'Event_remoteWifiResetSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteWifiResetSuccessDialogTitle',
    body: 'Event_remoteWifiResetSuccessDialogBody',
    action: 'Event_remoteWifiResetSuccessDialogAction',
    action2: 'Event_remoteWifiResetSuccessDialogAction2',
    close: 'Event_remoteWifiResetSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteWifiResetSuccessAlertTitle',
    body: 'Event_remoteWifiResetSuccessAlertBody',
  },
  location: 'Event_remoteWifiResetSuccessLocation',
  remoteControl: 'Event_remoteWifiResetSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteWifiOffFail', {
  prefixId: 'Event_remoteWifiOffFail',

  eventName: 'Event_remoteWifiOffFailEventName',

  warning: {
    title: 'Event_remoteWifiOffFailWarningTitle',
    hint: 'Event_remoteWifiOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteWifiOffFailNotificationTitle',
    body: 'Event_remoteWifiOffFailNotificationBody',
  },
  home: 'Event_remoteWifiOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteWifiOffFailDialogTitle',
    body: 'Event_remoteWifiOffFailDialogBody',
    action: 'Event_remoteWifiOffFailDialogAction',
    action2: 'Event_remoteWifiOffFailDialogAction2',
    close: 'Event_remoteWifiOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteWifiOffFailAlertTitle',
    body: 'Event_remoteWifiOffFailAlertBody',
  },
  location: 'Event_remoteWifiOffFailLocation',
  remoteControl: 'Event_remoteWifiOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteWifiOffSuccess', {
  prefixId: 'Event_remoteWifiOffSuccess',

  eventName: 'Event_remoteWifiOffSuccessEventName',

  warning: {
    title: 'Event_remoteWifiOffSuccessWarningTitle',
    hint: 'Event_remoteWifiOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteWifiOffSuccessNotificationTitle',
    body: 'Event_remoteWifiOffSuccessNotificationBody',
  },
  home: 'Event_remoteWifiOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteWifiOffSuccessDialogTitle',
    body: 'Event_remoteWifiOffSuccessDialogBody',
    action: 'Event_remoteWifiOffSuccessDialogAction',
    action2: 'Event_remoteWifiOffSuccessDialogAction2',
    close: 'Event_remoteWifiOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteWifiOffSuccessAlertTitle',
    body: 'Event_remoteWifiOffSuccessAlertBody',
  },
  location: 'Event_remoteWifiOffSuccessLocation',
  remoteControl: 'Event_remoteWifiOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteWifiOnFail', {
  prefixId: 'Event_remoteWifiOnFail',

  eventName: 'Event_remoteWifiOnFailEventName',

  warning: {
    title: 'Event_remoteWifiOnFailWarningTitle',
    hint: 'Event_remoteWifiOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteWifiOnFailNotificationTitle',
    body: 'Event_remoteWifiOnFailNotificationBody',
  },
  home: 'Event_remoteWifiOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteWifiOnFailDialogTitle',
    body: 'Event_remoteWifiOnFailDialogBody',
    action: 'Event_remoteWifiOnFailDialogAction',
    action2: 'Event_remoteWifiOnFailDialogAction2',
    close: 'Event_remoteWifiOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteWifiOnFailAlertTitle',
    body: 'Event_remoteWifiOnFailAlertBody',
  },
  location: 'Event_remoteWifiOnFailLocation',
  remoteControl: 'Event_remoteWifiOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteWifiOnSuccess', {
  prefixId: 'Event_remoteWifiOnSuccess',

  eventName: 'Event_remoteWifiOnSuccessEventName',

  warning: {
    title: 'Event_remoteWifiOnSuccessWarningTitle',
    hint: 'Event_remoteWifiOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteWifiOnSuccessNotificationTitle',
    body: 'Event_remoteWifiOnSuccessNotificationBody',
  },
  home: 'Event_remoteWifiOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteWifiOnSuccessDialogTitle',
    body: 'Event_remoteWifiOnSuccessDialogBody',
    action: 'Event_remoteWifiOnSuccessDialogAction',
    action2: 'Event_remoteWifiOnSuccessDialogAction2',
    close: 'Event_remoteWifiOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteWifiOnSuccessAlertTitle',
    body: 'Event_remoteWifiOnSuccessAlertBody',
  },
  location: 'Event_remoteWifiOnSuccessLocation',
  remoteControl: 'Event_remoteWifiOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteGeofenceOffFail', {
  prefixId: 'Event_remoteGeofenceOffFail',

  eventName: 'Event_remoteGeofenceOffFailEventName',

  warning: {
    title: 'Event_remoteGeofenceOffFailWarningTitle',
    hint: 'Event_remoteGeofenceOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteGeofenceOffFailNotificationTitle',
    body: 'Event_remoteGeofenceOffFailNotificationBody',
  },
  home: 'Event_remoteGeofenceOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteGeofenceOffFailDialogTitle',
    body: 'Event_remoteGeofenceOffFailDialogBody',
    action: 'Event_remoteGeofenceOffFailDialogAction',
    action2: 'Event_remoteGeofenceOffFailDialogAction2',
    close: 'Event_remoteGeofenceOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteGeofenceOffFailAlertTitle',
    body: 'Event_remoteGeofenceOffFailAlertBody',
  },
  location: 'Event_remoteGeofenceOffFailLocation',
  remoteControl: 'Event_remoteGeofenceOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteGeofenceOffSuccess', {
  prefixId: 'Event_remoteGeofenceOffSuccess',

  eventName: 'Event_remoteGeofenceOffSuccessEventName',

  warning: {
    title: 'Event_remoteGeofenceOffSuccessWarningTitle',
    hint: 'Event_remoteGeofenceOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteGeofenceOffSuccessNotificationTitle',
    body: 'Event_remoteGeofenceOffSuccessNotificationBody',
  },
  home: 'Event_remoteGeofenceOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteGeofenceOffSuccessDialogTitle',
    body: 'Event_remoteGeofenceOffSuccessDialogBody',
    action: 'Event_remoteGeofenceOffSuccessDialogAction',
    action2: 'Event_remoteGeofenceOffSuccessDialogAction2',
    close: 'Event_remoteGeofenceOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteGeofenceOffSuccessAlertTitle',
    body: 'Event_remoteGeofenceOffSuccessAlertBody',
  },
  location: 'Event_remoteGeofenceOffSuccessLocation',
  remoteControl: 'Event_remoteGeofenceOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteGeofenceChangeFail', {
  prefixId: 'Event_remoteGeofenceChangeFail',

  eventName: 'Event_remoteGeofenceChangeFailEventName',

  warning: {
    title: 'Event_remoteGeofenceChangeFailWarningTitle',
    hint: 'Event_remoteGeofenceChangeFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteGeofenceChangeFailNotificationTitle',
    body: 'Event_remoteGeofenceChangeFailNotificationBody',
  },
  home: 'Event_remoteGeofenceChangeFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteGeofenceChangeFailDialogTitle',
    body: 'Event_remoteGeofenceChangeFailDialogBody',
    action: 'Event_remoteGeofenceChangeFailDialogAction',
    action2: 'Event_remoteGeofenceChangeFailDialogAction2',
    close: 'Event_remoteGeofenceChangeFailDialogClose',
  },
  alert: {
    title: 'Event_remoteGeofenceChangeFailAlertTitle',
    body: 'Event_remoteGeofenceChangeFailAlertBody',
  },
  location: 'Event_remoteGeofenceChangeFailLocation',
  remoteControl: 'Event_remoteGeofenceChangeFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteGeofenceChangeSuccess', {
  prefixId: 'Event_remoteGeofenceChangeSuccess',

  eventName: 'Event_remoteGeofenceChangeSuccessEventName',

  warning: {
    title: 'Event_remoteGeofenceChangeSuccessWarningTitle',
    hint: 'Event_remoteGeofenceChangeSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteGeofenceChangeSuccessNotificationTitle',
    body: 'Event_remoteGeofenceChangeSuccessNotificationBody',
  },
  home: 'Event_remoteGeofenceChangeSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteGeofenceChangeSuccessDialogTitle',
    body: 'Event_remoteGeofenceChangeSuccessDialogBody',
    action: 'Event_remoteGeofenceChangeSuccessDialogAction',
    action2: 'Event_remoteGeofenceChangeSuccessDialogAction2',
    close: 'Event_remoteGeofenceChangeSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteGeofenceChangeSuccessAlertTitle',
    body: 'Event_remoteGeofenceChangeSuccessAlertBody',
  },
  location: 'Event_remoteGeofenceChangeSuccessLocation',
  remoteControl: 'Event_remoteGeofenceChangeSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteGeofenceOnFail', {
  prefixId: 'Event_remoteGeofenceOnFail',

  eventName: 'Event_remoteGeofenceOnFailEventName',

  warning: {
    title: 'Event_remoteGeofenceOnFailWarningTitle',
    hint: 'Event_remoteGeofenceOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteGeofenceOnFailNotificationTitle',
    body: 'Event_remoteGeofenceOnFailNotificationBody',
  },
  home: 'Event_remoteGeofenceOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteGeofenceOnFailDialogTitle',
    body: 'Event_remoteGeofenceOnFailDialogBody',
    action: 'Event_remoteGeofenceOnFailDialogAction',
    action2: 'Event_remoteGeofenceOnFailDialogAction2',
    close: 'Event_remoteGeofenceOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteGeofenceOnFailAlertTitle',
    body: 'Event_remoteGeofenceOnFailAlertBody',
  },
  location: 'Event_remoteGeofenceOnFailLocation',
  remoteControl: 'Event_remoteGeofenceOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteGeofenceOnSuccess', {
  prefixId: 'Event_remoteGeofenceOnSuccess',

  eventName: 'Event_remoteGeofenceOnSuccessEventName',

  warning: {
    title: 'Event_remoteGeofenceOnSuccessWarningTitle',
    hint: 'Event_remoteGeofenceOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteGeofenceOnSuccessNotificationTitle',
    body: 'Event_remoteGeofenceOnSuccessNotificationBody',
  },
  home: 'Event_remoteGeofenceOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteGeofenceOnSuccessDialogTitle',
    body: 'Event_remoteGeofenceOnSuccessDialogBody',
    action: 'Event_remoteGeofenceOnSuccessDialogAction',
    action2: 'Event_remoteGeofenceOnSuccessDialogAction2',
    close: 'Event_remoteGeofenceOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteGeofenceOnSuccessAlertTitle',
    body: 'Event_remoteGeofenceOnSuccessAlertBody',
  },
  location: 'Event_remoteGeofenceOnSuccessLocation',
  remoteControl: 'Event_remoteGeofenceOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteTrackingOffFail', {
  prefixId: 'Event_remoteTrackingOffFail',

  eventName: 'Event_remoteTrackingOffFailEventName',

  warning: {
    title: 'Event_remoteTrackingOffFailWarningTitle',
    hint: 'Event_remoteTrackingOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteTrackingOffFailNotificationTitle',
    body: 'Event_remoteTrackingOffFailNotificationBody',
  },
  home: 'Event_remoteTrackingOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteTrackingOffFailDialogTitle',
    body: 'Event_remoteTrackingOffFailDialogBody',
    action: 'Event_remoteTrackingOffFailDialogAction',
    action2: 'Event_remoteTrackingOffFailDialogAction2',
    close: 'Event_remoteTrackingOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteTrackingOffFailAlertTitle',
    body: 'Event_remoteTrackingOffFailAlertBody',
  },
  location: 'Event_remoteTrackingOffFailLocation',
  remoteControl: 'Event_remoteTrackingOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteTrackingOffSuccess', {
  prefixId: 'Event_remoteTrackingOffSuccess',

  eventName: 'Event_remoteTrackingOffSuccessEventName',

  warning: {
    title: 'Event_remoteTrackingOffSuccessWarningTitle',
    hint: 'Event_remoteTrackingOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteTrackingOffSuccessNotificationTitle',
    body: 'Event_remoteTrackingOffSuccessNotificationBody',
  },
  home: 'Event_remoteTrackingOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteTrackingOffSuccessDialogTitle',
    body: 'Event_remoteTrackingOffSuccessDialogBody',
    action: 'Event_remoteTrackingOffSuccessDialogAction',
    action2: 'Event_remoteTrackingOffSuccessDialogAction2',
    close: 'Event_remoteTrackingOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteTrackingOffSuccessAlertTitle',
    body: 'Event_remoteTrackingOffSuccessAlertBody',
  },
  location: 'Event_remoteTrackingOffSuccessLocation',
  remoteControl: 'Event_remoteTrackingOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteTrackingOnFail', {
  prefixId: 'Event_remoteTrackingOnFail',

  eventName: 'Event_remoteTrackingOnFailEventName',

  warning: {
    title: 'Event_remoteTrackingOnFailWarningTitle',
    hint: 'Event_remoteTrackingOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteTrackingOnFailNotificationTitle',
    body: 'Event_remoteTrackingOnFailNotificationBody',
  },
  home: 'Event_remoteTrackingOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteTrackingOnFailDialogTitle',
    body: 'Event_remoteTrackingOnFailDialogBody',
    action: 'Event_remoteTrackingOnFailDialogAction',
    action2: 'Event_remoteTrackingOnFailDialogAction2',
    close: 'Event_remoteTrackingOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteTrackingOnFailAlertTitle',
    body: 'Event_remoteTrackingOnFailAlertBody',
  },
  location: 'Event_remoteTrackingOnFailLocation',
  remoteControl: 'Event_remoteTrackingOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteTrackingOnSuccess', {
  prefixId: 'Event_remoteTrackingOnSuccess',

  eventName: 'Event_remoteTrackingOnSuccessEventName',

  warning: {
    title: 'Event_remoteTrackingOnSuccessWarningTitle',
    hint: 'Event_remoteTrackingOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteTrackingOnSuccessNotificationTitle',
    body: 'Event_remoteTrackingOnSuccessNotificationBody',
  },
  home: 'Event_remoteTrackingOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteTrackingOnSuccessDialogTitle',
    body: 'Event_remoteTrackingOnSuccessDialogBody',
    action: 'Event_remoteTrackingOnSuccessDialogAction',
    action2: 'Event_remoteTrackingOnSuccessDialogAction2',
    close: 'Event_remoteTrackingOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteTrackingOnSuccessAlertTitle',
    body: 'Event_remoteTrackingOnSuccessAlertBody',
  },
  location: 'Event_remoteTrackingOnSuccessLocation',
  remoteControl: 'Event_remoteTrackingOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineOffFail', {
  prefixId: 'Event_remoteEngineOffFail',

  eventName: 'Event_remoteEngineOffFailEventName',

  warning: {
    title: 'Event_remoteEngineOffFailWarningTitle',
    hint: 'Event_remoteEngineOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineOffFailNotificationTitle',
    body: 'Event_remoteEngineOffFailNotificationBody',
  },
  home: 'Event_remoteEngineOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteEngineOffFailDialogTitle',
    body: 'Event_remoteEngineOffFailDialogBody',
    action: 'Event_remoteEngineOffFailDialogAction',
    action2: 'Event_remoteEngineOffFailDialogAction2',
    close: 'Event_remoteEngineOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineOffFailAlertTitle',
    body: 'Event_remoteEngineOffFailAlertBody',
  },
  location: 'Event_remoteEngineOffFailLocation',
  remoteControl: 'Event_remoteEngineOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineOffSuccess', {
  prefixId: 'Event_remoteEngineOffSuccess',

  eventName: 'Event_remoteEngineOffSuccessEventName',

  warning: {
    title: 'Event_remoteEngineOffSuccessWarningTitle',
    hint: 'Event_remoteEngineOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineOffSuccessNotificationTitle',
    body: 'Event_remoteEngineOffSuccessNotificationBody',
  },
  home: 'Event_remoteEngineOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteEngineOffSuccessDialogTitle',
    body: 'Event_remoteEngineOffSuccessDialogBody',
    action: 'Event_remoteEngineOffSuccessDialogAction',
    action2: 'Event_remoteEngineOffSuccessDialogAction2',
    close: 'Event_remoteEngineOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineOffSuccessAlertTitle',
    body: 'Event_remoteEngineOffSuccessAlertBody',
  },
  location: 'Event_remoteEngineOffSuccessLocation',
  remoteControl: 'Event_remoteEngineOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineOnFail', {
  prefixId: 'Event_remoteEngineOnFail',

  eventName: 'Event_remoteEngineOnFailEventName',

  warning: {
    title: 'Event_remoteEngineOnFailWarningTitle',
    hint: 'Event_remoteEngineOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineOnFailNotificationTitle',
    body: 'Event_remoteEngineOnFailNotificationBody',
  },
  home: 'Event_remoteEngineOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteEngineOnFailDialogTitle',
    body: 'Event_remoteEngineOnFailDialogBody',
    action: 'Event_remoteEngineOnFailDialogAction',
    action2: 'Event_remoteEngineOnFailDialogAction2',
    close: 'Event_remoteEngineOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineOnFailAlertTitle',
    body: 'Event_remoteEngineOnFailAlertBody',
  },
  location: 'Event_remoteEngineOnFailLocation',
  remoteControl: 'Event_remoteEngineOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteEngineOnSuccess', {
  prefixId: 'Event_remoteEngineOnSuccess',

  eventName: 'Event_remoteEngineOnSuccessEventName',

  warning: {
    title: 'Event_remoteEngineOnSuccessWarningTitle',
    hint: 'Event_remoteEngineOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteEngineOnSuccessNotificationTitle',
    body: 'Event_remoteEngineOnSuccessNotificationBody',
  },
  home: 'Event_remoteEngineOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteEngineOnSuccessDialogTitle',
    body: 'Event_remoteEngineOnSuccessDialogBody',
    action: 'Event_remoteEngineOnSuccessDialogAction',
    action2: 'Event_remoteEngineOnSuccessDialogAction2',
    close: 'Event_remoteEngineOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteEngineOnSuccessAlertTitle',
    body: 'Event_remoteEngineOnSuccessAlertBody',
  },
  location: 'Event_remoteEngineOnSuccessLocation',
  remoteControl: 'Event_remoteEngineOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteACOffFail', {
  prefixId: 'Event_remoteACOffFail',

  eventName: 'Event_remoteACOffFailEventName',

  warning: {
    title: 'Event_remoteACOffFailWarningTitle',
    hint: 'Event_remoteACOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteACOffFailNotificationTitle',
    body: 'Event_remoteACOffFailNotificationBody',
  },
  home: 'Event_remoteACOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteACOffFailDialogTitle',
    body: 'Event_remoteACOffFailDialogBody',
    action: 'Event_remoteACOffFailDialogAction',
    action2: 'Event_remoteACOffFailDialogAction2',
    close: 'Event_remoteACOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteACOffFailAlertTitle',
    body: 'Event_remoteACOffFailAlertBody',
  },
  location: 'Event_remoteACOffFailLocation',
  remoteControl: 'Event_remoteACOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteACOffSuccess', {
  prefixId: 'Event_remoteACOffSuccess',

  eventName: 'Event_remoteACOffSuccessEventName',

  warning: {
    title: 'Event_remoteACOffSuccessWarningTitle',
    hint: 'Event_remoteACOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteACOffSuccessNotificationTitle',
    body: 'Event_remoteACOffSuccessNotificationBody',
  },
  home: 'Event_remoteACOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteACOffSuccessDialogTitle',
    body: 'Event_remoteACOffSuccessDialogBody',
    action: 'Event_remoteACOffSuccessDialogAction',
    action2: 'Event_remoteACOffSuccessDialogAction2',
    close: 'Event_remoteACOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteACOffSuccessAlertTitle',
    body: 'Event_remoteACOffSuccessAlertBody',
  },
  location: 'Event_remoteACOffSuccessLocation',
  remoteControl: 'Event_remoteACOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteACOnFail', {
  prefixId: 'Event_remoteACOnFail',

  eventName: 'Event_remoteACOnFailEventName',

  warning: {
    title: 'Event_remoteACOnFailWarningTitle',
    hint: 'Event_remoteACOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteACOnFailNotificationTitle',
    body: 'Event_remoteACOnFailNotificationBody',
  },
  home: 'Event_remoteACOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteACOnFailDialogTitle',
    body: 'Event_remoteACOnFailDialogBody',
    action: 'Event_remoteACOnFailDialogAction',
    action2: 'Event_remoteACOnFailDialogAction2',
    close: 'Event_remoteACOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteACOnFailAlertTitle',
    body: 'Event_remoteACOnFailAlertBody',
  },
  location: 'Event_remoteACOnFailLocation',
  remoteControl: 'Event_remoteACOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteACOnSuccess', {
  prefixId: 'Event_remoteACOnSuccess',

  eventName: 'Event_remoteACOnSuccessEventName',

  warning: {
    title: 'Event_remoteACOnSuccessWarningTitle',
    hint: 'Event_remoteACOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteACOnSuccessNotificationTitle',
    body: 'Event_remoteACOnSuccessNotificationBody',
  },
  home: 'Event_remoteACOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteACOnSuccessDialogTitle',
    body: 'Event_remoteACOnSuccessDialogBody',
    action: 'Event_remoteACOnSuccessDialogAction',
    action2: 'Event_remoteACOnSuccessDialogAction2',
    close: 'Event_remoteACOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteACOnSuccessAlertTitle',
    body: 'Event_remoteACOnSuccessAlertBody',
  },
  location: 'Event_remoteACOnSuccessLocation',
  remoteControl: 'Event_remoteACOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHazardOffFail', {
  prefixId: 'Event_remoteHazardOffFail',

  eventName: 'Event_remoteHazardOffFailEventName',

  warning: {
    title: 'Event_remoteHazardOffFailWarningTitle',
    hint: 'Event_remoteHazardOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHazardOffFailNotificationTitle',
    body: 'Event_remoteHazardOffFailNotificationBody',
  },
  home: 'Event_remoteHazardOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteHazardOffFailDialogTitle',
    body: 'Event_remoteHazardOffFailDialogBody',
    action: 'Event_remoteHazardOffFailDialogAction',
    action2: 'Event_remoteHazardOffFailDialogAction2',
    close: 'Event_remoteHazardOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteHazardOffFailAlertTitle',
    body: 'Event_remoteHazardOffFailAlertBody',
  },
  location: 'Event_remoteHazardOffFailLocation',
  remoteControl: 'Event_remoteHazardOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHazardOffSuccess', {
  prefixId: 'Event_remoteHazardOffSuccess',

  eventName: 'Event_remoteHazardOffSuccessEventName',

  warning: {
    title: 'Event_remoteHazardOffSuccessWarningTitle',
    hint: 'Event_remoteHazardOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHazardOffSuccessNotificationTitle',
    body: 'Event_remoteHazardOffSuccessNotificationBody',
  },
  home: 'Event_remoteHazardOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteHazardOffSuccessDialogTitle',
    body: 'Event_remoteHazardOffSuccessDialogBody',
    action: 'Event_remoteHazardOffSuccessDialogAction',
    action2: 'Event_remoteHazardOffSuccessDialogAction2',
    close: 'Event_remoteHazardOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteHazardOffSuccessAlertTitle',
    body: 'Event_remoteHazardOffSuccessAlertBody',
  },
  location: 'Event_remoteHazardOffSuccessLocation',
  remoteControl: 'Event_remoteHazardOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHazardOnFail', {
  prefixId: 'Event_remoteHazardOnFail',

  eventName: 'Event_remoteHazardOnFailEventName',

  warning: {
    title: 'Event_remoteHazardOnFailWarningTitle',
    hint: 'Event_remoteHazardOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHazardOnFailNotificationTitle',
    body: 'Event_remoteHazardOnFailNotificationBody',
  },
  home: 'Event_remoteHazardOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteHazardOnFailDialogTitle',
    body: 'Event_remoteHazardOnFailDialogBody',
    action: 'Event_remoteHazardOnFailDialogAction',
    action2: 'Event_remoteHazardOnFailDialogAction2',
    close: 'Event_remoteHazardOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteHazardOnFailAlertTitle',
    body: 'Event_remoteHazardOnFailAlertBody',
  },
  location: 'Event_remoteHazardOnFailLocation',
  remoteControl: 'Event_remoteHazardOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHazardOnSuccess', {
  prefixId: 'Event_remoteHazardOnSuccess',

  eventName: 'Event_remoteHazardOnSuccessEventName',

  warning: {
    title: 'Event_remoteHazardOnSuccessWarningTitle',
    hint: 'Event_remoteHazardOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHazardOnSuccessNotificationTitle',
    body: 'Event_remoteHazardOnSuccessNotificationBody',
  },
  home: 'Event_remoteHazardOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteHazardOnSuccessDialogTitle',
    body: 'Event_remoteHazardOnSuccessDialogBody',
    action: 'Event_remoteHazardOnSuccessDialogAction',
    action2: 'Event_remoteHazardOnSuccessDialogAction2',
    close: 'Event_remoteHazardOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteHazardOnSuccessAlertTitle',
    body: 'Event_remoteHazardOnSuccessAlertBody',
  },
  location: 'Event_remoteHazardOnSuccessLocation',
  remoteControl: 'Event_remoteHazardOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHeadLightOffFail', {
  prefixId: 'Event_remoteHeadLightOffFail',

  eventName: 'Event_remoteHeadLightOffFailEventName',

  warning: {
    title: 'Event_remoteHeadLightOffFailWarningTitle',
    hint: 'Event_remoteHeadLightOffFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHeadLightOffFailNotificationTitle',
    body: 'Event_remoteHeadLightOffFailNotificationBody',
  },
  home: 'Event_remoteHeadLightOffFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteHeadLightOffFailDialogTitle',
    body: 'Event_remoteHeadLightOffFailDialogBody',
    action: 'Event_remoteHeadLightOffFailDialogAction',
    action2: 'Event_remoteHeadLightOffFailDialogAction2',
    close: 'Event_remoteHeadLightOffFailDialogClose',
  },
  alert: {
    title: 'Event_remoteHeadLightOffFailAlertTitle',
    body: 'Event_remoteHeadLightOffFailAlertBody',
  },
  location: 'Event_remoteHeadLightOffFailLocation',
  remoteControl: 'Event_remoteHeadLightOffFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHeadLightOffSuccess', {
  prefixId: 'Event_remoteHeadLightOffSuccess',

  eventName: 'Event_remoteHeadLightOffSuccessEventName',

  warning: {
    title: 'Event_remoteHeadLightOffSuccessWarningTitle',
    hint: 'Event_remoteHeadLightOffSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHeadLightOffSuccessNotificationTitle',
    body: 'Event_remoteHeadLightOffSuccessNotificationBody',
  },
  home: 'Event_remoteHeadLightOffSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteHeadLightOffSuccessDialogTitle',
    body: 'Event_remoteHeadLightOffSuccessDialogBody',
    action: 'Event_remoteHeadLightOffSuccessDialogAction',
    action2: 'Event_remoteHeadLightOffSuccessDialogAction2',
    close: 'Event_remoteHeadLightOffSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteHeadLightOffSuccessAlertTitle',
    body: 'Event_remoteHeadLightOffSuccessAlertBody',
  },
  location: 'Event_remoteHeadLightOffSuccessLocation',
  remoteControl: 'Event_remoteHeadLightOffSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHeadLightOnFail', {
  prefixId: 'Event_remoteHeadLightOnFail',

  eventName: 'Event_remoteHeadLightOnFailEventName',

  warning: {
    title: 'Event_remoteHeadLightOnFailWarningTitle',
    hint: 'Event_remoteHeadLightOnFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHeadLightOnFailNotificationTitle',
    body: 'Event_remoteHeadLightOnFailNotificationBody',
  },
  home: 'Event_remoteHeadLightOnFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteHeadLightOnFailDialogTitle',
    body: 'Event_remoteHeadLightOnFailDialogBody',
    action: 'Event_remoteHeadLightOnFailDialogAction',
    action2: 'Event_remoteHeadLightOnFailDialogAction2',
    close: 'Event_remoteHeadLightOnFailDialogClose',
  },
  alert: {
    title: 'Event_remoteHeadLightOnFailAlertTitle',
    body: 'Event_remoteHeadLightOnFailAlertBody',
  },
  location: 'Event_remoteHeadLightOnFailLocation',
  remoteControl: 'Event_remoteHeadLightOnFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteHeadLightOnSuccess', {
  prefixId: 'Event_remoteHeadLightOnSuccess',

  eventName: 'Event_remoteHeadLightOnSuccessEventName',

  warning: {
    title: 'Event_remoteHeadLightOnSuccessWarningTitle',
    hint: 'Event_remoteHeadLightOnSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteHeadLightOnSuccessNotificationTitle',
    body: 'Event_remoteHeadLightOnSuccessNotificationBody',
  },
  home: 'Event_remoteHeadLightOnSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteHeadLightOnSuccessDialogTitle',
    body: 'Event_remoteHeadLightOnSuccessDialogBody',
    action: 'Event_remoteHeadLightOnSuccessDialogAction',
    action2: 'Event_remoteHeadLightOnSuccessDialogAction2',
    close: 'Event_remoteHeadLightOnSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteHeadLightOnSuccessAlertTitle',
    body: 'Event_remoteHeadLightOnSuccessAlertBody',
  },
  location: 'Event_remoteHeadLightOnSuccessLocation',
  remoteControl: 'Event_remoteHeadLightOnSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteDoorLockedFail', {
  prefixId: 'Event_remoteDoorLockedFail',

  eventName: 'Event_remoteDoorLockedFailEventName',

  warning: {
    title: 'Event_remoteDoorLockedFailWarningTitle',
    hint: 'Event_remoteDoorLockedFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteDoorLockedFailNotificationTitle',
    body: 'Event_remoteDoorLockedFailNotificationBody',
  },
  home: 'Event_remoteDoorLockedFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_remoteDoorLockedFailDialogTitle',
    body: 'Event_remoteDoorLockedFailDialogBody',
    action: 'Event_remoteDoorLockedFailDialogAction',
    action2: 'Event_remoteDoorLockedFailDialogAction2',
    close: 'Event_remoteDoorLockedFailDialogClose',
  },
  alert: {
    title: 'Event_remoteDoorLockedFailAlertTitle',
    body: 'Event_remoteDoorLockedFailAlertBody',
  },
  location: 'Event_remoteDoorLockedFailLocation',
  remoteControl: 'Event_remoteDoorLockedFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_remoteDoorLockedSuccess', {
  prefixId: 'Event_remoteDoorLockedSuccess',

  eventName: 'Event_remoteDoorLockedSuccessEventName',

  warning: {
    title: 'Event_remoteDoorLockedSuccessWarningTitle',
    hint: 'Event_remoteDoorLockedSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_remoteDoorLockedSuccessNotificationTitle',
    body: 'Event_remoteDoorLockedSuccessNotificationBody',
  },
  home: 'Event_remoteDoorLockedSuccessHome',
  dialog: {
    type: 'None',
    title: 'Event_remoteDoorLockedSuccessDialogTitle',
    body: 'Event_remoteDoorLockedSuccessDialogBody',
    action: 'Event_remoteDoorLockedSuccessDialogAction',
    action2: 'Event_remoteDoorLockedSuccessDialogAction2',
    close: 'Event_remoteDoorLockedSuccessDialogClose',
  },
  alert: {
    title: 'Event_remoteDoorLockedSuccessAlertTitle',
    body: 'Event_remoteDoorLockedSuccessAlertBody',
  },
  location: 'Event_remoteDoorLockedSuccessLocation',
  remoteControl: 'Event_remoteDoorLockedSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_carResgitrationFail', {
  prefixId: 'Event_carResgitrationFail',

  eventName: 'Event_carResgitrationFailEventName',

  warning: {
    title: 'Event_carResgitrationFailWarningTitle',
    hint: 'Event_carResgitrationFailWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_carResgitrationFailNotificationTitle',
    body: 'Event_carResgitrationFailNotificationBody',
  },
  home: 'Event_carResgitrationFailHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_carResgitrationFailDialogTitle',
    body: 'Event_carResgitrationFailDialogBody',
    action: 'Event_carResgitrationFailDialogAction',
    action2: 'Event_carResgitrationFailDialogAction2',
    close: 'Event_carResgitrationFailDialogClose',
  },
  alert: {
    title: 'Event_carResgitrationFailAlertTitle',
    body: 'Event_carResgitrationFailAlertBody',
  },
  location: 'Event_carResgitrationFailLocation',
  remoteControl: 'Event_carResgitrationFailRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_carResgitrationSuccess', {
  prefixId: 'Event_carResgitrationSuccess',

  eventName: 'Event_carResgitrationSuccessEventName',

  warning: {
    title: 'Event_carResgitrationSuccessWarningTitle',
    hint: 'Event_carResgitrationSuccessWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_carResgitrationSuccessNotificationTitle',
    body: 'Event_carResgitrationSuccessNotificationBody',
  },
  home: 'Event_carResgitrationSuccessHome',
  dialog: {
    type: 'Alert',
    title: 'Event_carResgitrationSuccessDialogTitle',
    body: 'Event_carResgitrationSuccessDialogBody',
    action: 'Event_carResgitrationSuccessDialogAction',
    action2: 'Event_carResgitrationSuccessDialogAction2',
    close: 'Event_carResgitrationSuccessDialogClose',
  },
  alert: {
    title: 'Event_carResgitrationSuccessAlertTitle',
    body: 'Event_carResgitrationSuccessAlertBody',
  },
  location: 'Event_carResgitrationSuccessLocation',
  remoteControl: 'Event_carResgitrationSuccessRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_keyFreeAlert4', {
  prefixId: 'Event_keyFreeAlert4',

  eventName: 'Event_keyFreeAlert4EventName',

  warning: {
    title: 'Event_keyFreeAlert4WarningTitle',
    hint: 'Event_keyFreeAlert4WarningHint',
    order: 20,
  },
  notification: {
    title: 'Event_keyFreeAlert4NotificationTitle',
    body: 'Event_keyFreeAlert4NotificationBody',
  },
  home: 'Event_keyFreeAlert4Home',
  dialog: {
    type: 'Confirm',
    title: 'Event_keyFreeAlert4DialogTitle',
    body: 'Event_keyFreeAlert4DialogBody',
    action: 'Event_keyFreeAlert4DialogAction',
    action2: 'Event_keyFreeAlert4DialogAction2',
    close: 'Event_keyFreeAlert4DialogClose',
  },
  alert: {
    title: 'Event_keyFreeAlert4AlertTitle',
    body: 'Event_keyFreeAlert4AlertBody',
  },
  location: 'Event_keyFreeAlert4Location',
  remoteControl: 'Event_keyFreeAlert4RemoteControl',

  image: {
    normal: Icons.ic_keybattery_normal,
    active: Icons.ic_keybattery_active,
    white: Icons.ic_keybattery_white,
  },
});

unsafeMessageIdMap.set('Event_towAwayAlert', {
  prefixId: 'Event_towAwayAlert',

  eventName: 'Event_towAwayAlertEventName',

  warning: {
    title: 'Event_towAwayAlertWarningTitle',
    hint: 'Event_towAwayAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_towAwayAlertNotificationTitle',
    body: 'Event_towAwayAlertNotificationBody',
  },
  home: 'Event_towAwayAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_towAwayAlertDialogTitle',
    body: 'Event_towAwayAlertDialogBody',
    action: 'Event_towAwayAlertDialogAction',
    action2: 'Event_towAwayAlertDialogAction2',
    close: 'Event_towAwayAlertDialogClose',
  },
  alert: {
    title: 'Event_towAwayAlertAlertTitle',
    body: 'Event_towAwayAlertAlertBody',
  },
  location: 'Event_towAwayAlertLocation',
  remoteControl: 'Event_towAwayAlertRemoteControl',

  image: {
    normal: Icons.ic_tow_normal,
    active: undefined,
    white: Icons.ic_tow_white,
  },
});

unsafeMessageIdMap.set('Event_geofenceInAlert', {
  prefixId: 'Event_geofenceInAlert',

  eventName: 'Event_geofenceInAlertEventName',

  warning: {
    title: 'Event_geofenceInAlertWarningTitle',
    hint: 'Event_geofenceInAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_geofenceInAlertNotificationTitle',
    body: 'Event_geofenceInAlertNotificationBody',
  },
  home: 'Event_geofenceInAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_geofenceInAlertDialogTitle',
    body: 'Event_geofenceInAlertDialogBody',
    action: 'Event_geofenceInAlertDialogAction',
    action2: 'Event_geofenceInAlertDialogAction2',
    close: 'Event_geofenceInAlertDialogClose',
  },
  alert: {
    title: 'Event_geofenceInAlertAlertTitle',
    body: 'Event_geofenceInAlertAlertBody',
  },
  location: 'Event_geofenceInAlertLocation',
  remoteControl: 'Event_geofenceInAlertRemoteControl',

  image: {
    normal: Icons.ic_geofence_normal,
    active: undefined,
    white: Icons.ic_geofence_white,
  },
});

unsafeMessageIdMap.set('Event_geofenceOutAlert', {
  prefixId: 'Event_geofenceOutAlert',

  eventName: 'Event_geofenceOutAlertEventName',

  warning: {
    title: 'Event_geofenceOutAlertWarningTitle',
    hint: 'Event_geofenceOutAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_geofenceOutAlertNotificationTitle',
    body: 'Event_geofenceOutAlertNotificationBody',
  },
  home: 'Event_geofenceOutAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_geofenceOutAlertDialogTitle',
    body: 'Event_geofenceOutAlertDialogBody',
    action: 'Event_geofenceOutAlertDialogAction',
    action2: 'Event_geofenceOutAlertDialogAction2',
    close: 'Event_geofenceOutAlertDialogClose',
  },
  alert: {
    title: 'Event_geofenceOutAlertAlertTitle',
    body: 'Event_geofenceOutAlertAlertBody',
  },
  location: 'Event_geofenceOutAlertLocation',
  remoteControl: 'Event_geofenceOutAlertRemoteControl',

  image: {
    normal: Icons.ic_geofence_normal,
    active: undefined,
    white: Icons.ic_geofence_white,
  },
});

unsafeMessageIdMap.set('Event_removeDcmAlert', {
  prefixId: 'Event_removeDcmAlert',

  eventName: 'Event_removeDcmAlertEventName',

  warning: {
    title: 'Event_removeDcmAlertWarningTitle',
    hint: 'Event_removeDcmAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_removeDcmAlertNotificationTitle',
    body: 'Event_removeDcmAlertNotificationBody',
  },
  home: 'Event_removeDcmAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_removeDcmAlertDialogTitle',
    body: 'Event_removeDcmAlertDialogBody',
    action: 'Event_removeDcmAlertDialogAction',
    action2: 'Event_removeDcmAlertDialogAction2',
    close: 'Event_removeDcmAlertDialogClose',
  },
  alert: {
    title: 'Event_removeDcmAlertAlertTitle',
    body: 'Event_removeDcmAlertAlertBody',
  },
  location: 'Event_removeDcmAlertLocation',
  remoteControl: 'Event_removeDcmAlertRemoteControl',

  image: {
    normal: Icons.ic_dcm_normal,
    active: undefined,
    white: Icons.ic_dcm_white,
  },
});

unsafeMessageIdMap.set('Event_alarmAlert', {
  prefixId: 'Event_alarmAlert',

  eventName: 'Event_alarmAlertEventName',

  warning: {
    title: 'Event_alarmAlertWarningTitle',
    hint: 'Event_alarmAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_alarmAlertNotificationTitle',
    body: 'Event_alarmAlertNotificationBody',
  },
  home: 'Event_alarmAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_alarmAlertDialogTitle',
    body: 'Event_alarmAlertDialogBody',
    action: 'Event_alarmAlertDialogAction',
    action2: 'Event_alarmAlertDialogAction2',
    close: 'Event_alarmAlertDialogClose',
  },
  alert: {
    title: 'Event_alarmAlertAlertTitle',
    body: 'Event_alarmAlertAlertBody',
  },
  location: 'Event_alarmAlertLocation',
  remoteControl: 'Event_alarmAlertRemoteControl',

  image: {
    normal: Icons.ic_alarm_normal,
    active: undefined,
    white: Icons.ic_alarm_white,
  },
});

unsafeMessageIdMap.set('Event_systemIGOffAlert', {
  prefixId: 'Event_systemIGOffAlert',

  eventName: 'Event_systemIGOffAlertEventName',

  warning: {
    title: 'Event_systemIGOffAlertWarningTitle',
    hint: 'Event_systemIGOffAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_systemIGOffAlertNotificationTitle',
    body: 'Event_systemIGOffAlertNotificationBody',
  },
  home: 'Event_systemIGOffAlertHome',
  dialog: {
    type: 'None',
    title: 'Event_systemIGOffAlertDialogTitle',
    body: 'Event_systemIGOffAlertDialogBody',
    action: 'Event_systemIGOffAlertDialogAction',
    action2: 'Event_systemIGOffAlertDialogAction2',
    close: 'Event_systemIGOffAlertDialogClose',
  },
  alert: {
    title: 'Event_systemIGOffAlertAlertTitle',
    body: 'Event_systemIGOffAlertAlertBody',
  },
  location: 'Event_systemIGOffAlertLocation',
  remoteControl: 'Event_systemIGOffAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_systemIGOnAlert', {
  prefixId: 'Event_systemIGOnAlert',

  eventName: 'Event_systemIGOnAlertEventName',

  warning: {
    title: 'Event_systemIGOnAlertWarningTitle',
    hint: 'Event_systemIGOnAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_systemIGOnAlertNotificationTitle',
    body: 'Event_systemIGOnAlertNotificationBody',
  },
  home: 'Event_systemIGOnAlertHome',
  dialog: {
    type: 'None',
    title: 'Event_systemIGOnAlertDialogTitle',
    body: 'Event_systemIGOnAlertDialogBody',
    action: 'Event_systemIGOnAlertDialogAction',
    action2: 'Event_systemIGOnAlertDialogAction2',
    close: 'Event_systemIGOnAlertDialogClose',
  },
  alert: {
    title: 'Event_systemIGOnAlertAlertTitle',
    body: 'Event_systemIGOnAlertAlertBody',
  },
  location: 'Event_systemIGOnAlertLocation',
  remoteControl: 'Event_systemIGOnAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_userIGOffAlert', {
  prefixId: 'Event_userIGOffAlert',

  eventName: 'Event_userIGOffAlertEventName',

  warning: {
    title: 'Event_userIGOffAlertWarningTitle',
    hint: 'Event_userIGOffAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_userIGOffAlertNotificationTitle',
    body: 'Event_userIGOffAlertNotificationBody',
  },
  home: 'Event_userIGOffAlertHome',
  dialog: {
    type: 'None',
    title: 'Event_userIGOffAlertDialogTitle',
    body: 'Event_userIGOffAlertDialogBody',
    action: 'Event_userIGOffAlertDialogAction',
    action2: 'Event_userIGOffAlertDialogAction2',
    close: 'Event_userIGOffAlertDialogClose',
  },
  alert: {
    title: 'Event_userIGOffAlertAlertTitle',
    body: 'Event_userIGOffAlertAlertBody',
  },
  location: 'Event_userIGOffAlertLocation',
  remoteControl: 'Event_userIGOffAlertRemoteControl',

  image: {
    normal: undefined,
    active: undefined,
    white: undefined,
  },
});

unsafeMessageIdMap.set('Event_userIGOnAlert', {
  prefixId: 'Event_userIGOnAlert',

  eventName: 'Event_userIGOnAlertEventName',

  warning: {
    title: 'Event_userIGOnAlertWarningTitle',
    hint: 'Event_userIGOnAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_userIGOnAlertNotificationTitle',
    body: 'Event_userIGOnAlertNotificationBody',
  },
  home: 'Event_userIGOnAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_userIGOnAlertDialogTitle',
    body: 'Event_userIGOnAlertDialogBody',
    action: 'Event_userIGOnAlertDialogAction',
    action2: 'Event_userIGOnAlertDialogAction2',
    close: 'Event_userIGOnAlertDialogClose',
  },
  alert: {
    title: 'Event_userIGOnAlertAlertTitle',
    body: 'Event_userIGOnAlertAlertBody',
  },
  location: 'Event_userIGOnAlertLocation',
  remoteControl: 'Event_userIGOnAlertRemoteControl',

  image: {
    normal: Icons.ic_engine_normal,
    active: Icons.ic_engine_active,
    white: Icons.ic_engine_white,
  },
});

unsafeMessageIdMap.set('Event_elePowSteOreAlert', {
  prefixId: 'Event_elePowSteOreAlert',

  eventName: 'Event_elePowSteOreAlertEventName',

  warning: {
    title: 'Event_elePowSteOreAlertWarningTitle',
    hint: 'Event_elePowSteOreAlertWarningHint',
    order: 16,
  },
  notification: {
    title: 'Event_elePowSteOreAlertNotificationTitle',
    body: 'Event_elePowSteOreAlertNotificationBody',
  },
  home: 'Event_elePowSteOreAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_elePowSteOreAlertDialogTitle',
    body: 'Event_elePowSteOreAlertDialogBody',
    action: 'Event_elePowSteOreAlertDialogAction',
    action2: 'Event_elePowSteOreAlertDialogAction2',
    close: 'Event_elePowSteOreAlertDialogClose',
  },
  alert: {
    title: 'Event_elePowSteOreAlertAlertTitle',
    body: 'Event_elePowSteOreAlertAlertBody',
  },
  location: 'Event_elePowSteOreAlertLocation',
  remoteControl: 'Event_elePowSteOreAlertRemoteControl',

  image: {
    normal: Icons.ic_steering_normal,
    active: Icons.ic_steering_active,
    white: Icons.ic_steering_white,
  },
});

unsafeMessageIdMap.set('Event_afsWarnAlret', {
  prefixId: 'Event_afsWarnAlret',

  eventName: 'Event_afsWarnAlretEventName',

  warning: {
    title: 'Event_afsWarnAlretWarningTitle',
    hint: 'Event_afsWarnAlretWarningHint',
    order: 23,
  },
  notification: {
    title: 'Event_afsWarnAlretNotificationTitle',
    body: 'Event_afsWarnAlretNotificationBody',
  },
  home: 'Event_afsWarnAlretHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_afsWarnAlretDialogTitle',
    body: 'Event_afsWarnAlretDialogBody',
    action: 'Event_afsWarnAlretDialogAction',
    action2: 'Event_afsWarnAlretDialogAction2',
    close: 'Event_afsWarnAlretDialogClose',
  },
  alert: {
    title: 'Event_afsWarnAlretAlertTitle',
    body: 'Event_afsWarnAlretAlertBody',
  },
  location: 'Event_afsWarnAlretLocation',
  remoteControl: 'Event_afsWarnAlretRemoteControl',

  image: {
    normal: Icons.ic_afs_normal,
    active: Icons.ic_afs_active,
    white: Icons.ic_afs_white,
  },
});

unsafeMessageIdMap.set('Event_fourWheelSysAlert', {
  prefixId: 'Event_fourWheelSysAlert',

  eventName: 'Event_fourWheelSysAlertEventName',

  warning: {
    title: 'Event_fourWheelSysAlertWarningTitle',
    hint: 'Event_fourWheelSysAlertWarningHint',
    order: 9,
  },
  notification: {
    title: 'Event_fourWheelSysAlertNotificationTitle',
    body: 'Event_fourWheelSysAlertNotificationBody',
  },
  home: 'Event_fourWheelSysAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_fourWheelSysAlertDialogTitle',
    body: 'Event_fourWheelSysAlertDialogBody',
    action: 'Event_fourWheelSysAlertDialogAction',
    action2: 'Event_fourWheelSysAlertDialogAction2',
    close: 'Event_fourWheelSysAlertDialogClose',
  },
  alert: {
    title: 'Event_fourWheelSysAlertAlertTitle',
    body: 'Event_fourWheelSysAlertAlertBody',
  },
  location: 'Event_fourWheelSysAlertLocation',
  remoteControl: 'Event_fourWheelSysAlertRemoteControl',

  image: {
    normal: Icons.ic_4wd_normal,
    active: Icons.ic_4wd_active,
    white: Icons.ic_4wd_white,
  },
});

unsafeMessageIdMap.set('Event_autoHighBeamAlert', {
  prefixId: 'Event_autoHighBeamAlert',

  eventName: 'Event_autoHighBeamAlertEventName',

  warning: {
    title: 'Event_autoHighBeamAlertWarningTitle',
    hint: 'Event_autoHighBeamAlertWarningHint',
    order: 24,
  },
  notification: {
    title: 'Event_autoHighBeamAlertNotificationTitle',
    body: 'Event_autoHighBeamAlertNotificationBody',
  },
  home: 'Event_autoHighBeamAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_autoHighBeamAlertDialogTitle',
    body: 'Event_autoHighBeamAlertDialogBody',
    action: 'Event_autoHighBeamAlertDialogAction',
    action2: 'Event_autoHighBeamAlertDialogAction2',
    close: 'Event_autoHighBeamAlertDialogClose',
  },
  alert: {
    title: 'Event_autoHighBeamAlertAlertTitle',
    body: 'Event_autoHighBeamAlertAlertBody',
  },
  location: 'Event_autoHighBeamAlertLocation',
  remoteControl: 'Event_autoHighBeamAlertRemoteControl',

  image: {
    normal: Icons.ic_autohighbeam_normal,
    active: Icons.ic_autohighbeam_active,
    white: Icons.ic_autohighbeam_white,
  },
});

unsafeMessageIdMap.set('Event_keyFreeAlert', {
  prefixId: 'Event_keyFreeAlert',

  eventName: 'Event_keyFreeAlertEventName',

  warning: {
    title: 'Event_keyFreeAlertWarningTitle',
    hint: 'Event_keyFreeAlertWarningHint',
    order: 21,
  },
  notification: {
    title: 'Event_keyFreeAlertNotificationTitle',
    body: 'Event_keyFreeAlertNotificationBody',
  },
  home: 'Event_keyFreeAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_keyFreeAlertDialogTitle',
    body: 'Event_keyFreeAlertDialogBody',
    action: 'Event_keyFreeAlertDialogAction',
    action2: 'Event_keyFreeAlertDialogAction2',
    close: 'Event_keyFreeAlertDialogClose',
  },
  alert: {
    title: 'Event_keyFreeAlertAlertTitle',
    body: 'Event_keyFreeAlertAlertBody',
  },
  location: 'Event_keyFreeAlertLocation',
  remoteControl: 'Event_keyFreeAlertRemoteControl',

  image: {
    normal: Icons.ic_keyfree_normal,
    active: Icons.ic_keyfree_active,
    white: Icons.ic_keyfree_white,
  },
});

unsafeMessageIdMap.set('Event_ecoIdelOffAlert', {
  prefixId: 'Event_ecoIdelOffAlert',

  eventName: 'Event_ecoIdelOffAlertEventName',

  warning: {
    title: 'Event_ecoIdelOffAlertWarningTitle',
    hint: 'Event_ecoIdelOffAlertWarningHint',
    order: 6,
  },
  notification: {
    title: 'Event_ecoIdelOffAlertNotificationTitle',
    body: 'Event_ecoIdelOffAlertNotificationBody',
  },
  home: 'Event_ecoIdelOffAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_ecoIdelOffAlertDialogTitle',
    body: 'Event_ecoIdelOffAlertDialogBody',
    action: 'Event_ecoIdelOffAlertDialogAction',
    action2: 'Event_ecoIdelOffAlertDialogAction2',
    close: 'Event_ecoIdelOffAlertDialogClose',
  },
  alert: {
    title: 'Event_ecoIdelOffAlertAlertTitle',
    body: 'Event_ecoIdelOffAlertAlertBody',
  },
  location: 'Event_ecoIdelOffAlertLocation',
  remoteControl: 'Event_ecoIdelOffAlertRemoteControl',

  image: {
    normal: Icons.ic_idling_normal,
    active: Icons.ic_idling_active,
    white: Icons.ic_idling_white,
  },
});

unsafeMessageIdMap.set('Event_brakeHoldAlert', {
  prefixId: 'Event_brakeHoldAlert',

  eventName: 'Event_brakeHoldAlertEventName',

  warning: {
    title: 'Event_brakeHoldAlertWarningTitle',
    hint: 'Event_brakeHoldAlertWarningHint',
    order: 18,
  },
  notification: {
    title: 'Event_brakeHoldAlertNotificationTitle',
    body: 'Event_brakeHoldAlertNotificationBody',
  },
  home: 'Event_brakeHoldAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_brakeHoldAlertDialogTitle',
    body: 'Event_brakeHoldAlertDialogBody',
    action: 'Event_brakeHoldAlertDialogAction',
    action2: 'Event_brakeHoldAlertDialogAction2',
    close: 'Event_brakeHoldAlertDialogClose',
  },
  alert: {
    title: 'Event_brakeHoldAlertAlertTitle',
    body: 'Event_brakeHoldAlertAlertBody',
  },
  location: 'Event_brakeHoldAlertLocation',
  remoteControl: 'Event_brakeHoldAlertRemoteControl',

  image: {
    normal: Icons.ic_brakehold_normal,
    active: Icons.ic_brakehold_active,
    white: Icons.ic_brakehold_white,
  },
});

unsafeMessageIdMap.set('Event_smaashiErrAlert', {
  prefixId: 'Event_smaashiErrAlert',

  eventName: 'Event_smaashiErrAlertEventName',

  warning: {
    title: 'Event_smaashiErrAlertWarningTitle',
    hint: 'Event_smaashiErrAlertWarningHint',
    order: 19,
  },
  notification: {
    title: 'Event_smaashiErrAlertNotificationTitle',
    body: 'Event_smaashiErrAlertNotificationBody',
  },
  home: 'Event_smaashiErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_smaashiErrAlertDialogTitle',
    body: 'Event_smaashiErrAlertDialogBody',
    action: 'Event_smaashiErrAlertDialogAction',
    action2: 'Event_smaashiErrAlertDialogAction2',
    close: 'Event_smaashiErrAlertDialogClose',
  },
  alert: {
    title: 'Event_smaashiErrAlertAlertTitle',
    body: 'Event_smaashiErrAlertAlertBody',
  },
  location: 'Event_smaashiErrAlertLocation',
  remoteControl: 'Event_smaashiErrAlertRemoteControl',

  image: {
    normal: Icons.ic_smartassist_normal,
    active: Icons.ic_smartassist_active,
    white: Icons.ic_smartassist_white,
  },
});

unsafeMessageIdMap.set('Event_lowBeamLevelErrAlert', {
  prefixId: 'Event_lowBeamLevelErrAlert',

  eventName: 'Event_lowBeamLevelErrAlertEventName',

  warning: {
    title: 'Event_lowBeamLevelErrAlertWarningTitle',
    hint: 'Event_lowBeamLevelErrAlertWarningHint',
    order: 22,
  },
  notification: {
    title: 'Event_lowBeamLevelErrAlertNotificationTitle',
    body: 'Event_lowBeamLevelErrAlertNotificationBody',
  },
  home: 'Event_lowBeamLevelErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_lowBeamLevelErrAlertDialogTitle',
    body: 'Event_lowBeamLevelErrAlertDialogBody',
    action: 'Event_lowBeamLevelErrAlertDialogAction',
    action2: 'Event_lowBeamLevelErrAlertDialogAction2',
    close: 'Event_lowBeamLevelErrAlertDialogClose',
  },
  alert: {
    title: 'Event_lowBeamLevelErrAlertAlertTitle',
    body: 'Event_lowBeamLevelErrAlertAlertBody',
  },
  location: 'Event_lowBeamLevelErrAlertLocation',
  remoteControl: 'Event_lowBeamLevelErrAlertRemoteControl',

  image: {
    normal: Icons.ic_headlamp_normal,
    active: Icons.ic_headlamp_active,
    white: Icons.ic_headlamp_white,
  },
});

unsafeMessageIdMap.set('Event_headLampSysAlert', {
  prefixId: 'Event_headLampSysAlert',

  eventName: 'Event_headLampSysAlertEventName',

  warning: {
    title: 'Event_headLampSysAlertWarningTitle',
    hint: 'Event_headLampSysAlertWarningHint',
    order: 25,
  },
  notification: {
    title: 'Event_headLampSysAlertNotificationTitle',
    body: 'Event_headLampSysAlertNotificationBody',
  },
  home: 'Event_headLampSysAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_headLampSysAlertDialogTitle',
    body: 'Event_headLampSysAlertDialogBody',
    action: 'Event_headLampSysAlertDialogAction',
    action2: 'Event_headLampSysAlertDialogAction2',
    close: 'Event_headLampSysAlertDialogClose',
  },
  alert: {
    title: 'Event_headLampSysAlertAlertTitle',
    body: 'Event_headLampSysAlertAlertBody',
  },
  location: 'Event_headLampSysAlertLocation',
  remoteControl: 'Event_headLampSysAlertRemoteControl',

  image: {
    normal: Icons.ic_headlampsys_normal,
    active: Icons.ic_headlampsys_active,
    white: Icons.ic_headlampsys_white,
  },
});

unsafeMessageIdMap.set('Event_bosErrAlert', {
  prefixId: 'Event_bosErrAlert',

  eventName: 'Event_bosErrAlertEventName',

  warning: {
    title: 'Event_bosErrAlertWarningTitle',
    hint: 'Event_bosErrAlertWarningHint',
    order: 8,
  },
  notification: {
    title: 'Event_bosErrAlertNotificationTitle',
    body: 'Event_bosErrAlertNotificationBody',
  },
  home: 'Event_bosErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_bosErrAlertDialogTitle',
    body: 'Event_bosErrAlertDialogBody',
    action: 'Event_bosErrAlertDialogAction',
    action2: 'Event_bosErrAlertDialogAction2',
    close: 'Event_bosErrAlertDialogClose',
  },
  alert: {
    title: 'Event_bosErrAlertAlertTitle',
    body: 'Event_bosErrAlertAlertBody',
  },
  location: 'Event_bosErrAlertLocation',
  remoteControl: 'Event_bosErrAlertRemoteControl',

  image: {
    normal: Icons.ic_bos_normal,
    active: Icons.ic_bos_active,
    white: Icons.ic_bos_white,
  },
});

unsafeMessageIdMap.set('Event_dscErrAlert', {
  prefixId: 'Event_dscErrAlert',

  eventName: 'Event_dscErrAlertEventName',

  warning: {
    title: 'Event_dscErrAlertWarningTitle',
    hint: 'Event_dscErrAlertWarningHint',
    order: 7,
  },
  notification: {
    title: 'Event_dscErrAlertNotificationTitle',
    body: 'Event_dscErrAlertNotificationBody',
  },
  home: 'Event_dscErrAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_dscErrAlertDialogTitle',
    body: 'Event_dscErrAlertDialogBody',
    action: 'Event_dscErrAlertDialogAction',
    action2: 'Event_dscErrAlertDialogAction2',
    close: 'Event_dscErrAlertDialogClose',
  },
  alert: {
    title: 'Event_dscErrAlertAlertTitle',
    body: 'Event_dscErrAlertAlertBody',
  },
  location: 'Event_dscErrAlertLocation',
  remoteControl: 'Event_dscErrAlertRemoteControl',

  image: {
    normal: Icons.ic_dsc_normal,
    active: Icons.ic_dsc_active,
    white: Icons.ic_dsc_white,
  },
});

unsafeMessageIdMap.set('Event_vscTscAlert', {
  prefixId: 'Event_vscTscAlert',

  eventName: 'Event_vscTscAlertEventName',

  warning: {
    title: 'Event_vscTscAlertWarningTitle',
    hint: 'Event_vscTscAlertWarningHint',
    order: 13,
  },
  notification: {
    title: 'Event_vscTscAlertNotificationTitle',
    body: 'Event_vscTscAlertNotificationBody',
  },
  home: 'Event_vscTscAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_vscTscAlertDialogTitle',
    body: 'Event_vscTscAlertDialogBody',
    action: 'Event_vscTscAlertDialogAction',
    action2: 'Event_vscTscAlertDialogAction2',
    close: 'Event_vscTscAlertDialogClose',
  },
  alert: {
    title: 'Event_vscTscAlertAlertTitle',
    body: 'Event_vscTscAlertAlertBody',
  },
  location: 'Event_vscTscAlertLocation',
  remoteControl: 'Event_vscTscAlertRemoteControl',

  image: {
    normal: Icons.ic_vsc_normal,
    active: Icons.ic_vsc_active,
    white: Icons.ic_vsc_white,
  },
});

unsafeMessageIdMap.set('Event_absAlert', {
  prefixId: 'Event_absAlert',

  eventName: 'Event_absAlertEventName',

  warning: {
    title: 'Event_absAlertWarningTitle',
    hint: 'Event_absAlertWarningHint',
    order: 12,
  },
  notification: {
    title: 'Event_absAlertNotificationTitle',
    body: 'Event_absAlertNotificationBody',
  },
  home: 'Event_absAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_absAlertDialogTitle',
    body: 'Event_absAlertDialogBody',
    action: 'Event_absAlertDialogAction',
    action2: 'Event_absAlertDialogAction2',
    close: 'Event_absAlertDialogClose',
  },
  alert: {
    title: 'Event_absAlertAlertTitle',
    body: 'Event_absAlertAlertBody',
  },
  location: 'Event_absAlertLocation',
  remoteControl: 'Event_absAlertRemoteControl',

  image: {
    normal: Icons.ic_abs_normal,
    active: Icons.ic_abs_active,
    white: Icons.ic_abs_white,
  },
});

unsafeMessageIdMap.set('Event_parkBrakeError', {
  prefixId: 'Event_parkBrakeError',

  eventName: 'Event_parkBrakeErrorEventName',

  warning: {
    title: 'Event_parkBrakeErrorWarningTitle',
    hint: 'Event_parkBrakeErrorWarningHint',
    order: 17,
  },
  notification: {
    title: 'Event_parkBrakeErrorNotificationTitle',
    body: 'Event_parkBrakeErrorNotificationBody',
  },
  home: 'Event_parkBrakeErrorHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_parkBrakeErrorDialogTitle',
    body: 'Event_parkBrakeErrorDialogBody',
    action: 'Event_parkBrakeErrorDialogAction',
    action2: 'Event_parkBrakeErrorDialogAction2',
    close: 'Event_parkBrakeErrorDialogClose',
  },
  alert: {
    title: 'Event_parkBrakeErrorAlertTitle',
    body: 'Event_parkBrakeErrorAlertBody',
  },
  location: 'Event_parkBrakeErrorLocation',
  remoteControl: 'Event_parkBrakeErrorRemoteControl',

  image: {
    normal: Icons.ic_parkingbrake_normal,
    active: Icons.ic_parkingbrake_active,
    white: Icons.ic_parkingbrake_white,
  },
});

unsafeMessageIdMap.set('Event_cvtAlert', {
  prefixId: 'Event_cvtAlert',

  eventName: 'Event_cvtAlertEventName',

  warning: {
    title: 'Event_cvtAlertWarningTitle',
    hint: 'Event_cvtAlertWarningHint',
    order: 5,
  },
  notification: {
    title: 'Event_cvtAlertNotificationTitle',
    body: 'Event_cvtAlertNotificationBody',
  },
  home: 'Event_cvtAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_cvtAlertDialogTitle',
    body: 'Event_cvtAlertDialogBody',
    action: 'Event_cvtAlertDialogAction',
    action2: 'Event_cvtAlertDialogAction2',
    close: 'Event_cvtAlertDialogClose',
  },
  alert: {
    title: 'Event_cvtAlertAlertTitle',
    body: 'Event_cvtAlertAlertBody',
  },
  location: 'Event_cvtAlertLocation',
  remoteControl: 'Event_cvtAlertRemoteControl',

  image: {
    normal: Icons.ic_cvt_normal,
    active: Icons.ic_cvt_active,
    white: Icons.ic_cvt_white,
  },
});

unsafeMessageIdMap.set('Event_Engine1Alert', {
  prefixId: 'Event_Engine1Alert',

  eventName: 'Event_Engine1AlertEventName',

  warning: {
    title: 'Event_Engine1AlertWarningTitle',
    hint: 'Event_Engine1AlertWarningHint',
    order: 1,
  },
  notification: {
    title: 'Event_Engine1AlertNotificationTitle',
    body: 'Event_Engine1AlertNotificationBody',
  },
  home: 'Event_Engine1AlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_Engine1AlertDialogTitle',
    body: 'Event_Engine1AlertDialogBody',
    action: 'Event_Engine1AlertDialogAction',
    action2: 'Event_Engine1AlertDialogAction2',
    close: 'Event_Engine1AlertDialogClose',
  },
  alert: {
    title: 'Event_Engine1AlertAlertTitle',
    body: 'Event_Engine1AlertAlertBody',
  },
  location: 'Event_Engine1AlertLocation',
  remoteControl: 'Event_Engine1AlertRemoteControl',

  image: {
    normal: Icons.ic_engine_normal,
    active: Icons.ic_engine_active,
    white: Icons.ic_engine_white,
  },
});

unsafeMessageIdMap.set('Event_srsAirbagAlert', {
  prefixId: 'Event_srsAirbagAlert',

  eventName: 'Event_srsAirbagAlertEventName',

  warning: {
    title: 'Event_srsAirbagAlertWarningTitle',
    hint: 'Event_srsAirbagAlertWarningHint',
    order: 14,
  },
  notification: {
    title: 'Event_srsAirbagAlertNotificationTitle',
    body: 'Event_srsAirbagAlertNotificationBody',
  },
  home: 'Event_srsAirbagAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_srsAirbagAlertDialogTitle',
    body: 'Event_srsAirbagAlertDialogBody',
    action: 'Event_srsAirbagAlertDialogAction',
    action2: 'Event_srsAirbagAlertDialogAction2',
    close: 'Event_srsAirbagAlertDialogClose',
  },
  alert: {
    title: 'Event_srsAirbagAlertAlertTitle',
    body: 'Event_srsAirbagAlertAlertBody',
  },
  location: 'Event_srsAirbagAlertLocation',
  remoteControl: 'Event_srsAirbagAlertRemoteControl',

  image: {
    normal: Icons.ic_srs_normal,
    active: Icons.ic_srs_active,
    white: Icons.ic_srs_white,
  },
});

unsafeMessageIdMap.set('Event_elePowSteRedAlert', {
  prefixId: 'Event_elePowSteRedAlert',

  eventName: 'Event_elePowSteRedAlertEventName',

  warning: {
    title: 'Event_elePowSteRedAlertWarningTitle',
    hint: 'Event_elePowSteRedAlertWarningHint',
    order: 15,
  },
  notification: {
    title: 'Event_elePowSteRedAlertNotificationTitle',
    body: 'Event_elePowSteRedAlertNotificationBody',
  },
  home: 'Event_elePowSteRedAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_elePowSteRedAlertDialogTitle',
    body: 'Event_elePowSteRedAlertDialogBody',
    action: 'Event_elePowSteRedAlertDialogAction',
    action2: 'Event_elePowSteRedAlertDialogAction2',
    close: 'Event_elePowSteRedAlertDialogClose',
  },
  alert: {
    title: 'Event_elePowSteRedAlertAlertTitle',
    body: 'Event_elePowSteRedAlertAlertBody',
  },
  location: 'Event_elePowSteRedAlertLocation',
  remoteControl: 'Event_elePowSteRedAlertRemoteControl',

  image: {
    normal: Icons.ic_powersteering_normal,
    active: Icons.ic_powersteering_active,
    white: Icons.ic_powersteering_white,
  },
});

unsafeMessageIdMap.set('Event_batteryAlert', {
  prefixId: 'Event_batteryAlert',

  eventName: 'Event_batteryAlertEventName',

  warning: {
    title: 'Event_batteryAlertWarningTitle',
    hint: 'Event_batteryAlertWarningHint',
    order: 3,
  },
  notification: {
    title: 'Event_batteryAlertNotificationTitle',
    body: 'Event_batteryAlertNotificationBody',
  },
  home: 'Event_batteryAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_batteryAlertDialogTitle',
    body: 'Event_batteryAlertDialogBody',
    action: 'Event_batteryAlertDialogAction',
    action2: 'Event_batteryAlertDialogAction2',
    close: 'Event_batteryAlertDialogClose',
  },
  alert: {
    title: 'Event_batteryAlertAlertTitle',
    body: 'Event_batteryAlertAlertBody',
  },
  location: 'Event_batteryAlertLocation',
  remoteControl: 'Event_batteryAlertRemoteControl',

  image: {
    normal: Icons.ic_charging_normal,
    active: Icons.ic_charging_active,
    white: Icons.ic_charging_white,
  },
});

unsafeMessageIdMap.set('Event_waterTempAlert', {
  prefixId: 'Event_waterTempAlert',

  eventName: 'Event_waterTempAlertEventName',

  warning: {
    title: 'Event_waterTempAlertWarningTitle',
    hint: 'Event_waterTempAlertWarningHint',
    order: 4,
  },
  notification: {
    title: 'Event_waterTempAlertNotificationTitle',
    body: 'Event_waterTempAlertNotificationBody',
  },
  home: 'Event_waterTempAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_waterTempAlertDialogTitle',
    body: 'Event_waterTempAlertDialogBody',
    action: 'Event_waterTempAlertDialogAction',
    action2: 'Event_waterTempAlertDialogAction2',
    close: 'Event_waterTempAlertDialogClose',
  },
  alert: {
    title: 'Event_waterTempAlertAlertTitle',
    body: 'Event_waterTempAlertAlertBody',
  },
  location: 'Event_waterTempAlertLocation',
  remoteControl: 'Event_waterTempAlertRemoteControl',

  image: {
    normal: Icons.ic_coolant_normal,
    active: Icons.ic_coolant_active,
    white: Icons.ic_coolant_white,
  },
});

unsafeMessageIdMap.set('Event_oilAlert', {
  prefixId: 'Event_oilAlert',

  eventName: 'Event_oilAlertEventName',

  warning: {
    title: 'Event_oilAlertWarningTitle',
    hint: 'Event_oilAlertWarningHint',
    order: 2,
  },
  notification: {
    title: 'Event_oilAlertNotificationTitle',
    body: 'Event_oilAlertNotificationBody',
  },
  home: 'Event_oilAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_oilAlertDialogTitle',
    body: 'Event_oilAlertDialogBody',
    action: 'Event_oilAlertDialogAction',
    action2: 'Event_oilAlertDialogAction2',
    close: 'Event_oilAlertDialogClose',
  },
  alert: {
    title: 'Event_oilAlertAlertTitle',
    body: 'Event_oilAlertAlertBody',
  },
  location: 'Event_oilAlertLocation',
  remoteControl: 'Event_oilAlertRemoteControl',

  image: {
    normal: Icons.ic_oil_normal,
    active: Icons.ic_oil_active,
    white: Icons.ic_oil_white,
  },
});

unsafeMessageIdMap.set('Event_brakeSysAlert', {
  prefixId: 'Event_brakeSysAlert',

  eventName: 'Event_brakeSysAlertEventName',

  warning: {
    title: 'Event_brakeSysAlertWarningTitle',
    hint: 'Event_brakeSysAlertWarningHint',
    order: 10,
  },
  notification: {
    title: 'Event_brakeSysAlertNotificationTitle',
    body: 'Event_brakeSysAlertNotificationBody',
  },
  home: 'Event_brakeSysAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_brakeSysAlertDialogTitle',
    body: 'Event_brakeSysAlertDialogBody',
    action: 'Event_brakeSysAlertDialogAction',
    action2: 'Event_brakeSysAlertDialogAction2',
    close: 'Event_brakeSysAlertDialogClose',
  },
  alert: {
    title: 'Event_brakeSysAlertAlertTitle',
    body: 'Event_brakeSysAlertAlertBody',
  },
  location: 'Event_brakeSysAlertLocation',
  remoteControl: 'Event_brakeSysAlertRemoteControl',

  image: {
    normal: Icons.ic_brakesystem_normal,
    active: Icons.ic_brakesystem_active,
    white: Icons.ic_brakesystem_white,
  },
});

unsafeMessageIdMap.set('Event_brakeFluAlert', {
  prefixId: 'Event_brakeFluAlert',

  eventName: 'Event_brakeFluAlertEventName',

  warning: {
    title: 'Event_brakeFluAlertWarningTitle',
    hint: 'Event_brakeFluAlertWarningHint',
    order: 11,
  },
  notification: {
    title: 'Event_brakeFluAlertNotificationTitle',
    body: 'Event_brakeFluAlertNotificationBody',
  },
  home: 'Event_brakeFluAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_brakeFluAlertDialogTitle',
    body: 'Event_brakeFluAlertDialogBody',
    action: 'Event_brakeFluAlertDialogAction',
    action2: 'Event_brakeFluAlertDialogAction2',
    close: 'Event_brakeFluAlertDialogClose',
  },
  alert: {
    title: 'Event_brakeFluAlertAlertTitle',
    body: 'Event_brakeFluAlertAlertBody',
  },
  location: 'Event_brakeFluAlertLocation',
  remoteControl: 'Event_brakeFluAlertRemoteControl',

  image: {
    normal: Icons.ic_brakefluid_normal,
    active: Icons.ic_brakefluid_active,
    white: Icons.ic_brakefluid_white,
  },
});

unsafeMessageIdMap.set('Event_epbAlert', {
  prefixId: 'Event_epbAlert',

  eventName: 'Event_epbAlertEventName',

  warning: {
    title: 'Event_epbAlertWarningTitle',
    hint: 'Event_epbAlertWarningHint',
    order: 26,
  },
  notification: {
    title: 'Event_epbAlertNotificationTitle',
    body: 'Event_epbAlertNotificationBody',
  },
  home: 'Event_epbAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_epbAlertDialogTitle',
    body: 'Event_epbAlertDialogBody',
    action: 'Event_epbAlertDialogAction',
    action2: 'Event_epbAlertDialogAction2',
    close: 'Event_epbAlertDialogClose',
  },
  alert: {
    title: 'Event_epbAlertAlertTitle',
    body: 'Event_epbAlertAlertBody',
  },
  location: 'Event_epbAlertLocation',
  remoteControl: 'Event_epbAlertRemoteControl',

  image: {
    normal: Icons.ic_epb_normal,
    active: Icons.ic_epb_active,
    white: Icons.ic_epb_white,
  },
});

unsafeMessageIdMap.set('Event_airbagAlert', {
  prefixId: 'Event_airbagAlert',

  eventName: 'Event_airbagAlertEventName',

  warning: {
    title: 'Event_airbagAlertWarningTitle',
    hint: 'Event_airbagAlertWarningHint',
    order: undefined,
  },
  notification: {
    title: 'Event_airbagAlertNotificationTitle',
    body: 'Event_airbagAlertNotificationBody',
  },
  home: 'Event_airbagAlertHome',
  dialog: {
    type: 'Confirm',
    title: 'Event_airbagAlertDialogTitle',
    body: 'Event_airbagAlertDialogBody',
    action: 'Event_airbagAlertDialogAction',
    action2: 'Event_airbagAlertDialogAction2',
    close: 'Event_airbagAlertDialogClose',
  },
  alert: {
    title: 'Event_airbagAlertAlertTitle',
    body: 'Event_airbagAlertAlertBody',
  },
  location: 'Event_airbagAlertLocation',
  remoteControl: 'Event_airbagAlertRemoteControl',

  image: {
    normal: Icons.ic_srs_normal,
    active: Icons.ic_srs_active,
    white: Icons.ic_srs_white,
  },
});

export const MessageIdMap: MessageIdMap = {
  get: (key: PrefixId) => unsafeMessageIdMap.get(key) as MessageIdMapValue,

  keys: [
    'Event_completeCarRegistration',
    'Event_waitingForCarRegistration',
    'Event_addSharedUser',
    'Event_sosCallManual',
    'Event_sosCallAuto',
    'Event_lowDriveBatteryAlert3',
    'Event_lowDriveBatteryAlert2',
    'Event_lowDriveBatteryAlert1',
    'Event_backSensorAlert',
    'Event_cornerSensorFrontAlert',
    'Event_cornerSensorRearAlert',
    'Event_cornerSensorAllAlert',
    'Event_acousticVehicleSystemAlert',
    'Event_bsmAlert',
    'Event_lkcSystemAlert',
    'Event_accBreakAlert',
    'Event_rearDiffLockAlert',
    'Event_dummyAirbagAlert',
    'Event_remoteCmdFailedConditionNotSatisfied',
    'Event_remoteCmdAccepted',
    'Event_commonNetworkError',
    'Event_getGpsError',
    'Event_remoteCmdCenterFail',
    'Event_remoteCmdDuplicated',
    'Event_operationCampaignAlert',
    'Event_operationAppAlert',
    'Event_operationMaintenaceAlert',
    'Event_ownerShipUserRemoveAllCar',
    'Event_ownerShipAdminRemoveCar',
    'Event_ownerShipUserRemoveCar',
    'Event_subscriptionWifiServiceExpired',
    'Event_subscriptionWifiContractDone',
    'Event_subscriptionWifiAlert',
    'Event_subscriptionWifiPreAlert',
    'Event_subscriptionDCMServiceExpired',
    'Event_subscriptionDCMContractDone',
    'Event_subsctiptionDCMAlert',
    'Event_subscriptionDCMPreAlert',
    'Event_ownerShipUserActivate',
    'Event_ownerShipAdminNotify',
    'Event_ownerShipInvitation',
    'Event_ownerShipChangeContents',
    'Event_serviceReminderAlertTire',
    'Event_serviceReminderAlertBrakeRr',
    'Event_serviceReminderAlertBrakeFr',
    'Event_serviceReminderAlertBrakeOil',
    'Event_serviceReminderAlertBattery',
    'Event_serviceReminderAlertEngOil',
    'Event_serviceReminderAlert',
    'Event_engineProhibit',
    'Event_trackingOn',
    'Event_ukkariDoor',
    'Event_ukkariLight',
    'Event_ukkariEngProhibit',
    'Event_ukkariHazard',
    'Event_ukkariAircon',
    'Event_ukkariEngine',
    'Event_ukkari',
    'Event_remoteEngineProhibitOffFail',
    'Event_remoteEngineProhibitOffSuccess',
    'Event_remoteEngineProhibitOnFail',
    'Event_remoteEngineProhibitOnSuccess',
    'Event_remoteWifiResetFail',
    'Event_remoteWifiResetSuccess',
    'Event_remoteWifiOffFail',
    'Event_remoteWifiOffSuccess',
    'Event_remoteWifiOnFail',
    'Event_remoteWifiOnSuccess',
    'Event_remoteGeofenceOffFail',
    'Event_remoteGeofenceOffSuccess',
    'Event_remoteGeofenceChangeFail',
    'Event_remoteGeofenceChangeSuccess',
    'Event_remoteGeofenceOnFail',
    'Event_remoteGeofenceOnSuccess',
    'Event_remoteTrackingOffFail',
    'Event_remoteTrackingOffSuccess',
    'Event_remoteTrackingOnFail',
    'Event_remoteTrackingOnSuccess',
    'Event_remoteEngineOffFail',
    'Event_remoteEngineOffSuccess',
    'Event_remoteEngineOnFail',
    'Event_remoteEngineOnSuccess',
    'Event_remoteACOffFail',
    'Event_remoteACOffSuccess',
    'Event_remoteACOnFail',
    'Event_remoteACOnSuccess',
    'Event_remoteHazardOffFail',
    'Event_remoteHazardOffSuccess',
    'Event_remoteHazardOnFail',
    'Event_remoteHazardOnSuccess',
    'Event_remoteHeadLightOffFail',
    'Event_remoteHeadLightOffSuccess',
    'Event_remoteHeadLightOnFail',
    'Event_remoteHeadLightOnSuccess',
    'Event_remoteDoorLockedFail',
    'Event_remoteDoorLockedSuccess',
    'Event_carResgitrationFail',
    'Event_carResgitrationSuccess',
    'Event_keyFreeAlert4',
    'Event_towAwayAlert',
    'Event_geofenceInAlert',
    'Event_geofenceOutAlert',
    'Event_removeDcmAlert',
    'Event_alarmAlert',
    'Event_systemIGOffAlert',
    'Event_systemIGOnAlert',
    'Event_userIGOffAlert',
    'Event_userIGOnAlert',
    'Event_elePowSteOreAlert',
    'Event_afsWarnAlret',
    'Event_fourWheelSysAlert',
    'Event_autoHighBeamAlert',
    'Event_keyFreeAlert',
    'Event_ecoIdelOffAlert',
    'Event_brakeHoldAlert',
    'Event_smaashiErrAlert',
    'Event_lowBeamLevelErrAlert',
    'Event_headLampSysAlert',
    'Event_bosErrAlert',
    'Event_dscErrAlert',
    'Event_vscTscAlert',
    'Event_absAlert',
    'Event_parkBrakeError',
    'Event_cvtAlert',
    'Event_Engine1Alert',
    'Event_srsAirbagAlert',
    'Event_elePowSteRedAlert',
    'Event_batteryAlert',
    'Event_waterTempAlert',
    'Event_oilAlert',
    'Event_brakeSysAlert',
    'Event_brakeFluAlert',
    'Event_epbAlert',
    'Event_airbagAlert',
  ],
};
