import * as Client from '@auto/monaka-client/dist/20';
import { getDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { Distance } from '../util/Distance';
import { getErrorMessage } from '../util/Error';
import { Option } from '../util/Option';
import { sleep } from '../util/Sleep';
import { Unit } from '../util/Unit';
import { AppSyncClient } from './Client';
import { println } from './NativeApi';
import { sendRemoteCommand } from './RemoteCommand';

export interface GeoPos {
  readonly lat: number;
  readonly lng: number;
}

export interface Geofence {
  readonly center: GeoPos;
  readonly radius: Distance;
}

export type GeofenceJson = {
  readonly center: GeoPos;
  readonly radius: number; // meter
};

type ClientGeofence = Exclude<Client.Vehicle['geofence'], null>;

export function toGeofenceJson(a: Geofence): GeofenceJson {
  return {
    center: a.center,
    radius: a.radius.toMeters(),
  };
}

export function fromGeofenceJson(a: GeofenceJson): Geofence {
  return {
    center: a.center,
    radius: Distance.meters(a.radius),
  };
}

export function fromClientGeofence(a: ClientGeofence): Geofence {
  return {
    center: {
      lat: a.baseLatitude,
      lng: a.baseLongitude,
    },
    radius: Distance.meters(a.radius),
  };
}

export function toClientGeofence(a: Geofence): ClientGeofence {
  return {
    baseLatitude: a.center.lat,
    baseLongitude: a.center.lng,
    radius: a.radius.toMeters(),
  };
}

export async function updateGeofence(
  vin: string,
  dcmId: string,
  geofence: Geofence,
): Promise<Unit> {
  return sendRemoteCommand({
    vin,
    dcmId,
    name: 'geofence',
    type: 'dsc',
    action: 'update',
    params: geofence,
  }).then((res) => {
    if (res.result === 'success') return Unit;
    else return Promise.reject(Error('Failed to update geofence'));
  });
}

export async function deleteGeofence(
  vin: string,
  dcmId: string,
): Promise<Unit> {
  return sendRemoteCommand({
    vin,
    dcmId,
    name: 'geofence',
    type: 'dsc',
    action: 'delete',
    params: {},
  }).then((res) => {
    if (res.result === 'success') return Unit;
    else return Promise.reject(Error('Failed to delete geofence'));
  });
}

export async function getGeofence(vin: string): Promise<Option<Geofence>> {
  println(`Getting a geofence for ${vin}`);
  if (BuildOpt.isMock()) {
    await sleep(500);
    const db = await getDB();
    return Option(db.vehicles.find((_) => _.vin === vin))
      .unwrap(
        (v) => Promise.resolve(v.geofence),
        () => Promise.reject(Error(`No vehicle for ${vin}`)),
      )
      .then((_) => {
        println(`Got the geofence for ${vin}`);
        return _;
      });
  } else {
    const gSysUserId = Option(await Client.Auth.currentDriver())
      .map((_) => _.gSysUserId)
      .getOrElse(() => 'unknown');
    return AppSyncClient.getGeofence(gSysUserId, vin)
      .then((a) => Option(a).map(fromClientGeofence))
      .catch((err) =>
        Promise.reject(
          Error(`Error occurred on getGeofence. ${getErrorMessage(err)}`),
        ),
      )
      .then((_) => {
        println(`Got the geofence for ${vin}`);
        return _;
      });
  }
}
