import QRCode from 'qrcode.react';
import React, { Suspense, useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import {
  InvitationResponse,
  getSharedUserInvitationCode,
} from '../../apis/SharedUser';
import { UserType } from '../../apis/VehicleList';
import { ErrorBoundary } from '../components/ErrorBoundary';
import {
  SubWindow,
  SubWindowProps,
  WindowDispatch,
  WindowStackController,
} from '../components/SlideInWindowStack';
import { HomeData } from '../home/Home';
import { Intl, useIntl } from '../i18n/Intl';
import { ContextDispatch, useContextStore } from '../states/ContextStore';
import { Store } from '../states/Store';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { create } from '../style/Style';
import { Display } from './Display';
import { Loading } from './Loading';
import { getSharedUserInvitationCodeKey, getUserTypeTextId } from './Utils';

type Data = HomeData;

interface Props {
  readonly props: SubWindowProps;
  readonly controller: WindowStackController;
  readonly vin: string;
  readonly userType: UserType;
}

const styles = create({
  container: {
    width: Percentage(100),
  },

  descriptionRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: Percentage(100),
    marginTop: Length.px(20),
    paddingLeft: Length.px(16),
    paddingRight: Length.px(16),
  },
  descriptionText: {
    fontSize: Length.px(16),
  },
  qrcodeRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: Length.px(288),
    marginTop: Length.px(12),
  },
  qrcodeImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Length.px(240),
    width: Length.px(240),
    padding: Length.px(24),
  },
  qrcodeDescriptionRow: {
    display: 'flex',
  },
  qrcodeDescriptionTitle: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: Length.px(8),
    fontSize: Length.px(16),
    fontWeight: 'bold',
  },
  qrcodeDescriptionText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: Length.px(16),
  },
  adjustTimeText: {
    lineHeight: 1.5,
  },
  actionButtonRow: {
    height: Length.px(48),
    width: Percentage(100),
    marginTop: Length.px(40),
    paddingLeft: Length.px(16),
    paddingRight: Length.px(16),
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Percentage(100),
    width: Percentage(100),
    backgroundColor: Colors.primary,
    borderRadius: Length.px(24),
  },
  actionButtonText: {
    color: Colors.buttonText,
    fontSize: Length.px(17),
    fontWeight: 'bold',
    lineHeight: 1,
  },
});

interface InviteCodeDisplayBodyProps {
  readonly store: Store<Data>;
  readonly vin: string;
  readonly userType: UserType;
  readonly onDone: (_: ContextDispatch) => ContextDispatch;
}

const InviteCodeDisplayBody: React.FC<InviteCodeDisplayBodyProps> = (props) => {
  const {
    views: { View, Text },
    dispatch,
  } = useContextStore();

  return (
    <View style={styles.container}>
      <View style={styles.descriptionRow}>
        <Text style={styles.descriptionText}>
          <Display id={'myCarManagement_screen2Message1'} />
        </Text>
      </View>

      <View style={styles.qrcodeRow}>
        <ErrorBoundary
          cacheKey={getSharedUserInvitationCodeKey(props.vin, props.userType)}
          fallback={<Loading />}
        >
          <Suspense fallback={<Loading />}>
            <ShowInviteCode {...props} />
          </Suspense>
        </ErrorBoundary>
      </View>

      <View style={styles.actionButtonRow}>
        <View
          style={styles.actionButton}
          onClick={() => props.onDone(dispatch)}
        >
          <Text style={styles.actionButtonText}>
            <Display id='myCarManagement_screen2Done' />
          </Text>
        </View>
      </View>
    </View>
  );
};

interface ShowInviteCodeProps {
  readonly vin: string;
  readonly userType: UserType;
}

function formatDateTime(intl: Intl, data: InvitationResponse) {
  const date = new Date(data.invitationExpireTime * 1000);
  return intl.formatDateTime(date);
}

const ShowInviteCode: React.FC<ShowInviteCodeProps> = (props) => {
  const {
    views: { View, Text, Image },
  } = useContextStore();

  const intl = useIntl();
  const { data } = useSWR(
    getSharedUserInvitationCodeKey(props.vin, props.userType),
    () => getSharedUserInvitationCode(props.vin, props.userType),
    { suspense: true },
  );

  return (
    <>
      <View style={styles.qrcodeImage}>
        {data == null ? (
          <Image />
        ) : (
          <QRCode value={JSON.stringify(data)} size={188} />
        )}
      </View>
      <View style={styles.qrcodeDescriptionRow}>
        <View style={styles.qrcodeDescriptionTitle}>
          <Text>
            <Display id={'myCarManagement_screen2Message2'} />
          </Text>
          <Text>
            <Display id={'myCarManagement_screen2Message3'} />
          </Text>
        </View>
        <View style={styles.qrcodeDescriptionText}>
          <Text>
            <Display id={getUserTypeTextId(props.userType)} />
          </Text>
          <Text style={styles.adjustTimeText}>
            {data == null ? '--' : formatDateTime(intl, data)}
          </Text>
        </View>
      </View>
    </>
  );
};

export const InviteCodeDisplay: React.FC<Props> = (props) => {
  const intl = useIntl();

  const { mutate } = useSWRConfig();
  const revalidateInvitationCode = useCallback(() => {
    mutate(getSharedUserInvitationCodeKey(props.vin, props.userType));
  }, [mutate, props.userType, props.vin]);

  const onClosed = useCallback(
    (dispatch: WindowDispatch) => {
      revalidateInvitationCode();
      return dispatch;
    },
    [revalidateInvitationCode],
  );

  const onDone = useCallback(
    (dispatch) => {
      props.controller.closeWindowStack();
      return dispatch;
    },
    [props.controller],
  );

  return (
    <SubWindow
      {...props.props}
      title={intl.formatMessage({ id: 'myCarManagement_pageTitleAddUser' })}
      onClosed={onClosed}
    >
      <InviteCodeDisplayBody
        store={props.props.parent.home}
        vin={props.vin}
        userType={props.userType}
        onDone={onDone}
      />
    </SubWindow>
  );
};
