export interface TimingFunction {
  readonly toStyle: () => string;
}

interface Statics {
  readonly linear: TimingFunction;
  readonly ease: TimingFunction;
  readonly easeIn: TimingFunction;
  readonly easeInOut: TimingFunction;
  readonly easeOut: TimingFunction;
  readonly cubicBezier: (
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ) => TimingFunction;
  readonly steps: (
    numberOfSteps: number,
    direction:
      | 'jump-start'
      | 'jump-end'
      | 'jump-both'
      | 'jump-none'
      | 'start'
      | 'end',
  ) => TimingFunction;
}

function keyword(name: string): TimingFunction {
  return {
    toStyle: () => name,
  };
}

const statics: Statics = {
  linear: keyword('linear'),
  ease: keyword('ease'),
  easeIn: keyword('ease-in'),
  easeInOut: keyword('ease-in-out'),
  easeOut: keyword('ease-out'),

  cubicBezier: (x1: number, y1: number, x2: number, y2: number) => {
    const text = `cubic-bezier(${x1}, ${y1}, ${x2}, ${y2})`;
    return {
      toStyle: () => text,
    };
  },

  steps: (
    numberOfSteps: number,
    direction:
      | 'jump-start'
      | 'jump-end'
      | 'jump-both'
      | 'jump-none'
      | 'start'
      | 'end',
  ) => {
    const text = `steps(${numberOfSteps}, ${direction})`;
    return {
      toStyle: () => text,
    };
  },
};

export const TimingFunction: Statics = statics;
