import React from 'react';
import { VehicleEvent } from '../../apis/EventTelemetry';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { None, Option, Some } from '../../util/Option';
import { CardHeader } from '../components/CardHeader';
import { HelpDialog } from '../components/HelpDialog';
import { PopupButton } from '../components/PopupButton';
import { HomeData } from '../home/Home';
import { EventIdMap } from '../i18n/EventIdMap';
import { Message, useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { Action } from '../states/Reducer';
import { Store } from '../states/Store';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { GeofenceSettingPane } from './GeofenceSettingPane';
import { getTrackingMode } from './Helpers';
import { menuPaneHeight } from './MenuPane';
import { VehicleTrackerPane } from './TrackingPane';
import { VehicleLocationState, defaultRadius } from './VehicleLocationState';

type Parent = HomeData;

type Props = Readonly<{
  parent: Store<Parent>;
}>;

export function FluidPanes({ parent }: Props): React.ReactElement {
  const {
    data: { context },
    dispatch,
  } = useParentStore({
    parent: {
      home: parent,
    },
  });
  const intl = useIntl();
  const localState = parent.data.localState.geofence.localState;
  const actions = VehicleLocationState.actions;
  const viewPort = context.viewPort;

  const radius = getSelectedVehicle(context)
    .flatMap((_) => _.geofence)
    .map((geofence) => geofence.radius);

  const trackingMode = getTrackingMode(
    context.serviceTelemetry.data.toOption().some((_) => _.en_theft_trk),
    context.selectedVehicleVin.some((vin) =>
      Option(context.ongoingRemoteStates[vin]).some((_) => _.trackingMode),
    ),
  );

  const geofenceAlert = context.vehicleEvents
    .reduce((r, e) => {
      return r.unwrap(
        (a) => {
          if (
            (e.eventId === '1021' || e.eventId === '1022') &&
            e.timestamp > a.timestamp
          )
            return Some(e);
          else return Some(a);
        },
        () => {
          if (e.eventId === '1021' || e.eventId === '1022') return Some(e);
          else return None();
        },
      );
    }, None<VehicleEvent>())
    .flatMap((e) => {
      if (e.eventId === '1021') return Some(e);
      else return None<VehicleEvent>();
    });
  const geofenceAlertMessage = geofenceAlert.flatMap<Message>((_) => {
    return Some(intl.formatMessage({ id: EventIdMap.get(_.eventId).location }));
  });

  return (
    <>
      <PopupButton
        parent={parent}
        title={geofenceAlertMessage.getOrElse(() => Message(''))}
        style={
          localState.mapMode === 'Open' && !geofenceAlert.isNone()
            ? {
                top: Length.px(
                  viewPort.height -
                    CardHeader.height.value -
                    menuPaneHeight.value,
                ),
                filter: DropShadow(
                  Length.px(0),
                  Length.px(0),
                  Length.px(4),
                  Colors.shadowMoreDark,
                ),
              }
            : {
                top: Length.px(viewPort.height),
              }
        }
        onClick={() =>
          dispatch.context
            .bind(Context.actions.updateCardInfo, {
              state: 'Closing',
            })
            .context.bind(
              Context.actions.updateOnCardClosed,
              Some(() => {
                return Action.bind(Context.actions.updateSelectedVehicleEvent, {
                  selectedVehicleEvent: geofenceAlert.getOrUndefined(),
                  vehicleEventWindowMode: 'Show',
                }).bind(Context.actions.updateCardInfo, {
                  state: 'Opening',
                  name: 'VehicleAlert',
                });
              }),
            )
        }
      />

      <GeofenceSettingPane
        parent={parent}
        radius={radius.getOrElse(() => defaultRadius)}
      />

      <VehicleTrackerPane parent={parent} trackingMode={trackingMode} />

      <HelpDialog
        parent={{ parent }}
        title={intl.formatMessage({ id: 'Location_geoFenceTitle' })}
        message={intl.formatMessage({ id: 'Location_geoFenceHint' })}
        state={localState.helpDialogState}
        onClose={(dispatch) =>
          dispatch.parent(actions.updateHelpDialogState, 'Hide')
        }
      />
    </>
  );
}
