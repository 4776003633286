import React from 'react';
import { MessageKeys, useIntl } from '../i18n/Intl';

interface Props {
  id: MessageKeys;
}

export const Display: React.FC<Props> = ({ id }) => {
  const intl = useIntl();

  return <>{intl.formatMessage({ id }).toReactNode()}</>;
};
