import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { toRadian } from '../../math/Radian';
import { theme } from '../../util/Theme';
import { cardSideMargin, cardTransitionDuration } from '../components/Card';
import { Shape } from '../components/Shape';
import { HomeDataMap } from '../home/Home';
import { FlashImage } from '../icons/FlashImage';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useContextStore } from '../states/ContextStore';
import { DataMapStore, ExtendParent } from '../states/DataMapStore';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';
import { toRemoteStates } from './Helpers';
import { RemoteStates } from './Types';
import { VehicleInfoCardData } from './VehicleInfoCard';

type Data = VehicleInfoCardData;
type DM = ExtendParent<HomeDataMap, 'vehicleInfo', Data>;
type Icon = string;

export type Props = Readonly<{
  parent: DataMapStore<DM>;
  carBodyImageShape: Shape;
  context: Context;
}>;

export const vehicleImageWidth = Length.px(360);
export const vehicleImageHeight = Length.px(160);
export const openedVehicleImageOffset = Length.px(36);
export const openedVehicleImageWidth = Length.px(
  vehicleImageWidth.value - openedVehicleImageOffset.value * 2,
);
const openedVehicleImageDxy = Length.px(
  (openedVehicleImageWidth.value - vehicleImageHeight.value) / 2,
);
const openedVehicleTransform = Transform(
  Matrix3x3.rotation(toRadian(90))
    .product(
      Matrix3x3.translate(
        openedVehicleImageDxy.value,
        openedVehicleImageDxy.value,
      ),
    )
    .to3x3(),
);

function getVehicleDoorIcons(remoteStates: RemoteStates): Icon {
  switch (remoteStates.doorLock) {
    case 'Normal':
      return Icons.img_vehicle_04_doors_normal;
    case 'Active':
      return Icons.img_vehicle_04_doors_active;
    case 'ToNormal':
      return Icons.img_vehicle_04_doors_active;
    case 'ToActive':
      return Icons.img_vehicle_04_doors_normal;
  }
}

function getVehicleLightIcons(
  remoteStates: RemoteStates,
): [Icon, Icon, boolean] {
  switch (remoteStates.light) {
    case 'Normal':
      return [
        Icons.img_vehicle_05_light_normal,
        Icons.img_vehicle_01_light_active,
        false,
      ];
    case 'Active':
      return [
        Icons.img_vehicle_05_light_active,
        Icons.img_vehicle_01_light_active,
        true,
      ];
    case 'ToNormal':
      return [
        Icons.img_vehicle_05_light_active,
        Icons.img_vehicle_01_light_active,
        true,
      ];
    case 'ToActive':
      return [
        Icons.img_vehicle_05_light_normal,
        Icons.img_vehicle_01_light_active,
        false,
      ];
    default:
      return [
        Icons.img_vehicle_05_light_normal,
        Icons.img_vehicle_01_light_active,
        false,
      ];
  }
}

function getVehicleHazardIcons(remoteStates: RemoteStates): [Icon, boolean] {
  switch (remoteStates.hazard) {
    case 'Normal':
      return [Icons.img_vehicle_02_hazard_active, false];
    case 'Active':
      return [Icons.img_vehicle_02_hazard_active, true];
    case 'ToNormal':
      return [Icons.img_vehicle_02_hazard_active, true];
    case 'ToActive':
      return [Icons.img_vehicle_02_hazard_active, false];
    default:
      return [Icons.img_vehicle_02_hazard_active, false];
  }
}

function getVehicleEngineAcIconState(
  remoteStates: RemoteStates,
): [boolean, boolean] {
  if (remoteStates.ac === 'Active' || remoteStates.ac === 'ToNormal') {
    return [true, true];
  } else if (
    remoteStates.engine === 'Active' ||
    remoteStates.engine === 'ToNormal'
  ) {
    return [true, false];
  }

  return [false, false];
}

function CarImageOverlay({
  remoteStates,
}: Readonly<{
  remoteStates: RemoteStates;
}>): React.ReactElement {
  const {
    views: { Image },
    data: { context },
  } = useContextStore();
  const vehicleDoorLockImage = getVehicleDoorIcons(remoteStates);
  const [vehicleLightImage1, vehicleLightImage2, vehicleLightActive] =
    getVehicleLightIcons(remoteStates);
  const [vehicleHazardImage, vehicleHazardActive] =
    getVehicleHazardIcons(remoteStates);
  const [vehicleEngineActive, vehicleAcActive] =
    getVehicleEngineAcIconState(remoteStates);

  const LoadedView = (
    <>
      <Image
        style={{
          width: vehicleImageWidth,
          height: vehicleImageHeight,
          position: 'absolute',
          top: Length.px(0),
          left: Length.px(0),
        }}
        alt='img_vehicle_doors'
        src={vehicleDoorLockImage}
      />
      <Image
        style={{
          width: vehicleImageWidth,
          height: vehicleImageHeight,
          position: 'absolute',
          top: Length.px(0),
          left: Length.px(0),
        }}
        alt='img_vehicle_light1'
        src={vehicleLightImage1}
      />

      {vehicleLightActive ? (
        <Image
          style={{
            width: vehicleImageWidth,
            height: vehicleImageHeight,
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
          }}
          alt='img_vehicle_light2'
          src={vehicleLightImage2}
        />
      ) : (
        <></>
      )}

      {vehicleHazardActive ? (
        <FlashImage
          style={{
            width: vehicleImageWidth,
            height: vehicleImageHeight,
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
          }}
          alt='img_vehicle_hazard'
          src={vehicleHazardImage}
          animate
        />
      ) : (
        <></>
      )}

      {vehicleEngineActive ? (
        <Image
          style={{
            width: vehicleImageWidth,
            height: vehicleImageHeight,
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
          }}
          alt='img_vehicle_ac'
          src={Icons.img_vehicle_06_engine_active}
        />
      ) : (
        <></>
      )}

      {vehicleAcActive ? (
        <Image
          style={{
            width: vehicleImageWidth,
            height: vehicleImageHeight,
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
          }}
          alt='img_vehicle_ac'
          src={Icons.img_vehicle_07_ac_active}
        />
      ) : (
        <></>
      )}
    </>
  );

  const EmptyView = (
    <>
      <Image
        style={{
          width: vehicleImageWidth,
          height: vehicleImageHeight,
          position: 'absolute',
          top: Length.px(0),
          left: Length.px(0),
        }}
        alt='img_vehicle_doors'
        src={Icons.img_vehicle_04_doors_normal}
      />
      <Image
        style={{
          width: vehicleImageWidth,
          height: vehicleImageHeight,
          position: 'absolute',
          top: Length.px(0),
          left: Length.px(0),
        }}
        alt='img_vehicle_light1'
        src={Icons.img_vehicle_05_light_normal}
      />
    </>
  );

  switch (context.contentState) {
    case 'Reload':
    case 'Updating':
    case 'Loaded':
      if (context.dataErrors.serviceTelemetry) return EmptyView;
      else return LoadedView;
    default:
      return EmptyView;
  }
}

export function CarImageContainer({
  isOpen,
}: Readonly<{
  isOpen: boolean;
}>): React.ReactElement {
  const {
    views: { View, Image },
    data: { context },
  } = useContextStore();
  const Container = View;
  const ImageSet = View;
  const ImageContainer = View;
  const viewPort = context.viewPort;
  const remoteStates = toRemoteStates(
    context.serviceTelemetry,
    context.ongoingRemoteStates,
  );
  const containerStyle: OptionalStyles = isOpen
    ? {
        width: openedVehicleImageWidth,
        height: vehicleImageHeight,
        transform: openedVehicleTransform,
      }
    : {
        width: theme(
          Length.px(viewPort.width - cardSideMargin.value * 2),
          Length.px(viewPort.width),
        ),
        height: vehicleImageHeight,
      };

  return (
    <Container
      style={{
        overflow: 'hidden',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        transition: [Transition('transform', cardTransitionDuration)],
        ...containerStyle,
      }}
    >
      <ImageContainer
        style={{
          display: 'flex',
        }}
      >
        <ImageSet
          style={{
            position: 'relative',
          }}
        >
          <Image
            style={{
              width: vehicleImageWidth,
              height: vehicleImageHeight,
            }}
            alt='img_vehicle_body'
            src={Icons.img_vehicle_03_body}
          />
          <CarImageOverlay remoteStates={remoteStates} />
        </ImageSet>
      </ImageContainer>
    </Container>
  );
}
