import { Time } from './Time';
import { TimingFunction } from './TimingFunction';

export interface Animation {
  readonly name: string;
  readonly duration: Time;
  readonly timingFunction: TimingFunction;
  readonly delay: Time;
  readonly iterationCount: IterationCount;
  readonly direction: Direction;
  readonly fillMode: FillMode;
  readonly playState: PlayState;
  readonly toStyle: () => string;
}

type IterationCount = 'infinite' | number;
type Direction = 'normal' | 'reverse' | 'alternate' | 'alternate-reverse';
type FillMode = 'none' | 'forwards' | 'backwards' | 'both';
type PlayState = 'running' | 'paused';

export function Animation(
  name: string,
  duration: Time,
  timingFunction: TimingFunction = TimingFunction.ease,
  delay: Time = Time(0, 's'),
  iterationCount: IterationCount = 1,
  direction: Direction = 'normal',
  fillMode: FillMode = 'none',
  playState: PlayState = 'running',
): Animation {
  const text = `${name} ${duration.toStyle()} ${timingFunction.toStyle()} ${delay.toStyle()} ${iterationCount} ${direction} ${fillMode} ${playState}`;
  return {
    name,
    duration,
    timingFunction,
    delay,
    iterationCount,
    direction,
    fillMode,
    playState,
    toStyle: () => text,
  };
}
