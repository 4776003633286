import * as Client from '@auto/monaka-client/dist/20';
import { ServiceKey } from '@auto/monaka-client/dist/20';
import React from 'react';
import { config } from '../Config';
import { homeDispatch } from '../ui/home/Home';
import { createIntl } from '../ui/i18n/Intl';
import { getDB } from '../ui/mock/Database';
import { ActionBinder } from '../ui/states/ActionBinder';
import { Context } from '../ui/states/Context';
import { Action } from '../ui/states/Reducer';
import { BuildOpt } from '../util/BuildOpt';
import { Option, OptionJson } from '../util/Option';
import { sleep } from '../util/Sleep';
import { AppSyncClient } from './Client';
import {
  Geofence,
  GeofenceJson,
  fromClientGeofence,
  fromGeofenceJson,
  toGeofenceJson,
} from './Geofence';
import { openLink, println } from './NativeApi';

export type UserType = Client.UserType;

export interface Vehicle {
  readonly vin: string;
  readonly groupPath: string;
  readonly attributes: {
    readonly carNo: Option<string>;
  };
  readonly model: string;
  readonly geofence: Option<Geofence>;
  readonly dcmId: string;
  readonly image: string;
  readonly userType: UserType;
  readonly services: ReadonlyArray<Client.ServiceKey>;
  readonly timestamp: Date;
}

export type VehicleJson = {
  readonly [Key in Exclude<
    keyof Vehicle,
    'attributes' | 'geofence' | 'timestamp'
  >]: Vehicle[Key];
} & {
  readonly attributes: {
    readonly carNo: OptionJson<string>;
  };
  readonly geofence: OptionJson<GeofenceJson>;
  readonly timestamp: number; // epoch in milliseconds
};

export function toVehicleJson(a: Vehicle): VehicleJson {
  return {
    ...a,
    attributes: { carNo: a.attributes.carNo.toJson() },
    geofence: a.geofence.map(toGeofenceJson).toJson(),
    timestamp: a.timestamp.getTime(),
  };
}

export function fromVehicleJson(a: VehicleJson): Vehicle {
  return {
    ...a,
    attributes: {
      carNo: Option(a.attributes.carNo.value),
    },
    geofence: Option(a.geofence.value).map(fromGeofenceJson),
    timestamp: new Date(a.timestamp),
  };
}

function fromClientVehicle(x: Client.Vehicle): Vehicle {
  return {
    vin: x.name,
    groupPath: x.groupPath,
    attributes: {
      carNo: Option(x.attributes.car_no),
    },
    model: x.carModelName,
    geofence: Option(x.geofence).map(fromClientGeofence),
    dcmId: x.dcmId,
    image: x.image,
    userType: x.userType as UserType,
    services: x.services as ServiceKey[],
    timestamp: new Date(Date.now()),
  };
}

export async function getVehicleList(): Promise<Vehicle[]> {
  if (BuildOpt.isMock()) {
    println('Getting vehicle list');
    await sleep(500);
    const db = await getDB();
    if (db.vehicleListError) return Promise.reject(Error('Vehicle list error'));
    else {
      println(`Got vehicle list. Number of cars: ${db.vehicles.length}`);
      return db.vehicles;
    }
  } else {
    const gSysUserId = Option(await Client.Auth.currentDriver())
      .map((_) => _.gSysUserId)
      .getOrElse(() => 'unknown');
    println(`Getting vehicle list for gSysUserId=${gSysUserId}`);
    const xs = await AppSyncClient.getVehicleList(gSysUserId).catch((err) => {
      const prefix = `Error on getVehicleList for gSysUserId=${gSysUserId}`;
      if (err.resultData?.errorCode) {
        return Promise.reject(Error(`${prefix}: ${err.resultData.errorCode}`));
      } else {
        return Promise.reject(Error(`${prefix}: ${err}`));
      }
    });
    println(`Got vehicle list: size=${xs.length}`);
    return xs.map(fromClientVehicle);
  }
}

export function getSelectedVehicle(context: Context): Option<Vehicle> {
  return context.selectedVehicleVin.flatMap((vin) =>
    Option(context.vehicles.find((_) => _.vin === vin)),
  );
}

export function getUnselectedVehicle(context: Context): Option<Vehicle> {
  return context.selectedVehicleVin.flatMap((vin) =>
    Option(context.vehicles.find((_) => _.vin !== vin)),
  );
}

export function getSelectedVehicleIndex(context: Context): Option<number> {
  return context.selectedVehicleVin.flatMap((vin) =>
    Option(context.vehicles.findIndex((_) => _.vin === vin)),
  );
}

export function useGetCarNickName(
  car: Vehicle,
  fallback = true,
): [string, boolean] {
  const context = React.useContext(Context.ref);
  const nickName = context.carNickName[car.vin];
  if (fallback && nickName === undefined) {
    return [car.model, true];
  }
  return [nickName ?? '', false];
}

export function isServiceAllowed(
  context: Context,
  serviceKey: Client.ServiceKey,
): boolean {
  return getSelectedVehicle(context).unwrap(
    (v) => v.services.includes(serviceKey),
    () => false,
  );
}

export function userRestrictionAction(context: Context): ActionBinder<Context> {
  const intl = createIntl(context);
  const vehicle = getSelectedVehicle(context).getOrNull();

  if (vehicle === null) {
    println('Vehicle is null. Cannot determine the restriction action');
    return Action();
  }

  return vehicle.userType === 'owner'
    ? Action.bind(Context.actions.updateConfirmDialog, {
        state: 'Show',
        title: intl.formatMessage({
          id: 'Common_dialogRestrictionOwnerTitle',
        }),
        message: intl.formatMessage({
          id: 'Common_dialogRestrictionOwnerBody',
        }),
        confirmButtonTitle: intl.formatMessage({
          id: 'Common_dialogRestrictionOwnerLink',
        }),
        cancelButtonTitle: intl.formatMessage({
          id: 'Common_dialogRestrictionClose',
        }),
        onConfirm: () => {
          const url = config[BuildOpt.env].subscriptionEndpoint;
          if (url.length > 0) {
            openLink(url);
          }
          return homeDispatch;
        },
      })
    : Action.bind(Context.actions.updateAlertDialog, {
        state: 'Show',
        title: intl.formatMessage({
          id: 'Common_dialogRestrictionUserTitle',
        }),
        message: intl.formatMessage({
          id: 'Common_dialogRestrictionUserBody',
        }),
      });
}
