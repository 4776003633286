import { config } from '../../Config';
import { GeoPos, Geofence } from '../../apis/Geofence';
import { UserLocation } from '../../apis/NativeApi';
import { VehicleLocation } from '../../apis/ServiceTelemetry';
import { StorageKeys } from '../../apis/StorageKey';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { getErrorMessage } from '../../util/Error';
import { Listener } from '../../util/Listener';
import { None, Option, Some } from '../../util/Option';
import { Result, Success } from '../../util/Result';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import { Shape } from '../components/Shape';
import { GoogleMap, LatLngBounds } from '../google/GoogleMap';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { Colors } from '../style/Color';
import {
  MapData,
  RenderMap,
  VehicleLocationData,
  isRenderMap,
  minGeofenceRadius,
} from './VehicleLocationState';

type Data = VehicleLocationData;

const geofenceCenterHeightPx = 12;

export async function createMapData(mapId: string): Promise<MapData> {
  const defaultMapLocation = {
    lat: 35.6505341,
    lng: 139.7077698,
  };

  const googleMap = await GoogleMap(mapId, {
    center: defaultMapLocation,
    zoom: getZoom(),
    disableDefaultUI: true,
    clickableIcons: false,
    backgroundColor: Colors.mapBackground.toStyle(),
  }).catch((err) =>
    Promise.reject(
      Error(`Failed to create a google map object: ${getErrorMessage(err)}`),
    ),
  );

  const vehicle = new google.maps.Marker({
    position: defaultMapLocation,
    icon: Icons.ic_location_vehicle,
    zIndex: 2,
  });

  const user = new google.maps.Marker({
    position: defaultMapLocation,
    icon: Icons.ic_location_me,
    zIndex: 1,
  });

  const circle = new google.maps.Circle({
    strokeColor: Colors.accent.toStyle(),
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: Colors.background.toStyle(),
    fillOpacity: 0,
    center: defaultMapLocation,
    radius: 10000,
  });

  const center = new google.maps.Marker({
    position: defaultMapLocation,
    icon: Icons.ic_geofence_center,
    zIndex: 3,
  });
  return { googleMap, vehicle, user, circle, center };
}

export function getZoom(): number {
  return Option(localStorage.getItem(StorageKeys.mapZoom))
    .map((_) => parseInt(_))
    .getOrElse(() => 11);
}

export function setZoom(zoom: number): void {
  localStorage.setItem(StorageKeys.mapZoom, zoom.toString());
}

// function equalDegree(x: number, y: number): boolean {
//   return Math.round(x * 100000) === Math.round(y * 100000);
// }
//
// function equalLatLng(p1: LatLng, p2: LatLng): boolean {
//   return equalDegree(p1.lat(), p2.lat()) && equalDegree(p1.lng(), p2.lng());
// }
//
// function equalBounds(b1: LatLngBounds, b2: LatLngBounds): boolean {
//   return (
//     equalLatLng(b1.getNorthEast(), b2.getNorthEast()) &&
//     equalLatLng(b1.getSouthWest(), b2.getSouthWest())
//   );
// }

function geofenceToCenter(geofence: Geofence): GeoPos {
  return {
    lat: geofence.center.lat,
    lng: geofence.center.lng,
  };
}

function renderGeofence(
  container: Shape,
  mapData: MapData,
  geofence: Option<Geofence>,
): void {
  geofence.unwrap(
    (geofence) => {
      const map = mapData.googleMap;
      mapData.circle.setCenter(geofenceToCenter(geofence));
      mapData.circle.setRadius(geofence.radius.toMeters());
      mapData.circle.setMap(map);

      const center = Option(map.getBounds())
        .flatMap((b) => {
          return container.getBound().flatMap((a) => {
            const north = b.getNorthEast().lat();
            const south = b.getSouthWest().lat();
            const heightLat = south - north;
            const dlat = (heightLat * geofenceCenterHeightPx) / a.height / 2;
            const center = geofenceToCenter(geofence);
            return Some({
              lat: center.lat + dlat,
              lng: center.lng,
            });
          });
        })
        .getOrElse(() => geofenceToCenter(geofence));

      mapData.center.setPosition(center);
      mapData.center.setMap(map);
    },
    () => {
      mapData.circle.setMap(null);
      mapData.center.setMap(null);
    },
  );
}

function renderMapHelper(
  container: Shape,
  mapData: MapData,
  drawMap: (map: GoogleMap) => void,
  vehicleLocation: Option<VehicleLocation>,
  userLocation: Option<GeoPos>,
  geofence: Option<Geofence>,
): void {
  const map = mapData.googleMap;
  const vehicle = mapData.vehicle;
  const user = mapData.user;

  drawMap(map);

  vehicleLocation.unwrap(
    (p) => {
      vehicle.setPosition(p.location);
      vehicle.setMap(map);
      vehicle.setIcon(
        p.isOld ? Icons.ic_location_vehicle_error : Icons.ic_location_vehicle,
      );
    },
    () => vehicle.setMap(null),
  );

  userLocation
    .map((p) => {
      user.setPosition(p);
      user.setMap(map);
      return Unit;
    })
    .getOrElse(() => {
      user.setMap(null);
      return Unit;
    });

  renderGeofence(container, mapData, geofence);
}

function renderMapOnClosed(
  container: Shape,
  mapData: MapData,
  vehicleLocation: Option<VehicleLocation>,
): void {
  vehicleLocation.forEach((vehicleLocation) =>
    renderMapHelper(
      container,
      mapData,
      (map) => {
        map.setOptions({ draggable: false });
        map.setCenter(vehicleLocation.location);
        map.setZoom(getZoom());
      },
      Some(vehicleLocation),
      None(),
      None(),
    ),
  );
}

async function renderMapOnOpened(
  context: Context,
  container: Shape,
  mapData: MapData,
  command: RenderMap,
  vehicleLocation: Option<VehicleLocation>,
  userLocation: Option<GeoPos>,
): Promise<void> {
  const geofence = getSelectedVehicle(context).flatMap((_) => _.geofence);
  renderMapHelper(
    container,
    mapData,
    (map) => {
      map.setOptions({ draggable: true });
      if (command.options.disablePanToDefault === false) {
        getDefaultBounds(container, userLocation, vehicleLocation).onResult(
          (_) => map.fitBounds(_.view),
          () =>
            vehicleLocation.onResult(
              (_) => map.setCenter(_.location),
              () =>
                userLocation.onResult(
                  (location) => map.setCenter(location),
                  () => {
                    return;
                  },
                ),
            ),
        );
      }
    },
    vehicleLocation,
    userLocation,
    geofence,
  );
}

export async function renderMap(data: Data, context: Context): Promise<void> {
  const command = data.localState.command;
  const localState = data.localState;
  const container = data.container;
  const mapMode = localState.mapMode;
  const userLocation = localState.userLocation;

  localState.mapData.forEach((mapData) => {
    if (isRenderMap(command)) {
      if (mapMode === 'Close') {
        renderMapOnClosed(
          container,
          mapData,
          context.serviceTelemetry.data.toOption().flatMap((_) => _.position),
        );
      } else if (mapMode === 'Open') {
        renderMapOnOpened(
          context,
          container,
          mapData,
          command,
          context.serviceTelemetry.data.toOption().flatMap((_) => _.position),
          getGeoPos(userLocation),
        );
      } else if (mapMode === 'Geofence') {
        renderMapHelper(
          container,
          mapData,
          (map) => {
            map.setOptions({ draggable: true });
            if (command.options.isUserLocationPolling === false) {
              localState.geofenceCache.forEach((_) => map.setCenter(_.center));
              map.setZoom(getZoom());
            }
          },
          None(),
          None(),
          localState.geofenceCache,
        );
        if (command.options.isUserLocationPolling === false)
          adjustGeofenceZoom(data);
      }
    } else if (command === 'RenderGeofence') {
      renderGeofence(container, mapData, localState.geofenceCache);
    } else if (command === 'AdjustGeofenceZoom' && mapMode === 'Geofence') {
      renderGeofence(container, mapData, localState.geofenceCache);
      adjustGeofenceZoom(data);
    } else if (command === 'PanToDefault') {
      panToDefault(data, context);
    }
  });
}

// const rect1 = Lazy(() => new google.maps.Rectangle());
// const rect2 = Lazy(() => new google.maps.Rectangle());
// const rect3 = Lazy(() => new google.maps.Rectangle());

type DefaultBounds = {
  box: LatLngBounds;
  frame: LatLngBounds;
  view: LatLngBounds;
};

function correctLat(lat: number): number {
  if (lat >= 90) return 85;
  else if (lat <= -90) return -85;
  else return lat;
}

function correctUserLocation(
  userLocation: GeoPos,
  vehicleLocation: GeoPos,
): GeoPos {
  if (userLocation.lng - vehicleLocation.lng > 180) {
    return { lat: userLocation.lat, lng: userLocation.lng - 360 };
  } else if (userLocation.lng - vehicleLocation.lng < -180) {
    return { lat: userLocation.lat, lng: userLocation.lng + 360 };
  } else {
    return userLocation;
  }
}

export function getGeoPos(
  userLocation: 'Loading' | Result<UserLocation>,
): Option<GeoPos> {
  return userLocation === 'Loading'
    ? None<GeoPos>()
    : userLocation
        .toOption()
        .flatMap((_) =>
          _ === 'NoPermission' || _ === 'Unavailable'
            ? None<GeoPos>()
            : Some(_),
        );
}

export function getDefaultBounds(
  container: Shape,
  userLocation: Option<GeoPos>,
  vehicleLocation: Option<VehicleLocation>,
): Option<DefaultBounds> {
  return vehicleLocation
    .map((_) => _.location)
    .flatMap((vehicleLocation) =>
      userLocation
        .map((userLocation) =>
          correctUserLocation(userLocation, vehicleLocation),
        )
        .map((userLocation) => {
          const containerBound = container.getBound().getUnsafeValue();
          const viewHeightPx = containerBound.height;
          const viewWidthPx = containerBound.width;
          const frameHeightPx = viewHeightPx - 120;
          const frameWidthPx = viewWidthPx - 120;
          const frameRatio = frameWidthPx / frameHeightPx;
          const [boxWest, boxEast] =
            vehicleLocation.lng < userLocation.lng
              ? [vehicleLocation.lng, userLocation.lng]
              : [userLocation.lng, vehicleLocation.lng];
          const [boxNorth, boxSouth] =
            vehicleLocation.lat < userLocation.lat
              ? [userLocation.lat, vehicleLocation.lat]
              : [vehicleLocation.lat, userLocation.lat];
          const boxWidth = boxEast - boxWest;
          const boxHeight = boxNorth - boxSouth;
          const boxRatio = boxWidth / boxHeight;
          const [frameWest, frameEast, frameNorth, frameSouth] = Scope(() => {
            if (boxRatio > frameRatio) {
              const bh = (boxWidth / frameRatio - boxHeight) / 2;
              return [
                boxWest,
                boxEast,
                correctLat(boxNorth - bh),
                correctLat(boxSouth + bh),
              ];
            } else {
              const bw = (boxHeight * frameRatio - boxWidth) / 2;
              return [
                boxWest - bw,
                boxEast + bw,
                correctLat(boxNorth),
                correctLat(boxSouth),
              ];
            }
          });
          const dlng = ((frameEast - frameWest) * 60) / frameWidthPx;
          const dlat = ((frameNorth - frameSouth) * 40) / frameHeightPx;
          const viewNorth = correctLat(frameNorth + dlat);
          const viewSouth = correctLat(frameSouth - dlat * 2);
          const viewWest = frameWest - dlng;
          const viewEast = frameEast + dlng;
          const box = new google.maps.LatLngBounds(
            new google.maps.LatLng(boxSouth, boxWest),
            new google.maps.LatLng(boxNorth, boxEast),
          );
          const frame = new google.maps.LatLngBounds(
            new google.maps.LatLng(frameSouth, frameWest),
            new google.maps.LatLng(frameNorth, frameEast),
          );
          const view = new google.maps.LatLngBounds(
            new google.maps.LatLng(viewSouth, viewWest),
            new google.maps.LatLng(viewNorth, viewEast),
          );
          return { box, frame, view };
        }),
    );
}

// function drawDebugDefaultRect(map: GoogleMap, bounds: DefaultBounds): void {
//   rect1.get().setBounds(bounds.box);
//   rect1.get().setOptions({ strokeColor: '#FFFFFF', strokeWeight: 2 });
//   rect1.get().setMap(map);
//   rect2.get().setBounds(bounds.frame);
//   rect2.get().setOptions({ strokeColor: '#00FF00', strokeWeight: 2 });
//   rect2.get().setMap(map);
//   rect3.get().setBounds(bounds.view);
//   rect3.get().setOptions({ strokeWeight: 1 });
//   rect3.get().setMap(map);
// }

function panToDefault(data: Data, context: Context): void {
  data.localState.mapData.forEach(async (mapData) => {
    const googleMap = mapData.googleMap;
    const vehicleLocation = context.serviceTelemetry.data
      .toOption()
      .flatMap((_) => _.position);
    const userLocation = getGeoPos(data.localState.userLocation);
    const geofence = getSelectedVehicle(context).flatMap((_) => _.geofence);
    // const [vehiclePos, vehicleIcon] = await getVehicleInfo(context.serviceTelemetry.position);
    getDefaultBounds(data.container, userLocation, vehicleLocation).onResult(
      (bounds) => {
        //drawDebugDefaultRect(_.googleMap, bounds);
        googleMap.fitBounds(bounds.view);
      },
      () =>
        vehicleLocation.onResult(
          (_) => googleMap.setCenter(_.location),
          () =>
            userLocation.onResult(
              (location) => googleMap.setCenter(location),
              () =>
                geofence.onResult(
                  (_) => {
                    googleMap.setCenter(_.center);
                    adjustGeofenceZoom(data);
                  },
                  () => {
                    const { position, zoom } =
                      config.regionPositions[context.locale.region];
                    googleMap.setCenter(position);
                    googleMap.setZoom(zoom);
                  },
                ),
            ),
        ),
    );
  });
}

function adjustGeofenceZoom(data: Data): void {
  const spherical = google.maps.geometry.spherical;
  const LatLng = google.maps.LatLng;
  const LatLngBounds = google.maps.LatLngBounds;

  data.localState.mapData.forEach((md) => {
    const map = md.googleMap;
    const circle = md.circle;
    const center = circle.getCenter();
    if (center === null) {
      return;
    }
    const radius =
      Math.max(minGeofenceRadius.toMeters(), circle.getRadius()) * 0.8;
    const north = spherical.computeOffset(center, radius, 0);
    const east = spherical.computeOffset(center, radius, 90);
    const south = spherical.computeOffset(center, radius, 180);
    const west = spherical.computeOffset(center, radius, 270);
    const bounds = new LatLngBounds(
      new LatLng(south.lat(), west.lng()),
      new LatLng(north.lat(), east.lng()),
    );
    map.fitBounds(bounds);
  });
}

export function listenBoundsChanged(googleMap: GoogleMap): Listener<Unit> {
  return Listener((dispatch) => {
    googleMap.addListener('bounds_changed', () => dispatch(Success(Unit)));
  });
}
