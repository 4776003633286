import React from 'react';
import { IconsDaihatsu } from '../../images/EncodedIconsDaihatsu';
import { IconsPerodua } from '../../images/EncodedIconsPerodua';
import { theme } from '../../util/Theme';
import { IcButton } from '../icons/IcButton';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { isContentLoading, shouldShowNetworkErrorOnHome } from './Helpers';
import { HomeDataMap } from './Home';

type Statics = Readonly<{
  headerHeight: number;
}>;

type Factory = {
  (props: Props): React.ReactElement;
};

type HomeHeader = Factory & Statics;

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

export function getLogo(locale: string): string {
  switch (locale) {
    case 'ja':
      return theme(
        IconsDaihatsu.ic_logo_horizontal_jp,
        IconsPerodua.ic_logo_horizontal_en,
      ); // Perodua does not have jp image.
    case 'en':
      return Icons.ic_logo_horizontal_en;
    default:
      return Icons.ic_logo_horizontal_en;
  }
}

const statics: Statics = {
  headerHeight: 44,
};

function HomeHeaderComponent(props: Props): React.ReactElement {
  const {
    views: { View, Image },
    data: { context },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const Container = View;

  return (
    <Container
      style={{
        position: 'relative',
        height: Length.px(HomeHeader.headerHeight),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.background,
        // zIndex: ZIndices.homeHeader,
      }}
    >
      {/* Left */}
      <View
        style={{
          width: Length.px(44),
        }}
      ></View>

      {/* Center */}
      <View
        onClick={(evt) => {
          evt.stopPropagation();
          return dispatch;
        }}
      >
        <Image
          alt='ic_logo'
          src={getLogo(context.locale.lang)}
          style={{
            height: Length.px(30),
          }}
        />
      </View>

      {/* Right */}
      {!shouldShowNetworkErrorOnHome(context) ? (
        <View
          onClick={(evt) => {
            if (isContentLoading(context)) {
              return dispatch;
            }
            evt.stopPropagation();
            return dispatch.context.bind(
              Context.actions.updateRegisterWindowMode,
              'Show',
            );
          }}
        >
          <IcButton
            image={Icons.ic_user}
            imageWidth={Length.px(44)}
            imageHeight={Length.px(44)}
            disabled={isContentLoading(context)}
          />
        </View>
      ) : (
        <View style={{ width: Length.px(44), height: Length.px(44) }} />
      )}
    </Container>
  );
}

const component: Factory = HomeHeaderComponent;

export const HomeHeader: HomeHeader = Object.assign(component, statics);
