import React from 'react';
import { Option } from '../../util/Option';
import { LineSpace } from '../components/LineSpace';
import { Message, useIntl } from '../i18n/Intl';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { Meter, State } from './Meter';
import { ServiceReminderDataMap } from './ServiceReminderCard';

type DP = DataMapDispatch<WithContext<ServiceReminderDataMap>>;

export function MaintenanceItem({
  parent,
  title,
  icon,
  value,
  state,
  threshold,
  ticks,
  tickImage,
  onClick,
}: Readonly<{
  parent: DataMapStore<ServiceReminderDataMap>;
  title: Message;
  icon: string;
  value: Percentage;
  state: State;
  threshold: {
    value: Percentage;
    text: Message;
  };
  ticks: Option<string[]>;
  tickImage: string;
  onClick: (evt: React.MouseEvent, _: DP) => DP;
}>): React.ReactElement {
  const {
    views: { View, Text, Image },
    dispatch,
  } = useParentStore({ parent });
  const intl = useIntl();

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
      }}
    >
      {/* Image */}
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: Length.px(12),
          paddingRight: Length.px(12),
        }}
      >
        <Image
          src={icon}
          style={{
            height: Length.px(40),
            width: Length.px(40),
          }}
        />
      </View>

      {/* Meter */}
      <View
        style={{
          flexGrow: 1,
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
      >
        <View
          style={{
            paddingTop: Length.px(12),
            paddingBottom: Length.px(8),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(16),
              fontWeight: 'bold',
            }}
          >
            {title.toReactNode()}
          </Text>
        </View>

        <Meter
          parent={parent}
          value={value}
          state={state}
          threshold={threshold}
          ticks={ticks}
          tickImage={tickImage}
        />

        <LineSpace height={Length.px(10)} />
      </View>

      {/* Reset */}
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: Length.px(96),
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
        onClick={(evt) => onClick(evt, dispatch)}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            color: Colors.accent,
          }}
        >
          {intl.formatMessage({ id: 'Remainder_mResetBtn' }).toReactNode()}
        </Text>
      </View>
    </View>
  );
}
