import { Driver } from '@auto/monaka-client/dist/20';
import * as Client from '@auto/monaka-client/dist/20';
import { createVehicle, getDB, updateDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { debugAsync1 } from '../util/Debug';
import { getErrorMessage } from '../util/Error';
import { Jsonable } from '../util/Json';
import { Region } from '../util/Locale';
import { None, Option } from '../util/Option';
import { sleep } from '../util/Sleep';
import { AppSyncClient, AuthClient } from './Client';
import { genVehicleEvents } from './EventTelemetry';
import {
  defaultVehicleLocation,
  genServiceTelemetry,
} from './ServiceTelemetry';
import { defaultWifiStatus } from './Wifi';

export type Platform = 'push_gcm';

export interface DeviceInfo extends Jsonable<'DeviceInfo', DeviceInfoJson> {
  readonly deviceToken: string;
  readonly usedAt: Date;
  readonly g_sys_user_id: string;
}

interface DeviceInfoJson {
  readonly deviceToken: string;
  readonly usedAt: number;
  readonly g_sys_user_id: string;
}

function toDeviceInfoJson(a: DeviceInfo): DeviceInfoJson {
  return {
    ...a,
    usedAt: a.usedAt.getTime(),
  };
}

function fromClientDeviceInfo(_: Client.DeviceInfo): DeviceInfo {
  const self: DeviceInfo = {
    kind: 'DeviceInfo',
    deviceToken: _.deviceToken,
    g_sys_user_id: _.g_sys_user_id,
    usedAt: new Date(_.usedAt),
    toJson: () => toDeviceInfoJson(self),
  };
  return self;
}

export function getCurrentDriver(): Promise<Option<Driver>> {
  return AuthClient.currentDriver().then((_) => Option(_));
}

export async function addUserInfo(params: {
  userId: string;
  region: Region;
}): Promise<void> {
  return debugAsync1(
    `addUserInfo(${params.userId}, ${params.region})`,
    async () => {
      if (BuildOpt.isMock()) {
        await sleep(500);
        return Promise.resolve();
      } else {
        const gSysUserId = Option(await Client.Auth.currentDriver())
          .map((_) => _.gSysUserId)
          .getOrElse(() => 'unknown');
        return AppSyncClient.addUserInfo({
          gSysUserId,
          userId: params.userId,
          locale: params.region,
        }).catch((err) =>
          Promise.reject(
            Error(`Error occurred on addUserInfo. ${getErrorMessage(err)}`),
          ),
        );
      }
    },
  );
}

export async function addDeviceInfo(deviceToken: string): Promise<void> {
  return debugAsync1(`addDeviceInfo`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve();
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.addDeviceInfo(gSysUserId, deviceToken).catch((err) =>
        Promise.reject(
          Error(`Error occurred on addDeviceInfo. ${getErrorMessage(err)}`),
        ),
      );
    }
  });
}

export async function registerDeviceToken(params: {
  platform: Platform;
  deviceToken: string;
}): Promise<void> {
  return debugAsync1(`registerDeviceToken`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve();
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.registerDeviceToken({ ...params, gSysUserId }).catch(
        (err) =>
          Promise.reject(
            Error(
              `Error occurred on registerDeviceToken. ${getErrorMessage(err)}`,
            ),
          ),
      );
    }
  });
}

export async function deleteDeviceToken(params: {
  platform: Platform;
  deviceToken: string;
}): Promise<void> {
  return debugAsync1(`deleteDeviceToken`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve();
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.deleteDeviceToken({ ...params, gSysUserId }).catch(
        (err) =>
          Promise.reject(
            Error(
              `Error occurred on deleteDeviceToken. ${getErrorMessage(err)}`,
            ),
          ),
      );
    }
  });
}

export async function addNotification(params: {
  platform: Platform;
  deviceToken: string;
}): Promise<void> {
  return debugAsync1(`addNotification`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve();
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.addNotification({
        ...params,
        gSysUserId,
      }).catch((err) =>
        Promise.reject(
          Error(`Error occurred on addNotification. ${getErrorMessage(err)}`),
        ),
      );
    }
  });
}

export async function deleteNotification(params: {
  platform: Platform;
  deviceInfoList: DeviceInfo[];
}): Promise<void> {
  return debugAsync1(`deleteNotification`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve();
    } else {
      return AppSyncClient.deleteNotification({
        platform: params.platform,
        deviceInfoList: params.deviceInfoList.map((a) => ({
          ...a,
          usedAt: a.usedAt.getTime(),
        })),
      }).catch((err) =>
        Promise.reject(
          Error(
            `Error occurred on deleteNotification. ${getErrorMessage(err)}`,
          ),
        ),
      );
    }
  });
}

export async function getDeviceInfo(
  deviceToken: string,
): Promise<Option<DeviceInfo>> {
  return debugAsync1(`getDeviceInfo`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve(None<DeviceInfo>());
    } else {
      return AppSyncClient.getDeviceInfo(deviceToken)
        .then((_) => Option(_).map(fromClientDeviceInfo))
        .catch((err) =>
          Promise.reject(
            Error(`Error occurred on getDeviceInfo. ${getErrorMessage(err)}`),
          ),
        );
    }
  });
}

export async function getRemovableDeviceInfoList(): Promise<DeviceInfo[]> {
  return debugAsync1(`getRemovableDeviceInfoList`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return Promise.resolve([]);
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.getRemovableDeviceInfoList(gSysUserId)
        .then((xs) => xs.map(fromClientDeviceInfo))
        .catch((err) =>
          Promise.reject(
            Error(
              `Error occurred on getRemovableDeviceInfoList. ${getErrorMessage(
                err,
              )}`,
            ),
          ),
        );
    }
  });
}

export async function acceptSharedUser(params: {
  invitationCode: string;
  invitationExpireTime: number;
}): Promise<{
  approvalLimitHours: number | null;
}> {
  return debugAsync1(`acceptSharedUser ${params.invitationCode}`, async () => {
    if (BuildOpt.isMock()) {
      await sleep(1000);
      const db = await getDB();
      const v = createVehicle(db, 'limited');
      const events = genVehicleEvents(v.vin);

      if (db.pairVehicleError)
        return Promise.reject(Error('Could not accept shared user'));
      else {
        await updateDB(db, {
          vehicles: db.vehicles.concat([v]),
          serviceTelemetries: db.serviceTelemetries
            .filter((s) => s.vin !== v.vin)
            .concat([
              genServiceTelemetry({
                vin: v.vin,
                position: defaultVehicleLocation,
              }),
            ]),
          vehicleEvents: db.vehicleEvents
            .filter((e) => e.vin !== v.vin)
            .concat([{ vin: v.vin, events }]),
          userVehicleEvents: db.userVehicleEvents
            .filter((e) => e.vin !== v.vin)
            .concat([{ vin: v.vin, events }]),
          wifiStatuses: db.wifiStatuses
            .filter((s) => s.vin !== v.vin)
            .concat([defaultWifiStatus(v.vin)]),
        });
        return Promise.resolve({ approvalLimitHours: 1 });
      }
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.acceptSharedUser({
        sharedUserId: gSysUserId,
        ...params,
      }).catch((err) =>
        Promise.reject(
          Error(`Error occurred on acceptSharedUser. ${getErrorMessage(err)}`),
        ),
      );
    }
  });
}
