import React from 'react';
import { println } from '../../apis/NativeApi';
import {
  Vehicle,
  getSelectedVehicleIndex,
  useGetCarNickName,
} from '../../apis/VehicleList';
import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Option, Some } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Range } from '../../util/Range';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { Unit } from '../../util/Unit';
import { LineSpace } from '../components/LineSpace';
import { Shape } from '../components/Shape';
import { toTouchVector } from '../events/Events';
import { Swipe } from '../events/Swipe';
import {
  isContentLoading,
  shouldShowNetworkErrorOnHome,
} from '../home/Helpers';
import { HomeData, HomeDataMap } from '../home/Home';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { ExtractLocalState } from '../states/Reducer';
import { Store } from '../states/Store';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transition } from '../style/Transition';
import { Badge, badgeHeight } from './Badge';

type Key = 'vehicleSelector';

type Data = Readonly<{
  localState: LocalState;
  container: Shape;
  slider: Shape;
  items: Item[];
}>;

type LocalState = Readonly<{
  swipe: ExtractLocalState<Swipe>;
}>;

type Item = Readonly<{
  shape: Shape;
}>;

type Actions = Readonly<{
  updateNumberOfCars: (data: Data, numberOfCars: number) => Data;
  render: (data: Data) => Data;
  onTouchStart: (data: Data, touch: Vector) => Data;
  onTouchMove: (data: Data, touch: Vector) => Data;
}>;

type Props = Readonly<{
  store: Store<HomeData>;
}>;

type DM = ExtendParent<HomeDataMap, Key, Data>;
type DP = DataMapDispatch<WithContext<DM>>;

type _State = 'Loading' | 'Loaded';

const carSelectorHeight = 178 + 3;
const imageHeight = 120;
const gap = 35;

function toSwipe(data: Data): Swipe {
  return {
    localState: data.localState.swipe,
  };
}

function fromSwipe(data: Data, swipe: Swipe): Data {
  return copy(data, {
    localState: {
      swipe: swipe.localState,
    },
  });
}

function dispatchSelectedIndex(
  dxy: Vector,
  context: Context,
  dispatch: DP,
): DP {
  return getSelectedVehicleIndex(context)
    .map<DP>((index) => {
      const _ = dispatch.context(Context.actions.updateContentState, 'Load');
      if (dxy.x < -10) {
        // increment
        if (index < context.vehicles.length - 1) {
          return _.context(
            Context.actions.updateSelectedVehicleVin,
            Some(context.vehicles[index + 1].vin),
          );
        } else {
          return dispatch;
        }
      } else if (dxy.x > 10) {
        // decrement
        if (index > 0) {
          return _.context(
            Context.actions.updateSelectedVehicleVin,
            Some(context.vehicles[index - 1].vin),
          );
        } else {
          return dispatch;
        }
      } else {
        return dispatch;
      }
    })
    .getOrElse(() => dispatch);
}

function NoData(): React.ReactElement {
  const {
    views: { View, Image },
  } = useContextStore();

  return (
    <>
      <View
        style={{
          height: Percentage(100),
          width: Percentage(100),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            width: Length.px(180),
            height: Length.px(120),
          }}
          src={Icons.img_vehicle_default}
        />
      </View>
      <LineSpace height={Length.px(12)} />
    </>
  );
}

function Loading(): React.ReactElement {
  const {
    views: { View },
  } = useContextStore();
  const Loading = View;

  return (
    <Loading
      style={{
        position: 'absolute',
        top: Length.px(0),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: Length.px(carSelectorHeight),
        width: Percentage(100),
      }}
    >
      <View
        style={{
          height: Length.px(32),
        }}
      >
        <IcWaitingRed style={{ height: Percentage(100) }} animate />
      </View>
    </Loading>
  );
}

function CarItem({
  parent,
  vehicle,
  onImageLoaded,
}: {
  parent: DataMapStore<DM>;
  vehicle: Vehicle;
  onImageLoaded: () => DP;
}): React.ReactElement {
  const {
    views: { View, Image },
  } = useParentStore({ parent });
  const [name] = useGetCarNickName(vehicle);

  return (
    <>
      <View
        style={{
          height: Length.px(imageHeight),
        }}
      >
        <Image
          alt={vehicle.vin}
          src={vehicle.image}
          height='100%'
          onLoad={onImageLoaded}
        />
      </View>

      <LineSpace height={Length.px(12)} />

      <View
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: Length.px(20),
          lineHeight: 1.2,
        }}
      >
        {theme(
          <>
            {name}
            <br />
          </>,
          null,
        )}
        {vehicle.attributes.carNo.getOrElse(() => '')}
      </View>
    </>
  );
}

function CarList({
  parent,
  disabled,
}: {
  parent: DataMapStore<DM>;
  disabled: boolean;
}): React.ReactElement {
  const {
    views,
    data: { context, vehicleSelector: data },
    dispatch,
  } = useParentStore({ parent });
  const { View } = views;
  const Slider = views.vehicleSelector.slider;

  const sliderStyle = getSelectedVehicleIndex(context)
    .flatMap((i) => Option(data.items[i]))
    .flatMap((item) =>
      data.container.getBound().flatMap((containerBound) =>
        data.slider.getBound().flatMap((sliderBound) =>
          item.shape.getBound().map((itemBound) => {
            const dx =
              sliderBound.left + containerBound.centerX - itemBound.centerX;
            return {
              transform: data.slider.translate(dx, 0),
              transition: Transition(
                'transform',
                Time.ms(300),
                TimingFunction.easeOut,
              ),
            } as OptionalStyles;
          }),
        ),
      ),
    )
    .getOrElse(() => ({}));

  return (
    <Slider
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        height: Percentage(100),
        padding: Length.px(0),
        border: Border.none,
        backgroundColor: Colors.background,
        ...sliderStyle,
      }}
    >
      {data.items.map((item, index) => {
        const vehicle = context.vehicles[index];
        const isSelectedIndex = getSelectedVehicleIndex(context)
          .map((i) => i === index)
          .getOrElse(() => false);

        return (
          <View
            key={index}
            ref={item.shape.getRef()}
            style={{
              display: 'inline-block',
              flexShrink: 0,
              marginLeft: Length.px(index !== 0 ? gap : 0),
              opacity: disabled ? 0.3 : 1.0,
            }}
            onClick={() =>
              disabled
                ? dispatch
                : Pipe(
                    dispatch.context.bind(
                      Context.actions.updateSelectedVehicleVin,
                      Some(vehicle.vin),
                    ),
                  )
                    .map((_) => {
                      if (isSelectedIndex) return _;
                      else
                        return _.context.bind(
                          Context.actions.updateContentState,
                          'Load',
                        );
                    })
                    .get()
            }
            onTouchStart={(evt) =>
              disabled
                ? dispatch
                : dispatch.vehicleSelector(
                    actions.onTouchStart,
                    toTouchVector(evt),
                  )
            }
            onTouchMove={(evt) =>
              disabled
                ? dispatch
                : dispatch.vehicleSelector(
                    actions.onTouchMove,
                    toTouchVector(evt),
                  )
            }
            onTouchEnd={() => {
              if (disabled) {
                return dispatch;
              }
              const dxy = fromSwipe(
                data,
                Swipe.actions.onTouchEnd(toSwipe(data)),
              ).localState.swipe.dxy;
              return dispatchSelectedIndex(dxy, context, dispatch);
            }}
          >
            <CarItem
              parent={parent}
              vehicle={vehicle}
              onImageLoaded={() =>
                dispatch.vehicleSelector(actions.render, Unit)
              }
            />
          </View>
        );
      })}
    </Slider>
  );
}

const actions: Actions = {
  updateNumberOfCars: (data: Data, numberOfCars: number) =>
    copy(data, {
      items: Range(0, numberOfCars - 1).map((_i) => ({
        shape: Shape({}),
      })),
    }),

  render: (data: Data) => data,

  onTouchStart: (data: Data, touch: Vector) =>
    fromSwipe(data, Swipe.actions.onTouchStart(toSwipe(data), touch)),

  onTouchMove: (data: Data, touch: Vector) =>
    fromSwipe(data, Swipe.actions.onTouchMove(toSwipe(data), touch)),
};

export function VehicleSelector(props: Props): React.ReactElement {
  const state = useStore<Key, Data, HomeDataMap, Props>({
    key: 'vehicleSelector',
    props,
    parent: { home: props.store },

    default: () => ({
      localState: {
        swipe: Swipe().localState,
      },
      container: Shape({}),
      slider: Shape({}),
      items: [],
    }),

    update: ({ data: { context, vehicleSelector: data }, dispatch }) => {
      return dispatch.pipe((_) => {
        if (context.vehicles.length !== data.items.length) {
          return _.vehicleSelector(
            actions.updateNumberOfCars,
            context.vehicles.length,
          );
        } else {
          return _;
        }
      });
    },
  });

  const { View } = state.views;
  const Container = state.views.vehicleSelector.container;
  const data = state.data.vehicleSelector;
  const context = state.data.context;
  const viewPort = context.viewPort;

  // Count number of events for unselected vehicles
  const [left, right] = context.selectedVehicleVin
    .map((vin) => {
      const [left, right] = context.userLastVehicleEvents.reduce<
        [number, number, boolean]
      >(
        ([left, right, isRight], e) => {
          const n = Option(
            context.otherServiceTelemetries.find((r) => r.vin === e.vin),
          )
            .map((r) =>
              r.data
                .map(
                  (s) =>
                    [s.en_theft_trk, s.dsc_eng_prohibit].filter((_) => _)
                      .length + e.events.length,
                )
                .getOrElse((err) => {
                  println(err);
                  return 0;
                }),
            )
            .getOrElse(() => 0);
          if (e.vin === vin) return [left, right, true];
          else if (isRight) return [left, right + n, isRight];
          else return [left + n, right, isRight];
        },
        [0, 0, false],
      );
      return [left, right];
    })
    .getOrElse(() => [0, 0]);

  return (
    <Container
      style={{
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: Colors.background,
      }}
    >
      {Scope(() => {
        const Badges = (
          <View
            style={{
              position: 'absolute',
              paddingLeft: Length.px(8),
              paddingRight: Length.px(8),
              display: 'flex',
              justifyContent: 'space-between',
              top: Length.px(imageHeight - badgeHeight),
              width: Length.px(viewPort.width),
            }}
          >
            {left > 0 ? <Badge nEvents={left} arrowPos='Left' /> : <View />}

            {right > 0 ? <Badge nEvents={right} arrowPos='Right' /> : <View />}
          </View>
        );

        const LoadedView = (
          <>
            <CarList
              parent={state.stores}
              disabled={isContentLoading(context)}
            />

            {Badges}
          </>
        );

        const NoDataView = <NoData />;

        const LoadingView = <Loading />;

        const isRemoved = data.items.length !== context.vehicles.length;

        switch (context.contentState) {
          case 'Loading': //return LoadingView;
          case 'Updating':
            if (isRemoved) return LoadingView;
            else if (shouldShowNetworkErrorOnHome(context)) {
              return <>{NoDataView}</>;
            } else {
              return (
                <>
                  {LoadedView}
                  {LoadingView}
                </>
              );
            }
          case 'Reload':
          case 'Loaded':
            if (context.dataErrors.vehicleList) return NoDataView;
            else if (isRemoved) return LoadingView;
            else return LoadedView;
          default:
            return LoadingView;
        }
      })}
    </Container>
  );
}
