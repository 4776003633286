import * as Client from '@auto/monaka-client/dist/20';
import { getDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { getErrorMessage } from '../util/Error';
import { Option } from '../util/Option';
import { sleep } from '../util/Sleep';
import { AppSyncClient } from './Client';

export type WifiStatus = {
  readonly vin: string;
  readonly wifiRequest: boolean;
  readonly wifiStatus: boolean;
  readonly currentSSID: string;
  readonly initialPassword: string;
  readonly initialAdminPassword: string;
  readonly igStatus: boolean;
  readonly wifiDataUsage: number;
  readonly wifiLimitDataUsage: number;
};

export function defaultWifiStatus(vin: string): WifiStatus {
  return {
    vin,
    wifiRequest: false,
    wifiStatus: false,
    currentSSID: 'Dummy SSID',
    initialPassword: 'Dummy Password',
    initialAdminPassword: 'Dummy Admin Password',
    igStatus: false,
    wifiDataUsage: 0,
    wifiLimitDataUsage: 0,
  };
}

export function errorWifiStatus(vin: string): WifiStatus {
  return {
    vin,
    wifiRequest: false,
    wifiStatus: false,
    currentSSID: '-',
    initialPassword: '-',
    initialAdminPassword: '-',
    igStatus: false,
    wifiDataUsage: 0,
    wifiLimitDataUsage: 0,
  };
}

export async function getWifiStatus(vin: string): Promise<WifiStatus> {
  if (BuildOpt.isMock()) {
    await sleep(500);
    const db = await getDB();
    const error = () =>
      Promise.reject(Error(`Failed to get wifi status(vin=${vin}).`));
    if (vin === 'vin-1' && db.wifiStatusErrorVin1) return error();
    else
      return Option(db.wifiStatuses.find((s) => s.vin === vin)).unwrap(
        (s) => Promise.resolve(s),
        () => error(),
      );
  } else {
    const gSysUserId = Option(await Client.Auth.currentDriver())
      .map((_) => _.gSysUserId)
      .getOrElse(() => 'unknown');
    return AppSyncClient.getWiFiStatus(gSysUserId, vin)
      .then((a) => ({
        ...a,
        vin,
      }))
      .catch((err) =>
        Promise.reject(
          Error(
            `Error occurred on getWiFiStatus(vin=${vin}). ${getErrorMessage(
              err,
            )}`,
          ),
        ),
      );
  }
}
