import React from 'react';
import { Intl, Message } from '../i18n/Intl';
import { ContentState } from '../states/Context';
import { useContextStore } from '../states/ContextStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

type Props = Readonly<{
  lastUpdate?: Date | Message;
  intl: Intl;
  contentState: ContentState;
  ongoingState: boolean;
}>;

export function LastUpdateDate(props: Props): React.ReactElement {
  const {
    views: { View },
  } = useContextStore();

  const lastUpdate = props.lastUpdate;
  const intl = props.intl;
  const contentState = props.contentState;
  const isSpinning =
    contentState === 'Loading' ||
    contentState === 'Updating' ||
    props.ongoingState;

  return lastUpdate == null ? (
    <View />
  ) : (
    <View
      key='lastUpdate'
      style={{
        display: 'flex',
        justifyContent: 'center',
        color: Colors.textLight,
        fontSize: Length.px(12),
        whiteSpace: 'nowrap',
      }}
    >
      {isSpinning ? (
        <View>
          {intl.formatMessage({ id: 'Common_onUpdating' }).toReactNode()}
        </View>
      ) : lastUpdate instanceof Date ? (
        <View>
          <View style={{ display: 'inline-block' }}>
            {intl.formatMessage({ id: 'Home_lastUpdated' }).toReactNode()}
          </View>
          <View style={{ display: 'inline-block' }}>
            {intl.formatDate(lastUpdate, {
              month: 'numeric',
              day: 'numeric',
            })}{' '}
            {intl.formatTime(lastUpdate)}
          </View>
        </View>
      ) : (
        <View>{lastUpdate.toReactNode()}</View>
      )}
    </View>
  );
}
