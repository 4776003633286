import React from 'react';
import { Scope } from '../../util/Scope';
import { MessageKeys, useIntl } from '../i18n/Intl';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { ControllerState, RemoteState } from './Types';
import { VehicleInfoCardDataMap } from './VehicleInfoCard';

type DM = VehicleInfoCardDataMap;
type DP = DataMapDispatch<WithContext<DM>>;

type InnerProps = Readonly<{
  parent: DataMapStore<DM>;
  isLoading: boolean;
  isBottom?: boolean;
  remoteState: RemoteState;
  showArrow: 'Always' | 'OnActive' | 'None';
  iconNormal: string;
  iconError: string;
  titleId: MessageKeys;
  messageNormalId: MessageKeys;
  messageToErrorId: MessageKeys;
  messageErrorId: MessageKeys;
  messageToNormalId: MessageKeys;
  onClick?: (evt: React.MouseEvent) => DP;
}>;

type Props = Readonly<{
  parent: DataMapStore<DM>;
  isLoading: boolean;
  remoteState: RemoteState;
  onClick?: (evt: React.MouseEvent, controllerState: ControllerState) => DP;
}>;

const styles = Scope(() => {
  const listItemRightRaw: OptionalStyles = {
    paddingLeft: Length.px(10),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: Percentage(100),
  };

  const titleRaw: OptionalStyles = {
    display: 'inline-block',
    fontSize: Length.px(12),
    fontWeight: 'bold',
    lineHeight: Length.px(12),
    padding: Length.px(2),
  };

  const messageRaw: OptionalStyles = {
    display: 'inline-block',
    fontSize: Length.px(16),
    lineHeight: Length.px(16),
    padding: Length.px(2),
  };

  return {
    remoteItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexGrow: 1,
    } as OptionalStyles,

    listItemLeft: {
      display: 'flex',
      alignItems: 'center',
    } as OptionalStyles,

    listItemBottomRight: listItemRightRaw,

    listItemRight: {
      borderBottom: Border('solid', Length.px(1), Colors.border),
      ...listItemRightRaw,
    } as OptionalStyles,

    titleNormal: {
      ...titleRaw,
      color: Colors.text,
    } as OptionalStyles,

    titleError: {
      ...titleRaw,
      color: Colors.errorText,
    } as OptionalStyles,

    messageNormal: {
      ...messageRaw,
      color: Colors.text,
    } as OptionalStyles,

    messageError: {
      ...messageRaw,
      color: Colors.errorHighlightText,
      fontWeight: 'bold',
      backgroundColor: Colors.errorHighlightBackground,
      borderRadius: Length.px(2),
    } as OptionalStyles,

    messageLoading: {
      ...messageRaw,
      color: Colors.errorText,
      fontWeight: 'bold',
    } as OptionalStyles,

    arrowStyle: {
      display: 'flex',
      alignItems: 'center',
      marginRight: Length.px(8),
    } as OptionalStyles,

    arrowStyleLoading: {
      display: 'flex',
      alignItems: 'center',
      marginRight: Length.px(8),
      opacity: 0.25,
    } as OptionalStyles,
  };
});

export function RemoteStateItem(props: InnerProps): React.ReactElement {
  const {
    views: { View, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();

  const title = intl.formatMessage({ id: props.titleId });
  const icon =
    props.remoteState === 'Active' || props.remoteState === 'ToNormal'
      ? props.iconError
      : props.iconNormal;

  const message = intl.formatMessage({
    id: Scope(() => {
      switch (props.remoteState) {
        case 'Active':
          return props.messageErrorId;
        case 'Normal':
          return props.messageNormalId;
        case 'ToActive':
          return props.messageToErrorId;
        case 'ToNormal':
          return props.messageToNormalId;
      }
    }),
  });

  const onClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    if (!props.isLoading && props.onClick) {
      return props.onClick(evt);
    } else {
      return dispatch;
    }
  };

  const arrowIcon = Scope(() => {
    if (props.remoteState === 'ToNormal' || props.remoteState === 'ToActive') {
      return (
        <View style={styles.arrowStyle}>
          <IcWaitingRed animate />
        </View>
      );
    } else if (
      props.showArrow === 'Always' ||
      (props.remoteState === 'Active' && props.showArrow === 'OnActive')
    ) {
      return (
        <View
          style={props.isLoading ? styles.arrowStyleLoading : styles.arrowStyle}
        >
          <Image alt='ic_action' src={Icons.ic_action} />
        </View>
      );
    } else {
      return <View />;
    }
  });

  return (
    <View style={styles.remoteItem} onClick={onClick}>
      <View style={styles.listItemLeft}>
        <img alt={title.toString()} src={icon} />
      </View>
      <View
        style={
          props.isBottom === true
            ? styles.listItemBottomRight
            : styles.listItemRight
        }
      >
        <View>
          <View>
            <View
              style={
                props.remoteState === 'Normal'
                  ? styles.titleNormal
                  : styles.titleError
              }
            >
              {title.toReactNode()}
            </View>
          </View>
          <View>
            <View
              style={
                props.remoteState === 'Normal'
                  ? styles.messageNormal
                  : props.remoteState === 'Active'
                  ? styles.messageError
                  : styles.messageLoading
              }
            >
              {message.toReactNode()}
            </View>
          </View>
        </View>
        {arrowIcon}
      </View>
    </View>
  );
}

export function DoorLockItem(props: Props): React.ReactElement {
  const { dispatch } = useParentStore({ parent: props.parent });
  const { onClick, ..._props } = props;
  return (
    <RemoteStateItem
      iconNormal={Icons.ic_lock_close_normal}
      iconError={Icons.ic_lock_open_active}
      titleId='VehicleInfo_doorLock'
      messageNormalId='VehicleInfo_locked'
      messageErrorId='VehicleInfo_unLocked'
      messageToErrorId='VehicleInfo_locked'
      messageToNormalId='VehicleInfo_unLocked'
      showArrow='OnActive'
      onClick={(evt) => {
        if (onClick && props.remoteState === 'Active') {
          return onClick(evt, {
            state: 'Show',
            itemName: 'DoorLock',
          });
        } else {
          return dispatch;
        }
      }}
      {..._props}
    />
  );
}

export function LightItem(props: Props): React.ReactElement {
  const { dispatch } = useParentStore({ parent: props.parent });
  const { onClick, ..._props } = props;
  return (
    <RemoteStateItem
      iconNormal={Icons.ic_light_normal}
      iconError={Icons.ic_light_active}
      titleId='VehicleInfo_light'
      messageNormalId='VehicleInfo_lightOff'
      messageErrorId='VehicleInfo_lightOn'
      messageToErrorId='VehicleInfo_lightOff'
      messageToNormalId='VehicleInfo_lightOn'
      showArrow='Always'
      onClick={(evt) => {
        if (onClick) {
          return onClick(evt, {
            itemName: 'Light',
            state: 'Show',
          });
        } else {
          return dispatch;
        }
      }}
      {..._props}
    />
  );
}

export function HazardWarningItem(props: Props): React.ReactElement {
  const { dispatch } = useParentStore({ parent: props.parent });
  const { onClick, ..._props } = props;
  return (
    <RemoteStateItem
      iconNormal={Icons.ic_hazard_normal}
      iconError={Icons.ic_hazard_active}
      titleId='VehicleInfo_hazardLight'
      messageNormalId='VehicleInfo_hazardOff'
      messageErrorId='VehicleInfo_hazardOn'
      messageToErrorId='VehicleInfo_hazardOff'
      messageToNormalId='VehicleInfo_hazardOn'
      showArrow='Always'
      onClick={(evt) => {
        if (onClick) {
          return onClick(evt, {
            itemName: 'Hazard',
            state: 'Show',
          });
        } else {
          return dispatch;
        }
      }}
      {..._props}
    />
  );
}

export function AirConditionerItem(props: Props): React.ReactElement {
  const { dispatch } = useParentStore({ parent: props.parent });
  const { onClick, ..._props } = props;
  return (
    <RemoteStateItem
      iconNormal={Icons.ic_ac_normal}
      iconError={Icons.ic_ac_active}
      titleId='VehicleInfo_airCon'
      messageNormalId='VehicleInfo_airConOff'
      messageErrorId='VehicleInfo_airConOn'
      messageToErrorId='VehicleInfo_airConOff'
      messageToNormalId='VehicleInfo_airConOn'
      showArrow='Always'
      isBottom
      onClick={(evt) => {
        if (onClick) {
          return onClick(evt, {
            itemName: 'Ac',
            state: 'Show',
          });
        } else {
          return dispatch;
        }
      }}
      {..._props}
    />
  );
}

export function EngineItem(props: Props): React.ReactElement {
  const { dispatch } = useParentStore({ parent: props.parent });
  const { onClick, ..._props } = props;
  return (
    <RemoteStateItem
      iconNormal={Icons.ic_engine_normal}
      iconError={Icons.ic_engine_active}
      titleId='VehicleInfo_engine'
      messageNormalId='VehicleInfo_engineOff'
      messageErrorId='VehicleInfo_engineOn'
      messageToErrorId='VehicleInfo_engineOff'
      messageToNormalId='VehicleInfo_engineOn'
      showArrow='Always'
      isBottom
      onClick={(evt) => {
        if (onClick) {
          return onClick(evt, {
            itemName: 'Engine',
            state: 'Show',
          });
        } else {
          return dispatch;
        }
      }}
      {..._props}
    />
  );
}
