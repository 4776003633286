import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';

export const cardBadgeHeight = Length.px(32);

export const CardBadgeStyle: OptionalStyles = {
  position: 'absolute',
  top: Length.px(0),
  left: Length.px(0),
  display: 'inline-block',
  paddingTop: Length.px(8),
  paddingBottom: Length.px(8),
  paddingLeft: Length.px(10),
  paddingRight: Length.px(10),
  backgroundColor: Colors.cardBadgeBackground,
  color: Colors.cardBadgeForeground,
  fontWeight: 'bold',
  fontSize: Length.px(16),
  lineHeight: Length.px(16),
  borderTopLeftRadius: Length.px(12),
  borderBottomRightRadius: Length.px(12),
  overflow: 'hidden',
};
