import { println } from '../../apis/NativeApi';
import { Anomaly } from '../../util/Error';
import { Listener } from '../../util/Listener';
import { Failure, Success } from '../../util/Result';
import { Unit } from '../../util/Unit';
import { systemErrorDialogAction } from '../components/AlertDialog';
import { Context } from './Context';
import { DispatchableDataMapState } from './DataMapStore';
import {
  DispatchBase,
  KindKey,
  PrivateDispatchBase,
  dispatchMethodBaseHelper,
} from './DispatchBase';
import { ComposableItem } from './DispatchItem';
import { DispatchableStore } from './Store';

export type AsyncAllCallback<R> = Listener<R> | Promise<R>;

export interface DispatchAsyncAllItem<DataMap, R>
  extends ComposableItem<DispatchAsyncAllItem<DataMap, R>> {
  readonly dispatch: (state: DispatchableDataMapState<DataMap>) => void;

  readonly flatMap: (
    f: (a: AsyncAllCallback<R>) => DispatchAsyncAllItem<DataMap, R>,
  ) => DispatchAsyncAllItem<DataMap, R>;

  readonly async: (f: AsyncAllCallback<R>) => DispatchAsyncAllItem<DataMap, R>;
}

function dispatchError(err: Anomaly) {
  println(err);
  Context.store.forEach((store) => {
    const _store = store as DispatchableStore<Context>;
    _store.dispatchAsync(
      systemErrorDialogAction('System error on an async process'),
      Unit,
    );
  });
}

function handleCallback<R>(
  f: AsyncAllCallback<R>,
  onSuccess: (a: R) => void,
  onFailure: (err: Anomaly) => void,
): void {
  if (f instanceof Promise) {
    f.then((_) => onSuccess(_)).catch((_) => onFailure(_));
  } else {
    f.onResult(
      (_) => onSuccess(_),
      (_) => onFailure(_),
    );
  }
}

function dispatch<
  Kind extends KindKey,
  DataMap,
  R extends DispatchBase<Kind, DataMap>,
>(state: DispatchableDataMapState<DataMap>, dispatch: R): void {
  const items = (dispatch as unknown as PrivateDispatchBase<Kind, DataMap>)
    .items;
  dispatchMethodBaseHelper(
    items,
    state,
    (_key, item, store) => {
      item.state.forEach((a) => a.dispatchAsync(store));
      item.event.forEach((a) => a.dispatch(store));
    },
    (item, state) => item.dispatch(state),
  );
}

function bind<
  Kind extends KindKey,
  DataMap,
  R extends DispatchBase<Kind, DataMap>,
>(
  f1: AsyncAllCallback<R>,
  f2: AsyncAllCallback<R>,
): DispatchAsyncAllItem<DataMap, R> {
  return createDispatchAsyncAllItem<Kind, DataMap, R>(
    Listener((emit) => {
      handleCallback(
        f1,
        (a) => emit(Success(a)),
        (e) => emit(Failure(e)),
      );
      handleCallback(
        f2,
        (a) => emit(Success(a)),
        (e) => emit(Failure(e)),
      );
    }),
  );
}

export function createDispatchAsyncAllItem<
  Kind extends KindKey,
  DataMap,
  R extends DispatchBase<Kind, DataMap>,
>(async: AsyncAllCallback<R>): DispatchAsyncAllItem<DataMap, R> {
  /* Usage
    Dispatch.async(emit => {
      emit(Dispatch()
        .bind(...)
        .context.bind(...)
      )
    })
   */
  type Unsafe = DispatchAsyncAllItem<DataMap, R> & {
    readonly __async: AsyncAllCallback<R>;
  };

  const unsafe: Unsafe = {
    __async: async,

    dispatch: (state: DispatchableDataMapState<DataMap>) => {
      handleCallback(
        async,
        (_) => dispatch(state, _),
        (_) => dispatchError(_),
      );
    },

    flatMap: (
      f: (a: AsyncAllCallback<R>) => DispatchAsyncAllItem<DataMap, R>,
    ) => f(async),

    async: (f: AsyncAllCallback<R>) => bind(async, f),

    compose: (a: DispatchAsyncAllItem<DataMap, R>) =>
      a.flatMap((f) => bind(async, f)),
  };

  return unsafe;
}
