import React from 'react';
import { openLink } from '../../apis/NativeApi';
import { useContextStore } from '../states/ContextStore';
import { Length } from '../style/Length';

export function TermsOverlay({
  state,
}: Readonly<{
  state: 'Show' | 'Hide';
}>): React.ReactElement {
  const {
    views: { View },
    dispatch,
  } = useContextStore();

  switch (state) {
    case 'Show':
      return (
        <>
          <View
            style={{
              position: 'absolute',
              bottom: Length.px(0),
              left: Length.px(0),
              width: Length.px(72),
              height: Length.px(25),
              //border: Border('solid', Length.px(1), RGBA.red),
            }}
            onClick={(evt) => {
              evt.stopPropagation();
              openLink('https://www.google.com/intl/en-US_US/help/terms_maps/');
              return dispatch;
            }}
          />

          <View
            style={{
              position: 'absolute',
              bottom: Length.px(0),
              right: Length.px(0),
              width: Length.px(72),
              height: Length.px(13),
              //border: Border('solid', Length.px(1), RGBA.red),
            }}
            onClick={(evt) => {
              evt.stopPropagation();
              openLink('https://www.google.com/intl/en-US_US/help/terms_maps/');
              return dispatch;
            }}
          />
        </>
      );
    case 'Hide':
      return <></>;
  }
}
