import React, { useCallback, useState } from 'react';
import { UserType } from '../../apis/VehicleList';
import {
  RootWindow,
  RootWindowProps,
  WindowStackController,
  useSubWindow,
} from '../components/SlideInWindowStack';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { create } from '../style/Style';
import { Display } from './Display';
import { InviteCodeDisplay } from './InviteCodeDisplay';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  props: RootWindowProps;
  controller: WindowStackController;
  vin: string;
}>;

const styles = create({
  container: {
    width: Percentage(100),
  },
  rowTitle: {
    fontSize: Length.px(16),
    fontWeight: 'bold',
  },
  rowText: {
    fontSize: Length.px(16),
    marginRight: Length.px(16),
  },
  divider: {
    width: Percentage(100),
    borderTop: Border('solid', Length.px(0.5), Colors.border),
  },

  heroImageRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: Length.px(16),
  },
  heroImage: {
    height: Length.px(80),
    width: Length.px(80),
    marginBottom: Length.px(6),
  },
  heroImageText: {
    fontSize: Length.px(16),
  },
  sharedUserRow: {
    display: 'flex',
    height: Length.px(96),
    paddingTop: Length.px(10),
    paddingBottom: Length.px(10),
  },
  sharedUserBody: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectedImage: {
    height: Length.px(40),
    width: Length.px(40),
    minWidth: Length.px(40),
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: Length.px(12),
    marginRight: Length.px(12),
  },
  sharedUserDivider: {
    width: Percentage(100),
    marginLeft: Length.px(64),
    borderTop: Border('solid', Length.px(0.5), Colors.border),
  },
  actionButtonRow: {
    height: Length.px(48),
    width: Percentage(100),
    marginTop: Length.px(20),
    paddingLeft: Length.px(16),
    paddingRight: Length.px(16),
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: Percentage(100),
    width: Percentage(100),
    backgroundColor: Colors.primary,
    borderRadius: Length.px(24),
  },
  actionButtonText: {
    color: Colors.buttonText,
    fontSize: Length.px(17),
    fontWeight: 'bold',
    lineHeight: 1,
  },
});

type SelectUserTypeProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  controller: WindowStackController;
  vin: string;
}>;

const SelectUserType: React.FC<SelectUserTypeProps> = (props) => {
  const {
    views: { View, Text, Image },
    dispatch,
  } = useParentStore({ parent: props.parent });

  const [userType, setUserType] = useState<UserType>('limited');
  const isSameType = useCallback(
    (type: UserType) => userType === type,
    [userType],
  );
  const SelectedImage: React.FC<{ userType: UserType }> = ({ userType }) =>
    isSameType(userType) ? (
      <Image style={styles.selectedImage} src={Icons.ic_checkmark} />
    ) : (
      <View style={styles.selectedImage} />
    );
  const changeUserType = useCallback(
    (type: UserType) => {
      if (isSameType(type)) {
        return undefined;
      }
      return () => {
        setUserType(type);
        return dispatch;
      };
    },
    [isSameType, dispatch],
  );

  const [subProps, controller] = useSubWindow(props.controller);
  const openNextWindow = useCallback(() => {
    controller.showWindow();
    return dispatch;
  }, [controller, dispatch]);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.heroImageRow}>
          <Image style={styles.heroImage} src={Icons.ic_owner} />
          <Text style={styles.heroImageText}>
            <Display id='myCarManagement_screen1Message' />
          </Text>
        </View>
      </View>
      <View style={styles.divider} />

      <View style={styles.container}>
        <View style={styles.sharedUserRow} onClick={changeUserType('all')}>
          <SelectedImage userType='all' />
          <View style={styles.sharedUserBody}>
            <Text style={styles.rowTitle}>
              <Display id='myCarManagement_screen1Select1Title' />
            </Text>
            <Text style={styles.rowText}>
              <Display id='myCarManagement_screen1Select1Message' />
            </Text>
          </View>
        </View>
        <View style={styles.sharedUserDivider} />
        <View style={styles.sharedUserRow} onClick={changeUserType('limited')}>
          <SelectedImage userType='limited' />
          <View style={styles.sharedUserBody}>
            <Text style={styles.rowTitle}>
              <Display id='myCarManagement_screen1Select2Title' />
            </Text>
            <Text style={styles.rowText}>
              <Display id='myCarManagement_screen1Select2Message' />
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.divider} />

      <View style={styles.container}>
        <View style={styles.actionButtonRow}>
          <View style={styles.actionButton} onClick={openNextWindow}>
            <Text style={styles.actionButtonText}>
              <Display id='myCarManagement_screen1Next' />
            </Text>
          </View>
        </View>
      </View>

      <InviteCodeDisplay
        props={subProps}
        controller={controller}
        vin={props.vin}
        userType={userType}
      />
    </>
  );
};

export const InviteSharedUser: React.FC<Props> = (props) => {
  const intl = useIntl();
  return (
    <RootWindow
      {...props.props}
      title={intl.formatMessage({ id: 'myCarManagement_pageTitleAddUser' })}
    >
      <SelectUserType
        parent={props.parent}
        controller={props.controller}
        vin={props.vin}
      />
    </RootWindow>
  );
};
