import React from 'react';
import { config } from '../../Config';
import { EmptyVehicleEvent, VehicleEvent } from '../../apis/EventTelemetry';
import { println } from '../../apis/NativeApi';
import { copy } from '../../util/Copyable';
import { None, Option, Some } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { commonErrorDialogAction } from '../components/AlertDialog';
import { Card, CardState } from '../components/Card';
import { EngineController } from '../components/EngineController';
import { Overlay } from '../components/Overlay';
import { SlideInCardState } from '../components/SlideInCard';
import { HomeData, HomeDataMap } from '../home/Home';
import { EventIdMap } from '../i18n/EventIdMap';
import { Message, MessageKeys, createIntl, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { Action } from '../states/Reducer';
import { Store } from '../states/Store';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { DetailedWindow } from './DetailedWindow';
import { getSelectedVehicleEvent } from './Helpers';

export type VehicleAlertCardData = Data;

type Key = 'vehicleAlert';

type Data = Readonly<{
  localState: LocalState;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly cardState: CardState;
  readonly controllerState: SlideInCardState;
  readonly isControllerSpinning: boolean;
}

interface Actions extends CommandActions<Data, Command> {
  readonly updateCardState: (data: Data, cardState: CardState) => Data;
  readonly updateControllerState: (
    data: Data,
    controllerState: SlideInCardState,
  ) => Data;
  readonly updateControllerSpinning: (
    data: Data,
    isControllerSpinning: boolean,
  ) => Data;
}

type Command = 'None';

type Props = Readonly<{
  store: Store<HomeData>;
}>;

type DM = ExtendParent<HomeDataMap, Key, Data>;
type DMC = WithContext<DM>;
type DP = DataMapDispatch<DMC>;

const commandState = CommandState<Data, Command>();

function ClosedView({
  nEvents,
}: Readonly<{
  nEvents: number;
}>): React.ReactElement {
  const {
    views: { View, Text },
    data: { context },
  } = useContextStore();
  const intl = useIntl();

  const vehicleEvents = context.vehicleEvents;

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.background,
        paddingTop: theme(Length.px(44), Length.px(40)),
        paddingBottom: Length.px(16),
        paddingLeft: Length.px(8),
        paddingRight: Length.px(8),
      }}
    >
      <Text
        style={{
          color: Colors.vehicleAlertForeground,
          backgroundColor: Colors.vehicleAlertBackground,
          borderRadius: Length.px(12),
          paddingTop: Length.px(3),
          paddingBottom: Length.px(2),
          paddingLeft: Length.px(20),
          paddingRight: Length.px(20),
          height: Length.px(24),
          fontSize: Length.px(16),
          lineHeight: Length.px(16),
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {Scope(() => {
          if (nEvents === 0) {
            return intl.formatMessage({ id: 'Home_noMessage' });
          } else if (nEvents === 1) {
            return intl.formatMessage({
              id: EventIdMap.get(vehicleEvents[0].eventId).home,
            });
          } else {
            return intl.formatMessage(
              { id: 'Home_newMessages' },
              { count: nEvents },
            );
          }
        }).toReactNode()}
      </Text>
    </View>
  );
}

function OpenedView({
  parent,
}: Readonly<{ parent: DataMapStore<DM> }>): React.ReactElement {
  const {
    views: { View },
    data: { context },
  } = useParentStore({ parent });
  const intl = useIntl();
  return (
    <View>
      {context.vehicleEvents.map((e, i) => {
        const title = intl.formatMessage({
          id: EventIdMap.get(e.eventId).alert.title,
        });
        if (title.toString().trim() === '-') return [];
        else
          return [
            <ListItem
              parent={parent}
              key={i.toString()}
              vehicleEvent={e}
              title={title}
            />,
          ];
      })}
    </View>
  );
}

function ListItem({
  parent,
  vehicleEvent,
  title,
}: Readonly<{
  parent: DataMapStore<DM>;
  key: string;
  vehicleEvent: VehicleEvent;
  title: Message;
}>): React.ReactElement {
  const {
    views: { View, Text, Image },
    dispatch,
  } = useParentStore({ parent });
  const intl = useIntl();

  const image = EventIdMap.get(vehicleEvent.eventId).image.normal;

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        return dispatch.context.bind(
          Context.actions.updateSelectedVehicleEvent,
          {
            selectedVehicleEvent: vehicleEvent,
            vehicleEventWindowMode: 'Show',
          },
        );
      }}
    >
      <View
        style={{
          flexGrow: 0,
          paddingLeft: Length.px(12),
          paddingRight: Length.px(12),
        }}
      >
        <Image
          src={image}
          style={{
            width: Length.px(40),
            height: Length.px(40),
          }}
        />
      </View>

      <View
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: Length.px(14),
          paddingBottom: Length.px(18),
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
      >
        <View>
          <Text
            style={{
              fontSize: Length.px(12),
              lineHeight: Length.px(12),
              fontWeight: 'bold',
            }}
          >
            {intl.formatDate(new Date(vehicleEvent.timestamp))}{' '}
            {intl.formatTime(new Date(vehicleEvent.timestamp))}
          </Text>

          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
            }}
          >
            {title.toReactNode()}
          </Text>
        </View>

        <View
          style={{
            paddingRight: Length.px(4),
          }}
        >
          <Image
            src={Icons.ic_forward_normal}
            style={{
              width: Length.px(24),
              height: Length.px(24),
            }}
          />
        </View>
      </View>
    </View>
  );
}

export const VehicleAlertCardActions: Actions = {
  ...commandState.actions,
  updateCardState: (data: Data, cardState: CardState) =>
    copy(data, {
      localState: {
        cardState,
      },
    }),

  updateControllerState: (data: Data, controllerState: SlideInCardState) =>
    copy(data, {
      localState: {
        controllerState,
      },
    }),

  updateControllerSpinning: (data: Data, isControllerSpinning: boolean) =>
    copy(data, {
      localState: {
        isControllerSpinning,
      },
    }),
};

const actions = VehicleAlertCardActions;

export function VehicleAlertCard(props: Props): React.ReactElement {
  const state = useStore<Key, Data, HomeDataMap, Props>({
    key: 'vehicleAlert',
    props,
    parent: { home: props.store },

    default: () => ({
      localState: {
        command: commandState.default,
        cardState: 'Closed',
        controllerState: 'Hide',
        isControllerSpinning: false,
        mapData: None(),
      },
    }),

    update: ({ data: { context, vehicleAlert: data }, dispatch }) => {
      const localState = data.localState;
      const intl = createIntl(context);
      return dispatch
        .compose(
          commandState.handleDataMap<DMC>(
            'vehicleAlert',
            data,
            dispatch,
            (_command) => dispatch,
          ),
        )
        .pipe((_) => {
          return Option.fromBoolean(context.contentState === 'Loaded')
            .flatMap(() => context.selectedVehicleEvent)
            .flatMap<EmptyVehicleEvent>((_) =>
              _.kind === 'EmptyVehicleEvent' ? Some(_) : None(),
            )
            .map((empty) => {
              return context.vehicleEvents
                .reduce<Option<VehicleEvent>>(
                  (_, e) =>
                    _.unwrap(
                      (a) => {
                        const min = Math.abs(
                          empty.timestamp.getTime() - a.timestamp.getTime(),
                        );
                        const d = Math.abs(
                          empty.timestamp.getTime() - e.timestamp.getTime(),
                        );
                        if (e.eventId === empty.eventId && d < min)
                          return Some(e);
                        else return Some(a);
                      },
                      () => (e.eventId === empty.eventId ? Some(e) : None()),
                    ),
                  None(),
                )
                .unwrap<DP>(
                  (e) =>
                    _.context.bind(Context.actions.updateSelectedVehicleEvent, {
                      selectedVehicleEvent: e,
                      vehicleEventWindowMode: 'Show',
                    }),
                  () => {
                    println(
                      Error(
                        `Could not find a vehicle event matched with eventId=${
                          empty.eventId
                        } in [${context.vehicleEvents
                          .map((_) => _.eventId)
                          .join(',')}]`,
                      ),
                    );
                    return _.context(
                      Context.actions.updateSelectedVehicleEvent,
                      { vehicleEventWindowMode: 'Hide' },
                    ).context(commonErrorDialogAction(intl));
                  },
                );
            })
            .getOrElse(() => _);
        })
        .pipe((_) => {
          if (
            context.nativeBackAction === 'InCard' &&
            localState.cardState === 'Opened'
          ) {
            const none = _.context.bind(
              Context.actions.updateNativeBackAction,
              'None',
            );
            if (
              context.alertDialog.state === 'Hide' &&
              context.confirmDialog.state === 'Hide'
            ) {
              if (localState.controllerState === 'Show') {
                return none.vehicleAlert(actions.updateControllerState, 'Hide');
              } else if (context.vehicleEventWindowMode === 'Show') {
                return none.context.bind(
                  Context.actions.updateSelectedVehicleEvent,
                  { vehicleEventWindowMode: 'Hide' },
                );
              } else {
                return _.context.bind(
                  Context.actions.updateNativeBackAction,
                  'TopLevel',
                );
              }
            } else {
              return none;
            }
          } else {
            return _;
          }
        });
    },
  });

  const { View, Text } = state.views;
  const intl = useIntl();
  const data = state.data.vehicleAlert;
  const context = state.data.context;
  const localState = data.localState;
  const cardState = localState.cardState;
  const isDataError =
    context.dataErrors.serviceTelemetry || context.dataErrors.vehicleEvents;
  const isLoaded =
    (context.contentState === 'Loaded' ||
      context.contentState === 'Reload' ||
      context.contentState === 'Updating') &&
    !context.dataErrors.vehicleEvents;

  const selectedVehicleEvent = getSelectedVehicleEvent(context);
  const nEvents = context.vehicleEvents.filter((e) => {
    const title = intl.formatMessage({
      id: EventIdMap.get(e.eventId).alert.title,
    });
    return title.toString().trim() !== '-';
  }).length;

  return (
    <Card
      id='VehicleAlertCard'
      cardName='VehicleAlert'
      parent={state.stores}
      badgeName={intl.formatMessage({ id: 'VehicleAlert_pageTitle' })}
      title={intl.formatMessage({ id: 'VehicleAlert_pageTitle' })}
      reloadable={false}
      reloadOnOpen={config.fetchDataOptions.onOpenVehicleAlert}
      openable={!isDataError && nEvents !== 0}
      contentState={context.contentState}
      overlayState='Hide'
      onOpen={(dispatch) =>
        dispatch.vehicleAlert.bind(actions.updateCardState, 'Opening')
      }
      onOpened={(dispatch) =>
        dispatch.vehicleAlert.bind(actions.updateCardState, 'Opened')
      }
      onClose={(dispatch) =>
        dispatch.vehicleAlert.bind(actions.updateCardState, 'Closing')
      }
      onClosed={(dispatch) =>
        dispatch.vehicleAlert.bind(actions.updateCardState, 'Closed')
      }
      extras={
        <>
          <DetailedWindow
            event={selectedVehicleEvent}
            mode={context.vehicleEventWindowMode}
            parent={state.stores}
          />

          <Overlay state={localState.controllerState} />

          <EngineController
            store={state.stores.vehicleAlert}
            state={localState.controllerState}
            isSpinning={localState.isControllerSpinning}
            closeAction={Action(actions.updateControllerState, 'Hide')}
            toastMessageId={(commandAction) => {
              switch (commandAction) {
                case 'true':
                  return Some<MessageKeys>(
                    'Event_remoteEngineProhibitOnSuccessRemoteControl',
                  );
                case 'false':
                  return Some<MessageKeys>(
                    'Event_remoteEngineProhibitOffSuccessRemoteControl',
                  );
              }
            }}
            updateIsSpinningAction={actions.updateControllerSpinning}
            updateControllerStateAction={actions.updateControllerState}
          />
        </>
      }
    >
      <View
        style={{
          opacity: isLoaded ? 1 : 0,
        }}
      >
        {Scope(() => {
          if (cardState === 'Opened' || cardState === 'Opening') {
            return <OpenedView parent={state.stores} />;
          } else {
            return <ClosedView nEvents={nEvents} />;
          }
        })}
      </View>

      {Scope(() => {
        const EmptyView = <></>;
        const ErrorView = (
          <View
            style={{
              position: 'absolute',
              top: Length.px(0),
              height: Percentage(100),
              width: Percentage(100),
              paddingTop: Length.px(44),
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: Colors.background,
            }}
          >
            <Text
              style={{
                fontSize: Length.px(14),
              }}
            >
              {intl.formatMessage({ id: 'Common_syncError' }).toReactNode()}
            </Text>
          </View>
        );

        switch (context.contentState) {
          case 'Loaded':
            if (isDataError) return ErrorView;
            else return EmptyView;
          default:
            return EmptyView;
        }
      })}
    </Card>
  );
}
