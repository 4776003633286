import { config } from '../../Config';
import { UserLocation, getUserLocation, println } from '../../apis/NativeApi';
import { RemoteCommandExecuterActions } from '../../apis/RemoteCommandExecuter';
import { Listener } from '../../util/Listener';
import { None, Option, Some } from '../../util/Option';
import { Result, Success } from '../../util/Result';
import { Unit } from '../../util/Unit';
import { CardState } from '../components/Card';
import { dialogAction } from '../components/Dialog';
import { State as HelpDialogState } from '../components/HelpDialog';
import { OverlayState } from '../components/Overlay';
import { SlideInCardState } from '../components/SlideInCard';
import { HomeData, HomeDispatch } from '../home/Home';
import { Intl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { getDefaultBounds, getGeoPos } from './MapUtils';
import { State as GeofenceMenuState } from './MenuPane';
import { Actions } from './VehicleLocationCardActions';
import {
  ButtonState,
  GpsState,
  RenderMap,
  TrackingMode,
  VehicleLocationData,
  VehicleLocationState,
} from './VehicleLocationState';

export function getOverlayState(
  helpDialogState: HelpDialogState,
  trackingPaneState: SlideInCardState,
): OverlayState {
  return helpDialogState === 'Show' || trackingPaneState === 'Show'
    ? 'Show'
    : 'Hide';
}

export function getGeofenceMenuState(cardState: CardState): GeofenceMenuState {
  switch (cardState) {
    case 'Closed':
      return 'Hide';
    case 'Closing':
      return 'Hide';
    case 'Opened':
      return 'Show';
    case 'Opening':
      return 'Show';
  }
}

export function getTrackingMode(
  en_theft_trk: boolean,
  ongoing: boolean,
): TrackingMode {
  if (en_theft_trk) {
    if (ongoing) return 'Normalizing';
    else return 'Active';
  } else {
    if (ongoing) return 'Activating';
    else return 'Normal';
  }
}

export function toRemoteCommandExecuterActions(
  actions: Actions,
): RemoteCommandExecuterActions<HomeData> {
  return {
    updateIsSpinning: actions.updateIsTrackingSlideButtonSpinning,
    // updateSubscribe: actions.updateTrackingSubscriber,
    // updateError: actions.updateError,
    // updateCommand: actions.updateCommand,
  };
}

export function getButtonState(
  data: VehicleLocationData,
  context: Context,
): ButtonState {
  return data.localState.mapData
    .flatMap((mapData) =>
      Option.join(
        Option(mapData),
        Option(mapData.googleMap.getBounds()),
        data.container.getBound(),
      ),
    )
    .unwrap(
      ([mapData, mapBounds, containerBound]) => {
        const heightPx = containerBound.height;
        const widthPx = containerBound.width;
        const mapCy = Math.round(heightPx / 2);
        const mapCx = Math.round(widthPx / 2);

        const mapNorth = mapBounds.getNorthEast().lat();
        const mapSouth = mapBounds.getSouthWest().lat();
        const mapWest = mapBounds.getSouthWest().lng();
        const mapEast = mapBounds.getNorthEast().lng();

        return getDefaultBounds(
          data.container,
          getGeoPos(data.localState.userLocation),
          context.serviceTelemetry.data.toOption().flatMap((_) => _.position),
        ).unwrap(
          (defaultBounds) => {
            const defCy = Math.round(
              (heightPx * (defaultBounds.view.getCenter().lat() - mapNorth)) /
                (mapSouth - mapNorth),
            );
            const defCx = Math.round(
              (widthPx * (defaultBounds.view.getCenter().lng() - mapWest)) /
                (mapEast - mapWest),
            );
            return {
              default: !(mapCx === defCx && mapCy === defCy),
            };
          },
          () =>
            context.serviceTelemetry.data
              .toOption()
              .flatMap((_) => _.position)
              .unwrap(
                () =>
                  Option(mapData.vehicle.getPosition()).unwrap(
                    (vehicleCenter) => {
                      const defCy = Math.round(
                        (heightPx * (vehicleCenter.lat() - mapNorth)) /
                          (mapSouth - mapNorth),
                      );
                      const defCx = Math.round(
                        (widthPx * (vehicleCenter.lng() - mapWest)) /
                          (mapEast - mapWest),
                      );
                      return {
                        default: !(
                          Math.abs(mapCx - defCx) < 2 &&
                          Math.abs(mapCy - defCy) < 2
                        ),
                      };
                    },
                    () => ({ default: false }),
                  ),
                () =>
                  getGeoPos(data.localState.userLocation).unwrap(
                    () =>
                      Option(mapData.user.getPosition()).unwrap(
                        (userCenter) => {
                          const defCy = Math.round(
                            (heightPx * (userCenter.lat() - mapNorth)) /
                              (mapSouth - mapNorth),
                          );
                          const defCx = Math.round(
                            (widthPx * (userCenter.lng() - mapWest)) /
                              (mapEast - mapWest),
                          );
                          return {
                            default: !(
                              Math.abs(mapCx - defCx) < 2 &&
                              Math.abs(mapCy - defCy) < 2
                            ),
                          };
                        },
                        () => ({ default: false }),
                      ),
                    () => ({ default: false }),
                  ),
              ),
        );
      },
      () => ({ default: false }),
    );
}

export function getGPSState(
  userLocation: 'Loading' | Result<UserLocation>,
): GpsState {
  if (userLocation !== 'Loading')
    return userLocation.unwrap(
      (l) => (l === 'NoPermission' || l === 'Unavailable' ? l : 'Normal'),
      () => 'Normal',
    );
  else return 'Normal';
}

export function startUserLocation(
  intl: Intl,
  empty: HomeDispatch,
): HomeDispatch {
  println(`Start polling to get the user location`);
  const [stopListener, stopSender] = Listener.withSender<Unit>();
  const actions = VehicleLocationState.actions;

  function recursive(
    dispatch: (a: Result<HomeDispatch>) => void,
    {
      enablePanToDefault,
      disableErrorDialog,
    }: {
      readonly enablePanToDefault?: boolean;
      readonly disableErrorDialog?: boolean;
    } = {},
  ): void {
    getUserLocation()
      .then((loc) => {
        dispatch(
          Success(
            empty
              .pipe((_) => {
                if (loc === 'NoPermission' || loc === 'Unavailable')
                  return _.send(stopSender, Unit);
                else return _;
              })
              .home(
                actions.updateCommand,
                RenderMap({
                  disablePanToDefault: enablePanToDefault !== true,
                  disableAdjustGeofenceZoom: true,
                  isUserLocationPolling: true,
                }),
              )
              .home(actions.updateUserLocation, Success(loc)),
          ),
        );
      })
      .catch((err) => {
        println(`Failed to get the user location. ${err}`);
        if (disableErrorDialog !== true) {
          dispatch(
            Success(
              empty.send(stopSender, Unit).context(
                dialogAction('Event_getGpsError', intl, () => {
                  recursive(dispatch, { disableErrorDialog: true });
                  return empty;
                }),
                Unit,
              ),
            ),
          );
        }
      });
  }

  function onStop(
    id: NodeJS.Timeout,
    dispatch: (a: Result<HomeDispatch>) => void,
  ): void {
    clearInterval(id);
    dispatch(Success(empty.home(actions.updateUserLocationStopper, None())));
  }

  return empty
    .home(actions.updateUserLocationStopper, Some(stopSender))
    .asyncAll(
      Listener<HomeDispatch>((dispatch) => {
        recursive(dispatch, { enablePanToDefault: true });

        const id = setInterval(() => {
          recursive(dispatch);
        }, config.geolocationInterval.toMilliSeconds());

        stopListener.onResult(
          () => onStop(id, dispatch),
          () => onStop(id, dispatch),
        );
      }),
    );
}
