import React from 'react';
import { Table2x2 } from '../components/Table2x2';
import { useIntl } from '../i18n/Intl';
import { useContextStore } from '../states/ContextStore';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';

const closedViewHeight = Length.px(100);

export function ClosedView(): React.ReactElement {
  const {
    views: { View, Text },
    data: { context },
  } = useContextStore();
  const intl = useIntl();

  if (context.dataErrors.wifiStatus)
    return (
      <View
        style={{
          height: closedViewHeight,
          width: Percentage(100),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: Length.px(14),
          }}
        >
          {intl.formatMessage({ id: 'Common_syncError' }).toReactNode()}
        </Text>
      </View>
    );
  else
    return (
      <View
        style={
          {
            //height: closedViewHeight,
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
          }
        }
      >
        <View
          style={{
            paddingTop: Length.px(40),
            paddingLeft: Length.px(8),
            paddingRight: Length.px(8),
            paddingBottom: Length.px(8),
          }}
        >
          <Table2x2
            leftTitle={intl.formatMessage({ id: 'WiFi_wifiBtn' }).toString()}
            leftBody={
              context.wifiStatus.wifiRequest
                ? intl.formatMessage({ id: 'WiFi_wifiOn' }).toString()
                : intl.formatMessage({ id: 'WiFi_wifiOff' }).toString()
            }
            rightTitle={intl.formatMessage({ id: 'WiFi_ssid' }).toString()}
            rightBody={context.wifiStatus.currentSSID}
            centerMargin={8}
          />
        </View>

        {/* <Text
        style={{
          fontWeight: 'bold',
        }}
      >
        T.B.D.
      </Text> */}
      </View>
    );
}
