import React from 'react';
import { Scope } from '../../util/Scope';
import { Message, MessageKeys, createIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { useContextStore } from '../states/ContextStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';

type FuelMileageItemBaseProps = Readonly<{
  icon: string;
  titleId1: MessageKeys;
  content1?: React.ReactNode;
  message1?: Message;
  titleId2: MessageKeys;
  message2: Message;
  enableButtonBorder?: boolean;
}>;

const styles = Scope(() => {
  return {
    listItem: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexGrow: 1,
    } as OptionalStyles,

    listItemLeft: {
      display: 'flex',
      alignItems: 'center',
      padding: Length.px(10),
    } as OptionalStyles,

    listItemRight: {
      borderTop: Border('solid', Length.px(1), Colors.border),
      display: 'flex',
      alignItems: 'center',
      width: Percentage(100),
    } as OptionalStyles,

    borderBottom: {
      borderBottom: Border('solid', Length.px(1), Colors.border),
    } as OptionalStyles,

    item1: {
      marginRight: Length.px(10),
      minWidth: Percentage(30),
    } as OptionalStyles,

    item2: {
      marginLeft: Length.px(10),
    } as OptionalStyles,

    title: {
      display: 'inline-block',
      fontSize: Length.px(12),
      fontWeight: 'bold',
      lineHeight: Length.px(12),
      padding: Length.px(2),
      color: Colors.text,
    } as OptionalStyles,

    content: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: Length.px(20),
    } as OptionalStyles,

    message: {
      display: 'inline-block',
      fontSize: Length.px(16),
      lineHeight: Length.px(16),
      color: Colors.text,
    } as OptionalStyles,
  };
});

export function FuelMileageItem(
  props: FuelMileageItemBaseProps,
): React.ReactElement {
  const {
    views: { View, Image },
    data: { context },
  } = useContextStore();

  const intl = createIntl(context);

  const title1 = intl.formatMessage({ id: props.titleId1 });
  const title2 = intl.formatMessage({ id: props.titleId2 });

  return (
    <View style={styles.listItem}>
      <View style={styles.listItemLeft}>
        <Image alt={title1.toString()} src={props.icon} />
      </View>
      <View
        style={{
          ...styles.listItemRight,
          ...(props.enableButtonBorder === true ? styles.borderBottom : {}),
        }}
      >
        <View style={styles.item1}>
          <View>
            <View style={styles.title}>{title1.toReactNode()}</View>
          </View>
          <View style={styles.content}>
            {props.content1 !== undefined ? (
              props.content1
            ) : (
              <View style={styles.message}>
                {props.message1?.toReactNode()}
              </View>
            )}
          </View>
        </View>
        <View style={styles.item2}>
          <View>
            <View style={styles.title}>{title2.toReactNode()}</View>
          </View>
          <View style={styles.content}>
            <View style={styles.message}>{props.message2.toReactNode()}</View>
          </View>
        </View>
      </View>
    </View>
  );
}

type FuelProps = Readonly<{
  gasGauge: React.ReactNode;
  range: number;
}>;

export function Fuel(props: FuelProps): React.ReactElement {
  return (
    <FuelMileageItem
      icon={Icons.ic_gas_normal}
      titleId1='VehicleInfo_fuelGauge'
      content1={props.gasGauge}
      titleId2={'VehicleInfo_remainingDistance'}
      message2={Message(`${props.range.toLocaleString('en-US')} km`)}
    />
  );
}

type MileageProps = Readonly<{
  mileage: number;
  fuelEconomy: number;
}>;

export function Mileage(props: MileageProps): React.ReactElement {
  return (
    <FuelMileageItem
      icon={Icons.ic_road_normal}
      titleId1='VehicleInfo_odoMeter'
      message1={Message(`${props.mileage.toLocaleString('en-US')} km`)}
      titleId2='VehicleInfo_fuelEconomy'
      message2={Message(`${props.fuelEconomy.toLocaleString('en-US')} km/L`)}
      enableButtonBorder
    />
  );
}

interface Props extends FuelProps, MileageProps {}

export function FuelMileageList(props: Props): React.ReactElement {
  return (
    <>
      <Fuel gasGauge={props.gasGauge} range={props.range} />
      <Mileage mileage={props.mileage} fuelEconomy={props.fuelEconomy} />
    </>
  );
}
