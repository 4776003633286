import { println } from '../apis/NativeApi';

export function spy<A>(f: (a: A) => void, a: A): A {
  f(a);
  return a;
}

export async function debugAsync1<A>(
  prefix: string,
  f: () => Promise<A>,
): Promise<A> {
  println(`${prefix} is in progress.`);
  const result = await f();
  println(`${prefix} has been done successfully`);
  return result;
}

export async function debugAsync2<A>(
  start: string,
  f: () => Promise<A>,
  end: (a: A) => string,
): Promise<A> {
  println(start);
  const result = await f();
  println(end(result));
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getActionName(actions: any, action: any): string | undefined {
  return Object.keys(actions).find((key) => actions[key] === action);
}
