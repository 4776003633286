import React from 'react';
import { IcButton } from '../icons/IcButton';
import { IcReload } from '../icons/IcReload';
import { Icons } from '../icons/Icons';
import { ContentState } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Length } from '../style/Length';

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  contentState: ContentState;
  ongoingState: boolean;
  onReload: (
    _: DataMapDispatch<WithContext<Parent>>,
  ) => DataMapDispatch<WithContext<Parent>>;
}>;

export function ReloadButton<Parent>(props: Props<Parent>): React.ReactElement {
  const {
    views: { View },
    dispatch,
  } = useParentStore<Parent>({
    parent: props.parent,
  });

  const contentState = props.contentState;
  const isSpinning =
    contentState === 'Loading' ||
    contentState === 'Updating' ||
    props.ongoingState;

  return (
    <View
      style={{
        height: Length.px(48),
        WebkitTapHighlightColor: 'transparent',
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        if (isSpinning) {
          return dispatch;
        } else {
          return props.onReload(dispatch);
        }
      }}
    >
      {isSpinning ? (
        <IcReload
          style={{
            width: Length.px(48),
            height: Length.px(48),
          }}
          animate
        />
      ) : (
        <IcButton
          image={Icons.ic_reload}
          imageWidth={Length.px(48)}
          imageHeight={Length.px(48)}
        />
      )}
    </View>
  );
}
