import * as Client from '@auto/monaka-client/dist/20';
import { RemoteCommandResponseStatusCode } from '@auto/monaka-client/dist/20';
import { DialogBodyId, DialogTitleId } from '../ui/i18n/Types';
import {
  getDB,
  updateDB,
  updateServiceTelemetry,
  updateWifiStatus,
} from '../ui/mock/Database';
import { ActionBinder } from '../ui/states/ActionBinder';
import { Context, LastRemoteCommand } from '../ui/states/Context';
import { Action } from '../ui/states/Reducer';
import { BuildOpt } from '../util/BuildOpt';
import { copy } from '../util/Copyable';
import { Duration } from '../util/Duration';
import { Anomaly, getErrorMessage } from '../util/Error';
import { Listener } from '../util/Listener';
import { None, Option, Some } from '../util/Option';
import { Failure, Success } from '../util/Result';
import { Scope } from '../util/Scope';
import { sleep } from '../util/Sleep';
import { theme } from '../util/Theme';
import { Unit } from '../util/Unit';
import { AppSyncClient } from './Client';
import { Geofence, toClientGeofence } from './Geofence';
import { println } from './NativeApi';

export type RemoteCommandName =
  | 'allDoors'
  | 'lights'
  | 'hazardLamp'
  | 'airCondition'
  | 'theftTracking'
  | 'geofence'
  | 'engineCommandProhibited'
  | 'wifi'
  | 'resetWifi'
  | 'sendTel';

export type RemoteCommandAction<Name extends RemoteCommandName> =
  Name extends 'allDoors'
    ? 'locked'
    : Name extends 'lights'
    ? 'on' | 'off'
    : Name extends 'hazardLamp'
    ? 'on' | 'off'
    : Name extends 'airCondition'
    ? 'on' | 'off'
    : Name extends 'theftTracking'
    ? 'true' | 'false'
    : Name extends 'geofence'
    ? 'create' | 'update' | 'delete'
    : Name extends 'engineCommandProhibited'
    ? 'true' | 'false'
    : Name extends 'wifi'
    ? 'true' | 'false'
    : Name extends 'resetWifi'
    ? '-'
    : Name extends 'sendTel'
    ? '-'
    : never;

export type RemoteCommandParams<
  Name extends RemoteCommandName,
  Action extends RemoteCommandAction<Name>,
> = Name extends 'geofence'
  ? Action extends 'create' | 'update'
    ? Geofence
    : {} // eslint-disable-line @typescript-eslint/ban-types
  : {}; // eslint-disable-line @typescript-eslint/ban-types

export type RemoteCommandType<Name extends RemoteCommandName> =
  Name extends 'geofence' ? 'dsc' : 'vehicle';

type RemoteCommandResponseDetail = {
  readonly statusCode: RemoteCommandResponseStatusCode;
};

export type RemoteCommandResponse<Name extends RemoteCommandName> = {
  readonly result: 'success' | 'failure';
  readonly command: {
    readonly dcmId: string;
    // readonly identityId: string;
    readonly gSysUserId: string;
    readonly vin: string;
    readonly type: RemoteCommandType<Name>;
    readonly name: Name;
    readonly action: RemoteCommandAction<Name>;
  };
  readonly detail: RemoteCommandResponseDetail;
};

export type ErrorDialogMessageId = {
  readonly dialogTitleId: DialogTitleId;
  readonly dialogBodyId: DialogBodyId;
};

const RemoteCommandEventName = 'RemoteCommandEvent';

function toClientRemoteCommandParams<
  Name extends RemoteCommandName,
  Action extends RemoteCommandAction<Name>,
>(
  name: Name,
  action: Action,
  params: RemoteCommandParams<Name, Action>,
): Client.RemoteCommandParams<Name, Action> {
  switch (name) {
    case 'geofence':
      switch (action) {
        case 'create':
        case 'update':
          return toClientGeofence(
            params as Geofence,
          ) as Client.RemoteCommandParams<Name, Action>;
        default:
          return params as unknown as Client.RemoteCommandParams<Name, Action>;
      }
    default:
      return params as unknown as Client.RemoteCommandParams<Name, Action>;
  }
}

function ErrorDialogMessageId(
  dialogTitleId: DialogTitleId,
  dialogBodyId: DialogBodyId,
): ErrorDialogMessageId {
  return { dialogTitleId, dialogBodyId };
}

export function getErrorDialogMessageId<
  Name extends RemoteCommandName,
  Action extends RemoteCommandAction<Name>,
>(name: Name, action: Action): ErrorDialogMessageId {
  switch (name) {
    case 'allDoors':
      return ErrorDialogMessageId(
        'Event_remoteDoorLockedFailDialogTitle',
        'Event_remoteDoorLockedFailDialogBody',
      );
    case 'lights':
      if (action === 'on')
        return ErrorDialogMessageId(
          'Event_remoteHeadLightOnFailDialogTitle',
          'Event_remoteHeadLightOnFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteHeadLightOffFailDialogTitle',
          'Event_remoteHeadLightOffFailDialogBody',
        );
    case 'hazardLamp':
      if (action === 'on')
        return ErrorDialogMessageId(
          'Event_remoteHazardOnFailDialogTitle',
          'Event_remoteHazardOnFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteHazardOffFailDialogTitle',
          'Event_remoteHazardOffFailDialogBody',
        );
    case 'airCondition':
      if (action === 'on')
        return ErrorDialogMessageId(
          'Event_remoteACOnFailDialogTitle',
          'Event_remoteACOnFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteACOffFailDialogTitle',
          'Event_remoteACOffFailDialogBody',
        );
    case 'theftTracking':
      if (action === 'true')
        return ErrorDialogMessageId(
          'Event_remoteTrackingOnFailDialogTitle',
          'Event_remoteTrackingOnFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteTrackingOffFailDialogTitle',
          'Event_remoteTrackingOffFailDialogBody',
        );
    case 'geofence':
      if (action === 'create')
        return ErrorDialogMessageId(
          'Event_remoteGeofenceOnFailDialogTitle',
          'Event_remoteGeofenceOnFailDialogBody',
        );
      else if (action === 'update')
        return ErrorDialogMessageId(
          'Event_remoteGeofenceChangeFailDialogTitle',
          'Event_remoteGeofenceChangeFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteGeofenceOffFailDialogTitle',
          'Event_remoteGeofenceOffFailDialogBody',
        );
    case 'engineCommandProhibited':
      if (action === 'true')
        return ErrorDialogMessageId(
          'Event_remoteEngineProhibitOnFailDialogTitle',
          'Event_remoteEngineProhibitOnFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteEngineProhibitOffFailDialogTitle',
          'Event_remoteEngineProhibitOffFailDialogBody',
        );
    case 'wifi':
      if (action === 'true')
        return ErrorDialogMessageId(
          'Event_remoteWifiOnFailDialogTitle',
          'Event_remoteWifiOnFailDialogBody',
        );
      else
        return ErrorDialogMessageId(
          'Event_remoteWifiOffFailDialogTitle',
          'Event_remoteWifiOffFailDialogBody',
        );
    case 'resetWifi':
      return ErrorDialogMessageId(
        'Event_remoteWifiResetFailDialogTitle',
        'Event_remoteWifiResetFailDialogBody',
      );
    default:
      return ErrorDialogMessageId(
        'Common_networkErrorMessage' as DialogTitleId,
        'Common_networkErrorMessage' as DialogBodyId,
      );
  }
}

export async function sendRemoteCommand<
  Name extends RemoteCommandName,
  Action extends RemoteCommandAction<Name>,
>(params: {
  vin: string;
  dcmId: string;
  name: Name;
  type: RemoteCommandType<Name>;
  action: Action;
  params: RemoteCommandParams<Name, Action>;
}): Promise<RemoteCommandResponse<Name>> {
  if (BuildOpt.isMock()) {
    println(
      `Sending a remote command: vin=${params.vin}, name=${params.name}, action=${params.action}`,
    );
    await sleep(3000);
    const db = await getDB();
    println(
      `Got the result of the remote command: vin=${params.vin}, name=${params.name}, action=${params.action}`,
    );

    const resp: RemoteCommandResponse<Name> = {
      result: 'success',
      command: {
        dcmId: params.dcmId,
        gSysUserId: '',
        vin: params.vin,
        type: params.type,
        name: params.name,
        action: params.action,
      },
      detail: {
        statusCode: 200,
      },
    };

    // Return error when sendRemoteCommandError
    if (db.sendRemoteCommandError === 'Any') {
      return Promise.reject(
        Anomaly(
          503,
          Error(
            `Error on sendRemoteCommand: vin=${params.vin}, name=${params.name}, action=${params.action}`,
          ),
        ),
      );
    } else if (db.sendRemoteCommandError === 409) {
      return Promise.reject(
        Anomaly(
          409,
          Error(
            `Error on sendRemoteCommand: vin=${params.vin}, name=${params.name}, action=${params.action}`,
          ),
        ),
      );
    } else if (db.sendRemoteCommandError === 500) {
      return Promise.reject(
        Anomaly(
          500,
          Error(
            `Error on sendRemoteCommand: vin=${params.vin}, name=${params.name}, action=${params.action}`,
          ),
        ),
      );
    }

    if (params.name === 'geofence') {
      switch (params.action) {
        case 'delete':
          await updateDB(db, {
            vehicles: db.vehicles.map((v) => {
              if (v.vin === params.vin) return copy(v, { geofence: None() });
              else return v;
            }),
          });
          break;
        default:
          await updateDB(db, {
            vehicles: db.vehicles.map((v) => {
              if (v.vin === params.vin)
                return copy(v, { geofence: Some(params.params as Geofence) });
              else return v;
            }),
          });
          break;
      }
    } else {
      setTimeout(
        () =>
          sendRemoteCommandResponse(
            params.vin,
            params.dcmId,
            params.name,
            params.type,
            params.action,
            Scope(() => {
              const flag = db.subscribeRemoteCommandResultError;
              return flag === 'None'
                ? 'success'
                : flag === 'SendTel' && params.name !== 'sendTel'
                ? 'success'
                : 'failure';
            }),
            Scope(() => {
              const flag = db.subscribeRemoteCommandResultError;
              return flag === 'None'
                ? 200
                : flag === 'SendTel' && params.name !== 'sendTel'
                ? 200
                : flag === 408 || flag === 409 || flag === 410
                ? flag
                : 500;
            }),
          ),
        2000,
      );
    }

    return resp;
  } else {
    const gSysUserId = Option(await Client.Auth.currentDriver())
      .map((_) => _.gSysUserId)
      .getOrElse(() => 'unknown');
    println(
      `Sending a remote command: type=${params.type} gSysUserId=${gSysUserId} vin=${params.vin}, name=${params.name}, action=${params.action}`,
    );
    return AppSyncClient.sendRemoteCommand({
      ...params,
      gSysUserId,
      params: toClientRemoteCommandParams(
        params.name,
        params.action,
        params.params,
      ),
    })
      .then((_) => {
        if (_.result === 'success') {
          println(
            `Got the result of the remote command: vin=${params.vin}, name=${params.name}, action=${params.action}`,
          );
          return {
            result: _.result,
            command: {
              dcmId: _.command.dcmId,
              gSysUserId: _.command.g_sys_user_id,
              vin: _.command.vin,
              type: _.command.type,
              name: _.command.name,
              action: _.command.action,
            },
            detail: _.detail,
          };
        } else {
          println(
            `Error sending remote command: vin=${params.vin}, name=${params.name}, action=${params.action}`,
          );
          const msg = Anomaly(
            _.detail.statusCode,
            Error(
              `Error on sendRemoteCommand(code=${_.detail.statusCode}, vin=${_.command.vin}, name=${_.command.name}, action=${_.command.action})`,
            ),
          );
          return Promise.reject(msg);
        }
      })
      .catch((err) => {
        return Promise.reject(
          Anomaly.of(
            err,
            `Error on sendRemoteCommand(name=${params.name}, action=${params.action})`,
          ),
        );
      });
  }
}

export async function sendRemoteCommandResponse<
  Name extends RemoteCommandName,
  Action extends RemoteCommandAction<Name>,
>(
  vin: string,
  dcmId: string,
  name: Name,
  type: RemoteCommandType<Name>,
  action: Action,
  result: 'success' | 'failure',
  statusCode: RemoteCommandResponseStatusCode,
): Promise<Unit> {
  const db = await getDB();
  if (result === 'success') {
    await Scope(() => {
      switch (name) {
        case 'allDoors':
          return updateServiceTelemetry(db, vin, { door_status: true });
        case 'lights':
          if (action === 'on')
            return updateServiceTelemetry(db, vin, { light: true });
          else return updateServiceTelemetry(db, vin, { light: false });
        case 'hazardLamp':
          if (action === 'on')
            return updateServiceTelemetry(db, vin, { hazard_lamp: true });
          else return updateServiceTelemetry(db, vin, { hazard_lamp: false });
        case 'airCondition':
          if (action === 'on') {
            return updateServiceTelemetry(
              db,
              vin,
              theme(
                {
                  air_con_status: true,
                  eng_status: true,
                },
                {
                  eng_status: true,
                },
              ),
            );
          } else {
            return updateServiceTelemetry(
              db,
              vin,
              theme(
                {
                  air_con_status: false,
                  eng_status: false,
                },
                {
                  eng_status: false,
                },
              ),
            );
          }
        case 'theftTracking':
          if (action === 'true')
            return updateServiceTelemetry(db, vin, { en_theft_trk: true });
          else return updateServiceTelemetry(db, vin, { en_theft_trk: false });
        case 'engineCommandProhibited':
          if (action === 'true')
            return updateServiceTelemetry(db, vin, { dsc_eng_prohibit: true });
          else
            return updateServiceTelemetry(db, vin, { dsc_eng_prohibit: false });
        case 'wifi':
          if (action === 'true')
            return updateWifiStatus(db, vin, {
              wifiRequest: true,
              wifiStatus: true,
            });
          else
            return updateWifiStatus(db, vin, {
              wifiRequest: false,
              wifiStatus: false,
            });
        case 'resetWifi':
          return db;
        case 'sendTel':
          return db;
        default:
          return db;
      }
    });
  }

  const resp: RemoteCommandResponse<Name> = {
    result,
    command: {
      dcmId,
      gSysUserId: '',
      vin,
      type,
      name,
      action,
    },
    detail: {
      statusCode: statusCode,
    },
  };

  window.dispatchEvent(
    new CustomEvent(RemoteCommandEventName, {
      detail: {
        data: resp,
      },
    }),
  );

  return Unit;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RemoteCommandEventListener = (event: any) => void;
const remoteCommandEventListeners: Map<string, RemoteCommandEventListener> =
  new Map();
function getKey(name: RemoteCommandName, dcmId: string): string {
  return `${name}-${dcmId}`;
}

export async function subscribeRemoteCommand<Name extends RemoteCommandName>(
  name: Name,
  dcmId: string,
  vin: string,
): Promise<Listener<RemoteCommandResponse<Name>>> {
  if (BuildOpt.isMock()) {
    println(
      `Start subscribing command (name=${name}, dcmId=${dcmId}, vin=${vin})`,
    );
    const db = await getDB();
    if (db.subscribeRemoteCommandResultError === 'Any') {
      return Promise.reject(
        Anomaly(
          503,
          Error(
            `Error on subscribeRemoteCommand(name=${name}, dcmId=${dcmId}, vin=${vin})}`,
          ),
        ),
      );
    } else if (db.subscribeRemoteCommandResultError === 408) {
      return Promise.reject(
        Anomaly(
          408,
          Error(
            `Error on subscribeRemoteCommand(name=${name}, dcmId=${dcmId}, vin=${vin})}`,
          ),
        ),
      );
    } else if (db.subscribeRemoteCommandResultError === 409) {
      return Promise.reject(
        Anomaly(
          409,
          Error(
            `Error on subscribeRemoteCommand(name=${name}, dcmId=${dcmId}, vin=${vin})}`,
          ),
        ),
      );
    } else if (db.subscribeRemoteCommandResultError === 410) {
      return Promise.reject(
        Anomaly(
          410,
          Error(
            `Error on subscribeRemoteCommand(name=${name}, dcmId=${dcmId}, vin=${vin})}`,
          ),
        ),
      );
    } else {
      return Promise.resolve(
        Listener((dispatch) => {
          const remoteCommandEventListener: RemoteCommandEventListener = (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            event: any,
          ) => {
            println(`Receive the subscribed result: ${JSON.stringify(event)}`);
            if (event.detail?.data) {
              const resp: RemoteCommandResponse<Name> = event.detail.data;
              println(
                `Receive the subscribed result: ${resp.result} (name=${name}, action=${resp.command.action}, dcmId=${dcmId}, vin=${vin})`,
              );
              if (resp.result === 'failure') {
                const msg = `Failed to execute the remote command (name=${name}, dcmId=${dcmId}, vin=${vin})`;
                dispatch(Failure(Anomaly(resp.detail.statusCode, msg)));
              } else {
                dispatch(Success(resp));
              }
            }
          };
          remoteCommandEventListeners.set(
            getKey(name, dcmId),
            remoteCommandEventListener,
          );
          window.addEventListener(
            RemoteCommandEventName,
            remoteCommandEventListener,
          );
        }),
      );
    }
  } else {
    return new Promise((resolve, reject) => {
      const listener = Listener<RemoteCommandResponse<Name>>((dispatch) => {
        return AppSyncClient.subscribeRemoteCommandResponse(
          dcmId,
          vin,
          name,
          async (_) => {
            println(`callback: ${JSON.stringify(_)})`);
            println(
              `Receive the subscribed result: ${_.result} (name=${name}, action=${_.command.action}, dcmId=${dcmId}, vin=${vin})`,
            );
            if (_.result === 'success') {
              dispatch(
                Success<RemoteCommandResponse<Name>>({
                  result: _.result,
                  command: {
                    dcmId: _.command.dcmId,
                    gSysUserId: '',
                    vin: _.command.vin,
                    type: _.command.type,
                    name: _.command.name,
                    action: _.command.action,
                  },
                  detail: _.detail,
                }),
              );
            } else {
              const msg = `Error on  (name=${name}, action=${_.command.action}, dcmId=${dcmId}, vin=${vin})`;
              dispatch(Failure(Anomaly(_.detail.statusCode, Error(msg))));
            }
          },
        )
          .then(() => {
            println(`resolve listener`);
            resolve(listener);
          })
          .catch((err) => {
            const msg = `Error on subscribeRemoteCommand(name=${name}, dcmId=${dcmId}, vin=${vin}): ${getErrorMessage(
              err,
            )}`;
            return reject(Anomaly.of(err, msg));
          });
      });
    });
  }
}

export function unsubscribeRemoteCommand<Name extends RemoteCommandName>(
  name: Name,
  dcmId: string,
): Promise<Unit> {
  println(`unsubscribe dcmId: ${dcmId} name: ${name}`);
  if (BuildOpt.isMock()) {
    Option(remoteCommandEventListeners.get(getKey(name, dcmId))).unwrap(
      (l) => {
        window.removeEventListener(RemoteCommandEventName, l);
        remoteCommandEventListeners.delete(getKey(name, dcmId));
        return Promise.resolve(Unit);
      },
      () =>
        Promise.reject(
          Anomaly(
            0,
            `No remote command event listener was found for ${name}-${dcmId}`,
          ),
        ),
    );
    return Promise.resolve(Unit);
  } else {
    /*
    return IotClient.unsubscribeRemoteCommand(name, dcmId)
      .then((_) => Unit)
      .catch((err) => {
        const msg = `Error on unsubscribeRemoteCommand(name=${name}, dcmId=${dcmId}): ${getErrorMessage(
          err,
        )}`;
        return Promise.reject(Anomaly.of(err, msg));
      });
    */
    return AppSyncClient.unsubscribeRemoteCommandResponse(dcmId)
      .then((_) => Unit)
      .catch((err) => {
        const msg = `Error on unsubscribeRemoteCommand(name=${name}, dcmId=${dcmId}): ${getErrorMessage(
          err,
        )}`;
        return Promise.reject(Anomaly.of(err, msg));
      });
  }
}

export function hasLastRemoteCommand(
  lastCommands: LastRemoteCommand[],
  name: RemoteCommandName,
  action: RemoteCommandAction<RemoteCommandName>,
): boolean {
  return lastCommands.some((_) => _.name === name && _.action === action);
}

export function equalLastRemoteCommand(
  a: LastRemoteCommand,
  b: LastRemoteCommand,
): boolean {
  return (
    a.timestamp === b.timestamp && a.name === b.name && a.action === b.action
  );
}

export function createLastRemoteCommandAction(
  commandName: RemoteCommandName,
  action: RemoteCommandAction<RemoteCommandName>,
): { add: ActionBinder<Context>; remove: Promise<ActionBinder<Context>> } {
  const duration = Duration.minutes(3);
  const lastCommand: LastRemoteCommand = {
    name: commandName,
    action,
    timestamp: Date.now(),
  };

  return {
    add: Action.bind((context) => {
      return Context.actions.updateLastRemoteCommands(
        context,
        context.lastRemoteCommands.concat([lastCommand]),
      );
    }, Unit),
    remove: new Promise<ActionBinder<Context>>((resolve) => {
      setTimeout(() => {
        resolve(
          Action.bind((context) => {
            println(
              `A Last executed command(${commandName}, ${action}) has been removed since ${duration.toString()} has been passed`,
            );
            return Context.actions.updateLastRemoteCommands(
              context,
              context.lastRemoteCommands.filter(
                (a) => !equalLastRemoteCommand(a, lastCommand),
              ),
            );
          }, Unit),
        );
      }, duration.toMilliSeconds());
    }),
  };
}
