import { RemoteCommandAction } from '@auto/monaka-client/dist/20';
import React from 'react';
import { RemoteCommandName } from '../../apis/RemoteCommand';
import { execRemoteCommand } from '../../apis/RemoteCommandExecuter';
import { Option } from '../../util/Option';
import { MessageKeys, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { ActionBinder } from '../states/ActionBinder';
import { useParentStore } from '../states/ContextStore';
import { Store } from '../states/Store';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { LineSpace } from './LineSpace';
import { SlideButton } from './SlideButton';
import { SlideInCard, SlideInCardState } from './SlideInCard';

type Props<Data> = Readonly<{
  store: Store<Data>;
  state: SlideInCardState;
  isSpinning: boolean;

  closeAction: ActionBinder<Data>;

  toastMessageId: (
    commandAction: RemoteCommandAction<'engineCommandProhibited'>,
  ) => Option<MessageKeys>;

  updateIsSpinningAction: (data: Data, isControllerSpinning: boolean) => Data;

  updateControllerStateAction: (
    data: Data,
    controllerState: SlideInCardState,
  ) => Data;
}>;

export function EngineController<Data>({
  store,
  state,
  isSpinning,
  closeAction,
  toastMessageId,
  updateIsSpinningAction,
  updateControllerStateAction,
}: Props<Data>): React.ReactElement {
  const parent = { parent: store };
  const {
    views: { View, Text },
    data: { context },
  } = useParentStore({
    parent,
  });
  const intl = useIntl();

  const prohibit = context.serviceTelemetry.data
    .toOption()
    .some((_) => _.dsc_eng_prohibit);
  const titleId: MessageKeys = prohibit
    ? 'VehicleAlert_engineProhibitOff'
    : 'VehicleAlert_engineProhibitOn';
  const bodyId: MessageKeys = prohibit
    ? 'VehicleAlert_engineProhibitOffMsg'
    : 'VehicleAlert_engineProhibitOnMsg';
  const isButtonEnabled = prohibit;
  const commandName: RemoteCommandName = 'engineCommandProhibited';
  const commandAction = isButtonEnabled ? 'false' : 'true';

  return (
    <SlideInCard
      parent={parent}
      state={state}
      title={intl.formatMessage({ id: titleId })}
      closeIcon={Icons.ic_close_normal}
      minHeight={280}
      onClose={(_, dispatch) =>
        dispatch.parent(updateControllerStateAction, 'Hide')
      }
    >
      <View>
        <Text>{intl.formatMessage({ id: bodyId }).toReactNode()}</Text>
      </View>

      <View
        style={{
          paddingTop: Length.px(20),
          paddingBottom: Length.px(14),
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <SlideButton
            parent={parent}
            isEnabled={isButtonEnabled}
            isSpinning={isSpinning}
            buttonIcon={Icons.ic_enginelock_white}
            leftIcon={
              isButtonEnabled
                ? Icons.ic_slide_off_black
                : Icons.ic_slide_off_white
            }
            rightIcon={
              isButtonEnabled
                ? Icons.ic_slide_on_black
                : Icons.ic_slide_on_white
            }
            onChange={({ dispatch }) =>
              execRemoteCommand({
                commandName,
                commandAction,
                closeAction: dispatch.parent(closeAction),
                toastMessageId: toastMessageId(commandAction),
                updateIsSpinning: (enable) =>
                  dispatch.parent(updateIsSpinningAction, enable),
                context,
                dispatch,
              })
            }
          />
        </View>

        {isSpinning ? (
          <>
            <LineSpace height={Length.px(12)} />

            <Text
              style={{
                fontSize: Length.px(14),
                lineHeight: Length.px(14),
                color: Colors.textLight,
                textAlign: 'center',
              }}
            >
              {intl
                .formatMessage({ id: 'VehicleAlert_processCommand' })
                .toReactNode()}
            </Text>
          </>
        ) : (
          <LineSpace height={Length.px(26)} />
        )}
      </View>
    </SlideInCard>
  );
}
