import { ServiceTelemetryResult } from '../../apis/ServiceTelemetry';
import { copy } from '../../util/Copyable';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { State as HelpDialogState } from '../components/HelpDialog';
import { OverlayState } from '../components/Overlay';
import { SlideInCardState } from '../components/SlideInCard';
import { MessageKeys } from '../i18n/Intl';
import { OngoingRemoteStates, defaultOngoingState } from '../states/Context';
import {
  ControllerState,
  FuelMileage,
  RemoteState,
  RemoteStateItemName,
  RemoteStates,
} from './Types';

export function toRemoteStateList(
  remoteStates: RemoteStates,
): Array<[RemoteStateItemName, RemoteState]> {
  return [
    ['DoorLock', remoteStates.doorLock],
    ['Light', remoteStates.light],
    ['Hazard', remoteStates.hazard],
    ['Ac', remoteStates.ac],
    ['Engine', remoteStates.engine],
  ];
}

export function updateRemoteStates(
  remoteStates: RemoteStates,
  itemName: RemoteStateItemName,
  remoteState: RemoteState,
): RemoteStates {
  return copy<RemoteStates>(
    remoteStates,
    Scope(() => {
      switch (itemName) {
        case 'DoorLock':
          return { doorLock: remoteState };
        case 'Light':
          return { light: remoteState };
        case 'Hazard':
          return { hazard: remoteState };
        case 'Ac':
          return { ac: remoteState };
        default:
          return {};
      }
    }),
  );
}

export function getToastMessageId(
  itemName: RemoteStateItemName,
  active: boolean,
): MessageKeys {
  switch (itemName) {
    case 'DoorLock':
      if (active) return 'RemoteControlResult_doorLockedMsg';
      else return 'RemoteControlResult_doorLockedMsg';
    case 'Light':
      if (active) return 'RemoteControlResult_headLightOnMsg';
      else return 'RemoteControlResult_headLightOffMsg';
    case 'Hazard':
      if (active) return 'RemoteControlResult_hazardLightOnMsg';
      else return 'RemoteControlResult_hazardLigntOffMsg';
    case 'Ac':
      if (active) return 'RemoteControlResult_airConOnMsg';
      else return 'RemoteControlResult_airConOffMsg';
    case 'Engine':
      if (active) return 'RemoteControlResult_engineOnMsg';
      else return 'RemoteControlResult_engineOffMsg';
  }
}

export function getOverlayState(
  controllerState: ControllerState,
  helpDialogState: HelpDialogState,
  engineControllerState: SlideInCardState,
): OverlayState {
  return controllerState.state === 'Show' ||
    helpDialogState === 'Show' ||
    engineControllerState === 'Show'
    ? 'Show'
    : 'Hide';
}

function toRemoteState(
  remoteState: boolean,
  ongoingRemoteState: boolean,
): RemoteState {
  if (remoteState) {
    if (ongoingRemoteState) return 'ToNormal';
    else return 'Active';
  } else {
    if (ongoingRemoteState) return 'ToActive';
    else return 'Normal';
  }
}

export function toRemoteStates(
  serviceTelemetry: ServiceTelemetryResult,
  ongoingRemoteStates: OngoingRemoteStates,
): RemoteStates {
  const ongoingRemoteState = serviceTelemetry.data.unwrap(
    (_) =>
      Option(ongoingRemoteStates[_.vin]).unwrap(
        (_) => _,
        () => defaultOngoingState,
      ),
    () => defaultOngoingState,
  );
  return {
    doorLock: toRemoteState(
      serviceTelemetry.data.toOption().some((_) => _.door_status),
      ongoingRemoteState.door,
    ),
    light: toRemoteState(
      serviceTelemetry.data.toOption().some((_) => _.light),
      ongoingRemoteState.light,
    ),
    hazard: toRemoteState(
      serviceTelemetry.data.toOption().some((_) => _.hazard_lamp),
      ongoingRemoteState.hazard,
    ),
    ac: toRemoteState(
      serviceTelemetry.data.toOption().some((_) => _.air_con_status),
      theme(ongoingRemoteState.air_con, false),
    ),
    engine: toRemoteState(
      serviceTelemetry.data.toOption().some((_) => _.eng_status),
      theme(false, ongoingRemoteState.air_con), // engine and air_con use same remote command.
    ),
  };
}

export function getRemoteState(
  remoteStates: RemoteStates,
  item: RemoteStateItemName,
): RemoteState {
  switch (item) {
    case 'DoorLock':
      return remoteStates.doorLock;
    case 'Light':
      return remoteStates.light;
    case 'Hazard':
      return remoteStates.hazard;
    case 'Ac':
      return remoteStates.ac;
    case 'Engine':
      return remoteStates.engine;
  }
}

export function toFuelMileage(
  serviceTelemetry: ServiceTelemetryResult,
): FuelMileage {
  const defaultFuelMileage: FuelMileage = {
    fuel: 0,
    fuelWarn: false,
    range: 0,
    mileage: 0,
    fuelEconomy: 0,
  };

  return serviceTelemetry.data.unwrap(
    (_) => {
      return {
        fuel: _.fl_seg,
        fuelWarn: _.fl_wrn,
        range: _.range,
        mileage: _.odo,
        fuelEconomy: _.to_fc,
      };
    },
    () => defaultFuelMileage,
  );
}
