/**
 * @module
 */
import React from 'react';
import { None, Option, Some } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { EmptyProps } from '../components/Component';
import { useViews } from '../components/View';
import { usePrevious } from '../states/Previous';
import { Context } from './Context';
import {
  DataMapDispatch,
  DataMapState,
  DataMapStore,
  DispatchableDataMapState,
  IndexedDataMap,
  IndexedDispatchables,
  InnerDataMapState,
  PreviousDataMap,
  UpdateArgs,
  WithContext,
  WithSetCallback,
  createDispatchDataMap,
} from './DataMapStore';
import { DispatchBase, emptyDispatchItemsBase } from './DispatchBase';
import { CurrentCallback } from './DispatchCurrentItem';
import { DispatchableStore, Store } from './Store';

export type ContextDataMap = EmptyProps;
export type ContextDispatch = DataMapDispatch<WithContext<ContextDataMap>>;

export const contextDispatch = Scope<ContextDispatch>(() => {
  return createDispatchDataMap(
    emptyDispatchItemsBase<'DataMapDispatch', WithContext<ContextDataMap>>([
      'context',
    ]),
  );
});

export function useContextStore<Props = EmptyProps>(
  props: Readonly<{
    props?: Props;
    update?: (
      args: UpdateArgs<Props, WithContext<ContextDataMap>>,
    ) => DispatchBase<'DataMapDispatch', WithContext<ContextDataMap>>;
  }> = {},
): DataMapState<Props, ContextDataMap> {
  return useParentStore<ContextDataMap, Props>(props);
}

export function useParentStore<DataMap, Props = EmptyProps>({
  props: fprops,
  parent,
  update,
}: Readonly<{
  props?: Props;
  parent?: DataMapStore<DataMap>;
  update?: (
    args: UpdateArgs<Props, WithContext<DataMap>>,
  ) => DispatchBase<'DataMapDispatch', WithContext<DataMap>>;
}> = {}): DataMapState<Props, DataMap> {
  type D = WithContext<DataMap>;
  const props = fprops ?? ({} as Props);
  const context = React.useContext(Context.ref);
  const contextStore = Context.store;
  const [callback, setCallback] = React.useState<Option<CurrentCallback<D>>>(
    None(),
  );

  const fparent = React.useMemo(() => Option(parent), [parent]);

  const keys = React.useMemo(
    () =>
      fparent
        .unwrap(
          (p) => Object.keys(p),
          () => [],
        )
        .concat(['context']),
    [fparent],
  );

  const [data, stores] = React.useMemo(
    () =>
      keys.reduce(
        ([x, y], k) => {
          if (k !== 'context') {
            const _x = x as unknown as IndexedDataMap<DataMap>;
            const _y = y as unknown as IndexedDispatchables<DataMap>;
            fparent.forEach((p) => {
              const _p = p as unknown as {
                [k: string]: Store<DataMap[keyof DataMap]>;
              };
              _x[k] = _p[k].data;
              _y[k] = _p[k] as unknown as DispatchableStore<
                DataMap[keyof DataMap]
              >;
            });
          }
          return [x, y];
        },
        contextStore.unwrap(
          (cs) => [{ context }, { context: cs }],
          () => [{}, {}],
        ) as unknown as [D, DataMapStore<D>],
      ),
    [context, contextStore, fparent, keys],
  );

  const prev = usePrevious<PreviousDataMap<Props, D>>({
    props,
    data,
  });

  const dispatch = React.useMemo(
    () =>
      createDispatchDataMap(
        emptyDispatchItemsBase<'DataMapDispatch', D>(keys as Array<keyof D>),
      ),
    [keys],
  );

  const state: DispatchableDataMapState<D> = {
    stores,
    setCallback: (callback: CurrentCallback<D>) => setCallback(Some(callback)),
  };

  const views = useViews<D>(state);

  const self: InnerDataMapState<Props, DataMap> & WithSetCallback<D> = {
    ...state,
    data,
    prev,
    dispatch,
    views,
  };

  React.useEffect(() => {
    Option(update).forEach((f) =>
      f({ props, data, prev, dispatch }).dispatch(self),
    );
  });

  React.useEffect(() => {
    callback.forEach((f) => f({ props, data, prev, dispatch }).dispatch(self));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  return self;
}
