import * as Color from './Color';
import { Length } from './Length';

export interface Filter {
  readonly toStyle: () => string;
}

export interface DropShadow extends Filter {
  readonly offsetX: Length;
  readonly offsetY: Length;
  readonly blurRadius: Length;
  readonly color: Color.RGBA;
}

export function DropShadow(
  offsetX: Length,
  offsetY: Length,
  blurRadius: Length,
  color: Color.RGBA,
): DropShadow {
  const text = `drop-shadow(${offsetX.toStyle()} ${offsetY.toStyle()} ${blurRadius.toStyle()} ${color.toStyle()})`;
  return {
    offsetX,
    offsetY,
    blurRadius,
    color,
    toStyle: () => text,
  };
}
