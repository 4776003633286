import * as Client from '@auto/monaka-client/dist/20';
import React from 'react';
import { addUser, getDB, updateDB } from '../ui/mock/Database';
import { Context } from '../ui/states/Context';
import { BuildOpt } from '../util/BuildOpt';
import { debugAsync1 } from '../util/Debug';
import { getErrorMessage } from '../util/Error';
import { Scope } from '../util/Scope';
import { sleep } from '../util/Sleep';
import { AppSyncClient } from './Client';
import { UserType } from './VehicleList';

export interface SharedUser {
  sharedUserId: string;
  localUserId: string;
  userName: string;
  email: string;
  userType: UserType;
}

function fromClientSharedUser(
  user: Client.SharedUserInfo | Client.OwnerUserInfo,
): SharedUser {
  return {
    sharedUserId: user.sharedUserId,
    localUserId: user.localUserId,
    userName: user.userName,
    email: user.email,
    userType: user.userType as UserType,
  };
}

export interface InvitationResponse {
  readonly invitationCode: string;
  readonly invitationExpireTime: number;
}

export async function getSharedUserList(vin: string): Promise<SharedUser[]> {
  return debugAsync1('getSharedUserList', async () => {
    const gSysUserId = await callGetCurrentDriverId();
    const [owner, list] = await Promise.all([
      callGetOwner(gSysUserId, vin),
      callGetSharedUserList(gSysUserId, vin),
    ]);
    return [fromClientSharedUser(owner), ...list.map(fromClientSharedUser)];
  });
}

async function callGetCurrentDriverId(): Promise<string> {
  if (BuildOpt.isMock()) {
    await sleep(500);
    return 'unknown';
  } else {
    try {
      const driver = await Client.Auth.currentDriver();
      return driver === null ? 'unknown' : driver.gSysUserId;
    } catch (err) {
      throw Error(
        `Error occurred on Auth.currentDriver. ${getErrorMessage(err)}`,
      );
    }
  }
}

async function callGetSharedUserList(
  gSysUserId: string,
  vin: string,
): Promise<SharedUser[]> {
  return Scope(async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      const db = await getDB();
      const map = db.sharedUsers;
      let users = map.get(vin);
      if (users === undefined) {
        users = [
          await addUser(db, vin, 'all'),
          await addUser(db, vin, 'limited'),
        ];
      }
      return users;
    } else {
      try {
        const list = await AppSyncClient.getSharedUserList({ gSysUserId, vin });
        return list.sharedUsers.map(fromClientSharedUser);
      } catch (err) {
        throw Error(
          `Error occurred on getSharedUserList. ${getErrorMessage(err)}`,
        );
      }
    }
  }).then((list) => list.filter((user) => user.sharedUserId !== gSysUserId));
}

async function callGetOwner(
  sharedUserId: string,
  vin: string,
): Promise<SharedUser> {
  if (BuildOpt.isMock()) {
    await sleep(500);
    const db = await getDB();
    return addUser(db, vin, 'owner');
  } else {
    try {
      const owner = await AppSyncClient.getOwner({ sharedUserId, vin });
      return fromClientSharedUser(owner);
    } catch (err) {
      throw Error(`Error occurred on getOwner. ${getErrorMessage(err)}`);
    }
  }
}

export async function getSharedUserInvitationCode(
  vin: string,
  userType: UserType,
): Promise<InvitationResponse> {
  return debugAsync1('getSharedUserInvitationCode', async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      return {
        invitationCode: 'ldmy8004-020a-4c99-821b-1643cb7cb3e2',
        invitationExpireTime: Math.trunc(Date.now() / 1000),
      };
    } else {
      try {
        const ownerUserId = await callGetCurrentDriverId();
        const res = await AppSyncClient.getSharedUserInvitationCode({
          ownerUserId,
          vin,
          userType,
        });
        return {
          invitationCode: res.invitationCode,
          invitationExpireTime: res.invitationExpireTime,
        };
      } catch (err) {
        throw Error(
          `Error occurred on getSharedUserInvitationCode. ${getErrorMessage(
            err,
          )}`,
        );
      }
    }
  });
}

export async function updateSharedUserPrivilege(
  sharedUserId: string,
  vin: string,
  userType: UserType,
): Promise<boolean> {
  return debugAsync1('updateSharedUserPrivilege', async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      const db = await getDB();
      const map = db.sharedUsers;
      const users = map.get(vin);
      const user = users?.find((user) => user.sharedUserId === sharedUserId);
      if (users != null && user != null) {
        user.userType = userType;
        await updateDB(db, { sharedUsers: map });
        return true;
      }
      return false;
    } else {
      try {
        return await AppSyncClient.updateSharedUserPrivilege({
          sharedUserId,
          vin,
          userType,
        });
      } catch (err) {
        throw Error(
          `Error occurred on updateSharedUserPrivilege. ${getErrorMessage(
            err,
          )}`,
        );
      }
    }
  });
}

export async function deleteSharedUser(
  deleteUserId: string,
  vin: string,
): Promise<boolean> {
  return debugAsync1('deleteSharedUser', async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      const db = await getDB();
      const map = db.sharedUsers;
      const users = map
        .get(vin)
        ?.filter((user) => user.sharedUserId !== deleteUserId);
      if (users != null) {
        map.set(vin, users);
        await updateDB(db, { sharedUsers: map });
        return true;
      }
      return false;
    } else {
      try {
        return await AppSyncClient.deleteSharedUser({
          sharedUserId: deleteUserId,
          vin,
        });
      } catch (err) {
        throw Error(
          `Error occurred on deleteSharedUser. ${getErrorMessage(err)}`,
        );
      }
    }
  });
}

export function useGetUserNickName(
  user: SharedUser,
  fallback = true,
): [string, boolean] {
  const context = React.useContext(Context.ref);
  const nickName = context.userNickName[user.sharedUserId];
  if (fallback && nickName === undefined) {
    return [user.userName, true];
  }
  return [nickName ?? '', false];
}
