import React from 'react';
import { Animation } from '../style/Animation';
import { OptionalStyles, toCSSProperties } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { animation } from './Helpers';

type Props = {
  readonly alt: string;
  readonly src: string;
  readonly style?: OptionalStyles;
  readonly animate?: boolean;
};

// eslint-disable-next-line react/display-name
export const FlashImage = React.forwardRef(
  (
    { style: _style, alt, src, animate }: Props,
    ref: React.Ref<HTMLImageElement>,
  ) => {
    const style: OptionalStyles = {
      ...animation(
        Animation(
          'flash',
          Time.ms(700),
          TimingFunction.linear,
          Time.ms(0),
          'infinite',
        ),
        animate,
      ),
      ..._style,
    };

    return <img alt={alt} src={src} style={toCSSProperties(style)} ref={ref} />;
  },
);
