import React from 'react';
import ic_triangle from '../../assets/ic_triangle.svg';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { LineSpace } from '../components/LineSpace';
import { Message } from '../i18n/Intl';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { ServiceReminderDataMap } from './ServiceReminderCard';

export type State = 'Error' | 'Warn' | 'Normal';

export function Meter({
  parent,
  value,
  state,
  threshold,
  ticks,
  tickImage,
}: Readonly<{
  parent: DataMapStore<ServiceReminderDataMap>;
  value: Percentage;
  state: State;
  threshold: {
    value: Percentage;
    text: Message;
  };
  ticks: Option<string[]>;
  tickImage: string;
}>): React.ReactElement {
  const {
    views: { View, Text, Image },
  } = useParentStore({ parent });

  const meterMaxWidth = 120;
  const meterWidth = (meterMaxWidth * value.value) / 100;
  const barColor = Scope(() => {
    if (state === 'Error') return Colors.errorText;
    else if (state === 'Warn') return Colors.warningText;
    else return Colors.normalActionForeground;
  });
  const thresholdImageWidth = 8;
  const thresholdPx =
    (meterMaxWidth * threshold.value.value) / 100 - thresholdImageWidth / 2;

  return (
    <View>
      <View
        style={{
          position: 'relative',
          width: Length.px(meterMaxWidth),
          height: Length.px(12),
        }}
      >
        <View
          style={{
            width: Length.px(meterMaxWidth),
            height: Length.px(12),
            backgroundColor: Colors.componentBackground,
          }}
        />

        <View
          style={{
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
            width: Length.px(meterWidth),
            height: Length.px(12),
            backgroundColor: barColor,
          }}
        />

        <Image
          src={tickImage}
          style={{
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
            width: Length.px(meterMaxWidth),
            height: Length.px(12),
          }}
        />
      </View>

      <LineSpace height={Length.px(4)} />

      {ticks
        .map((ticks) => (
          <View
            key='ticks'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: Length.px(meterMaxWidth + 40),
            }}
          >
            {ticks.map((tick, idx) => (
              <Text
                key={idx}
                style={{
                  fontSize: Length.px(9),
                  lineHeight: Length.px(9),
                }}
              >
                {tick}
              </Text>
            ))}
          </View>
        ))
        .getOrElse(() => (
          <View />
        ))}

      <View
        style={{
          position: 'relative',
        }}
      >
        <View
          style={{
            position: 'relative',
            left: Length.px(thresholdPx),
          }}
        >
          <Image
            src={ic_triangle}
            style={{
              width: Length.px(thresholdImageWidth),
              height: Length.px(12),
            }}
          />
          <Text
            style={{
              display: 'inline-block',
              fontSize: Length.px(12),
              lineHeight: Length.px(12),
              paddingLeft: Length.px(4),
            }}
          >
            {threshold.text.toReactNode()}
          </Text>
        </View>
      </View>
    </View>
  );
}
