import { Animation } from './Animation';
import { Border } from './Border';
import { BoxShadow } from './BoxShadow';
import { RGBA } from './Color';
import { Filter } from './Filter';
import { GridLine } from './GridLine';
import { Length } from './Length';
import { Percentage } from './Percentage';
import { Transform } from './Transform';
import { Transition } from './Transition';

export type ToStyle = Readonly<{
  toStyle: () => string;
}>;

export type FontWeight = OptionalStyles['fontWeight'];

type LPA = LP | 'auto';

type MMHW =
  | Length
  | Percentage
  | 'none'
  | 'max-content'
  | 'min-content'
  | 'fit-content'
  | 'fill-available';

type LP = Length | Percentage;
export type Padding = LP;

export type OptionalStyles = Readonly<{
  [Key in keyof React.CSSProperties]?: Key extends 'animation'
    ? Animation
    : Key extends 'backgroundColor'
    ? RGBA
    : Key extends 'border'
    ? Border
    : Key extends 'borderTop' | 'borderRight' | 'borderBottom' | 'borderLeft'
    ? Border
    : Key extends 'boxShadow'
    ? BoxShadow
    : Key extends 'borderRadius'
    ? Length | Array<Length>
    : Key extends
        | 'borderTopLeftRadius'
        | 'borderTopRightRadius'
        | 'borderBottomLeftRadius'
        | 'borderBottomRightRadius'
    ? Length
    : Key extends 'color'
    ? RGBA
    : Key extends 'filter'
    ? Filter
    : Key extends 'fontSize'
    ? Length
    : Key extends 'gridColumn' | 'gridRow'
    ? GridLine
    : Key extends 'width' | 'height'
    ? LP
    : Key extends 'top' | 'left' | 'bottom' | 'right'
    ? LPA
    : Key extends 'lineHeight'
    ? LP | 'normal' | number
    : Key extends 'margin'
    ? LPA | Array<LPA>
    : Key extends 'marginTop' | 'marginRight' | 'marginBottom' | 'marginLeft'
    ? LPA
    : Key extends 'maxWidth' | 'minWidth' | 'maxHeight' | 'minHeight'
    ? MMHW
    : Key extends 'opacity'
    ? number | Percentage
    : Key extends
        | 'padding'
        | 'paddingTop'
        | 'paddingRight'
        | 'paddingBottom'
        | 'paddingLeft'
    ? Padding
    : Key extends 'transform'
    ? Transform
    : Key extends 'transition'
    ? Transition | Array<Transition>
    : Key extends 'verticalAlign'
    ?
        | 'baseline'
        | 'sub'
        | 'super'
        | 'text-top'
        | 'text-bottom'
        | 'middle'
        | 'top'
        | 'bottom'
        | LP
    : React.CSSProperties[Key];
}>;

function toStyleText(v: unknown): string {
  if (typeof v === 'string') return v;
  else if ((v as ToStyle).toStyle) return (v as ToStyle).toStyle();
  else return `${v}`;
}

function toCSSProp(k: keyof React.CSSProperties, v: unknown): string {
  if (v instanceof Array) {
    switch (k) {
      case 'transition':
        return v.map((_) => toStyleText(_)).join(',');
      default:
        return v.map((_) => toStyleText(_)).join(' ');
    }
  } else return toStyleText(v);
}

export function toCSSProperties(styles: OptionalStyles): React.CSSProperties {
  const _styles = {
    boxSizing: 'border-box',
    ...styles,
  };

  return Object.keys(_styles).reduce<React.CSSProperties>((props, key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v = (_styles as any)[key];
    if (v === undefined) return props;
    else {
      const x = toCSSProp(key as keyof React.CSSProperties, v);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (props as any)[key] = x;
      return props;
    }
  }, {});
}

export function applyStyle(elem: HTMLElement, styles: OptionalStyles): void {
  const _styles = {
    boxSizing: 'border-box',
    ...styles,
  };

  Object.keys(_styles).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const v = (_styles as any)[key];
    if (v !== undefined) {
      const x = toStyleText(v);
      elem.style.setProperty(key, x);
    }
  }, {});
}

export function create<T extends { [name: string]: OptionalStyles }>(
  styles: T,
): T {
  return styles;
}
