import React, { useState } from 'react';
import { println } from '../../apis/NativeApi';
import { Anomaly } from '../../util/Error';
import { createIntl } from '../i18n/Intl';
import { useContextStore } from '../states/ContextStore';
import { commonErrorDialogAction } from './AlertDialog';

type Props = React.PropsWithChildren<{
  cacheKey: string;
  fallback: React.ReactElement;
}>;

interface State {
  hasError: boolean;
}

const initialState = { hasError: false };

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  reset(): void {
    this.setState(initialState);
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, _: unknown): void {
    const a = Anomaly.of(error);
    println(a);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const { hasError } = this.state;
    const { cacheKey } = this.props;

    if (hasError && prevState.hasError && prevProps.cacheKey !== cacheKey) {
      this.reset();
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ShowErrorDialog>{this.props.fallback}</ShowErrorDialog>;
    }
    return this.props.children;
  }
}

const ShowErrorDialog: React.FC = ({ children }) => {
  const [shown, setShown] = useState(false);
  useContextStore({
    update: ({ data: { context }, dispatch }) => {
      if (!shown && context.alertDialog.state !== 'Show') {
        setShown(true);
        return dispatch.context(commonErrorDialogAction(createIntl(context)));
      } else {
        return dispatch;
      }
    },
  });
  return <>{children}</>;
};
