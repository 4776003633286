import React from 'react';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { LineSpace } from '../components/LineSpace';
import { createIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { NotificationList } from '../notification/Notification';
import { PeroduaLink } from '../perodualink/PeroduaLink';
import { WidgetList } from '../perodualink/WidgetList';
import { InitialRegistration } from '../registration/InitialRegistration';
import { SDLCard } from '../sdl/SDLCard';
import { ServiceReminderCard } from '../service-reminder/ServiceReminderCard';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Updater } from '../updater/Updater';
import { VehicleAlertCard } from '../vehicle-alert/VehicleAlertCard';
import { VehicleInfoCard } from '../vehicle-information/VehicleInfoCard';
import { VehicleLocationCard } from '../vehicle-location/VehicleLocationCard';
import { VehicleSelector } from '../vehicle-selector/VehicleSelector';
import { WifiCard } from '../wifi/WifiCard';
import { shouldShowNetworkErrorOnHome } from './Helpers';
import { HomeDataMap } from './Home';
import { HomeHeader } from './HomeHeader';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

export function HomeContainer(props: Props): React.ReactElement {
  const {
    views: { View, Image, Text },
    data: { context },
  } = useParentStore({ parent: props.parent });
  const Container = View;
  const viewPort = context.viewPort;
  const store = props.parent.home;
  const intl = createIntl(context);

  const ErrorView = (
    <>
      <LineSpace height={Length.px(4)} />
      <View
        style={{
          paddingTop: Length.px(10),
          paddingBottom: Length.px(10),
          paddingLeft: Length.px(12),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          borderTop: Border('solid', Length.px(0.5), Colors.border),
          borderBottom: Border('solid', Length.px(0.5), Colors.border),
        }}
      >
        <Image src={Icons.ic_info_normal} />
        <View style={{ minWidth: Length.px(12) }} />
        <Text style={{ fontSize: Length.px(16) }}>
          {intl
            .formatMessage({ id: 'networkError_noInternerConnection' })
            .toString()}
        </Text>
      </View>
    </>
  );

  return (
    <Container
      ref={context.homeContainer.getRef()}
      id='HomeContainer'
      style={{
        position: 'absolute',
        overflow:
          context.cardInfo.state === 'Opening' ||
          context.cardInfo.state === 'Opened'
            ? 'hidden'
            : 'scroll',
        top: Length.px(0),
        left: Length.px(0),
        width: Length.px(viewPort.width),
        height: Length.px(viewPort.height),
      }}
    >
      <HomeHeader parent={props.parent} />

      <LineSpace height={Length.px(8)} />

      {Scope(() => {
        if (context.contentState === 'NoVehicle') {
          return <InitialRegistration parent={props.parent} />;
        } else {
          return theme(
            <>
              <VehicleSelector store={store} />
              <Updater parent={props.parent} />
              {!shouldShowNetworkErrorOnHome(context) ? (
                <>
                  <NotificationList parent={props.parent} />
                  <VehicleInfoCard store={store} />
                  <VehicleLocationCard parent={props.parent} />
                  <ServiceReminderCard store={store} />
                  <SDLCard parent={props.parent} />
                  <VehicleAlertCard store={store} />
                  <WifiCard store={store} />
                </>
              ) : (
                ErrorView
              )}
            </>,
            <>
              <VehicleSelector store={store} />
              <Updater parent={props.parent} />
              {!shouldShowNetworkErrorOnHome(context) ? (
                <>
                  <NotificationList parent={props.parent} />
                  <PeroduaLink parent={props.parent} />
                  <VehicleInfoCard store={store} />
                  <VehicleAlertCard store={store} />
                  <WidgetList parent={props.parent} />
                </>
              ) : (
                ErrorView
              )}
            </>,
          );
        }
      })}

      <LineSpace height={Length.px(context.screenSize.marginBottom)} />
    </Container>
  );
}
