import React from 'react';
import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Option } from '../../util/Option';
import { toTouchVector } from '../events/Events';
import { Swipe } from '../events/Swipe';
import { Message } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import { Icons } from '../icons/Icons';
import { ViewPort } from '../states/Context';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { ExtractLocalState } from '../states/Reducer';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transition } from '../style/Transition';
import { Shape } from './Shape';
import { ShapeViewBase } from './View';

type Key = 'helpDialog';
export type HelpDialogData = Data;

type Data = Readonly<{
  localState: LocalState;
  container: Shape;
}>;

type InnerState = 'Opening' | 'Opened' | 'Closing' | 'Closed';

type LocalState = Readonly<{
  swipe: ExtractLocalState<Swipe>;
  innerState: InnerState;
}>;

type Actions = Readonly<{
  onTouchStart: (data: Data, touch: Vector) => Data;
  onTouchMove: (data: Data, touch: Vector) => Data;
  updateInnerState: (data: Data, innerState: InnerState) => Data;
}>;

export type State = 'Show' | 'Hide';

type DM<Parent> = ExtendParent<Parent, 'helpDialog', Data>;
type DMC<Parent> = WithContext<DM<Parent>>;
type DP<Parent> = DataMapDispatch<DMC<Parent>>;

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  title: Message;
  message: Message;
  state: State;
  // open?: boolean;
  onClose?: (_: DP<Parent>) => DP<Parent>;
}>;

const sideMargin = 16;
const bottomMargin = 16;
const duration = Time.ms(300);
const timingFunction = TimingFunction.easeOut;

function toSwipe(data: Data): Swipe {
  return {
    localState: data.localState.swipe,
  };
}

function getContainerStyle(
  state: State,
  container: Shape,
  viewPort: ViewPort,
  innerState: InnerState,
): OptionalStyles {
  const style: OptionalStyles = {
    position: 'absolute',
    left: Length.px(sideMargin),
    width: Length.px(viewPort.width - sideMargin * 2),
    transition: [Transition('top', duration, timingFunction)],
  };

  return container
    .getBound()
    .map<OptionalStyles>((bound) => {
      if (state === 'Show') {
        return {
          ...style,
          top: Length.px(viewPort.height - bottomMargin - bound.height),
        };
      } else {
        return {
          ...style,
          top: Length.px(viewPort.height),
          left: Length.px(
            sideMargin + (innerState === 'Closed' ? viewPort.width : 0),
          ),
        };
      }
    })
    .getOrElse(() => style);
}

function isClosedBySwipe(data: Data): boolean {
  const swipe = Swipe.actions.onTouchEnd(toSwipe(data)).localState;
  return swipe.dxy.y > 20;
}

const actions: Actions = {
  onTouchStart: (data: Data, touch: Vector) => {
    return copy(data, {
      localState: {
        swipe: Swipe.actions.onTouchMove(toSwipe(data), touch).localState,
      },
    });
  },

  onTouchMove: (data: Data, touch: Vector) => {
    return copy(data, {
      localState: {
        swipe: Swipe.actions.onTouchMove(toSwipe(data), touch).localState,
      },
    });
  },

  updateInnerState: (data: Data, innerState: InnerState) =>
    copy(data, {
      localState: { innerState },
    }),
};

export function HelpDialog<Parent>(props: Props<Parent>): React.ReactElement {
  const {
    views,
    data: { context, helpDialog: data },
    dispatch,
    stores,
  } = useStore<Key, Data, Parent>({
    key: 'helpDialog',
    parent: props.parent,
    default: () => ({
      localState: {
        swipe: Swipe().localState,
        innerState: 'Closed',
      },
      container: Shape({}),
    }),
    update: ({ data: { helpDialog: data }, dispatch }) =>
      dispatch.pipe((_) => {
        if (props.state === 'Show' && data.localState.innerState === 'Closed') {
          return _.helpDialog(actions.updateInnerState, 'Opening');
        } else if (
          props.state === 'Hide' &&
          data.localState.innerState === 'Opened'
        ) {
          return _.helpDialog(actions.updateInnerState, 'Closing');
        } else {
          return _;
        }
      }),
  });

  const View = views.default;
  const Container = views.helpDialog.container as ShapeViewBase<DMC<Parent>>;
  const viewPort = context.viewPort;

  return (
    <Container
      style={getContainerStyle(
        props.state,
        data.container,
        viewPort,
        data.localState.innerState,
      )}
      onTouchStart={(evt) => {
        evt.stopPropagation();
        return dispatch.helpDialog(actions.onTouchStart, toTouchVector(evt));
      }}
      onTouchMove={(evt) => {
        evt.stopPropagation();
        return dispatch.helpDialog(actions.onTouchMove, toTouchVector(evt));
      }}
      onTouchEnd={(evt) => {
        evt.stopPropagation();
        if (isClosedBySwipe(data) && props.onClose) {
          return props.onClose(dispatch);
        } else {
          return dispatch;
        }
      }}
      onTransitionEnd={(evt) => {
        return dispatch
          .effect(() => evt.stopPropagation())
          .pipe((_) =>
            props.state === 'Show'
              ? _.helpDialog(actions.updateInnerState, 'Opened')
              : props.state === 'Hide'
              ? _.helpDialog(actions.updateInnerState, 'Closed')
              : _,
          );
      }}
    >
      {/* Inner Container Pane */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: Length.px(200),
          maxHeight: Length.px(viewPort.height - bottomMargin * 2),
          filter: DropShadow(
            Length.px(0),
            Length.px(4),
            Length.px(12),
            Colors.shadowDark,
          ),
          borderRadius: Length.px(16),
          backgroundColor: Colors.background,
        }}
      >
        {/* Header Pane */}
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: Length.px(16),
          }}
        >
          {/* Title Pane */}
          <View
            style={{
              display: 'inline-block',
              fontSize: Length.px(20),
              fontWeight: 'bold',
              lineHeight: Length.px(20),
              paddingTop: Length.px(24),
              paddingBottom: Length.px(20),
            }}
          >
            {props.title.toReactNode()}
          </View>

          {/* Close Button */}
          <View
            style={{
              display: 'inline-block',
              width: Length.px(48),
              height: Length.px(48),
            }}
          >
            <IcButton
              parent={stores}
              image={Icons.ic_close_normal}
              imageWidth={Length.px(48)}
              imageHeight={Length.px(48)}
              onClick={(dispatch) =>
                Option(props.onClose).unwrap(
                  (f) => f(dispatch),
                  () => dispatch,
                )
              }
            />
          </View>
          {/* End of Close Button */}
        </View>
        {/* End of Header Pane */}

        {/* Content Pane */}
        <View
          style={{
            flexGrow: 1,
            paddingLeft: Length.px(16),
            paddingRight: Length.px(16),
            paddingBottom: Length.px(16),
            overflow: 'scroll',
          }}
        >
          <View
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
            }}
          >
            {props.message.toReactNode()}
          </View>
        </View>
        {/* End of Content Pane */}
      </View>
      {/* Inner Container Pane */}
    </Container>
  );
}
