// auto generated by scripts/icons.sh via scripts/make_inlined_assets.sh.
import { IconsCommon } from '../../images/EncodedIconsCommon';
import { IconsDaihatsu } from '../../images/EncodedIconsDaihatsu';
import { IconsPerodua } from '../../images/EncodedIconsPerodua';
import { theme } from '../../util/Theme';

export const Icons = {
  ...IconsCommon,
  btn_location_default: theme(
    IconsDaihatsu.btn_location_default,
    IconsPerodua.btn_location_default,
  ),
  btn_step1_on: theme(IconsDaihatsu.btn_step1_on, IconsPerodua.btn_step1_on),
  btn_step2_on: theme(IconsDaihatsu.btn_step2_on, IconsPerodua.btn_step2_on),
  btn_step3_on: theme(IconsDaihatsu.btn_step3_on, IconsPerodua.btn_step3_on),
  btn_step4_on: theme(IconsDaihatsu.btn_step4_on, IconsPerodua.btn_step4_on),
  btn_step5_on: theme(IconsDaihatsu.btn_step5_on, IconsPerodua.btn_step5_on),
  ic_action: theme(IconsDaihatsu.ic_action, IconsPerodua.ic_action),
  ic_back_normal: theme(
    IconsDaihatsu.ic_back_normal,
    IconsPerodua.ic_back_normal,
  ),
  ic_checkmark: theme(IconsDaihatsu.ic_checkmark, IconsPerodua.ic_checkmark),
  ic_close_normal: theme(
    IconsDaihatsu.ic_close_normal,
    IconsPerodua.ic_close_normal,
  ),
  ic_delete: theme(IconsDaihatsu.ic_delete, IconsPerodua.ic_delete),
  ic_forward_normal: theme(
    IconsDaihatsu.ic_forward_normal,
    IconsPerodua.ic_forward_normal,
  ),
  ic_geofence_center: theme(
    IconsDaihatsu.ic_geofence_center,
    IconsPerodua.ic_geofence_center,
  ),
  ic_location_vehicle: theme(
    IconsDaihatsu.ic_location_vehicle,
    IconsPerodua.ic_location_vehicle,
  ),
  ic_logo_horizontal_en: theme(
    IconsDaihatsu.ic_logo_horizontal_en,
    IconsPerodua.ic_logo_horizontal_en,
  ),
  ic_owner_delete: theme(
    IconsDaihatsu.ic_owner_delete,
    IconsPerodua.ic_owner_delete,
  ),
  ic_reload: theme(IconsDaihatsu.ic_reload, IconsPerodua.ic_reload),
  ic_reset: theme(IconsDaihatsu.ic_reset, IconsPerodua.ic_reset),
  ic_user: theme(IconsDaihatsu.ic_user, IconsPerodua.ic_user),
  ic_waiting_red: theme(
    IconsDaihatsu.ic_waiting_red,
    IconsPerodua.ic_waiting_red,
  ),
};
