import React from 'react';
import { useContextStore } from '../states/ContextStore';

export function FallbackError({
  error,
  componentStack,
}: Readonly<{
  error: Error;
  componentStack: string | null;
}>): React.ReactElement {
  const store = useContextStore();
  const { View } = store.views;
  return (
    <View>
      <View>You have encountered an error</View>
      <View>{error.toString()}</View>
      <View>{componentStack}</View>
    </View>
  );
}
