import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { Drag } from '../events/Drag';
import { toTouchVector } from '../events/Events';
import { Swipe } from '../events/Swipe';
import { Message } from '../i18n/Intl';
import { IcButton } from '../icons/IcButton';
import {
  CommandActions,
  CommandLocalState,
  CommandState,
} from '../states/CommandState';
import { ScreenSize, ViewPort } from '../states/Context';
import {
  DataMapDispatch,
  DataMapStore,
  ExtendParent,
  WithContext,
  useStore,
} from '../states/DataMapStore';
import { ExtractLocalState } from '../states/Reducer';
import { Border } from '../style/Border';
import { Bound } from '../style/Bound';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { OptionalStyles } from '../style/Style';
import { Time } from '../style/Time';
import { TimingFunction } from '../style/TimingFunction';
import { Transform } from '../style/Transform';
import { Transition } from '../style/Transition';
import { Shape } from './Shape';
import { ShapeViewBase } from './View';

type Key = 'slideInCard';
export type SlideInCardData = Data;
export type SlideInCardState = State;

type Data = Readonly<{
  kind: 'SlideIn';
  localState: LocalState;
  target: Shape;
}>;

interface LocalState extends CommandLocalState<Command> {
  readonly innerState: InnerState;
  readonly closeType: CloseType;
  readonly drag: Drag;
  readonly swipe: ExtractLocalState<Swipe>;
}

interface Actions extends CommandActions<Data, Command> {
  readonly updateInnerState: (data: Data, innerState: InnerState) => Data;
  readonly onTouchStart: (data: Data, touch: Vector) => Data;
  readonly onTouchMove: (
    data: Data,
    args: { viewPort: ViewPort; touch: Vector },
  ) => Data;
  readonly onTouchEnd: (data: Data, viewPort: ViewPort) => Data;
  readonly updateCloseType: (data: Data, closeType: CloseType) => Data;
}

type InnerState = 'Opening' | 'Opened' | 'Closing' | 'Closed';

export type CloseType = 'None' | 'Pull' | 'CloseButton';

type Command = 'CallOnOpen' | 'CallOnClose';

// type TransitionEndCommand = 'CallOnOpened' | 'CallOnClosed';

export type SlideInActions = Actions;
type State = 'Show' | 'Hide';

type Props<Parent> = Readonly<{
  parent: DataMapStore<Parent>;
  state: State;
  title: Message;
  closeIcon: string;
  closeText?: Message;
  minHeight: number;
  children?: React.ReactNode;
  onOpen?: (_: ED<Parent>) => ED<Parent>;
  onClose?: (closeType: CloseType, _: ED<Parent>) => ED<Parent>;
  onOpened?: (data: Data, _: ED<Parent>) => ED<Parent>;
  onClosed?: (data: Data, _: ED<Parent>) => ED<Parent>;
  onChange?: (bound: Bound, _: ED<Parent>) => ED<Parent>;
}>;

type DM<Parent> = ExtendParent<Parent, Key, Data>;
type DMC<Parent> = WithContext<DM<Parent>>;
type ED<Parent> = DataMapDispatch<WithContext<Parent>>;
type DP<Parent> = DataMapDispatch<DMC<Parent>>;

const duration = Time.ms(300);
const timingFunction = TimingFunction.easeOut;
const commandState = CommandState<Data, Command>();
const defaultTransitions = [
  Transition('top', duration, timingFunction),
  Transition('transform', duration, timingFunction),
];

function toSwipe(data: Data): Swipe {
  return {
    localState: data.localState.swipe,
  };
}

function getTargetStyle<Parent>(
  target: Shape,
  props: Props<Parent>,
  screenSize: ScreenSize,
  innerState: InnerState,
): OptionalStyles {
  const style: OptionalStyles = {
    position: 'absolute',
    top: Length.px(screenSize.height),
    left: innerState === 'Closed' ? Length.px(screenSize.width) : Length.px(0),
    width: Length.px(screenSize.width),
    backgroundColor: Colors.background,
    borderTopLeftRadius: Length.px(16),
    borderTopRightRadius: Length.px(16),
    paddingBottom: Length.px(screenSize.marginBottom),
  };

  return target
    .getBound()
    .map<OptionalStyles>((bound) => {
      if (props.state === 'Show') {
        const top =
          bound.height > screenSize.height
            ? 0
            : screenSize.height - bound.height;
        return {
          ...style,
          top: Length.px(top),
          height: Length.px(bound.height),
          filter: DropShadow(
            Length.px(0),
            Length.px(4),
            Length.px(12),
            Colors.shadowDark,
          ),
        };
      } else {
        return {
          ...style,
          top: Length.px(screenSize.height),
          filter: undefined,
        };
      }
    })
    .getOrElse(() => style);
}

function shouldClose(data: Data, viewPort: ViewPort): boolean {
  const swipe = Swipe.actions.onTouchEnd(toSwipe(data)).localState;
  const bound = data.target.getBound().getUnsafeValue();
  const y = bound.top;
  const height = bound.height;
  const maxY =
    height > viewPort.height
      ? viewPort.height / 2
      : viewPort.height - height / 2;
  return swipe.dxy.y > 20 || y > maxY;
}

function onTransitionEnd<Parent>(
  evt: React.TransitionEvent,
  props: Props<Parent>,
  data: Data,
  dispatch: DP<Parent>,
): DP<Parent> {
  return dispatch
    .effect(() => evt.stopPropagation())
    .pipe((_) =>
      props.state === 'Show'
        ? _.slideInCard(actions.updateInnerState, 'Opened').pipe((_) =>
            Option(props.onOpened).unwrap(
              (f) => _.compose(_.unsafeCast<ED<Parent>>((a) => f(data, a))),
              () => _,
            ),
          )
        : props.state === 'Hide'
        ? _.slideInCard(actions.updateInnerState, 'Closed').pipe((_) =>
            Option(props.onClosed).unwrap(
              (f) => _.compose(_.unsafeCast<ED<Parent>>((a) => f(data, a))),
              () => _,
            ),
          )
        : _,
    );
}

const actions: Actions = {
  ...commandState.actions,

  updateInnerState: (data: Data, innerState: InnerState) =>
    copy(data, {
      localState: { innerState },
    }),

  onTouchStart: (data: Data, touch: Vector) => {
    return copy(data, {
      localState: {
        drag: Drag.actions.onTouchStart(data.localState.drag, touch),
        swipe: Swipe.actions.onTouchMove(toSwipe(data), touch).localState,
      },
      target: data.target.updateStyles({
        transition: [],
      }),
    });
  },

  onTouchMove: (data: Data, { viewPort, touch }) => {
    const drag = Drag.actions.onTouchMove(data.localState.drag, touch);
    const bound = data.target.getBound().getUnsafeValue();
    const y = bound.top - viewPort.height;
    const touchY = touch.y - viewPort.height;
    const maxY = -30;
    const minY = -bound.height;
    return copy(data, {
      localState: {
        drag: drag,
        swipe: Swipe.actions.onTouchMove(toSwipe(data), touch).localState,
      },
      target: Scope(() => {
        if (touchY <= minY || touchY >= maxY) {
          return data.target;
        } else if (y >= minY && y <= maxY) {
          return data.target.updateStyles({
            transform: data.target.translate(0, drag.dt.y),
          });
        } else if (y < minY) {
          return data.target.updateStyles({
            transform: Transform(Matrix3x3.translate(0, 0)),
          });
        } else if (y > maxY) {
          return data.target.updateStyles({
            transform: Transform(Matrix3x3.translate(0, maxY)),
          });
        } else {
          return data.target;
        }
      }),
    });
  },

  onTouchEnd: (data: Data, viewPort: ViewPort) =>
    copy(data, {
      localState: {
        closeType: 'Pull',
        command: shouldClose(data, viewPort) ? 'CallOnClose' : 'CallOnOpen',
      },
      target: data.target.updateStyles({
        transform: Transform(Matrix3x3.translate(0, 0)),
        transition: defaultTransitions,
      }),
    }),

  updateCloseType: (data: Data, closeType: CloseType) =>
    copy(data, {
      localState: { closeType },
    }),
};

export function SlideInCard<Parent>(props: Props<Parent>): React.ReactElement {
  const state = useStore<Key, Data, Parent, Props<Parent>>({
    key: 'slideInCard',
    props,
    parent: props.parent,

    default: () => ({
      kind: 'SlideIn',
      localState: {
        command: commandState.default,
        innerState: 'Closed',
        closeType: 'None',
        drag: Drag(),
        swipe: Swipe().localState,
      },
      target: Shape({}),
    }),

    initialize: (data: Data) =>
      copy(data, {
        localState: {
          drag: Drag.initialize(data.localState.drag),
          swipe: Swipe.initialize(toSwipe(data)).localState,
        },
        target: data.target.updateStyles({
          transition: defaultTransitions,
        }),
      }),

    update: ({ data: { slideInCard: data }, dispatch }) => {
      const localState = data.localState;
      return commandState
        .handleDataMap<DMC<Parent>>(
          'slideInCard',
          data,
          dispatch,
          (command) => {
            if (command === 'CallOnOpen' && props.onOpen) {
              return dispatch.unsafeCast<ED<Parent>>(props.onOpen);
            } else if (command === 'CallOnClose') {
              return Option(props.onClose).unwrap(
                (f) =>
                  dispatch.unsafeCast<ED<Parent>>((_) =>
                    f(localState.closeType, _),
                  ),
                () => dispatch,
              );
            } else {
              return dispatch;
            }
          },
        )
        .pipe((_) => {
          if (props.state === 'Show' && localState.innerState === 'Closed') {
            return _.slideInCard(actions.updateInnerState, 'Opening');
          } else if (
            props.state === 'Hide' &&
            localState.innerState === 'Opened'
          ) {
            return _.slideInCard(actions.updateInnerState, 'Closing');
          } else {
            return _;
          }
        });
    },
  });

  const { View } = state.views;
  const Target = state.views.slideInCard.target as ShapeViewBase<DMC<Parent>>;
  const Notch = View;
  const Header = View;
  const Body = View;
  const viewPort = state.data.context.viewPort;
  const context = state.data.context;
  const data = state.data.slideInCard;
  const localState = data.localState;
  const isOnTransition =
    localState.innerState === 'Opening' || localState.innerState === 'Closing';
  const dispatch = state.dispatch;

  return (
    <Target
      style={getTargetStyle(
        data.target,
        props,
        context.screenSize,
        localState.innerState,
      )}
      onTouchStart={(evt) => {
        evt.stopPropagation();
        return dispatch.pipe((_) =>
          isOnTransition
            ? _
            : _.slideInCard(actions.onTouchStart, toTouchVector(evt)),
        );
      }}
      onTouchMove={(evt) => {
        evt.stopPropagation();
        return dispatch.pipe((_) =>
          isOnTransition
            ? _
            : _.pipe((_) =>
                _.slideInCard(actions.onTouchMove, {
                  viewPort,
                  touch: toTouchVector(evt),
                }),
              ).pipe((_) =>
                Option.join(
                  Option(props.onChange),
                  data.target.getBound(),
                ).unwrap(
                  ([onChange, bound]) =>
                    _.compose(
                      _.unsafeCast<ED<Parent>>((a) => onChange(bound, a)),
                    ),
                  () => _,
                ),
              ),
        );
      }}
      onTouchEnd={(evt) => {
        evt.stopPropagation();
        return dispatch.pipe((_) =>
          isOnTransition ? _ : _.slideInCard(actions.onTouchEnd, viewPort),
        );
      }}
      onTransitionEnd={(evt) => onTransitionEnd(evt, props, data, dispatch)}
    >
      <View
        style={{
          minHeight: Length.px(props.minHeight),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Notch
          style={{
            flexGrow: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: Length.px(6),
          }}
        >
          <View
            style={{
              width: Length.px(48),
              border: Border('solid', Length.px(3), Colors.thickBorder),
              borderRadius: Length.px(3),
              backgroundColor: Colors.thickBorder,
            }}
          />
        </Notch>

        {/* Header */}
        <Header
          style={{
            flexGrow: 0,
            paddingLeft: Length.px(16),
          }}
        >
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Title */}
            <View
              style={{
                fontSize: Length.px(20),
                fontWeight: 'bold',
                lineHeight: Length.px(20),
              }}
            >
              <View>{props.title.toReactNode()}</View>
            </View>

            {/* Close Button */}
            {props.onClose !== undefined && (
              <View
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: Length.px(16),
                  fontWeight: 'bold',
                  lineHeight: Length.px(16),
                  color: Colors.primary,
                  width: Length.px(48),
                  height: Length.px(48),
                  WebkitTapHighlightColor: 'transparent', // MON-698
                }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  return dispatch
                    .slideInCard(actions.updateCloseType, 'CloseButton')
                    .slideInCard(actions.updateCommand, 'CallOnClose');
                }}
              >
                <IcButton
                  image={props.closeIcon}
                  imageWidth={Percentage(100)}
                  imageHeight={Percentage(100)}
                />
              </View>
            )}
          </View>
        </Header>

        <Body
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingLeft: Length.px(16),
            paddingRight: Length.px(16),
          }}
        >
          {props.children}
        </Body>
      </View>
    </Target>
  );
}
