import React, { useCallback } from 'react';
import { config } from '../../Config';
import { openLink } from '../../apis/NativeApi';
import { IconsPerodua } from '../../images/EncodedIconsPerodua';
import { BuildOpt } from '../../util/BuildOpt';
import { HomeDataMap } from '../home/Home';
import { IcButton } from '../icons/IcButton';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';

const LinkButtons = [
  {
    image: IconsPerodua.btn_app_family,
    linkUrl: config[BuildOpt.env].dynamicLinkEndpoint + '/app/home',
  },
  {
    image: IconsPerodua.btn_app_locate,
    linkUrl: config[BuildOpt.env].dynamicLinkEndpoint + '/app/home',
  },
  {
    image: IconsPerodua.btn_app_shop,
    linkUrl: config[BuildOpt.env].dynamicLinkEndpoint + '/app/home',
  },
  {
    image: IconsPerodua.btn_app_sos,
    linkUrl: config[BuildOpt.env].dynamicLinkEndpoint + '/app/home',
  },
];

const iconSize = Length.px(88);

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

type LinkButtonProps = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  image: string;
  linkUrl: string;
}>;

const LinkButton: React.VFC<LinkButtonProps> = ({
  parent,
  image,
  linkUrl,
}: LinkButtonProps) => {
  const {
    views: { View },
    dispatch,
  } = useParentStore({ parent });

  const onClick = useCallback(
    (e) => {
      console.log('PeroduaLink Clicked', linkUrl);
      e.stopPropagation();
      return dispatch.effect(() => {
        if (linkUrl.length > 0) {
          openLink(linkUrl);
        }
      });
    },
    [linkUrl, dispatch],
  );

  return (
    <View
      style={{
        width: iconSize,
        height: iconSize,
      }}
      onClick={onClick}
    >
      <IcButton
        image={image}
        imageWidth={Percentage(100)}
        imageHeight={Percentage(100)}
      />
    </View>
  );
};

export const PeroduaLink: React.VFC<Props> = ({ parent }: Props) => {
  const {
    views: { View },
  } = useParentStore({ parent });

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: Colors.backgroundDark,
        paddingTop: Length.px(10),
        paddingBottom: Length.px(6),
      }}
    >
      {LinkButtons.map((link, i) => (
        <LinkButton key={`link-button-${i}`} {...link} parent={parent} />
      ))}
    </View>
  );
};
