import React from 'react';
import { Time } from '../style/Time';
import { IconProps } from './Helpers';
import { Icons } from './Icons';
import { SpinImage } from './SpinImage';

// eslint-disable-next-line react/display-name
export const IcSliderWaiting = React.forwardRef(
  (props: IconProps, ref: React.Ref<HTMLImageElement>) => {
    return (
      <SpinImage
        alt='ic_slider_waiting'
        src={Icons.ic_slider_waiting}
        duration={Time.ms(1200)}
        degree='15'
        ref={ref}
        {...props}
      />
    );
  },
);
