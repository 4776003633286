import React from 'react';
import { config } from '../../Config';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { CardHeader } from '../components/CardHeader';
import { LastUpdateDate } from '../components/LastUpdateDate';
import { ReloadButton } from '../components/ReloadButton';
import { SlideInWindow, SlideInWindowMode } from '../components/SlideInWindow';
import { getLastUpdate, isContentLoading } from '../home/Helpers';
import { HomeDataMap } from '../home/Home';
import { Message, useIntl } from '../i18n/Intl';
import { ContentState, Context, OngoingRemoteStates } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore, UpdateArgs, WithContext } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { SubPanes } from './SubPanes';
import { VehicleLocationBody } from './VehicleLocationBody';
import { VehicleLocationState } from './VehicleLocationState';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mode: SlideInWindowMode;
}>;

const mapId = 'monaka.vehicle.location.window.map';

export function GeofenceWindow(props: Props): React.ReactElement {
  const state = useParentStore<HomeDataMap, Props>({
    props,
    parent: props.parent,
    update: (args: UpdateArgs<Props, WithContext<HomeDataMap>>) =>
      VehicleLocationState.update<Props>({ ...args, mapId }),
  });

  const intl = useIntl();
  const context = state.data.context;
  const data = state.data.home;
  const localState = data.localState.geofence.localState;
  const dispatch = state.dispatch;
  const BodyOverlay = state.views.default;

  const lastUpdate: Date | Message = Scope(() => {
    if (context.dataErrors.serviceTelemetry)
      return intl.formatMessage({ id: 'Common_syncError' });
    else
      return context.serviceTelemetry.data
        .toOption()
        .flatMap((_) => _.position)
        .unwrap(
          (_) =>
            _.isOld
              ? intl.formatMessage({ id: 'Common_syncError' })
              : new Date(getLastUpdate(context)),
          () => intl.formatMessage({ id: 'Common_syncError' }),
        );
  });
  const contentState = Scope<ContentState>(() => {
    if (
      context.contentState === 'Loading' ||
      localState.googleMapState !== 'Loaded'
    ) {
      return 'Loading';
    } else if (
      context.contentState === 'Updating' ||
      localState.googleMapState !== 'Loaded'
    ) {
      return 'Updating';
    } else {
      return context.contentState;
    }
  });
  const ongoingState = context.selectedVehicleVin.some((vin) =>
    Option(context.ongoingRemoteStates[vin]).some((s) =>
      Object.keys(s).some((key) => {
        type A = OngoingRemoteStates & { readonly [key: string]: boolean };
        return (context.ongoingRemoteStates as A)[key];
      }),
    ),
  );

  return (
    <>
      <SlideInWindow
        parent={props.parent}
        mode={props.mode}
        title={intl.formatMessage({ id: 'Location_pageTitle' })}
        subTitle={
          <LastUpdateDate
            lastUpdate={lastUpdate}
            intl={intl}
            contentState={contentState}
            ongoingState={ongoingState}
          />
        }
        headerLeft={
          <ReloadButton
            parent={state.stores}
            contentState={contentState}
            ongoingState={ongoingState}
            onReload={() =>
              dispatch
                .context(Context.actions.updateContentState, 'Reload')
                .context(
                  Context.actions.updateFetchVehicleDataOptions,
                  config.fetchDataOptions.onReloadVehicleLocation,
                )
            }
          />
        }
        direction='vertical'
        onOpen={(dispatch) => VehicleLocationState.onOpen(context, dispatch)}
        onOpened={(dispatch) => VehicleLocationState.onOpened(dispatch)}
        onClose={(dispatch) =>
          VehicleLocationState.onClose(data, dispatch).compose(
            dispatch.context(Context.actions.updateGeofenceWindowMode, 'Hide'),
          )
        }
        onClosed={(dispatch) => VehicleLocationState.onClosed(dispatch)}
        disableCloseWhileLoading={true}
      >
        <VehicleLocationBody
          parent={props.parent}
          mapId={mapId}
          headerHeight={CardHeader.height}
        />
      </SlideInWindow>
      <SubPanes parent={props.parent} />
      {props.mode === 'Show' && isContentLoading(context) && (
        <BodyOverlay
          style={{
            position: 'absolute',
            top: CardHeader.height,
            left: Length.px(0),
            width: Length.px(context.viewPort.width),
            height: Length.px(context.viewPort.height),
            backgroundColor: Colors.overlayCover,
          }}
        />
      )}
    </>
  );
}
