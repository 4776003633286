import * as Client from '@auto/monaka-client/dist/20';
import { createVehicle, getDB, updateDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { debugAsync1 } from '../util/Debug';
import { getErrorMessage } from '../util/Error';
import { Option } from '../util/Option';
import { sleep } from '../util/Sleep';
import { AppSyncClient } from './Client';
import { genVehicleEvents } from './EventTelemetry';
import { println } from './NativeApi';
import { defaultNotificationStatus } from './Notification';
import {
  defaultVehicleLocation,
  genServiceTelemetry,
} from './ServiceTelemetry';
import { defaultWifiStatus } from './Wifi';

export async function pairActivate(vin: string): Promise<void> {
  return debugAsync1('pairActivate', async () => {
    if (BuildOpt.isMock()) {
      await sleep(1000);
      println(`PairActivating vehicle and user for vin=${vin}`);
      const db = await getDB();
      if (db.pairActivateError)
        return Promise.reject(Error('Could not pairActivate the vehicle'));
      return;
    } else {
      return AppSyncClient.pairActivate(vin).catch((err) => {
        const msg = Error(getErrorMessage(err));
        println(msg);
        return Promise.reject(msg);
      });
    }
  });
}

export async function pair(
  vin: string,
  bssid: string,
): Promise<{ paired: boolean }> {
  return debugAsync1('pairVehicle', async () => {
    if (BuildOpt.isMock()) {
      await sleep(1000);
      println(`Pairing vehicle and user for vin=${vin}, bssid=${bssid}`);
      const db = await getDB();
      const v = createVehicle(db);
      if (db.pairVehicleError)
        return Promise.reject(Error('Could not pair the vehicle'));
      else {
        const events = genVehicleEvents(v.vin);
        await updateDB(db, {
          vehicles: db.vehicles.concat([v]),
          serviceTelemetries: db.serviceTelemetries
            .filter((s) => s.vin !== v.vin)
            .concat([
              genServiceTelemetry({
                vin: v.vin,
                position: defaultVehicleLocation,
              }),
            ]),
          vehicleEvents: db.vehicleEvents
            .filter((e) => e.vin !== v.vin)
            .concat([{ vin: v.vin, events }]),
          userVehicleEvents: db.userVehicleEvents
            .filter((e) => e.vin !== v.vin)
            .concat([{ vin: v.vin, events }]),
          wifiStatuses: db.wifiStatuses
            .filter((s) => s.vin !== v.vin)
            .concat([defaultWifiStatus(v.vin)]),
          notificationStatuses: db.notificationStatuses
            .filter((s) => s.vin !== v.vin)
            .concat([defaultNotificationStatus(v.vin)]),
        });
        return {
          paired: true,
        };
      }
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      println(
        `Pairing vehicle and user for gSysUserId=${gSysUserId}, vin=${vin}, ssid=${bssid}`,
      );
      return AppSyncClient.pair({
        gSysUserId,
        vin,
        bssid,
      })
        .then((_) => {
          if (_.paired === false) {
            const msg = Error('Could not pair the vehicle');
            return Promise.reject(msg);
          } else {
            return Promise.resolve(_);
          }
        })
        .catch((err) => {
          const msg = Error(getErrorMessage(err));
          println(msg);
          return Promise.reject(msg);
        });
    }
  });
}

export async function deleteVehicle(vin: string): Promise<void> {
  return debugAsync1('deleteVehicle', async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      const db = await getDB();
      await updateDB(db, {
        vehicles: db.vehicles.filter((_) => _.vin !== vin),
        serviceTelemetries: db.serviceTelemetries.filter((_) => _.vin !== vin),
        vehicleEvents: db.vehicleEvents.filter((_) => _.vin !== vin),
        userVehicleEvents: db.userVehicleEvents.filter((_) => _.vin !== vin),
      });
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      await AppSyncClient.deleteVehicle(gSysUserId, vin);
    }
  });
}
