import React from 'react';
import { println } from '../../apis/NativeApi';
import {
  RemoteCommandAction,
  RemoteCommandName,
  createLastRemoteCommandAction,
  sendRemoteCommand,
  subscribeRemoteCommand,
  unsubscribeRemoteCommand,
} from '../../apis/RemoteCommand';
import {
  getRemoteCommandErrorMessage,
  updateOngoingRemoteStates,
} from '../../apis/RemoteCommandExecuter';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { WifiStatus, getWifiStatus } from '../../apis/Wifi';
import { Matrix3x3 } from '../../math/Matrix';
import { toRadian } from '../../math/Radian';
import { Anomaly } from '../../util/Error';
import { Option, Some } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { Unit } from '../../util/Unit';
import {
  commonErrorDialogAction,
  errorDialogAction,
} from '../components/AlertDialog';
import { LineSpace } from '../components/LineSpace';
import { ToggleSwitch } from '../components/ToggleSwitch';
import { HomeActions, HomeDataMap } from '../home/Home';
import { Message, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { ActionBinder } from '../states/ActionBinder';
import { Context } from '../states/Context';
import { useContextStore, useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Transform } from '../style/Transform';

const imageWidth = Length.px(160);
const imageHeight = Length.px(300);

function VehicleImage({
  wifiStatus,
}: Readonly<{
  wifiStatus: WifiStatus;
}>): React.ReactElement {
  const {
    views: { View, Image },
  } = useContextStore();
  return (
    <>
      <View
        style={{
          position: 'absolute',
          transform: Transform(
            Matrix3x3.translate(160, -50)
              .product(Matrix3x3.rotation(toRadian(90)))
              .to3x3(),
          ),
        }}
      >
        <Image
          src={Icons.img_vehicle_03_body}
          style={{
            position: 'absolute',
          }}
        />

        <Image
          src={Icons.img_vehicle_04_doors_normal}
          style={{
            position: 'absolute',
          }}
        />

        <Image
          src={Icons.img_vehicle_05_light_normal}
          style={{
            position: 'absolute',
          }}
        />
      </View>

      <View
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: imageWidth,
          height: imageHeight,
        }}
      >
        {Scope(() => {
          if (wifiStatus.wifiStatus) {
            return <Image src={Icons.ic_wifi_on} style={{}} />;
          } else {
            return <Image src={Icons.ic_wifi_off} style={{}} />;
          }
        })}
      </View>
    </>
  );
}

function WifiToggleSwitch({
  parent,
  wifiStatus,
  isSpinning,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  wifiStatus: WifiStatus;
  isSpinning: boolean;
}>): React.ReactElement {
  const {
    dispatch,
    data: { context },
  } = useParentStore({ parent });
  const intl = useIntl();
  return (
    <ToggleSwitch
      parent={parent}
      isEnabled={wifiStatus.wifiRequest}
      isSpinning={isSpinning}
      onChange={(isEnabled) => {
        if (
          !isEnabled &&
          context.ssidInfo.ssid !== '' &&
          context.ssidInfo.ssid === wifiStatus.currentSSID
        ) {
          return dispatch.context(
            errorDialogAction(
              intl.formatMessage({ id: 'WiFi_dialogWifiAlertTitle' }),
              intl.formatMessage({ id: 'WiFi_dialogWifiAlertBody' }),
            ),
          );
        }
        return getSelectedVehicle(context)
          .map((vehicle) => {
            const commandName: RemoteCommandName = 'wifi';
            const commandAction: RemoteCommandAction<'wifi'> = isEnabled
              ? 'true'
              : 'false';
            const lastCommandAction = createLastRemoteCommandAction(
              commandName,
              commandAction,
            );

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function errorAction(err: any): ActionBinder<Context> {
              const a = Anomaly.of(err);
              const m = getRemoteCommandErrorMessage(
                vehicle,
                commandName,
                commandAction,
                a.code,
                intl,
              );
              return errorDialogAction(m.dialogTitle, m.dialogBody)
                .bind(
                  updateOngoingRemoteStates(Some(vehicle.vin), 'wifi', false),
                )
                .bind(Context.actions.updateWifiStatus, {
                  wifiRequest: !isEnabled,
                  // wifiStatus: !isEnabled,
                });
            }

            return dispatch
              .context(Context.actions.updateWifiStatus, {
                wifiRequest: isEnabled,
                // wifiStatus: isEnabled,
              })
              .context(
                updateOngoingRemoteStates(Some(vehicle.vin), 'wifi', true),
              )
              .asyncAll(
                subscribeRemoteCommand(commandName, vehicle.dcmId, vehicle.vin)
                  .then(async (listener) => {
                    await sendRemoteCommand({
                      vin: vehicle.vin,
                      dcmId: vehicle.dcmId,
                      name: commandName,
                      type: 'vehicle',
                      action: commandAction,
                      params: {},
                    });

                    await listener.toPromise();
                    println(
                      `A response of the subscribed WiFi command has been received.`,
                    );

                    await unsubscribeRemoteCommand(commandName, vehicle.dcmId);

                    println(`The WiFi command has been unsubscribed`);

                    const wifiStatus = await getWifiStatus(vehicle.vin);

                    println(
                      `A WiFi status has been recieved: ${JSON.stringify(
                        wifiStatus,
                      )}`,
                    );

                    return dispatch
                      .context(Context.actions.showToast, {
                        state: 'Show',
                        message: intl.formatMessage({
                          id: wifiStatus.wifiRequest
                            ? 'WiFi_wifiOnMsg'
                            : 'WiFi_wifiOffMsg',
                        }),
                      })
                      .context(
                        updateOngoingRemoteStates(
                          Some(vehicle.vin),
                          'wifi',
                          false,
                        ),
                      )
                      .context(Context.actions.updateWifiStatus, wifiStatus)
                      .context(lastCommandAction.add)
                      .asyncAll(
                        lastCommandAction.remove
                          .then((action) => dispatch.context(action))
                          .catch((_err) =>
                            dispatch.effect(() =>
                              println(
                                `WARN: Failed to remove last command(${vehicle.vin}, ${commandName}, ${commandAction})`,
                              ),
                            ),
                          ),
                      );
                  })
                  .catch((err) =>
                    dispatch
                      .effect(() => println(err))
                      .context(errorAction(err)),
                  ),
              );
          })
          .getOrElse(() => {
            println(Error(`A vehicle has not been selected yet`));
            return dispatch.context.bind(commonErrorDialogAction(intl), Unit);
          });
      }}
    />
  );
}

function RightListItems({
  parent,
  wifiStatus,
  isSpinning,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  wifiStatus: WifiStatus;
  isSpinning: boolean;
}>): React.ReactElement {
  const {
    data: { context },
  } = useContextStore();
  const intl = useIntl();
  const tethering = intl.formatMessage({
    id:
      context.ssidInfo.ssid === wifiStatus.currentSSID
        ? 'WiFi_onTethering'
        : 'WiFi_noTethering',
  });
  return (
    <>
      <ListItem
        title={intl.formatMessage({ id: 'WiFi_wifiBtn' })}
        message={intl.formatMessage({
          id: wifiStatus.wifiRequest ? 'WiFi_wifiOn' : 'WiFi_wifiOff',
        })}
        right={
          <WifiToggleSwitch
            parent={parent}
            wifiStatus={wifiStatus}
            isSpinning={isSpinning}
          />
        }
        height={Length.px(72)}
      />

      <ListItem
        title={intl.formatMessage({ id: 'WiFi_ssid' })}
        message={Message(wifiStatus.currentSSID)}
        height={Length.px(64)}
        borderTop={Length.px(0.5)}
      />

      <ListItem
        title={intl.formatMessage({ id: 'WiFi_initialPwd' })}
        message={Message(wifiStatus.initialPassword)}
        height={Length.px(64)}
        borderTop={Length.px(0.5)}
      />

      <ListItem
        title={intl.formatMessage({ id: 'WiFi_tethering' })}
        message={tethering}
        height={Length.px(64)}
        borderTop={Length.px(0.5)}
      />
    </>
  );
}

function ListItem(
  props: Readonly<{
    title: Message;
    message: Message;
    right?: React.ReactNode;
    height: Length;
    borderTop?: Length;
  }>,
): React.ReactElement {
  const {
    views: { View, Text },
  } = useContextStore();
  const Left = View;
  const Right = View;

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: props.borderTop
          ? Border('solid', props.borderTop, Colors.border)
          : undefined,
        boxSizing: 'border-box',
        height: props.height,
      }}
    >
      <Left
        style={{
          marginTop: Length.px(14),
          marginBottom: Length.px(18),
        }}
      >
        <View>
          <Text
            style={{
              fontSize: Length.px(12),
              lineHeight: Length.px(12),
              fontWeight: 'bold',
            }}
          >
            {props.title.toReactNode()}
          </Text>
        </View>

        <LineSpace height={Length.px(4)} />

        <View>
          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(16),
            }}
          >
            {props.message.toReactNode()}
          </Text>
        </View>
      </Left>

      <Right
        style={{
          marginTop: Length.px(12),
          marginBottom: Length.px(12),
          marginRight: Length.px(8),
        }}
      >
        {props.right}
      </Right>
    </View>
  );
}

export function OpenedView(
  props: Readonly<{
    parent: DataMapStore<HomeDataMap>;
  }>,
): React.ReactElement {
  const {
    views: { View, Text },
    data: { context },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const VehicleImagePane = View;
  const WifiInfoPane = View;
  const intl = useIntl();
  const wifiStatus = context.wifiStatus;
  const isSpinning = context.selectedVehicleVin.some((vin) =>
    Option(context.ongoingRemoteStates[vin]).some((_) => _.wifi),
  );

  return (
    <View>
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <VehicleImagePane
          style={{
            position: 'relative',
            flexGrow: 1,
            width: imageWidth,
            overflow: 'hidden',
          }}
        >
          <VehicleImage wifiStatus={wifiStatus} />
        </VehicleImagePane>

        <WifiInfoPane
          style={{
            flexGrow: 1,
          }}
        >
          <RightListItems
            parent={props.parent}
            wifiStatus={wifiStatus}
            isSpinning={isSpinning}
          />
        </WifiInfoPane>
      </View>

      <View
        style={{
          paddingLeft: Length.px(16),
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: Length.px(14),
            paddingBottom: Length.px(18),
            paddingRight: Length.px(16),
            borderTop: Border('solid', Length.px(0.5), Colors.border),
            boxSizing: 'border-box',
            height: Length.px(48),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              fontWeight: 'bold',
              lineHeight: Length.px(20),
            }}
          >
            {intl.formatMessage({ id: 'WiFi_wifiUsage' }).toReactNode()}
          </Text>

          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
            }}
          >
            {context.wifiStatus.wifiDataUsage}GB
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: Length.px(14),
            paddingBottom: Length.px(18),
            paddingRight: Length.px(16),
            borderTop: Border('solid', Length.px(0.5), Colors.border),
            boxSizing: 'border-box',
            height: Length.px(48),
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              fontWeight: 'bold',
              lineHeight: Length.px(20),
            }}
          >
            {intl.formatMessage({ id: 'WiFi_wifiLimit' }).toReactNode()}
          </Text>

          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
            }}
          >
            {context.wifiStatus.wifiLimitDataUsage}GB
          </Text>
        </View>

        <View
          style={{
            paddingTop: Length.px(14),
            paddingBottom: Length.px(18),
            paddingRight: Length.px(16),
            borderTop: Border('solid', Length.px(0.5), Colors.border),
            borderBottom: Border('solid', Length.px(0.5), Colors.border),
            boxSizing: 'border-box',
            height: Length.px(48),
          }}
          onClick={(evt) => {
            return dispatch
              .effect(() => evt.stopPropagation())
              .pipe((_) =>
                context.serviceTelemetry.data
                  .toOption()
                  .some((_) => _.ig_status)
                  ? _.context(Context.actions.updateOverlayState, {
                      state: 'Show',
                    }).home(HomeActions.updateResetPaneState, 'Show')
                  : _.context(
                      errorDialogAction(
                        intl.formatMessage({ id: 'WiFi_wifiResetError' }),
                        intl.formatMessage({ id: 'WiFi_wifiResetMsg' }),
                      ),
                    ),
              );
          }}
        >
          <Text
            style={{
              fontSize: Length.px(16),
              lineHeight: Length.px(20),
              color: Colors.accent,
            }}
          >
            {intl
              .formatMessage({ id: 'WiFi_wififorgetPassword' })
              .toReactNode()}
          </Text>
        </View>
      </View>
    </View>
  );
}
