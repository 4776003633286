import { Vector } from '../../math/Vector';
import { copy } from '../../util/Copyable';
import { Reducer } from '../states/Reducer';

export interface Drag {
  readonly isDragging: boolean;
  readonly initial: Vector;
  readonly previous: Vector;
  readonly current: Vector;
  readonly dt: Vector;
}

interface Acts {
  readonly onTouchStart: (data: Drag, touch: Vector) => Drag;
  readonly onTouchMove: (data: Drag, touch: Vector) => Drag;
  readonly onTouchEnd: (data: Drag) => Drag;
}

type Statics = Reducer<Drag, Acts>;

interface Factory {
  (): Drag;
}

const factory: Factory = () => ({
  isDragging: false,
  initial: Vector(0, 0),
  previous: Vector(0, 0),
  current: Vector(0, 0),
  dt: Vector(0, 0),
});

const statics: Statics = {
  initialize: (data: Drag) => data,

  actions: {
    onTouchStart: (data: Drag, touch: Vector) =>
      copy(data, {
        isDragging: true,
        initial: touch,
        previous: touch,
        current: touch,
      }),

    onTouchMove: (data: Drag, touch: Vector) => {
      const previous = data.current;
      const current = touch;
      const dt = previous.to(current);
      return copy(data, {
        previous,
        current,
        dt,
      });
    },

    onTouchEnd: (data: Drag) =>
      copy(data, {
        isDragging: false,
        initial: Vector(0, 0),
        previous: Vector(0, 0),
        current: Vector(0, 0),
        dt: Vector(0, 0),
      }),
  },
};

export const Drag: Factory & Statics = Object.assign(factory, statics);
