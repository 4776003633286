import React, { useCallback, useEffect, useState } from 'react';
import { println } from '../../apis/NativeApi';
import { Anomaly } from '../../util/Error';
import { Scope } from '../../util/Scope';
import { commonErrorDialogAction } from '../components/AlertDialog';
import { HomeDataMap } from '../home/Home';
import { Message, useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { OptionalStyles } from '../style/Style';

export type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  style?: OptionalStyles;
  children?: React.ReactNode;
  dialogOptions?: Readonly<{
    title: Message;
    message: Message;
    confirmButtonTitle?: Message;
  }>;
  action?: () => Promise<void>;
  onFinished?: () => void;
}>;

export const AsyncActionView: React.FC<Props> = (props) => {
  const {
    views: { View },
    data: { context },
    dispatch,
  } = useParentStore({ parent: props.parent });
  const intl = useIntl();
  const [isWaiting, setIsWaiting] = useState(false);
  useEffect(() => {
    if (isWaiting) {
      console.log('AsyncActionView', isWaiting, context.contentState);
    }
    if (isWaiting && context.contentState === 'Loaded') {
      setTimeout(() => {
        props.onFinished?.();
      }, 0);
      setIsWaiting(false);
    }
  }, [context.contentState, isWaiting, props]);
  const onClick = useCallback(() => {
    const onConfirm = () => {
      return dispatch.context
        .bind(Context.actions.updateOverlayState, {
          state: 'Show',
          opacity: 0.4,
        })
        .context.bind(Context.actions.updateWindowSpinnerState, 'Show')
        .asyncAll(
          Scope(async () => {
            try {
              await props.action?.();
            } catch (err) {
              const a = Anomaly.of(err);
              println(a);
              return dispatch.context(commonErrorDialogAction(intl));
            }
            setTimeout(() => {
              setIsWaiting(true);
            }, 0);
            return dispatch.context.bind(
              Context.actions.updateContentState,
              'Reload',
            );
          }),
        );
    };

    if (props.dialogOptions == null) {
      return onConfirm();
    } else {
      return dispatch.context.bind(Context.actions.updateConfirmDialog, {
        state: 'Show',
        ...props.dialogOptions,
        onConfirm,
      });
    }
  }, [intl, props, dispatch]);

  return (
    <View
      style={props.style}
      onClick={props.action == null ? undefined : onClick}
    >
      {props.children}
    </View>
  );
};
