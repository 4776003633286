import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { genVehicleEvents } from '../../apis/EventTelemetry';
import {
  defaultVehicleLocation,
  genServiceTelemetry,
} from '../../apis/ServiceTelemetry';
import { UserType } from '../../apis/VehicleList';
import { defaultWifiStatus } from '../../apis/Wifi';
import { BuildOpt } from '../../util/BuildOpt';
import { ToOptional } from '../../util/Copyable';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DB, createVehicle } from './Database';
import { ParentProps } from './DebugPane';
import { dispatchDB } from './Helpers';
import { Item } from './Item';
import { Subtitle } from './Subtitle';

function createVehicleData(db: DB, userType: UserType): ToOptional<DB> {
  const v = createVehicle(db, userType);
  const events = genVehicleEvents(v.vin);
  return {
    vehicles: [...db.vehicles, v],
    serviceTelemetries: db.serviceTelemetries
      .filter((s) => s.vin !== v.vin)
      .concat([
        genServiceTelemetry({
          vin: v.vin,
          position: defaultVehicleLocation,
        }),
      ]),
    vehicleEvents: db.vehicleEvents
      .filter((e) => e.vin !== v.vin)
      .concat([{ vin: v.vin, events }]),
    userVehicleEvents: db.userVehicleEvents
      .filter((e) => e.vin !== v.vin)
      .concat([{ vin: v.vin, events }]),
    wifiStatuses: db.wifiStatuses
      .filter((s) => s.vin !== v.vin)
      .concat([defaultWifiStatus(v.vin)]),
  };
}

export function DummyDataPane({ parent }: ParentProps): React.ReactElement {
  const {
    views: { Text },
    data: { debug: data },
    dispatch,
  } = useParentStore({ parent });
  const db = data.db;

  return (
    <>
      <Subtitle title='Add Dummy Data' />

      <Item
        parent={parent}
        text={<Text>Add Shared Vehicle (All features available).</Text>}
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(db, createVehicleData(db, 'all'), dispatch).context.bind(
            Context.actions.updateContentState,
            'Load',
          )
        }
      />

      <Item
        parent={parent}
        text={
          <Text>Add Shared Vehicle (Available with some restrictions).</Text>
        }
        icon={<SendIcon />}
        disable={!BuildOpt.isMock()}
        onClick={() =>
          dispatchDB(
            db,
            createVehicleData(db, 'limited'),
            dispatch,
          ).context.bind(Context.actions.updateContentState, 'Load')
        }
      />
    </>
  );
}
