import React from 'react';
import { config } from '../../Config';
import { ReloadButton } from '../components/ReloadButton';
import { Shape } from '../components/Shape';
import { SlideInWindow, SlideInWindowMode } from '../components/SlideInWindow';
import { Spinner } from '../components/Spinner';
import { isContentLoading } from '../home/Helpers';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { OpenedView } from './OpenedView';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mode: SlideInWindowMode;
}>;

export function WifiWindow({ parent, mode }: Props): React.ReactElement {
  const {
    views: { View },
    data: { context },
    stores,
  } = useParentStore({ parent });
  const intl = useIntl();
  const BodyOverlay = View;

  return (
    <SlideInWindow
      parent={parent}
      mode={mode}
      title={intl.formatMessage({ id: 'WiFi_pageTitle' })}
      headerLeft={
        <ReloadButton
          parent={parent}
          contentState={context.contentState}
          ongoingState={false}
          onReload={(dispatch) =>
            dispatch
              .context(Context.actions.updateContentState, 'Reload')
              .context(
                Context.actions.updateFetchVehicleDataOptions,
                config.fetchDataOptions.onReloadWifi,
              )
          }
        />
      }
      direction='vertical'
      onClose={(dispatch) =>
        dispatch.context(Context.actions.updateWifiWindowMode, 'Hide')
      }
      disableCloseWhileLoading={true}
    >
      <OpenedView parent={stores} />

      {isContentLoading(context) && (
        <BodyOverlay
          style={{
            position: 'absolute',
            top: Length.px(0),
            left: Length.px(0),
            width: Length.px(context.viewPort.width),
            height: Length.px(context.viewPort.height),
            backgroundColor: Colors.overlayCover,
          }}
        />
      )}

      <Spinner
        state={isContentLoading(context) ? 'Show' : 'Hide'}
        owner={Shape({})}
        position={'WindowCenter'}
        useSecondColor={true}
      />
    </SlideInWindow>
  );
}
