import React from 'react';
import { Scope } from '../../util/Scope';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { IcWaitingWhite } from '../icons/IcWaitingWhite';
import { ViewPort } from '../states/Context';
import { useContextStore } from '../states/ContextStore';
import { Length } from '../style/Length';
import { OptionalStyles } from '../style/Style';
import { Shape } from './Shape';

export type State = 'Show' | 'Hide';
type Position = 'OwnerCenter' | 'WindowCenter';

interface Props {
  readonly state: State;
  readonly owner: Shape;
  readonly position?: Position;
  readonly useSecondColor?: boolean;
}

const width = 32;
const height = 32;

function getContainerStyle(
  state: State,
  owner: Shape,
  position: Position,
  viewPort: ViewPort,
): OptionalStyles {
  const style: OptionalStyles = {
    display: 'inline-block',
    position: 'absolute',
    width: Length.px(width),
    height: Length.px(height),
  };

  if (state === 'Show') {
    switch (position) {
      case 'OwnerCenter':
        return owner
          .getBound()
          .map<OptionalStyles>((ownerBound) => {
            const left = (ownerBound.width - width) / 2;
            const top = (ownerBound.height - height) / 2;
            return {
              ...style,
              left: Length.px(left),
              top: Length.px(top),
            };
          })
          .getOrElse(() => style);
      case 'WindowCenter': {
        const left = (viewPort.width - width) / 2;
        const top = (viewPort.height - height) / 2;
        return {
          ...style,
          left: Length.px(left),
          top: Length.px(top),
        };
      }
    }
  } else {
    return {
      height: Length.px(0),
      top: Length.px(viewPort.height),
    };
  }
}

export function Spinner(props: Props): React.ReactElement {
  const {
    views,
    data: { context },
  } = useContextStore();
  const View = views.default;
  const viewPort = context.viewPort;

  return (
    <View
      style={getContainerStyle(
        props.state,
        props.owner,
        props.position ?? 'OwnerCenter',
        viewPort,
      )}
    >
      {Scope(() => {
        if (props.state === 'Show') {
          return props.useSecondColor === true ? (
            <IcWaitingWhite
              style={{
                width: Length.px(width),
                height: Length.px(height),
              }}
              animate
            />
          ) : (
            <IcWaitingRed
              style={{
                width: Length.px(width),
                height: Length.px(height),
              }}
              animate
            />
          );
        } else {
          return <View />;
        }
      })}
    </View>
  );
}
