export interface Lazy<A> {
  readonly kind: 'Lazy';
  readonly isEvaluated: () => boolean;
  readonly get: () => A;
}

export function Lazy<A>(f: () => A): Lazy<A> {
  let a: A | null = null;
  return {
    kind: 'Lazy',
    isEvaluated: () => (a === null ? false : true),
    get: () => {
      if (a === null) {
        a = f();
      }
      return a;
    },
  };
}
