import * as Client from '@auto/monaka-client/dist/20';
import { EventId } from '../ui/i18n/Types';
import { getDB } from '../ui/mock/Database';
import { BuildOpt } from '../util/BuildOpt';
import { getErrorMessage } from '../util/Error';
import { None, Option, OptionJson, Some } from '../util/Option';
import { Scope } from '../util/Scope';
import { sleep } from '../util/Sleep';
import { AppSyncClient } from './Client';
import { GeoPos } from './Geofence';
import { println } from './NativeApi';
import {
  VehicleLocation,
  defaultVehicleLocation,
  genServiceTelemetry,
} from './ServiceTelemetry';

export interface VehicleEvent {
  readonly kind: 'VehicleEvent';
  readonly vin: string;
  readonly obuId: string;
  readonly timestamp: Date;
  readonly position: Option<GeoPos>;
  readonly accuracy: number;
  readonly altitude: number;
  readonly altitude_accuracy: number;
  readonly tripId: string;
  readonly eventId: EventId;
}

export type VehicleEventJson = Omit<VehicleEvent, 'timestamp' | 'position'> & {
  readonly position: OptionJson<GeoPos>;
  readonly timestamp: number; // Epoch time in milliseconds
};

export type UserVehicleEvent = {
  readonly vin: string;
  readonly events: VehicleEvent[];
};

export type UserVehicleEventJson = Omit<UserVehicleEvent, 'events'> & {
  readonly events: VehicleEventJson[];
};

export type EmptyVehicleEvent = {
  readonly kind: 'EmptyVehicleEvent';
  readonly vin: string;
  readonly eventId: EventId;
  readonly timestamp: Date;
};

export type EmptyVehicleEventJson = Omit<EmptyVehicleEvent, 'timestamp'> & {
  readonly timestamp: number; // Epoch time in milliseconds
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function uniq(events: VehicleEvent[]): VehicleEvent[] {
  const map = new Map<EventId, VehicleEvent>();
  return events.flatMap((e) => {
    if (map.has(e.eventId)) return [];
    else {
      map.set(e.eventId, e);
      return [e];
    }
  });
}

function sortVehicleEvents(events: VehicleEvent[]): VehicleEvent[] {
  return Array.from(events).sort(
    (a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
  );
}

export function EmptyVehicleEvent(
  vin: string,
  eventId: EventId,
  timestamp: Date,
): EmptyVehicleEvent {
  return {
    kind: 'EmptyVehicleEvent',
    vin,
    timestamp,
    eventId,
  };
}

export function toVehicleEventJson(a: VehicleEvent): VehicleEventJson {
  return {
    ...a,
    position: a.position.toJson(),
    timestamp: a.timestamp.getTime(),
  };
}

export function fromVehicleEventJson(a: VehicleEventJson): VehicleEvent {
  return {
    ...a,
    position: Option.fromJson(a.position),
    timestamp: new Date(a.timestamp),
  };
}

export function toUserVehicleEventJson(
  a: UserVehicleEvent,
): UserVehicleEventJson {
  return {
    ...a,
    events: a.events.map(toVehicleEventJson),
  };
}

export function fromUserVehicleEventJson(
  a: UserVehicleEventJson,
): UserVehicleEvent {
  return {
    ...a,
    events: a.events.map(fromVehicleEventJson),
  };
}

export function toEmptyVehicleEventJson(
  a: EmptyVehicleEvent,
): EmptyVehicleEventJson {
  return {
    ...a,
    timestamp: a.timestamp.getTime(),
  };
}

export function fromEmptyVehicleEventJson(
  a: EmptyVehicleEventJson,
): EmptyVehicleEvent {
  return {
    ...a,
    timestamp: new Date(a.timestamp),
  };
}

function fromClientVehicleEvent(a: Client.VehicleEvent): VehicleEvent {
  return {
    kind: 'VehicleEvent',
    vin: a.vin,
    obuId: a.obu_id,
    timestamp: new Date(a.timestamp),
    position:
      a.latitude === null || a.longitude === null
        ? None()
        : Some({
            lat: a.latitude,
            lng: a.longitude,
          }),
    accuracy: a.accuracy,
    altitude: a.altitude,
    altitude_accuracy: a.altitude_accuracy,
    tripId: a.trip_id,
    eventId: a.event_id as EventId,
  };
}

function fromClientUserVehicleEvent(
  a: Client.UserVehicleEvent,
): UserVehicleEvent {
  return {
    vin: a.vin,
    events: a.events.map(fromClientVehicleEvent),
  };
}

export async function genVehicleEvent(
  vin: string,
  eventId: EventId,
): Promise<VehicleEvent> {
  const db = await getDB();
  return Option(db.serviceTelemetries.find((_) => _.vin === vin)).unwrap(
    (s) =>
      Promise.resolve<VehicleEvent>({
        kind: 'VehicleEvent',
        vin,
        obuId: '',
        timestamp: new Date(Date.now()),
        position: s.position.flatMap((_) =>
          _.isOld ? None() : Some(_.location),
        ),
        accuracy: 1.0,
        altitude: 0,
        altitude_accuracy: 1.0,
        tripId: '',
        eventId,
      }),
    () => Promise.reject(Error(`Failed to get a service telemetry for ${vin}`)),
  );
}

export function genVehicleEvents(vin: string): VehicleEvent[] {
  function toPosition(p: Option<VehicleLocation>): Option<GeoPos> {
    return p.flatMap((_) => (_.isOld ? None() : Some(_.location)));
  }

  function getRandomTimestamp(): Date {
    return new Date(Date.now() - Math.random() * 10800000);
  }

  const serviceTelemetry = genServiceTelemetry({
    vin,
    position: defaultVehicleLocation,
  });
  return [
    {
      kind: 'VehicleEvent',
      vin,
      obuId: '',
      timestamp: getRandomTimestamp(),
      position: toPosition(serviceTelemetry.position),
      accuracy: 1.0,
      altitude: 0,
      altitude_accuracy: 1.0,
      tripId: '',
      eventId: '202',
    },

    {
      kind: 'VehicleEvent',
      vin,
      obuId: '',
      timestamp: getRandomTimestamp(),
      position: toPosition(serviceTelemetry.position),
      accuracy: 1.0,
      altitude: 0,
      altitude_accuracy: 1.0,
      tripId: '',
      eventId: '204',
    },

    {
      kind: 'VehicleEvent',
      vin,
      obuId: '',
      timestamp: getRandomTimestamp(),
      position: toPosition(serviceTelemetry.position),
      accuracy: 1.0,
      altitude: 0,
      altitude_accuracy: 1.0,
      tripId: '',
      eventId: '1011',
    },

    {
      kind: 'VehicleEvent',
      vin,
      obuId: '',
      timestamp: getRandomTimestamp(),
      position: toPosition(serviceTelemetry.position),
      accuracy: 1.0,
      altitude: 0,
      altitude_accuracy: 1.0,
      tripId: '',
      eventId: '1031',
    },
  ];
}

/**
 * @param vin
 * @param from Epoch time in milliseconds
 */
export async function getVehicleEventList(params: {
  vin: string;
  from?: number;
}): Promise<VehicleEvent[]> {
  println(`Getting the vehicle event list for vin=${params.vin}`);
  const xs = await Scope(async () => {
    if (BuildOpt.isMock()) {
      await sleep(1000);
      const db = await getDB();
      if (db.vehicleEventErrorVin1 && params.vin === 'vin-1')
        return Promise.reject(
          Error(`Vehicle event list error for ${params.vin}`),
        );
      else if (db.vehicleEventErrorVin2 && params.vin === 'vin-2')
        return Promise.reject(
          Error(`Vehicle event list error for ${params.vin}`),
        );
      else {
        println(`Got the vehicle event list for vin=${params.vin}`);
        return Option(
          db.vehicleEvents.find((_) => _.vin === params.vin),
        ).unwrap(
          (_) => Promise.resolve(_.events),
          () =>
            Promise.reject(
              Error(`Error on getVehiclesEventList for vin=${params.vin}`),
            ),
        );
      }
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.getVehicleEventList({
        gSysUserId,
        vin: params.vin,
        from:
          params.from !== undefined
            ? params.from
            : Date.now() - 30 * 24 * 60 * 60 * 1000,
      })
        .then((xs) => {
          println(
            `Got the vehicle event list for vin=${params.vin}, size=${xs.length}`,
          );
          return xs.map(fromClientVehicleEvent);
        })
        .catch(async (err) => {
          const prefix = `Error on getVehiclesEventList for vin=${params.vin}, gSysUserId=${gSysUserId}`;
          const msg = getErrorMessage(err);
          return Promise.reject(Error(`${prefix}: ${msg}`));
        });
    }
  });

  return sortVehicleEvents(xs);
}

export async function getUserLastVehiclesEventList(): Promise<
  UserVehicleEvent[]
> {
  println(`Getting the user last vehicle event list.`);
  const xs = await Scope(async () => {
    if (BuildOpt.isMock()) {
      await sleep(1000);
      const db = await getDB();
      if (db.userLastVehicleEventError)
        return Promise.reject(Error('User last vehicle event list error'));
      else {
        println(`Got the user last vehicle event list.`);
        return db.vehicles.flatMap((vehicle) =>
          Option(
            db.userVehicleEvents.find((_) => _.vin === vehicle.vin),
          ).unwrap(
            (e) => [e],
            () => [],
          ),
        );
      }
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.getUserLastVehiclesEventList(gSysUserId)
        .then((xs) => {
          println(`Got the user last vehicle event list.`);
          return xs.map(fromClientUserVehicleEvent);
        })
        .catch(async (err) => {
          const prefix = `Error on getUserLastVehiclesEventList for gSysUserId=${gSysUserId}`;
          const msg = getErrorMessage(err);
          return Promise.reject(Error(`${prefix}: ${msg}`));
        });
    }
  });

  return xs.map((x) => ({
    ...x,
    events: sortVehicleEvents(x.events),
  }));
}

export async function getLastVehicleEventList(
  vin: string,
): Promise<VehicleEvent[]> {
  println(`Getting the last vehicle event list(vin=${vin}).`);
  const xs = await Scope(async () => {
    if (BuildOpt.isMock()) {
      await sleep(500);
      const db = await getDB();
      if (db.userLastVehicleEventError)
        return Promise.reject(Error('User last vehicle event list error'));
      else {
        println(`Got the last vehicle event list(vin=${vin}).`);
        return Option(db.userVehicleEvents.find((_) => _.vin === vin)).unwrap(
          (e) => e.events,
          () => [],
        );
      }
    } else {
      const gSysUserId = Option(await Client.Auth.currentDriver())
        .map((_) => _.gSysUserId)
        .getOrElse(() => 'unknown');
      return AppSyncClient.getLastVehicleEventList({ gSysUserId, vin })
        .then((xs) => {
          println(`Got the user last vehicle event list.`);
          return xs.map(fromClientVehicleEvent);
        })
        .catch(async (err) => {
          const prefix = `Error on getLastVehicleEventList for gSysUserId=${gSysUserId}`;
          const msg = getErrorMessage(err);
          return Promise.reject(Error(`${prefix}: ${msg}`));
        });
    }
  });

  return sortVehicleEvents(xs);
}
