import { EmptyVehicleEvent, VehicleEvent } from '../../apis/EventTelemetry';
import { None } from '../../util/Option';
import { Context } from '../states/Context';

export const defaultVehicleEvent: VehicleEvent = {
  kind: 'VehicleEvent',
  vin: '',
  obuId: '',
  timestamp: new Date(0),
  position: None(),
  accuracy: 0,
  altitude: 0,
  altitude_accuracy: 0,
  tripId: '',
  eventId: '001',
};

export function getSelectedVehicleEvent(
  context: Context,
): VehicleEvent | EmptyVehicleEvent {
  return context.selectedVehicleEvent
    .map((e) => {
      switch (e.kind) {
        case 'VehicleEvent':
        case 'EmptyVehicleEvent':
          return e;
        case 'StateInfo':
          return defaultVehicleEvent;
      }
    })
    .getOrElse(() => defaultVehicleEvent);
}
