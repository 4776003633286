import React from 'react';
import { showSharedUserRegistrationScreen } from '../../apis/NativeApi';
import { Button } from '../components/Button';
import { LineSpace } from '../components/LineSpace';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Length } from '../style/Length';

export function InitialRegistration({
  parent,
}: {
  parent: DataMapStore<HomeDataMap>;
}): React.ReactElement {
  const {
    views: { View, Image },
  } = useParentStore({ parent });
  const intl = useIntl();

  return (
    <View>
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image
          src={Icons.img_vehicle_default}
          style={{
            width: Length.px(180),
            height: Length.px(120),
          }}
        />
      </View>

      <LineSpace height={Length.px(20)} />

      <View
        style={{
          paddingLeft: Length.px(16),
          paddingRight: Length.px(16),
        }}
      >
        <Button
          parent={parent}
          title={intl.formatMessage({ id: 'VehicleActivate_startButton' })}
          onClick={(dispatch) =>
            dispatch.context.bind(Context.actions.updateConfirmDialog, {
              state: 'Show',
              title: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterTitle',
              }),
              message: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterBody',
              }),
              confirmButtonTitle: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterButton1',
              }),
              confirmButton2Title: intl.formatMessage({
                id: 'VehicleActivate_dialogMyCarRegisterButton2',
              }),
              onConfirm: () => {
                return dispatch.context.bind(
                  Context.actions.updateCarRegScreenMode,
                  'Show',
                );
              },
              onConfirm2: () => {
                showSharedUserRegistrationScreen();
                return dispatch.context.bind(
                  Context.actions.updateSharedUserRegScreenMode,
                  { state: 'Scanning', error: false },
                );
              },
            })
          }
        />
      </View>
    </View>
  );
}
