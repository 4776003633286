import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BuildOpt } from './util/BuildOpt';

Sentry.init({
  dsn: 'https://60e764203a204dc7a27205db416c5c8e@o1070225.ingest.sentry.io/6314838',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  debug:
    BuildOpt.env === 'prod' && BuildOpt.env.startsWith('stg') ? false : true,
  environment: BuildOpt.env,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
