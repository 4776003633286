import React from 'react';
import { openGoogleMap } from '../../apis/NativeApi';
import { Scope } from '../../util/Scope';
import { systemErrorDialogAction } from '../components/AlertDialog';
import { cardBorderRadius } from '../components/Card';
import { TermsOverlay } from '../google/TermsOverlay';
import { HomeDataMap } from '../home/Home';
import { useIntl } from '../i18n/Intl';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Colors } from '../style/Color';
import { DropShadow } from '../style/Filter';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { getGPSState } from './Helpers';
import { ButtonContainer } from './MapButtons';
import { menuPaneHeight } from './MenuPane';
import { PermissionWarning, noPermissionHeight } from './PermissionWarning';

export function VehicleLocationBody({
  parent,
  mapId,
  headerHeight,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  mapId: string;
  headerHeight: Length;
}>): React.ReactElement {
  const {
    views,
    data: { home: data, context },
    dispatch,
  } = useParentStore<HomeDataMap>({
    parent,
  });
  const { View, Text } = views;
  const Container = views.home.localState.geofence.container;
  const MapView = View;
  const viewPort = context.viewPort;
  const intl = useIntl();
  const localState = data.localState.geofence.localState;
  const gpsState = getGPSState(localState.userLocation);
  const isDataError: boolean =
    context.dataErrors.serviceTelemetry ||
    context.serviceTelemetry.data
      .toOption()
      .flatMap((_) => _.position)
      .unwrap(
        (_) => false,
        () => true,
      );

  return (
    <>
      <PermissionWarning
        parent={parent}
        mapMode={localState.mapMode}
        gpsState={gpsState}
      />

      <Container
        style={{
          ...(localState.mapMode === 'Open'
            ? {
                height: Length.px(
                  viewPort.height -
                    headerHeight.value -
                    menuPaneHeight.value -
                    (gpsState === 'Normal' ? 0 : noPermissionHeight.value),
                ),
              }
            : {}),
        }}
      >
        <MapView
          id={mapId}
          style={{
            width: Percentage(100),
            height: Percentage(100),
            borderRadius:
              localState.cardState === 'Opened'
                ? Length.px(0)
                : cardBorderRadius,
          }}
          onTransitionEnd={(evt) =>
            dispatch.effect(() => evt.stopPropagation())
          }
        />

        <TermsOverlay state='Show' />

        {Scope(() => {
          const EmptyView = null;
          const WhiteView = (
            <View
              style={{
                position: 'absolute',
                top: Length.px(0),
                width: Percentage(100),
                height: Percentage(100),
                backgroundColor: Colors.background,
                borderRadius: cardBorderRadius,
              }}
            />
          );

          const ErrorView = (
            <View
              style={{
                position: 'absolute',
                top: Length.px(0),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: Percentage(100),
                height: Percentage(100),
                backgroundColor: Colors.background,
                borderRadius: cardBorderRadius,
              }}
            >
              <Text>
                {intl.formatMessage({ id: 'Common_syncError' }).toReactNode()}
              </Text>
            </View>
          );

          switch (context.contentState) {
            case 'Reload':
            case 'Updating':
            case 'Loaded':
              if (isDataError && localState.cardState === 'Closed')
                return ErrorView;
              else return EmptyView;
            case 'Loading':
              return WhiteView;
            default:
              return WhiteView;
          }
        })}
      </Container>

      <ButtonContainer store={parent.home} gpsState={gpsState} />

      {/* Open in the Map App*/}
      <View
        style={{
          display: localState.mapMode === 'Open' ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: Length.px(
            8 + (gpsState === 'Normal' ? 0 : noPermissionHeight.value),
          ),
          left: Length.px(8),
          width: Length.px(128),
          height: Length.px(32),
          backgroundColor: Colors.normalActionForeground,
          borderRadius: Length.px(2),
          filter: DropShadow(
            Length.px(0),
            Length.px(0),
            Length.px(4),
            Colors.shadow,
          ),
        }}
        onClick={(_evt) =>
          context.serviceTelemetry.data
            .toOption()
            .flatMap((_) => _.position)
            .unwrap(
              (p) => {
                openGoogleMap(p.location);
                return dispatch;
              },
              () =>
                dispatch.context(
                  systemErrorDialogAction('No vehicle location'),
                ),
            )
        }
      >
        <View
          style={{
            fontSize: Length.px(12),
            fontWeight: 'bold',
            lineHeight: Length.px(12),
            color: Colors.buttonText,
          }}
        >
          {intl.formatMessage({ id: 'Location_openMapBtn' }).toReactNode()}
        </View>
      </View>
    </>
  );
}
