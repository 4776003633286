import { ToOptional, copy } from '../../util/Copyable';
import { DataMapDispatch } from './DataMapStore';
import { Dispatch } from './Dispatch';
import { BindMethodBase } from './DispatchBase';
import { DispatchRoot } from './DispatchRoot';

export type CommandData<Command> = {
  readonly localState: CommandLocalState<Command>;
};

export type CommandLocalState<Command> = {
  readonly command: AsCommand<Command>;
};

export type AsCommand<Command> = Command | 'None';

export type CommandActions<Owner, Command> = {
  readonly updateCommand: (data: Owner, command: AsCommand<Command>) => Owner;
};

export type CommandState<Owner, Command> = Readonly<{
  default: AsCommand<Command>;

  actions: CommandActions<Owner, Command>;

  handle: <Parent>(
    data: Owner,
    f: (
      _: Dispatch<Owner, Parent>,
      command: AsCommand<Command>,
    ) => Dispatch<Owner, Parent>,
  ) => Dispatch<Owner, Parent>;

  handleParent: <Self>(
    data: Owner,
    f: (
      _: Dispatch<Self, Owner>,
      command: AsCommand<Command>,
    ) => Dispatch<Self, Owner>,
  ) => Dispatch<Self, Owner>;

  handleRoot: (
    data: Owner,
    f: (
      _: DispatchRoot<Owner>,
      command: AsCommand<Command>,
    ) => DispatchRoot<Owner>,
  ) => DispatchRoot<Owner>;

  handleDataMap: <DataMap>(
    key: keyof DataMap,
    data: Owner,
    dispatch: DataMapDispatch<DataMap>,
    f: (command: AsCommand<Command>) => DataMapDispatch<DataMap>,
  ) => DataMapDispatch<DataMap>;
}>;

function hasNameProp(command: unknown): command is Readonly<{ name: string }> {
  return (command as { name?: string }).name !== undefined;
}

function equalCommand(command1: unknown, command2: unknown): boolean {
  if (hasNameProp(command1) && hasNameProp(command2)) {
    return command1.name === command2.name;
  } else {
    return command1 === command2;
  }
}

export function newCommandState<Owner, Command>({
  get,
  update,
}: Readonly<{
  get: (data: Owner) => AsCommand<Command>;
  update: (command: AsCommand<Command>) => ToOptional<Owner>;
}>): CommandState<Owner, Command> {
  const self: CommandState<Owner, Command> = {
    default: 'None',

    actions: {
      updateCommand: (data: Owner, command: AsCommand<Command>) =>
        copy(data, update(command)),
    },

    handle: <Parent>(
      data: Owner,
      f: (
        _: Dispatch<Owner, Parent>,
        command: AsCommand<Command>,
      ) => Dispatch<Owner, Parent>,
    ) => {
      const _ = Dispatch<Owner, Parent>();
      const command = get(data);
      if (command !== 'None') {
        return f(_, command).bind((data2) => {
          const command2 = get(data2);
          return self.actions.updateCommand(
            data2,
            !equalCommand(command2, command) ? command2 : 'None',
          );
        }, {});
      } else {
        return _;
      }
    },

    handleParent: <Self>(
      data: Owner,
      f: (_: Dispatch<Self, Owner>, command: Command) => Dispatch<Self, Owner>,
    ) => {
      const _ = Dispatch<Self, Owner>();
      const command = get(data);
      if (command !== 'None') {
        return f(_, command).parent((data2) => {
          const command2 = get(data2);
          return self.actions.updateCommand(
            data2,
            !equalCommand(command2, command) ? command2 : 'None',
          );
        }, {});
      } else {
        return _;
      }
    },

    handleRoot: (
      data: Owner,
      f: (_: DispatchRoot<Owner>, command: Command) => DispatchRoot<Owner>,
    ) => {
      const _ = DispatchRoot<Owner>();
      const command = get(data);
      if (command !== 'None') {
        return f(_, command).bind((data2) => {
          const command2 = get(data2);
          return self.actions.updateCommand(
            data2,
            !equalCommand(command2, command) ? command2 : 'None',
          );
        }, {});
      } else {
        return _;
      }
    },

    handleDataMap: <DataMap>(
      key: keyof DataMap,
      data: Owner,
      dispatch: DataMapDispatch<DataMap>,
      f: (command: AsCommand<Command>) => DataMapDispatch<DataMap>,
    ) => {
      const command = get(data as unknown as Owner);
      if (command !== 'None') {
        const m = f(command)[key] as unknown as BindMethodBase<
          'DataMapDispatch',
          DataMap,
          keyof DataMap
        >;
        return m((data2) => {
          const command2 = get(data2 as unknown as Owner);
          return self.actions.updateCommand(
            data2 as unknown as Owner,
            !equalCommand(command2, command) ? command2 : 'None',
          ) as unknown as DataMap[keyof DataMap];
        });
      } else {
        return dispatch;
      }
    },
  };
  return self;
}

export function CommandState<
  Owner extends CommandData<Command>,
  Command,
>(): CommandState<Owner, Command> {
  return newCommandState<Owner, Command>({
    get: (data) => data.localState.command,

    update: (command) => {
      const d: CommandData<Command> = {
        localState: { command },
      };
      return d as ToOptional<Owner>;
    },
  });
}
