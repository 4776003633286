/**
 * @module
 */
import React from 'react';
import { None, Option, Some } from '../../util/Option';
import { EmptyProps } from '../components/Component';
import { ViewsBase, useViews } from '../components/View';
import { usePrevious } from '../states/Previous';
import { Context } from './Context';
import {
  DispatchBase,
  DispatchItemsBase,
  createDispatchBase,
  emptyDispatchItemsBase,
} from './DispatchBase';
import { CurrentCallback } from './DispatchCurrentItem';
import { DispatchableStore, Store, useDataStore } from './Store';

export type ContextDataMap = Readonly<{
  context: Context;
}>;

export type WithContext<DataMap> = ExtendParent<DataMap, 'context', Context>;

export type DataMapDispatch<DataMap> = DispatchBase<'DataMapDispatch', DataMap>;

export type DataMapStore<DataMap> = Readonly<{
  [Key in keyof DataMap]: Store<DataMap[Key]>;
}>;

export type IndexedDispatchables<DataMap> = {
  [key: string]: DispatchableStore<DataMap[keyof DataMap]>;
};

export type IndexedDataMap<DataMap> = { [key: string]: DataMap[keyof DataMap] };

export type DataMapStateBase<DataMap> = Readonly<{
  stores: DataMapStore<DataMap>;
}>;

type DataMapStateCore<Props, DataMap> = Readonly<{
  data: DataMap;
  prev: Option<PreviousDataMap<Props, DataMap>>;
  dispatch: DataMapDispatch<DataMap>;
  views: ViewsBase<DataMap>;
}>;

export type DataMapState<Props, DataMap> = DataMapStateBase<DataMap> &
  DataMapStateCore<Props, WithContext<DataMap>>;

export type InnerDataMapState<Props, DataMap> = DataMapStateBase<
  WithContext<DataMap>
> &
  DataMapStateCore<Props, WithContext<DataMap>>;

export type WithSetCallback<DataMap> = Readonly<{
  setCallback: (callback: CurrentCallback<DataMap>) => void;
}>;

export type DispatchableDataMapState<DataMap> =
  | (DataMapStateBase<DataMap> & WithSetCallback<DataMap>)
  | DataMapStateBase<DataMap>;

export type PreviousDataMap<Props, DataMap> = Readonly<{
  props: Props;
  data: DataMap;
}>;

export type UpdateArgs<Props, DataMap> = Readonly<{
  props: Props;
  data: DataMap;
  prev: Option<PreviousDataMap<Props, DataMap>>;
  dispatch: DataMapDispatch<DataMap>;
}>;

export type Update<DataMap, Props = EmptyProps> = (
  args: UpdateArgs<Props, WithContext<DataMap>>,
) => DataMapDispatch<WithContext<DataMap>>;

type UseStoreArgs<Props, Parent, Data, Key extends string> = Readonly<{
  key: Key;
  props?: Props;
  parent?: DataMapStore<Parent>;
  default: () => Data;
  initialize?: (data: Data) => Data;
  update?: Update<ExtendParent<Parent, Key, Data>, Props>;
}>;

export type ExtendParent<
  Parent,
  Key extends string,
  Data,
> = Key extends keyof Parent ? never : Parent & Readonly<{ [K in Key]: Data }>;

export function asWithSetCallback<DataMap>(
  state: DispatchableDataMapState<DataMap>,
): Option<WithSetCallback<DataMap>> {
  if ((state as WithSetCallback<DataMap>).setCallback) {
    return Some(state as WithSetCallback<DataMap>);
  } else {
    return None();
  }
}

function createDispatchDataMapHelper<DataMap>(
  items: DispatchItemsBase<'DataMapDispatch', DataMap>,
): DispatchBase<'DataMapDispatch', DataMap> {
  return createDispatchBase<'DataMapDispatch', DataMap>(
    items,
    createDispatchDataMapHelper,
  );
}

export function createDispatchDataMap<DataMap>(
  items: DispatchItemsBase<'DataMapDispatch', DataMap>,
): DataMapDispatch<DataMap> {
  return createDispatchDataMapHelper<DataMap>(
    items,
  ) as DataMapDispatch<DataMap>;
}

export function emptyDataMapDispatch<DataMap>(
  keys: Array<keyof DataMap>,
): DataMapDispatch<DataMap> {
  return createDispatchDataMap(
    emptyDispatchItemsBase<'DataMapDispatch', DataMap>(keys),
  );
}

// export function toDispatchRoot<DataMap, SelfKey extends keyof DataMap>(
//   dispatch: DataMapDispatch<DataMap>,
//   self: SelfKey,
// ): DispatchRoot<DataMap[SelfKey]> {
//   type Self = DataMap[SelfKey];
//   type RDM = RootDataMap<Self>;
//
//   const _dispatch = dispatch as DataMapDispatch<DataMap> &
//     PrivateDispatchBase<'DataMapDispatch', DataMap>;
//
//   const _items = _dispatch.items as unknown as {
//     [Key in SelfKey]: DispatchItemsMember<RDM, 'self'>;
//   } & DispatchGroupItems<RDM> &
//     WithDispatchAsyncAllItem<'DispatchRoot', RDM> &
//     WithDispatchCurrentItem<RDM>;
//
//   const rootItems = {
//     context: _items['context' as keyof RDM],
//     self: _items[self],
//     asyncAll: _items['asyncAll'],
//     current: _items['current'],
//   } as DispatchItemsBase<'DispatchRoot', RDM>;
//
//   return createDispatchRoot<Self>(rootItems);
// }
//
// export function toDispatch<
//   DataMap,
//   SelfKey extends keyof DataMap,
//   ParentKey extends keyof DataMap,
// >(
//   dispatch: DataMapDispatch<DataMap>,
//   self: SelfKey,
//   parent: ParentKey,
// ): Dispatch<DataMap[SelfKey], DataMap[ParentKey]> {
//   type Self = DataMap[SelfKey];
//   type Parent = DataMap[ParentKey];
//   type DM = DefaultDataMap<Self, Parent>;
//
//   const _dispatch = dispatch as DataMapDispatch<DataMap> &
//     PrivateDispatchBase<'DataMapDispatch', DataMap>;
//
//   const _items = _dispatch.items as unknown as {
//     [Key in SelfKey]: DispatchItemsMember<DM, 'self'>;
//   } & {
//     [Key in ParentKey]: DispatchItemsMember<DM, 'parent'>;
//   } & DispatchGroupItems<DM> &
//     WithDispatchAsyncAllItem<'Dispatch', DM> &
//     WithDispatchCurrentItem<DM>;
//
//   const rootItems = {
//     context: _items['context' as keyof DM],
//     self: _items[self],
//     parent: _items[parent],
//     asyncAll: _items['asyncAll'],
//     current: _items['current'],
//   } as DispatchItemsBase<'Dispatch', DM>;
//
//   return createDispatch<Self, Parent>(rootItems);
// }
//
// export function fromDispatch<
//   DataMap,
//   SelfKey extends keyof DataMap,
//   ParentKey extends keyof DataMap,
// >(
//   dispatch: Dispatch<DataMap[SelfKey], DataMap[ParentKey]>,
//   self: SelfKey,
//   parent: ParentKey,
// ): DataMapDispatch<DataMap> {
//   type Self = DataMap[SelfKey];
//   type Parent = DataMap[ParentKey];
//   type DP = Dispatch<Self, Parent>;
//   type DM = DefaultDataMap<Self, Parent>;
//   type DI = {
//     [Key in SelfKey]: DispatchItemsMember<DM, 'self'>;
//   } & {
//     [Key in ParentKey]: DispatchItemsMember<DM, 'parent'>;
//   } & DispatchGroupItems<DM> &
//     WithDispatchAsyncAllItem<'Dispatch', DM> &
//     WithDispatchCurrentItem<DM>;
//
//   const _dispatch = dispatch as DP & PrivateDispatchBase<'Dispatch', DM>;
//
//   const _items = _dispatch.items as unknown as DI;
//
//   const rootItems = Object.keys(_items).reduce((o, k) => {
//     if (k === 'self') o[self as string] = _items[k];
//     else if (k === 'parent') o[parent as string] = _items[k];
//     else o[k as string] = _items[k as keyof DI];
//     return o;
//   }, {} as { [K: string]: any }) as DispatchItemsBase<
//     'DataMapDispatch',
//     DataMap
//   >;
//
//   return createDispatchDataMap<DataMap>(rootItems);
// }

export function useDispatchFromKeys<DataMap>(
  keys: Array<keyof DataMap>,
): DataMapDispatch<DataMap> {
  return React.useMemo(() => emptyDataMapDispatch(keys), [keys]);
}

export function useStore<
  Key extends string,
  Data,
  Parent = EmptyProps,
  Props = EmptyProps,
>({
  key,
  props: fprops,
  parent,
  default: getDefault,
  initialize,
  update,
}: UseStoreArgs<Props, Parent, Data, Key>): DataMapState<
  Props,
  ExtendParent<Parent, Key, Data>
> {
  type DataMap = ExtendParent<Parent, Key, Data>;
  const props = fprops ?? ({} as Props);
  const context = React.useContext(Context.ref);
  const contextStore = Context.store;
  const store = useDataStore<Data>(getDefault, initialize);

  const [callback, setCallback] = React.useState<
    Option<CurrentCallback<WithContext<DataMap>>>
  >(None());

  const fparent = React.useMemo(() => Option(parent), [parent]);
  const keys = React.useMemo(
    () =>
      fparent
        .unwrap(
          (p) => Object.keys(p),
          () => [],
        )
        .concat([key as string, 'context']),
    [key, fparent],
  );

  const [data, stores] = React.useMemo(
    () =>
      keys.reduce(
        ([x, y], k) => {
          if (k !== 'context') {
            const _x = x as unknown as IndexedDataMap<DataMap>;
            const _y = y as unknown as IndexedDispatchables<DataMap>;
            if (k === key) {
              _x[k] = store.data as unknown as DataMap[keyof DataMap];
              _y[k] = store as unknown as DispatchableStore<
                DataMap[keyof DataMap]
              >;
            } else {
              fparent.forEach((p) => {
                const _p = p as unknown as {
                  [k: string]: Store<DataMap[keyof DataMap]>;
                };
                _x[k] = _p[k].data;
                _y[k] = _p[k] as unknown as DispatchableStore<
                  DataMap[keyof DataMap]
                >;
              });
            }
          }
          return [x, y];
        },
        contextStore.unwrap(
          (cs) => [{ context }, { context: cs }],
          () => [{}, {}],
        ) as unknown as [
          WithContext<DataMap>,
          DataMapStore<WithContext<DataMap>>,
        ],
      ),
    [context, contextStore, store, fparent, keys, key],
  );

  const prev = usePrevious<PreviousDataMap<Props, WithContext<DataMap>>>({
    props,
    data,
  });

  const dispatch = useDispatchFromKeys<WithContext<DataMap>>(
    keys as Array<keyof WithContext<DataMap>>,
  );

  const state: DispatchableDataMapState<WithContext<DataMap>> = {
    stores,
    setCallback: (callback: CurrentCallback<WithContext<DataMap>>) =>
      setCallback(Some(callback)),
  };

  const views = useViews<WithContext<DataMap>>(state);

  const self: InnerDataMapState<Props, DataMap> &
    WithSetCallback<WithContext<DataMap>> = {
    ...state,
    data,
    prev,
    dispatch,
    views,
  };

  React.useEffect(() => {
    if (store.isInitialized) {
      Option(update).forEach((f) =>
        f({ props, data, prev, dispatch }).dispatch(self),
      );
    }
  });

  React.useEffect(() => {
    callback.forEach((f) => f({ props, data, prev, dispatch }).dispatch(self));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback]);

  return self;
}
