import React from 'react';
import { IcWaitingRed } from '../icons/IcWaitingRed';
import { Length } from '../style/Length';

export const Loading: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 64,
        width: '100%',
      }}
    >
      <IcWaitingRed
        style={{
          width: Length.px(32),
          height: Length.px(32),
        }}
        animate
      />
    </div>
  );
};
