import React from 'react';
import { EmptyVehicleEvent, VehicleEvent } from '../../apis/EventTelemetry';
import { ServiceTelemetry } from '../../apis/ServiceTelemetry';
import { getSelectedVehicle } from '../../apis/VehicleList';
import { Option } from '../../util/Option';
import { Scope } from '../../util/Scope';
import { theme } from '../../util/Theme';
import { isContentLoading } from '../home/Helpers';
import { HomeDataMap } from '../home/Home';
import { EventIdMap } from '../i18n/EventIdMap';
import { Intl, Message, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

export type VehicleState = Readonly<{
  kind: 'StateInfo';
  name: 'engineProhibit' | 'trackingOn';
  title: Message;
  image: string;
}>;

function getVehicleStateList(
  serviceTelemetry: ServiceTelemetry,
  intl: Intl,
): VehicleState[] {
  const xs: VehicleState[] = [];
  if (serviceTelemetry.dsc_eng_prohibit)
    xs.push({
      kind: 'StateInfo',
      name: 'engineProhibit',
      title: intl.formatMessage({ id: 'Home_engineProhibit' }),
      image: Icons.ic_enginelock_white,
    });

  if (serviceTelemetry.en_theft_trk)
    xs.push({
      kind: 'StateInfo',
      name: 'trackingOn',
      title: intl.formatMessage({ id: 'Home_trackingOn' }),
      image: Icons.ic_pursuit_white,
    });

  return xs;
}

function ListItem({
  parent,
  vehicleEvent,
  title,
}: Readonly<{
  parent: DataMapStore<HomeDataMap>;
  vehicleEvent: VehicleEvent | VehicleState | EmptyVehicleEvent;
  title: Message;
}>): React.ReactElement {
  const {
    views: { View, Image, Text },
    data: { context },
    dispatch,
  } = useParentStore({ parent });
  const image = Scope(() => {
    switch (vehicleEvent.kind) {
      case 'VehicleEvent':
        return EventIdMap.get(vehicleEvent.eventId).image.white;
      case 'StateInfo':
        return vehicleEvent.image;
    }
  });

  const disabled = isContentLoading(context);

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: Colors.primaryDark,
        borderBottom: Border('solid', Length.px(0.5), Colors.background),
        opacity: disabled ? Colors.disabledOpacity : 1.0,
      }}
      onClick={(evt) => {
        if (disabled) {
          return dispatch;
        }
        evt.stopPropagation();
        return dispatch.context
          .bind(Context.actions.updateCardInfo, {
            state: 'Opening',
            name: Scope(() => {
              switch (vehicleEvent.kind) {
                case 'VehicleEvent':
                  return 'VehicleAlert';
                case 'StateInfo':
                  switch (vehicleEvent.name) {
                    case 'engineProhibit':
                      return 'VehicleInfo';
                    case 'trackingOn':
                      return 'VehicleLocation';
                  }
              }
            }),
          })
          .context.bind(Context.actions.updateSelectedVehicleEvent, {
            selectedVehicleEvent: vehicleEvent,
            vehicleEventWindowMode: 'Show',
          });
      }}
    >
      <View
        style={{
          flexGrow: 0,
          paddingTop: Length.px(4),
          paddingBottom: Length.px(4),
          paddingLeft: Length.px(12),
          paddingRight: Length.px(12),
        }}
      >
        <Image
          src={image}
          style={{
            width: Length.px(40),
            height: Length.px(40),
          }}
        />
      </View>

      <View
        style={{
          flexGrow: 1,
          paddingTop: Length.px(10),
          paddingBottom: Length.px(16),
        }}
      >
        <Text
          style={{
            fontSize: Length.px(16),
            lineHeight: Length.px(20),
            color: Colors.buttonText,
          }}
        >
          {title.toReactNode()}
        </Text>
      </View>

      <View
        style={{
          paddingRight: Length.px(4),
        }}
      >
        <Image
          src={Icons.ic_forward_white}
          style={{
            width: Length.px(24),
            height: Length.px(24),
          }}
        />
      </View>
    </View>
  );
}

export function NotificationList({ parent }: Props): React.ReactElement {
  const {
    views: { View },
    data: { context },
  } = useParentStore({ parent });
  const intl = useIntl();

  return getSelectedVehicle(context)
    .flatMap((vehicle) => {
      return Option(
        context.userLastVehicleEvents.find((_) => _.vin === vehicle.vin),
      ).map((lastEvents) => {
        return (
          <View
            key={lastEvents.vin}
            style={{
              marginBottom: theme(Length.px(24), Length.px(0)),
            }}
          >
            {lastEvents.events.flatMap((e, idx) => {
              const title = intl.formatMessage({
                id: EventIdMap.get(e.eventId).home,
              });
              if (title.toString().trim() !== '-')
                return [
                  <ListItem
                    parent={parent}
                    key={`${idx}-${e.eventId}`}
                    vehicleEvent={e}
                    title={title}
                  />,
                ];
              else return [];
            })}
            {context.serviceTelemetry.data.unwrap(
              (srv) =>
                getVehicleStateList(srv, intl).flatMap((e, i) => (
                  <ListItem
                    parent={parent}
                    key={`${e.name}-${i}`}
                    vehicleEvent={e}
                    title={e.title}
                  />
                )),
              () => undefined,
            )}
          </View>
        );
      });
    })
    .getOrElse(() => <View />);
}
