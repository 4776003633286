import { config } from '../Config';
import {
  commonErrorDialogAction,
  errorDialogAction,
} from '../ui/components/AlertDialog';
import { dialogAction } from '../ui/components/Dialog';
import { FetchVehicleDataOptions } from '../ui/home/FetchVehicleData';
import { Intl, Message, MessageKeys, createIntl } from '../ui/i18n/Intl';
import { MessageIdMap } from '../ui/i18n/MessageIdMap';
import {
  Context,
  OngoingRemoteState,
  OngoingRemoteStates,
  defaultOngoingState,
} from '../ui/states/Context';
import { DataMapDispatch, WithContext } from '../ui/states/DataMapStore';
import { Anomaly } from '../util/Error';
import { Listener } from '../util/Listener';
import { Option, Some } from '../util/Option';
import { Pipe } from '../util/Pipe';
import { Success } from '../util/Result';
import { Scope } from '../util/Scope';
import { theme } from '../util/Theme';
import { Unit } from '../util/Unit';
import { println } from './NativeApi';
import {
  ErrorDialogMessageId,
  RemoteCommandAction,
  RemoteCommandName,
  RemoteCommandParams,
  RemoteCommandResponse,
  RemoteCommandType,
  createLastRemoteCommandAction,
  getErrorDialogMessageId,
  sendRemoteCommand,
  subscribeRemoteCommand,
  unsubscribeRemoteCommand,
} from './RemoteCommand';
import { ServiceTelemetry, ServiceTelemetryResult } from './ServiceTelemetry';
import { Vehicle, getSelectedVehicle } from './VehicleList';

type ErrorData = {
  readonly dialogTitle: Message;
  readonly dialogBody: Message;
};

export type RemoteCommandExecuterActions<Data> = {
  readonly updateIsSpinning: (data: Data, isSpinning: boolean) => Data;
};

function updateServiceTelemetry(
  srv: ServiceTelemetry,
  name: RemoteCommandName,
  state: boolean,
): ServiceTelemetryResult {
  return {
    vin: srv.vin,
    data: Success(
      Scope(() => {
        switch (name) {
          case 'allDoors':
            return { ...srv, door_status: state };
          case 'lights':
            return { ...srv, light: state };
          case 'hazardLamp':
            return { ...srv, hazard_lamp: state };
          case 'airCondition':
            return theme(
              { ...srv, air_con_status: state, eng_status: state },
              { ...srv, eng_status: state },
            );
          case 'theftTracking':
            return { ...srv, en_theft_trk: state };
          case 'engineCommandProhibited':
            return { ...srv, dsc_eng_prohibit: state };
          case 'wifi':
            return { ...srv, wifi_status: state };
          default:
            return srv;
        }
      }),
    ),
  };
}

function getFetchDataOptions(name: RemoteCommandName): FetchVehicleDataOptions {
  return Scope(() => {
    switch (name) {
      case 'allDoors':
        return config.fetchDataOptions.onDoorLockCommand;
      case 'lights':
        return config.fetchDataOptions.onHeadLightCommand;
      case 'hazardLamp':
        return config.fetchDataOptions.onHazardLampCommand;
      case 'airCondition':
        return config.fetchDataOptions.onAirConCommand;
      case 'theftTracking':
        return config.fetchDataOptions.onTrackingModeCommand;
      case 'engineCommandProhibited':
        return config.fetchDataOptions.onEngineProhibitCommand;
      default:
        return {};
    }
  });
}

function updateOngoingRemoteState(
  name: RemoteCommandName,
  state: boolean,
): Partial<OngoingRemoteState> {
  switch (name) {
    case 'allDoors':
      return { door: state };
    case 'lights':
      return { light: state };
    case 'hazardLamp':
      return { hazard: state };
    case 'airCondition':
      return { air_con: state };
    case 'theftTracking':
      return { trackingMode: state };
    case 'engineCommandProhibited':
      return { engineProhibit: state };
    case 'wifi':
      return { wifi: state };
    default:
      return {};
  }
}

export function updateOngoingRemoteStates(
  vin: Option<string>,
  name: RemoteCommandName,
  state: boolean,
): (data: Context) => Context {
  return (data: Context) => {
    const ongoingRemoteStates = data.ongoingRemoteStates;
    const update = updateOngoingRemoteState(name, state);
    const ongoingState = vin.unwrap(
      (vin) => ongoingRemoteStates[vin],
      () => undefined,
    );
    const newState = Option(ongoingState).unwrap(
      (a) => ({
        ...a,
        ...update,
      }),
      () => ({
        ...defaultOngoingState,
        ...update,
      }),
    );

    const states: OngoingRemoteStates = {
      ...ongoingRemoteStates,
      ...Object.fromEntries([[vin, newState]]),
    };

    return {
      ...data,
      ongoingRemoteStates: states,
    };
  };
}

export function getRemoteCommandErrorMessage<
  Name extends RemoteCommandName,
  Action extends RemoteCommandAction<Name>,
>(
  vehicle: Vehicle,
  commandName: Name,
  commandAction: Action,
  code: number,
  intl: Intl,
): ErrorData {
  const messageId: ErrorDialogMessageId = Scope(() => {
    switch (code) {
      case 408: {
        const d = MessageIdMap.get('Event_remoteCmdCenterFail').dialog;
        return {
          dialogTitleId: d.title,
          dialogBodyId: d.body,
        };
      }
      case 409: {
        const d = MessageIdMap.get('Event_remoteCmdDuplicated').dialog;
        return {
          dialogTitleId: d.title,
          dialogBodyId: d.body,
        };
      }
      case 410: {
        const d = MessageIdMap.get(
          'Event_remoteCmdFailedConditionNotSatisfied',
        ).dialog;
        return {
          dialogTitleId: d.title,
          dialogBodyId: d.body,
        };
      }
      case 500: {
        const d = MessageIdMap.get('Event_remoteCmdCenterFail').dialog;
        return {
          dialogTitleId: d.title,
          dialogBodyId: d.body,
        };
      }
      default:
        return getErrorDialogMessageId(commandName, commandAction);
    }
  });
  return {
    dialogTitle: intl.formatMessage({ id: messageId.dialogTitleId }),
    dialogBody: intl.formatMessage(
      { id: messageId.dialogBodyId },
      { model: vehicle.model },
    ),
  };
}

function actionToBoolean(
  action: RemoteCommandAction<RemoteCommandName>,
): boolean {
  switch (action) {
    case 'locked':
      return false;
    case 'on':
      return true;
    case 'off':
      return false;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return false;
  }
}

export function execRemoteCommand<
  Parent,
  Name extends Exclude<RemoteCommandName, 'geofence'>,
  Action extends RemoteCommandAction<Name>,
>({
  commandName,
  commandAction,
  closeAction,
  toastMessageId,
  updateIsSpinning,
  context,
  dispatch,
}: Readonly<{
  commandName: Name;
  commandAction: Action;
  closeAction: DataMapDispatch<WithContext<Parent>>;
  toastMessageId: Option<MessageKeys>;
  updateIsSpinning: (enable: boolean) => DataMapDispatch<WithContext<Parent>>;
  context: Context;
  dispatch: DataMapDispatch<WithContext<Parent>>;
}>): DataMapDispatch<WithContext<Parent>> {
  type DP = DataMapDispatch<WithContext<Parent>>;

  const intl = createIntl(context);
  //const dispatch = Dispatch<SlideButtonData, Data>();
  const lastCommandAction = createLastRemoteCommandAction(
    commandName,
    commandAction,
  );

  const getErrorData: (code: number, vehicle: Vehicle) => ErrorData = (
    code,
    vehicle,
  ) =>
    getRemoteCommandErrorMessage(
      vehicle,
      commandName,
      commandAction,
      code,
      intl,
    );

  const send: (vehicle: Vehicle) => Promise<RemoteCommandResponse<Name>> = (
    vehicle,
  ) =>
    sendRemoteCommand<Name, Action>({
      dcmId: vehicle.dcmId,
      vin: vehicle.vin,
      type: 'vehicle' as RemoteCommandType<Name>,
      name: commandName,
      action: commandAction,
      params: {} as RemoteCommandParams<Name, Action>,
    });

  const errorAction: (vehicle: Vehicle, err: unknown) => DP = (
    vehicle,
    err,
  ) => {
    const a = Anomaly.of(err);
    return (
      dispatch
        .effect(() => println(a))
        // .parent.bind(closeAction)
        // .parent.bind(actions.updateIsSpinning, false)
        .compose(closeAction)
        .compose(updateIsSpinning(false))
        .context(
          updateOngoingRemoteStates(Some(vehicle.vin), commandName, false),
        )
        .context(
          Pipe(getErrorData(a.code, vehicle))
            .map((errorData) =>
              errorDialogAction(errorData.dialogTitle, errorData.dialogBody),
            )
            .get(),
        )
    );
  };

  const noVehicleError: (err: Error) => DP = (err) =>
    dispatch
      .effect(() => println(err))
      .compose(closeAction)
      .compose(updateIsSpinning(false))
      .context(commonErrorDialogAction(intl));

  const listen: (
    vehicle: Vehicle,
    listener: Listener<RemoteCommandResponse<Name>>,
  ) => Promise<DP> = (vehicle, listener) =>
    listener
      .toPromise()
      .then((res) =>
        unsubscribeRemoteCommand(commandName, vehicle.dcmId).then(() =>
          dispatch
            .context(
              updateOngoingRemoteStates(Some(vehicle.vin), commandName, false),
            )
            .context((data) => {
              const validVin = data.selectedVehicleVin.unwrap(
                (vin) => vin === res.command.vin,
                () => false,
              );
              if (validVin) {
                return context.serviceTelemetry.data.unwrap(
                  (srv) =>
                    Context.actions.updateServiceTelemetry(
                      data,
                      updateServiceTelemetry(
                        srv,
                        commandName,
                        actionToBoolean(commandAction),
                      ),
                    ),
                  () => data,
                );
              } else {
                return data;
              }
            })
            .context(Context.actions.updateContentState, 'Reload')
            .context(
              Context.actions.updateFetchVehicleDataOptions,
              getFetchDataOptions(commandName),
            )
            .context(lastCommandAction.add, Unit)
            .pipe((_) =>
              toastMessageId
                .map((id) =>
                  _.context(Context.actions.showToast, {
                    state: 'Show',
                    message: intl.formatMessage({ id }),
                  }),
                )
                .getOrElse(() => _),
            )
            .asyncAll(
              lastCommandAction.remove.then((_) => dispatch.context(_, Unit)),
            ),
        ),
      )
      .catch((err) => errorAction(vehicle, err));

  return getSelectedVehicle(context).unwrap(
    (vehicle) =>
      dispatch
        .compose(updateIsSpinning(true))
        .context(
          updateOngoingRemoteStates(Some(vehicle.vin), commandName, true),
        )
        .asyncAll(
          subscribeRemoteCommand(commandName, vehicle.dcmId, vehicle.vin)
            .then((listener) =>
              send(vehicle).then((_res) =>
                dispatch
                  .compose(closeAction)
                  .compose(updateIsSpinning(false))
                  .context(dialogAction('Event_remoteCmdAccepted', intl))
                  .asyncAll(listen(vehicle, listener)),
              ),
            )
            .catch((err) => errorAction(vehicle, err)),
        ),
    () => noVehicleError(Error(`ERROR: No vehicle selected`)),
  );
}
