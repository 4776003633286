/**
 * Structure of ViewBase:
 * + ViewState
 *   - ref
 *     + RefView
 *       - state
 *         + DispatchableDataMapState
 *       - shape
 *   - view
 *     + ViewBase
 *       - (props) => React.ReactElement
 * @module
 */
import React from 'react';
import { None, Option, Some } from '../../util/Option';
import {
  DispatchableDataMapState,
  IndexedDispatchables,
} from '../states/DataMapStore';
import { DefaultDataMap } from '../states/Dispatch';
import { DispatchMethodBase } from '../states/DispatchBase';
import { RootDataMap } from '../states/DispatchRoot';
import { OptionalStyles, toCSSProperties } from '../style/Style';
import { PropsHandlers } from './EventHandlers';
import { Shape } from './Shape';

export type ViewBase<DataMap, E> = (
  props: Props<DataMap, E>,
) => React.ReactElement | null;

export type ShapeViewBase<DataMap> = ViewBase<DataMap, HTMLDivElement>;

type PropKeysWithoutEvents<DataMap, E> = Exclude<
  keyof React.AllHTMLAttributes<E>,
  keyof PropsHandlers<DataMap, E> | 'style'
>;

type PropsWithoutEvents<DataMap, E> = {
  readonly [Key in PropKeysWithoutEvents<
    DataMap,
    E
  >]?: React.AllHTMLAttributes<E>[Key];
};

//interface PropsWithEvents<Data extends Copyable<Data>> extends PropsWithoutEvents<Data>, PropsWithParent<Data> {}

export interface Props<DataMap, E>
  extends PropsWithoutEvents<DataMap, E>,
    PropsHandlers<DataMap, E> {
  readonly style?: OptionalStyles;
  readonly ref?: React.Ref<E>;
}

type MutableRefView<DataMap> = {
  state: DispatchableDataMapState<DataMap>;
  shape: Option<Shape>;
};

export type RefView<DataMap> = Readonly<MutableRefView<DataMap>>;

type ViewState<DataMap, E> = Readonly<{
  ref: RefView<DataMap>;
  view: ViewBase<DataMap, E>;
}>;

type ShapeViewState<DataMap> = ViewState<DataMap, HTMLDivElement>;

type MutableViewState<DataMap, E> = Omit<ViewState<DataMap, E>, 'ref'> & {
  readonly ref: MutableRefView<DataMap>;
};

export type ViewsBaseWithoutShapes<DataMap> = {
  readonly [Key in ViewBaseKeys]: ViewBase<DataMap, ElementType[Key]>;
};

type DeepShapes<
  DataMap,
  Data,
  Output extends ShapeViewBase<DataMap> | ShapeViewState<DataMap>,
> = Readonly<{
  [Key in keyof Data]: Data[Key] extends Shape
    ? Output
    : Data[Key] extends boolean | number | string
    ? never
    : DeepShapes<DataMap, Data[Key], Output>;
}>;

export type ShapeMap<
  DataMap,
  Output extends ShapeViewBase<DataMap> | ShapeViewState<DataMap>,
> = Readonly<{
  [Key in keyof DataMap]: DeepShapes<DataMap, DataMap[Key], Output>;
}>;

export type ViewsBaseWithShapes<DataMap> = ShapeMap<
  DataMap,
  ShapeViewBase<DataMap>
>;

export type ViewsBase<DataMap> = ViewsBaseWithShapes<DataMap> &
  ViewsBaseWithoutShapes<DataMap>;

export type Views<Data, Parent> = ViewsBase<DefaultDataMap<Data, Parent>>;

export type ParentViews<Data, Parent> = ViewsBase<DefaultDataMap<Data, Parent>>;

export type RootViews<Data> = ViewsBase<RootDataMap<Data>>;

type ViewStatesWithoutShapes<DataMap> = Readonly<{
  [K in ViewBaseKeys]: ViewState<DataMap, ElementType[K]>;
}>;

type ViewStates<DataMap> = ShapeMap<DataMap, ShapeViewState<DataMap>> &
  ViewStatesWithoutShapes<DataMap>;

type ViewProps<E> = {
  readonly ref?: React.Ref<E>;
  readonly style: React.CSSProperties;
};

type ViewMap = Readonly<{
  default: 'div';
  image: 'img';
  input: 'input';
  View: 'div';
  Text: 'div';
  Button: 'button';
  Label: 'label';
  Image: 'img';
  Input: 'input';
  Select: 'select';
  Option: 'option';
}>;

type ElementType = Readonly<{
  default: HTMLDivElement;
  image: HTMLImageElement;
  input: HTMLInputElement;
  View: HTMLDivElement;
  Text: HTMLDivElement;
  Button: HTMLButtonElement;
  Label: HTMLLabelElement;
  Image: HTMLImageElement;
  Input: HTMLInputElement;
  Select: HTMLSelectElement;
  Option: HTMLOptionElement;
}>;

type ViewBaseKeys = keyof ViewMap;

const viewMap: ViewMap = {
  default: 'div',
  image: 'img',
  input: 'input',
  View: 'div',
  Text: 'div',
  Button: 'button',
  Label: 'label',
  Image: 'img',
  Input: 'input',
  Select: 'select',
  Option: 'option',
};

const viewKeys = Object.keys(viewMap) as Array<ViewBaseKeys>;

export function asShapeView<DataMap>(a: unknown): ShapeViewBase<DataMap> {
  return a as ShapeViewBase<DataMap>;
}

function defineView<
  DataMap,
  Key extends ViewBaseKeys,
  N extends ViewMap[Key] = ViewMap[Key],
  E extends ElementType[Key] = ElementType[Key],
>(refView: RefView<DataMap>, elementName: N): ViewBase<DataMap, E> {
  // eslint-disable-next-line react/display-name
  return React.forwardRef((props: Props<DataMap, E>, ref: React.Ref<E>) => {
    const { style, children, ...rest } = props;
    const state = refView.state;

    const _props = Object.keys(rest).reduce((acc, key) => {
      const input = rest as any; // eslint-disable-line @typescript-eslint/no-explicit-any
      const output = acc as any; // eslint-disable-line @typescript-eslint/no-explicit-any

      if (key.startsWith('on') && typeof input[key] === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        output[key] = (evt: any) => {
          const dispatch: DispatchMethodBase<DataMap> = input[key](evt);
          dispatch.dispatch(state);
        };
      } else if (key === 'style') {
        output[key] = toCSSProperties(input[key]);
      } else {
        output[key] = input[key];
      }
      return output;
    }, {});

    const viewStyle: OptionalStyles = style ? style : {};
    const viewProps = refView.shape
      .map<ViewProps<E>>((shape) => ({
        ref: shape.getRef(),
        style: toCSSProperties({
          ...shape.styles,
          ...viewStyle,
        }),
      }))
      .getOrElse(() => ({
        ref: ref ?? undefined,
        style: toCSSProperties(viewStyle),
      }));

    return React.createElement(
      elementName,
      {
        ..._props,
        ...viewProps,
      },
      children,
    );
  });
}

function createViewState<
  DataMap,
  Key extends ViewBaseKeys,
  N extends ViewMap[Key] = ViewMap[Key],
  E extends ElementType[Key] = ElementType[Key],
>(
  state: DispatchableDataMapState<DataMap>,
  elementName: N,
): ViewState<DataMap, E> {
  const ref: RefView<DataMap> = {
    state,
    shape: None<Shape>(),
  };
  const view: ViewBase<DataMap, E> = defineView<DataMap, Key, N, E>(
    ref,
    elementName,
  );
  return { ref, view };
}

function hasKind(a: unknown, kind: string): boolean {
  return (a as { kind: string }).kind === kind;
}

export function deepShapes<
  DataMap,
  Data,
  Output extends ShapeViewState<DataMap> = ShapeViewState<DataMap>,
>(
  state: DispatchableDataMapState<DataMap>,
  data: Data,
): Option<DeepShapes<DataMap, Data, Output>> {
  const keys = Object.keys(data);
  if (
    typeof data === 'string' ||
    typeof data === 'boolean' ||
    typeof data === 'number' ||
    typeof data === 'function' ||
    data instanceof Array ||
    hasKind(data, 'Option') ||
    hasKind(data, 'Result') ||
    keys.length === 0
  ) {
    return None();
  } else {
    const result = keys.reduce<DeepShapes<DataMap, Data, Output>>(
      (acc, key) => {
        const output = acc as {
          [key: string]:
            | ShapeViewState<DataMap>
            | DeepShapes<DataMap, unknown, Output>;
        };
        const input = data as unknown as { [key: string]: Shape };

        if (hasKind(input[key], 'Shape')) {
          output[key] = createViewState<DataMap, 'View'>(state, 'div');
        } else {
          deepShapes(state, input[key]).forEach((_) => (output[key] = _));
        }

        return acc;
      },
      {} as DeepShapes<DataMap, Data, Output>,
    );
    return Some(result);
  }
}

function createDefaultViewStates<DataMap>(
  state: DispatchableDataMapState<DataMap>,
): ViewStatesWithoutShapes<DataMap> {
  return viewKeys.reduce((o, k) => {
    const _ = o as {
      [Key in ViewBaseKeys]: ViewState<DataMap, ElementType[ViewBaseKeys]>;
    };
    _[k] = createViewState<DataMap, ViewBaseKeys>(state, viewMap[k]);
    return o;
  }, {} as ViewStatesWithoutShapes<DataMap>);
}

function useViewStates<DataMap>(
  state: DispatchableDataMapState<DataMap>,
): ViewStates<DataMap> {
  type Data = DataMap[keyof DataMap];
  const _stores = state.stores as unknown as IndexedDispatchables<DataMap>;
  const [views] = React.useState<ViewStates<DataMap>>(() => {
    const keys = Object.keys(_stores);
    const shapeMap = keys.reduce((o, k) => {
      const data = _stores[k].data;
      const _ = o as unknown as {
        [key: string]: DeepShapes<DataMap, unknown, ShapeViewState<DataMap>>;
      };
      _[k] = deepShapes(state, data).getOrElse(
        () => ({} as DeepShapes<DataMap, Data, ShapeViewState<DataMap>>),
      );
      return o;
    }, {} as ShapeMap<DataMap, ShapeViewState<DataMap>>);
    const viewStates = createDefaultViewStates(state);
    return {
      ...shapeMap,
      ...viewStates,
    } as ViewStates<DataMap>;
  });
  return views;
}

function getViewHelper<DataMap, E>(
  state: DispatchableDataMapState<DataMap>,
  viewState: ViewState<DataMap, E>,
  shape: Option<Shape>,
): ViewBase<DataMap, E> {
  const viewStateM = viewState as MutableViewState<DataMap, E>;
  viewStateM.ref.state = state;
  viewStateM.ref.shape = shape;
  return viewState.view;
}

function deepViewsHelper<DataMap, Data>(
  state: DispatchableDataMapState<DataMap>,
  data: Data,
  viewStates: DeepShapes<DataMap, Data, ShapeViewState<DataMap>>,
): DeepShapes<DataMap, Data, ShapeViewBase<DataMap>> {
  return Object.entries(viewStates).reduce<
    DeepShapes<DataMap, Data, ShapeViewBase<DataMap>>
  >((acc, [key, value]) => {
    const _value = value as Partial<ShapeViewState<DataMap>>;
    const leaf = acc as unknown as {
      [key: string]: ShapeViewBase<DataMap>;
    };
    const node = acc as unknown as {
      [key: string]: DeepShapes<
        DataMap,
        Data[keyof Data],
        ShapeViewBase<DataMap>
      >;
    };
    // filter only shape keys
    //if (viewKeys.every((_) => _ !== viewKey)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const childData = (data as any)[key];
    if (_value.ref && _value.view) {
      leaf[key] = getViewHelper(
        state,
        value as ShapeViewState<DataMap>,
        Some(childData),
      );
    } else {
      node[key] = deepViewsHelper(
        state,
        childData,
        value as DeepShapes<DataMap, Data[keyof Data], ShapeViewState<DataMap>>,
      );
    }
    //}
    return acc;
  }, {} as DeepShapes<DataMap, Data, ShapeViewBase<DataMap>>);
}

const noneShape = None<Shape>();

function createDefaultViews<DataMap>(
  state: DispatchableDataMapState<DataMap>,
  viewStates: ViewStatesWithoutShapes<DataMap>,
): ViewsBaseWithoutShapes<DataMap> {
  return viewKeys.reduce((o, k) => {
    const _ = o as {
      [key in ViewBaseKeys]: ViewBase<DataMap, ElementType[ViewBaseKeys]>;
    };
    _[k] = getViewHelper<DataMap, ElementType[ViewBaseKeys]>(
      state,
      viewStates[k] as ViewState<DataMap, ElementType[ViewBaseKeys]>,
      noneShape,
    );
    return o;
  }, {} as ViewsBaseWithoutShapes<DataMap>);
}

function getViewsHelper<DataMap>(
  state: DispatchableDataMapState<DataMap>,
  viewStates: ViewStates<DataMap>,
): ViewsBase<DataMap> {
  const _stores = state.stores as unknown as IndexedDispatchables<DataMap>;
  const shapeMap = Object.entries(_stores).reduce((o, [key, store]) => {
    const _viewStates = viewStates as {
      [key: string]: DeepShapes<
        DataMap,
        DataMap[keyof DataMap],
        ShapeViewState<DataMap>
      >;
    };
    const _o = o as {
      [key: string]: DeepShapes<
        DataMap,
        DataMap[keyof DataMap],
        ShapeViewBase<DataMap>
      >;
    };
    const shapes = deepViewsHelper(state, store.data, _viewStates[key]);

    _o[key] = shapes;
    return o;
  }, {} as ShapeMap<DataMap, ShapeViewBase<DataMap>>);
  const views = createDefaultViews(state, viewStates);
  return {
    ...shapeMap,
    ...views,
  } as ViewsBase<DataMap>;
}

function getViews<DataMap>(
  state: DispatchableDataMapState<DataMap>,
  viewStates: ViewStates<DataMap>,
): ViewsBase<DataMap> {
  return getViewsHelper(state, viewStates);
}

export function useViews<DataMap>(
  state: DispatchableDataMapState<DataMap>,
): ViewsBase<DataMap> {
  const viewStates = useViewStates(state);
  return getViews<DataMap>(state, viewStates);
}
