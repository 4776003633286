import React from 'react';
import { Context } from '../states/Context';
import { Store, useDataStore } from '../states/Store';

export function ContextProvider({
  children,
}: Readonly<{ children: React.ReactNode }>): React.ReactElement {
  const contextStore: Store<Context> = useDataStore(Context, Context);

  Context.setStore(contextStore);

  return (
    <Context.ref.Provider value={contextStore.data}>
      {children}
    </Context.ref.Provider>
  );
}
