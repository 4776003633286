import React from 'react';
import { Matrix3x3 } from '../../math/Matrix';
import { Option } from '../../util/Option';
import { Pipe } from '../../util/Pipe';
import { Actions, Reducer } from '../states/Reducer';
import { Border } from '../style/Border';
import { Bound } from '../style/Bound';
import { Length } from '../style/Length';
import { OptionalStyles, Padding, toCSSProperties } from '../style/Style';
import { Transform } from '../style/Transform';

type Props<E extends HTMLElement> = {
  readonly ref: React.RefObject<E>;
  readonly style: React.CSSProperties;
};

type Sides<A> = {
  top: A;
  right: A;
  bottom: A;
  left: A;
};

export type Borders = Sides<Border>;

export type Paddings = Sides<Padding>;

export interface Shape {
  readonly kind: 'Shape';
  readonly styles: OptionalStyles;
  readonly getProps: <E extends HTMLElement>() => Props<E>;
  readonly getBound: () => Option<Bound>;
  readonly getBorders: () => Borders;
  readonly getPaddings: () => Paddings;
  readonly getLeft: () => number;
  readonly getTop: () => number;
  readonly getRaw: () => Option<HTMLElement>;
  readonly getRef: <E extends HTMLElement>() => React.RefObject<E>;
  readonly translate: (dx: number, dy: number) => Transform;
  readonly updateStyles: (styles: OptionalStyles) => Shape;
}

type Acts = Actions<
  Shape,
  {
    // no additional members
  }
>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Statics extends Reducer<Shape, Acts> {}

interface Factory {
  (
    styles: OptionalStyles,
    ref?: React.RefObject<HTMLElement>,
    id?: number,
  ): Shape;
}

type _Subscriber = (shape: Shape) => void;

const createData: Factory = (
  styles,
  ref = React.createRef<HTMLElement>(),
  id = 0,
) => ({
  $: { id },
  kind: 'Shape',

  styles: styles,

  getProps: <E extends HTMLElement>() => ({
    ref: ref as React.RefObject<E>,
    style: toCSSProperties(styles),
  }),

  getBound: () =>
    Option(ref.current).map((e) =>
      Bound.fromDOMRect(e.getBoundingClientRect()),
    ),

  getBorders: () => ({
    top: styles.borderTop ?? styles.border ?? Border.none,
    right: styles.borderRight ?? styles.border ?? Border.none,
    bottom: styles.borderBottom ?? styles.border ?? Border.none,
    left: styles.borderLeft ?? styles.border ?? Border.none,
  }),

  getPaddings: () => ({
    top: styles.paddingTop ?? styles.padding ?? Length.px(0),
    right: styles.paddingRight ?? styles.padding ?? Length.px(0),
    bottom: styles.paddingBottom ?? styles.padding ?? Length.px(0),
    left: styles.paddingLeft ?? styles.padding ?? Length.px(0),
  }),

  getLeft: () =>
    Pipe(styles.left)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((x: any) => {
        if (x === undefined) return 0;
        else if (x.value) return x.value;
        else return 0;
      })
      .get(),

  getTop: () =>
    Pipe(styles.top)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((x: any) => {
        if (x === undefined) return 0;
        else if (x.value) return x.value;
        else return 0;
      })
      .get(),

  translate: (dx: number, dy: number) =>
    Transform(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Matrix3x3.translate(dx, dy).product(styles.transform!.matrix).to3x3(),
    ),

  getRaw: () => Option(ref.current),

  getRef: <E extends HTMLElement>() => ref as React.RefObject<E>,

  updateStyles: (_styles: OptionalStyles) =>
    createData(
      {
        ...styles,
        ..._styles,
      },
      ref,
      id + 1,
    ),
});

const factory: Factory = (styles) =>
  createData({
    boxSizing: 'border-box',
    transform: Transform(Matrix3x3.unit),
    border: Border.none,
    padding: Length.px(0),
    ...styles,
  });

const statics: Statics = {
  initialize: (data: Shape) => {
    const rect = data.getBound().getUnsafeValue();
    return data.updateStyles({
      width: data.styles.width ?? Length.px(rect.width),
      height: data.styles.height ?? Length.px(rect.height),
    });
  },
  actions: {},
};

export const Shape: Factory & Statics = Object.assign(factory, statics);
