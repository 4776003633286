import React from 'react';
import { None, Option, Some } from '../../util/Option';
import { LineSpace } from '../components/LineSpace';
import { Table2x2 } from '../components/Table2x2';
import { Message, MessageKeys, useIntl } from '../i18n/Intl';
import { Icons } from '../icons/Icons';
import { useParentStore } from '../states/ContextStore';
import {
  DataMapDispatch,
  DataMapStore,
  WithContext,
} from '../states/DataMapStore';
import { Border } from '../style/Border';
import { Colors } from '../style/Color';
import { Length } from '../style/Length';
import { Percentage } from '../style/Percentage';
import { MaintenanceItem } from './MaintenanceItem';
import {
  ServiceReminderActions,
  ServiceReminderDataMap,
} from './ServiceReminderCard';

type DP = DataMapDispatch<WithContext<ServiceReminderDataMap>>;

function ListItem({
  parent,
  text,
  onClick,
}: Readonly<{
  parent: DataMapStore<ServiceReminderDataMap>;
  text: Message;
  onClick?: (_: DP) => DP;
}>): React.ReactElement {
  const {
    views: { View, Text },
    dispatch,
  } = useParentStore({ parent });

  return (
    <View
      style={{
        paddingTop: Length.px(14),
        paddingBottom: Length.px(18),
        borderTop: Border('solid', Length.px(0.5), Colors.border),
      }}
      onClick={() =>
        Option(onClick).unwrap(
          (f) => f(dispatch),
          () => dispatch,
        )
      }
    >
      <Text
        style={{
          fontSize: Length.px(16),
          lineHeight: Length.px(16),
          color: Colors.accent,
        }}
      >
        {text.toReactNode()}
      </Text>
    </View>
  );
}

function dispatchResetPane(messageId: MessageKeys, dispatch: DP): DP {
  // return Dispatch<Data, Parent, Command>(actions.updateCommand, 'OpenResetPane')
  return dispatch
    .serviceReminder(ServiceReminderActions.get().updateResetPaneState, 'Show')
    .serviceReminder(
      ServiceReminderActions.get().updateResetPaneTitleId,
      messageId,
    );
}

export function OpenedView({
  parent,
}: Readonly<{
  parent: DataMapStore<ServiceReminderDataMap>;
}>): React.ReactElement {
  const {
    views: { View, Text },
  } = useParentStore({ parent });
  const intl = useIntl();

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'stretch',
          paddingLeft: Length.px(16),
          paddingRight: Length.px(16),
        }}
      >
        <LineSpace height={Length.px(16)} />

        <View
          style={{
            fontSize: Length.px(16),
            lineHeight: Length.px(20),
          }}
        >
          <Text>
            {intl
              .formatMessage({ id: 'Remainder_pageSubTitle1' })
              .toReactNode()}
          </Text>

          <Text>
            ({intl.formatMessage({ id: 'Remainder_exp' }).toReactNode()} :{' '}
            {intl.formatMessage({ id: 'Remainder_exp_1' }).toReactNode()}{' '}
            {intl.formatMessage({ id: 'Remainder_exp_2' }).toReactNode()})
          </Text>
        </View>

        <LineSpace height={Length.px(16)} />

        <Table2x2
          leftTitle={intl.formatMessage({ id: 'Remainder_day' }).toString()}
          leftBody={intl.formatDate(new Date())}
          rightTitle={intl
            .formatMessage({ id: 'Remainder_odoMeter' })
            .toString()}
          rightBody={`${(10050 as number).toLocaleString('en-US')} km`}
          centerMargin={8}
        />

        <LineSpace height={Length.px(15)} />

        <ListItem
          parent={parent}
          text={intl.formatMessage({ id: 'Remainder_myShop' })}
        />

        <ListItem
          parent={parent}
          text={intl.formatMessage({ id: 'Remainder_mySchedule' })}
        />

        <ListItem
          parent={parent}
          text={intl.formatMessage({ id: 'Remainder_search' })}
        />

        <ListItem
          parent={parent}
          text={intl.formatMessage({ id: 'Remainder_histroy' })}
          onClick={(dispatch) =>
            dispatch.serviceReminder(
              ServiceReminderActions.get().updateMaintenanceHistoryMode,
              'Show',
            )
          }
        />
      </View>

      <View
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          height: Length.px(48),
          backgroundColor: Colors.backgroundMoreDark,
          paddingLeft: Length.px(16),
          paddingBottom: Length.px(8),
        }}
      >
        <Text
          style={{
            fontSize: Length.px(14),
            lineHeight: Length.px(14),
            color: Colors.textLight,
            fontWeight: 'bold',
          }}
        >
          {intl.formatMessage({ id: 'Remainder_pageSubTitle2' }).toReactNode()}
        </Text>
      </View>

      <MaintenanceItem
        parent={parent}
        title={intl.formatMessage({ id: 'Remainder_mEngineOil' })}
        icon={Icons.ic_oil_normal}
        value={Percentage(79)}
        state={'Error'}
        threshold={{
          value: Percentage(75),
          text: intl.formatMessage({ id: 'Remainder_mGauge' }),
        }}
        ticks={Some(['', '2,500', '5,000', '7,500', '10,000 km'])}
        tickImage={Icons.ic_scale_engine}
        onClick={(evt, _) => {
          evt.stopPropagation();
          return dispatchResetPane('Remainder_mEngineOil', _);
        }}
      />

      <MaintenanceItem
        parent={parent}
        title={intl.formatMessage({ id: 'Remainder_mBattery' })}
        icon={Icons.ic_charging_normal}
        value={Percentage(90)}
        state={'Normal'}
        threshold={{
          value: Percentage(50),
          text: intl.formatMessage({ id: 'Remainder_mGauge' }),
        }}
        ticks={None<string[]>()}
        tickImage={Icons.ic_scale_others}
        onClick={(evt, _) => {
          evt.stopPropagation();
          return dispatchResetPane('Remainder_mBattery', _);
        }}
      />

      <MaintenanceItem
        parent={parent}
        title={intl.formatMessage({ id: 'Remainder_mBrakeOil' })}
        icon={Icons.ic_brakeoil_normal}
        value={Percentage(60)}
        state={'Normal'}
        threshold={{
          value: Percentage(50),
          text: intl.formatMessage({ id: 'Remainder_mGauge' }),
        }}
        ticks={None<string[]>()}
        tickImage={Icons.ic_scale_others}
        onClick={(evt, _) => {
          evt.stopPropagation();
          return dispatchResetPane('Remainder_mBrakeOil', _);
        }}
      />

      <MaintenanceItem
        parent={parent}
        title={intl.formatMessage({ id: 'Remainder_mBrakePadFr' })}
        icon={Icons.ic_brakepad_normal}
        value={Percentage(40)}
        state={'Warn'}
        threshold={{
          value: Percentage(50),
          text: intl.formatMessage({ id: 'Remainder_mGauge' }),
        }}
        ticks={None<string[]>()}
        tickImage={Icons.ic_scale_others}
        onClick={(evt, _) => {
          evt.stopPropagation();
          return dispatchResetPane('Remainder_mBrakePadFr', _);
        }}
      />

      <MaintenanceItem
        parent={parent}
        title={intl.formatMessage({ id: 'Remainder_mBrakePadRr' })}
        icon={Icons.ic_brakepad_normal}
        value={Percentage(15)}
        state={'Error'}
        threshold={{
          value: Percentage(50),
          text: intl.formatMessage({ id: 'Remainder_mGauge' }),
        }}
        ticks={None<string[]>()}
        tickImage={Icons.ic_scale_others}
        onClick={(evt, _) => {
          evt.stopPropagation();
          return dispatchResetPane('Remainder_mBrakePadRr', _);
        }}
      />

      <MaintenanceItem
        parent={parent}
        title={intl.formatMessage({ id: 'Remainder_mTirePressure' })}
        icon={Icons.ic_tirepressure_normal}
        value={Percentage(60)}
        state={'Normal'}
        threshold={{
          value: Percentage(50),
          text: intl.formatMessage({ id: 'Remainder_mPSI' }),
        }}
        ticks={None<string[]>()}
        tickImage={Icons.ic_scale_others}
        onClick={(evt, _) => {
          evt.stopPropagation();
          return dispatchResetPane('Remainder_mTirePressure', _);
        }}
      />
    </>
  );
}
