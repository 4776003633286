/*
  Similar to React Native's BackHandler, UIBackHandler lets developer register event listeners for the back action (invoked by Android's back action), and lets developer control how components responds. 
  The event subscriptions are called in reverse order (i.e. the last registered subscription is called first).

  - If one subscription returns true, then subscriptions registered earlier will not be called, and handle() function returns true.
  - If no subscription returns true or none are registered, the handle() function returns false.

  See: https://reactnative.dev/docs/backhandler
*/
import { println } from '../apis/NativeApi';

export type UIBackHandlerAction = () => boolean;
export interface UIBackHandlerSubscription {
  remove(): void;
}

export interface UIBackHandler {
  readonly addListener: (
    action: UIBackHandlerAction,
  ) => UIBackHandlerSubscription;
  readonly removeListener: (action: UIBackHandlerAction) => void;
  readonly handle: () => boolean;
}

export function createBackHandler(): UIBackHandler {
  const handlers: UIBackHandlerAction[] = [];

  const self: UIBackHandler = {
    addListener: (action) => {
      handlers.push(action);
      println(`[DEBUG] backHandler added ${handlers.length}`);
      return {
        remove: (): void => self.removeListener(action),
      };
    },

    removeListener: (action) => {
      if (handlers.indexOf(action) !== -1) {
        handlers.splice(handlers.indexOf(action), 1);
        println(`[DEBUG] backHandler removed ${handlers.length}`);
      }
    },

    handle: () => {
      for (let i = handlers.length - 1; i >= 0; i--) {
        if (handlers[i]()) {
          return true;
        }
      }
      return false;
    },
  };

  return self;
}
