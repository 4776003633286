import { Package } from '@auto/monaka-client/dist/20';
import * as unsafePackageJson from '../package.json';
import { GeoPos } from './apis/Geofence';
import { FetchVehicleDataOptions } from './ui/home/FetchVehicleData';
import { Duration } from './util/Duration';
import { Region } from './util/Locale';

export type Env =
  | 'mock'
  | 'dev'
  | 'qa'
  | 'devjp'
  | 'qajp'
  | 'mockp'
  | 'devmy'
  | 'qamy'
  | 'stgmy'
  | 'stgjp'
  | 'prod' /* TBD */;

type Config = {
  readonly version: string;
  readonly clientVersion: string;
  readonly geolocationTimeout: Duration;
  readonly geolocationInterval: Duration;
  readonly regionPositions: {
    readonly [R in Region]: {
      readonly position: GeoPos;
      readonly zoom: number;
    };
  };
  readonly fetchDataOptions: {
    readonly afterSignedIn: FetchVehicleDataOptions;
    readonly onBackToForeground: FetchVehicleDataOptions;
    readonly onReloadHome: FetchVehicleDataOptions;
    readonly onReloadVehicleInfo: FetchVehicleDataOptions;
    readonly onReloadVehicleLocation: FetchVehicleDataOptions;
    readonly onOpenVehicleInfo: FetchVehicleDataOptions;
    readonly onOpenVehicleLocation: FetchVehicleDataOptions;
    readonly onOpenServiceReminder: FetchVehicleDataOptions;
    readonly onOpenSDL: FetchVehicleDataOptions;
    readonly onOpenVehicleAlert: FetchVehicleDataOptions;
    readonly onOpenWifi: FetchVehicleDataOptions;
    readonly onReloadWifi: FetchVehicleDataOptions;

    // Remote Commands
    readonly onDoorLockCommand: FetchVehicleDataOptions;
    readonly onHeadLightCommand: FetchVehicleDataOptions;
    readonly onHazardLampCommand: FetchVehicleDataOptions;
    readonly onAirConCommand: FetchVehicleDataOptions;
    readonly onEngineProhibitCommand: FetchVehicleDataOptions;
    readonly onTrackingModeCommand: FetchVehicleDataOptions;
    readonly onGeofenceCommand: FetchVehicleDataOptions;
    // onWifiCommand and onWifiResetCommand are already implemented in a different way
    // readonly onWifiCommand: FetchVehicleDataOptions;
    // readonly onWifiResetCommand: FetchVehicleDataOptions;

    readonly onDeleteVehicle: FetchVehicleDataOptions;
    readonly onAddVehicle: FetchVehicleDataOptions;
    readonly onSelectVehicle: FetchVehicleDataOptions;
    readonly onEventType1: {
      readonly onForeground: FetchVehicleDataOptions;
    };
    // readonly onEventType2: {
    //   readonly onForeground: FetchVehicleDataOptions;
    // };
    readonly onEventType3: {
      readonly onForeground: FetchVehicleDataOptions;
    };
    readonly onEventType4: {
      readonly onForeground: FetchVehicleDataOptions;
    };
    // readonly onEventType5: {
    //   readonly onForeground: FetchVehicleDataOptions;
    // };
    readonly onEventType6: {
      readonly onForeground: FetchVehicleDataOptions;
    };
    readonly onEventType7: {
      readonly onForeground: FetchVehicleDataOptions;
    };
    readonly onEventType8: {
      readonly onForeground: FetchVehicleDataOptions;
    };
    // readonly onEventType9: {
    //   readonly onForeground: FetchVehicleDataOptions;
    // };
    readonly onEventType10: {
      readonly onForeground: FetchVehicleDataOptions;
    };
  };
} & {
  readonly [env in Env]: {
    readonly authEndpoint: string;
    readonly appSyncEndpoint: string;
    readonly appSyncRegion: string;
    readonly iotEndpoint: string;
    readonly dynamicLinkEndpoint: string;
    readonly subscriptionEndpoint: string;
  };
};

type UnsafePackageJson = { readonly version: string };

export const config: Config = {
  version: (unsafePackageJson as UnsafePackageJson).version,
  clientVersion: Package.version,
  geolocationTimeout: Duration.seconds(30),
  geolocationInterval: Duration.seconds(10),
  regionPositions: {
    JP: {
      position: {
        lat: 35.4222,
        lng: 139.4133,
      },
      zoom: 4,
    },
    MY: {
      position: {
        lat: 3.1357,
        lng: 101.688,
      },
      zoom: 5,
    },
    ID: {
      position: {
        lat: -6.1252,
        lng: 106.5042,
      },
      zoom: 5,
    },
    TH: {
      position: {
        lat: 13.4514,
        lng: 100.305,
      },
      zoom: 5,
    },
  },

  fetchDataOptions: {
    afterSignedIn: {
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onBackToForeground: {
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onReloadHome: {
      disableOtherSendTels: true,
    },
    onReloadVehicleInfo: {
      disableVehicleList: true,
      disableSendTel: true,
      disableOtherServiceTelemetries: true,
      disableOtherSendTels: true,
      disableWifiStatus: true,
    },
    onReloadVehicleLocation: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableSendTel: true,
      disableOtherServiceTelemetries: true,
      disableOtherSendTels: true,
      disableWifiStatus: true,
    },
    onOpenVehicleInfo: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
      enableGeofence: false,
      enableLastVehiclesEventList: true,
    },
    onOpenVehicleLocation: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
      enableGeofence: true,
      enableLastVehiclesEventList: false,
    },
    onOpenServiceReminder: {},
    onOpenSDL: {},
    onOpenVehicleAlert: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: false,
      disableVehicleEventList: false,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: false,
      disableSendTel: true,
      disableOtherSendTels: true,
      enableGeofence: true,
      enableLastVehiclesEventList: false,
    },
    onOpenWifi: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: true,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: false,
      disableSendTel: true,
      disableOtherSendTels: true,
      enableGeofence: false,
      enableLastVehiclesEventList: false,
    },
    onReloadWifi: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: false,
      disableSendTel: true,
      disableOtherSendTels: true,
      enableGeofence: false,
      enableLastVehiclesEventList: false,
    },

    onDoorLockCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onHeadLightCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onHazardLampCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onAirConCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onEngineProhibitCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onTrackingModeCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onGeofenceCommand: {
      disableVehicleList: true,
      disableUserLastVehiclesEventList: true,
      disableVehicleEventList: true,
      disableServiceTelemetry: false,
      disableOtherServiceTelemetries: true,
      disableWifiStatus: true,
      disableNotificationStatus: true,
      disableSendTel: true,
      disableOtherSendTels: true,
    },

    onDeleteVehicle: {
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onAddVehicle: {
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onSelectVehicle: {
      disableSendTel: true,
      disableOtherSendTels: true,
    },
    onEventType1: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: false,
        disableServiceTelemetry: false,
        disableOtherServiceTelemetries: false,
        disableWifiStatus: true,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: false,
        enableLastVehiclesEventList: true,
      },
    },
    // onEventType2: {
    //   onForeground: {},
    // },
    onEventType3: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: true,
        disableServiceTelemetry: false,
        disableOtherServiceTelemetries: true,
        disableWifiStatus: true,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: false,
        enableLastVehiclesEventList: false,
      },
    },
    onEventType4: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: true,
        disableServiceTelemetry: false,
        disableOtherServiceTelemetries: false,
        disableWifiStatus: true,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: false,
        enableLastVehiclesEventList: false,
      },
    },
    // onEventType5: {
    //   onForeground: {},
    // },
    onEventType6: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: true,
        disableServiceTelemetry: false,
        disableOtherServiceTelemetries: true,
        disableWifiStatus: true,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: false,
        enableLastVehiclesEventList: false,
      },
    },
    onEventType7: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: true,
        disableServiceTelemetry: true,
        disableOtherServiceTelemetries: true,
        disableWifiStatus: false,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: false,
        enableLastVehiclesEventList: false,
      },
    },
    onEventType8: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: true,
        disableServiceTelemetry: false,
        disableOtherServiceTelemetries: false,
        disableWifiStatus: true,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: true,
        enableLastVehiclesEventList: false,
      },
    },
    // onEventType9: {
    //   onForeground: {},
    // },
    onEventType10: {
      onForeground: {
        disableVehicleList: true,
        disableUserLastVehiclesEventList: true,
        disableVehicleEventList: true,
        disableServiceTelemetry: false,
        disableOtherServiceTelemetries: true,
        disableWifiStatus: true,
        disableSendTel: true,
        disableOtherSendTels: true,
        enableGeofence: false,
        enableLastVehiclesEventList: false,
      },
    },
  },

  mock: {
    authEndpoint: '',
    appSyncEndpoint: '',
    appSyncRegion: '',
    iotEndpoint: '',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: '',
  },
  mockp: {
    authEndpoint: '',
    appSyncEndpoint: '',
    appSyncRegion: '',
    iotEndpoint: '',
    dynamicLinkEndpoint: 'https://p2dev.ufirst.com.my',
    subscriptionEndpoint: '',
  },

  dev: {
    authEndpoint:
      'https://sdbi19ijh3.execute-api.ap-northeast-1.amazonaws.com/release/user_federation',
    appSyncEndpoint:
      'https://7uqae7tj6bg5hm5kz6ccm7r4u4.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-northeast-1',
    iotEndpoint: 'a2e8mkcf2qn963-ats.iot.ap-northeast-1.amazonaws.com',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: '',
  },

  qa: {
    authEndpoint:
      'https://he9s0sb373.execute-api.ap-northeast-1.amazonaws.com/release/user_federation',
    appSyncEndpoint:
      'https://dou4lfz5qfaotolocou2rnxoni.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-northeast-1',
    iotEndpoint: 'ahwnphjq7v4q6-ats.iot.ap-northeast-1.amazonaws.com',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: '',
  },
  devjp: {
    authEndpoint: 'https://auth.dspa.dev-jp.dmc-dxi.com/user_federation',
    appSyncEndpoint:
      'https://ozun3yncjrdcthf4emblxoirui.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-northeast-1',
    iotEndpoint: '',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: 'https://ui-subscription.dspa.dev-jp.dmc-dxi.com',
  },
  devmy: {
    authEndpoint:
      'https://jspxs7fal1.execute-api.ap-southeast-1.amazonaws.com/release/user_federation',
    appSyncEndpoint:
      'https://7uphhkknkzca7nwigwxietpcdu.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-southeast-1',
    iotEndpoint: '',
    dynamicLinkEndpoint: 'https://p2dev.ufirst.com.my',
    subscriptionEndpoint: '',
  },
  qajp: {
    authEndpoint:
      'https://5v2xdh614f.execute-api.ap-northeast-1.amazonaws.com/release/user_federation',
    appSyncEndpoint:
      'https://u2tv37yz5fczdi3dtv4irsieeq.appsync-api.ap-northeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-northeast-1',
    iotEndpoint: '',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: 'https://ui-subscription.dspa.qa-jp.dmc-dxi.com',
  },
  qamy: {
    authEndpoint:
      'https://uph6fn4ud7.execute-api.ap-southeast-1.amazonaws.com/release/user_federation',
    appSyncEndpoint:
      'https://mr53kxgyfbc73c52rtmatfstu4.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-southeast-1',
    iotEndpoint: '',
    dynamicLinkEndpoint: 'https://p2uat.ufirst.com.my',
    subscriptionEndpoint: '',
  },
  stgjp: {
    authEndpoint: '',
    appSyncEndpoint: '',
    appSyncRegion: '',
    iotEndpoint: '',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: '',
  },
  stgmy: {
    authEndpoint:
      'https://v80a9kyzp9.execute-api.ap-southeast-1.amazonaws.com/release/user_federation',
    appSyncEndpoint:
      'https://aecmzyft25haxl4qtohhgve37u.appsync-api.ap-southeast-1.amazonaws.com/graphql',
    appSyncRegion: 'ap-southeast-1',
    iotEndpoint: '',
    dynamicLinkEndpoint: 'https://p2uat.ufirst.com.my',
    subscriptionEndpoint: '',
  },
  prod: {
    authEndpoint: '',
    appSyncEndpoint: '',
    appSyncRegion: '',
    iotEndpoint: '',
    dynamicLinkEndpoint: '',
    subscriptionEndpoint: '',
  },
};
