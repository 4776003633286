import React from 'react';
import { config } from '../../Config';
import {
  isServiceAllowed,
  userRestrictionAction,
} from '../../apis/VehicleList';
import { Scope } from '../../util/Scope';
import { Card } from '../components/Card';
import { CardHeader } from '../components/CardHeader';
import { getLastUpdate } from '../home/Helpers';
import { HomeDataMap } from '../home/Home';
import { Message, useIntl } from '../i18n/Intl';
import { ContentState, Context } from '../states/Context';
import { useParentStore } from '../states/ContextStore';
import { DataMapStore } from '../states/DataMapStore';
import { getOverlayState } from './Helpers';
import { SubPanes } from './SubPanes';
import { VehicleLocationBody } from './VehicleLocationBody';
import { VehicleLocationState } from './VehicleLocationState';

type Props = Readonly<{
  parent: DataMapStore<HomeDataMap>;
}>;

const mapId = 'monaka.vehicle.location.card.map';

export function VehicleLocationCard(props: Props): React.ReactElement {
  const state = useParentStore<HomeDataMap, Props>({
    props,
    parent: props.parent,
    update: (args) => VehicleLocationState.update({ ...args, mapId }),
  });

  const context = state.data.context;
  const intl = useIntl();
  const localState = state.data.home.localState.geofence.localState;
  const lastUpdate: Date | Message = Scope(() => {
    if (context.dataErrors.serviceTelemetry)
      return intl.formatMessage({ id: 'Common_syncError' });
    else
      return context.serviceTelemetry.data
        .toOption()
        .flatMap((_) => _.position)
        .unwrap(
          (_) =>
            _.isOld
              ? intl.formatMessage({ id: 'Common_syncError' })
              : new Date(getLastUpdate(context)),
          () => intl.formatMessage({ id: 'Common_syncError' }),
        );
  });
  const contentState = Scope<ContentState>(() => {
    if (
      context.contentState === 'Loading' ||
      localState.googleMapState !== 'Loaded'
    ) {
      return 'Loading';
    } else if (
      context.contentState === 'Updating' ||
      localState.googleMapState !== 'Loaded'
    ) {
      return 'Updating';
    } else {
      return context.contentState;
    }
  });
  const overlayState = getOverlayState(
    localState.helpDialogState,
    localState.trackingPaneState,
  );

  return (
    <Card
      id='VehicleLocationCard'
      cardName='VehicleLocation'
      parent={state.stores}
      badgeName={intl.formatMessage({ id: 'Location_pageTitle' })}
      title={intl.formatMessage({ id: 'Location_pageTitle' })}
      lastUpdate={lastUpdate}
      reloadable={true}
      reloadOnOpen={config.fetchDataOptions.onOpenVehicleLocation}
      openable={true}
      contentState={contentState}
      overlayState={overlayState}
      pullToCloseOnHeader
      disableSpinnerOnOpen
      onOpen={(dispatch) => VehicleLocationState.onOpen(context, dispatch)}
      onClose={(dispatch) =>
        VehicleLocationState.onClose(state.data.home, dispatch)
      }
      onOpened={(dispatch) => VehicleLocationState.onOpened(dispatch)}
      onClosed={(dispatch) => VehicleLocationState.onClosed(dispatch)}
      onReload={(dispatch) =>
        dispatch
          .context(Context.actions.updateContentState, 'Reload')
          .context(
            Context.actions.updateFetchVehicleDataOptions,
            config.fetchDataOptions.onReloadVehicleLocation,
          )
      }
      onClickOverride={
        !isServiceAllowed(context, 'vehicleLocation')
          ? (dispatch) => dispatch.context.bind(userRestrictionAction(context))
          : undefined
      }
      extras={<SubPanes parent={state.stores} />}
    >
      <VehicleLocationBody
        parent={props.parent}
        mapId={mapId}
        headerHeight={CardHeader.height}
      />
    </Card>
  );
}
