/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { OptionalStyles } from '../ui/style/Style';

type ToOptionalObject<A extends {}> = {
  readonly [K in keyof A]?: ToOptional<A[K]>;
};

type NonOptional =
  | Date
  | Array<any>
  | Promise<any>
  | Map<any, any>
  | Set<any>
  | Error
  | { kind: 'Shepe' }
  | { kind: 'Distance' }
  | { kind: 'Duration' }
  | { kind: 'Lazy' }
  | { kind: 'Option' }
  | { kind: 'Result' };

export type ToOptional<A> = A extends NonOptional
  ? A
  : A extends {}
  ? ToOptionalObject<A>
  : A;

// type RequiredKeys<A> = Exclude<{ [K in keyof A]: K }[keyof A], undefined>;

export type ToRequiredObject<A> = {
  readonly [K in keyof A]-?: ToRequired<A[K]>;
};

export type ToRequired<A> = A extends undefined
  ? never
  : A extends null
  ? never
  : A extends number
  ? A
  : A extends string
  ? A
  : A extends boolean
  ? A
  : A extends any[]
  ? A
  : A extends Function
  ? A
  : A extends OptionalStyles
  ? A
  : A extends Record<string, any>
  ? ToRequiredObject<A>
  : A extends {}
  ? ToRequiredObject<A>
  : A;

export function copy<A>(a: A, values: ToOptional<A>): A {
  const _a = a as any;
  return Object.keys(a).reduce<A>((acc: any, k) => {
    const v0 = _a[k];
    const v1 = (values as any)[k];

    if (v1 === undefined) acc[k] = v0;
    else if (v1 instanceof Array) acc[k] = v1;
    else if (v1 instanceof Promise) acc[k] = v1;
    else if (v1 instanceof Date) acc[k] = v1;
    else if (v1 instanceof Map) acc[k] = v1;
    else if (v1 instanceof Set) acc[k] = v1;
    else if (v1 instanceof Error) acc[k] = v1;
    else if (v1 instanceof Symbol) acc[k] = v1;
    else if (v1 instanceof RegExp) acc[k] = v1;
    else if (v1 instanceof ArrayBuffer) acc[k] = v1;
    else if (v1.kind === 'Shape') acc[k] = v1;
    else if (v1.kind === 'Distance') acc[k] = v1;
    else if (v1.kind === 'Duration') acc[k] = v1;
    else if (v1.kind === 'Lazy') acc[k] = v1;
    else if (v1.kind === 'Option') acc[k] = v1;
    else if (v1.kind === 'Result') acc[k] = v1;
    else if (v1.kind === 'Listener') acc[k] = v1;
    else if (v1.kind === 'Sender') acc[k] = v1;
    else if (typeof v0 === 'object' && typeof v1 === 'object')
      acc[k] = copy(v0, v1);
    else acc[k] = v1;
    return acc;
  }, {} as any as A);
}
